import {Component, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {
  AccountService,
  AlertService,
  CampaignProductService,
  CampaignService,
  CampaignTrackingService,
  CRMCampaignService,
  FunnelStepService,
  ReportsConfigService,
  StorageService,
  UserService,
  SessionSummaryReportService, RefundReportService, MerchantService
} from '../_services';
import {ReportIntent} from '../_models';
import {ReportsAbstractComponent} from './reports-abstract.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {generateReportsQuery, normalizeMomentForQuery} from '../_helpers';
import {takeUntil} from 'rxjs/operators';
import {reportsFieldData} from '../session/sessionFieldData';
import * as moment from "moment-timezone";
import {config} from '../../config/config';

@Component({
  moduleId: module.id.toString(),
  selector: "session-summary-report",
  templateUrl: "./templates/session-summary-report.component.html",
  styleUrls: ['./styles/reports.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SessionSummaryReportComponent extends ReportsAbstractComponent {
  public fields = {
    dimensions: [
      [
        {model: "lifeline", label: "Lifelines", default: false, disabled: false},
        {model: "lifeline_type", label: "Lifeline type", default: false, disabled: false},
        {model: "bypass", label: "Bypass", default: false, disabled: false}
      ],
      [
        {model: "bypass_type", label: "Bypass type", default: false, disabled: false},
        {model: "source", label: "Path channel", default: false, disabled: false},
        {model: "result", label: "Session result", default: false, disabled: false},
      ],
      [
        {model: "session_type", label: "Session Type", default: false, disabled: false},
        {model: "carrier_type", label: "Phone Type", default: false, disabled: false},
        {model: "entered_input", label: "Key Prompts", default: false, disabled: false},
      ],
      [
        {model: "session_action_status", label: "Session Action Status", default: false, disabled: false},
        {model: "campaign_name", label: "Brand", default: false, disabled: false},
      ],
    ],
    timeInterval: [
      [
        {model: "hour", label: "Hours", default: false, disabled: false},
        {model: "date", label: "Date", default: false, disabled: false},
        {model: "week", label: "Week", default: false, disabled: false},
        {model: "month", label: "Month", default: false, disabled: false},
        {model: "year", label: "Year", default: false, disabled: false},
      ],
    ],
    statistics: [
      [
        {model: "session_count", label: "Session quantity", default: false, disabled: false},
      ],
      [
        {model: "successfull_sessions", label: "Successfull Sessions", default: false, disabled: false},
      ],
      [
        {model: "customer_count", label: "Customer Quantity", default: false, disabled: false},
      ],
      []
    ],
    calculations: [
      [
        {model: "self_service_rate", label: "Self Service Rate", default: false, disabled: false},
      ]
    ],
    exclude: [
      [
        {model: "exclude_tests", label: "Tests", default: true, disabled: false, isGlobal: false},
        {model: "exclude_bypass", label: "Bypass", default: false, disabled: false, isGlobal: false},
      ],
      [
        {model: "exclude_hangups", label: "Hangups", default: true, disabled: false, isGlobal: false},
        {model: "exclude_lifeline", label: "Lifeline", default: false, disabled: false, isGlobal: false},
      ],
      [
        {model: "exclude_any_empty_column", label: "Rows with empty cells", default: false, disabled: false, isGlobal: false},
        {model: "exclude_zeroes", label: "All Zeroes", default: false, disabled: false, isGlobal: false},
      ],
      [
        {model: "exclude_unknowns", label: "Unknowns", default: true, disabled: false, isGlobal: false},
        {model: "exclude_child_sessions", label: "Consolidate Sessions", default: true, disabled: false,
          isGlobal: true},
        {model: "exclude_child_orders", label: "Consolidate Orders", default: true, disabled: false, isGlobal: true}
      ],
    ],
    filters: [
      [
        {model: "lifeline", label: "Lifeline", default: false, disabled: false},
        {model: "bypass", label: "Bypass", default: false, disabled: false},
      ],
      [
        {model: "source", label: "Path Channel", default: false, disabled: false},
        {model: "result", label: "Session Result", default: false, disabled: false},
      ],
      [
        {model: "campaign_id", label: "Brand", default: false, disabled: false},
        {model: "device_type", label: "Device Type", default: false, disabled: false},
      ],
      [
        {model: "carrier_type", label: "Phone Type", default: false, disabled: false},
        {model: "entered_input__contains", label: "Key Prompts", default: false, disabled: false},
      ]
    ],
    filtersData: [
      [
        {model: "campaign_id", filter: "campaign_id", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "device_type", filter: "device_type", type: "multi-select", label: "Device Type", default: "", options: []},
        {model: "lifeline", filter: "lifeline", type: "select", label: "Lifeline", default: "",
          options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
          ]},
        {model: "bypass", filter: "bypass", type: "select", label: "Bypass", default: "",
          options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
          ]},
        {model: "source", filter: "source", type: "multi-select", label: "Path Channel", default: "",
          options: [
            {id: "Web", text: "Web"},
            {id: "Email", text: "Email"},
            {id: "Phone", text: "Phone"},
            {id: "Social Media", text: "Social Media"},
          ]},
        {model: "carrier_type", filter: "carrier_type", type: "multi-select", label: "Phone Type", default: "",
          options: [
            {id: "Unknown", text: "Unknown"},
            {id: "Mobile", text: "Mobile"},
            {id: "Landline", text: "Landline"},
            {id: "VOIP", text: "VOIP"},
          ]},
        {model: "result", filter: "result", type: "multi-select", label: "Session results", default: "",
          options: [
            {id: "Unknown", text: "Unknown"},
            {id: "Active Session", text: "Active Session"},
            {id: "Timeout", text: "Timeout"},
            {id: "Abandoned", text: "Abandoned"},
            {id: "Complete", text: "Complete"},
            {id: "Lifeline - Timeout", text: "LifelineTimeout"},
            {id: "Lifeline - Undo", text: "Lifeline - Undo"},
            {id: "Lifeline - Exit", text: "Lifeline - Exit"},
            {id: "Lifeline - Error", text: "Lifeline - Error"},
            {id: "Lifeline Contact Failed", text: "Lifeline Contact Failed"},
            {id: "Logged Out", text: "Logged Out"},
            {id: "Bypassed", text: "Bypassed"},
            {id: "Invalid Token", text: "Invalid Token"},
            {id: "Error", text: "Error"},
            {id: "Expired Token", text: "Expired Token"},
            {id: "Frequency Bypassed", text: "Frequency Bypassed"},
            {id: 'Active with Lifeline', text: 'Active with Lifeline'},
            {id: 'Active with Dropoff', text: 'Active with Dropoff'},
            {id: 'Active with Error', text: 'Active with Error'},
            {id: "Lifeline - Search", text: "Lifeline - Search"},
            {id: "Lifeline - No Match", text: "Lifeline - No Match"},
          ]},
        {model: "entered_input__contains", filter: "entered_input__contains", type: "input", label: "Key Prompts", default: "",
          options: []},
      ]
    ]
  };

  public summary: any = null
  public summaryFields: string[] = []
  public orderOptionsBreakdown: any = []
  public mainMenuBreakdown: any = []
  public self_service_rate = 0


  protected fieldData = reportsFieldData;

  public state = {}

  public dateFilterTypes = [
    {value: 'session', text: 'Session'},
    {value: 'customer', text: 'Customer'}
  ];
  public dateFilterTypeDefaultValue = 'session';

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: SessionSummaryReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService, campaignService,
      reportsConfigService, trackingSourceService, userService, accountService, campaignProductService,
      crmCampaignService, storageService, stepService, refundReportService, merchantService);
  }

  getDefaultDateRange() {
    return {startDate: moment().subtract(8, 'days'),
      endDate: moment().subtract(1, 'days')}
  }

  getSummary() {
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getSummary(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe((result: {session_results: {}, self_service_rate: number[]}) => {
        if (result) {
          this.summary = result['session_results']
          this.summaryFields = Object.keys(this.summary)
          if (result['self_service_rate'] && result['self_service_rate'].length === 2 && result['self_service_rate'][1] > 0) {
            this.self_service_rate = (result['self_service_rate'][0] / result['self_service_rate'][1]) * 100
          }
          this.loading = false
        }
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  getOrderOptionsBreakdownSummary() {
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getOrderOptionsBreakdown(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.loading = false
        if (result && Array.isArray(result)) {
          this.orderOptionsBreakdown = result
        }
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  getMainMenuBreakdownSummary() {
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getMainMenuBreakdown(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.loading = false
        if (result && Array.isArray(result)) {
          this.mainMenuBreakdown = result
        }
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  getFieldLabel(field: string) : string {
    const control = this.fieldData[field]
    if (!control) {
      return ''
    }
    return control.label;
  }

  getSummaryFieldValue(field: string) {
    return this.summary[field]
  }

  getFieldValue(row, field: string) {
    let value = row[field]
    if (this.fieldData[field] && 'getter' in this.fieldData[field]) {
      value = this.fieldData[field].getter(row, value);
    }

    if (field === 'step_category' && value === 'Sub Path') {
      value = 'My Orders'
    }

    return value;
  }


  getTotal(row) {
    let total: any = 0
    if (row !== undefined){
      total = Object.values(row).reduce((acc: number, cur: number) => acc + cur, 0);
    }
    return total
  }

  getSummaryFieldRate(field: string) {
    let total = this.getTotal(this.summary)
    let fieldValue = this.summary[field]

    if (total && fieldValue) {
      return ((fieldValue / total) * 100).toFixed(1)
    }
  }

  populateDeviceTypesFilter(date_range) {
    let accounts = [];
    this.form.value.account.forEach(item => {
      accounts.push(item.id);
    })

    let queryParams = {account_id: accounts, page: 1, page_size: config.maxPageSize,
      start_date: normalizeMomentForQuery(date_range['startDate'], this.form.value.timezone),
      end_date: normalizeMomentForQuery(date_range['endDate'], this.form.value.timezone)};
    let deviceTypes = [];
    this.loading = true
    this.reportsService.getDeviceTypes(queryParams)
      .subscribe(data => {
        this.loading = false
        if (data) {
          data.forEach(item => {
            deviceTypes.push({id: item, text: item})
          })
          this.filtersDynamicOptions['device_type'] = deviceTypes;
        }
      }, error => {this.handleError(error)})
  }

  initializeStepsFilters(date_range) {
    super.initializeStepsFilters(date_range);
    this.getSummary()
    this.getMainMenuBreakdownSummary()
    this.getOrderOptionsBreakdownSummary()
    this.populateDeviceTypesFilter(date_range)
  }

  setState(){
    let formData = this.form.value;
    formData['intent'] = ReportIntent.General
    formData['startDate'] = moment(formData.date_range.startDate).format()
    formData['endDate'] = moment(formData.date_range.endDate).format()
    formData['date_range']['startDate'] = moment(formData.date_range.startDate).format()
    formData['date_range']['endDate'] = moment(formData.date_range.endDate).format()

    this.state = {
      'load_as_configuration': true,
      'config': {
        'name': "",
        'user': null,
        'is_global': false,
        'configuration': formData
      }
    }
  }

  selectCustomerMode(event) {
    super.selectCustomerMode(event)
    this.getSummary()
    this.getOrderOptionsBreakdownSummary()
  }
}
