// sso-flag.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {CRMService} from './_services/crm.service';
@Injectable({
  providedIn: 'root',
})
export class SsoFlagGuard implements CanActivate {
  constructor(private router: Router, private crmService: CRMService,) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const queryParams = route.queryParams;
    if (queryParams.sso_flag === 'true') {
      document.body.classList.add('no-scroll');
      if (this.crmService.connectShopifyApp()){
        return false;
      }
    }
    return true; 
  }
}