import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceTransactionService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {Transaction, TransactionType} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'invoice-transaction-form',
  templateUrl: './invoice-transaction-edit.component.html'
})
export class InvoiceTransactionEditComponent extends CrudSavePopupComponent implements OnInit {
  transaction: Transaction;
  transactionTypes: { value: TransactionType, label: string }[] = [
    {value: TransactionType.Charge, label: 'Charge'},
    {value: TransactionType.UsageCharge, label: 'Usage Charge'},
    {value: TransactionType.SubscriptionAdjustment, label: 'Subscription Adjustment'}
  ];

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected transactionService: InvoiceTransactionService,
              protected alertService: AlertService
  ) {
    super(router, location, route, transactionService, alertService);
    this.objectName = 'Transaction';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      type: [null, Validators.required],
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.transaction = this.inputData;
  }
}
