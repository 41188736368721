import { Component, OnInit } from '@angular/core';
import {
  Bank,
  BankAccessLevel,
  BankAccessLevelLabels,
  BankAccessLevelsList,
  BankContactTitle,
  BankContactTitleLabels,
  BankContactTitleList,
  BankInfoFields,
  BankStatus,
  BusinessTypeLabels,
  BusinessType,
  BusinessTypesList,
  CheckoutTypeLabels,
  CheckoutType,
  CheckoutTypesList,
  ContactInfoFields,
  PermissionType,
  Processor,
  ProductTypeLabels,
  ProductType,
  ProductTypesList,
  ProfileTab,
} from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CrudSaveComponent } from '../../../_directives';
import { Location } from '@angular/common';
import { AlertService, CheckoutBankService, RegionService } from '../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { Region, User } from '../../../_models';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-checkout-bank-new',
  templateUrl: './checkout-bank-edit.component.html',
  styleUrls: ['./checkout-bank-edit.component.scss', '../profiles-styles.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CheckoutBankNewComponent extends CrudSaveComponent implements OnInit {
  ProfileTabs = ProfileTab;
  PermissionTypes = PermissionType;
  activeTab = ProfileTab.BankInformation;

  public bank: Bank | Processor;
  public bankSummary = [];
  public contactSummary = [];
  public permissionSummary = [];

  permissions = {
    [PermissionType.BusinessType]: Object.values(BusinessTypesList),
    [PermissionType.ProductType]: Object.values(ProductTypesList),
    [PermissionType.CheckoutType]: Object.values(CheckoutTypesList),
  };

  selected_permissions: any = {
    [PermissionType.BusinessType]: [],
    [PermissionType.ProductType]: [],
    [PermissionType.CheckoutType]: [],
  };

  temp_selected_permissions = {
    [PermissionType.BusinessType]: [],
    [PermissionType.ProductType]: [],
    [PermissionType.CheckoutType]: [],
  };

  fields_to_permission_types_map = {
    business_types: PermissionType.BusinessType,
    product_types: PermissionType.ProductType,
    checkout_types: PermissionType.CheckoutType,
  };

  expandedStates = {
    [PermissionType.BusinessType]: true,
    [PermissionType.ProductType]: false,
    [PermissionType.CheckoutType]: false,
  };

  public isExclude: boolean = false;

  users: User[] = [];
  isPDFLoading: boolean = false;

  accessLevels: BankAccessLevel[] = BankAccessLevelsList;
  contactTitles: BankContactTitle[] = BankContactTitleList;

  states: Region[];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected bankService: CheckoutBankService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected regionService: RegionService
  ) {
    super(router, location, route, bankService, alertService);
    this.isNew = true;
    this.objectName = 'bank profile';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      address1: [null, [Validators.required]],
      address2: [null, null],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      zip: [null, [Validators.required]],
      country: ['us', [Validators.required]],
      phone: [null, [Validators.minLength(12)]],
      fax: [null, null],
      email: [null, [Validators.email]],
      mcc_code_type: [null, null],
      risk_contact: [null, [Validators.minLength(12)]],
      exclude_types: [null, null],
      about: [null, null],
      status: [BankStatus.Active, null],
      primary_contact: this.formBuilder.group({
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        phone: [null, [Validators.required, Validators.minLength(12)]],
        access_level: [BankAccessLevel.All, [Validators.required]],
        contact_title: [BankContactTitle.Owner, [Validators.required]],
      }),
    });
    this.addExtraField();
    super.ngOnInit();
    this.updateStates();
  }

  protected updateStates() {
    this.states = [];
    let country = this.form.value.country;
    this.regionService.list(country).subscribe(
      (states: Region[]) => {
        this.states = states;

        if (this.states && this.states.length) {
          this.form.controls['state'].setValidators([Validators.required]);
        } else {
          this.form.controls['state'].setValidators([]);
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  addExtraField() {
    this.form.addControl('bid', this.formBuilder.control(null, [Validators.required]));
  }

  protected getFormData(): any {
    let data = super.getFormData();

    Object.keys(this.fields_to_permission_types_map).forEach((field_name) => {
      let permission_type = this.fields_to_permission_types_map[field_name];
      data[field_name] = this.selected_permissions[permission_type];
    });

    data['exclude_types'] = this.isExclude;

    return data;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      super.onSubmit();
    } else {
      this.navigateToErrorTab();
    }
  }

  protected navigateToErrorTab() {
    const bankInfoFields = Object.values(BankInfoFields);
    const contactInfoFields = Object.values(ContactInfoFields);

    let bankError = false;
    let contactError = false;

    bankInfoFields.forEach((field) => {
      const control = this.form.get(field);
      if (control && control.errors) {
        control.markAsTouched();
        control.markAsDirty();
        bankError = true;
      }
    });

    if (bankError) {
      this.activeTab = ProfileTab.BankInformation;
      return;
    }

    contactInfoFields.forEach((field) => {
      const control = this.form.get(field);
      if (control && control.errors) {
        control.markAsTouched();
        control.markAsDirty();
        contactError = true;
      }
    });

    if (contactError) {
      this.activeTab = ProfileTab.ContactInformation;
    }
  }

  downloadPDF(content: string): void {
    const data = document.getElementById(content);
    if (data) {
      this.isPDFLoading = true;
      data.querySelectorAll('.status > span').forEach((span) => ((span as HTMLElement).style.paddingBottom = '5px'));

      html2canvas(data).then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pdfName = content.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        const xOffset = (pdf.internal.pageSize.getWidth() - pdf.getTextWidth(pdfName)) / 2;

        pdf.setFontSize(18).text(pdfName, xOffset, 10);
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 15, imgWidth, imgHeight);
        pdf.save(`${content}.pdf`);
        this.isPDFLoading = false;
      });
    }
  }

  selectAllStates(permission_type: PermissionType) {
    const allSelected = this.selected_permissions[permission_type].length === this.permissions[permission_type].length;

    if (allSelected) {
      this.selected_permissions[permission_type] = [];
    } else {
      this.selected_permissions[permission_type] = [...this.permissions[permission_type]];
    }
  }
  getPermissions(permission_type: PermissionType) {
    return this.permissions[permission_type];
  }

  allPermissionsSelected(permission_type: PermissionType): boolean {
    return this.selected_permissions[permission_type].length === this.permissions[permission_type].length;
  }

  isPermissionSelected(permission_type: PermissionType, permission: BusinessType | CheckoutType | ProductType) {
    return this.selected_permissions[permission_type].indexOf(permission) > -1;
  }

  getPermissionLabel(permission_type: PermissionType, permission: BusinessType | CheckoutType | ProductType) {
    let label = '';

    switch (permission_type) {
      case PermissionType.BusinessType:
        label = BusinessTypeLabels[permission];
        break;
      case PermissionType.ProductType:
        label = ProductTypeLabels[permission];
        break;
      case PermissionType.CheckoutType:
        label = CheckoutTypeLabels[permission];
        break;
      default:
        break;
    }

    return label;
  }

  setActiveTab(tab: ProfileTab) {
    this.activeTab = tab;
  }

  // Permission Tab Functions
  isExpanded(index: PermissionType): boolean {
    return this.expandedStates[index];
  }

  toggleExpand(index: PermissionType) {
    this.expandedStates[index] = !this.expandedStates[index];
  }

  createBankUser() {
    this.router.navigate(['/checkouts', 'bank-profiles', 'user', 'new']);
  }

  toggleCheckbox(group: PermissionType, permission: BusinessType | ProductType | CheckoutType) {
    if (this.isPermissionSelected(group, permission)) {
      let idx = this.selected_permissions[group].indexOf(permission);
      this.selected_permissions[group].splice(idx, 1);
    } else {
      this.selected_permissions[group].push(permission);
    }
  }

  toggleState() {
    Object.keys(this.selected_permissions).forEach((permission_type) => {
      const temp_permissions = [...this.temp_selected_permissions[permission_type]];
      this.temp_selected_permissions[permission_type] = [...this.selected_permissions[permission_type]];
      this.selected_permissions[permission_type] = temp_permissions;
    });
    this.isExclude = !this.isExclude;
  }

  getAccessLevelLabel(access_level: BankAccessLevel) {
    return BankAccessLevelLabels[access_level];
  }

  getContactTitleLabel(title: BankContactTitle) {
    return BankContactTitleLabels[title];
  }

  getPermissionsList(type: PermissionType, permissions: number[]): string {
    return permissions.length > 0 ? permissions.map((perm) => this.getPermissionLabel(type, perm)).join(', ') : '--';
  }
}
