import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignTrackingService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {TrackingSource} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-tracking-form',
  templateUrl: './campaign-tracking-form.component.html'
})
export class CampaignTrackingFormComponent extends CrudSavePopupComponent implements OnInit {
  trackingSource: TrackingSource;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected trackingService: CampaignTrackingService,
              protected alertService: AlertService
  ) {
    super(router, location, route, trackingService, alertService);
    this.objectName = 'Brand Tracking Source';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      campaign: [null, Validators.required],
      source: [null, Validators.required]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.trackingSource = this.inputData;
    this.isNew = !this.trackingSource || !this.trackingSource.id;
  }

}
