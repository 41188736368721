import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Form} from '../_forms';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CRMField, TicketSystemField} from '../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'ticket-system-fields',
  templateUrl: './ticket-system-fields.component.html'
})

export class TicketSystemFieldsComponent extends Form implements OnInit, OnChanges {
  @Input('formGroup') formGroup: FormGroup;
  @Input('groupName') groupName: string;
  @Input('fields') inputFields: TicketSystemField[];
  @Input('values') values: {[key: string]: string;};
  fields: TicketSystemField[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.updateForm()
  }

  ngOnChanges() {
    this.updateForm();
  }

  updateForm() {
    if (this.formGroup) {
      let fields = [];
      //remove the current fields
      for (let field in this.formGroup.controls) {
        this.formGroup.removeControl(field);
      }
      //add the fields to the form group
      if (this.inputFields) {
        this.inputFields.forEach((field: CRMField) => {
          let defaultValue = field.default || null;
          if (this.values && field.name in this.values) {
            defaultValue = this.values[field.name];
          }
          this.formGroup.addControl(field.name,
            new FormControl(defaultValue, field.required ? Validators.required : []));
        });
        fields = this.inputFields;
      }
      this.fields = fields;
    }
  }

  getFieldLabel(field: TicketSystemField) {
    if (field.label) {
      return field.label;
    }
    if (field.name.length > 0) {
      return 'API ' + field.name[0].toUpperCase() + field.name.slice(1).toLowerCase().replace('_', ' ');
    }
    return field.name;
  }

  getFieldType(field: TicketSystemField) {
    return field.type || 'text';
  }

  protected findFieldControl(field: string) {
    return this.formGroup.get(field)
  }
}
