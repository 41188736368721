import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from "@angular/common";
import {AvailableFunnelItemStatuses, Campaign, CRMTypeId, Pager, User, normalizeMomentForQuery} from '../_models';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {AlertService, CampaignService, UserService} from '../_services';
import * as moment from "moment";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: 'visitors.component.html',
  styleUrls: ['./visitors.component.css'],
  selector: 'app-visitors'
})
export class VisitorsComponent extends Form implements OnInit {
  sessionFilter = null;
  user: User;

  public alwaysShowCalendars = true;
  public ranges = {
    'Today': this.setDateRangeToToday(),
    'Yesterday': this.setDateRangeToYesterday(),
    'Past 7 Days': this.setDateRangeToLastNDays(7),
    'Month to Date': this.setDateRangeToCurrentMonth(),
    'Last Month': this.setDateRangeToLastMonth(),
    'Last 30 days': this.setDateRangeToLastNDays(31),
    'Last 3 Months to Date': this.setDateRangeToNPastMonthsToDate(3),
    'Last 6 Months to Date': this.setDateRangeToNPastMonthsToDate(6),
    'Last 12 Months to Date': this.setDateRangeToNPastMonthsToDate(12),
    'Year to Date': this.setDateRangeToCurrentYear(),
    'Last Year': this.setDateRangeToLastYear()
  };
  public maxDate = moment();

  public campaigns: Campaign[] = [];
  public orderStatuses = AvailableFunnelItemStatuses

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    private campaignService: CampaignService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    super(alertService, router, location);
    this.title = 'Search for Sessions';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      first_name: [null],
      last_name: [null],
      order_id: [null],
      phone: [null],
      date_range: new FormControl(null, null),
      email: [null, [Validators.email]],
      order_status: [null],
      campaign: [null],
      username: [null],
      id: [null]
    });
    super.ngOnInit();

    this.campaignService.hideMenu();

    this.campaignService.list({})
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: Pager) => {
        this.campaigns = data.results;
      }, error => {
        this.handleError(error);
      });

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  search() {
    let filter = {'started': true};

    for (let field in this.form.controls) {
      let val = this.form.get(field).value;
      if (field === "date_range" && (val !== null && (val.startDate === null || val.endDate === null))) {
        val = null;
      }

      if (val || val === 0) {
        switch (field) {
          case 'date_range':
            let startDate = val.startDate;
            let endDate = val.endDate;
            if (startDate.isSame(endDate)) {
              endDate = endDate.add(24, 'hours')
            }
            filter['date_joined__gte'] = normalizeMomentForQuery(val.startDate);
            filter['date_joined__lt'] = normalizeMomentForQuery(val.endDate);
            break;

          case 'first_name':
            filter['customer__first_name__iexact'] = val;
            break;

          case 'last_name':
            filter['customer__last_name__iexact'] = val;
            break;

          case 'order_id':
            filter['customer__orders__order_id__iexact'] = val;
            break;

          case 'email':
            filter['customer__email__iexact'] = val;
            break;

          case 'campaign':
            filter['campaign__id'] = val;
            break;

          case 'phone':
            filter['phone'] = val;
            break;

          case 'order_status':
            filter['session_inputs__funnel_item_status'] = val;
            break;

          case 'username':
            filter['username'] = 'session_' + val;
            break;

          case 'id':
            filter['id'] = val;
            break;
        }
      }
    }

    if (!this.form.value.test_mode) {
      filter['customer__crm__type!'] = CRMTypeId.Test;
    }

    this.sessionFilter = {...filter};
  }

  setDateRangeToToday() {
    return [new Date(), new Date()];
  }

  setDateRangeToYesterday() {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    return [date, date]
  }

  setDateRangeToLastNDays(numDays: number) {
    let date = new Date();

    date.setDate((date.getDate() + 1) - numDays);

    return [date, new Date()];
  }

  setDateRangeToLastMonth() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1st of last month
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);

    //end date = last day of previous month
    endDate.setDate(0);

    return [startDate, endDate];
  }

  setDateRangeToCurrentMonth() {
    return this.setDateRangeToNPastMonthsToDate(0);
  }

  setDateRangeToNPastMonthsToDate(numPastMonths: number) {
    let date = new Date();

    date.setDate(1);
    date.setMonth(date.getMonth() - numPastMonths);

    return [date, new Date()];
  }

  setDateRangeToCurrentYear() {
    let date = new Date();

    date.setDate(1);
    date.setMonth(0);

    return [date, new Date()];
  }

  setDateRangeToLastYear() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1/1 of last year
    startDate.setMonth(0);
    startDate.setDate(1);
    startDate.setFullYear(startDate.getFullYear() - 1);

    //end date = 12/31 of last year
    endDate.setMonth(11);
    endDate.setDate(31);
    endDate.setFullYear(endDate.getFullYear() - 1);

    return [startDate, endDate];
  }

  keyPressed(e) {
    if (e.keyCode === 13) {
      this.search();
      return false;
    }
  }

}
