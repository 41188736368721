import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  Bank,
  ContactInfoFields,
  ProcessorDocument,
  ProcessorDocumentStatus,
  ProcessorDocumentStatusLabels,
  ProcessorDocumentType,
  ProcessorDocumentTypeLabels,
  ProcessorInfoFields,
  ProfileTab,
} from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CheckoutBankNewComponent } from '../bank/checkout-bank-new.component';
import { Location } from '@angular/common';
import {
  AlertService,
  CheckoutBankService,
  CheckoutProcessorService,
  ProcessorDocumentService,
  RegionService,
} from '../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { config } from '../../../../config/config';
import { Pager } from '../../../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { ProcessorDocumentPopupComponent } from './processor-document-popup.component';

@Component({
  selector: 'app-checkout-processor-new',
  templateUrl: './checkout-processor-edit.component.html',
  styleUrls: ['./checkout-processor-edit.component.scss', '../profiles-styles.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutProcessorNewComponent extends CheckoutBankNewComponent implements OnInit {
  acquiring_banks: any[] = [];
  selectedBanks: any[] = [];
  dropdownSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false,
    idField: 'id',
    textField: 'text',
  };
  processorDocumentStatus = ProcessorDocumentStatus;
  activeTab = ProfileTab.ProcessorInformation;
  documents: ProcessorDocument[] = [];
  selectedDocumentIndex: number = null;
  selectedDocument: ProcessorDocument;
  acquiredBanks: any[] = [];

  @ViewChild('documentPopup', { static: false }) documentPopup: ProcessorDocumentPopupComponent;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected processorService: CheckoutProcessorService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected bankService: CheckoutBankService,
    protected modalService: NgxSmartModalService,
    protected documentService: ProcessorDocumentService,
    protected regionService: RegionService
  ) {
    // @ts-ignore
    super(router, location, route, processorService, alertService, formBuilder, regionService);
    this.isNew = true;
    this.objectName = 'processor';
  }

  ngOnInit() {
    super.ngOnInit();

    this.bankService.list({ page: 1, page_size: config.maxPageSize }).subscribe(
      (data: Pager) => {
        if (data) {
          const banks = [];
          data.results.forEach((item: Bank) => {
            banks.push({ id: item.id, text: item.name });
          });
          this.acquiring_banks = banks;
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  addExtraField() {
    this.form.addControl('acquiring_banks', this.formBuilder.control([], [Validators.required]));
  }

  protected getFormData(): any {
    let data = super.getFormData();

    let acquiring_banks = [];
    data['acquiring_banks'].forEach((item: any) => {
      acquiring_banks.push(item['id']);
    });

    data['acquiring_banks'] = acquiring_banks;
    return data;
  }

  selectDocument(document: ProcessorDocument, index: number) {
    this.selectedDocumentIndex = index;
    this.selectedDocument = document;
    this.modalService.getModal('documentModal').open();
  }

  closeDocumentModal() {
    this.selectedDocument = null;
    if (this.documentPopup) {
      this.documentPopup.resetFields();
    }
    this.modalService.getModal('documentModal').close();
  }

  protected navigateToErrorTab() {
    const processorInfoFields = Object.values(ProcessorInfoFields);
    const contactInfoFields = Object.values(ContactInfoFields);

    let processorError = false;
    let contactError = false;

    processorInfoFields.forEach((field) => {
      const control = this.form.get(field);
      if (control && control.errors) {
        control.markAsTouched();
        control.markAsDirty();
        processorError = true;
      }
    });

    if (processorError) {
      this.activeTab = ProfileTab.ProcessorInformation;
      return;
    }

    contactInfoFields.forEach((field) => {
      const control = this.form.get(field);
      if (control && control.errors) {
        control.markAsTouched();
        control.markAsDirty();
        contactError = true;
      }
    });

    if (contactError) {
      this.activeTab = ProfileTab.ContactInformation;
    }
  }

  onDocumentSaved(document: ProcessorDocument) {
    this.documents[this.selectedDocumentIndex] = document;
    this.selectedDocument = null;
    this.selectedDocumentIndex = this.documents.length;
    this.modalService.getModal('documentModal').close();
  }

  getDocumentTypeLabel(type: ProcessorDocumentType) {
    return ProcessorDocumentTypeLabels[type];
  }

  getDocumentStatusLabel(status: ProcessorDocumentStatus) {
    return ProcessorDocumentStatusLabels[status];
  }

  deleteDocument(id) {
    if (window.confirm('Are you sure you want to delete this document?')) {
      this.loading = true;

      this.documentService
        .delete(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (result) => {
            this.alertService.success('Document deleted');
            this.documents = this.documents.filter((data) => data.id !== id);
            this.loading = false;
          },
          (error) => {
            this.handleSubmitError(error);
            this.loading = false;
          }
        );
    }
  }

  getAcquiringBanks(acquiringBanks: any[]) {
    const acquiredBanks = acquiringBanks.length ? acquiringBanks : this.acquiring_banks;
    const acqBanks = acquiredBanks.map((item) => item.text);
    return acqBanks.length > 0 ? acqBanks.join(', ') : '--';
  }

  convertBytes(bytes: number): string {
    if (!bytes) return '--';

    const mb = bytes / (1024 * 1024);
    if (mb >= 1) {
      return `${mb.toFixed(1)}Mb`;
    } else {
      const kb = bytes / 1024;
      return `${kb.toFixed(1)}Kb`;
    }
  }

  createProcessorUser() {
    this.router.navigate(['/checkouts', 'processor-profiles', 'user', 'new']);
  }

  formatDate(dateString: string): string {
    const dateMoment = moment(dateString).tz('EST');
    return dateMoment.format('DD MMM, YYYY');
  }

  formatTime(dateString: string): string {
    const dateMoment = moment(dateString).tz('EST');
    return dateMoment.format('HH:mm:ss z');
  }
}
