import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private loaderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  loader$: Observable<boolean> = this.loaderSubject.asObservable();
  private backgroundColor = "rgba(255, 255, 255, 0.7)";
  private text = "";
  private zIndex = 10000;

  constructor() {}

  getLoaderState(): boolean {
    return this.loaderSubject.getValue();
  }

  show(backgroundColor?: string, text?: string, zIndex?: number) {
    this.loaderSubject.next(true);
    if (backgroundColor) this.backgroundColor = backgroundColor;
    if (text) this.text = text;
    if (zIndex) this.zIndex = zIndex;
  }

  hide() {
    this.loaderSubject.next(false);
    this.backgroundColor = "rgba(255, 255, 255, 0.7)";
    this.text = "";
  }

  getLoaderBackground(): string {
    return this.backgroundColor;
  }

  getLoaderText(): string {
    return this.text;
  }

  getLoaderZIndex(): number {
    return this.zIndex;
  }
}
