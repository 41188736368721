import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  CRMCampaignService,
  CRMService,
  CampaignService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CrudPagedListComponent} from '../_directives';
import {
  CRM,
  CRMTypeId,
  CRMCampaign,
  Campaign,
  Pager
} from '../_models';
import {Observable} from "rxjs";
import {config} from '../../config/config';
import {takeUntil} from "rxjs/operators";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './crm-campaign.component.html',
  styleUrls: ['./crm-campaign.component.css']
})
export class CRMCampaignComponent extends CrudPagedListComponent implements OnInit {
  crmCampaigns$: Observable<CRMCampaign[]> = this.data$;
  crms: CRM[];
  crmNames: {} = {};
  campaigns: Campaign[];
  campaignNames: {} = {};
  loading = false;
  bulkIds = [];
  selectedCRMCampaign: CRMCampaign | null = null;
  idPrefix = '';
  allSelected = false;
  @Input('crmId') defaultCRMId: string | number;

  protected selectedCampaignsMap: {} = {};
  pageSize = 50;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected crmCampaignService: CRMCampaignService,
    private crmService: CRMService,
    private campaignService: CampaignService,
    private modalService: NgxSmartModalService,
    private formBuilder: FormBuilder
  ) {
    super(router, location, route, crmCampaignService, alertService);
    this.objectName = 'campaign';
    this.title = 'Campaigns';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      crm: [this.defaultCRMId ? this.defaultCRMId : null],
      campaign: [null],
      name__icontains: [null],
      crm_campaign_id: [null]
    }));

    super.ngOnInit();

    this.crmService.list({page: 1, page_size: config.maxPageSize, 'type!': CRMTypeId.Test})
      .subscribe(
      (data: Pager) => {
        if (data) {
          this.crms = data.results;

          this.crms.forEach((crm: CRM) => {
            this.crmNames[crm.id.toString()] = crm.name;
          });

          if (this.crms.length == 1) {
            // we only have 1 crm, so select it as the default
            this.defaultCRMId = this.crms[0].id.toString();
            this.form.patchValue({crm: this.crms[0].id});
          }
        }
      },
      error => {
        this.handleError(error);
      }
    );

    this.campaignService.list({page: 1, page_size: config.maxPageSize})
      .subscribe(
        (data: Pager) => {
          if (data) {
            this.campaigns = data.results;

            this.campaigns.forEach((campaign: Campaign) => {
              this.campaignNames[campaign.id.toString()] = campaign.name;
            });
          }
        },
        error => {
          this.handleError(error);
        }
      );

    this.queryData();
  }

  get editMode() : boolean {
    return !!this.bulkIds.length || !!this.selectedCRMCampaign;
  }

  protected onRouteParams(params: {}) {
    this.selectedCampaignsMap = {};

    if ('crm' in params) {
      this.defaultCRMId = params['crm'];
      this.form.patchValue({crm: this.defaultCRMId});
    }
  }

  protected onFilterFieldChange(field: string, value) {
    if (field === 'crm') {
      this.setTitleFromCRM(value)
    }
  }

  protected setTitleFromCRM(crm) {
    if (crm) {
      crm = crm.toString();
    }

    if (crm && crm in this.crmNames) {
      this.title = this.crmNames[crm] + ' Campaigns';
    } else {
      this.title = 'All Campaigns';
    }
  }

  isEnabled(campaign: CRMCampaign) {
    return campaign.id in this.selectedCampaignsMap;
  }

  enable(campaign: CRMCampaign, event) {
    const enabled: boolean = event.target.checked;

    if (enabled) {
      this.selectedCampaignsMap[campaign.id] = null; // we don't care about the value, just the key
    } else if (campaign.id in this.selectedCampaignsMap) {
      delete this.selectedCampaignsMap[campaign.id];
    }
  }

  selectAll(event) {
    const enabled: boolean = event.target.checked;

    if (enabled) {
      // Select all rows
      this.crmCampaignService.getAllIds(this.getQueryFilter())
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (data: Array<string | number>) => {
            data.forEach(id => {
              if (!(id in this.selectedCampaignsMap)) {
                this.selectedCampaignsMap[id] = null; // we don't care about the value, just the key
              }
            });

            this.allSelected = true;
          },
          error => {
            this.handleError(error);
          });
    } else {
      // Unselect all
      this.allSelected = false;
      this.selectedCampaignsMap = {};
    }
  }

  canBulkEdit() {
    return this.selectedCampaignIds.length > 1;
  }

  bulkEdit() {
    this.selectedCRMCampaign = null;
    this.bulkIds = this.selectedCampaignIds;
    this.openEditCRMCampaignDialog();
  }

  editCRMCampaign(campaign: CRMCampaign) {
    this.selectedCRMCampaign = campaign;
    this.openEditCRMCampaignDialog();
  }

  protected openEditCRMCampaignDialog() {
    this.modalService.getModal('editCRMCampaignDialog').open();
  }

  onSaveCRMCampaign(campaign: CRMCampaign) {
    this.modalService.getModal('editCRMCampaignDialog').close();
    this.queryData();
  }

  onCloseCRMCampaignDialog() {
    this.selectedCRMCampaign = null;
    this.bulkIds = [];
  }

  get selectedCampaignIds() {
    return Object.keys(this.selectedCampaignsMap);
  }

}
