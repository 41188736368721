import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, UserService, RegionService } from '../_services';
import { FormBuilder, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { Form } from '../_forms';
import { Region } from '../_models';
import { CRMChoices, SupportTickets } from "./enums";

function zipCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return;
    }
    const zipCodePattern = /^\d{5}(?:-\d{4})?$/;
    if (!zipCodePattern.test(value)) {
      return { invalidZipCode: true };
    }
  };
}

@Component({
  moduleId: module.id.toString(),
  styles: ['.h-100 { height: auto !important;}  .elisyam-bg.background-05 { background-position: center; } .register { padding-bottom: 20px; }'],
  templateUrl: 'register.component.html'
})

export class RegisterComponent extends Form implements OnInit {
  loading = false;
  countries: Region[];
  states: Region[];
  country: string = 'us';
  defaultFilterSettings = {
    singleSelection: false,
    allowSearchFilter: false,
    enableCheckAll: false,
    idField: 'key',
    textField: 'value',
  };

  supportEmailItems = [
    {key: "IVR Support Email", value: "IVR Support Email"},
    {key: "IVR Support for Phone", value: "IVR Support for Phone"},
    {key: "Prospect Chaser", value: "Prospect Chaser"},
  ]

  crmChoices = CRMChoices
  supportTicketChoices = SupportTickets

  stepsStore = {
    previous: 0,
    current: 1,
    next: 2
  }

  totalFormSteps = 3;
  stepOne: any = ['first_name','last_name','phone','email','password','cpassword'];
  stepTwo: any = ['account.name','account.default_address.address1','account.default_address.city','account.default_address.state','account.default_address.zip'];
  lastStep: any = ['account.website','account.current_crm', 'account.industry','account.monthly_support_tickets'];

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    private userService: UserService,
    private regionService: RegionService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      cpassword: [null, [Validators.required, Validators.minLength(8)]],
      phone: [null, Validators.required],
      account: this.formBuilder.group({
        name: [null, Validators.required],
        website: [null, Validators.required],
        current_crm: [null, Validators.required],
        monthly_support_tickets: [null, Validators.required],
        industry: [null, Validators.required],
        default_address: this.formBuilder.group({
          address1: [null, Validators.required],
          address2: [null],
          city: [null, Validators.required],
          state: [null, Validators.required],
          zip: [null, [Validators.required, Validators.maxLength(8), zipCodeValidator()]],
          country: ['us', Validators.required]
        })
      })
    }, {validators: this.mustMatch('password', 'cpassword')});
    this.updateStates();
    this.regionService.getCountries()
      .subscribe(
        (countries: Region[]) => {
          this.countries = countries;
        }
      )
  }

  protected updateStates() {
    this.states = [];
    this.regionService.list(this.country)
      .subscribe(
        (states: Region[]) => {
          this.states = states;

          if (this.states && this.states.length) {
            this.form.controls['state'].setValidators([Validators.required]);
          }
          else {
            this.form.controls['state'].setValidators([]);
          }
        },
        error => {
          this.handleError(error);
        }
      )
  }

  selectCountry(event) {
    if (event.target.value !== this.country) {
      this.country = event.target.value;
      this.getControl('account.default_address').patchValue({state: null})
      this.updateStates();
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  isFieldValid(name: string, markTouchedAndDirty:boolean = false): boolean {
    const control = this.getControl(name);
    if (markTouchedAndDirty) {
      control.markAsDirty()
      control.markAsTouched()
    }
    return control && control.valid && (control.touched || control.dirty);
  }

  checkingFieldValidation(array: any, markTouchedAndDirty:boolean = true) {
    return array
            .map((item:any) => this.isFieldValid(item,markTouchedAndDirty))
            .every((item: any) => item) ? true :false;
  }

  canMoveToStep(step: number) {
    switch (step) {
      case 2:
        return  this.checkingFieldValidation(this.stepOne);
      case 3:
        return  this.checkingFieldValidation(this.stepTwo);
    }
  }

  moveOneStep(forward: boolean = true) {
    if (forward) {
      let nextStep = this.stepsStore.next + 1;
      if (!this.canMoveToStep(this.stepsStore.next)) {
        return;
      }
      if (nextStep > this.totalFormSteps) {
        nextStep = this.totalFormSteps  // Is the next step is going out of bound, keep it in the bounds
      }
      this.stepsStore = {
        previous: this.stepsStore.current,
        current: this.stepsStore.next,
        next: nextStep
      };
    } else {
      let previousStep = this.stepsStore.previous - 1;
      if (previousStep < 0) {
        return;
      }
      this.stepsStore = {
        previous: previousStep,
        current: this.stepsStore.previous,
        next: this.stepsStore.current
      }
    }
  }

  moveToInvalids(error: object) {
    let errorFields = Object.keys(error);
    let firstStepErrorFields = this.stepOne.filter(field => errorFields.includes(field));
    let stepsToMoveBack = 0;
    if (firstStepErrorFields.length > 0) {
      stepsToMoveBack = 2;
    } else if (errorFields.includes('account')) {
      let accountFieldErrors = error['account'];
      if ('default_address' in accountFieldErrors) {
        stepsToMoveBack = 1;
      }
    }

    for (let i = 0; i < stepsToMoveBack; i++) {
      this.moveOneStep(false);
    }
  }

  onSubmit() {

    if(!this.checkingFieldValidation(this.lastStep)) {
      return;
    }
    this.submitted = true;
    let websiteValue = this.form.get('account.website').value;
    if (!websiteValue.startsWith('http://') && !websiteValue.startsWith('https://')) {
      this.form.get('account.website').patchValue('https://' + websiteValue);
    }
    if (this.form.valid) {
      this.loading = true;

      this.userService.create(this.form.value)
        .subscribe(
          data => {
            this.alertService.success('Registration successful.  We sent an email to ' + this.form.value.email + ' with a link to activate your account.  Please check your email and click the link before logging in.', true);
            this.router.navigate(['/user/register-success']);
          },
          error => {
            if (error.error) {
              this.moveToInvalids(error.error);
            }
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }
}
