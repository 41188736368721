import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Form} from '../_forms';
import {
  Action,
  CustomStepCategoryEnum,
  Funnel,
  FunnelInput,
  FunnelInputTypeEnum,
  FunnelStep,
  IntentConfirmationButtonText,
  OfferIntentParents,
  OfferIntents,
  OfferIntentTypeLabels,
  OfferTypes,
  Pager,
  PathNewStepSelection,
  StepCategory,
  generateStep,
} from '../_models';
import {AlertService, FunnelInputService, FunnelService, FunnelStepService, StepTemplateService,} from '../_services';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder, Validators} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {FunnelInputFieldsComponent} from '../funnel-input';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './path-step.component.html',
  styleUrls: ['./path-step.component.scss'],
  selector: 'app-path-step',
})
export class PathStepComponent extends Form implements OnInit {
  @Input('step') step: FunnelStep;
  @Input('funnel') funnel: Funnel;
  @Input('nextStepId') nextStepId: string | number;
  @Input('canBeDeleted') canBeDeleted: boolean = true;
  @Input('rejectResponseStep') rejectResponseStep: FunnelStep;
  @Input('acceptResponseStep') acceptResponseStep: FunnelStep;
  @Output('firstStepCreated') onFirstStepCreated: EventEmitter<FunnelStep[]> =
    new EventEmitter<FunnelStep[]>();
  @Output('delete') onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output('onStepLoading') onStepLoading: EventEmitter<any> = new EventEmitter<any>();

  public surveyCategories: StepCategory[] = [
    CustomStepCategoryEnum.Survey,
    CustomStepCategoryEnum.CancelOrderSurvey,
    CustomStepCategoryEnum.CancelTrialSurvey,
    CustomStepCategoryEnum.CancelSubSurvey,
    CustomStepCategoryEnum.ReturnSurvey,
    CustomStepCategoryEnum.PauseSurvey,
  ];
  protected type: FunnelInputTypeEnum;
  protected category: StepCategory;
  public stepTemplates: FunnelStep[] = [];
  public inputs: FunnelInput[] = [];
  public showFunnelInputs: boolean = false;
  public productFunnelInputsData = [];
  public selectedTemplateId: string | number = null;
  public offers: Funnel[] = [];
  public waitForChildStepsToCreate: boolean = false;
  public troubleshooters: Funnel[] = [];
  public stepTemplateId: number | string = null;
  public selectedDownsellId: number | string = null;
  public offerIntentType = OfferIntents;
  public ResponsesArray: FunnelStep[] = [];
  public PathNewStepSelection = PathNewStepSelection
  public activeStepType: PathNewStepSelection | null = null;
  public selectedInput: FunnelInput = null;
  @ViewChild(FunnelInputFieldsComponent, { static: false }) inputComponent: FunnelInputFieldsComponent;

  constructor(
    protected alertService: AlertService,
    protected router: Router,
    protected location: Location,
    protected formBuilder: FormBuilder,
    protected stepService: FunnelStepService,
    protected stepTemplateService: StepTemplateService,
    protected funnelService: FunnelService,
    protected inputService: FunnelInputService,
    protected modalService: NgxSmartModalService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        type: [this.type, Validators.required],
        category: [this.category, Validators.required],
        name: [null, Validators.required],
        label: [null],
        template: [null],
      })
    );

    this.stepService.setFunnelId(this.funnel.id);
    this.stepTemplateService
      .list({
        category__in: this.surveyCategories.join(','),
        offer_intent: this.funnel.offer_intent,
        folder__isnull: true
      })
      .subscribe((stepTemplates: Pager) => {
        this.stepTemplates = stepTemplates.results;
      });

    this.funnelService
      .list({
        offer_type: this.getOfferType(),
        offer_intent: this.funnel.offer_intent,
      })
      .subscribe((offers: Pager) => {
        this.offers = offers.results;
      });

    this.funnelService
      .list({ resourcetype: 'Troubleshooter' })
      .subscribe((troubleshooters: Pager) => {
        this.troubleshooters = troubleshooters.results;
      });

    this.stepTemplateId = this.step.template;

    this.ResponsesArray = [this.acceptResponseStep, this.rejectResponseStep]
    this.onStepSave(this.step);

    if (this.isConfirmation()) {
      this.step.content = ''
      this.stepService.get(this.step.id).subscribe((step: FunnelStep) => {
        this.step.content = step.content;
      })
    }
  }

  isUpsell() {
    return (
      this.step.type === FunnelInputTypeEnum.EnterProductFunnel &&
      this.step.category === CustomStepCategoryEnum.UpsellOrder
    );
  }

  showDragStep(stepType: PathNewStepSelection) {
    this.activeStepType = stepType;
  }

  hideDragStep(stepType: PathNewStepSelection) {
    if (this.activeStepType === stepType) {
      this.activeStepType = null;
    }
  }

  isSurvey() {
    return this.surveyCategories.indexOf(this.category) > -1;
  }

  isTroubleShooter() {
    return this.type === FunnelInputTypeEnum.EnterTroubleshooter;
  }

  isOfferSelected(offerId: number): boolean {
    return (
      this.offers.some((offer) => offer.id === offerId) ||
      !!this.selectedDownsellId
    );
  }

  isFunnelInputSelected(id: number): boolean {
    return this.productFunnelInputsData.some(
      (input) => input.productFunnel === id
    );
  }

  isEnterProductPath() {
    return this.type === FunnelInputTypeEnum.EnterProductFunnel;
  }
  getSurveyStepCategory(offerIntent: number): number {
    const surveyStepCategoryMap = {
      [OfferIntents.CancelOrder]: CustomStepCategoryEnum.CancelOrderSurvey,
      [OfferIntents.ReturnOrder]: CustomStepCategoryEnum.ReturnSurvey,
      [OfferIntents.ReturnSubscription]: CustomStepCategoryEnum.ReturnSurvey,
      [OfferIntents.ReturnTrial]: CustomStepCategoryEnum.ReturnSurvey,
      [OfferIntents.CancelTrial]: CustomStepCategoryEnum.CancelTrialSurvey,
      [OfferIntents.CancelSubscription]: CustomStepCategoryEnum.CancelSubSurvey,
      [OfferIntents.CancelSubscriptionHold]: CustomStepCategoryEnum.CancelOrderSurvey,
      [OfferIntents.CancelTrialHold]: CustomStepCategoryEnum.CancelOrderSurvey,
    };
    return surveyStepCategoryMap[offerIntent] || 0;
  }

  isConfirmation() {
    return (
      [
        FunnelInputTypeEnum.Confirmation,
        FunnelInputTypeEnum.ConfirmationNo,
      ].indexOf(this.type) > -1
    );
  }

  isResponse() {
    return this.type === FunnelInputTypeEnum.InputActionStatus;
  }

  getOfferType() {
    const downsellOfferIntents = [
      OfferIntents.CancelOrder,
      OfferIntents.CancelSubscriptionHold,
      OfferIntents.CancelTrialHold,
      OfferIntents.ReturnOrder,
      OfferIntents.CancelSubscription,
      OfferIntents.CancelTrial,
      OfferIntents.ReturnSubscription,
      OfferIntents.ReturnTrial
    ];
    const upsellOfferIntents = [OfferIntents.Upsell];

    if (
      downsellOfferIntents.indexOf(this.funnel.offer_intent) > -1 &&
      !this.isUpsell()
    ) {
      return OfferTypes.Downsell;
    }

    if (this.isUpsell()) {
      return OfferTypes.Upsell;
    }

    return OfferTypes.Support;
  }

  getPathType() {
    let offer_intent = this.funnel.offer_intent

    if (offer_intent in OfferIntentParents) {
      offer_intent = OfferIntentParents[offer_intent]
    }

    return OfferIntentTypeLabels[offer_intent];
  }

  navigateToStep(stepTemplates: FunnelStep[]) {
    if (!stepTemplates.length || !this.stepTemplateId) {
      this.redirectToCreateStep('survey')
    } else {
      const url = this.router.serializeUrl(this.router.createUrlTree([
        '/steps',
        'survey',
        'category',
        OfferTypes.Survey,
        this.getSurveyStepCategory(this.funnel.offer_intent),
        'step',
        this.stepTemplateId,
      ]));
      window.open(url, '_blank');
    }

  }

  handleEnterProductFunnelStepInputs(
    inputs: FunnelInput[],
    parentInput: FunnelInput = null
  ) {
    inputs.forEach((input) => {
      if (input.type === FunnelInputTypeEnum.EnterProductFunnel) {
        const value = {
          id: input.id,
          productFunnel:
            input.product_funnels.length > 0 ? input.product_funnels[0] : null,
          step: input.step,
        };
        if (parentInput) {
          value['parentInput'] = parentInput.label;
        }
        this.productFunnelInputsData.push(value);
      }
    });
  }

  handleSurveyInputs(inputs: FunnelInput[]) {
    const apiCalls = [];
    inputs.forEach((input: FunnelInput) => {
      if (input.next_step) {
        apiCalls.push(this.stepService.get(input.next_step));
      } else {
        const stepToCreate = this.generateStep(
          input.id,
          FunnelInputTypeEnum.EnterProductFunnel,
          FunnelInputTypeEnum.EnterProductFunnel
        );

        stepToCreate.inputs.forEach((input) => {
          if (
            [
              FunnelInputTypeEnum.AltChildExit,
              FunnelInputTypeEnum.PathNotFound,
            ].indexOf(input.type) > -1 &&
            this.nextStepId
          ) {
            input.next_step = this.nextStepId;
          }
        });

        apiCalls.push(this.stepService.create(stepToCreate));
      }
    });

    forkJoin(apiCalls).subscribe(
      (apiSteps: FunnelStep[]) => {
        if (this.waitForChildStepsToCreate) {
          this.onFirstStepCreated.emit(apiSteps);
        }

        apiSteps.forEach((step: FunnelStep) => {
          let parentInput: FunnelInput = null;
          step.previous_inputs.forEach((inputId) => {
            const parentInputObjects = inputs.filter(
              (input: FunnelInput) => input.id === inputId
            );
            if (parentInputObjects && parentInputObjects.length > 0) {
              parentInput = parentInputObjects[0];
              parentInput.next_step = step.id;
              return;
            }
          });
          this.handleEnterProductFunnelStepInputs(step.inputs, parentInput);
        });
        this.loading = false;
      },
      (error) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  processStepInputs(inputs: FunnelInput[]) {
    if (this.isSurvey()) {
      this.handleSurveyInputs(inputs);
    }

    if (this.isConfirmation()) {
      this.inputs = inputs;
    }

    if (this.isTroubleShooter() || this.isEnterProductPath()) {
      this.inputs = inputs.filter(
        (input) =>
          [
            FunnelInputTypeEnum.EnterTroubleshooter,
            FunnelInputTypeEnum.EnterProductFunnel,
          ].indexOf(input.type) > -1
      );
    }
  }

  onTemplateChanged(event) {
    const template_id = event.target.value;
    this.stepTemplateId = template_id;
    if (template_id === this.selectedTemplateId) {
      return;
    }
    this.loading = true;
    this.stepTemplateService.get(template_id).subscribe(
      (template: FunnelStep) => {
        let stepTemplate: FunnelStep;
        if (template.static_data) {
          const { static_data, ...restData } = template;
          const staticData = {
            embed_code: static_data.embed_code || null,
            image: (static_data.image && static_data.image.id) || null,
            image_mobile:
              (static_data.image_mobile && static_data.image_mobile.id) || null,
            image_tablet:
              (static_data.image_tablet && static_data.image_tablet.id) || null,
            image_type: static_data.image_type,
          };
          //@ts-ignore
          stepTemplate = { ...restData, static_data: staticData };
        } else {
          stepTemplate = template;
        }

        if (stepTemplate) {
          this.generateStepDataFromTemplate(stepTemplate);
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  generateStep(
    parent_input: string | number,
    type: FunnelInputTypeEnum,
    category: StepCategory = CustomStepCategoryEnum.Custom
  ) {
    const step = generateStep(type, this.funnel, category);
    step['parent_input'] = parent_input;
    step.name = step.name + ` for ${parent_input}`;
    return step;
  }

  getOfferLabel() {
    if (this.isUpsell()) {
      return 'Upsell Offer';
    }
    return 'Downsell Offer';
  }

  normalizeObject(
    obj: FunnelStep | FunnelInput | Action,
    recursive: boolean = false
  ) {
    const fieldsToNull = ['next_step', 'step'];
    const fieldsToDelete = ['id'];
    fieldsToNull.forEach((field) => {
      obj[field] = null;
    });
    fieldsToDelete.forEach((field) => {
      delete obj[field];
    });

    if (recursive) {
      const recursiveFields = ['inputs', 'actions'];
      recursiveFields.forEach((field) => {
        if (field in obj) {
          obj[field] = obj[field].map((item) =>
            this.normalizeObject(item, true)
          );
        }
      });
    }
    return obj;
  }

  deleteExistingInputSteps() {
    if (!this.step.id) {
      return;
    }
    const apiCalls = [];

    this.step.inputs.forEach((input) => {
      if (input.next_step) {
        apiCalls.push(this.stepService.delete(input.next_step));
      }
    });

    forkJoin(apiCalls).subscribe(
      (_) => {},
      (error) => {
        this.handleError(error);
      }
    );
  }

  generateStepDataFromTemplate(template: FunnelStep) {
    this.step.template = template.id;
    this.deleteExistingInputSteps();
    this.step.inputs = [];
    template = this.normalizeObject(template, true) as FunnelStep;
    this.step = { ...this.step, ...template };

    if (this.step.id) {
      this.stepService.update(this.step.id, this.step).subscribe(
        (step: FunnelStep) => {
          if (step) {
            this.loading = false;
            this.onStepSave(step);
          }
        },
        (error) => {
          this.handleError(error);
          this.loading = false;
        }
      );
    } else {
      this.stepService.create(this.step).subscribe(
        (step: FunnelStep) => {
          if (step) {
            if (step.is_first_step) {
              if (this.isSurvey()) {
                this.onStepLoading.emit({ loading: true });
                this.waitForChildStepsToCreate = true;
              } else {
                this.onFirstStepCreated.emit([step]);
              }
            }
            this.onStepSave(step);
            this.loading = false;
          }
        },
        (error) => {
          this.handleError(error);
          this.loading = false;
        }
      );
    }
  }

  onStepSave(step: FunnelStep) {
    this.step = step;
    this.type = step.type;
    this.category = step.category;
    this.selectedTemplateId = step.template;

    if (this.step.id) {
      this.productFunnelInputsData = [];
      this.processStepInputs(step.inputs);
    }
  }

  onStepFieldUpdate(fieldName: string, event) {
    const data = {};
    data[fieldName] = event.target.value;
    this.stepService.patch(this.step.id, data).subscribe(
      (_) => {},
      (error) => {
        this.handleError(error);
      }
    );
  }

  navigateToDownsell(stepId: number) {
    if (!this.isOfferSelected(stepId)) {
      this.redirectToCreateStep(this.isUpsell() ? 'upsell' : 'downsell');
    } else {
      const url = this.router.serializeUrl(this.router.createUrlTree([
        '/steps',
        this.isUpsell() ? 'upsell' : 'downsell',
        this.isUpsell() ? OfferTypes.Upsell : OfferTypes.Downsell,
        'details',
        this.selectedDownsellId || stepId,
      ]));
      window.open(url, '_blank');
    }
  }

  onInputFieldUpdate(inputId: string | number, fieldName: string, event) {
    const data = {};
    data[fieldName] = event.target.value;
    this.selectedDownsellId = Number(event.target.value);

    if (fieldName === 'product_funnels') {
      data[fieldName] = [data[fieldName]];
    }
    this.loading = true;

    if (this.productFunnelInputsData.length) {
      const updatedInput = this.productFunnelInputsData.find(
        (input) => input.id === inputId
      );
      if (updatedInput) {
        updatedInput.productFunnel = Number(event.target.value);
      }
    }
    this.inputService.patch(inputId, data).subscribe(
      (input: FunnelInput) => {
        this.loading = false;
      },
      (error) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  getStepTitleLabel() {
    if (this.isConfirmation()) {
      return 'Confirmation Pop-up';
    }

    return this.step.name;
  }

  private capitalizeFirstLetter(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  getInputTitleLabel(input: FunnelInput): string {
    const intentText = IntentConfirmationButtonText[this.funnel.offer_intent];
    if (input.type === FunnelInputTypeEnum.Confirmation) {
      return `${this.capitalizeFirstLetter(intentText)} button`;
    } else if (input.type === FunnelInputTypeEnum.ConfirmationNo) {
      return `Don't ${intentText} button`;
    }
    return 'Label';
  }

  isSurveyStep(step: FunnelStep) {
    const surveyCategories: StepCategory[] = [
      CustomStepCategoryEnum.Survey,
      CustomStepCategoryEnum.CancelOrderSurvey,
      CustomStepCategoryEnum.CancelTrialSurvey,
      CustomStepCategoryEnum.CancelSubSurvey,
      CustomStepCategoryEnum.ReturnSurvey,
      CustomStepCategoryEnum.PauseSurvey,
    ];

    return surveyCategories.indexOf(step.category) > -1;
  }

  redirectToCreateStep(stepName: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/steps/${stepName}`]));
    window.open(url, '_blank');
  }

  deleteStep() {
    if (this.canBeDeleted) {
      this.loading = true;
      this.onDelete.emit({ step: this.step, next_step: this.nextStepId });
    }
  }

  onCloseInputDialog() {
    this.loading = true
    let data: FunnelInput = this.inputComponent.getFormData()

    if (data) {
      this.inputService.patch(this.selectedInput.id, {actions: data.actions})
        .subscribe((data: FunnelInput) => {
          this.loading = false;
          this.selectedInput = null;
          this.inputs.forEach((input: FunnelInput, index) => {
            if (input.id === data.id) {
              this.inputs[index].actions = data.actions
            }
          })
        }, error => {
          this.loading = false;
          this.selectedInput = null;
          this.handleError(error)
        })
    }
  }

  openInputDialog(input: FunnelInput) {
    this.selectedInput = input
    this.modalService.getModal('inputDialog').open()
  }

  isCancelOrderType() {
    return [OfferIntents.CancelOrder, OfferIntents.CancelSubscriptionHold, OfferIntents.CancelTrialHold].indexOf(this.funnel.offer_intent) > -1
  }

  isReturnOrderType() {
    return [OfferIntents.ReturnOrder, OfferIntents.ReturnSubscription, OfferIntents.ReturnTrial].indexOf(this.funnel.offer_intent)
  }
}
