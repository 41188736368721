import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  AudioFileService,
  CampaignProductService,
  FaqTopicService,
  FunnelInputService,
  FunnelService,
  FunnelStepGroupService,
  FunnelStepService,
  WidgetCategoryService,
  WidgetService
} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {
  Action,
  AudioFile,
  AvailableActionHoldoffs,
  AvailableFunnelItemStatuses,
  CampaignProduct,
  Category,
  ContactTypeLabels,
  CustomStepCategoryEnum,
  Funnel,
  FunnelInput,
  FunnelInputSystemTypes,
  FunnelInputTypeEnum,
  FunnelInputTypeLabels,
  FunnelStep,
  FunnelStepGroup,
  FunnelStepSaveType,
  FunnelSystemInputChildTypes,
  FunnelType,
  getCampaignProductImageUrl,
  getEmailPathVariables,
  getFunnelInputTypeGroup,
  getFunnelItemTypeLabel,
  getFunnelPathVariables,
  getFunnelStepMobileIconButtonTypeOptions,
  ImageSize, OfferIntents, OfferTypes,
  Pager,
  PopupCloseSetting,
  PopupCloseSettingLabels,
  SelectOption,
  SipRegion,
  SipRegionLabels,
  StepCategory,
  StepCategoryLabels,
  WidgetContentType,
  WidgetVariable,
} from '../_models';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {config} from '../../config/config';
import {FunnelInputFieldsComponent} from '../funnel-input';
import {FunnelActionFieldsComponent} from '../funnel-action';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {curveBundle} from 'd3-shape';
import {plainText as getPlainText, tinyMCETTextOnly} from '../_helpers';
import {WebBuilderComponent} from '../web-builder';

@Component({
  moduleId: module.id.toString(),
  encapsulation: ViewEncapsulation.None,
  templateUrl: './funnel-step-edit.component.html',
  styleUrls: ['./funnel-step.component.scss']
})
export class FunnelStepNewComponent extends CrudSaveComponent implements OnInit {
  otherSteps: FunnelStep[] = [];
  funnel: Funnel;
  step: FunnelStep;
  type: FunnelInputTypeEnum;
  typeOptions: { value: FunnelInputTypeEnum, label: string }[] = [];
  category: StepCategory;
  categoryOptions: { value: StepCategory, label: string }[] = [];
  isFirstStepDisabled = false;
  funnelId: string | number;
  availableItemStatuses: { id: number, text: string }[] = [];
  availableActionHoldoffs: { id: number, text: string }[] = [];
  itemStatusSettings: any = {};
  faqTopicOptions: SelectOption[] = [];
  popupCloseSetting = PopupCloseSettingLabels;
  campaignProducts: CampaignProduct[] = null;
  campaignProductsModalInput: CampaignProduct[] = null;
  lifelines: Funnel[] = [];
  stepList: any[] = [];
  inputs: FunnelInput[] = [];
  selectedInputIndex: number = -1;
  plainText = getPlainText;
  showEditors = false;
  groups: FunnelStepGroup[] = [];
  selectedGroup: FunnelStepGroup;
  contentVariables = null;
  voicePathVariables = getEmailPathVariables();
  smsPathVariables = getEmailPathVariables();
  voiceEditorInitCallback = tinyMCETTextOnly;
  audioFiles: AudioFile[] = [];
  audioFileMap: {} = {};
  previewData: any = null;
  editorContent: any = null;
  contentData = {
    style: '', funnel: null, enhanced_content: '',
    enhanced_content_components: '', use_bootstrap_spacing: false
  };
  stepSaveType: FunnelStepSaveType = null;
  enhancedMode = false;
  sipEnabled = false;
  sipRegions: { value: number, label: string }[] = [];
  isOpen = false;
  assignedProductFunnels: Funnel[] = [];
  productFunnels: Funnel[] = [];
  inputDesigns = getFunnelStepMobileIconButtonTypeOptions();
  stepLoading: boolean = false;
  widgetCategories: Category[] = [];

  @ViewChild(WebBuilderComponent, { static: false }) builder: WebBuilderComponent;
  @ViewChild(FunnelInputFieldsComponent, { static: false }) inputComponent: FunnelInputFieldsComponent;

  protected defaultCategoryOptions: { value: StepCategory, label: string }[] = [];
  protected itemStatusLabels = {};
  protected actionHoldoffLabels = {};
  protected jumpSteps = {};
  private prevStep: FunnelStep;
  private prevInput: FunnelInput;
  private prevInputIsAutopick = false;
  private widgets: WidgetVariable[] = [];

  //variables needed for previous input graph
  graphView: any[] = [980, 100];
  curve: any = curveBundle.beta(1);
  chart: any;
  hierarchialGraph: { links: any[], nodes: any[] };

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected stepService: FunnelStepService,
    protected alertService: AlertService,
    protected inputService: FunnelInputService,
    protected faqTopicService: FaqTopicService,
    protected productService: CampaignProductService,
    protected funnelService: FunnelService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
    protected groupService: FunnelStepGroupService,
    protected audioFileService: AudioFileService,
    protected widgetService: WidgetService,
    protected widgetCategoryService: WidgetCategoryService
  ) {
    super(router, location, route, stepService, alertService);
    this.isNew = true;
    this.objectName = 'Path Step';
    this.title = 'Create New Path Step';
    this.type = FunnelInputTypeEnum.Choice;
    this.category = CustomStepCategoryEnum.Custom;
    this.itemStatusSettings = {
      singleSelection: false,
      allowSearchFilter: false,
      enableCheckAll: false
    };
    this.chart = {
      name: 'Directed Graph',
      selector: 'directed-graph',
      inputFormat: 'graph',
      options: ['colorScheme', 'showLegend']
    };

    SipRegionLabels.forEach((label, value) => {
      this.sipRegions.push({value: value, label: label});
    });
  }

  ngOnInit() {
    this.buildItemStatusList();
    this.availableItemStatuses.forEach((value: { id: number, text: string }) => {
      this.itemStatusLabels[value.id] = value.text;
    });
    this.buildActionHoldoffList();
    this.availableActionHoldoffs.forEach((value: { id: number, text: string }) => {
      this.actionHoldoffLabels[value.id] = value.text;
    });

    this.setForm(this.formBuilder.group({
      type: [this.type, Validators.required],
      category: [this.category, Validators.required],
      name: [null, Validators.required],
      label: [null],
      help: [null],
      is_first_step: [false],
      allow_undo: [true],
      show_step_link: [true],
      item_statuses: [null],
      faq_topic: [null],
      banner: [null],
      subtitle: [null],
      content: [null],
      style: [null],
      classes: [null],
      is_popup: [false],
      hide_status: [false],
      contact_type: [null],
      lifeline: [null],
      lifeline_enabled: [true],
      exit_lifeline: [null],
      error_lifeline: [null],
      group: [null],
      voice_prompt: [null],
      voice_file: [null],
      sms_prompt: [null],
      forward_to_call_center: [false],
      call_center_phone: [null],
      phone_loop_count: [1, [Validators.required, Validators.min(1)]],
      enhanced_mode: [false],
      enhanced_content: [null],
      enhanced_content_components: [null],
      call_center_sip_uri: [null],
      call_center_sip_username: [null],
      call_center_sip_password: [null],
      call_center_sip_secure: [false],
      call_center_sip_refer: [true],
      call_center_sip_region: [SipRegion.Default],
      fail_if_any_input_invalid: [false],
      invalid_message: [null],
      action_holdoffs: [null],
      mobile_icon_button_type: [null],
      use_bootstrap_spacing: [false],
      popup_close_setting: [PopupCloseSetting.Undo]
    }));

    if (this.isNew) {
      this.getFaqTopics();
    }

    super.ngOnInit();

    //get all available lifelines
    this.funnelService.list({resourcetype: 'Lifeline', page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.lifelines = data.results;
        },
        error => {
          this.handleError(error);
        }
      );

    //get all available audio files
    this.audioFileService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (files: AudioFile[]) => {
          this.audioFiles = files;

          files.forEach((file) => {
            this.audioFileMap[file.id] = file;
          });
        },
        error => {
          this.handleError(error);
        }
      );

    //get the content variables
    this.widgetService.getVariables()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (widgets: WidgetVariable[]) => {
          this.widgets = widgets;
          this.buildContentVariables();
        },
        error => {
          this.handleError(error);
        }
      );

    this.widgetCategoryService.list({})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (categories: Pager) => {
          this.widgetCategories = categories.results;
        },
        error => {
          this.handleError(error);
        }
      );

    this.getGroups();
    this.getStepCategories();
  }

  get typeString(): string {
    return FunnelInputTypeLabels[this.type];
  }

  get selectedInput() {
    return (this.selectedInputIndex >= 0) ? this.inputs[this.selectedInputIndex] : null;
  }

  get showGraph() {
    return this.prevStep && this.prevInput && !this.step;
  }

  get hasInputAddPermission() {
    return this.canAddInput();
  }

  protected getGroups() {
    this.groupService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.groups = data.results;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  protected getFaqTopics() {
    this.faqTopicService.getSelectOptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        options => {
          this.faqTopicOptions = options;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  protected getCampaignProducts() {
    if (this.step && (this.step.type === FunnelInputTypeEnum.Product)) {
      if (this.step.campaign_products.length) {
        this.productService.list({page: 1, page_size: config.maxPageSize, funnel_steps: this.step.id})
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (page: Pager) => {
              let products = page.results;

              // we got the products for this step, but now we need to sort them by step's campaign product ids
              products.sort((a: CampaignProduct, b: CampaignProduct) => {
                let productIds: any[] = this.step.campaign_products;

                return productIds.indexOf(a.id) - productIds.indexOf(b.id);
              });

              this.campaignProducts = products;
            },
            error => {
              this.handleError(error);
            }
          );
      } else {
        this.campaignProducts = [];
      }
    }
  }

  protected buildItemStatusList() {
    this.availableItemStatuses = AvailableFunnelItemStatuses.slice(0);
  }

  protected buildActionHoldoffList() {
    this.availableActionHoldoffs = AvailableActionHoldoffs.slice(0);
  }

  protected onRouteParams(params: {}) {
    this.resetForm(true);
    this.hierarchialGraph = {links: [], nodes: []};
    this.prevInput = null;
    this.prevInputIsAutopick = false;
    this.prevStep = null;
    this.enhancedMode = false;
    this.campaignProducts = null;
    this.inputs = [];
    this.isFirstStepDisabled = false;
    this.inputService.clearStepId();
    this.type = FunnelInputTypeEnum.Choice;
    this.category = CustomStepCategoryEnum.Custom;
    this.loading = true;

    if ('funnel_id' in params) {
      this.funnelId = params['funnel_id'];

      if (!this.funnel || (this.funnel.id != this.funnelId)) {
        this.stepService.setFunnelId(this.funnelId);
        this.funnel = null;

        forkJoin([
          this.funnelService.get(this.funnelId),
          this.stepService.list(),
          this.stepService.listAll({resourcetype: 'VisualFunnel', 'funnel!': this.funnelId})
        ]).pipe(takeUntil(this.destroy$))
          .subscribe(
            (data: [Funnel, FunnelStep[], FunnelStep[]]) => {
              if (data) {
                const funnel = data[0];
                this.jumpSteps = {};
                this.otherSteps = [];

                // get the other steps in the same funnel that this step can point to
                data[1].forEach((step: FunnelStep) => {
                  if (step.id !== this.id) {
                    this.otherSteps.push(step);
                    this.addJumpStep(step);
                  }
                });

                // build a list of steps that we can jump to from an internal link
                if (funnel.resourcetype !== FunnelType.Visual && funnel.resourcetype !== FunnelType.Hybrid) {
                  data[2].forEach((step: FunnelStep) => {
                    this.addJumpStep(step);
                  });
                }

                this.funnel = funnel;
                this.contentData.funnel = this.funnel;
                this.buildJumpStepList();
                this.updateAllowedTypes();
                this.buildProductFunnelList();
                this.loading = false;

                if (!this.otherSteps.length) {
                  //default this to true if this is the only step in the funnel
                  this.form.patchValue({is_first_step: true});
                  this.isFirstStepDisabled = true;
                }
              }
            },
            error => {
              this.handleError(error);
            }
          );
      }
    }

    if ('prev_input' in params) {
      let inputId = params['prev_input'];
      const autopick = !!params['autopick']

      //if we're inserting this new step after another step's input, get the input we're coming from
      if (inputId && inputId !== '') {
        this.contentData = {
          style: '', funnel: this.funnel, enhanced_content: '',
          enhanced_content_components: '', use_bootstrap_spacing: false
        };
        this.inputService.get(inputId)
          .pipe(takeUntil(this.destroy$), mergeMap((input: FunnelInput) => {
            this.prevInput = input;
            this.prevInputIsAutopick = autopick;
            this.inputService.setStepId(input.step);

            return this.stepService.get(input.step);
          }))
          .subscribe(
            (step: FunnelStep) => {
              this.prevStep = step;
              this.buildGraph();
              this.loading = false;
            },
            error => {
              this.handleError(error);
              this.loading = false;
            }
          );
      } else {
        this.loading = false;
      }
    }
  }

  protected updateAllowedTypes() {
    let allowedTypes = [];
    let typeOptions = [];

    if (this.isNew) {
      allowedTypes = [
        FunnelInputTypeEnum.Choice,
        FunnelInputTypeEnum.Radio,
        FunnelInputTypeEnum.Select,
        FunnelInputTypeEnum.RadioSelect,
        FunnelInputTypeEnum.Item,
        FunnelInputTypeEnum.ActiveItem,
        FunnelInputTypeEnum.FAQs,
        FunnelInputTypeEnum.BillingAddress,
        FunnelInputTypeEnum.ShippingAddress,
        FunnelInputTypeEnum.PaymentInfo,
        FunnelInputTypeEnum.ContactCallCenter,
        FunnelInputTypeEnum.MatchCustomer
      ];

      if (this.funnel.resourcetype === FunnelType.Hybrid) {
        allowedTypes.push(FunnelInputTypeEnum.SelectCampaign);
      } else {
        allowedTypes.push(FunnelInputTypeEnum.EnterProductFunnel);
        allowedTypes.push(FunnelInputTypeEnum.EnterTroubleshooter);
        allowedTypes.push(FunnelInputTypeEnum.Product);
        allowedTypes.push(FunnelInputTypeEnum.ItemStatus);
        allowedTypes.push(FunnelInputTypeEnum.InputActionStatus);
        allowedTypes.push(FunnelInputTypeEnum.Checkout);
        allowedTypes.push(FunnelInputTypeEnum.CheckoutConfirm);
        allowedTypes.push(FunnelInputTypeEnum.RepeatActionHoldoff);
        allowedTypes.push(FunnelInputTypeEnum.ShipmentTracking);
      }
    }
    else if (this.step) {
      allowedTypes = getFunnelInputTypeGroup(this.step.type);
    }

    //get the pick list of input types sorted alphabetically
    allowedTypes.forEach(type => {
      typeOptions.push({value: type, label: FunnelInputTypeLabels[type]})
    });

    typeOptions.sort((a, b): number => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    this.typeOptions = typeOptions;
  }

  private addUniqueOtherStep(newStep: FunnelStep) {
    if (this.otherSteps.every((step: FunnelStep) => step.id !== newStep.id)) {
      this.otherSteps.push(newStep);
    }
  }

  private addJumpStep(step: FunnelStep) {
    if (step.show_step_link && !(step.slug in this.jumpSteps)) {
      this.jumpSteps[step.slug] = step;
    }
  }

  protected buildContentVariables() {
    let items = getFunnelPathVariables();
    this.contentVariables = null;

    this.widgets.forEach((widget: WidgetVariable) => {
      items.push({value: '{' + widget.slug + '}', text: widget.name, image: widget});
    });

    if (this.type === FunnelInputTypeEnum.ContactCallCenter) {
      items.push({value: '{support_phone}', text: 'Call Center Phone'});
    }

    items.sort((a, b): number => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    this.contentVariables = items;

    // this is needed to force the tinymce editors to reload
    setTimeout(() => this.contentVariables = items, 0);
  }

  private buildJumpStepList() {
    const isVisualFunnel = this.funnel.resourcetype === FunnelType.Visual ||
      this.funnel.resourcetype === FunnelType.Hybrid;
    if (isVisualFunnel) {
      this.widgetService.type = WidgetContentType.Visual;
    } else if (this.funnel.resourcetype === FunnelType.Product) {
      this.widgetService.type = WidgetContentType.Product;
    }
    const isTroubleshooter = this.funnel.resourcetype === FunnelType.Troubleshooter;
    const currentPrefix = isTroubleshooter ? 'Current Troubleshooter => ' : 'Current Path => ';

    this.showEditors = false;
    this.stepList = [];

    Object.values(this.jumpSteps).forEach((step: FunnelStep) => {
      const prefix = isVisualFunnel ? '' :
        ((step.funnel === this.funnel.id) ? currentPrefix : 'Top Visual Path => ');

      this.stepList.push({id: step.id, title: prefix + step.name, value: step.slug, name: step.name});
    });

    // sort the list alphabetically
    this.stepList.sort((a, b): number => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });

    // this is needed to force the tinymce editors to reload
    setTimeout(() => this.showEditors = true, 0);
  }

  private buildGraph() {
    this.hierarchialGraph.nodes = [
      {
        id: this.prevStep.id.toString(),
        label: FunnelStepService.getStepName(this.prevStep),
        stepId: this.prevStep.id.toString()
      },
      {
        id: 'new_step',
        label: 'New Step',
        stepId: null
      }
    ];
    this.hierarchialGraph.links = [
      {
        id: 'input_' + this.prevInput.id.toString(),
        source: this.prevStep.id.toString(),
        target: 'new_step',
        targetId: null,
        label: this.prevInput.label + (this.prevInputIsAutopick ? ' (autopicked)' : ''),
        stepId: this.prevInput.step,
        inputId: this.prevInput.id
      }
    ];
  }

  canSupportLifelines(): boolean {
    return this.funnel &&
      this.funnel.is_visual &&
      this.funnel.resourcetype !== FunnelType.Lifeline &&
      this.funnel.resourcetype !== FunnelType.Survey;
  }

  showFaqTopics(): boolean {
    return this.funnel && this.funnel.is_visual && (this.type === FunnelInputTypeEnum.FAQs);
  }

  canShowAsPopup(): boolean {
    return this.funnel && this.funnel.is_visual;
  }

  canShowAsPopupCloseSetting(): boolean {
    return this.canShowAsPopup() && this.form.get('is_popup').value
  }

  isCallCenterStep(): boolean {
    return this.funnel && this.funnel.is_visual && (this.type === FunnelInputTypeEnum.ContactCallCenter);
  }

  isActionHoldoffStep(): boolean {
    return this.funnel && this.funnel.is_visual && (this.type === FunnelInputTypeEnum.RepeatActionHoldoff);
  }

  isHybridFunnel(): boolean {
    return this.funnel && (this.funnel.resourcetype === FunnelType.Hybrid);
  }

  selectType(event) {
    const newType = parseInt(event.target.value);
    const oldType = this.type;
    this.type = newType;

    if ((newType !== oldType) && !this.step) {
      this.showEditors = false;

      if (this.isSystemType(newType)) {
        //changing to a new system type, so create the default system input
        this.inputs =
          [{
          type: newType,
          label: getFunnelItemTypeLabel(newType, newType),
          next_step: null
        } as FunnelInput];

        //create fixed system alternate inputs
        if ((newType !== FunnelInputTypeEnum.ItemStatus) && (newType in FunnelSystemInputChildTypes)) {
          FunnelSystemInputChildTypes[newType].forEach(type => {
            if ([
              FunnelInputTypeEnum.ShipmentReturnLabel, FunnelInputTypeEnum.ShipmentReturnQRCode
            ].indexOf(type) === -1) {
              this.inputs.push({
                type: type,
                label: getFunnelItemTypeLabel(newType, type),
                next_step: null
              } as FunnelInput);
            }
          });
        }
      } else if (this.isSystemType(oldType)) {
        // changing from a system type to a normal type, so just clear the existing inputs
        this.inputs = [];
      }
      this.inputs = [...this.inputs];
      this.buildContentVariables(); // rebuild the content variables
      this.buildProductFunnelList();

      // this is needed to force the tinymce editors to reload
      setTimeout(() => this.showEditors = true, 0);

    //   disable step category if type is same
      this.setStepCategoryInput()
    }
  }

  protected setStepCategoryInput(){
    if(this.isSystemType(this.type)){
      this.form.patchValue({category: this.type});
      this.categoryOptions = [{value: this.type, label: StepCategoryLabels[this.type]}];
      this.form.controls['category'].disable();
    }
    else {
      // set to default category if previous selected was a system type
      this.categoryOptions = this.defaultCategoryOptions
      let selectedCategory = this.form.controls['category']['value']
      if(this.isSystemType(selectedCategory) || StepCategoryLabels[selectedCategory] === undefined){
        this.form.patchValue({category: CustomStepCategoryEnum.Custom})
      }
      this.form.controls['category'].enable();
    }
  }


  addInput(input?: FunnelInput) {
    if (this.canAddInput()) {
      if (!input) {
        const type = this.type;

        // new empty input, so create an empty input with a default type
        if (this.isSystemType(type)) {
          let inputType = FunnelSystemInputChildTypes[type][this.inputs.length - 1];

          input = {
            type: inputType,
            label: FunnelInputTypeLabels[inputType],
            next_step: null
          } as FunnelInput;
        } else {
          input = {
            type: this.inputs.length ? this.inputs[0].type : type,
            next_step: null
          } as FunnelInput;
        }
      }

      input.id = `new-${Date.now()}`;

      this.inputs.push(input);
      this.editInput(this.inputs.length - 1);
    }
  }

  editInput(index: number) {
    this.selectedInputIndex = index;
    this.openInputDialog();
  }

  removeInput(index: number, builderInput?: boolean) {
    if (this.canDeleteInput(this.inputs[index])) {
      this.inputs.splice(index, 1);
    }
  }

  removeSelectedInput(deleteInputs) {
    deleteInputs.map(deleteInput => {
      let index = this.inputs.findIndex(input => (input.id === deleteInput.id && deleteInput.checked === true));
      if(index>-1) {
        this.removeInput(index)
      }
    })
  }

  getInputLabel(input: FunnelInput): string {
    let label = null;

    if (input) {
      label = getFunnelItemTypeLabel(this.type, input.type, input.label);
    }

    return label || '&lt;New Input&gt;'; //this is referenced from innerHTML, so we must use html entities
  }

  copy() {
    if (this.step) {
      this.stepService.copy(this.step.id);
    }
  }

  copyInput(input: FunnelInput) {
    if (input && input.id) {
      this.inputService.copy(input.id);
    }
  }

  canPasteInput() {
    return this.canAddInput() && this.inputService.canPaste();
  }

  pasteInput() {
    this.inputService.paste()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (input: FunnelInput) => {
          this.inputs.push(input);
          this.editInput(this.inputs.length - 1);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  canAddInput() {
    const type = this.type;
    let canAdd = false;

    if (type !== null) {
      switch (type) {
        case FunnelInputTypeEnum.Item:
        case FunnelInputTypeEnum.ActiveItem:
        case FunnelInputTypeEnum.ItemStatus:
        case FunnelInputTypeEnum.InputActionStatus:
          canAdd = (type in FunnelSystemInputChildTypes) &&
            (this.inputs.length <= FunnelSystemInputChildTypes[type].length);
          break;

        default:
          canAdd = !this.isSystemType(type);
          break;
      }
    }

    return canAdd;
  }

  isSystemType(type?: number) {
    if (type === undefined) {
      type = this.type;
    }

    return (type !== null) && (FunnelInputSystemTypes.indexOf(type) !== -1);
  }

  canDeleteInput(input: FunnelInput) {
    const type = this.type;
    let canDelete = true; //any normal input can be deleted

    if (input && this.isSystemType(type)) { // if this is a system input
      switch (type)
      {
        case FunnelInputTypeEnum.Item:
        case FunnelInputTypeEnum.ActiveItem:
        case FunnelInputTypeEnum.ItemStatus:
        case FunnelInputTypeEnum.InputActionStatus:
          canDelete = input.type !== type; // can only delete additional system inputs that were manually added
          break;

        default:
          canDelete = false;
          break;
      }
    }

    return canDelete;
  }

  canSortInputs() {
    const type = this.type;

    if (this.isSystemType(type)) {
      // item status inputs are the only system inputs that can be sorted
      return type === FunnelInputTypeEnum.ItemStatus;
    }

    return true; //normal inputs can be sorted
  }

  protected openInputDialog() {
    this.modalService.getModal('inputDialog').open();
  }

  onCloseInputDialog() {
    this.showEditors = false;
    Object.assign(this.inputs[this.selectedInputIndex], this.inputComponent.getFormData());
    this.inputs = [...this.inputs];
    this.selectedInputIndex = -1;

    // this is needed to force the tinymce editors to reload
    setTimeout(() => this.showEditors = true, 0);
  }

  addCampaignProduct() {
    this.campaignProductsModalInput = this.campaignProducts;
    this.modalService.getModal('campaignProductsDialog').open();
  }

  onCampaignProductsSelected(products: CampaignProduct[]) {
    this.campaignProducts = products;
    this.modalService.getModal('campaignProductsDialog').close();
  }

  removeCampaignProduct(productId) {
    this.campaignProducts = this.campaignProducts.filter(
      (product: CampaignProduct) => product.id != productId);
  }

  getCampaignProductImageUrl(campaignProduct) {
    return getCampaignProductImageUrl(campaignProduct, ImageSize.small);
  }

  copyCampaignProducts() {
    this.stepService.copyProducts(this.campaignProducts);
  }

  canPasteCampaignProducts() {
    return this.stepService.canPasteProducts();
  }

  pasteCampaignProducts() {
    this.campaignProducts = this.stepService.pasteProducts();
  }

  updateAudioFile(event) {
    if (event.target.value == 0) {
      this.router.navigate(['audio/upload']);
    }
  }

  getContactTypes() {
    let types = [];

    ContactTypeLabels.forEach((value, index) => {
      if (index > 0) {
        types.push({id: index, label: value});
      }
    });

    return types;
  }

  getActionLabel(action: Action) {
    return FunnelActionFieldsComponent.getActionDescriptions(action).join('.  ');
  }

  isEnterProductFunnel(): boolean {
    return (this.type === FunnelInputTypeEnum.EnterProductFunnel);
  }

  isStatusStep(): boolean {
    return (this.type === FunnelInputTypeEnum.InputActionStatus) ||
      (this.type === FunnelInputTypeEnum.CheckoutConfirm)
  }

  preview() {
    if (this.funnel) {
      let inputs = []
      if (this.builder && this.enhancedMode) {
        const editorContent = this.builder.saveBuilderContent(true);
        inputs = this.onSaveFunnelStep(editorContent);
        inputs = this.copyAndConvertIconsIntoIDs(inputs);
      }
      this.previewData = this.getFormData();
      if (inputs.length) {
        this.previewData.inputs = inputs;
      }
      this.modalService.getModal('funnelStepPreviewDialog').open();
    }
  }

  onPreview(previewUrl: string) {
    this.modalService.getModal('funnelStepPreviewDialog').close();
    window.open(previewUrl, '_blank');
  }

  protected buildProductFunnelList() {
    this.productFunnels = [];
    this.assignedProductFunnels = [];

    if (this.funnel && this.isEnterProductFunnel() && this.inputs && this.inputs.length) {
      let selectedFunnelMap = {};
      let funnels = [];
      let assignedFunnels = [];
      const filter = (this.funnel && this.funnel.use_intents) ? {
        'offer_intent__in!': [OfferIntents.Legacy, OfferIntents.Other].join(','),
        offer_type__in: [OfferTypes.SubPath, OfferTypes.CustomerPortal].join(','),
      } : {offer_intent: OfferIntents.Legacy}

      if (this.inputs[0].product_funnels) {
        for (let funnel_id of this.inputs[0].product_funnels) {
          selectedFunnelMap[funnel_id] = true;
        }
      }

      this.funnelService.list({
        resourcetype: 'ProductFunnel', is_visual: true, page: 1, page_size: config.maxPageSize, ...filter
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: Pager) => {
            for (let funnel of data.results) {
              funnels.push(funnel);

              if (funnel.id in selectedFunnelMap) {
                assignedFunnels.push(funnel);
              }
            }

            this.productFunnels = funnels;
            this.assignedProductFunnels = assignedFunnels;
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  protected copyAndConvertIconsIntoIDs(inputs) {
    let convertedInputs = [];
    inputs.forEach(input => {
      let productFunnelIds = [];

      if (input.type === FunnelInputTypeEnum.EnterProductFunnel) {
        // add assigned product funnel ids
        this.assignedProductFunnels.forEach((funnel: Funnel) => {
          productFunnelIds.push(funnel.id);
        });
      }

      let data = Object.assign({}, input, {
        icon: input.icon ? input.icon.id : null,
        next_step: input.next_step === 'new' ? null : input.next_step,
        autopick_next_step: input.autopick_next_step === 'new' ? null : input.autopick_next_step,
        product_funnels: productFunnelIds
      });
      convertedInputs.push(data);
    });

    return convertedInputs;
  }

  protected getFormData() {
    let itemStatuses = null;
    let actionHoldoffs = null;
    const classStr = this.form.value.classes ? this.form.value.classes.trim() : '';
    const sipUri = this.sipEnabled ? this.form.value.call_center_sip_uri : '';
    let classes = [];
    let inputs = [];
    let num_digits = 1;

    // copy the input data and convert icons into ids
    inputs = this.copyAndConvertIconsIntoIDs(this.inputs);

    if (this.campaignProducts) {
      this.form.value.campaign_products = this.campaignProducts.map((product: CampaignProduct) => product.id);
    }

    if (this.form.value.item_statuses && this.form.value.item_statuses.length) {
      itemStatuses = [];
      this.form.value.item_statuses.forEach(itemStatus => {
        itemStatuses.push(itemStatus.id);
      });
    }

    if (this.form.value.action_holdoffs && this.form.value.action_holdoffs.length) {
      actionHoldoffs = [];
      this.form.value.action_holdoffs.forEach(actionHoldoff => {
        actionHoldoffs.push(actionHoldoff.id);
      });
    }

    // convert the class string into an array of unique values
    if (classStr.length) {
      classes = classStr.split(' ').filter((value, index, self) => self.indexOf(value) === index);
    }

    // displaying as a popup is only allowed for certain step types
    if (!this.canShowAsPopup()) {
      this.form.value.is_popup = false;
    }

    if (inputs.length && (inputs[0].type === FunnelInputTypeEnum.Phone)) {
      num_digits = 10;
    }

    return Object.assign({}, this.form.value, {
      item_statuses: itemStatuses,
      classes: classes,
      inputs: inputs,
      num_digits: num_digits,
      call_center_sip_uri: sipUri,
      action_holdoffs: actionHoldoffs
    });
  }

  protected onSaveComplete(data) {
    if (this.prevInput) {
      //we just inserted this new step after another step's input, so set the input's next step to the one we created
      const patchData = this.prevInputIsAutopick ? {autopick_next_step: this.id} : {next_step: this.id};
      this.inputService.patch(this.prevInput.id, patchData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (input: FunnelInput) => {
            this.queueInputsNeedingNextStep(data);
          },
          error => {
            this.handleSubmitError(error);
          });
    } else {
      this.queueInputsNeedingNextStep(data);
    }
    if (this.isNew) {
      this.router.navigate(['/path', this.funnel.id, 'step', 'edit', data.id]);
    }
    this.inputs = [...data.inputs];
  }

  protected handleSubmitError(error: any, keepAfterNavigationChange: boolean = false, showAlert: boolean = false) {
    if (this.isAdvanceMode) {
      this.inputs = this.editorContent.oldInputs;
      this.editorContent = null;
      showAlert = true;
    }
    super.handleSubmitError(error, keepAfterNavigationChange, showAlert);
  }

  protected queueInputsNeedingNextStep(newStep: FunnelStep) {
    let hasAnyNewInput = false;
    this.inputs.forEach((input: FunnelInput, index: number) => {
      if (input.next_step === 'new') { //if adding a new step after saving this input
        this.inputService.queueInputForNewStep(newStep.inputs[index].id, false);
        hasAnyNewInput = true;
      }

      if (input.autopick_next_step === 'new') { //if adding a new step after saving this input
        this.inputService.queueInputForNewStep(newStep.inputs[index].id, true);
        hasAnyNewInput = true;
      }
    });
    if (hasAnyNewInput) {
      this.contentData = {
        style: '', funnel: this.funnel, enhanced_content: '',
        enhanced_content_components: '', use_bootstrap_spacing: false
      };
      this.builder.clearBuilder(false);
    }
    this.navigateToNextStep(newStep);
  }

  protected navigateToNextStep(newStep?: FunnelStep) {
    const prevInput = this.inputService.getNextInputForNewStep();

    if (prevInput) {
      if (newStep) {
        // we just created a new step, so add it to our step lists
        this.addUniqueOtherStep(newStep);
        this.addJumpStep(newStep);
        this.buildJumpStepList();
      }

      this.navigate(['/path', this.funnel.id, 'step', 'new',
          {prev_input: prevInput.id, autopick: prevInput.autopick}], {replaceUrl: true});
    } else {
      this.loading = false;
      if (this.stepSaveType === FunnelStepSaveType.SaveAndStay) {
        this.stepSaveType = FunnelStepSaveType.Cancel;
      } else {
        this.loading = false;
        if(!this.isAdvanceMode){
          this.goBack();
        }
      }
    }
  }

  cancel() {
    this.navigateToNextStep();
  }

  createGroup() {
    this.selectedGroup = null;
    this.openEditGroupDialog();
  }

  editGroup(groupId: number | string) {
    this.selectedGroup = this.groups.find(
      (group: FunnelStepGroup) => group.id.toString() === groupId.toString());
    this.openEditGroupDialog();
  }

  protected openEditGroupDialog() {
    this.modalService.getModal('editGroupDialog').open();
  }

  onSaveGroup(group: FunnelStepGroup) {
    this.modalService.getModal('editGroupDialog').close();
    this.getGroups();
    this.form.patchValue({group: group.id});
  }

  get hasAnyUnAttachedInput() {
    return this.enhancedMode && this.inputs.some((input) => !input.builder_id);
  }

  get isAdvanceMode() {
    return this.isOpen && this.enhancedMode && this.showEditors && this.contentVariables;
  }

  saveFunnelStep(saveType: any) {
    if (saveType === FunnelStepSaveType.SaveAndStay) {
      this.stepSaveType = FunnelStepSaveType.SaveAndStay;
    } else {
      this.stepSaveType = FunnelStepSaveType.Save;
    }
    if (this.builder && this.enhancedMode) {
      if (this.form.valid) {
        this.builder.saveBuilderContent();
      } else {
        this.alertService.error("Please fill out all required information");
        return;
      }
    }
    this.inputs = this.inputs.map((input) => {
      if ((input.id || '').toString().startsWith('new-')) {
        delete input['id'];
      }
      return input;
    });

    if (!this.hasAnyUnAttachedInput) {
      this.onSubmit();
    } else {
      this.alertService.error("Please attach all inputs to editor components in advance mode.");
    }
  }

  updateExistingInput(builderInputs, input, index) {
    const builderInputIndex = builderInputs.findIndex((builderInput) => (
      (builderInput.input == (input.id || input.type))
    ));
    if (builderInputIndex === -1) {
      this.inputs[index].builder_id = null;
      this.inputs[index].classes = null;
      return;
    }
    const builderInput = builderInputs[builderInputIndex];
    delete builderInput['input'];
    delete builderInput['component'];
    Object.assign(input, builderInput);
    if ((input.id || '').toString().startsWith('new-')) {
      delete input['id'];
      input['input'] = 'new';
    }
    builderInputs[builderInputIndex]['updated'] = true;
  }

  onSaveFunnelStep(data) {
    this.editorContent = { ...data, oldInputs: [...this.inputs] };
    this.form.controls['enhanced_content'].setValue(data.html);
    this.form.controls['enhanced_content_components'].setValue(data.components);
    this.form.controls['style'].setValue(data.css);
    this.form.controls['use_bootstrap_spacing'].setValue(data.use_bootstrap_spacing);

    let allInputs = [];
    if (data.inputs && data.inputs.length) {
      let newInputs = [];
      data.inputs = data.inputs.filter(input => input);
      for (let i = 0; i < this.inputs.length; i++) {
        let input = this.inputs[i];
        this.updateExistingInput(data.inputs, input, i);
      }

      if (!this.hasAnyUnAttachedInput) {
        newInputs = data.inputs.filter(input => !input.updated).map((input, index) => {
          delete input['component'];
          return {
            icon: null,
            help: null,
            values: [],
            product_funnels: [],
            matched_step_key: null,
            is_alt_child_exit: false,
            hide_if_invalid: true,
            ...input,
            input: "new"
          }
        });
      }
      allInputs = allInputs.concat(this.inputs, newInputs).map((input) => {
        if (input.next_step === 'alternate') {
          input['is_alt_child_exit'] = true;
          input['next_step'] = null;
        } else if (!input.next_step) {
          input['is_alt_child_exit'] = false;
        }
        input['formatted_label'] = input.label;
        return input;
      });
    } else {
      allInputs = allInputs.map((input) => {
        input.builder_id = null;
        return input;
      })
    }

    if (!data.isPreview) {
      this.inputs = allInputs;
    }
    return allInputs;
  }

  enableEnhancedMode(event) {
    this.enhancedMode = event.target.checked;
    if (!this.enhancedMode) {
      this.isOpen = false;
    }
  }

  toggleEditor() {
    this.isOpen = !this.isOpen;
  }

  getFunnelLabel(funnel: Funnel): string {
    return funnel.is_template ? 'System Template - ' + funnel.name : funnel.name;
  }

  protected getStepCategories(){
    let stepCategory = []
    for (let category in CustomStepCategoryEnum) {
      if (!isNaN(Number(category))) {
        stepCategory.push(category)
      }
    }
    stepCategory.forEach(cat => {
      this.defaultCategoryOptions.push({value: cat, label: StepCategoryLabels[cat]});
    })
    this.categoryOptions = this.defaultCategoryOptions
  }
}
