import {HttpClient} from '@angular/common/http';
import {CrudService} from "./crud.service";
import {ClipboardService} from "./clipboard.service";
import {PaginationService} from './pagination.service';

export abstract class FileService extends PaginationService {
  protected fileType = null;

  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService,
    protected route: string
  ) {
    super(http, route);
  }

  move(id, toPath: string) {
    return this.http.post(this.route + '/' + id.toString() + '/move/', {path: toPath});
  }

  cut(id: string | number) {
    this.clipboardService.copy(this.getKey(), {op: 'move', id: id});
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste(this.getKey());
  }

  paste(toPath: string) {
    const file = this.clipboardService.paste(this.getKey());

    // for now we only support cut and paste (no copy yet), so always assume file.op to be 'move'
    return this.move(file.id, toPath);
  }

  clearSelection() {
    this.clipboardService.clearSelection(this.getKey())
  }

  private getKey() : string {
    return (this.fileType && this.fileType.length) ? 'file_' + this.fileType : 'file';
  }

}
