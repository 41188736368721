import {Component, OnInit} from '@angular/core';
import {CrudSaveComponent} from "../_directives";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, FaqTopicService, FaqService, FunnelStepService, WidgetService} from "../_services";
import {FormBuilder, Validators} from "@angular/forms";
import {Faq, FunnelInputTypeEnum, getCampaignVariables, WidgetVariable} from "../_models";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqNewComponent extends CrudSaveComponent implements OnInit {
  faq: Faq;
  faqTopicOptions: {id: number | string, text: string}[] = [];
  stepList: any[];
  showEditors = false;
  topicSettings: any = {};
  contentVariables = null;

  protected topicLabels = {};

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected topicService: FaqTopicService,
    protected faqService: FaqService,
    protected alertService: AlertService,
    protected stepService: FunnelStepService,
    protected widgetService: WidgetService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, faqService, alertService);
    this.title = 'Create FAQ';
    this.objectName = 'FAQ';
    this.isNew = true;
    this.topicSettings = {
      singleSelection: false,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      question: [null, Validators.required],
      blurb: [null],
      answer: [null, Validators.required],
      chat_answer: [null],
      keywords: [null],
      topics: [null],
      meta_description: [null],
      include_in_copy: [false],
      display: [true],
    });

    super.ngOnInit();
    this.loading = true
    this.stepService.getGlobalJumpLinks(true, {resourcetype: 'VisualFunnel'})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        stepList => {
          this.loading = false
          this.stepList = stepList;
          this.showEditors = true;
        },
        error => {
          this.loading = false
          this.handleError(error);
        }
      );

    //get the content variables
    this.widgetService.getVariables({
      product__isnull: true,
      campaign_product__isnull: true,
      folder__isnull: true,
      block_id__isnull: true,
      enhanced_mode: false,
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (widgets: WidgetVariable[]) => {
          this.buildContentVariables(widgets);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  protected onRouteParams(params: {}) {
    this.getTopics(params);
  }

  protected buildContentVariables(widgets: WidgetVariable[]) {
    let items = getCampaignVariables();
    this.contentVariables = null;

    widgets.forEach((widget: WidgetVariable) => {
      items.push({value: '{' + widget.slug + '}', text: widget.name, image: widget});
    });

    items.sort((a, b): number => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    this.contentVariables = items;

    // this is needed to force the tinymce editors to reload
    setTimeout(() => this.contentVariables = items, 0);
  }

  protected getTopics(params?: {}) {
    //get the list of faq topics
    this.loading = true
    this.topicService.getSelectOptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        options => {
          let faqTopicOptions = [];

          options.forEach(option => {
            this.topicLabels[option.value] = option.label;
            faqTopicOptions.push({id: option.value, text: option.label});
          });

          this.faqTopicOptions = faqTopicOptions;
          this.onTopicsReady(params);
        },
        error => {
          this.loading = false
          this.handleError(error);
        }
      );
  }

  protected onTopicsReady(params?: {}) {
    if (params && ('topic' in params)) {
      const topicId = params['topic'];

      this.faqTopicOptions.forEach(topic => {
        if (params['topic'] === topic.id.toString()) {
          this.form.patchValue({topics: [topic]});
        }
      });
    }
    this.loading = false
  }

  protected getFormData() {
    let data = {keywords: [], topics: []};
    const keywordsStr = this.form.value.keywords ? this.form.value.keywords.trim() : '';

    // convert the keywords string into an array of unique values
    if (keywordsStr.length) {
      data['keywords'] = keywordsStr.split(',').filter(
        (value, index, self) => self.indexOf(value) === index);
    }

    if (this.form.value.topics && this.form.value.topics.length) {
      this.form.value.topics.forEach(topic => {
        data.topics.push(topic.id);
      });
    }

    return Object.assign({}, this.form.value, data);
  }
}
