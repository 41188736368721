import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AlertService, CampaignService, CheckoutsService, LoaderService, SideNavService } from '../../_services';
import { CampaignProduct } from '../../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CheckoutConfig, CheckoutConfigDefaults, BuilderProductType } from '../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CrudSaveComponent } from '../../../app/_directives';
import { Location } from '@angular/common';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-checkout-builder',
  templateUrl: './checkout-builder.component.html',
  styleUrls: ['./checkout-builder.component.scss'],
})
export class CheckoutBuilderComponent extends CrudSaveComponent implements OnInit, OnDestroy {
  selectedProducts: CampaignProduct[];
  productType: BuilderProductType;
  checkoutId: string | number;
  checkoutConfig: CheckoutConfig = CheckoutConfigDefaults;
  multipleProducts = false;
  campaignsLoading: boolean = true;
  isLoading = true;
  checkoutURL = environment.checkoutTemplatesUrl;
  public safeCheckoutURL: SafeResourceUrl;
  private iframe: HTMLIFrameElement | null = null;

  constructor(
    protected checkoutsService: CheckoutsService,
    private campaignService: CampaignService,
    protected loaderService: LoaderService,
    private sideNav: SideNavService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected location: Location,
    public modalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    protected alertService: AlertService
  ) {
    super(router, location, route, checkoutsService, alertService);

    this.safeCheckoutURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.checkoutURL);
    this.sideNav.setSideNavExpanded(false);
    this.route.params.subscribe((params) => {
      this.checkoutId = params['id'];
    });
    this.checkoutConfig.config = {
      build_mode: true,
      campaigns: [],
    };
  }

  ngOnInit(): void {
    document.body.classList.add('checkout-builder-active');
    this.loaderService.show();

    this.loaderService.loader$.subscribe((state) => {
      this.isLoading = state;
    });

    if (this.checkoutId) {
      this.checkoutsService.get(this.checkoutId).subscribe((checkout: any) => {
        this.checkoutConfig = {
          config: {
            build_mode: this.checkoutConfig.config.build_mode,
            campaigns: [],
          },
          checkout: {
            ...checkout,
            confirmation_title: checkout.confirmation_title || this.checkoutConfig.checkout.confirmation_title,
            confirmation_message: checkout.confirmation_message || this.checkoutConfig.checkout.confirmation_message,
          },
        };
        this.getCampaigns();
      });
    } else {
      this.getCampaigns();
    }
  }

  getCampaigns() {
    this.campaignService.list({}).subscribe(
      (data) => {
        this.loaderService.hide();
        this.checkoutConfig.config.campaigns = data.results;
        this.campaignsLoading = false;

        setTimeout(() => {
          this.intializeIframe();
        });
      },
      (error) => {
        this.campaignsLoading = false;
        this.loaderService.hide();
      }
    );
  }

  intializeIframe() {
    this.iframe = document.getElementById('react-iframe') as HTMLIFrameElement;
    if (this.iframe) {
      this.iframe.addEventListener('load', () => {
        this.sendMessageToIframe(this.checkoutConfig);
      });
    }
  }

  sendMessageToIframe(data: any) {
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(data, this.checkoutURL);
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.origin === this.checkoutURL) {
      if (event.data) {
        /* Main Product */
        if (event.data.selectMainProduct) {
          this.productType = BuilderProductType.Main;
          this.modalService.getModal('selectProductsDialog').open();
          this.multipleProducts = false;
        } else if (event.data.selectUpsellProduct) {
          /* Upsell Card Product */
          this.productType = BuilderProductType.Upsell;
          this.modalService.getModal('selectProductsDialog').open();
          this.multipleProducts = false;
        } else if (event.data.selectPopupProduct) {
          /* Upsell Popup Product */
          this.productType = BuilderProductType.Popup;
          this.modalService.getModal('selectProductsDialog').open();
          this.multipleProducts = false;
        } else if (event.data.selectRelatedProducts) {
          /* Related Products */
          this.productType = BuilderProductType.Related;
          this.modalService.getModal('selectProductsDialog').open();
          this.multipleProducts = true;
        } else if (event.data.checkout) {
          /* Deploy Checkout */
          this.loaderService.show();
          this.checkoutsService.deploy(this.checkoutId, event.data.checkout).subscribe(
            () => {
              this.loaderService.hide();
              this.alertService.success('Checkout Deployed Successfully');
              this.router.navigate(['/checkouts']);
            },
            (error) => {
              this.loaderService.hide();
              this.alertService.error('Error Deploying Checkout');
            }
          );
        }
      }
    }
  }

  onProductsSelected(products: CampaignProduct[]) {
    this.selectedProducts = products;
    switch (this.productType) {
      case BuilderProductType.Main:
        this.sendMessageToIframe({ mainProduct: products[0] });
        break;
      case BuilderProductType.Upsell:
        this.sendMessageToIframe({ upsellProduct: products[0] });
        break;
      case BuilderProductType.Popup:
        this.sendMessageToIframe({ popupProduct: products[0] });
        break;
      case BuilderProductType.Related:
        this.sendMessageToIframe({ relatedProducts: products });
        break;
    }

    this.productType = null;
    this.multipleProducts = false;
    this.modalService.getModal('selectProductsDialog').close();
  }

  ngOnDestroy(): void {
    this.sideNav.setSideNavExpanded(true);
    document.body.classList.remove('checkout-builder-active');
  }
}
