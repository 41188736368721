import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { PaginationService } from "./pagination.service";

@Injectable()
export class SessionService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'sessions');
  }

  getInputs(sessionId, queryParams?) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get(`${this.route}/${sessionId}/inputs/`, {params});
  }

  getFaqsData(sessionId) {
    return this.http.get(`${this.route}/${sessionId}/faqs/`)
  }

  getChildrenData(sessionId) {
    return this.http.get(`${this.route}/${sessionId}/children/`)
  }
}
