import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReportsAbstractService} from './reports-abstract.service';

@Injectable()
export class MarketplaceReportService extends ReportsAbstractService {
  public reportRoute: string = 'marketplace_report'

  constructor(protected http: HttpClient) {
    super(http);
    this.route = this.reportRoute;
  }

  getTotalRevenue(params:any){
    params['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    return this.http.post<any>(this.route + "/total_revenue/", params);
  }

  getBrandSummary(params:any){
    params['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    return this.http.post<any>(this.route + "/brand_summary/", params);
  }

  getProductSummary(params:any){
    params['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    return this.http.post<any>(this.route + "/product_summary/", params);
  }

  downloadFile(queryParams, elem) {
    queryParams['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    this.getDownloadReportData(queryParams)
      .subscribe(_ => {
        elem.loading = false
      }, error => {
        elem.loading = false
      })
  }

}
