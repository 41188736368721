import {formatMoney} from "../_helpers/functions";
import {BaseModel} from "./base-model";
import {Customer} from "./crm";
import {BillingCycleTypeEnum, CampaignProduct, getVariantLabel, Product, Variant} from "./product";
import {FulfillmentStatus} from "./fulfillment";

export enum OrderStatus {
  Pending = 0,
  Complete = 1,
  Partial = 2,
  Declined = 3,
  Refunded = 4,
  Cancelled = 5,
  CODPending = 6,
  Deleted = 7,
}

export enum OrderTestType {
  Recurring = 1,
  InTrial = 2,
  OutOfTrial = 3,
  OneTimeShipped = 4,
  OneTimeInHold = 5
}

interface BaseOrder extends BaseModel {
  crm: string | number;
  campaign: string | number;
  order_id: string;
  actual_order_id: string;
  status: OrderStatus;
  test_type: OrderTestType;
  is_fully_refunded: boolean;
  is_3ds: boolean;
}

export interface Order extends BaseOrder {
  customer: string | number;
}

export interface NewOrder extends BaseOrder {
  customer: Customer;
}

export interface TestOrder {
  phone?: string;
  email?: string;
  campaign_products: (string | number)[];
  decline_transaction: boolean;
  mark_shipped: boolean;
  force_rebill: boolean;
}

export interface CopyTestOrder {
  decline_transaction: boolean;
  mark_shipped: boolean;
  force_rebill: boolean;
  copy_pricing: boolean;
}

export enum ItemStatus {
  Pending = 0,
  Trial = 1,
  Active = 2,
  Cancelled = 3,
  RecycleFailed = 4,
  RecycleBilling = 5,
  Refunded = 6,
  Declined = 7,
  Complete = 8,
  Replaced = 9,
  Paused = 10,
  ThirdPartyCancel = 11,
  RecycleFailedHold = 12,
}

export const ItemStatusLabels = [
  'Pending',
  'In Trial',
  'Active',
  'Cancelled',
  'Recycle Failed',
  'Recycle Billing',
  'Refunded',
  'Declined',
  'Complete',
  'Replaced',
  'Paused',
  'Cancelled',
  'Recycle Failed',
]

export enum ItemPendingStatus {
  NotPending = 0,
  Cancel = 1,
  Return = 2,
  Exchange = 3,
  CancelSubscription = 4
}

export interface Item extends BaseModel {
  crm: string | number;
  order: Order;
  quantity: number;
  price: number;
  shipping_price: number;
  total_price: number;
  tax: number;
  status: ItemStatus;
  billing_cycle_type: BillingCycleTypeEnum;
  billing_interval_days: number;
  billing_cycle: number;
  final_billing_cycle: number;
  next_bill_date: string;
  pause_resume_date: string;
  cancel_date: string;
  item_id: string;
  created: string;
  updated: string;
  campaign_product: CampaignProduct;
  product: Product;
  is_active: boolean;
  can_cancel: boolean;
  can_return: boolean;
  can_exchange: boolean;
  return_by_date: string;
  exchange_by_date: string;
  fulfillment_status: FulfillmentStatus;
  pending_status: ItemPendingStatus;
  recurring_price: number;
  recurring_shipping_price: number;
  current_price: number;
  name: string;
  extra_return_days: number;
  variant: Variant;
}

export enum TransactionPaymentSource {
  CreditCard = 0,
  Check = 1,
  Prepaid = 2,
  COD = 3,
  DirectDebit = 4,
  Paypal = 5,
  ApplePay = 6,
  GooglePay = 7,
  Other = 8
}

export const TransactionPaymentSourceLabel = []
TransactionPaymentSourceLabel[TransactionPaymentSource.CreditCard] = 'Credit Card';
TransactionPaymentSourceLabel[TransactionPaymentSource.Check] = 'Check';
TransactionPaymentSourceLabel[TransactionPaymentSource.Prepaid] = 'Prepaid'
TransactionPaymentSourceLabel[TransactionPaymentSource.COD] = 'Cash on Delivery'
TransactionPaymentSourceLabel[TransactionPaymentSource.DirectDebit] = 'Direct Debit'
TransactionPaymentSourceLabel[TransactionPaymentSource.Paypal] = 'Paypal'
TransactionPaymentSourceLabel[TransactionPaymentSource.ApplePay] = 'Apple Pay'
TransactionPaymentSourceLabel[TransactionPaymentSource.GooglePay] = 'Google Pay'
TransactionPaymentSourceLabel[TransactionPaymentSource.Other] = 'Other'

export enum CardType {
  None = 0,
  Visa = 1,
  Mastercard = 2,
  Amex = 3,
  Discover = 4,
  Other = 5
}

export const CardTypeLabels = []
CardTypeLabels[CardType.None] = 'No Credit Card';
CardTypeLabels[CardType.Visa] = 'Visa';
CardTypeLabels[CardType.Mastercard] = 'Mastercard';
CardTypeLabels[CardType.Amex] = 'AMEX';
CardTypeLabels[CardType.Discover] = 'Discover';
CardTypeLabels[CardType.Other] = 'Other';

export enum TranType {
  Sale = 1,
  Authorize = 2,
  Capture = 3,
  Void = 4,
  Refund = 5,
}

export const TranTypeLabel = []
TranTypeLabel[TranType.Sale] = 'Sale';
TranTypeLabel[TranType.Authorize] = 'Authorize';
TranTypeLabel[TranType.Capture] = 'Capture';
TranTypeLabel[TranType.Void] = 'Void';
TranTypeLabel[TranType.Refund] = 'Refund';

export function getItemProductName(item: Item, includePrice: boolean = false): string {
  let name = item.campaign_product.name;
  const variantLabel = getVariantLabel(item.variant, false);

  if (variantLabel) {
    name += ' - ' + variantLabel;
  }

  if (includePrice) {
    name += ' ($' + formatMoney(item.total_price) + ')';
  }

  return name;
}
