import {Component, OnInit} from '@angular/core';
import {StorageService, AlertService} from "../_services";
import {Location} from "@angular/common";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './profiler.component.html',
  styleUrls: ['./profiler.component.css']
})
export class ProfilerEnableComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private alertService: AlertService,
    private location: Location
  ) { }

  ngOnInit() {
    this.storageService.set('profilerEnabled', true);
    this.alertService.success('Profiler Enabled', true);
    this.location.back();
  }

}
