import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  StepEditorIds,
  StepElements,
  StepResponseScreen,
  StepResponseScreenLayout,
  CampaignProduct,
  CustomStepCategoryEnum,
  getFunnelPathVariables,
} from '../../../_models';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StepEmulatorService, StepTemplateService } from '../../../_services';
import { addDummyValues, FontFamilyFormats, FontSizes, initializeEditorStyles } from '../../step-util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as tinymce from 'tinymce';
import { FormControlStatus } from '../../../_forms';

@Component({
  selector: 'step-response-screen',
  templateUrl: './step-response-screen.component.html',
  styleUrls: ['./step-response-screen.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class StepResponseScreenComponent implements OnInit, OnDestroy, OnChanges {
  @Input() layout: StepResponseScreenLayout;
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() theme: string;
  @Input() product: CampaignProduct = {} as CampaignProduct;
  @Input() stepType: CustomStepCategoryEnum;
  stepResponseScreen: StepResponseScreen;
  stepEditorIds = StepEditorIds;
  stepElements = StepElements;
  private subscription: Subscription;
  funnelPathVariables: { value: string; text: string }[] = [];
  @ViewChild('responseInputs', { static: false }) responseInputs: ElementRef;

  constructor(protected stepService: StepTemplateService, protected stepEmulatorService: StepEmulatorService) {}

  ngOnInit() {
    this.subscription = this.stepEmulatorService.stepResponseScreen$.subscribe((data) => {
      this.stepResponseScreen = data;
    });

    this.funnelPathVariables = getFunnelPathVariables(this.stepType);
    this.applyTheme();
    this.initalizeValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.theme && changes.theme.currentValue) {
      if (this.stepResponseScreen) {
        this.applyTheme();
      }
    }

    if (changes.product && this.product.id) {
      const title = this.form.get('response_data').get('label').value,
        response = this.form.get('response_data').get('content').value;
      this.onTitleChange(title);
      this.onResponseChange(response);
    }
  }

  applyTheme() {
    this.stepResponseScreen.detailButton.backgroundColor = this.theme;
    this.stepResponseScreen.continueButton.borderColor = this.theme;
    this.stepResponseScreen.continueButton.fontColor = this.theme;
  }

  initalizeValues() {
    this.stepResponseScreen.titleContent = addDummyValues(
      this.form.get('response_data').get('label').value,
      this.funnelPathVariables
    );
    this.stepResponseScreen.responseContent = addDummyValues(
      this.form.get('response_data').get('content').value,
      this.funnelPathVariables
    );
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  responseTitleEditor = {
    plugins: 'line-height emoticons paste autoresize',
    toolbar:
      'bold italic underline strikethrough | fontsizeselect fontselect lineheightselect | forecolor backcolor | alignleft aligncenter alignright | emoticons ',
    fontsize_formats: FontSizes,
    font_formats: FontFamilyFormats,
    paste_as_text: true,
    auto_focus: this.stepEditorIds.ResponseTitle,
    min_height: 110,
    max_height: 200,
    autoresize_bottom_margin: 0,
    setup: (editor: tinymce.Editor) => {
      initializeEditorStyles(editor, '24px', "''Open Sans', Ubuntu, sans-serif", true);

      editor.on('Change KeyUp', () => {
        const content = editor.getContent();
        this.onTitleChange(content);
      });
    },
  };

  responseEditor = {
    plugins: 'line-height emoticons image media link paste autoresize',
    toolbar:
      'bold italic underline strikethrough link | fontsizeselect fontselect lineheightselect | forecolor backcolor | alignleft aligncenter alignright | image media | emoticons',
    fontsize_formats: FontSizes,
    font_formats: FontFamilyFormats,
    paste_as_text: true,
    auto_focus: this.stepEditorIds.ResponseTitle,
    min_height: 200,
    max_height: 300,
    autoresize_bottom_margin: 0,
    setup: (editor: tinymce.Editor) => {
      initializeEditorStyles(editor, '22px', "'Open Sans', Ubuntu, sans-serif", true);

      editor.on('Change KeyUp', () => {
        const content = editor.getContent();
        this.onResponseChange(content);
      });
    },
  };

  updateStepResponseScreen() {
    this.stepEmulatorService.updateStepResponseScreen(this.stepResponseScreen);
  }

  onTitleChange(content: string) {
    const updatedContent = addDummyValues(content, this.funnelPathVariables, this.product);
    this.stepResponseScreen.titleContent = updatedContent;
  }

  onResponseChange(content: string) {
    const updatedContent = addDummyValues(content, this.funnelPathVariables, this.product);
    this.stepResponseScreen.responseContent = updatedContent;
  }

  addResponsTitleVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    const editorInstance = tinymce.get(this.stepEditorIds.ResponseTitle);
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }

    selectElement.selectedIndex = 0;
  }

  addResponseVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    const editorInstance = tinymce.get(this.stepEditorIds.Response);
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }

    selectElement.selectedIndex = 0;
  }

  get labelInvalidity() {
    const responseData = this.form.get('response_data') as FormGroup;
    return this.enableFormErrors && responseData.controls.label.status === FormControlStatus.Invalid;
  }

  get contentInvalidity() {
    const responseData = this.form.get('response_data') as FormGroup;
    return this.enableFormErrors && responseData.controls.content.status === FormControlStatus.Invalid;
  }

  getElementRef(): ElementRef | null {
    if (this.labelInvalidity || this.contentInvalidity) {
      if (!this.isElementVisible(this.stepElements.Response)) {
        this.toggleElement(this.stepElements.Response);
      }

      return this.responseInputs;
    }

    return null;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
