import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceService, UserService} from '../_services';
import {Invoice, InvoiceStatus, InvoiceStatusLabels, User} from '../_models';
import {CrudPagedListComponent} from '../_directives';
import {Observable} from "rxjs";
import {NgxSmartModalService} from 'ngx-smart-modal';
import {formatMoney, parseISODateString} from '../_helpers';
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './invoice.component.html'
})
export class InvoiceComponent extends CrudPagedListComponent implements OnInit {
  invoices$: Observable<Invoice[]> = this.data$;
  selectedInvoice: Invoice;
  pricingOnly = false;
  user: User;

  private isRegenerating: {} = {};

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected invoiceService: InvoiceService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService,
    private userService: UserService
  ) {
    super(router, location, route, invoiceService, alertService);
    this.objectName = 'invoice';
    this.title = 'Invoices';
  }

  ngOnInit() {
    super.ngOnInit();

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  viewDetails(invoice: Invoice, pricingOnly: boolean = false) {
    this.selectedInvoice = invoice;
    this.pricingOnly = pricingOnly;
    this.modalService.getModal('invoiceDialog').open();
  }

  getStatus(invoice: Invoice) {
    return InvoiceStatusLabels[invoice.status];
  }

  getDate(invoice: Invoice) {
    const date = parseISODateString(invoice.settle_date);

    return date.toLocaleDateString("en-US", {timeZone: 'UTC'});
  }

  getAmount(amount: string) {
    return '$' + formatMoney(amount);
  }

  canCharge(invoice: Invoice) {
    if (this.user && this.user.is_staff) {
      return (invoice.status === InvoiceStatus.Unpaid) || (invoice.status === InvoiceStatus.Declined) ||
        (invoice.status === InvoiceStatus.Pending);
    }

    return false;
  }

  canPrice(invoice: Invoice) {
    return this.user && this.user.is_staff;
  }

  charge(invoice: Invoice) {
    if (this.canCharge(invoice)) {
      this.selectedInvoice = invoice;
      this.modalService.getModal('invoiceChargeDialog').open();
    }
  }

  onInvoiceCharged(invoice: Invoice) {
    this.modalService.getModal('invoiceChargeDialog').close();
    this.queryData();
    this.selectedInvoice = null;
  }

  canSplit(invoice: Invoice) {
    if (this.user && this.user.is_staff) {
      return invoice.status === InvoiceStatus.Pending;
    }

    return false;
  }

  split(invoice: Invoice) {
    if (this.canSplit(invoice)) {
      this.selectedInvoice = invoice;
      this.modalService.getModal('invoiceSplitDialog').open();
    }
  }

  onInvoiceSplit(invoice: Invoice) {
    this.modalService.getModal('invoiceSplitDialog').close();
    this.queryData();
    this.selectedInvoice = null;
  }

  canRegenerate(invoice: Invoice) {
    if (this.user && this.user.is_staff) {
      return this.user.is_superuser || invoice.status === InvoiceStatus.Pending;
    }

    return false;
  }

  regenerate(invoice: Invoice) {
    if (this.canRegenerate(invoice) && window.confirm('Are you sure you want to regenerate this invoice?')) {
      this.isRegenerating[invoice.id] = true;
      this.invoiceService.regenerate(invoice.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.alertService.success('Successfully regenerated invoice.');
            this.isRegenerating[invoice.id] = false;
            this.queryData();
          },
          error => {
            this.handleSubmitError(error);
            this.isRegenerating[invoice.id] = false;
          });
    }
  }

  isBusyRegenerating(invoice: Invoice) {
    return (invoice.id in this.isRegenerating) && this.isRegenerating[invoice.id]
  }

}
