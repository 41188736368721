import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from "./pagination.service";

@Injectable()
export class InvoiceItemService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'invoice_items');
  }

  cancel(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/cancel/', {});
  }

}
