import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {CrudSavePopupComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService, UserService, WidgetService, WidgetCategoryService, FolderService} from '../_services';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Category, Folder, Pager, User} from '../_models';
import {BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  selector: 'setting-form',
  styleUrls: ['./design-template.component.css'],
  templateUrl: './template-setting.component.html',
})
export class TemplateSettingComponent extends CrudSavePopupComponent implements OnInit {

  @Output('delete') onDelete: EventEmitter<any> = new EventEmitter<any>();
  widgetCategories$: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>(null);
  categoryForm: FormGroup;
  user: User;
  public folders: Folder[] = []

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected widgetService: WidgetService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService,
              protected widgetCategoryService: WidgetCategoryService,
              protected userService: UserService,
              protected folderService: FolderService) {
    super(router, location, route, widgetService, alertService);
    this.objectName = 'template setting';
    this.widgetCategoryService.list({})
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: Pager) => {
        this.widgetCategories$.next(res.results);
    },
        error => {
          this.loading = false;
        this.handleError(error);
        });
    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null, Validators.required],
      content: [null],
      resourcetype: [null],
      campaign: [null],
      product: [null],
      campaign_product: [null],
      style: [null],
      enhanced_mode: [false],
      enhanced_content: [null],
      categories: [null],
      is_global: [false],
      folder: [null],
      include_in_copy: [false],
    }));
    super.ngOnInit();
    this.categoryForm = new FormGroup({
      name: new FormControl(''),
      is_global: new FormControl(false),
    });

    this.folderService.list({})
      .subscribe(data => {
        this.folders = data.results
      })
  }

  getSubmitBtnText(): string {
    if (this.inputData && this.inputData.id) {
      return 'Update';
    }

    return 'Save';
  }

  protected onSaveComplete(data) {
    const id = this.inputData.id;
    super.onSaveComplete(data);
    if (!id) {
      this.router.navigate(['/template/edit', data.id]);
    }
  }

  delete() {
    this.onDelete.emit(this.inputData.id);
    this.onCancel.emit();
  }

  addTagFn(name: string) {
    return new Promise((resolve) => {
      this.loading = true;
      this.categoryForm.controls['name'].patchValue(name);
      this.widgetCategoryService.create(this.getCategoryFormData())
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
        this.loading = false;
        this.widgetCategories$.next(this.widgetCategories$.getValue().concat(res));
        resolve(res);
      },
      error => {
        this.loading = false;
        this.handleError(error);
      });
    });
  }

  getCategoryFormData() {
    return this.categoryForm.value;
  }

  onSubmit() {
    const categories = [];
    const categoriesToAdd = this.form.value.categories;
    categoriesToAdd.forEach((category) => {
      if (category.id) {
        categories.push(category.id);
      }
    });
    Object.assign(this.form.value.categories, categories);
    super.onSubmit();
  }
}
