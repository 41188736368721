import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { PaginationService } from "./pagination.service";
import {Observable} from "rxjs";

@Injectable()
export class CRMCampaignService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'crm_campaigns');
  }

  listAll(queryParams: any): Observable<any> {
    let params = new HttpParams({fromObject: queryParams});

    return this.http.get<any>(this.route + '/list_all/', {params: params});
  }

  listShippingMethods(id: number): Observable<any> {
    return this.http.get<any>(this.route + '/' + id.toString() + '/shipping_methods/');
  }

}
