import {Component} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, UserService, FolderService} from '../_services';
import {Folder, FolderType, User} from '../_models';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './folder-edit.component.html',
})
export class FolderNewComponent extends CrudSaveComponent {
  public type: FolderType = FolderType.Widget;
  public parent: string | number = null;
  public folder: Folder = null;
  public user: User = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected folderService: FolderService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected userService: UserService
  ) {
    super(router, location, route, folderService, alertService);
    this.isNew = true;
    this.objectName = "folder"
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      include_in_copy: [false],
      is_global: [false]
    });
    super.ngOnInit();

    this.type = this.route.snapshot.params['type'] || FolderType.Widget;
    this.parent = this.route.snapshot.params['parent'] || null;

    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user
      }, error => {
        this.handleError(error)
      })
  }

  canChangeFolder(folder: Folder) {
    return !folder.is_global || (this.user && this.user.is_staff)
  }

  protected getFormData(): any {
    const data = super.getFormData();
    data['type'] = this.type;
    data['parent'] = this.parent;
    return data
  }

  get subFolders() {
    if (this.folder) {
      return this.folder.children || []
    }

    return []
  }

  delete(id, objectName = '') {
    if(window.confirm('Are you sure you want to delete ' + objectName + '?')) {
      this.loading = true;

      this.folderService.delete(id)
        .subscribe(_ => {
          this.alertService.success('Successfully deleted ' + objectName);
          this.loading = false
        })
    }
  }

  canPasteFolder() {
    return this.folderService.canPaste()
  }

  copy(folderId: string | number) {
    this.folderService.copy(folderId)
  }

  paste() {
    this.loading = true;
    if (this.folder) {
      this.folderService.paste(this.folder.id)
        .subscribe(_ => {
          this.folderService.get(this.folder.id)
            .subscribe(data => {
              this.loading = false;
              this._data$.next(data)
            }, error => {
              this.loading = false;
              this.handleError(error)
            })
        }, error => {
          this.loading = false;
          this.handleError(error)
        })
    }
  }
}
