import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudService} from "./crud.service";

@Injectable()
export class SipCredentialService extends CrudService {
  constructor(
    protected http: HttpClient
  ) {
    super(http, 'sip_domain');
  }

  setDomainId(id: string | number) {
    this.route = 'sip_domain/' + id.toString() + '/credentials';
  }

}
