import {OnInit} from "@angular/core";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Form} from '../_forms';
import * as moment from "moment";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService} from "../_services";

export class LogsContainerAbstractComponent extends Form implements OnInit {
  public ranges = {
    'Today': this.setDateRangeToToday(),
    'Yesterday': this.setDateRangeToYesterday(),
    'Past 7 Days': this.setDateRangeToLastNDays(7),
    'Month to Date': this.setDateRangeToCurrentMonth(),
    'Last Month': this.setDateRangeToLastMonth(),
    'Last 30 days': this.setDateRangeToLastNDays(31),
  };
  public maxDate = moment();
  public alwaysShowCalendars = true;
  public showClearButton = true;
  public ready = false;

  constructor(
    protected alertService: AlertService,
    protected router: Router,
    protected location: Location,
    protected formBuilder: FormBuilder
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    let currentDate = new Date();
    let normalizedDate: any = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
      0, 0, 0);
    normalizedDate = moment(normalizedDate);
    this.form = this.formBuilder.group({
      date_range: new FormControl({startDate: normalizedDate, endDate: normalizedDate}, Validators.required),
    });
  }

  setDateRangeToToday() {
    return [new Date(), new Date()];
  }

  setDateRangeToYesterday() {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    return [date, date]
  }

  setDateRangeToLastNDays(numDays: number) {
    let date = new Date();

    date.setDate((date.getDate() + 1) - numDays);

    return [date, new Date()];
  }

  setDateRangeToLastMonth() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1st of last month
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);

    //end date = last day of previous month
    endDate.setDate(0);

    return [startDate, endDate];
  }

  setDateRangeToCurrentMonth() {
    return this.setDateRangeToNPastMonthsToDate(0);
  }

  setDateRangeToNPastMonthsToDate(numPastMonths: number) {
    let date = new Date();

    date.setDate(1);
    date.setMonth(date.getMonth() - numPastMonths);

    return [date, new Date()];
  }

  setDateRangeToCurrentYear() {
    let date = new Date();

    date.setDate(1);
    date.setMonth(0);

    return [date, new Date()];
  }

  setDateRangeToLastYear() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1/1 of last year
    startDate.setMonth(0);
    startDate.setDate(1);
    startDate.setFullYear(startDate.getFullYear() - 1);

    //end date = 12/31 of last year
    endDate.setMonth(11);
    endDate.setDate(31);
    endDate.setFullYear(endDate.getFullYear() - 1);

    return [startDate, endDate];
  }
}
