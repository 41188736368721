import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ProductCategoryService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Category, FulfillmentTypeEnum, Image, ImageType, Pager, Product, ProcessTypeEnum} from "../_models";
import {config} from '../../config/config';
import {takeUntil} from "rxjs/operators";
import {Form} from '../_forms';
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-basic-fields.component.html',
  selector: 'product-basic-fields',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class ProductBasicFieldsComponent extends Form implements OnInit, OnChanges {
  categories: Category[] = [];
  imageType: ImageType = ImageType.ProductImage;
  defaultImage: Image;
  processTypes = [
    {value: ProcessTypeEnum.Sale, label: 'Sale (Default)'},
    {value: ProcessTypeEnum.Fee, label: 'Fee'},
    {value: ProcessTypeEnum.Warranty, label: 'Warranty'},
    {value: ProcessTypeEnum.Installment, label: 'Installment'},
  ];

  @Input('product') product: Product;
  @Input('isBulk') isBulk: boolean = false

  constructor(
    protected router: Router,
    protected location: Location,
    private categoryService: ProductCategoryService,
    private formBuilder: FormBuilder,
    private modalService: NgxSmartModalService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.updateForm();
    super.ngOnInit();

    this.categoryService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.categories = data.results;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm() {
    this.setForm(this.formBuilder.group({
      name: [null],
      categories: [[]],
      process_type: [ProcessTypeEnum.Sale, Validators.required],
      hold_period: this.isBulk ? [null, [Validators.min(0)]] : [6, [Validators.required, Validators.min(0)]],
      hold_period_max_cycle: this.isBulk ?
        [null, [Validators.min(0)]] : [1, [Validators.required, Validators.min(0)]],
      shipping_hold_period: [null, [Validators.min(0)]],
      override_quantity: [null],
    }));

    if (this.product) {
      this.form.patchValue(this.product);
      this.defaultImage = this.product.default_image;
    }
  }

  get isNonShippable() {
    return !this.product || (this.product.fulfillment_type === FulfillmentTypeEnum.NoShipment);
  }

  get isNormalSaleProduct() {
    return this.isFieldEqual('process_type', ProcessTypeEnum.Sale);
  }

  removeImage() {
    this.defaultImage = null;
  }

  onImageSelectStart() {
    this.modalService.getModal('defaultImageDialog').open();
  }

  onImageSelectDone(image: Image) {
    this.defaultImage = image;
    this.modalService.getModal('defaultImageDialog').close();
  }

  getFormData() {
    let data: {} = this.form.value;
    const allow_blank_fields = ['hold_period', 'hold_period_max_cycle', 'override_quantity', 'shipping_hold_period'];

    if (this.defaultImage) {
      data['default_image'] = this.defaultImage.id;
    }

    //convert empty strings to null for certain fields so the api will accept them
    allow_blank_fields.forEach((field: string) => {
      if (field in data) {
        let value = data[field];

        if (typeof(value) == 'string') {
          if (value.trim().length == 0) {
            data[field] = null;
          }
        }
      }
    });

    return data;
  }

}
