import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignCategoryService, CampaignService, UserService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CampaignNewComponent} from "./campaign-new.component";
import {Campaign} from "../_models";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.css']
})
export class CampaignEditComponent extends CampaignNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    protected categoryService: CampaignCategoryService,
    protected userService: UserService,
    protected modalService: NgxSmartModalService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService, categoryService, userService, modalService,
      formBuilder);
    this.isNew = false;
    this.isPartial = true;
    this.isNewCampaign = false;
    this.showSuccessMessage = true;
    this.title = 'Brand Settings'
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe(
      (campaign: Campaign) => {
        if (campaign) {
          if (!campaign.support_email_fields) {
            campaign.support_email_fields = []
          }

          this.form.patchValue(campaign);
          this.campaign = campaign;
          this.campaignService.showMenu(campaign, 'edit', false, campaign.is_hybrid);
          this.isHybrid = campaign.is_hybrid;

          this.imageFields.forEach(field => {
            if (campaign[field.name]) {
              this.images[field.name] = campaign[field.name];
            }
          });
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected onSaveComplete(data) {
    this.goBack();
  }
}
