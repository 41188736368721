import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from './pagination.service';
import {Observable, of as observableOf} from 'rxjs';
import {TicketSystemType} from '../_models';
import {map} from 'rxjs/operators';

@Injectable()
export class TicketSystemService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'ticket_systems');
  }

  getTypes(): Observable<TicketSystemType[]> {
    let typesString = sessionStorage.getItem('ticketSystemTypes');

    if (typesString) {
      //get the cached crm types
      return observableOf(JSON.parse(typesString));
    }
    else {
      //crm types not cached, so get it from the server
      return this.http.get<any>(this.route + '/types/').pipe(map((types: TicketSystemType[]) => {
        sessionStorage.setItem('ticketSystemTypes', JSON.stringify(types)); //cache the crm types returned from the server
        return types;
      }));
    }
  }
}
