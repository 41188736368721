import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelInputService, FunnelService, FunnelStepService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {
  Funnel,
  FunnelInput,
  FunnelStep,
  FunnelInputTypeEnum
} from "../_models";
import {takeUntil} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {FunnelInputFieldsComponent} from "./funnel-input-fields.component";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-input-edit.component.html',
  styleUrls: ['./funnel-input.component.css']
})
export class FunnelInputNewComponent extends CrudSaveComponent implements OnInit, AfterViewInit {
  otherSteps: FunnelStep[] = [];
  input: FunnelInput;
  step: FunnelStep;
  funnel: Funnel;
  @ViewChild(FunnelInputFieldsComponent, { static: false }) inputFields: FunnelInputFieldsComponent;

  protected addNewStep = false;
  protected addNewAutopickStep = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected inputService: FunnelInputService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected stepService: FunnelStepService,
    protected funnelService: FunnelService
  ) {
    super(router, location, route, inputService, alertService);
    this.isNew = true;
    this.objectName = 'Path Input';
    this.title = 'Create New Path Input';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    super.ngOnInit();
  }

  protected onRouteParams(params: {}) {
    if (('step_id' in params) && ('funnel_id' in params)) {
      let stepId = params['step_id'];
      let funnelId = params['funnel_id'];

      this.inputService.setStepId(stepId);
      this.stepService.setFunnelId(funnelId);

      forkJoin([
        this.funnelService.get(funnelId),
        this.stepService.list()
      ]).pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: [Funnel, FunnelStep[]]) => {
            this.funnel = data[0];

            data[1].forEach((step: FunnelStep) => {
              if (step.id == stepId) {
                this.step = step;
              } else {
                this.otherSteps.push(step);
              }
            });
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.setForm(this.inputFields.form), 0);
  }

  get stepType() : FunnelInputTypeEnum {
    return this.step ? this.step.type : FunnelInputTypeEnum.Choice;
  }

  copy() {
    if (this.input) {
      this.inputService.copy(this.input.id);
    }
  }

  protected getFormData() {
    let data = this.inputFields.getFormData();

    // convert icon structure into icon id
    if (data.icon) {
      data.icon = data.icon.id;
    }

    if (data.next_step === 'new') {
      data.next_step = null;
      this.addNewStep = true; //add a new step after saving
    }

    if (data.autopick_next_step === 'new') {
      data.autopick_next_step = null;
      this.addNewAutopickStep = true; //add a new autopick step after saving
    }

    return data;
  }

  protected onSaveComplete(data) {
    if (this.addNewStep) {
      this.addNewStep = false;
      this.navigate(['/path', this.funnel.id, 'step', 'new', {prev_input: this.id}],
        {replaceUrl: true});
    }
    else if (this.addNewAutopickStep) {
      this.addNewAutopickStep = false;
      this.navigate(['/path', this.funnel.id, 'step', 'new', {prev_input: this.id, autopick: true}],
        {replaceUrl: true});
    }
    else {
      this.goBack();
    }
  }
}
