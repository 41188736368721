import {Component, OnInit} from '@angular/core';
import {CampaignComponent} from "../campaign";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, CampaignCategoryService, CampaignService} from "../_services";
import {FormBuilder} from "@angular/forms";

@Component({
  moduleId: module.id.toString(),
  templateUrl: '../campaign/campaign.component.html'
})
export class HybridCampaignComponent extends CampaignComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    protected categoryService: CampaignCategoryService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService, categoryService, formBuilder);
    this.title = 'Hybrid Brands';
    this.isHybrid = true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
