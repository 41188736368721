import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  StepDiscountSettingTypes,
  StepDiscountSettingsLayout,
  StepDurationUnits,
  StepElements,
} from '../../../_models/steps';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { CustomStepCategoryEnum, DiscountTypeEnum, ExecutionTypeEnum } from '../../../_models';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormControlStatus } from '../../../_forms';
import { StepEmulatorService } from '../../../_services';
import { HoldOffAbstractComponent } from './hold-off-abstract.component';
import { MatExpansionPanel } from '@angular/material';

@Component({
  selector: 'step-discount-settings',
  templateUrl: './step-discount-settings.component.html',
  styleUrls: ['./step-discount-settings.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class StepDiscountSettingsComponent extends HoldOffAbstractComponent implements OnInit {
  @Input() layout: StepDiscountSettingsLayout;
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() stepType: CustomStepCategoryEnum;
  @Input() isNew: boolean;
  @Input() templateId: string;
  stepElements = StepElements;
  discountStepType: StepDiscountSettingTypes;
  discountTypes = DiscountTypeEnum;
  executionTypes = ExecutionTypeEnum;
  stepDurationUnits = StepDurationUnits;
  inputTitle: string = 'Discount Amount';
  selectTitle: string = 'Discount if index not found';
  selectedBillingCycle: null | number = null;
  userControlledNextBillingDate: boolean = true;
  userControlledMaxBillingDate: boolean = true;
  discountIndexList = [
    { value: 0, label: '1st Product Discount' },
    { value: 1, label: '2nd Product Discount' },
    { value: 2, label: '3rd Product Discount' },
    { value: 3, label: '4th Product Discount' },
    { value: 4, label: '5th Product Discount' },
  ];
  upsaleIndexList = [
    { value: 0, label: '1st Upsell Product' },
    { value: 1, label: '2nd Upsell Product' },
    { value: 2, label: '3rd Upsell Product' },
    { value: 3, label: '4th Upsell Product' },
    { value: 4, label: '5th Upsell Product' },
  ];
  @ViewChild('discountSettingInputs', { static: false }) discountSettingInputs: ElementRef;
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;

  constructor(private stepEmulatorService: StepEmulatorService) {
    super();
  }

  ngOnInit() {
    const inputs = this.form.get('inputs') as FormArray;
    const firstInput = inputs.at(0) as FormGroup;
    this.actions = (firstInput.get('actions') as FormArray).at(0) as FormGroup;

    this.discountStepType = this.layout.type;
    if (this.isDiscountRefund) {
      this.actions.patchValue({
        bill_now: false,
        final_billing_cycle_offset: null,
      });
    } else if (this.isFutureOrder) {
      this.actions.patchValue({
        include_tax: false,
      });
    }

    if (this.isReactivateSubscription) {
      this.selectedBillingCycle = this.actions.get('billing_interval_days').value;
      if (this.selectedBillingCycle > 0 && this.selectedBillingCycle !== null) {
        this.selectedBillingCycle = 1;
      }

      const next_bill_date_delay = this.actions.get('next_bill_date_delay').value;
      const max_bill_date_delay = this.actions.get('max_bill_date_delay').value;
      this.userControlledNextBillingDate = next_bill_date_delay === null;
      this.userControlledMaxBillingDate = max_bill_date_delay === null;
    } else {
      super.ngOnInit();
    }

    this.switchDiscountType();
  }

  updateBillingCycle() {
    if (this.selectedBillingCycle === null || this.selectedBillingCycle === 0) {
      this.actions.patchValue({
        billing_interval_days: this.selectedBillingCycle,
      });
    }
  }

  nextBillingDateUpdate() {
    if (this.userControlledNextBillingDate) {
      this.actions.patchValue({
        next_bill_date_delay: null,
      });
    }
  }

  maxBillingDateUpdate() {
    if (this.userControlledMaxBillingDate) {
      this.actions.patchValue({
        max_bill_date_delay: null,
      });
    }
  }

  switchDiscountType() {
    const discountControl = this.actions.get('discount');

    if (discountControl) {
      const discount = discountControl.value;

      switch (this.actions.get('discount_type').value) {
        case DiscountTypeEnum.Fixed:
          this.inputTitle = 'Discount Amount';
          discountControl.setValidators([Validators.required, Validators.min(1)]);
          this.actions.patchValue({
            discount,
            discount_index: null,
          });
          break;

        case DiscountTypeEnum.Percent:
          this.inputTitle = 'Discount Percent';
          discountControl.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);

          this.actions.patchValue({
            discount,
            discount_index: null,
          });
          break;

        case DiscountTypeEnum.PercentOfOrder:
          this.inputTitle = 'Discount Percent';
          discountControl.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
          this.actions.patchValue({
            discount,
            discount_index: null,
            check_last_discount: false,
          });
          break;

        case DiscountTypeEnum.Index:
          this.inputTitle = 'Discount index';
          discountControl.setValidators([]);
          this.actions.patchValue({
            discount_index: 0,
          });
          break;

        case DiscountTypeEnum.IndexOrFixed:
          this.inputTitle = 'Discount index';
          discountControl.setValidators([Validators.required, Validators.min(1)]);
          this.selectTitle = 'Discount if index not found';
          this.actions.patchValue({
            discount,
            discount_index: 0,
          });
          break;

        case DiscountTypeEnum.IndexOrPercent:
          this.inputTitle = 'Discount index';
          discountControl.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
          this.selectTitle = 'Discount Percentage if index not found';
          this.actions.patchValue({
            discount,
            discount_index: 0,
          });
          break;
      }
    }
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  get isFixed() {
    return this.actions.get('discount_type').value === DiscountTypeEnum.Fixed;
  }
  get isPercent() {
    return this.actions.get('discount_type').value === DiscountTypeEnum.Percent;
  }
  get isIndex() {
    return this.actions.get('discount_type').value === DiscountTypeEnum.Index;
  }
  get isIndexOrFixed() {
    return this.actions.get('discount_type').value === DiscountTypeEnum.IndexOrFixed;
  }
  get isIndexOrPercent() {
    return this.actions.get('discount_type').value === DiscountTypeEnum.IndexOrPercent;
  }
  get isPercentOrder() {
    return this.actions.get('discount_type').value === DiscountTypeEnum.PercentOfOrder;
  }

  get isImmediate() {
    return this.actions.get('execution_type').value === ExecutionTypeEnum.Immediate;
  }
  get isDelayed() {
    return this.actions.get('execution_type').value === ExecutionTypeEnum.Delayed;
  }
  get isValidationOnly() {
    return this.actions.get('execution_type').value === ExecutionTypeEnum.ValidationOnly;
  }

  get isDiscountRefund() {
    return this.discountStepType === StepDiscountSettingTypes.DiscountRefund;
  }

  get isFutureOrder() {
    return this.discountStepType === StepDiscountSettingTypes.FutureOrder;
  }

  get isUpsellOrder() {
    return this.stepType === CustomStepCategoryEnum.UpsellOrder;
  }

  get isReactivateSubscription() {
    return this.stepType === CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount;
  }

  get settingsInvalidity() {
    return this.enableFormErrors && this.actions.status === FormControlStatus.Invalid;
  }

  get discountInvalidity() {
    return this.enableFormErrors && this.actions.controls.discount.status === FormControlStatus.Invalid;
  }

  get holdOffInvalidity() {
    return this.enableFormErrors && this.actions.controls.holdoff_delay.status === FormControlStatus.Invalid;
  }

  getElementRef(): ElementRef | null {
    if (this.discountInvalidity || this.holdOffInvalidity) {
      if (!this.isElementVisible(this.stepElements.DiscountSettings)) {
        this.toggleElement(this.stepElements.DiscountSettings);
      }

      return this.discountSettingInputs;
    }

    return null;
  }
}
