import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, IconFileService} from '../_services';
import {CrudListComponent} from '../_directives';
import {IconFile} from '../_models';
import {Observable} from "rxjs";

@Component({
  moduleId: module.id.toString(),
  selector: 'icons',
  templateUrl: './icon.component.html',
  styleUrls: ['../image/image.component.css', './icon.component.css']
})
export class IconComponent extends CrudListComponent implements OnInit {
  icons$: Observable<IconFile[]> = this.data$;
  selectedIcon: IconFile;
  loading = false;
  uploadNewFile = false;
  @Output('select') selection: EventEmitter<any> = new EventEmitter<any>();
  @Output('cancel') cancelSelection: EventEmitter<any> = new EventEmitter<any>();

  private notifyParent = true;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected fileService: IconFileService,
    protected alertService: AlertService
  ) {
    super(router, location, route, fileService, alertService);
    this.objectName = 'icon';
    this.title = 'Select an Icon';
  }

  ngOnInit() {
    super.ngOnInit();

    //notify parent component if we are a child
    this.notifyParent = (this.route.component !== this.constructor);
  }

  select(icon: IconFile) {
    this.selectedIcon = icon; //single selection

    if (this.notifyParent) {
      this.selection.emit(this.selectedIcon); //notify the parent of the selection
    }
  }

  isSelected(icon: IconFile) {
    return this.selectedIcon && (this.selectedIcon.id === icon.id);
  }

  onUploadStart() {
    this.uploadNewFile = true;
  }

  onUploadComplete(icon: IconFile) {
    if (!this._data$.getValue().some(function(elem) {
      return elem.id === icon.id
    })) {
      this._data$.next(this._data$.getValue().concat([icon]));
    }
    
    this.select(icon);
    this.uploadNewFile = false;
  }

  onUploadCancel() {
    this.uploadNewFile = false;
  }

  cancel() {
    if (this.notifyParent) {
      this.cancelSelection.emit();
    }
    else {
      this.goBack();
    }
  }

  protected onDelete(id) {
    //icon was deleted, so make sure it's not selected
    if (this.selectedIcon && (this.selectedIcon.id === id)) {
      this.selectedIcon = null;
    }
  }

}
