import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

interface SideNavData {
  isSideNavExpanded?: boolean;
  showSideNavToggle?: boolean;
}

@Injectable({
    providedIn: "root",
  })
export class SideNavService {
  protected sideNavData: BehaviorSubject<SideNavData> = new BehaviorSubject<SideNavData>(
    {isSideNavExpanded: false, showSideNavToggle: true});

  getSideNavObs() {
    return this.sideNavData.asObservable();
  }

  setSideNavExpanded(expanded: boolean) {
    this.sideNavData.next({isSideNavExpanded: expanded});
  }

  setShowSideNavToggle(show: boolean) {
    this.sideNavData.next({showSideNavToggle: show});
  }
}


