import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReportsAbstractService} from "./reports-abstract.service";

@Injectable()
export class ReportsService extends ReportsAbstractService {
  public reportRoute: string = 'reports'
  constructor(protected http: HttpClient) {
    super(http);
    this.route = this.reportRoute;
  }
}
