import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelActionService, FunnelService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {takeUntil} from "rxjs/operators";
import {
  Action,
  Funnel
} from "../_models";
import {FunnelActionFieldsComponent} from "./funnel-action-fields.component";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-action-edit.component.html',
  styleUrls: ['./funnel-action.component.css']
})
export class FunnelActionNewComponent extends CrudSaveComponent implements OnInit, AfterViewInit {
  action: Action;
  funnel: Funnel;
  @ViewChild(FunnelActionFieldsComponent, { static: false }) actionFields: FunnelActionFieldsComponent;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected actionService: FunnelActionService,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, actionService, alertService);
    this.isNew = true;
    this.objectName = 'Path Input Action';
    this.title = 'Create New Path Input Action';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    super.ngOnInit();
  }

  ngAfterViewInit() {
    setTimeout(() => this.form = this.actionFields.form, 0);
  }

  protected onRouteParams(params: {}) {
    if ('input_id' in params) {
      let inputId = params['input_id'];

      this.actionService.setInputId(inputId);
    }

    if ('funnel_id' in params) {
      let funnelId = params['funnel_id'];

      this.funnelService.get(funnelId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (funnel: Funnel) => {
            this.funnel = funnel;
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  protected getFormData() {
    return this.actionFields.getFormData();
  }

}
