import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import {Faq, Widget, WidgetContentType} from '../_models';
import {ClipboardService} from './clipboard.service';

@Injectable()
export class WidgetService extends PaginationService {
  type: WidgetContentType = null;
  constructor(protected http: HttpClient,
              protected clipboardService: ClipboardService) {
    super(http, 'widgets');
  }

  getVariables(queryParams?: any) {
    let options = {};

    if (queryParams) {
      options['params'] = new HttpParams({fromObject: queryParams});
    }

    return this.http.get<any>('widgets/names/', options);
  }

  copy(widget: Widget) {
    this.clipboardService.copy('widget', widget);
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('widget');
  }

  paste(folderId: string | number) {
    let widget: Widget = this.clipboardService.paste('widget');
    widget.folder = folderId;
    widget.name = widget.name + ' (copy)'

    return this.http.post(this.route + '/', widget);
  }

  bulkCreate(data: any) {
    return this.http.post(this.route + '/bulk_create/', data);
  }
}
