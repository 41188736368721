import { Component, Input } from "@angular/core";

export interface DoughnutChartData {
  data: number[];
  labels: string[];
  backgroundColors?: DoughnutChartColors[];
  noData?: boolean;
  loading?: boolean;
  total?: string;
}

interface DoughnutChartColors {
  backgroundColor: string[];
}

@Component({
  selector: "doughnut-chart",
  templateUrl: "./doughnut-chart.component.html",
  styleUrls: ["./doughnut-chart.component.scss"],
})
export class DoughnutChartComponent {
  @Input() cutoutPercentage: number = 85;
  @Input() totalSessions: string;
  @Input() chartData: DoughnutChartData = {
    data: [100],
    labels: ["void"],
    backgroundColors: [
      {
        backgroundColor: ["#B1BEC4"],
      },
    ],
  };

  chartOptions = {};

  ngOnChanges(): void {
    this.chartOptions = {
      cutoutPercentage: this.cutoutPercentage,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "rgba(47, 49, 66, 0.8)",
        titleFontSize: 13,
        titleFontColor: "#fff",
        caretSize: 0,
        cornerRadius: 4,
        xPadding: 10,
        displayColors: true,
        yPadding: 10,
      },
      elements: {
        arc: {
          borderWidth: 0,
          borderRadius: 10,
        },
      },
    };
  }
}
