import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, WidgetService} from '../_services';
import {Form} from '../_forms';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './widget.component.html'
})
export class WidgetComponent extends Form implements OnInit {
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected widgetService: WidgetService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService) {
    super(alertService, router, location);
    this.title = 'Content';
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
