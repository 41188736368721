import { BasePlugin } from "./base";

export class LineheightPlugin extends BasePlugin {
  constructor(protected tinymce) {
    super(tinymce);
  }

  public create() {
    this.tinymce.PluginManager.add("line-height", (editor) => {
      editor.ui.registry.addIcon(
        "line-height",
        `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 8L6 5M6 5L9 8M6 5V19M3 16L6 19M6 19L9 16M13 6H20M13 12H20M13 18H20" stroke="#222F3E" stroke-opacity="0.9" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
      );

      editor.ui.registry.addMenuButton("lineheightselect", {
        icon: "line-height",
        tooltip: "Line Height",
        fetch: function (callback) {
          const node = editor.selection.getNode();
          const currentLineHeight = editor.dom.getStyle(
            node,
            "line-height",
            true
          );
          const currentFontSize = editor.dom.getStyle(node, "font-size", true);

          let lineHeightValue = parseFloat(currentLineHeight);
          if (
            currentLineHeight.endsWith("px") &&
            currentFontSize.endsWith("px")
          ) {
            const fontSizeValue = parseFloat(currentFontSize);
            lineHeightValue = lineHeightValue / fontSizeValue;
          }

          const lineHeights = ["0.8", "0.9", "1", "1.2", "1.3", "1.4", "1.5", "1.6", "2.0"];

          const items = lineHeights.map((lineHeight) => ({
            type: "menuitem",
            text: lineHeight,
            active: lineHeightValue.toFixed(1) === lineHeight,
            onAction: () => editor.execCommand("LineHeight", false, lineHeight),
          }));

          callback(items);
        },
      });

      editor.addCommand("LineHeight", (ui, value) => {
        editor.formatter.apply("lineheight", { value: value });
      });

      editor.on("init", () => {
        editor.formatter.register("lineheight", {
          inline: "span",
          styles: { "line-height": "%value" },
        });
      });
    });
  }
}
