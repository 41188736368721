import { Component, Input, OnInit } from "@angular/core";
import { DashboardChartData } from "../../../../app/_models";

export type ProgressChartType = "INLINE" | "DEFAULT" | "TABLEPROGRESS";

@Component({
  selector: "progress-chart",
  templateUrl: "./progress-chart.component.html",
  styleUrls: ["./progress-chart.component.scss"],
})
export class ProgressChartComponent {
  @Input("chartData") chartData: DashboardChartData = null;
  @Input("chartType") chartType: ProgressChartType = "DEFAULT";
  @Input("isReport") isReport = false;

  getMaxValue(property: string): number {
    return Math.max(...this.chartData.map((item) => item[property]));
  }
}
