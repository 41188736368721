import {THEMES} from '../../../web-builder/pre-defined.themes';
const buttonTheme = editor => {

  editor.StyleManager.addType('button-themes', {
    create({props, change}) {
      const el = document.createElement('div');
      const buttonThemes = THEMES[props.id];
      el.innerHTML = `<ul>`;
      Object.keys(buttonThemes).forEach((element) => {
        let style = '';
        Object.keys(buttonThemes[element]['styles']).forEach(preDefinedstyle => {
          style += `${preDefinedstyle}: ${buttonThemes[element]['styles'][preDefinedstyle]};`;
        });
        el.innerHTML += `<li>
            <button type="button" style="${style}" value="${element}" class="theme-btn">${buttonThemes[element]['name']}</button>
          </li>`;
      });
      el.innerHTML += `</ul>`;
      const elements = el.querySelectorAll('.theme-btn');
      elements.forEach((element: any) => {
        if (element.callback) {
          element.removeEventListener('click', element.callback);
        }
        element.addEventListener('click', element.callback = function (event) {
          change({event}); // change will trigger the emit
        });
      })
      return el;
    },
    emit({props, updateStyle}, {event, complete}) {
      const {value} = event.target;
      const valueRes = THEMES[props.id][value].styles;
      updateStyle(valueRes, {complete});
      const selectComponent = editor.getSelected();
      for (let val in valueRes) {
        selectComponent.updateTrait(val, valueRes[val]);
      }
    },
  });
};
export default buttonTheme;
