import {Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ProductCategoryService, SessionService, StorageService} from '../_services';
import {CrudPagedListComponent} from '../_directives';
import {Observable, of as observableOf} from 'rxjs';
import {Category, Pager, Session, SessionBase, SessionInput, SessionSourceLabels} from '../_models';
import get from 'lodash-es/get';
import {config} from "../../config/config";
import {mergeMap, takeUntil} from "rxjs/operators";
import {fieldData} from "./sessionFieldData";
import {NgxSmartModalService} from "ngx-smart-modal";
import { DataTableBodyComponent } from '@swimlane/ngx-datatable';

const sourceLabelToIconMap = {
  'Web': '<i class="fa fa-globe fa-lg"></i>',
  'Email': '<i class="fa fa-envelope fa-lg"></i>',
  'Phone': '<i class="fa fa-phone fa-lg"></i>',
  'SocialMedia': '<i class="fa fa-facebook-square fa-lg"></i>'
};

@Component({
  selector: 'sessions',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SessionComponent extends CrudPagedListComponent implements OnInit, OnChanges {
  sessions$: Observable<Session[] | SessionInput[]> = this.data$;
  columns = [];
  campaignCategoriesMap = {};
  selectedSession: SessionBase = null;
  childFields = ['date_joined', 'source', 'result', 'device_info.device_type', 'steps_count'];

  @Input('filter') inputFilter: {} = {};
  @Input('fields') fields: string[] = [];
  @Input('isDashboardSession') isDashboardSession: boolean;
  @Input('displayDummyData') displayDummyData: boolean = false;
  @ViewChild('sessionTable', {static: false}) sessionTable;
  childSessions: SessionBase[] = [];

  protected fieldData = fieldData;

  private defaultFields = [
    'date_joined',
    'campaign.name',
    'type',
    'latest_result',
    'first_name',
    'last_name',
    'device_info.device_type',
    'source',
    'total_steps_count',
    'frequency',
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected sessionService: SessionService,
    protected alertService: AlertService,
    protected categoryService: ProductCategoryService,
    protected modalService: NgxSmartModalService,
    protected storageService: StorageService
  ) {
    super(router, location, route, sessionService, alertService);
    this.objectName = 'session';

    if (!this.fields || !this.fields.length) {
      this.fields = this.defaultFields;
    }
    this.createCampaignCategoriesMap();
  }

  ngOnInit() {
    super.ngOnInit(); 
  }
  
  getSourceLabelIcon(sourceLabel: string): string {
    return sourceLabelToIconMap[sourceLabel] || '';
  }
  
  protected initializeFilter() {
    this.filter = Object.assign({parent_session__isnull: true}, this.inputFilter);
  }

  ngOnChanges() {
    this.setFilter(Object.assign({parent_session__isnull: true}, this.inputFilter));
  }

  getChildSessions(session: Session) {
    if (session.frequency > 1) {
      if (session.child_sessions && session.child_sessions.length) {
        return observableOf(session.child_sessions);
      }
      else {
        return this.sessionService.list({
            page: 1, page_size: config.maxPageSize,
            parent_session: session.id,
            ordering: "date_joined"
        }).pipe(takeUntil(this.destroy$), mergeMap((data: Pager) => {
          session.child_sessions = [session]; // include the parent as the 1st result

          data.results.forEach((childSession: SessionBase) => {
            session.child_sessions.push(childSession);
          });

          return observableOf(session.child_sessions);
        }));
      }
    }

    return observableOf([]);
  }

  getFieldLabel(field: string) : string {
    return this.fieldData[field].label;
  }

  getSessionFieldValue(session: SessionBase | SessionInput, field: string) {
    let value = get(session, field);

    if ('getter' in this.fieldData[field]) {
      let args = [session, value]
      if (field === 'date_joined') {
        args.push(this.storageService.get('timezone'));
      }

      value = this.fieldData[field].getter(...args);
    }

    return value;
  }

  onSelect(event) {
    const session: Session = event.selected[0];

    if (session.frequency === 1) {
      this.selectSession(session);
    } else if (this.sessionTable) {
      this.getChildSessions(session).subscribe(data => {
        this.childSessions = data;
      }, error => {
        this.alertService.error(error.message);
      })
      this.sessionTable.rowDetail.toggleExpandRow(session);
    }
  }

  selectSession(session: SessionBase) {
    this.selectedSession = session;
    this.modalService.getModal('sessionDetailModal').open();
  }

  protected runInitialQuery() {
    // don't run the initial query - wait for filter event
  }

  private createCampaignCategoriesMap() {
    this.categoryService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (pager: Pager) => {
          pager.results.forEach((category: Category) => {
            this.campaignCategoriesMap[category.id] = category.name;
          });
          console.log("pager", pager.results);
          console.log("mapped", this.campaignCategoriesMap);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  getMappedCategories(ids: Array<string | number>) {
    let names = [];
    ids.forEach((id: string | number) => {
      names.push(this.campaignCategoriesMap[id]);
    });
    return names.sort().join(", ");
  }

  protected queryData() {
    let dummyData: Pager = {
      page: 1,
      num_pages: 1,
      count: 0,
      results: [],
    }
    if (this.displayDummyData) {
      this.setPagerData(dummyData)
      this.onPagerData(dummyData)
    } else {
      super.queryData();
    }
  }

  protected onPagerData(data: Pager) {
    super.onPagerData(data); 
    setTimeout(() => {
      this.scrollToTopOfTable();
    }, 0);
  }
  
  protected scrollToTopOfTable() {
    if (this.sessionTable && this.sessionTable.bodyComponent instanceof DataTableBodyComponent) {
      const datatableBody = this.sessionTable.bodyComponent;
      if (datatableBody && datatableBody.scroller) {
        datatableBody.scroller.element.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
}
