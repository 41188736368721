import {Component, OnInit} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, TicketSystemService} from '../_services';
import {TicketSystem, TicketSystemType} from '../_models';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './ticket-system-edit.component.html',
  styleUrls: ['./ticket-system.component.css']
})

export class TicketSystemNewComponent extends CrudSaveComponent implements OnInit {
  public ticketSystemTypes: TicketSystemType[];
  public selectedTicketSystemType: TicketSystemType;
  public ticketSystem: TicketSystem;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected ticketSystemService: TicketSystemService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, ticketSystemService, alertService);
    this.isNew = true
    this.objectName = 'Ticket System'
    this.title = 'Ticket System'
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      display_name: [null, [Validators.required]],
      type: [null, [Validators.required]],
      credentials: this.formBuilder.group({}),
      config: this.formBuilder.group({})
    })
    super.ngOnInit();
    this.ticketSystemService.getTypes()
      .subscribe((types: TicketSystemType[]) => {
        this.ticketSystemTypes = types;
      }, error => {
        this.handleError(error)
      })
  }

  selectType(event) {
    const selectedTypeId = event.target.value;
    let ticketSystem: any = null
    if (selectedTypeId && !isNaN(Number(selectedTypeId))) {
      ticketSystem = this.ticketSystemTypes.filter((item: TicketSystemType) => item.id === Number(selectedTypeId));
      if (ticketSystem.length > 0) {
        ticketSystem = ticketSystem[0]
      }
    }
    this.selectedTicketSystemType = ticketSystem
    if (ticketSystem) {
      this.form.patchValue({display_name: this.selectedTicketSystemType.name})
    }
  }
}
