import { Component, OnDestroy, OnInit } from '@angular/core';
import { CrudPagedListComponent } from '../../_directives';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, FunnelService, SideNavService, UserService } from '../../_services';
import { Location } from '@angular/common';
import { stepLayout, steps } from '../step-util';
import { Breadcrumb, OfferTypeLabels, OfferTypes, OfferIntents } from '../../_models';

@Component({
  selector: 'app-step-category-selection',
  templateUrl: './step-category-selection.component.html',
  styleUrls: ['./step-category-selection.component.scss'],
})
export class StepCategorySelectionComponent extends CrudPagedListComponent implements OnInit, OnDestroy {
  offerType: OfferTypes;
  offerName: string;
  offerIntent: OfferIntents;
  useExistingTemplate: boolean;
  steps = steps;
  title: string;
  funnelId: string = '';
  funnelName: string = '';
  breadcrumbs: Breadcrumb[] = [];
  categoryTitles = {
    [OfferTypes.Downsell]: 'Add a new downsell',
    [OfferTypes.Upsell]: 'Add a new upsell',
    [OfferTypes.Survey]: 'Select survey step',
    [OfferTypes.Support]: 'Add new step',
  };
  protected returnUrl = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    private sideNav: SideNavService,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, funnelService, alertService);
    if (window.innerWidth < 1530) {
      this.sideNav.setSideNavExpanded(false);
    }
    
    this.route.params.subscribe((params) => {
      this.funnelId = params['offer_id'];
      this.offerType = Number(params['offer_type']);
      this.offerName = OfferTypeLabels[this.offerType];
      this.offerIntent = Number(params['offer_intent']);
      this.useExistingTemplate = params['use_existing'] === 'true';
    });
  }

  ngOnInit() {
    if (this.funnelId) {
      this.fetchFunnel();
    }
    this.filterSteps();
    this.generateBreadcrumbs();
  }

  fetchFunnel() {
    this.funnelService.list({}, this.funnelId).subscribe(
      (response) => {
        this.funnelName = response.slug;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  filterSteps() {
    this.steps = steps.filter(
      (step) =>
        step.offer_type === this.offerType &&
        (!step.offer_intents || step.offer_intents.length === 0 || step.offer_intents.includes(this.offerIntent))
    );
    this.title = this.categoryTitles[this.offerType];
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ['steps', 'survey'],
        },
        {
          label: 'Choose a Template',
        },
      ];
    } else if (this.offerType === OfferTypes.CustomerPortal) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ['steps', this.offerName],
        },
        {
          label: 'Choose a Template',
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
          url: ['steps', this.offerName],
        },
        {
          label: '',
          url: ['steps', this.offerName, this.offerType, 'details', this.funnelId],
        },
        {
          label: 'Choose a Template',
        },
      ];
    }
  }

  selectTemplate(stepType: number): void {
    const selectedLayout = stepLayout[stepType];

    if (selectedLayout) {
      const routes = this.getRouteConfig(stepType);
      const path = this.useExistingTemplate ? routes.template : routes.base;

      this.router.navigate(path);
    }
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  private getRouteConfig(stepType: number) {
    const baseRoute = ['steps', this.offerName];

    if (this.offerType === OfferTypes.Survey) {
      return {
        base: [...baseRoute, 'category', this.offerType, 'step', stepType],
        template: [...baseRoute, 'category', this.offerType, 'template', stepType],
      };
    }

    if (this.offerType === OfferTypes.CustomerPortal) {
      return {
        base: [...baseRoute, 'category', this.offerType, 'step', stepType],
        template: [...baseRoute, 'category', this.offerType, 'template', stepType],
      };
    }

    // Default case for Downsell, Upsell, and Support
    return {
      base: [...baseRoute, this.funnelId, 'category', this.offerType, 'step', stepType],
      template: [...baseRoute, this.funnelId, 'category', this.offerType, 'template', stepType],
    };
  }

  ngOnDestroy(): void {
    this.sideNav.setSideNavExpanded(true);
  }
}
