import {Component, OnInit} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ExchangeableProductsSetService, PaginationService, RelatedProductSetService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {
  RelatedProductSet,
  RelatedProductSetType,
  RelatedProductSetTypeLabel
} from '../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'related-product-set-new',
  templateUrl: './related-product-set-edit.component.html'
})
export class RelatedProductSetNewComponent extends CrudSaveComponent implements OnInit {
  public category: string | number = null

  // hard-coded for now
  public type: RelatedProductSetType = RelatedProductSetType.EXCHANGEABLE
  public typeLabel: string = RelatedProductSetTypeLabel[RelatedProductSetType.EXCHANGEABLE]

  public relatedProductSet: RelatedProductSet = null

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected relatedProductSetService: RelatedProductSetService,
    protected exchangeableProductsSetService: ExchangeableProductsSetService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
  ) {
    super(router, location, route, relatedProductSetService, alertService);
    this.isNew = true
    this.objectName = `${this.typeLabel} Products set`
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]]
    })
    super.ngOnInit()

    this.category = this.route.snapshot.params['category'] || null
  }

  get exchangeableSets() {
    return this.relatedProductSet ? this.relatedProductSet.exchangeable_sets : []
  }

  protected getFormData(): any {
    let data = super.getFormData();
    data['type'] = this.type
    data['category'] = this.category
    return data
  }

  getUrlParams() {
    if (this.category) {
      return {type: this.category}
    }

    return {}
  }

  delete(id, objectName = '') {
    if (window.confirm('Are you sure you want to delete ' + objectName + '?')) {
      this.loading = true

      this.exchangeableProductsSetService.delete(id)
        .subscribe(_ => {
          this.alertService.success('Successfully deleted ' + objectName)
          this.loading = false
          let exchangeableSets = this.relatedProductSet.exchangeable_sets
          exchangeableSets = exchangeableSets.filter(item => item.id !== id)
          this.relatedProductSet.exchangeable_sets = exchangeableSets
        })
    }
  }
}
