import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, StorageService} from '../_services';
import {FileUpload} from '../_forms';
import {DomSanitizer} from "@angular/platform-browser";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  moduleId: module.id.toString(),
  selector: 'style-upload',
  templateUrl: 'style-upload.component.html',
  styleUrls: ['./style.component.css']
})
export class StyleUploadComponent extends FileUpload implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected storageService: StorageService,
    protected domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) {
    super(router, location, alertService, storageService, domSanitizer);
    this.path = 'styles/';
    this.fieldName = 'style';
    this.title = 'Upload a Style File (css, scss or less)';
    this.options.allowedContentTypes = ['text/css', 'text/plain'];
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected onUploadComplete(result) {
    console.log(result);
  }
}
