import {Component} from '@angular/core';
import {AccountService, AlertService} from '../_services';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {CrudSaveComponent} from '../_directives';
import {FormBuilder} from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  selector: 'search-keywords',
  templateUrl: './account-search-keywords.component.html',
})
export class AccountSearchKeywordsComponent extends CrudSaveComponent {
  isNew = false
  title = 'Search keywords'
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected accountService: AccountService,
              protected alertService: AlertService,
              protected formBuilder: FormBuilder) {
    super(router, location, route, accountService, alertService);
    this.isNew = false;
    this.isPartial = true;
    this.objectName = 'FAQ Lifeline Keywords';
    this.title = 'FAQ Lifeline Keywords';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      search_lifeline_keywords: [null]
    })
    this.data$.subscribe(data => {
      if (data && data['search_lifeline_keywords']) {
        this.form.patchValue({search_lifeline_keywords: data['search_lifeline_keywords'].join("\n")})
      }
    })
    super.ngOnInit();
  }

  protected getFormData(): any {
    let data = {search_lifeline_keywords: []}
    const keywordsStr = this.form.value.search_lifeline_keywords ? this.form.value.search_lifeline_keywords.trim() : '';

    // convert the keywords string into an array of unique values
    if (keywordsStr.length) {
      data['search_lifeline_keywords'] = keywordsStr.split("\n").filter(
        (value, index, self) => self.indexOf(value) === index);
    }
    return data
  }
}
