import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {CampaignProduct, RelatedCampaignProduct, ExchangeableProduct, ImageSize, CRMCampaign} from "../_models";
import {getCampaignProductImageUrl} from "../_helpers";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './exchangeable-product-fields.component.html',
  selector: 'upsale-product-fields',
  styleUrls: ['./exchangeable-product.component.css']
})
export class UpsaleProductFieldsComponent extends Form implements OnInit, OnChanges {
  selectedToProduct: RelatedCampaignProduct;
  ready = false;
  @Input('inputProduct') exchangeableProduct: ExchangeableProduct;
  @Input('index') formIndex: number = 0;
  @Input('crmId') crmId: string | number;
  @Input('crmCampaigns') crmCampaigns: CRMCampaign[] = [];
  productSelectionEnabled = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      to_campaign_product: [null, Validators.required],
      quantity: [1, Validators.required],
      total_price: [null, Validators.min(0)],
      total_shipping_price: [null, Validators.min(0)],
      display_name: [null]
    }));

    super.ngOnInit();
    this.updateForm();
  }

  ngOnChanges() {
    this.updateForm();
  }

  get isNew() : boolean {
    return !this.exchangeableProduct || !this.exchangeableProduct.id;
  }

  protected updateForm() {
    this.ready = false;
    this.productSelectionEnabled = false;

    if (this.form) {
      if (this.exchangeableProduct) {
        this.selectedToProduct = this.exchangeableProduct.to_campaign_product;
        this.form.patchValue(this.exchangeableProduct);
      }
      else {
        this.selectedToProduct = null;
        this.resetForm();
      }

      setTimeout(() => this.ready = true, 0); // this is needed to force the tinymce editor to reload
    }
  }

  onProductSelected(product: CampaignProduct) {
    this.selectedToProduct = product;
    this.resetForm();
    this.form.patchValue({to_campaign_product: product});
    this.productSelectionEnabled = false;
  }

  cancelProductSelection() {
    this.productSelectionEnabled = false;
  }

  selectProduct() {
    this.productSelectionEnabled = true;
  }

  getFormData() {
    let data = this.form.value;
    const allow_blank_fields = ['total_price', 'total_shipping_price'];

    //convert empty strings to null for certain fields so the api will accept them
    allow_blank_fields.forEach((field: string) => {
      if (field in data) {
        let value = data[field];

        if (typeof(value) == 'string') {
          if (value.trim().length == 0) {
            data[field] = null;
          }
        }
      }
    });

    if (this.exchangeableProduct) {
      data['id'] = this.exchangeableProduct.id;
    }

    return data;
  }

  getToProductImageUrl() {
    return getCampaignProductImageUrl(this.selectedToProduct, ImageSize.medium);
  }

}
