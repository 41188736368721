import {Component, OnInit} from '@angular/core';
import {InviteStatus, User, UserGroup, UserGroupLabels} from '../_models';
import {CrudPagedListComponent} from "../_directives";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AccountService, AlertService, UserService} from "../_services";
import {NgxSmartModalService} from "ngx-smart-modal";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent extends CrudPagedListComponent implements OnInit {
  showInviteDialog = false;
  users$: Observable<User[]> = this.data$;

  userGroups = [
    {value: UserGroup.Owner, label: UserGroupLabels.Owner},
    {value: UserGroup.Employee, label: UserGroupLabels.Employee},
  ]

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected alertService: AlertService,
    private accountService: AccountService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, userService, alertService);
    this.objectName = 'user';
    this.title = 'Users';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  isInvitePending(user: User) {
    return user.invite_status === InviteStatus.Pending
  }

  invite() {
    this.showInviteDialog = true;
    this.modalService.getModal('inviteDialog').open();
  }

  onInviteDialogClosed() {
    this.showInviteDialog = false;
  }

  onInvitationsSent() {
    this.modalService.getModal('inviteDialog').close();
    this.queryData();
    this.showInviteDialog = false;
  }

  resendInvitation(user: User) {
    if (this.isInvitePending(user)) {
      this.accountService.resendInvitation([user.email])
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.alertService.success('Invitation sent.');
          },
          error => {
            this.handleSubmitError(error);
          });
    }
  }

  activate(user: User, event) {
    let active: boolean = event.target.checked;
    let action = active ? 'activate' : 'deactivate';

    if (!this.isInvitePending(user) &&
      window.confirm('Are you sure you want to ' + action + ' ' + user.first_name + "'s access to your account?")) {
      this.userService.patch(user.id, {is_active: active})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            action = active ? 'Activated' : 'Deactivated';
            this.alertService.success(action + ' ' + user.first_name + "'s access");
          },
          error => {
            this.handleSubmitError(error);
            event.target.checked = !active; //revert checkbox to previous state
          });
    }
    else {
      event.target.checked = !active; //revert checkbox to previous state (should not get here but just in case)
    }
  }

  setGroup(user: User, event) {
    if (event && event.target.value) {
      const group = event.target.value;
      this.userService.patch(user.id, {group})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
        (data) => {
          this.alertService.success(`${user.first_name} is assigned to ${event.target.selectedOptions[0].label}.`);
        },
        (error) => {
          this.handleSubmitError(error);
        }
      )
    }
  }
}
