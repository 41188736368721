import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Form} from '../_forms';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-subtext.component.html',
  selector: 'product-subtext',
  styleUrls: ['./product-subtext.component.css']
})
export class ProductSubtextComponent extends Form implements OnInit, OnChanges {
  @Input('productSubtexts') productSubtexts;
  @Input('isExchangeable') isExchangeable: boolean = false;
  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
  ) {
    super(alertService, router, location);
  }

  get subtextForm() {
    return this.form.get('subtext') as FormArray;
  }

  get hasMultipleSubtexts(){
    return this.subtextForm.controls.length > 1;
  }

  addSubtext(value?) {
    this.subtextForm.push(new FormControl(value, Validators.maxLength(25)));
  }

  removeSubtext(index: number) {
    this.subtextForm.removeAt(index);
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      subtext: new FormArray([])
    }));
    super.ngOnInit();
    if (this.isExchangeable) {
      this.updateForm();
    }
  }

  updateForm() {
    if (this.form) {
      if (!this.subtextForm) {
        this.form.addControl('subtext', new FormArray([]));
      }
      if (this.productSubtexts) {
        if (this.productSubtexts instanceof Array) {
          this.productSubtexts.map((subtext) => {
            const div = document.createElement('div');
            div.innerHTML = subtext;
            subtext = div.textContent || div.innerText || '';
            this.addSubtext(subtext);
            return subtext;
          });
        }
      }

      if (!this.subtextForm.controls.length) {
        this.addSubtext()
      }
    }
  }

  ngOnChanges() {
    this.updateForm()
  }

  getFormData() {
    return this.subtextForm.value.filter(subtext => subtext && subtext.trim());
  }

}
