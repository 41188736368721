import {
  AvailableFunnelItemStatuses,
  LifelineTypeLabels,
  PhoneCarrierTypeLabels,
  ReportSessionInput,
  Session,
  SessionActionResult,
  SessionActionResultLabels,
  SessionMatchMethodLabels,
  SessionResultLabels,
  SessionSourceLabels,
  SessionStatusLabels,
  TimeData,
  SessionType,
  SessionTypeLabels,
  CardType,
  TranTypeLabel,
  TransactionPaymentSource,
  TransactionPaymentSourceLabel,
  TranMatchedStatusLabel,
  AlertSourceLabel,
  RequestTypeLabel,
  getCRMCustomerHtml,
  getCRMOrderHtml,
} from '../_models';
import {convertSecondsToSessionTime} from '../_helpers';
import * as moment from "moment-timezone";

export const fieldData = {
  id: {label: 'ID', getter: (session: Session, value: any) => session.username ? session.username.substr(8) : ''},
  date_joined: {
    label: 'Created', getter: (session: Session, value: any, timezone: string) => {
      if (!timezone) {
        timezone = moment.tz.guess();
      }
      let thisMoment = moment.tz(value, timezone)
      return thisMoment.format(TimeData.SessionListDateFormat);
    }
  },
  result: {
    label: 'Session Result',
    getter: (session: Session, value: any) => (value >= 0) ? SessionResultLabels[value] : 'Unknown'
  },
  latest_result: {
    label: 'Session',
    getter: (session: Session, value: any) => (value >= 0) ? SessionResultLabels[value] : 'Unknown'
  },
  lifeline: {
    label: 'Lifeline',
    getter: (session: Session, value: any) => (value >= 0) ? session.lifeline : ''
  },
  lifeline_type: {label: 'Lifeline Type', getter: (session: Session, value: any) => LifelineTypeLabels[value]},
  status: {label: 'Status', getter: (session: Session, value: any) => SessionStatusLabels[value]},
  type: {label: 'Type', getter: (session: Session, value: any) => SessionTypeLabels[value]},
  matched_by: {label: 'Matched By', getter: (session: Session, value: any) => SessionMatchMethodLabels[value]},
  source: {label: 'Channel', getter: (session: Session, value: any) => SessionSourceLabels[value]},
  sid1: {label: 'Sub Source 1'},
  sid2: {label: 'Sub Source 2'},
  sid3: {label: 'Sub Source 3'},
  bypass: {
    label: 'Bypass', getter: (session: Session, value: any) =>
      (session.result >= 0) && SessionResultLabels[session.result] === 'Bypassed' ? 'Yes' : 'No'
  },
  bypass_type: {label: 'Bypass type', getter: (session: Session, value: any) => 'Phone'},
  path_channel: {label: 'Path channel', getter: (session: Session, value: any) => SessionSourceLabels[session.source]},
  is_billable: {label: 'Billable?', getter: (session: Session, value: any) => value ? 'Yes' : 'No'},
  sub_channel_1: {label: 'Sub channel 1', getter: (session: Session, value: any) => session.sid1 ? session.sid1 : ''},
  sub_channel_2: {label: 'Sub channel 2', getter: (session: Session, value: any) => session.sid2 ? session.sid2 : ''},
  affiliate: {
    label: 'Path Channel',
    getter: (session: Session, value: any) => session.tracking_source ? session.tracking_source : ''
  },
  first_name: {label: 'First Name', getter: (session: Session, value: any) => value ? value : '--'},
  last_name: {label: 'Last Name', getter: (session: Session, value: any) => value ? value : '--'},
  'campaign.name': {label: 'Brand'},
  'customers.crm': {
    label: 'CRM',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].crm : ''
  },
  'customers.customer_id': {
    label: 'Customer ID',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].customer_id : ''
  },
  'customers.first_name': {
    label: 'First Name',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].first_name : ''
  },
  'customers.last_name': {
    label: 'Last Name',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].last_name : ''
  },
  'customers.address1': {
    label: 'Address',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].address1 : ''
  },
  'customers.city': {
    label: 'City',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].city : ''
  },
  'customers.state': {
    label: 'State',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].state : ''
  },
  'customers.zip': {
    label: 'Zip/Post Code',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].zip : ''
  },
  'customers.country': {
    label: 'Country',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].country : ''
  },
  'customers.email': {
    label: 'Email',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].email : ''
  },
  'customers.phone': {
    label: 'Phone',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].phone : ''
  },
  'customers.ship_first_name': {
    label: 'Shipping First Name',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].ship_first_name : ''
  },
  'customers.ship_last_name': {
    label: 'Shipping Last Name',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].ship_last_name : ''
  },
  'customers.ship_address1': {
    label: 'Shipping Address',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].ship_address1 : ''
  },
  'customers.ship_city': {
    label: 'Shipping City',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].ship_city : ''
  },
  'customers.ship_state': {
    label: 'Shipping State',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].ship_state : ''
  },
  'customers.ship_zip': {
    label: 'Shipping Zip/Post Code',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].ship_zip : ''
  },
  'customers.ship_country': {
    label: 'Shipping Country',
    getter: (session: Session, value: any) => session.customers && session.customers.length ? session.customers[0].ship_country : ''
  },
  'device_info.browser_name': {
    label: 'Browser',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.browser_name : ''
  },
  'device_info.browser_full_version': {
    label: 'Browser Version',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.browser_full_version : ''
  },
  'device_info.engine_name': {
    label: 'Browser Engine',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.engine_name : ''
  },
  'device_info.engine_version': {
    label: 'Engine Version',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.engine_version : ''
  },
  'device_info.os_name': {
    label: 'Operating System',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.os_name : ''
  },
  'device_info.os_version': {
    label: 'OS Version',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.os_version : ''
  },
  'device_info.device_type': {
    label: 'Device',
    getter: (session: Session, value: any) => session.device_info ? capitalizeFirstLetter(session.device_info.device_type) : '--'
  },
  'device_info.mobile_vendor': {
    label: 'Mobile Vendor',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.mobile_vendor : ''
  },
  'device_info.mobile_model': {
    label: 'Mobile Model',
    getter: (session: Session, value: any) => session.device_info ? session.device_info.mobile_model : ''
  },
  'phone_call.carrier_type': {
    label: 'Phone call carrier',
    getter: (session: Session, value: any) => session.phone_call ? PhoneCarrierTypeLabels[session.phone_call.carrier_type] : ''
  },
  'session_inputs.category': {
    label: 'Category', getter: (session: Session, value: any) => session.session_inputs ?
      this.getMappedCategories(session.session_inputs[0].item.product.categories) : ''
  },
  'session_inputs.product_name': {
    label: 'Product name', getter: (session: Session, value: any) => session.session_inputs ?
      session.session_inputs[0].item.name : ''
  },
  'session_inputs.order_id': {
    label: 'Order ID', getter: (session: Session, value: any) => session.session_inputs ?
      session.session_inputs[0].item.order.order_id : ''
  },
  'total_steps_count': {
    label: 'Steps', getter: (session: Session, value: any) => value
  },
  'steps_count': {
    label: 'Steps count', getter: (session: Session, value: any) => value
  },
  'frequency': {
    label: 'Frequency', getter: (session: Session, value: any) => value
  },
  'funnel_item_status': {
    label: 'Order Status', getter: (session: Session, value: any) => {
      if (value) {
       const funnelItem = AvailableFunnelItemStatuses.find(item => item.id === value);
       return funnelItem.text;
      }
    }
  }
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const convertToDollar = (value) => {
  if (!value) {
    return '$0.00';
  }

  value = parseFloat(value.toFixed(2)).toLocaleString();
  value = value.split('.');
  if (value.length == 1) {
    return `$${value.join('.')}.00`;
  }

  if (value[1].length > 1) {
    return '$' + value.join('.');
  }

  return '$' + value.join('.') + '0';
}

export const reportsFieldData = {
  username: {
    label: 'Session ID', getter: (sessionInput: ReportSessionInput, value: any) =>
      value ? value.substr(8) : ''
  },
  result: {
    label: 'Session result',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  lifeline: {
    label: 'Lifeline',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },

  date: {
    label: 'Date',
    getter: (sessionInput: ReportSessionInput, value: any) => value},

  week: {
    label: 'Week',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },

  month: {
    label: 'Month',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },

  year: {
    label: 'Year',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },

  hour: {
    label: 'Hours',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },

  bypass: {
    label: 'Bypass',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  bypass_type: {
    label: 'Bypass Type',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  source: {
    label: 'Path channel',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  lifeline_type: {
    label: 'Lifeline Type',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  status: {label: 'Status', getter: (sessionInput: ReportSessionInput, value: any) => value},
  aff_id: {label: 'Affiliate', getter: (sessionInput: ReportSessionInput, value: any) => value},
  affiliate: {label: 'Affiliate', getter: (sessionInput: ReportSessionInput, value: any) => value},
  sid1: {label: 'Source sub 1', getter: (sessionInput: ReportSessionInput, value: any) => value},
  sid2: {label: 'Source sub 2', getter: (sessionInput: ReportSessionInput, value: any) => value},
  sid3: {label: 'Source sub 3', getter: (sessionInput: ReportSessionInput, value: any) => value},
  tracking_source: {
    label: 'Path channel source',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  campaign_name: {
    label: 'Brand', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  customer_id: {
    label: 'Customer ID',
    getter: (sessionInput: ReportSessionInput, value: any) => getCRMCustomerHtml(value, sessionInput.crm_type)
  },
  country: {
    label: 'Country',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  browser_name: {
    label: 'Browser',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  os_version: {
    label: 'OS Version',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  device_type: {
    label: 'Device Type',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  mobile_vendor: {
    label: 'Device Brand',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  mobile_model: {
    label: 'Device Model',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  carrier_type: {
    label: 'Phone Type',
    getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  'category': {
    label: 'Category', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  product_name: {
    label: 'Product name', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  order_id: {
    label: 'Order ID', getter: (sessionInput: ReportSessionInput, value: any) => getCRMOrderHtml(value, value, sessionInput.crm_type)
  },
  cancelled: {
    label: 'Total cancels', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  saved: {
    label: 'Total saves', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  sub1: {
    label: 'Aff sub 1', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  sub2: {
    label: 'Aff sub 2', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  sub3: {
    label: 'Aff sub 3', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  revenue: {
    label: 'Revenue', getter: (sessionInput: ReportSessionInput, value: any) => convertToDollar(value)
  },
  revenue_type: {
    label: 'Revenue type', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  downsell: {
    label: 'Downsells', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  upsell: {
    label: 'Upsells', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  save_amount: {
    label: 'Save amount', getter: (sessionInput: ReportSessionInput, value: any) =>
      convertToDollar(value)
  },
  refund_quantity: {
    label: 'Refund quantity', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  refund_amount: {
    label: 'Refund amount', getter: (sessionInput: ReportSessionInput, value: any) =>
      convertToDollar(value)
  },
  return_amount: {
    label: 'Return amount', getter: (sessionInput: ReportSessionInput, value: any) =>
      convertToDollar(value)
  },
  return_quantity: {
    label: 'Return quantity', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  downsell_take_rate: {
    label: 'Downsell take rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  revisions: {
    label: 'Exchangeables', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  exchangeable_take_rate: {
    label: 'Exchangeable take rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  upsell_take_rate: {
    label: 'Upsell take rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  save_rate: {
    label: 'Save rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  total_save_rate: {
    label: 'Save rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  refund_rate: {
    label: 'Refund rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  rma_rate: {
    label: 'RMA rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  cancel_rate: {
    label: 'Cancel rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  crm_campaign_name: {
    label: 'CRM campaign', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  cancel_reason: {
    label: 'Cancel reason', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  funnel_item_status: {
    label: 'Order status', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  order_status: {
    label: 'Order status', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  partial_refund_rate: {
    label: 'Partial refund rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  full_refund_rate: {
    label: 'Full refund rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  recording_url: {
    label: 'Video Recording', getter: (sessionInput: ReportSessionInput, value: any) => {
      if (!value) {
        return ''
      }

      if (value.startsWith('api-')) {
        value = value.replace('api-', 'https://')
      } else {
        value = value.replace('app', 'us')
      }

      return value
    }
  },
  phone_recording_url: {
    label: 'Audio Recording', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  exchangeable_product_name: {
    label: 'Exchangeable product', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  crm_type: {
    label: 'CRM name', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  session_count: {
    label: 'Session quantity', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  partial_refund_amount: {
    label: 'Partial refund amount', getter: (sessionInput: ReportSessionInput, value: any) =>
      convertToDollar(value)
  },
  full_refund_amount: {
    label: 'Full refund amount', getter: (sessionInput: ReportSessionInput, value: any) =>
      convertToDollar(value)
  },
  refund_status_conditional: {
    label: 'Refund status', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  downsell_step_name: {
    label: 'Downsell Variation', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  campaign_product_id: {
    label: 'Campaign product id', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  campaign_product_ids: {
    label: 'Campaign product id', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  amount_paid: {
    label: 'Total billed', getter: (sessionInput: ReportSessionInput, value: any) =>
      convertToDollar(value)
  },
  total_count_sum: {
    label: 'Actions total', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  is_billable: {
    label: 'Billable', getter: (sessionInput: ReportSessionInput, value: any) => {
      if (value === null || value === '') {
        return ''
      } else if (value) {
        return 'Billable'
      }
      return 'Non-Billable'
    }
  },
  frequency: {
    label: 'Frequency', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  steps_count: {
    label: 'Steps Count', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  entered_input: {
    label: 'Key Prompts', getter: (sessionInput: ReportSessionInput, value: any) => value? value: ''
  },
  action_result: {
    label: 'Session Action Result', getter: (sessionInput: ReportSessionInput, value: any) => value? value: ''
  },
  session_action_result: {
    label: 'Session Action Result', getter: (sessionInput: ReportSessionInput, value: any) => value? value: ''
  },
  latest_result: {
    label: 'Latest Result', getter: (sessionInput: ReportSessionInput, value: any) =>
      value ? value : ''
  },
  latest_result_based_on_execution: {
    label: 'Latest result based on time', getter: (sessionInput: ReportSessionInput, value: any) =>
      value ? value : ''
  },
  product_path: {
    label: 'Product Path', getter: (sessionInput: ReportSessionInput, value: any) => value ? value : ''
  },
  downsell_attempt: {
    label: 'Downsell Attempts', getter: (sessionInput: ReportSessionInput, value: any) => value ? value : ''
  },
  exchangeable_product_id: {
    label: 'Exchangeable Product ID', getter: (sessionInput: ReportSessionInput, value: any) => value ? value : ''
  },
  upsell_product_id: {
    label: 'Upsell Product', getter: (sessionInput: ReportSessionInput, value: any) => value ? value : ''
  },
  exchangeable_product_price: {
    label: 'Exchangeable Product Price', getter: (sessionInput: ReportSessionInput, value: any) => convertToDollar(value)
  },
  account_name: {
    label: 'Account', getter: (sessionInput: ReportSessionInput, value: any) => value ? value : ''
  },
  search_phrase: {
    label: 'Search Phrase', getter: (row, value: any) => value ? value : ''
  },
  session_status: {
    label: 'Session Status', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  number_of_searches: {
    label: 'Number of Searches', getter: (row, value: any) => value !== null ? value : 0
  },
  results_shown: {
    label: 'Number of results shown', getter: (row, value: any) => value !== null ? value : 0
  },
  views: {
    label: 'Views', getter: (row, value: any) => value !== null ? value : 0
  },
  upvotes: {
    label: 'UP Votes', getter: (row, value: any) => value !== null ? value : 0
  },
  downvotes: {
    label: 'Down Votes', getter: (row, value: any) => value !== null ? value : 0
  },
  question: {
    label: 'Faq Question', getter: (row, value: any) => value ? value : ''
  },
  crm_campaign_id: {
    label: 'CRM campaign ID', getter: (row, value: any) => value ? value : ''
  },
  customer_count: {
    label: 'Customer Quantity', getter: (row, value: any) => value !== null ? value : 0
  },
  billing_cycle: {
    label: 'Billing Cycle', getter: (row, value: any) => value !== null ? value : 0
  },
  step_name: {
    label: 'Step Impressions', getter: (row, value: any) => value ? value : ''
  },
  input_name: {
    label: 'Input Name', getter: (row, value: any) => value ? value.replace(/<[^>]*>/g, '') : ''
  },
  inputs_count: {
    label: 'Input Count', getter: (row, value: any) => value !== null ? value : 0
  },
  inputs_take_rate: {
    label: 'Inputs take rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  autopick: {
    label: 'Autopick', getter: (sessionInput: ReportSessionInput, value: any) => {
      if (value === null) {
        return ''
      }
      return value? 'Yes': 'No'
    }
  },
  is_recurring: {
    label: 'Recurring', getter: (row, value: any) => {
      if (value === null) {
        return ''
      }
      return value? 'Yes': 'No'
    }
  },
  is_trial: {
    label: 'Trial', getter: (row, value: any) => {
      if (value === null) {
        return ''
      }
      return value? 'Yes': 'No'
    }
  },
  upsell_product_name: {
    label: 'Upsell Product', getter: (row, value: any) => value ? value : ''
  },
  order_date: {
    label: 'Order Timestamp', getter: (row, value: any, timezone) => {
      if (!timezone) {
        timezone = moment.tz.guess();
      }
      let thisMoment = moment.tz(value, timezone)
      return thisMoment.format(TimeData.ReportsApiDateFormat);
    }
  },
  customer_name: {
    label: 'Customer Name', getter: (row, value: any) => value ? value : ''
  },
  billing_interval_days: {
    label: 'Rebill Interval', getter: (row, value: any) => value ? value : ''
  },
  is_save: {
    label: 'Save', getter: (row, value: any) => {
      if (value === null) {
        return ''
      }
      return value? 'Yes': 'No'
    }
  },
  initial_transaction_date: {
    label: 'Initial Rebill Date', getter: (row, value: any) => value ? value : ''
  },
  rebill_date: {
    label: 'Rebill Date', getter: (row, value: any) => value ? value : ''
  },
  is_order_active: {
    label: 'Order Active', getter: (row, value: any) => {
      if (value === null) {
        return ''
      }
      return value? 'Yes': 'No'
    }
  },
  sms_status: {
    label: 'SMS Status', getter: (row, value: any) => value ? value : ''
  },
  session_type: {
    label: 'Session Type', getter: (row, value: any) => value ? value : ''
  },
  session_action_status: {
    label: 'Session Action Status', getter: (row, value: any) => value ? value : ''
  },
  message: {
    label: 'Message', getter: (row, value: any) => value ? value : ''
  },
  handled_handoff: {
    label: 'Handled by call center', getter: (row, value: any) => value !== null ? value : 0
  },
  handoff: {
    label: 'HandOffs', getter: (row, value: any) => value !== null ? value : 0
  },
  product_price: {
    label: 'Product price', getter: (row, value: any) => value !== null ? value : 0
  },
  hold_save: {
    label: 'Saves on Holds', custom_label: 'Saved', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  subscription_save: {
    label: 'Saves on Subscriptions', custom_label: 'Saved', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  rma_save: {
    label: 'Saves on RMAs', custom_label: 'Saved', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  hold_cancel: {
    label: 'Cancels on Holds', custom_label: 'Cancels', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  subscription_cancel: {
    label: 'Cancels on Subscriptions', custom_label: 'Cancels', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  merchant_id: {label: 'Merchant Processor', getter: (session: Session, value: any) => value ? value : ''},
  chargeback_ratio: {label: 'Chargeback Ratio', getter: (session: Session, value: any) => value ? value.toFixed(2) + " %" : 0.0},
  dispute_ratio: {label: 'Dispute Ratio', getter: (session: Session, value: any) => value ? value.toFixed(2) + " %" : 0.0},
  alert_ratio: {label: 'Alert Ratio', getter: (session: Session, value: any) => value ? value.toFixed(2) + " %" : 0.0},
  time_period: {label: 'Time Period', getter: (session: Session, value: any) => {
        var date = new Date(value);
        return date.toLocaleString('en-US', { month: 'long' }) + " " + date.getFullYear()
      }
    },
  chargeback_date: {
    label: 'Chargeback Date', getter: (row, value: any, timezone) => {
      if (!timezone) {
        timezone = moment.tz.guess();
      }
      let thisMoment = moment.tz(value, timezone)
      return thisMoment.format(TimeData.ReportsApiDateFormat);
    }
  },
  created: {
    label: 'Transaction Date', getter: (row, value: any, timezone) => {
      if (!timezone) {
        timezone = moment.tz.guess();
      }
      let thisMoment = moment.tz(value, timezone)
      return thisMoment.format(TimeData.ReportsApiDateFormat);
    }
  },
  refund_date: {
    label: 'Refund Date', getter: (row, value: any, timezone) => {
      if (!timezone) {
        timezone = moment.tz.guess();
      }
      let thisMoment = moment.tz(value, timezone)
      return thisMoment.format(TimeData.ReportsApiDateFormat);
    }
  },
  card_bin: {label: 'Card BIN', getter: (session: Session, value: any) => value ? value : ''},
  card_last4: {label: 'Card Last 4', getter: (session: Session, value: any) => value ? value : ''},
  card_type: {label: 'Card Type', getter: (session: Session, value: any) => value >= 0 ? CardType[value] : ''},
  mid_number: {label: 'MID Number', getter: (session: Session, value: any) => value ? value : ''},
  crm_campaign: {
    label: 'CRM campaign', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  campaign: {
    label: 'Brand', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  transaction_id: {
    label: 'Transaction ID', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  amount: {
    label: 'Transaction Amount', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  customer_email: {
    label: 'Customer Email', getter: (sessionInput: ReportSessionInput, value: any) => value
  },
  orders_count: {
    label: 'Orders', getter: (row, value: any) => value !== null ? value : 0
  },
  rdr_count: {
    label: 'RDR Count', getter: (row, value: any) => value !== null ? value : 0
  },
  chargeback_count: {
    label: 'Chargeback Count', getter: (row, value: any) => value !== null ? value : 0
  },
  dispute_count: {
    label: 'Dispute Count', getter: (row, value: any) => value !== null ? value : 0
  },
  alert_count: {
    label: 'Alert Count', getter: (row, value: any) => value !== null ? value : 0
  },
  alert_source: {label: 'Alert Source', getter: (session: Session, value: any) => value >= 0 ? AlertSourceLabel[value] : 'None'},
  matched_status: {label: 'Solvpath Matched Status', getter: (session: Session, value: any) => value >= 0 ? TranMatchedStatusLabel[value] : 'None'},
  request_type: {label: 'Request Type', getter: (session: Session, value: any) => value >= 0 ? RequestTypeLabel[value] : 'None'},
  transaction_count: {
    label: 'Transaction Count', getter: (row, value: any) => value !== null ? value : 0
  },
  transaction_type: {
    label: 'Transaction Type',
    getter: (row, value: any) => (value >= 0) ? TranTypeLabel[value] : 'Unknown'
  },
  sp_session_source: {label: 'Session Source', getter: (session: Session, value: any) => SessionSourceLabels[value]},
  sp_session_id: {
    label: 'Solvpath Session ID', getter: (session: Session, value: any) =>
      value ? value : ''
  },
  payment_source: {
    label: 'Payment Method',
    getter: (row, value: any) => (row['payment_source'] >= 0) ? TransactionPaymentSourceLabel[row['payment_source']] : 'Unknown'
  },
  successful_session: {
    label: 'Successful', getter: (row, value: any) => value !== null ? value : 0
  },
  dropoff: {
    label: 'Dropoff', getter: (row, value: any) => value !== null ? value : 0
  },
  dropoffs: {
    label: 'Dropoffs', getter: (row, value: any) => value !== null ? value : 0
  },
  unmatched: {
    label: 'Unmatched', getter: (row, value: any) => value !== null ? value : 0
  },
  update_info: {
    label: 'Update Info', getter: (row, value: any) => value !== null ? value : ''
  },
  faqs: {
    label: 'FAQs', getter: (row, value: any) => value !== null ? value : ''
  },
  marketplace: {
    label: 'Marketplace', getter: (row, value: any) => value !== null ? value : ''
  },
  successfull_sessions: {
    label: 'Successfull Sessions', getter: (row, value: any) => value !== null ? value : 0
  },
  self_service_rate: {
    label: 'Self Service Rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  step_category: {
    label: 'Step Category', getter: (row, value: any) => value !== null ? value : ''
  },
  deflected: {
    label: 'Pending Saves', getter: (row, value: any) => value !== null ? value : 0
  },
  total_saves: {
    label: 'Total Saves', getter: (row, value: any) => value !== null ? value : 0
  },
  retracted_save: {
    label: 'Retracted Saves', getter: (row, value: any) => value !== null ? value : 0
  },
  deflected_hold_cancel: {
    label: 'Deflected Hold Cancels', custom_label: 'Deflected Cancels',getter: (row, value: any) => value !== null ? value : 0
  },
  deflected_subscription_cancel: {
    label: 'Deflected Subscription Cancels', custom_label: 'Deflected Cancels', getter: (row, value: any) => value !== null ? value : 0
  },
  deflected_rma: {
    label: 'Deflected RMAs', getter: (row, value: any) => value !== null ? value : 0
  },
  total_views: {
    label: 'Total Views', getter: (row, value: any) => value !== null ? value : 0
  },
  total_add_to_carts: {
    label: 'Total Add to carts', getter: (row, value: any) => value !== null ? value : 0
  },
  total_checked_outs: {
    label: 'Total Checked outs', getter: (row, value: any) => value !== null ? value : 0
  },
  total_revenue: {
    label: 'Revenue', getter: (sessionInput: ReportSessionInput, value: any) => convertToDollar(value)
  },
  total_sale: {
    label: 'Total Sales', getter: (row, value: any) => value !== null ? value : 0
  },
  cancel_hold_request: {
    label: 'Cancel on hold requests', custom_label: 'Cancel Requests', getter: (row, value: any) => value !== null ? value : 0
  },
  cancel_subscription_request: {
    label: 'Cancel on subscription requests', custom_label: 'Cancel Requests', getter: (row, value: any) => value !== null ? value : 0
  },
  rma_request: {
    label: 'Return Requests', getter: (row, value: any) => value !== null ? value : 0
  },
  subscription_adjustment: {
    label: 'Subscription Adjustments', getter: (row, value: any) => value !== null ? value : 0
  },
  save_hold_rate: {
    label: 'Save Rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  save_subscription_rate: {
    label: 'Save Rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  rma_save_rate: {
    label: 'Save Rate', getter: (sessionInput: ReportSessionInput, value: any) => value? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  deflected_hold: {
    label: 'Orders Deflected', getter: (row, value: any) => value !== null ? value : 0
  },
  subscription_reactivate: {
    label: 'Subscription Reactivates', getter: (row, value: any) => value !== null ? value : 0
  },
  reorder: {
    label: 'Reorder', getter: (row, value: any) => value !== null ? value : 0
  },
  deflected_subscription: {
    label: 'Deflected Subscriptions', getter: (row, value: any) => value !== null ? value : 0
  },
  deflected_rma_request: {
    label: 'RMAs Deflected', getter: (row, value: any) => value !== null ? value : 0
  },
  conversion_based_on_visits: {
    label: 'Visits Conversion', getter: (row, value: any) => value !== null ? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  conversion_based_on_add_to_cart: {
    label: 'Interested Buyers Conversion', getter: (row, value: any) => value !== null ? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  payment_method: {
    label: 'Payment Method', getter: (row, value: any) => value !== null ? value : ""
  },
  total_buy_nows: {
    label: 'Total Buy nows', getter: (row, value: any) => value !== null ? value : 0
  },
  total_marketplace_views: {
    label: 'Visitors', getter: (row, value: any) => value !== null ? value : 0
  },
  total_quantity: {
    label: 'Sales', getter: (row, value: any) => value !== null ? value : 0
  },
  conversion_based_on_marketplace_views: {
    label: 'Conversion', getter: (row, value: any) => value !== null ? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  conversion_based_on_views: {
    label: 'Conversion', getter: (row, value: any) => value !== null ? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  average_order_value: {
    label: 'AOV', getter: (sessionInput: ReportSessionInput, value: any) => convertToDollar(value)
  },
  watchlist: {
    label: 'Watch list', getter: (row, value: any) => value !== null ? value : ""
  },
  revise_request: {
    label: 'Revise requests', getter: (row, value: any) => value !== null ? value : 0
  },
  shipping_frequency_request: {
    label: 'Shipping Frequency requests', getter: (row, value: any) => value !== null ? value : 0
  },
  order_status_request: {
    label: 'Order Status requests', getter: (row, value: any) => value !== null ? value : 0
  },
  update_info_request: {
    label: 'Update Info requests', getter: (row, value: any) => value !== null ? value : 0
  },
  faqs_request: {
    label: 'FAQs requests', getter: (row, value: any) => value !== null ? value : 0
  },
  marketplace_request: {
    label: 'Marketplace requests', getter: (row, value: any) => value !== null ? value : 0
  },
  error: {
    label: 'Error', getter: (row, value: any) => value !== null ? value : ""
  },
  note: {
    label: 'CRM Note', getter: (row, value: any) => value !== null ? value : ""
  },
  email: {
    label: 'Customer Email', getter: (row, value: any) => value !== null ? value : ""
  },
  phone: {
    label: 'Customer Phone', getter: (row, value: any) => value !== null ? value : ""
  },
  path_name: {
    label: 'Path Name', getter: (row, value: any) => value !== null ? value : ""
  },
  errors_count: {
    label: 'Total Failures', getter: (row, value: any) => value !== null ? value : 0
  },
  converted: {
    label: 'Total Converted', getter: (row, value: any) => value !== null ? value : 0
  },
  conversion_rate: {
    label: 'Conversion Rate', getter: (row, value: any) => value !== null ? `${(value * 100).toFixed(2)}%`: '0.00%'
  },
  total_cart_abandoned: {
    label: 'Total Cart Abandoned', getter: (row, value: any) => value !== null ? value : 0
  },
  total_card_declined: {
    label: 'Total Card Declined', getter: (row, value: any) => value !== null ? value : 0
  },
  failed_tries: {
    label: 'Failed Tries', getter: (row, value: any) => value !== null ? value : 0
  },
  ip_address: {
    label: 'IP Address', getter: (row, value: any) => value !== null ? value : ""
  },
  clicks: {
    label: 'Clicks', getter: (row, value: any) => value !== null ? value : ""
  },
  declined: {
    label: 'Declines', getter: (row, value: any) => value !== null ? value : ""
  },
  potential_revenue: {
    label: 'Potential Revenue', getter: (row, value: any) => value !== null ? value : ""
  },
  sp_session_path: {
    label: 'Product Path',
    getter: (row, value: any) => value ? value : ''
  },
  sp_session_step: {
    label: 'Step', getter: (row, value: any) => value ? value : ''
  },
  sp_session_status: {label: 'Session Status',
    getter: (row, value: any) => value ? value : ''},
  sp_session_result: {
    label: 'Session Result',
    getter: (row, value: any) => value ? value : ''
  },
  sp_session_latest_result: {
    label: 'Latest Result',
    getter: (row, value: any) => value ? value : ''
  },
  sp_session_steps_count: {
    label: 'Steps Count', getter: (row, value: any) => value
  },
  path_aff_ids: {
    label: 'Affiliate Ids of Path',
    getter: (row, value: any) => value ? value : ''
  },
  session_time: {
    label: 'Session Time',
    getter: (row, value: any) => {
      let converted = convertSecondsToSessionTime(value);
      if (converted) {
        return converted;
      }

      return '';
    }
  }
}
