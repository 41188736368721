import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { CRMTypes } from '../_models';
import { map } from "rxjs/operators";
import { PaginationService } from "./pagination.service";
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { escape } from 'lodash';

@Injectable()
export class CRMService extends PaginationService {
  constructor(
    protected http: HttpClient,
    private authService: AuthenticationService,
    private router: Router
  ) {
    super(http, 'crm');
  }

  getTypes() : Observable<CRMTypes> {
    let typesString = sessionStorage.getItem('crmTypes');

    if (typesString) {
      //get the cached crm types
      return observableOf(JSON.parse(typesString));
    }
    else {
      //crm types not cached, so get it from the server
      return this.http.get<any>('crm/types/').pipe(map((types: CRMTypes) => {
        sessionStorage.setItem('crmTypes', JSON.stringify(types)); //cache the crm types returned from the server
        return types;
      }));
    }
  }

  sync(id) {
    return this.http.post('crm/' + id.toString() + '/sync_campaigns/', {});
  }

  syncTransactionData(id) {
    return this.http.post('crm/' + id.toString() + '/sync_transactions/', {},
      {'headers':{'chargeback_api':'true'}}
      );
  }

  copyOrder(id, data: any) {
    return this.http.post('crm/' + id.toString() + '/copy_order/', data);
  }

  connectShopifyApp() {
    const jwtToken = this.authService.getAuthToken();  
    const parentWindow = window.opener;
    
    console.log(jwtToken);
    if (jwtToken && parentWindow) {
      const message = {
        type: 'jwt',
        data: {
          jwtToken: jwtToken
        }
      };
      parentWindow.postMessage(message, '*');
      this.router.navigate(['/sso-complete']);
      return true;
    }
    return false;
  }
}
