import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PlanService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {CampaignStatus, Plan} from "../_models";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './plan-edit.component.html'
})
export class PlanNewComponent extends CrudSaveComponent implements OnInit {
  plan: Plan;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected planService: PlanService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService
  ) {
    super(router, location, route, planService, alertService);
    this.isNew = true;
    this.objectName = 'Billing Plan';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      description: [null],
      subscription_rate: [null, [Validators.required, Validators.min(0)]],
      included_sessions: [null, [Validators.required, Validators.min(0)]],
      crm_action_fee: [null, [Validators.required, Validators.min(0)]],
      session_fee: [null, [Validators.required, Validators.min(0)]],
      included_local_numbers: [null, [Validators.required, Validators.min(0)]],
      included_tollfree_numbers: [null, [Validators.required, Validators.min(0)]],
      local_number_rate: [null, [Validators.required, Validators.min(0)]],
      local_call_rate: [null, [Validators.required, Validators.min(0)]],
      tollfree_number_rate: [null, [Validators.required, Validators.min(0)]],
      tollfree_call_rate: [null, [Validators.required, Validators.min(0)]],
      sip_call_rate: [null, [Validators.required, Validators.min(0)]],
      call_recording_rate: [null, [Validators.required, Validators.min(0)]],
      sms_outbound_fee: [null, [Validators.required, Validators.min(0)]],
      mms_outbound_fee: [null, [Validators.required, Validators.min(0)]],
      sms_inbound_fee: [null, [Validators.required, Validators.min(0)]],
      mms_inbound_fee: [null, [Validators.required, Validators.min(0)]],
      is_active: [false],
      num_trial_days: [null, [Validators.required, Validators.min(0)]],
      include_crm_actions: [false],
      handoff_rate: [100, [Validators.required, Validators.min(0), Validators.max(100)]],
      transaction_rate: [null, [Validators.required, Validators.min(0)]],
      transaction_fee: [null, [Validators.required, Validators.min(0)]],
      usage_credit_limit: [500, [Validators.required, Validators.min(10)]],
    });
    super.ngOnInit();
  }

  toggleInludeCRMActions(event) {
    if (event.target.checked) {
      this.form.patchValue({crm_action_fee: 0}); // don't care but needs to be set to something to validate
    } else {
      this.form.patchValue({crm_action_fee: this.getControlValue('session_fee')});
    }

    this.toggleBooleanField(event, 'include_crm_actions');
  }

  getFormData() {
    if (this.form.value.include_crm_actions) {
      this.form.value.crm_action_fee = this.form.value.session_fee;
    }

    return Object.assign({}, this.form.value, {handoff_rate: this.form.value.handoff_rate / 100});
  }
}
