export enum ReportTypes {
  PATH_PERFORMANCE = 1,
  REFUND = 2,
  CUSTOM = 3,
  FAQ = 4,
  SESSION_SUMMARY = 5,
  CHARGEBACK = 6,
  MARKETPLACE = 7,
  RETENTION = 8,
  DASHBOARD = 9,
  RETENTION_DASHBOARD = 10,
  PAYMENT_UPDATER = 11,
  SP_CHARGEBACK = 12,
  SUBSCRIPTION_RETENTION = 13,
  ORDER_RETENTION = 14,
  RETURN_RETENTION = 15,
}

export enum ReportDimensionCategories {
  Dimensions = 0,
}

export enum ReportDimensions {
  ProductPath = "product_path",
  ProductName = "product_name",
  StepImpressions = "step_name",
  CancelReason = "cancel_reason",
  Brand = "campaign_name",
  InputName = "input_name",
  CRMCampaignName = "crm_campaign_name",
  CRMCampaignID = "crm_campaign_id",
  CampaignProductID = "campaign_product_id",
  SessionID = "username",
  CustomerID = "customer_id",
  OrderID = "order_id",
  Hour = "hour",
  Week = "week",
  Month = "month",
  Year = "year",
  RefundQuantity = "refund_quantity",
  AmountPaid = "amount_paid",
  RefundAmount = "refund_amount",
  ParitalRefundAmount = "partial_refund_amount",
  FullRefundAmount = "full_refund_amount",
  CustomerCount = "customer_count",
  OrdersCount = "orders_count",
  PathChannel = "source",
  TrafficSource = "tracking_source",
  SourceSub1 = "sid1",
  SourceSub2 = "sid2",
  SourceSub3 = "sid3",
  CustomerName = "customer_name",
  CustomerEmail = "email",
  CustomerPhone = "phone",
  Error = "error",
  CRMNote = "note",
  PathName = "path_name",
  VideoRecording = "recording_url",
  Affiliate = "aff_id",
  AffSub1 = "sub1",
  AffSub2 = "sub2",
  AffSub3 = "sub3",
  Country = "country",
  DeviceType = "device_type",
  BrowserName = "browser_name",
  DeviceOSVersion = "os_version",
  DeviceBrand = "mobile_vendor",
  DeviceModel = "mobile_model",
  IPAddress = "ip_address",
  SessionActionResult = "action_result",
  SessionResult = "result",
  SessionActionStatus = "session_action_status",
  SessionQuantity = "session_count",
  FailedTries = "failed_tries",
  Tests = "exclude_tests",
  AllZeroes = "exclude_zeroes",
  AlertSource = "alert_source",
  BillingCycle = "billing_cycle",
  CardBIN = "card_bin",
  CardType = "card_type",
  CardLast4 = "card_last4",
  ChargebackDate = "chargeback_date",
  MerchantID = "merchant_id",
  MIDNumber = "mid_number",
  PaymentMethod = "payment_source",
  RefundDate = "refund_date",
  RequestType = "request_type",
  SolvpathSessionID = "sp_session_id",
  SessionSource = "sp_session_source",
  SolvpathMatchedType = "matched_status",
  TransactionAmount = "amount",
  TransactionID = "transaction_id",
  TransactionDate = "created",
  TransactionType = "transaction_type",
  AlertRatio = "alert_ratio",
  ChargebackRatio = "chargeback_ratio",
  DisputeCount = "dispute_count",
  DsiputeRatio = "dispute_ratio",
  TimePeriod = "time_period",
  HoldPath = "hold_path",
  RMAPath = "rma_path",
  SubscriptionPath = "subscription_path",
}
