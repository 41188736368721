import { AfterViewInit, Component, OnInit } from "@angular/core";
import { eDashboardSection } from "./dashboard.enum";
import { delay, isObjectEmpty } from "../_helpers";
import {
  AccountService,
  AlertService,
  DashboardService,
  LoaderService,
  OverviewPathChannelService,
  UserService,
} from "../_services";
import {
  Account,
  DashboardCampaignBreakdown,
  DashboardCustomerRequestsAPIResponse,
  DashboardFAQ,
  DashboardFilter,
  DashboardMarketplaceCampaignAPIResponse,
  DashboardMarketplaceProductConversionsAPIResponse,
  DashboardMarketplaceSalesAPIResponse,
  DashboardMarketplaceSummaryAPIResponse,
  DashboardMarketplaceTopCardsAPIResponse,
  DashboardMarketplaceTotalVisitorsAPIResponse,
  DashboardMarketplaceWatchlist,
  DashboardRetentionDeflectionDataAPIResponse,
  DashboardRetentionStatisticsAPIResponse,
  DashboardRetentionWatchlistAPIResponse,
  DashboardSessionResultsAPIResponse,
  EngagementCards,
  InfoCards,
  MarketplaceCampaign,
  MarketplaceProductConversion,
  MarketplaceSummary,
  MarketplaceTopCards,
  MarketplaceVisitor,
  Pager,
  RetentionCancelCategory,
  RetentionDeflectionData,
  RetentionDownsellAPIResponse,
  RetentionDownsellData,
  RetentionMiscDataAPIResponse,
  RetentionSummaryData,
  RetentionWatchlist,
  SessionResult,
} from "../_models";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";
import { config } from "../../config/config";
import { Subject } from "rxjs";
import { OverviewCustomerRequestsService } from "../_services/dashboard/overview/overview-customer-requests.service";
import { OverviewTopFaqsService } from "../_services/dashboard/overview/overview-top-faqs.service";
import { OverviewCostSavingsService } from "../_services/dashboard/overview/overview-cost-savings.service";
import { OverviewCampaignBreakdownService } from "../_services/dashboard/overview/overview-campaign-breakdown.service";
import {
  OverviewInfo,
  PathChannelSource,
  campaigns,
  customerRequests,
  engagementCardData,
  faqs,
  overviewInfoCardsData,
  sessions,
  sessionsDoughnutData,
  sessionsTableData,
} from "./overview/overview.data";
import { DoughnutChartData } from "./data-visualization/doughnut-chart/doughnut-chart.component";
import {
  DefaultTableRecord,
  InlineTableData,
} from "./data-visualization/data-table/data-table.component";
import { RetentionDeflectionDataService } from "../_services/dashboard/retention/retention-deflection-data.service";
import { RetentionStatisticsService } from "../_services/dashboard/retention/retention-statistics.service";
import { RetentionWatchlistService } from "../_services/dashboard/retention/retention-watchlist.service";
import { RetentionDownsellService } from "../_services/dashboard/retention/retention-downsell-take-rate.service";
import { RetentionSummaryService } from "../_services/dashboard/retention/retention-summary.service";
import {
  RetentionInfoMid,
  RetentionInfoTop,
  RetentionWatchlistFields,
  cancellationData,
  cancellationTableData,
  deflection,
  downsells,
  pathBreakdowns,
  retentionMidCards,
  retentionTopCards,
  summaryTableData,
  summmaryData,
  surveys,
  watchlist,
} from "./retention/retention.data";
import { MarketplaceCampaignService } from "../_services/dashboard/marketplace/marketplace-campaign.service";
import { MarketplaceProductConversionsService } from "../_services/dashboard/marketplace/marketplace-product-conversions.service";
import { MarketplaceSalesService } from "../_services/dashboard/marketplace/marketplace-sales.service";
import { MarketplaceSummaryService } from "../_services/dashboard/marketplace/marketplace-summary.service";
import { MarketplaceTopCardsService } from "../_services/dashboard/marketplace/marketplace-top-cards.service";
import { MarketplaceTotalVisitorsService } from "../_services/dashboard/marketplace/marketplace-total-visitors.service";
import { MarketplaceWatchlistService } from "../_services/dashboard/marketplace/marketplace-watchlist.service";
import {
  MarketplaceInfo,
  campaignConversions,
  marketplaceDoughnutData,
  marketplaceInfoCards,
  marketplaceTableData,
  marketplaceWatchlist,
  productConversions,
  saleResponses,
  visitors,
} from "./marketplace/marketplace.data";
import { OverviewSessionsService } from "../_services/dashboard/overview/overview-sessions.service";
import { RetentionMiscDataService } from "../_services/dashboard/retention/retention-misc-data.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements AfterViewInit, OnInit {
  selectedSection;
  selectedFilters: DashboardFilter = null;
  isReportDownloading: boolean = false;
  showOverviewReport: boolean = false;
  viewReport: string;
  accounts: Account[] = [];

  protected overviewServicesMap = {};
  protected retentionServicesMap = {};
  protected marketplaceServicesMap = {};

  /* OVERVIEW */
  // Misc
  isObjectEmpty = isObjectEmpty;

  // Cards Data
  infoCards: InfoCards = overviewInfoCardsData;
  engagementCards: EngagementCards = engagementCardData;

  // Charts Data
  campaigns = campaigns;
  faqs = faqs;
  sessionsDoughnutData: DoughnutChartData = sessionsDoughnutData;
  customerRequests = customerRequests;
  sessionsTableData: InlineTableData = sessionsTableData;
  sessions = sessions;

  /* RETENTION */
  // Cards Data
  retentionTopCards: InfoCards = retentionTopCards;
  retentionMidCards: InfoCards = retentionMidCards;

  // Charts Data
  summaryData: DoughnutChartData = summmaryData;
  summaryTableData: InlineTableData = summaryTableData;
  cancellationData: DoughnutChartData = cancellationData;
  cancellationTableData: InlineTableData = cancellationTableData;
  surveyResponses = surveys;
  downsells = downsells;
  deflection = deflection;
  watchlist = watchlist;
  pathBreakdowns = pathBreakdowns;

  /* MARKETPLACE */
  // Cards Data
  marketplaceInfoCardsData: InfoCards = marketplaceInfoCards;

  // Charts Data
  marketplaceDoughnutData: DoughnutChartData = marketplaceDoughnutData;
  marketplaceTableData: InlineTableData = marketplaceTableData;
  saleResponses = saleResponses;
  visitors = visitors;
  productConversions = productConversions;
  campaignConversions = campaignConversions;
  activeWatchlists = marketplaceWatchlist;

  protected _destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private loader: LoaderService,
    private accountService: AccountService,
    private dashboardService: DashboardService,
    protected overviewPathChannelService: OverviewPathChannelService,
    protected overviewCustomerRequestsService: OverviewCustomerRequestsService,
    protected overviewTopFAQsService: OverviewTopFaqsService,
    protected overviewCostSavingsService: OverviewCostSavingsService,
    protected overviewCampaignBreakdownService: OverviewCampaignBreakdownService,
    protected dashboardSessionService: OverviewSessionsService,
    protected retentionMiscDataService: RetentionMiscDataService,
    protected retentionDeflectionDataService: RetentionDeflectionDataService,
    protected retentionStatisticsService: RetentionStatisticsService,
    protected retentionWatchlistService: RetentionWatchlistService,
    protected retentionDownsellService: RetentionDownsellService,
    protected retentionSummaryService: RetentionSummaryService,
    protected marketplaceCampaignService: MarketplaceCampaignService,
    protected marketplaceProductConversionsService: MarketplaceProductConversionsService,
    protected marketplaceSalesService: MarketplaceSalesService,
    protected marketplaceSummaryService: MarketplaceSummaryService,
    protected marketplaceTopCardsService: MarketplaceTopCardsService,
    protected marketplaceTotalVisitorsService: MarketplaceTotalVisitorsService,
    protected marketplaceWatchlistService: MarketplaceWatchlistService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    this.overviewServicesMap["dashboardSessionService"] =
      dashboardSessionService;
    this.overviewServicesMap["overviewPathChannelService"] =
      overviewPathChannelService;
    this.overviewServicesMap["overviewCustomerRequestsService"] =
      overviewCustomerRequestsService;
    this.overviewServicesMap["overviewTopFAQsService"] = overviewTopFAQsService;
    this.overviewServicesMap["overviewCostSavingsService"] =
      overviewCostSavingsService;
    this.overviewServicesMap["overviewCampaignBreakdownService"] =
      overviewCampaignBreakdownService;
    this.retentionServicesMap["retentionMiscDataService"] =
      retentionMiscDataService;
    this.retentionServicesMap["retentionDeflectionDataService"] =
      retentionDeflectionDataService;
    this.retentionServicesMap["retentionStatisticsService"] =
      retentionStatisticsService;
    this.retentionServicesMap["retentionWatchlistService"] =
      retentionWatchlistService;
    this.retentionServicesMap["retentionSummaryService"] =
      retentionSummaryService;
    this.marketplaceServicesMap["marketplaceCampaignService"] =
      marketplaceCampaignService;
    this.marketplaceServicesMap["marketplaceProductConversionsService"] =
      marketplaceProductConversionsService;
    this.marketplaceServicesMap["marketplaceSalesService"] =
      marketplaceSalesService;
    this.marketplaceServicesMap["marketplaceSummaryService"] =
      marketplaceSummaryService;
    this.marketplaceServicesMap["marketplaceTopCardsService"] =
      marketplaceTopCardsService;
    this.marketplaceServicesMap["marketplaceTotalVisitorsService"] =
      marketplaceTotalVisitorsService;
    this.marketplaceServicesMap["marketplaceWatchlistService"] =
      marketplaceWatchlistService;
    this.selectedSection = eDashboardSection.Overview;
  }

  ngOnInit(): void {
    this.setAccountID();
    this.getAccountsList();
    this.getDashboardData();
    this.getSectionData();
  }

  getSectionData() {
    /* OVERVIEW */
    if (this.selectedSection === "OVERVIEW") {
      this.getDashboardPathChannelData();
      this.getSessionsData();
      this.getCostSavings();
      this.getCustomerRequestsData();
      this.getTopFAQsData();
      this.getCampaignsBreakdownData();
    } else if (this.selectedSection === "RETENTION") {
      /* RETENTION */
      this.getMiscData();
      this.getStatisticsData();
      this.getRetentionSummary();
      // this.getRetentionCancelCategoriesData();
      // this.getDownsellData();
      this.getDeflectionData();
      this.getWatchlistData();
    } else if (this.selectedSection === "MARKETPLACE") {
      /* MARKETPLACE */
      this.getInfoCardsData();
      // this.getVisitors();
      this.getMarketplaceSummaryData();
      this.getSalesData();
      this.getProductConversions();
      // this.getCampaignConversions();
      this.getMarketplaceWatchlistData();
    }
  }

  setAccountID() {
    this.selectedFilters = this.dashboardService.getFilter();
    this.accountService.selectedAccount$.subscribe(
      (account: any) => {
        if (account) {
          this.dashboardService.setFilter({
            ...this.selectedFilters,
            account_id: account.id,
            campaign_id: null,
          });
        }
      },
      (error) => {
        this.alertService.error(error.message);
      }
    );
  }

  getAccountsList() {
    this.accountService
      .list({ page: 1, page_size: config.maxPageSize })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.accounts = data.results;
        },
        (error) => {
          this.alertService.error(error.message);
        }
      );
  }

  async ngAfterViewInit() {
    await delay(2000);
    this.loader.hide();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onSectionChange(section) {
    if (section === this.selectedSection) return;
    this.selectedSection = section;
    this.dashboardService.setFilter({
      ...this.selectedFilters,
      section,
    });
    this.getSectionData();
  }

  getDashboardData() {
    this.dashboardService.filters$
      .pipe(takeUntil(this._destroy$))
      .subscribe((change) => {
        this.selectedFilters = change;
        this.initiateCardsLoading();
        if (this.selectedFilters.account_id && this.selectedFilters.crm_id) {
          if (this.selectedSection === "OVERVIEW") {
            Object.keys(this.overviewServicesMap).forEach((key) => {
              this.overviewServicesMap[key].setFilter(change);
              this.overviewServicesMap[key].getData();
              this.sessions.flag = true;
            });
            this.updateSessionFilter();
          } else if (this.selectedSection === "RETENTION") {
            Object.keys(this.retentionServicesMap).forEach((key) => {
              this.retentionServicesMap[key].setFilter(change);
              this.retentionServicesMap[key].getData();
            });
          } else if (this.selectedSection === "MARKETPLACE") {
            Object.keys(this.marketplaceServicesMap).forEach((key) => {
              this.marketplaceServicesMap[key].setFilter(change);
              this.marketplaceServicesMap[key].getData();
            });
          }
        }
      });
  }

  getCostSavings() {
    this.overviewCostSavingsService.data$.subscribe((costSaving) => {
      if (!costSaving && costSaving !== 0) return;

      const formattedCostSaving = costSaving.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.infoCards[
        OverviewInfo.CostSavings
      ].value = `$${formattedCostSaving}`;
    });
  }

  getSessionsData() {
    this.dashboardSessionService.data$
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: DashboardSessionResultsAPIResponse) => {
        if (!data) return;

        const { self_service_rate, session_results } = data;
        const { Successful, Bypass, Dropoffs, Lifeline } = session_results;
        const successfulSessions = Successful || 0;
        const bypassSessions = Bypass || 0;
        const dropoffSessions = Dropoffs || 0;
        const lifelineSessions = Lifeline || 0;

        const totalSessions =
          successfulSessions +
          bypassSessions +
          dropoffSessions +
          lifelineSessions;

        /* Stopping Loading */
        this.sessionsDoughnutData.loading = false;

        /* Info Cards Data */
        if (self_service_rate[0] === 0 && self_service_rate[1] === 0)
          this.infoCards[OverviewInfo.SelfService].value = "0%";
        else
          this.infoCards[OverviewInfo.SelfService].value = `${(
            (self_service_rate[0] / self_service_rate[1]) *
            100
          ).toFixed(2)}%`;
        this.infoCards[
          OverviewInfo.Sessions
        ].value = `${totalSessions.toLocaleString()}`;
        this.infoCards[
          OverviewInfo.SuccessfulSessions
        ].value = `${successfulSessions.toLocaleString()}`;

        /* Sessions Table & Chart Data */
        /* CASE: No Data Returned from API */
        if (totalSessions === 0) {
          this.sessionsTableData.forEach((data) => {
            data.value = 0;
            data.percentage = "0%";
          });

          this.sessionsDoughnutData.data = [
            successfulSessions,
            bypassSessions,
            dropoffSessions,
            lifelineSessions,
          ];

          /* Chart No Record Case: true */
          this.sessionsDoughnutData.noData = true;
        } else {
          /* CASE: Data Returned from API */
          /* Chart No Record Case: false */
          this.sessionsDoughnutData.noData = false;

          /* Chart Data */
          this.sessionsDoughnutData.data = [
            successfulSessions,
            bypassSessions,
            dropoffSessions,
            lifelineSessions,
          ];

          /* Table Data */
          this.sessionsTableData[0].value = successfulSessions.toLocaleString();
          this.sessionsTableData[0].percentage =
            successfulSessions > 0
              ? `${((successfulSessions / totalSessions) * 100).toFixed(2)}%`
              : "0%";

          this.sessionsTableData[1].value = bypassSessions.toLocaleString();
          this.sessionsTableData[1].percentage =
            bypassSessions > 0
              ? `${((bypassSessions / totalSessions) * 100).toFixed(2)}%`
              : "0%";

          this.sessionsTableData[2].value = dropoffSessions.toLocaleString();
          this.sessionsTableData[2].percentage =
            dropoffSessions > 0
              ? `${((dropoffSessions / totalSessions) * 100).toFixed(2)}%`
              : "0%";

          this.sessionsTableData[3].value = lifelineSessions.toLocaleString();
          this.sessionsTableData[3].percentage =
            lifelineSessions > 0
              ? `${((lifelineSessions / totalSessions) * 100).toFixed(2)}%`
              : "0%";
        }
      });
  }

  getCustomerRequestsData() {
    this.overviewCustomerRequestsService.data$.subscribe(
      (requests: DashboardCustomerRequestsAPIResponse) => {
        if (!requests) return;
        this.customerRequests.data = [];
        this.customerRequests.loading = false;
        if (isObjectEmpty(requests)) {
          this.customerRequests.noData = true;
          return;
        }
        const validKeys = [
          "cancel_hold_request",
          "revise_request",
          "shipping_frequency_request",
          "order_status_request",
          "update_info_request",
          "faqs_request",
          "marketplace_request",
        ];

        this.customerRequests.data = Object.keys(requests)
          .filter((key) => validKeys.includes(key))
          .map((key) => ({
            name: key
              .replace("_request", "")
              .split("_")
              .map((word, index) => {
                if (word === "frequency" && index === 1) {
                  return "Freq";
                }
                if (word === "revise") return "Revise Order";
                return (
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                );
              })
              .join(" "),
            value: requests[key] || 0,
          }));
        if (this.customerRequests.data.length)
          this.customerRequests.noData = false;
      }
    );
  }

  getDashboardPathChannelData() {
    this.overviewPathChannelService.data$.subscribe((pathChannels) => {
      if (!pathChannels) return;
      this.engagementCards[0].count =
        pathChannels[PathChannelSource.Phone] || "0";
      this.engagementCards[1].count =
        pathChannels[PathChannelSource.Email] || "0";
      this.engagementCards[2].count =
        pathChannels[PathChannelSource.Web] || "0";
      this.engagementCards[3].count =
        pathChannels[PathChannelSource.SocialMedia] || "0";

      this.engagementCards.forEach((card) => {
        if (card.count) card.count = card.count.toLocaleString();
      });
    });
  }

  getTopFAQsData() {
    this.overviewTopFAQsService.data$.subscribe((FAQs) => {
      if (!FAQs) return;
      this.faqs.data = [];
      this.faqs.loading = false;

      if (FAQs.length >= 3)
        FAQs.forEach((faq: DashboardFAQ) => {
          const faqData = {
            name: faq.question,
            value: faq.views,
          };

          this.faqs.data.push(faqData);
        });
    });
  }

  getCampaignsBreakdownData() {
    this.overviewCampaignBreakdownService.data$.subscribe((campaigns) => {
      if (!campaigns) return;
      this.campaigns.data = [];
      this.campaigns.loading = false;

      if (campaigns.length)
        campaigns.forEach((campaign: DashboardCampaignBreakdown) => {
          const campaignData: DefaultTableRecord = {
            name: campaign.crm_campaign_name,
            id: campaign.crm_campaign_id,
            value: campaign.session_count.toLocaleString(),
          };

          if (campaign.diff_percentage) {
            if (campaign.diff_percentage < 0) {
              campaignData.is_negative = true;
            }
            campaignData.percentage = Math.abs(campaign.diff_percentage);
          }
          this.campaigns.data.push(campaignData);

          if (campaigns.length === 1) {
            this.campaigns.title = "Top Campaign";
          }
        });
    });
  }

  updateSessionFilter() {
    if (!this.sessions.flag) {
      return;
    }
    let filter = { started: true };

    if ("start_date" in this.selectedFilters) {
      filter["date_joined__gt"] = moment(this.selectedFilters.start_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if ("end_date" in this.selectedFilters) {
      filter["date_joined__lte"] = moment(this.selectedFilters.end_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if (this.selectedFilters.campaign_id) {
      filter["campaign"] = this.selectedFilters.campaign_id;
    }

    if (!this.sessions.filters.includeTestSessions) {
      filter["is_test"] = false;
    }

    if (this.selectedFilters.account_id) {
      filter["account_id"] = this.selectedFilters.account_id;
    }

    let result_filters: SessionResult[] = [];

    if (!this.sessions.filters.includeHangups) {
      result_filters.push(SessionResult.Abandoned);
    }

    if (!this.sessions.filters.includeUnknowns) {
      result_filters.push(SessionResult.Unknown);
    }

    if (result_filters.length > 0) {
      filter["result__in!"] = result_filters.join(",");
    }

    this.sessions.filter = { ...filter };
    this.sessions.flag = false;
  }

  /* Session Table Props */
  onTestSessionsChange(event) {
    this.sessions.filters.includeTestSessions = event.target.checked;
    this.dashboardService.setFilter({
      ...this.selectedFilters,
      ...{ includeTest: this.sessions.filters.includeTestSessions },
    });
    this.sessions.state.includeTest = this.sessions.filters.includeTestSessions;
  }

  onHangupsChange(event) {
    this.sessions.filters.includeHangups = event.target.checked;
    this.dashboardService.setFilter({
      ...this.selectedFilters,
      ...{ includeHangups: this.sessions.filters.includeHangups },
    });
    this.sessions.state.includeHangups = this.sessions.filters.includeHangups;
  }

  onUnknownsChange(event) {
    this.sessions.filters.includeUnknowns = event.target.checked;
    this.dashboardService.setFilter({
      ...this.selectedFilters,
      ...{ includeUnknowns: this.sessions.filters.includeUnknowns },
    });
    this.sessions.state.includeUnknowns = this.sessions.filters.includeUnknowns;
  }

  /* ======================RETENTION=======================*/
  getMiscData() {
    this.retentionMiscDataService.data$.subscribe(
      (miscData: RetentionMiscDataAPIResponse) => {
        const {
          customers_cancelled,
          customers_saved,
          path_breakdown,
          survey_responses,
          cancel_categories,
        } = miscData;

        /* Customers Cancelled */
        this.retentionTopCards[RetentionInfoTop.CustomersLost].value =
          customers_cancelled.toLocaleString();

        /* Cancel Categories (Hidden For Now)*/
        // this.cancellationData.noData = false;
        // this.cancellationData.loading = false;
        // if (Array.isArray(cancel_categories) && cancel_categories.length > 0) {
        //   cancel_categories.forEach((i: RetentionCancelCategory) => {
        //     switch (i.cancel_reason_category) {
        //       case "Unwanted":
        //         this.assignCancelCategoryData(i, 0);
        //         break;
        //       case "Technical issues":
        //         this.assignCancelCategoryData(i, 1);
        //         break;
        //       case "Price":
        //         this.assignCancelCategoryData(i, 2);
        //         break;
        //       case "Product issue":
        //         this.assignCancelCategoryData(i, 3);
        //         break;
        //       case "Support issues":
        //         this.assignCancelCategoryData(i, 4);
        //         break;
        //       case "Shipping":
        //         this.assignCancelCategoryData(i, 5);
        //         break;
        //     }
        //     this.cancellationTableData.forEach((data, index) => {
        //       data.value = this.cancellationData.data[index].toLocaleString();
        //       if (data.percentage === null) data.percentage = "0%";
        //     });
        //     if (
        //       this.cancellationData.data.every((value: number) => value === 0)
        //     ) {
        //       this.cancellationData.noData = true;
        //     }
        //   });
        // } else {
        //   this.cancellationData.noData = true;
        //   this.cancellationTableData.forEach((data) => {
        //     data.value = 0;
        //     data.percentage = "0%";
        //   });
        // }

        /* Survey Responses */
        this.surveyResponses.data = [];
        this.surveyResponses.loading = false;
        if (survey_responses.length >= 3) {
          const responses = [];
          for (const response of survey_responses) {
            if (response.cancel_reason) {
              responses.push({
                name: response.cancel_reason,
                value: response.cancelled.toLocaleString() || 0,
              });
            }
          }
          this.surveyResponses.data = responses;
        }

        /* Path Breakdown */
        this.pathBreakdowns.data = [];
        this.pathBreakdowns.loading = false;
        if (path_breakdown.length > 0) {
          const paths = [];
          for (const path of path_breakdown) {
            paths.push({
              name: path.product_path,
              // type: "Type1",
              sessions: path.session_count.toLocaleString() || "0",
              saved: path.saved || 0,
            });
          }
          this.pathBreakdowns.data = paths;
        }
      }
    );
  }

  /* Cancel Categories hidden for now */
  // assignCancelCategoryData(i: RetentionCancelCategory, index: number) {
  //   this.cancellationData.data[index] = i.cancelled;
  //   this.cancellationTableData[index].percentage = `${i.cancelled_percentage}%`;
  // }

  getStatisticsData() {
    this.retentionStatisticsService.data$.subscribe(
      (apiData: DashboardRetentionStatisticsAPIResponse) => {
        if (!apiData) return;
        if (apiData && Object.keys(apiData).length > 0) {
          const apiResponse = apiData[0];
          this.retentionTopCards[RetentionInfoTop.RevenueSaved].value =
            "$" +
            apiResponse.save_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

          this.retentionTopCards[RetentionInfoTop.Saves].value =
            apiResponse.total_saves.toLocaleString();

          this.retentionTopCards[RetentionInfoTop.SaveRate].value =
            (apiResponse.total_save_rate * 100).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + "%";

          this.retentionMidCards[RetentionInfoMid.OrdersSaved].value =
            apiResponse.hold_save.toLocaleString();
          this.retentionMidCards[RetentionInfoMid.OrdersCancelled].value =
            apiResponse.hold_cancel.toLocaleString();
          this.retentionMidCards[RetentionInfoMid.SubscriptionsSaved].value =
            apiResponse.subscription_save.toLocaleString();
          this.retentionMidCards[
            RetentionInfoMid.SubscriptionsCancelled
          ].value = apiResponse.subscription_cancel.toLocaleString();
        } else {
          // Set values to '0' when no data is available
          this.retentionMidCards[RetentionInfoMid.OrdersSaved].value = "0";
          this.retentionMidCards[RetentionInfoMid.OrdersCancelled].value = "0";
          this.retentionMidCards[RetentionInfoMid.SubscriptionsSaved].value =
            "0";
          this.retentionMidCards[
            RetentionInfoMid.SubscriptionsCancelled
          ].value = "0";
          this.retentionTopCards[RetentionInfoTop.RevenueSaved].value = "$0";
          this.retentionTopCards[RetentionInfoTop.Saves].value = "0";
          this.retentionTopCards[RetentionInfoTop.SaveRate].value = "0%";
        }
      }
    );
  }

  getRetentionSummary() {
    this.retentionSummaryService.data$.subscribe((summary) => {
      if (!summary) return;
      this.summaryData.noData = false;
      this.summaryData.loading = false;

      if (Array.isArray(summary) && summary.length > 0) {
        summary.forEach((element: RetentionSummaryData) => {
          this.summaryData.data[0] = element.total_saves || 0;
          this.summaryData.data[1] = element.retracted_save || 0;
          this.summaryData.data[2] = element.dropoffs || 0;
          this.summaryData.data[3] = element.return_quantity || 0;
          this.summaryData.data[4] = element.cancelled || 0;

          const totalSessions = this.summaryData.data
            .slice(0, 5)
            .reduce((acc, val) => acc + val, 0);
          this.summaryData.total = totalSessions.toLocaleString();

          this.summaryTableData[0].percentage = `${(
            (this.summaryData.data[0] / totalSessions) *
            100
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`;

          this.summaryTableData[1].percentage = `${(
            (this.summaryData.data[1] / totalSessions) *
            100
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`;

          this.summaryTableData[2].percentage = `${(
            (this.summaryData.data[2] / totalSessions) *
            100
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`;

          this.summaryTableData[3].percentage = `${(
            (this.summaryData.data[3] / totalSessions) *
            100
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`;

          this.summaryTableData[4].percentage = `${(
            (this.summaryData.data[4] / totalSessions) *
            100
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`;

          this.summaryTableData.forEach((data, index) => {
            data.value = this.summaryData.data[index].toLocaleString();
            if (data.percentage === null || data.percentage === "NaN%")
              data.percentage = "0%";
          });

          if (this.summaryData.data.every((value: number) => value === 0)) {
            this.summaryData.noData = true;
          }
        });
      } else {
        this.summaryData.noData = true;
        this.summaryTableData.forEach((data) => {
          data.value = 0;
          data.percentage = "0%";
        });
      }
    });
  }

  getDownsellData() {
    this.retentionDownsellService.data$.subscribe(
      (downsell: RetentionDownsellAPIResponse) => {
        if (!downsell) return;
        this.downsells.data = [];
        this.downsells.loading = false;

        if (Array.isArray(downsell) && downsell.length > 1)
          downsell.forEach((i: RetentionDownsellData) => {
            const downsellData: DefaultTableRecord = {
              name: i.step_category,
              value: `${(i.downsell_take_rate * 100).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`,
            };

            if (i.diff_percentage) {
              if (i.diff_percentage < 0) {
                downsellData.is_negative = true;
              }
              downsellData.percentage = Math.abs(i.diff_percentage);
            }

            this.downsells.data.push(downsellData);
          });
      }
    );
  }

  getDeflectionData() {
    this.retentionDeflectionDataService.data$.subscribe(
      (deflection: DashboardRetentionDeflectionDataAPIResponse) => {
        if (!deflection) return;
        this.deflection.noData = false;
        this.deflection.loading = false;

        if (Array.isArray(deflection) && deflection.length > 0) {
          deflection.forEach((i: RetentionDeflectionData) => {
            switch (i.key) {
              case "total_saves":
                this.assignDeflectionData(i, 0);
                break;
              case "retracted_save":
                this.assignDeflectionData(i, 1);
                break;
              case "deflected_cancel":
                this.assignDeflectionData(i, 2);
                break;
              case "deflected_rma":
                this.assignDeflectionData(i, 3);
                break;
              case "return_quantity":
                this.assignDeflectionData(i, 4);
                break;
              case "cancelled":
                this.assignDeflectionData(i, 5);
                break;
            }

            this.deflection.data.forEach((deflection) => {
              if (!deflection.value) deflection.value = 0;
            });
          });
        } else {
          this.deflection.data.forEach((deflection) => {
            deflection.value = 0;
          });
          this.deflection.noData = true;
        }
      }
    );
  }

  assignDeflectionData(i: RetentionDeflectionData, index: number) {
    this.deflection.data[index].value = i.count.toLocaleString() || "0";
    if (i.diff_percentage) {
      if (i.diff_percentage < 0) {
        this.deflection.data[index].is_negative = true;
      }
      this.deflection.data[index].percentage = Math.abs(i.diff_percentage);
    }
  }

  getWatchlistData() {
    this.retentionWatchlistService.data$.subscribe(
      (watchlist: DashboardRetentionWatchlistAPIResponse) => {
        if (!watchlist) return;

        if (Array.isArray(watchlist) && watchlist.length > 0) {
          watchlist.forEach((i: RetentionWatchlist) => {
            switch (i.reason) {
              case "Dispute Risk":
                this.assignWatchlistData(
                  i,
                  RetentionWatchlistFields.DisputeRisk
                );
                break;
              case "Cancel Dropoff":
                this.assignWatchlistData(
                  i,
                  RetentionWatchlistFields.CancelDropOff
                );
                break;
              case "Return Dropoff":
                this.assignWatchlistData(
                  i,
                  RetentionWatchlistFields.ReturnDropOff
                );
                break;
              case "Deflected Customer":
                this.assignWatchlistData(
                  i,
                  RetentionWatchlistFields.DeflectedCustomers
                );
                break;
              case "Pause Subscription":
                this.assignWatchlistData(
                  i,
                  RetentionWatchlistFields.PausedSubscriptions
                );
                break;
              case "Session Error":
                this.assignWatchlistData(
                  i,
                  RetentionWatchlistFields.SessionErrors
                );
                break;
            }

            this.watchlist.data.forEach((watchlist) => {
              if (!watchlist.value) watchlist.value = 0;
            });
          });

          // Calculate the sum of values in watchlistData
          this.watchlist.total = this.watchlist.data
            .reduce((total, item) => {
              const itemValue = parseFloat(item.value);
              if (!isNaN(itemValue)) {
                return total + itemValue;
              }
              return total;
            }, 0)
            .toLocaleString();
        } else {
          this.watchlist.data.forEach((watchlist) => {
            watchlist.value = 0;
          });
          this.watchlist.total = "0";
        }

        // Update the loading state
        this.watchlist.loading = false;
      }
    );
  }

  assignWatchlistData(i: RetentionWatchlist, index: number) {
    this.watchlist.data[index].value = i.count.toLocaleString() || "0";
    if (i.trend) {
      if (i.trend < 0) {
        this.watchlist.data[index].is_negative = true;
      }
      this.watchlist.data[index].percentage = Math.abs(i.trend);
    }
  }

  /* ======================MARKETPLACE=======================*/

  getInfoCardsData() {
    this.marketplaceTopCardsService.data$.subscribe(
      (apiData: DashboardMarketplaceTopCardsAPIResponse) => {
        if (!apiData) return;

        const data = apiData as MarketplaceTopCards;

        if (data && Object.keys(data).length > 0) {
          this.marketplaceInfoCardsData[MarketplaceInfo.Revenue].value =
            "$" +
            data.total_revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

          this.marketplaceInfoCardsData[MarketplaceInfo.CompletedOrders].value =
            data.total_checked_outs.toLocaleString();

          this.marketplaceInfoCardsData[
            MarketplaceInfo.AverageOrderValue
          ].value =
            "$" +
            data.average_order_value.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

          this.marketplaceInfoCardsData[MarketplaceInfo.ConversionRate].value =
            (data.conversion_based_on_visits * 100).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + "%";
        } else {
          // Set values to '0' when no data is available
          this.marketplaceInfoCardsData[MarketplaceInfo.Revenue].value = "0";
          this.marketplaceInfoCardsData[MarketplaceInfo.CompletedOrders].value =
            "0";
          this.marketplaceInfoCardsData[
            MarketplaceInfo.AverageOrderValue
          ].value = "0";
          this.marketplaceInfoCardsData[MarketplaceInfo.ConversionRate].value =
            "0";
        }
      }
    );
  }

  getVisitors() {
    this.marketplaceTotalVisitorsService.data$.subscribe(
      (request: DashboardMarketplaceTotalVisitorsAPIResponse) => {
        if (!request) return;
        this.visitors.data = [];
        this.visitors.loading = false;
        this.visitors.noData = false;
        this.visitors.total = "0";
        let total = 0;

        if (Array.isArray(request) && request.length > 0) {
          const series = [];
          request.forEach((visitor: MarketplaceVisitor) => {
            const visitorData = {
              name: moment(visitor.created)
                .tz(this.selectedFilters.timezone)
                .format("hh:mm A"),
              value: visitor.total_marketplace_views,
            };

            total = total + visitorData.value;
            series.push(visitorData);
          });
          this.visitors.total = total.toLocaleString();
          this.visitors.data = [
            {
              name: "Visitors",
              series: series,
            },
          ];
        } else {
          this.visitors.noData = true;
        }
      }
    );
  }

  getMarketplaceSummaryData() {
    this.marketplaceSummaryService.data$
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: DashboardMarketplaceSummaryAPIResponse) => {
        if (!data) return;

        this.marketplaceDoughnutData.loading = false;
        this.marketplaceDoughnutData.noData = false;

        if (isObjectEmpty(data)) {
          this.marketplaceDoughnutData.noData = true;
          return;
        }

        const marketplaceSummary = data as MarketplaceSummary;

        this.marketplaceDoughnutData.data = [
          marketplaceSummary.total_checked_outs,
          marketplaceSummary.total_cart_abandoned,
          marketplaceSummary.total_card_declined,
        ];

        this.marketplaceDoughnutData.total = (
          marketplaceSummary.total_checked_outs +
          marketplaceSummary.total_cart_abandoned +
          marketplaceSummary.total_card_declined
        ).toLocaleString();

        const totalSessions = +this.marketplaceDoughnutData.total;

        this.marketplaceTableData[0].value =
          marketplaceSummary.total_checked_outs.toLocaleString();
        this.marketplaceTableData[0].percentage = `${(
          (marketplaceSummary.total_checked_outs / totalSessions) *
          100
        ).toFixed(2)}%`;

        this.marketplaceTableData[1].value =
          marketplaceSummary.total_cart_abandoned.toLocaleString();
        this.marketplaceTableData[1].percentage = `${(
          (marketplaceSummary.total_cart_abandoned / totalSessions) *
          100
        ).toFixed(2)}%`;

        this.marketplaceTableData[2].value =
          marketplaceSummary.total_card_declined.toLocaleString();
        this.marketplaceTableData[2].percentage = `${(
          (marketplaceSummary.total_card_declined / totalSessions) *
          100
        ).toFixed(2)}%`;
      });
  }

  getSalesData() {
    this.marketplaceSalesService.data$.subscribe(
      (request: DashboardMarketplaceSalesAPIResponse) => {
        if (!request) return;
        this.saleResponses.data = [];
        this.saleResponses.loading = false;

        if (request && Object.keys(request).length >= 3) {
          const responsesArray = [];
          for (const key in request) {
            if (request.hasOwnProperty(key)) {
              const response = request[key];
              const name = response.tracking_source;

              if (name !== null) {
                const modifiedName =
                  name.charAt(0).toUpperCase() +
                  name.slice(1).replace(/-/g, " ");
                responsesArray.push({
                  name: modifiedName,
                  value: response.total_sale || 0,
                });
              }
            }
          }
          this.saleResponses.data = responsesArray;
        }
      }
    );
  }

  getProductConversions() {
    this.marketplaceProductConversionsService.data$.subscribe(
      (request: DashboardMarketplaceProductConversionsAPIResponse) => {
        if (!request) return;
        this.productConversions.data = [];
        this.productConversions.loading = false;

        if (Array.isArray(request) && request.length > 0) {
          request.forEach((product: MarketplaceProductConversion) => {
            const productConversionData: DefaultTableRecord = {
              name: product.product_name.split(" (")[0],
              visitors: product.total_views.toLocaleString(),
              sales: product.total_checked_outs.toLocaleString(),
              revenue: "$" + product.total_revenue.toLocaleString(),
              value: `${(product.conversion_based_on_visits * 100).toFixed(
                2
              )}%`,
            };

            if (product.trend < 0) {
              productConversionData.is_negative = true;
            }
            productConversionData.percentage = Math.abs(product.trend);

            this.productConversions.data.push(productConversionData);
          });
        }
      }
    );
  }

  getCampaignConversions() {
    this.marketplaceCampaignService.data$.subscribe(
      (request: DashboardMarketplaceCampaignAPIResponse) => {
        if (!request) return;
        this.campaignConversions.data = [];
        this.campaignConversions.loading = false;

        if (Array.isArray(request) && request.length > 0) {
          request.forEach((campaign: MarketplaceCampaign) => {
            const campaignConversionData: DefaultTableRecord = {
              name: campaign.crm_campaign_name,
              value: `${(campaign.conversion_based_on_visits * 100).toFixed(
                2
              )}%`,
            };

            if (campaign.trend < 0) {
              campaignConversionData.is_negative = true;
            }
            campaignConversionData.percentage = Math.abs(campaign.trend);

            this.campaignConversions.data.push(campaignConversionData);
          });
        }
      }
    );
  }

  getMarketplaceWatchlistData() {
    this.marketplaceWatchlistService.data$.subscribe(
      (apiResponse: DashboardMarketplaceWatchlist) => {
        if (!apiResponse) return;
        this.activeWatchlists.loading = false;
        this.activeWatchlists.noData = false;

        if (apiResponse && Object.keys(apiResponse).length > 0) {
          this.activeWatchlists.data[0].value =
            apiResponse.total_cart_abandoned.toLocaleString();
          this.activeWatchlists.data[0].percentage =
            apiResponse.total_cart_abandoned_trend;
          this.activeWatchlists.data[1].value =
            apiResponse.total_card_declined.toLocaleString();
          this.activeWatchlists.data[1].percentage =
            apiResponse.total_card_declined_trend;
          this.activeWatchlists.data[2].value =
            apiResponse.in_cart.toLocaleString();
          this.activeWatchlists.data[2].percentage = apiResponse.in_cart_trend;

          if (apiResponse.total_cart_abandoned_trend < 0) {
            this.activeWatchlists.data[0].percentage = parseFloat(
              apiResponse.total_cart_abandoned_trend.toString().slice(1)
            );
            this.activeWatchlists.data[0].is_negative = true;
          }

          if (apiResponse.total_card_declined_trend < 0) {
            this.activeWatchlists.data[1].percentage = parseFloat(
              apiResponse.total_card_declined_trend.toString().slice(1)
            );
            this.activeWatchlists.data[1].is_negative = true;
          }

          if (apiResponse.in_cart_trend < 0) {
            this.activeWatchlists.data[2].percentage = parseFloat(
              apiResponse.in_cart_trend.toString().slice(1)
            );
            this.activeWatchlists.data[2].is_negative = true;
          }

          this.activeWatchlists.total = (
            apiResponse.in_cart +
            apiResponse.total_card_declined +
            apiResponse.total_cart_abandoned
          ).toLocaleString();
        } else {
          this.activeWatchlists.noData = true;
          this.activeWatchlists.total = "0";
        }
      }
    );
  }

  /* Loading Methods */
  initiateCardsLoading() {
    /* OVERVIEW */
    if (this.selectedSection === "OVERVIEW") {
      this.infoCards.forEach((card) => (card.value = ""));
      this.engagementCards.forEach((card) => (card.count = ""));
      this.customerRequests.loading = true;
      this.faqs.loading = true;
      this.campaigns.loading = true;

      this.sessionsDoughnutData.noData = false;
      this.sessionsDoughnutData.loading = true;
      this.sessionsTableData.forEach((session) => {
        session.value = null;
        session.percentage = null;
      });
    }

    /* RETENTION */
    if (this.selectedSection === eDashboardSection.Retention) {
      this.retentionTopCards.forEach((card) => (card.value = ""));
      this.retentionMidCards.forEach((card) => (card.value = ""));
      this.deflection.data.forEach((data) => {
        data.value = 0;
        data.percentage = null;
      });

      this.cancellationData.data = [0, 0, 0, 0, 0, 0];
      this.summaryData.data = [0, 0, 0, 0, 0, 0];

      this.summaryTableData.forEach((data) => {
        data.value = 0;
        data.percentage = "0%";
      });

      this.cancellationTableData.forEach((data) => {
        data.value = 0;
        data.percentage = "0%";
      });

      this.surveyResponses.loading = true;
      this.watchlist.loading = true;
      this.deflection.loading = true;
      this.pathBreakdowns.loading = true;
      this.downsells.loading = true;
      this.summaryData.loading = true;
      this.cancellationData.loading = true;

      this.cancellationData.noData = false;
      this.deflection.noData = false;
      this.summaryData.noData = false;
    }

    /* MARKETPLACE */
    if (this.selectedSection === eDashboardSection.Marketplace) {
      this.marketplaceInfoCardsData.forEach((card) => (card.value = ""));
      this.saleResponses.loading = true;
      this.visitors.loading = true;
      this.marketplaceDoughnutData.loading = true;
      this.productConversions.loading = true;
      this.campaignConversions.loading = true;
      this.activeWatchlists.loading = true;
    }
  }

  async downloadReport() {
    this.isReportDownloading = true;
    this.loader.show("white", "Generating your report");
    this.viewReport = this.selectedSection;
    await delay(1500);

    const filters = this.dashboardService.getFilter();
    const brandName = this.accounts.find(
      (account) => account.id === +filters.account_id
    ).name;
    const page1 = document.getElementById(this.selectedSection);

    const headerHeight = 1.8; // Height of the header in inches
    const contentHeight = 11; // Remaining height for the content

    const PDF = new jsPDF({
      unit: "in",
      format: [8.5, 11],
    });

    // Draw header background
    PDF.setFillColor(240, 240, 240); // Background color
    PDF.rect(0, 0, 8.5, headerHeight, "F");

    // Draw image
    const img = new Image();
    img.src = "/assets/img/report-header.png";
    const imgWidth = 8.5; // Set the image width to match document width
    const imgX = 0; // Image starts from the left
    PDF.addImage(img, "JPEG", imgX, 0, imgWidth, headerHeight);

    // Draw text
    const headingY = headerHeight / 2;
    const reportTypeY = headerHeight / 2 + 0.5;
    const dateY = headerHeight / 2 + 0.85;

    PDF.setTextColor(22, 53, 73);
    PDF.setFontSize(32);
    PDF.text(brandName, 4.25, headingY, { align: "center" });

    PDF.setFontSize(22);
    const reportName =
      this.selectedSection.charAt(0).toUpperCase() +
      this.selectedSection.slice(1).toLowerCase() +
      " Report";
    PDF.text(reportName, 4.25, reportTypeY, { align: "center" });

    PDF.setFontSize(13);
    PDF.setTextColor(124, 141, 153);
    const formattedStartDate = moment(this.selectedFilters.start_date).format(
      "MMMM D"
    );
    const formattedEndDate = moment(this.selectedFilters.end_date).format(
      "MMMM D, YYYY"
    );
    PDF.text(`${formattedStartDate} - ${formattedEndDate}`, 4.25, dateY, {
      align: "center",
    });

    let page1Padding = 0.7;
    let page2Padding = 0.6;

    if (this.selectedSection === eDashboardSection.Marketplace) {
      page1Padding = 0;
      page2Padding = 0.8;
    }

    // Draw content
    html2canvas(page1, {
      scrollX: 0,
      scrollY: 0,
      scale: 5,
    }).then((canvas) => {
      const contentURI = canvas.toDataURL("image/jpeg");
      PDF.addImage(
        contentURI,
        "JPEG",
        0,
        headerHeight + page1Padding,
        8.5,
        contentHeight
      );

      // Load the footer image
      const imgFooter = new Image();
      imgFooter.src = "/assets/img/report-footer.png";

      // Draw footer image
      const imgFooterWidth = 8.5; // Set the image width to match document width
      const imgFooterHeight = 0.51; // Height of the footer image
      const imgFooterY = 11 - imgFooterHeight; // Position the image at the bottom
      PDF.addImage(
        imgFooter,
        "PNG",
        0,
        imgFooterY,
        imgFooterWidth,
        imgFooterHeight
      );

      /* ======== PAGE 2 ======== */
      if (this.selectedSection !== eDashboardSection.Marketplace) {
        const page2 = document.getElementById(this.selectedSection + "2");

        PDF.addPage();

        PDF.setFillColor(240, 240, 240);
        PDF.rect(0, 0, 8.5, headerHeight, "F");

        PDF.addImage(img, "JPEG", imgX, 0, imgWidth, headerHeight);

        PDF.setTextColor(22, 53, 73);
        PDF.setFontSize(32);
        PDF.text(brandName, 4.25, headingY, { align: "center" });

        PDF.setFontSize(22);
        PDF.text(reportName, 4.25, reportTypeY, { align: "center" });

        PDF.setFontSize(13);
        PDF.setTextColor(124, 141, 153);

        PDF.text(`${formattedStartDate} - ${formattedEndDate}`, 4.25, dateY, {
          align: "center",
        });

        // Draw content for page 2
        html2canvas(page2, {
          scrollX: 0,
          scrollY: 0,
          scale: 10,
        }).then((canvas2) => {
          const contentURI2 = canvas2.toDataURL("image/jpeg");
          PDF.addImage(
            contentURI2,
            "JPEG",
            0,
            headerHeight + page2Padding,
            8.5,
            contentHeight
          );

          PDF.addImage(
            imgFooter,
            "PNG",
            0,
            imgFooterY,
            imgFooterWidth,
            imgFooterHeight
          );

          PDF.save(`${reportName} (${formattedStartDate} ${formattedEndDate})`);
        });
      } else
        PDF.save(`${reportName} (${formattedStartDate} ${formattedEndDate})`);

      this.viewReport = "";
      this.loader.hide();
      this.isReportDownloading = false;
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }
}
