import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {ImageSize, Cycle, CampaignProduct, getProductImageUrl} from "../_models";
import {Form} from '../_forms';
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './cycle.component.html',
  selector: 'cycles',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class CycleComponent extends Form implements OnInit, OnChanges {
  selectedCycleIndex: number;
  selectedCycle: Cycle;

  @Input('product') campaignProduct: CampaignProduct;

  constructor(
    protected router: Router,
    protected location: Location,
    private modalService: NgxSmartModalService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.updateForm();
    super.ngOnInit();
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm() {
    this.selectedCycle = null;
  }

  getCycleProduct(cycle: Cycle) {
    return cycle.product || this.campaignProduct.product;
  }

  getCycleImageUrl(cycle: Cycle) {
    return getProductImageUrl(this.getCycleProduct(cycle), ImageSize.original);
  }

  getCycleName(cycle: Cycle) {
    return cycle.display_name || this.getCycleProduct(cycle).name;
  }

  edit(cycleIndex: number) {
    this.selectedCycleIndex = cycleIndex;
    this.selectedCycle = this.campaignProduct.cycles[this.selectedCycleIndex];
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('cycleDialog').open();
  }

  onSaveCycle(cycle: Cycle) {
    cycle.product = this.selectedCycle.product;
    cycle.combination = this.selectedCycle.combination;
    this.modalService.getModal('cycleDialog').close();
    Object.assign(this.campaignProduct.cycles[this.selectedCycleIndex], cycle);
    this.selectedCycle = null;
  }

}
