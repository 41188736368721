import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, CampaignService, OrderService, PhoneService } from '../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { CrudSaveComponent } from '../_directives';
import {Campaign, CampaignProduct, Phone, Pager, NewOrder} from "../_models";
import { takeUntil, mergeMap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { config } from "../../config/config";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-test.component.html'
})
export class CampaignTestComponent extends CrudSaveComponent implements OnInit {
  campaign: Campaign;
  selectedProducts: CampaignProduct[] = [];
  orders: NewOrder[] = [];
  phoneNumbers: Phone[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private phoneService: PhoneService,
    private modalService: NgxSmartModalService,
  ) {
    super(router, location, route, campaignService, alertService);
    this.objectName = 'Test Order';
    this.title = 'Create Test Order';
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      phone: [null],
      email: [null, Validators.email],
      campaign_products: [[]],
      decline_transaction: [false],
      mark_shipped: [false],
      force_rebill: [false],
    });

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        this.campaign = campaign;

        return this.phoneService.list({page: 1, page_size: config.maxPageSize, campaign: campaign.id})
      }

      return EMPTY;
    })).subscribe(
      (data: Pager) => {
        if (data) {
          this.phoneNumbers = data.results;
        }
      },
      error => {
        this.handleError(error);
      });

    super.ngOnInit();

  }

  onProductSelected(product: CampaignProduct) {
    this.selectedProducts = [product];
    this.modalService.getModal('selectProductsDialog').close();
  }

  selectProduct() {
    this.modalService.getModal('selectProductsDialog').open();
  }

  createNewOrder() {
    this.submitted = false;
    this.orders = [];
    this.title = 'Create Another Test Order';
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      const orderData = Object.assign({}, this.form.value, {
        email: this.form.value.email.trim() || null,
        phone: this.form.value.phone.trim() || null,
        campaign_products: this.selectedProducts.map((product: CampaignProduct) => product.id),
      });

      this.orderService.sendTestOrder(orderData).pipe(takeUntil(this.destroy$)).subscribe(
        (orders: NewOrder[]) => {
          this.orders = orders;
          this.loading = false;
          this.title = 'Created Test Order';
          this.canDeactivateService.setFormDirty(false);
        },
        error => {
          this.handleSubmitError(error);
          this.loading = false;
        }
      );
    }
  }

}
