import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CRMCampaignService, CampaignService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder} from '@angular/forms';
import {CRMCampaign, Campaign, Pager, CRMCampaignShippingMethod} from '../_models';
import {config} from "../../config/config";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  selector: 'crm-campaign-form',
  templateUrl: './crm-campaign-form.component.html'
})
export class CRMCampaignFormComponent extends CrudSavePopupComponent implements OnInit {
  campaigns: Campaign[] = [];
  shippingMethods: CRMCampaignShippingMethod[] = [];
  crmCampaign: CRMCampaign;
  @Input('bulkIds') bulkIds: (string | number)[];

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected crmCampaignService: CRMCampaignService,
              protected alertService: AlertService,
              protected campaignService: CampaignService
  ) {
    super(router, location, route, crmCampaignService, alertService);
    this.objectName = 'CRM Campaign';
  }

  ngOnInit() {
    this.shippingMethods = [];
    this.setForm(this.formBuilder.group({
      campaign: [null],
      keep_subscriptions_in_sync: [true],
      separate_next_cycle_items: [false]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.crmCampaign = this.inputData;

    if (this.crmCampaign) {
      this.form.patchValue(this.crmCampaign);

      this.campaignService.list({
        is_hybrid: false,
        page: 1,
        page_size: config.maxPageSize
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: Pager) => {
            this.campaigns = data.results;
          },
          error => {
            this.handleError(error);
          }
        );

    if (!this.isBulk) {
      this.crmCampaignService.listShippingMethods(+this.crmCampaign.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            this.shippingMethods = data;
          },
          error => {
            this.handleError(error);
          }
        );
      }
    }
  }

  get isBulk() {
    return this.bulkIds && (this.bulkIds.length > 0);
  }

  getFormData(): any {
    let formData = super.getFormData();
    if (!this.isBulk) {
      formData['shipping_methods'] = this.shippingMethods.map((shippingMethod: CRMCampaignShippingMethod) => shippingMethod.id);
    }
    return formData;
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      if (this.isBulk) {
        //perform bulk update of data
        let data = this.getFormData();

        this.removeEmptyFields(data);
        this.crmCampaignService.bulkUpdateWithSameData(this.bulkIds, data)
          .pipe(takeUntil(this._destroy$))
          .subscribe(
            data => {
              this.alertService.success('CRM campaign bulk update successful.', true);
              this.canDeactivateService.setFormDirty(false);
              this.onSaveComplete(data);
            },
            error => {
              this.handleSubmitError(error);
              this.loading = false;
            });
      }
      else {
        super.onSubmit(); //normal object update
      }
    }
  }

  private removeEmptyFields(data) {
    if (!data['campaign']) {
      delete data['campaign'];
    }
  }

}
