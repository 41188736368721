import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location } from '@angular/common';
import {AlertService, CampaignService, CampaignCategoryService} from '../_services';
import {CrudPagedListComponent} from '../_directives';
import {Campaign, CampaignStatus, Category, Pager} from '../_models';
import {FormBuilder} from '@angular/forms';
import {Observable} from "rxjs";
import {config} from '../../config/config';
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign.component.html'
})
export class CampaignComponent extends CrudPagedListComponent implements OnInit {
  campaigns$: Observable<Campaign[]> = this.data$;
  categories: Category[];
  loading = false;
  isHybrid = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    protected categoryService: CampaignCategoryService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService);
    this.objectName = 'brand';
    this.title = 'Brands';
  }

  ngOnInit() {
    this.campaignService.hideMenu();
    this.form = this.formBuilder.group({
      categories: [null]
    });

    super.ngOnInit();

    if (!this.isHybrid) {
      this.categoryService.list({page: 1, page_size: config.maxPageSize})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (pager: Pager) => {
            this.categories = pager.results;
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  protected initializeFilter() {
    super.initializeFilter();
    this.filter['is_hybrid'] = this.isHybrid;
  }

  isActive(campaign: Campaign) {
    return (campaign.status !== CampaignStatus.Inactive) ? true : false;
  }

  isEnabled(campaign: Campaign) {
    return (campaign.status === CampaignStatus.Active) ? true : false;
  }

  enable(campaign: Campaign, event) {
    let enable: boolean = event.target.checked;
    let action = enable ? 'enable' : 'disable';

    if (this.isActive(campaign) &&
        window.confirm('Are you sure you want to ' + action + ' ' + campaign.name + ' campaign?')) {
      this.campaignService.patch(campaign.id, {status: enable ? CampaignStatus.Active : CampaignStatus.Disabled})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            action = enable ? 'Enabled' : 'Disabled';
            this.alertService.success(action + ' ' + campaign.name + ' campaign.');
          },
          error => {
            this.handleSubmitError(error);
            event.target.checked = !enable; //revert checkbox to previous state
          });
    }
    else {
      event.target.checked = !enable; //revert checkbox to previous state (should not get here but just in case)
    }
  }

}
