import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, ProductInfoSectionService } from '../_services';
import { Section } from '../_models';
import { CrudListComponent } from '../_directives';
import { Observable } from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-info-section.component.html'
})
export class ProductInfoSectionComponent extends CrudListComponent implements OnInit {
  sections$ : Observable<Section[]> = this.data$;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected sectionService: ProductInfoSectionService,
              protected alertService: AlertService) {
    super(router, location, route, sectionService, alertService);
    this.objectName = 'section';
    this.title = 'Product Info Sections';
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
