import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AccountService, AlertService, UserService} from '../_services';
import {CrudPagedListComponent} from '../_directives';
import {Account, User, AccountStatusLabels, AccountBillingStatusLabels, Pager} from '../_models';
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {NgxSmartModalService} from "ngx-smart-modal";
import {parseISODateString} from "../_helpers";
import {FormControl} from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent extends CrudPagedListComponent implements OnInit {
  accounts$: Observable<Account[]> = this.data$;
  user: User;
  selectedAccountId = null;
  editAccount: Account;
  searchInput: FormControl = new FormControl('');
  filter = {};
  isLoading = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected alertService: AlertService,
    private userService: UserService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, accountService, alertService);
    this.objectName = 'account';
    this.title = 'Accounts';
  }

  ngOnInit() {
    super.ngOnInit();

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;

          if (user.account) {
            this.selectedAccountId = user.account.id;
          }
        },
        error => {
          this.handleError(error);
        }
      );
    this.searchInput.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(value=> {
        this.page = 1;
        if (value) {
          this.filter['name__icontains'] = value;
        } else {
          delete this.filter['name__icontains'];
        }
        this.queryData();
      });

    if (this.filter['name__icontains']) {
      this.searchInput.setValue(this.filter['name__icontains']);
    }
  }

  isSelected(account: Account) {
    return account.id === this.selectedAccountId;
  }

  select(account: Account, event) {
    const selectedAccountId = event.target.checked ? account.id : null;

    // If selectedAccountId is null, this means the checkbox is unselected, this will set account id to null
    // so returning from here to not break other things
    if (!selectedAccountId) {
      event.target.checked = true;
      return;
    }

    if (this.user && this.user.is_staff && (selectedAccountId !== this.selectedAccountId)) {
      this.isLoading = true
      this.accountService.select(selectedAccountId)
        .subscribe(
          data => {
            const msg = selectedAccountId ?
              "You're now associated with the " + account.name + " account." :
              "You're now not associated with any account.";
            this.selectedAccountId = selectedAccountId;
            this.alertService.success(msg, true);
            this.userService.clearCache();
            this.router.navigate(['/']);
            this.accountService.setUserAccount(account);
            this.isLoading = false
          },
          error => {
            this.handleSubmitError(error);
            this.isLoading = false
          });
    }
  }

  getStatusLabel(account: Account) {
    return AccountStatusLabels[account.status];
  }

  getBillingStatusLabel(account: Account) {
    return AccountBillingStatusLabels[account.billing_status];
  }

  getPlanLabel(account: Account) {
    return account.plan ? account.plan.name : 'None';
  }

  formatDate(dateString: string) {
    return dateString ?
      parseISODateString(dateString).toLocaleDateString("en-US", {timeZone: 'UTC'}) : '';
  }

  edit(account: Account) {
    this.editAccount = account;
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('editAccountDialog').open();
  }

  onSaveAccount(account: Account) {
    this.modalService.getModal('editAccountDialog').close();
    this.queryData();
  }

  protected onPagerData(data: Pager) {
    super.onPagerData(data);
    setTimeout(() => {
      this.scrollToTopOfTable();
    }, 0);
  }

  protected scrollToTopOfTable() {
    window.scrollTo({ top: 90, behavior: 'smooth' });
  }
}
