import {Component} from '@angular/core';
import {CrudPagedListComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ShippingSystemService} from '../_services';
import {ShippingSystem} from '../_models';
import {Observable} from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './shipping-system.component.html',
  styleUrls: ['./shipping-system.component.css']
})
export class ShippingSystemComponent extends CrudPagedListComponent {
  shippingSystem$: Observable<ShippingSystem[]> = this.data$;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected shippingSystemService: ShippingSystemService,
              protected alertService: AlertService) {
    super(router, location, route, shippingSystemService, alertService);
    this.title = 'Shipping System'
  }
}
