import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, UserService } from '../_services/index';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './register-verify.component.html',
  styleUrls: ['./register-verify.component.css']
})

export class RegisterVerifyComponent implements OnInit {
  public message = 'Verifying your credentials'
  public loading: boolean = false
  public showResendButton: boolean = false
  public heading = ''
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
  ) { }

  ngOnInit() {
    let uid = this.route.snapshot.queryParams['uid'];
    let token = this.route.snapshot.queryParams['token'];
    this.loading = true
    this.userService.verify(uid, token)
      .subscribe(
        data => {
          this.loading = false
          this.heading = 'Your Registration Has Been Completed!'
          this.message = 'Please wait for your account to be approved <br/> by one of our representatives.'
        },
        error => {
          this.loading = false
          if (error.status === 403) {
            this.showRegistrationCompleted()
          } else if (error.status === 400) {
            this.showTokenError()
          } else {
            this.showError(error.statusText)
          }
        });
  }

  showRegistrationCompleted() {
    this.heading = 'Your Registration Has Been Completed!'
    this.message = 'Please wait for your account to be approved <br/> by one of our representatives.'
  }

  showTokenError() {
    this.heading = 'We\'ve encountered an issue!'
    this.message = 'We have found an issue with your token. It is either invalid or it is expired.<br/>' +
      'Please click on the button below to get your verification email again'
    this.showResendButton = true
  }

  showError(message) {
    this.heading = 'We\'ve encountered an issue!'
    this.message = `We have encountered an issue while processing your credentials. <br/> ${message}`
  }
}
