import {BaseModel} from './base-model';

export enum EmailIntegrationType {
  FORWARDING = 1
}

export const EmailIntegrationTypeLabels = {};
EmailIntegrationTypeLabels[EmailIntegrationType.FORWARDING] = 'Forwarding';

export enum EmailIntegrationStatus {
  UNVERIFIED = 0,
  VERIFIED_DOMAIN_ONLY = 1,
  VERIFIED = 2
}

export const EmailIntegrationStatusLabels = {};
EmailIntegrationStatusLabels[EmailIntegrationStatus.UNVERIFIED] = 'Awaiting Forwarding Verification';
EmailIntegrationStatusLabels[EmailIntegrationStatus.VERIFIED_DOMAIN_ONLY] = 'Awaiting SPF verification';
EmailIntegrationStatusLabels[EmailIntegrationStatus.VERIFIED] = 'Verified';

export interface EmailIntegration extends BaseModel {
  name: string;
  type: EmailIntegrationType;
  status: EmailIntegrationStatus;
  forwarding_email: string;
  email: string;
  auth_link: string;
}
