import {componentOptions, InputTypes} from '../index';
import {typeLogout} from './logout-button-type';
import {typeMainMenu} from './main-menu-button-type';

export const typeFooter = 'footer';

export const footerType = editor => {
  const domc = editor.DomComponents;

  // SELECT
  domc.addType(typeFooter, {
    isComponent: el => {
      if (el && el.getAttribute) {
        if (el.getAttribute('elemtype') === InputTypes.FOOTER) {
          return {type: typeFooter, editable: false, name: 'Footer'};
        }
        else if (el.closest && el.closest(`[elemtype="${InputTypes.FOOTER}"]`)) {
          if (el.tagName == 'svg') {
            return { type: 'svg', ...componentOptions };
          } else if (el.tagName == 'path') {
            return { type: 'svg-in'};
          } else if (el.tagName === 'IMG') {
            return { type: 'image', name: 'Logo'};
          } else if (el.tagName === 'NAV') {
            return { type: 'default', name: 'Nav'};
          }
        }
      }
    },
    model: {
      defaults: {
        name: 'Footer',
        tagName: 'div',
        attributes: { elemtype: InputTypes.FOOTER, class: 'footer-wrapper' },
        components: [
          {
            name: 'Nav',
            tagName: 'nav',
            attributes: { class: 'footer-container' },
            components: [
              {
                tagName: 'ul',
                attributes: { class: 'footer-main-menu' },
                components: [{type: typeMainMenu}]
              },
              {
                tagName: 'ul',
                attributes: { class: 'footer-logout' },
                components: [{ type: typeLogout}]
              }
            ]
          },
          {
            name: 'Div',
            tagName: 'div',
            hoverable: false,
            selectable: false,
            highlightable: false,
            attributes: { class: 'footer-text' },
            components: [
              {
                type: 'text',
                attributes: { class: 'footer-content' },
                content: 'Powered By SolvPath'
              },
            ]
          }
        ]
      }
    },
  });
};
