import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  AccountService,
  AlertService,
  CampaignProductService,
  CampaignService,
  CampaignTrackingService,
  CRMCampaignService,
  FunnelStepService,
  MerchantService,
  ReportsConfigService,
  RetentionReportService,
  StorageService,
  UserService,
} from "../_services";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ReportsAbstractV2Component } from "./reports-abstract-v2.component";
import { MultiOptions } from "../custom-multi-select/multi-select";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { fields, filters } from "./utils/constants";
import { cloneDeep } from "lodash";
import { ReportDimensionCategories, ReportDimensions, ReportTypes } from "./models/reports";
import {forkJoin, merge, Observable} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  selector: "subscription-retention-report-v2",
  templateUrl: "./templates/reports-v2.component.html",
  styleUrls: ["./styles/reports-v2.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionRetentionReportV2Component
  extends ReportsAbstractV2Component
  implements OnInit, AfterViewInit
{
  reportTitle = "Subscription Retention Report";
  fields: MultiOptions = cloneDeep(fields);
  filters: MultiOptions = cloneDeep(filters);
  reportType = ReportTypes.SUBSCRIPTION_RETENTION;

  commonReportsFields = [
    "cancel_subscription_request",
    "subscription_cancel",
    "deflected_subscription_cancel",
    "subscription_save",
  ];

  summaryFields = [
    "session_count",
    "cancel_subscription_request",
    "subscription_cancel",
    "deflected_subscription_cancel",
    "subscription_save",
    "save_amount",
    "refund_amount",
    "save_subscription_rate",
    "subscription_reactivate",
  ];

  summaryRawFields = [
    [
      "cancel_subscription_request",
      "subscription_cancel",
      "deflected_subscription_cancel",
      "subscription_save",
      "save_subscription_rate",
    ],
    [
      "session_count",
      "save_amount",
      "refund_amount",
    ],
    [
      "subscription_reactivate",
    ]
  ];

  selectedDimensions = [
    {
      model: "subscription_path",
      label: "Product Path",
      selected: true,
      disabled: false,
    },
    {
      model: "product_name",
      label: "Product Name",
      selected: true,
      disabled: false,
    },
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: RetentionReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected merchantService: MerchantService
  ) {
    super(
      router,
      location,
      route,
      alertService,
      reportsService,
      campaignService,
      reportsConfigService,
      trackingSourceService,
      userService,
      accountService,
      campaignProductService,
      crmCampaignService,
      storageService,
      stepService,
      merchantService,
      modalService
    );
    this.fields[ReportDimensionCategories.Dimensions].options.find((x) => x.model === ReportDimensions.ProductPath).model =
    ReportDimensions.SubscriptionPath;
    this.updatePreSelectedDimensions([ReportDimensions.SubscriptionPath, ReportDimensions.ProductName]);
    this.filterCommonDimensions(this.commonReportsFields);
  }

  ngOnInit() {
    this.predefinedFilters = { cancel_subscription_request__gt: 0 };
    super.ngOnInit();
  }

  ngAfterViewInit() {
    if (this.panel) {
      this.panel.expanded = true;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedDimensions,
      event.previousIndex,
      event.currentIndex
    );
  }

  getSummaryApiCalls(): Observable<any> {
    const apiCalls = []
    this.summaryRawFields.forEach(summaryFields => {
      const query = this.getQueryForSummary()
      query.fields = summaryFields
      apiCalls.push(this.reportsService.getFilteredReport(query, ""))
    })

    return forkJoin(apiCalls)
      .pipe(takeUntil(this._destroy$))
      .pipe(
        map((results) => {
          let response = {}
          results.forEach(result => {
            response = {...response, ...result[0]}
          })

          return [response]
        })
      )
  }
}
