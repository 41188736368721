import handleLinkTraits from '../traits/link-traits';
import {getInputTypes, InputTypes, isValidInputType} from '../index';

const radioButtonType = editor => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('default');
  const defaultModel = defaultType.model;
  const inputTypes = getInputTypes(InputTypes.RADIO_BUTTON)

  editor.DomComponents.addType(InputTypes.RADIO_BUTTON, {
    model: defaultModel.extend({
      defaults: Object.assign({}, defaultModel.prototype.defaults),
      init() {
        this.doStuff();
      },
      doStuff() {
        const ctype = this.getAttributes().ctype;
        if (isValidInputType(ctype)) {
          if (!this.get('traits').where({name: 'path-name'})[0]) {
            this.addTrait({
              name: 'path-name',
              type: 'path-name-text',
              label: 'Label',
              changeProp: 1
            });
          }
          if (!this.get('traits').where({name: 'select-type'})[0]) {
            this.addTrait({
              type: 'select-link-type',
              label: 'Type',
              name: 'select-type',
              options: [...inputTypes],
            });
          }
          handleLinkTraits(this, ctype);
        }
      },
    },{
      isComponent: (el) => {
        if (el && el.getAttribute && el.getAttribute('elemtype') == InputTypes.RADIO_BUTTON) {
          return { type: 'radio' };
        }
      }
    }),
  });
};
export default radioButtonType;

