import {Component} from '@angular/core';
import {ExchangeableProductsSetNewComponent} from './exchangeable-products-set-new.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignProductService, ExchangeableProductsSetService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ExchangeableProductsSet} from '../_models';

@Component({
  moduleId: module.id,
  templateUrl: './exchangeable-products-set-edit.component.html',
  styleUrls: ['./exchangeable-products-set.component.css']
})
export class ExchangeableProductsSetEditComponent extends ExchangeableProductsSetNewComponent {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected exchangeableProductsSetService: ExchangeableProductsSetService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
    protected campaignProductService: CampaignProductService
  ) {
    super(router, location, route, exchangeableProductsSetService, alertService, formBuilder, modalService,
      campaignProductService);
    this.isNew = false
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true

    this.data$.subscribe((exchangeableProductsSet: ExchangeableProductsSet) => {
      this.loading = false
      if (exchangeableProductsSet) {
        this.exchangeableProductsSet = exchangeableProductsSet
        this.set_type = exchangeableProductsSet.set_type_id
        this.to_products = exchangeableProductsSet.exchangeable_items
        this.form.patchValue({name: exchangeableProductsSet.name})
      }
    }, error => {
      this.loading = false;
      this.handleError(error)
    })
  }
}
