import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  AccountService,
  AlertService,
  CampaignProductService,
  CampaignService,
  CampaignTrackingService,
  CRMCampaignService,
  FunnelStepService,
  MerchantService,
  PaymentUpdaterReportService,
  ReportsConfigService,
  StorageService,
  UserService,
} from "../_services";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ReportsAbstractV2Component } from "./reports-abstract-v2.component";
import { MultiOptions } from "../custom-multi-select/multi-select";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { paymentUpdaterFields, paymentUpdaterFilters } from "./utils/constants";
import { cloneDeep } from "lodash";
import { ReportTypes } from "./models/reports";

@Component({
  moduleId: module.id.toString(),
  selector: "return-retention-report-v2",
  templateUrl: "./templates/reports-v2.component.html",
  styleUrls: ["./styles/reports-v2.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentUpdaterReportV2Component
  extends ReportsAbstractV2Component
  implements OnInit, AfterViewInit
{
  reportTitle = "Payment Updater Report";
  fields: MultiOptions = cloneDeep(paymentUpdaterFields);
  predefinedFields = ["exclude_tests", "exclude_zeroes"];
  filters: MultiOptions = cloneDeep(paymentUpdaterFilters);
  reportType = ReportTypes.PAYMENT_UPDATER;

  commonReportsFields = [
    "clicks",
    "converted",
    "conversion_rate",
    "revenue",
    "declined",
    "dropoffs",
    "errors_count",
    "potential_revenue",
  ];
  summaryFields = [
    "clicks",
    "converted",
    "conversion_rate",
    "revenue",
    "recurring_revenue",
    "declined",
    "errors_count",
    "dropoffs",
  ];
  selectedDimensions = [
    {
      model: "tracking_source",
      label: "Traffic source",
      selected: true,
      disabled: false,
    },
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: PaymentUpdaterReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected merchantService: MerchantService
  ) {
    super(
      router,
      location,
      route,
      alertService,
      reportsService,
      campaignService,
      reportsConfigService,
      trackingSourceService,
      userService,
      accountService,
      campaignProductService,
      crmCampaignService,
      storageService,
      stepService,
      merchantService,
      modalService
    );

    this.updatePreSelectedDimensions("tracking_source");
    this.filterCommonDimensions(this.commonReportsFields);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    if (this.panel) {
      this.panel.expanded = true;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedDimensions,
      event.previousIndex,
      event.currentIndex
    );
  }
}
