import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {NewOrder, TestOrder, CopyTestOrder, Order} from "../_models";

@Injectable()
export class OrderService {
  constructor(protected http: HttpClient) {
  }

  sendTestOrder(data: TestOrder): Observable<NewOrder[]> {
    return <Observable<NewOrder[]>>this.http.post('order_tests/', data);
  }

  copyTestOrder(order: Order, data: CopyTestOrder): Observable<NewOrder[]> {
    return <Observable<NewOrder[]>>this.http.post('order_tests/' + order.id.toString() + '/copy/', data);
  }
}
