import {getInputTypes, InputTypes, Types} from '../index';
const inputTypes = getInputTypes(InputTypes.RADIO_BUTTON);

const radioButton = editor => {
  const bm = editor.BlockManager;
  bm.add('radio-button', {
    category: 'Basic',
    label: 'Radio Button',
    media: '<i class="fa fa-2x fa-dot-circle-o"></i>',
    content: {
      content: '',
      tagName: "div",
      draggable: true,
      droppable: false,
      layerable: false,
      type: InputTypes.RADIO_BUTTON,
      attributes: { ctype: Types.NONE, elemtype: InputTypes.RADIO_BUTTON },
      style: { display: 'inline-block', padding: '8px', "font-size": "14px"},
      traits: [{
        name: 'path-name',
        type: 'path-name-text',
        label: 'Label',
        changeProp: 1
      },
      {
        type: 'select-link-type',
        label: 'Type',
        name: 'select-type',
        options: [...inputTypes],
      }],
      components: [
        { tagName: "input", attributes: { type: "radio" }, removable: false, editable: false, highlightable: false, copyable: false },
        { tagName: "label", content: "Change Label", removable: false, editable: false, highlightable: false, copyable: false }
      ]
    }
  });
};
export default radioButton;
