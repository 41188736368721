import { Component, Input, OnInit } from '@angular/core';
import { CustomerPortalSettingHints, SettingsPreviewContent, SettingsPreviewTypes } from '../_models';

@Component({
  selector: 'app-customer-portal-preview',
  templateUrl: './customer-portal-preview.component.html',
  styleUrls: ['./customer-portal.component.scss'],
})
export class CustomerPortalPreviewComponent implements OnInit {
  @Input() activePreviewType: SettingsPreviewTypes;
  settingsPreviewTypes = SettingsPreviewTypes;

  previewContentMap: Record<SettingsPreviewTypes, SettingsPreviewContent> = {
    [SettingsPreviewTypes.CancelOrder]: {
      heading: CustomerPortalSettingHints.order.orderCancellation,
      imageSrc: '/assets/settingsAssets/cancel-my-order-preview.jpg',
      imageHeight: 255,
      bottom: '-17.5rem'
    },
    [SettingsPreviewTypes.FulfillmentStatus]: {
      heading: CustomerPortalSettingHints.order.fulfillmentStatus,
      imageSrc: '/assets/settingsAssets/fulfillment-preview.jpg',
      imageHeight: 163,
      bottom: '-14rem'
    },
    [SettingsPreviewTypes.TrackShipment]: {
      heading: CustomerPortalSettingHints.order.shipmentTracking,
      imageSrc: '/assets/settingsAssets/track-shipment-preview.jpg',
      imageHeight: 163,
      bottom: '-7.5rem'
    },
    [SettingsPreviewTypes.ViewTransaction]: {
      heading: CustomerPortalSettingHints.order.transactionStatus,
      imageSrc: '/assets/settingsAssets/view-transaction-preview.jpg',
      imageHeight: 172,
      bottom: '-10rem'
    },
    [SettingsPreviewTypes.UpsellSelection]: {
      heading: CustomerPortalSettingHints.order.upsellItemSelection,
      imageSrc: '/assets/settingsAssets/upsell-preview.jpg',
      imageHeight: 287,
      bottom: '0rem'
    },
    [SettingsPreviewTypes.HiddenFees]: {
      heading: CustomerPortalSettingHints.order.additionalFeesLabel,
      imageSrc: '/assets/settingsAssets/hidden-fees-preview.jpg',
      imageHeight: 137,
      bottom: '-1rem'
    },
    [SettingsPreviewTypes.CancelSubscription]: {
      heading: CustomerPortalSettingHints.subscription.subscriptionCancellation,
      imageSrc: '/assets/settingsAssets/cancel-subscription-preview.jpg',
      imageHeight: 255,
      bottom: '-17.5rem'
    },
    [SettingsPreviewTypes.SubscriptionStatus]: {
      heading: CustomerPortalSettingHints.subscription.subscriptionStatus,
      imageSrc: '/assets/settingsAssets/subscription-status-preview.jpg',
      imageHeight: 208,
      bottom: '-6rem'
    },
    [SettingsPreviewTypes.EditPaymentMethod]: {
      heading: CustomerPortalSettingHints.subscription.paymentMethodEditing,
      imageSrc: '/assets/settingsAssets/edit-payment-preview.jpg',
      imageHeight: 164,
      bottom: '-3rem'
    },
    [SettingsPreviewTypes.EditShippingAddress]: {
      heading: CustomerPortalSettingHints.subscription.shippingAddressEditing,
      imageSrc: '/assets/settingsAssets/edit-shipping-address-preview.jpg',
      imageHeight: 429,
      bottom: '-23.5rem'
    },
    [SettingsPreviewTypes.EditShippingFrequency]: {
      heading: CustomerPortalSettingHints.subscription.shippingFrequencyEditing,
      imageSrc: '/assets/settingsAssets/edit-shipping-frequency-preview.jpg',
      imageHeight: 247,
      bottom: '-6rem'
    },
    [SettingsPreviewTypes.SkipShipment]: {
      heading: CustomerPortalSettingHints.subscription.skipUpcomingShipment,
      imageSrc: '/assets/settingsAssets/skip-shipment-preview.jpg',
      imageHeight: 255,
      bottom: '-5rem'
    },
    [SettingsPreviewTypes.ReviseOrder]: {
      heading: CustomerPortalSettingHints.subscription.reviseSubscriptionItems,
      imageSrc: '/assets/settingsAssets/revise-order-preview.jpg',
      imageHeight: 255,
      bottom: '-5rem'
    },
    [SettingsPreviewTypes.ShowNextShipmentDate]: {
      heading: CustomerPortalSettingHints.subscription.nextShipmentDateVisibility,
      imageSrc: '/assets/settingsAssets/next-shipment-preview.jpg',
      imageHeight: 208,
      bottom: '-3rem'
    },
    [SettingsPreviewTypes.AllowReactiveSubscription]: {
      heading: CustomerPortalSettingHints.subscription.reactivateSubscription,
      imageSrc: '/assets/settingsAssets/reactive-subscriptions-preview.jpg',
      imageHeight: 255,
      bottom: '0rem'
    },
    [SettingsPreviewTypes.ShowOrderPrice]: {
      heading: CustomerPortalSettingHints.payment.showOrderPrice,
      imageSrc: '/assets/settingsAssets/order-price-preview.jpg',
      imageHeight: 187,
      bottom: '-8.2rem'
    },
    [SettingsPreviewTypes.Taxes]: {
      heading: CustomerPortalSettingHints.payment.taxes,
      imageSrc: '/assets/settingsAssets/taxes-preview.jpg',
      imageHeight: 135,
      bottom: '-3rem'
    },
    [SettingsPreviewTypes.ShippingFee]: {
      heading: CustomerPortalSettingHints.payment.shippingFee,
      imageSrc: '/assets/settingsAssets/shipping-fee-preview.jpg',
      imageHeight: 137,
      bottom: '-3rem'
    },
    [SettingsPreviewTypes.PaymentStatus]: {
      heading: CustomerPortalSettingHints.payment.paymentStatus,
      imageSrc: '/assets/settingsAssets/payment-status-preview.jpg',
      imageHeight: 197,
      bottom: '0rem'
    },
  };

  constructor() {}

  ngOnInit(): void {}

  getPreviewContent() {
    return (
      this.previewContentMap[this.activePreviewType] || {
        heading: 'Default Heading',
        imageSrc: '/assets/settingsAssets/default-preview.jpg',
        imageHeight: 200,
        bottom: '0rem'
      }
    );
  }
}
