import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelInputService, FunnelStepService, FunnelService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {FunnelInput} from "../_models";
import {FunnelInputNewComponent} from "./funnel-input-new.component";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-input-edit.component.html',
  styleUrls: ['./funnel-input.component.css']
})
export class FunnelInputEditComponent extends FunnelInputNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected inputService: FunnelInputService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected stepService: FunnelStepService,
    protected funnelService: FunnelService
  ) {
    super(router, location, route, inputService, alertService, formBuilder, stepService, funnelService);
    this.isNew = false;
    this.title = 'Edit Path Input';
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe(
      (input: FunnelInput) => {
        if (input) {
          this.input = input;
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

}
