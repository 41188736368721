import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService, AudienceService,
  FunnelService,
  ProductService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {Funnel, FunnelInputTypeEnum} from "../_models";
import {NgxSmartModalService} from "ngx-smart-modal";
import {CustomerPortalNewComponent} from "./customer-portal-new.component";
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './customer-portal-edit.component.html',
  styleUrls: ['../funnel/funnel.component.css', './customer-portal.component.scss'],
  animations: [
    trigger("fadeIn", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerPortalEditComponent extends CustomerPortalNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected modalService: NgxSmartModalService,
    protected audienceService: AudienceService
  ) {
    super(router, location, route, funnelService, alertService, formBuilder, productService, modalService,
      audienceService);
    this.isNew = false;
    this.title = '';
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.data$.subscribe(
      (funnel: Funnel) => {
        if (funnel) {
          this.funnel = funnel;
          this.form.patchValue(funnel);
          this.useMatchedWelcomeMessage = !!(funnel.matched_welcome_msg && funnel.matched_welcome_msg.length);
          this.form.patchValue(funnel.customer_portal_settings);
          this.buildProductFunnelList();

          if (funnel.customer_portal_settings.side_menu_inputs && funnel.customer_portal_settings.side_menu_inputs.length) {
            let inputs = [];
            let labels = {}

            this.availableSideMenuInputs.forEach((input: {id: FunnelInputTypeEnum, text: string}) => {
              labels[input.id] = input.text;
            });

            funnel.customer_portal_settings.side_menu_inputs.forEach(value => {
              inputs.push({id: value, text: labels[value]});
            });

            this.form.patchValue({side_menu_inputs: inputs});
          }

          if (funnel.customer_portal_settings.holdoff_actions_config && this.funnel.customer_portal_settings.holdoff_actions_config.length) {
            const holdoff_actions_config = []
            funnel.customer_portal_settings.holdoff_actions_config.forEach(config => {
              config.holdoff_actions = this.availableActionHoldoffs.filter(
                item => config.holdoff_actions.indexOf(item['id']) > -1)
              holdoff_actions_config.push(config)
            })
            this.form.patchValue({holdoff_actions_config})
          }
          this.toggleSetting(this.customerPortalSettingType.General)
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }
}
