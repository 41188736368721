import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhoneSearchParam, PhoneSearchResult } from '../_models/index';
import { PaginationService } from "./pagination.service";

@Injectable()
export class PhoneService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'phone');
  }

  search(param: PhoneSearchParam) : Observable<PhoneSearchResult[]> {
    return this.http.post<any>('phonenumber/search/', param);
  }
}
