import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {AlertService, CampaignService, FunnelService, PusherService, UserService} from '../_services';
import {CrudPagedListComponent} from '../_directives';
import {Funnel, FunnelNode, FunnelType, FunnelTypeLabels, NotificationType, Pager, SessionType, User} from '../_models';
import {forkJoin, Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.css']
})
export class FunnelComponent extends CrudPagedListComponent implements OnInit {
  funnels$: Observable<Funnel[]> = this.data$;
  templates: Funnel[] = [];
  selectedFunnel: Funnel;
  defaultFunnelType: FunnelType = FunnelType.Visual;
  defaultSessionType: SessionType = SessionType.Support;
  baseRoute = 'path';
  user: User;

  private isPublishing: {} = {};

  treeControl = new NestedTreeControl<FunnelNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FunnelNode>();
  templatesTreeControl = new NestedTreeControl<FunnelNode>(node => node.children);
  templatesDataSource = new MatTreeNestedDataSource<FunnelNode>();

  hasChild = (_: number, node: FunnelNode) => {
    return !!node.children && node.children.length > 0;
  };

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected modalService: NgxSmartModalService,
    protected pusherService: PusherService
  ) {
    super(router, location, route, funnelService, alertService);
    this.objectName = 'Path';
    this.title = 'My Paths';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      resourcetype: [null]
    });

    super.ngOnInit();

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );

    this.funnelService.copiedFunnel.pipe(takeUntil(this.destroy$)).subscribe(
      (id: number) => {
        if (id) {
          this.queryData();
        }
      },
      (error) => {
        this.handleError(error);
        this.loading = false;
      }
    )

    this.campaignService.hideMenu();

    this.pusherService.channel.bind(NotificationType[NotificationType.PATH_PUBLISHING], (data: any) => {
      if (this.user && this.user.account && data.account === this.user.account.id) {
        data = data.payload;

        if (data && data.is_completed) {
          this.queryData();
          this.alertService.success(`${this.objectName} changes are published.`, true);
        }
      }
    });
  }

  protected onRouteParams(params: {}) {
    if ('resourcetype' in params) {
      this.defaultFunnelType = params['resourcetype'] || FunnelType.Visual;
    }

    if ('session_type' in params) {
      this.defaultSessionType = params['session_type'] || SessionType.Support;
    }
  }

  isProductFunnel(funnel) : boolean {
    return (funnel.resourcetype === FunnelType.Product);
  }

  isHybridFunnel(funnel) : boolean {
    return (funnel.resourcetype === FunnelType.Hybrid);
  }

  getType(funnel: Funnel) : string {
    return FunnelTypeLabels[funnel.resourcetype];
  }

  copyFunnel(funnel: Funnel) {
    this.funnelService.copy(funnel.id);
  }

  canPasteFunnel() {
    return this.funnelService.canPaste();
  }

  pasteFunnel() {
    this.loading = true;
    this.funnelService.paste()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (funnel: Funnel) => {
          this.alertService.success('Created new ' + this.objectName + ' - ' + funnel.name + '.');
          if (funnel) {
            this.onFunnelPasted(funnel);
          }
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.loading = false;
        }
      );
  }

  protected onFunnelPasted(funnel: Funnel) {
    if (funnel.is_template) {
      this.templates = [...this.templates, funnel];
      this.templatesDataSource.data = this.templates as FunnelNode[];
    }
    else {
      let funnels = [...this._data$.getValue(), funnel];
      this._data$.next(funnels);
      this.dataSource.data = funnels as FunnelNode[];
    }
  }

  clone(funnel: Funnel) {
    this.loading = true;
    this.funnelService.clone(funnel.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (funnel: Funnel) => {
          this.alertService.success('Created new ' + this.objectName + ' - ' + funnel.name + '.');
          this.onFunnelPasted(funnel);
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.loading = false;
        }
      );
  }

  saveAsTemplate(funnel: Funnel) {
    this.loading = true;
    this.funnelService.createTemplate(funnel.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (template: Funnel) => {
          this.alertService.success('Created new ' + this.objectName + ' template - ' + template.name + '.');
          this.onFunnelPasted(template);
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.loading = false;
        }
      );
  }

  setPublic(template: Funnel, event) {
    const isPublic: boolean = event.target.checked;
    this.loading = true;
    this.funnelService.patch(template.id, {resourcetype: template.resourcetype, is_public: isPublic})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (updatedTemplate: Funnel) => {
          let status = updatedTemplate.is_public ? 'public' : 'private';
          this.alertService.success(template.name + ' template is set to ' + status + '.');
          this.queryData();
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.loading = false;
        }
      );
  }

  preview(funnel: Funnel) {
    this.selectedFunnel = funnel;
    this.modalService.getModal('funnelPreviewDialog').open();
  }

  onPreview(previewUrl: string) {
    this.modalService.getModal('funnelPreviewDialog').close();
    window.open(previewUrl, '_blank');
  }

  publish(funnel: Funnel) {
    if (funnel.is_modified) {
      this.isPublishing[funnel.id] = true;
      this.funnelService.publish(funnel.id)
        .subscribe(
          (newFunnel: Funnel) => {
            this.isPublishing[funnel.id] = false;
          },
          error => {
            this.handleError(error);
            this.isPublishing[funnel.id] = false;
          }
        );
    }
  }

  deleteUnassigned(funnel: Funnel) {
    let observables = [];

    if (funnel.children && funnel.children.length) {
      funnel.children.forEach((child: Funnel) => {
        observables.push(this.funnelService.delete(child.id));
      })
    }

    if (observables.length) {
      if(window.confirm('Are you sure you want to delete all unassigned product paths?')) {
        this.loading = true;

        forkJoin(observables).pipe(takeUntil(this.destroy$))
          .subscribe(
            results => {
              this.alertService.success('Deleted all unassigned product paths.');
              this.queryData();
              this.loading = false;
            },
            error => {
              this.handleSubmitError(error);
              this.loading = false;
            });
      }
    }
  }

  isBusyPublishing(funnel: Funnel) {
    return (funnel.id in this.isPublishing) && this.isPublishing[funnel.id]
  }

  protected onPagerData(data: Pager) {
    const allFunnels = data.results;
    let funnels: Funnel[] = [];
    let templates: Funnel[] = [];

    allFunnels.forEach((funnel: Funnel) => {
      if (funnel.is_template) {
        templates.push(funnel);
      } else {
        funnels.push(funnel);
      }
    });

    this.templates = templates;
    this.dataSource.data = funnels as FunnelNode[];
    this.templatesDataSource.data = templates as FunnelNode[];
  }

  protected onPagerError(error) {
    this._data$.error(error);
    this.templates = [];
    this.dataSource.data = [] as FunnelNode[];
    this.templatesDataSource.data = [] as FunnelNode[];
  }

  protected onDelete(id) {
    this.queryData();
  }

  copyToClipboard(funnel: Funnel) {
    this.loading = true;
    this.funnelService.copyToClipboard(funnel.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (funnel: Funnel) => {
          this.alertService.success('Copied ' + this.objectName + ' to the clipboard.');
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.loading = false;
        }
      );
  }

  pasteFromClipboard() {
    this.loading = true;
    this.funnelService.pasteFromClipboard()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (funnel: Funnel) => {
          this.alertService.success('Created new ' + this.objectName + ' - ' + funnel.name + '.');
          if (funnel) {
            this.onFunnelPasted(funnel);
          }
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.loading = false;
        }
      );
  }

  protected queryData() {
    let params = Object.assign({}, this.getQueryFilter(), {page: this.page, page_size: this.pageSize});
    this.loading = true;
    this.paginationService.list(params, this.id)
      .subscribe(
        (data: Pager) => {
          this.onPagerData(data);
          this.setPagerData(data);
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.onPagerError(error);
          this.loading = false;
        });
  }
}
