import { OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActionHoldoffType, StepDurationUnits } from '../../../_models';
import { convertFromSeconds, convertToSeconds } from '../../step-data';

export abstract class HoldOffAbstractComponent implements OnInit {
  actions: FormGroup;
  crmDuration: number;
  crmUnit: StepDurationUnits = StepDurationUnits.Hours;
  actionHoldoffTypes = ActionHoldoffType;
  actionHoldoffTypeSelected = ActionHoldoffType.NoDelay;

  ngOnInit(): void {
    this.crmDuration = convertFromSeconds(this.actions.get('holdoff_delay').value, this.crmUnit);
    let type = this.crmDuration;
    if (this.crmDuration > 0) {
      type = ActionHoldoffType.Custom;
    }
    this.updateActionHoldoffType(type);
  }

  updateHoldOffDelay() {
    this.actions.patchValue({
      holdoff_delay: convertToSeconds(this.crmDuration, this.crmUnit)
    });
  }

  updateActionHoldoffType(type: ActionHoldoffType) {
    const hold_off = this.actions.get('holdoff_delay');
    this.actionHoldoffTypeSelected = type;
    switch (type) {
      case ActionHoldoffType.BrandSetting:
        hold_off.setValidators([]);
        this.crmDuration = null;
        break;

      case ActionHoldoffType.NoDelay:
        hold_off.setValidators([]);
        this.crmDuration = 0;
        break;

      case ActionHoldoffType.Custom:
        hold_off.setValidators([Validators.required, Validators.min(1)]);
        if (this.crmDuration <= 0) {
          this.crmDuration = 1;
        }
        break;
    }

    this.updateHoldOffDelay();
  }

  get isNoDelay() {
    return this.actionHoldoffTypeSelected === ActionHoldoffType.NoDelay;
  }
  get isDefaultSetting() {
    return this.actionHoldoffTypeSelected === ActionHoldoffType.BrandSetting;
  }
  get isCustomDelay() {
    return this.actionHoldoffTypeSelected === ActionHoldoffType.Custom;
  }
}
