import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  ProductService,
  CampaignProductService,
  CRMCampaignService,
  CRMService,
  ProductCategoryService, UserService, AccountService, CampaignService
} from '../_services';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CrudPagedListComponent} from '../_directives';
import {
  CampaignProduct,
  Category,
  CRM,
  CRMTypeId,
  CRMCampaign,
  ImageSize,
  Pager, User, BillingCycleTypeMap, DiscountPriceTypeMap, ExchangeableProduct, Campaign
} from '../_models';
import {forkJoin, Observable} from "rxjs";
import {config} from '../../config/config';
import {takeUntil} from "rxjs/operators";
import {compareExchangeableProducts, generateFilterQuery, getCampaignProductImageUrl} from '../_helpers';
import {NgxSmartModalService} from 'ngx-smart-modal';
import get from 'lodash-es/get';
import {campaignProductFieldData} from './campaign-product-field-data';
import {CR} from "@angular/compiler/src/i18n/serializers/xml_helper";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-product.component.html',
  styleUrls: ['./campaign-product.component.css']
})
export class CampaignProductComponent extends CrudPagedListComponent implements OnInit {
  campaignProducts$: Observable<CampaignProduct[]> = this.data$;
  categories: any[] = [];
  crmCampaigns: any[] = [];
  crms: CRM[];
  crmNames: {} = {};
  allCRMCampaigns: {} = {};
  selectProductsFlag = false;
  editEnabled = true;
  loading = false;
  multiple = false;
  bulkIds = [];
  bulkProductIds = [];
  selectedCampaignProduct: CampaignProduct | null = null;
  selectedCRMCampaign: CRMCampaign | null = null;
  idPrefix = '';
  allSelected = false;
  checkedFields: string[] = [];
  @Input('crmId') defaultCRMId: string | number;
  @Input('campaigns') defaultCRMCampaigns: CRMCampaign[] = [];
  @Input('useAccountIdInFilter') useAccountIdInFilter: boolean = false;
  @Input('accountId') accountId: string | number | Array<{ id: string | number, text: string }> = null;
  public allowExportCampaignProducts: boolean = true;

  protected selectedProductsMap: {} = {};
  protected selectedBaseProductMap: {} = {};
  protected crmCampaignsMap: {} = {};
  pageSize = 50;
  public accounts: Account[] = [];
  public user: User = null;

  orderingFieldsMap = {
    'price': '_price',
    'name': 'name',
    'base_product_id': 'product__product_id',
    'product_name': 'product__name',
    'crm_campaign_id': 'default_crm_campaign__crm_campaign_id',
    'crm_campaign_name': 'default_crm_campaign__name',
    'crm': 'crm__name',
    'image': 'product__images__isnull',
    'product_sku': 'product__sku',
  };

  protected fieldData = campaignProductFieldData;
  public filtersDynamicOptions = {
    crm: [],
    campaign_id: [],
    product_categories: [],
  };

  public fields = {
    dimensions: [
      [
        {model: "image", label: "Image", default: true, disabled: false},
        {model: "name", label: "Product Name", default: true, disabled: false, sortable: true},
        {model: "crm_campaign_name", label: "Campaign", default: true, disabled: false, sortable: true},
        {model: "crm_campaign_id", label: "CRM Campaign ID", default: true, disabled: false, sortable: true},
        {model: "campaign_product_id", label: "Product ID", default: true, disabled: false, sortable: true},
        {model: "base_product_id", label: "Base Product ID", default: true, disabled: false, sortable: true},
        {model: "product_name", label: "Base Product Name", default: false, disabled: false, sortable: true},
        {model: "product_sku", label: "SKU", default: true, disabled: false, sortable: true},
        {model: "crm", label: "CRM", default: false, disabled: false},
        {model: "offer_id", label: "Offer ID", default: false, disabled: false, sortable: true},
        {model: "billing_model_id", label: "Billing Model ID", default: false, disabled: false, sortable: true},
        {model: "price", label: "Price", default: false, disabled: false, sortable: true},
        {model: "min_price", label: "Minimum Price", default: false, disabled: false, sortable: true},
        {model: "billing_cycle_type", label: "Billing Cycle Type", default: false, disabled: false},
        {model: "index_prices", label: "Index Prices", default: false, disabled: false},
        {model: "discount_type", label: "Discount Type", default: false, disabled: false},
        {model: "exchangeable_status", label: "Exchangeable Status", default: false, disabled: false},
        {model: "fulfillment_quantity", label: "Fulfillment Quantity", default: false, disabled: false, sortable: true},
      ]
    ],
    filters: [
      [
        {model: "crm_campaigns", label: "CRM Campaign", default: false, disabled: false},
        {model: "name__icontains", label: "Name", default: false, disabled: false},
        {model: "product__name__icontains", label: "Base Product Name", default: false, disabled: false},
        {model: "product__sku__icontains", label: "SKU", default: false, disabled: false},
        {model: "campaign_product_id", label: "Campaign Product ID", default: false, disabled: false},
        {model: "product__product_id", label: "Base Product ID", default: false, disabled: false},
        {model: "product__categories", label: "Category", default: false, disabled: false},
        {model: "billing_cycle_type", label: "Billing Cycle Type", default: false, disabled: false},
        {model: "discount_type", label: "Discount Type", default: false, disabled: false},
        {model: "crm_campaigns__campaign", label: "Brand", default: false, disabled: false},
        {model: "is_deleted", label: "Exclude deleted products", default: true, disabled: false},
      ]
    ],
    filtersData: [
      [
        {model: "name__icontains", filter: "name__icontains", type: "input", label: "Name", default: ""},
        {model: "product__name__icontains", filter: "product__name__icontains", type: "input", label: "Base Product Name", default: ""},
        {model: "product__sku__icontains", filter: "product__sku__icontains", type: "input", label: "SKU", default: ""},
        {model: "product__product_id", filter: "product__product_id", type: "input", label: "Base Product ID", default: ""},
        {model: "crm_campaigns", filter: "crm_campaigns", type: "multi-select", label: "CRM campaign", default: "", options: []},
        {model: "campaign_product_id", filter: "campaign_product_id", type: "input", label: "Campaign Product ID", default: ""},
        {model: "product__categories", filter: "product__categories", type: "multi-select", label: "Category", default: "", options: []},
        {model: "crm_campaigns__campaign", filter: "crm_campaigns__campaign", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "is_deleted", filter: "is_deleted", type: "hidden-input", label: "Exclude deleted products", default: false, options: []},
        {model: "billing_cycle_type", filter: "billing_cycle_type", type: "select", label: "Billing Cycle Type", default: "", options:
          [{ label: 'All', value: "" }].concat(
            Object.keys(BillingCycleTypeMap).map((type) => {
              return { label: BillingCycleTypeMap[type], value: type };
            })
          )},
        {model: "discount_type", filter: "discount_type", type: "select", label: "Discount Type", default: "", options:
          [{ label: 'All', value: "" }].concat(
            Object.keys(DiscountPriceTypeMap).map((type) => {
              return { label: DiscountPriceTypeMap[type], value: type };
            })
          )},
      ],
    ]
  };
  private exchangeableProductsMap = {};
  public selectedExchangeableProducts: ExchangeableProduct[] = []

  filtersApplied = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected campaignProductService: CampaignProductService,
    protected alertService: AlertService,
    protected crmService: CRMService,
    protected crmCampaignService: CRMCampaignService,
    protected categoryService: ProductCategoryService,
    protected modalService: NgxSmartModalService,
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignService: CampaignService
  ) {
    super(router, location, route, campaignProductService, alertService);
    this.objectName = 'product';
    this.title = 'Products';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      account: [null, null],
      crm: [this.defaultCRMId ? this.defaultCRMId : null]
    });

    for (let key of Object.keys(this.fields)) {
      let currentFieldsList = this.fields[key];
      let formGroupName = key;
      let formGroupFields = {};
      for (let group of currentFieldsList) {
        for (let field of group) {
          formGroupFields[field.model] = new FormControl({value: field.default, disabled: field.disabled});
        }
      }
      this.form.addControl(formGroupName, new FormGroup(formGroupFields));
    }

    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user;
        if (user.is_staff) {
          this.useAccountIdInFilter = true;
          this.accountId = user.account.id;
          this.form.get('account').setValue([{id: this.accountId, name: user.account.name}]);
        }
        super.ngOnInit();
        this.fetchCRMRelatedData();
        this.fetchCampaignProducts()
      });

    this.accountService.list({})
      .subscribe((accounts: Pager) => {
        this.accounts = accounts.results;
      }, err => {
        this.handleError(err)
      })
  }

  get editMode() : boolean {
    return !!this.bulkIds.length || !!this.selectedCampaignProduct;
  }

  setupFilterEvents() {}

  anyFieldChecked() {
    let fieldsThatMustBeChecked = ['dimensions'];
    let formData = this.form.value;
    let anyFieldChecked = false;

    Object.keys(formData).forEach(mainKey => {
      if (fieldsThatMustBeChecked.indexOf(mainKey) > -1) {
        let fields = formData[mainKey];
        Object.keys(fields).forEach(key => {
          if (fields[key]) {
            anyFieldChecked = true;
          }
        })
      }
    });

    return anyFieldChecked;
  }

  getCampaignProducts(event = null) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.submitted = true;
    if (!this.form.valid) {
      this.alertService.error("Please see the errors and try again");
      return;
    }
    if (!this.anyFieldChecked()) {
      this.loading = false;
      this.alertService.error("Please select any of the fields to fetch campaign products")
      return;
    }

    let dimensionFields = this.form.value.dimensions;
    this.checkedFields = [];
    let scopedFields = [];

    Object.keys(dimensionFields).forEach(key => {
      if (dimensionFields[key]) {
        scopedFields.push(key);
      }
    });

    this.checkedFields = scopedFields;
  }

  fetchCRMRelatedData() {
    let categoryQueryParams = {page: 1, page_size: config.maxPageSize};
    let crmQueryParams = {page: 1, page_size: config.maxPageSize, 'type!': CRMTypeId.Test};
    let crmCampaignQueryParams = {page: 1, page_size: config.maxPageSize, 'crm__type!': CRMTypeId.Test};
    let campaignQueryParams = {page: 1, page_size: config.maxPageSize, 'crm__type!': CRMTypeId.Test};

    if (this.useAccountIdInFilter) {
      let accountId = this.accountId;
      if (Array.isArray(accountId)) {
        let accounts = [];
        accountId.forEach(item => {
          accounts.push(item.id);
        });
        accountId = accounts;
      }
      categoryQueryParams['account_id'] = accountId;
      crmQueryParams['account_id'] = accountId;
      crmCampaignQueryParams['account_id'] = accountId;
      campaignQueryParams['account_id'] = accountId;
    }

    this.categoryService.list(categoryQueryParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (pager: Pager) => {
          this.filtersDynamicOptions['product__categories'] = [];
          pager.results.forEach((category: Category) => {
            this.filtersDynamicOptions['product__categories'].push({id: category.id, text: category.name});
          });
        },
        error => {
          this.handleError(error);
        }
      );

    this.campaignService.list(campaignQueryParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (pager: Pager) => {
          this.filtersDynamicOptions['crm_campaigns__campaign'] = [];
          pager.results.forEach((campaign: Campaign) => {
            this.filtersDynamicOptions['crm_campaigns__campaign'].push({id: campaign.id, text: campaign.display_name});
          });
        },
        error => {
          this.handleError(error);
        }
      );

    forkJoin([
      this.crmService.list(crmQueryParams),
      this.crmCampaignService.list(crmCampaignQueryParams)
    ]).subscribe(
      (data: [Pager, Pager]) => {
        if (data) {
          this.crms = data[0].results;
          this.crms.forEach((crm: CRM) => {
            this.crmNames[crm.id.toString()] = crm.name;
            this.crmCampaignsMap[crm.id.toString()] = [];
          });

          this.filtersDynamicOptions['crm_campaigns'] = [];

          // fill in the list of crm campaigns for each crm
          data[1].results.forEach((campaign: CRMCampaign) => {
            const campaignMap = {
              id: campaign.id,
              text: `${campaign.name} (${campaign.crm_campaign_id})`
            };
            if (!this.crmCampaignsMap[campaign.crm.toString()]) {
              this.crmCampaignsMap[campaign.crm.toString()] = [];
            }
            this.crmCampaignsMap[campaign.crm.toString()].push(campaignMap);
            this.allCRMCampaigns[campaign.id] = campaign;

            if (!this.filtersDynamicOptions['crm_campaigns']) {
              this.filtersDynamicOptions['crm_campaigns'] = [];
            }
            this.filtersDynamicOptions['crm_campaigns'].push(campaignMap);
          });

          if (this.crms.length == 1) {
            // we only have 1 crm, so select it as the default
            this.defaultCRMId = this.crms[0].id.toString();
            this.form.get('crm').setValue(this.crms[0].id);
          }

          this.filtersDynamicOptions['crm'] = data[0].results;
          this.setDefaultCampaignList();
          this.form.patchValue({
            crm: this.defaultCRMId,
            crm_campaigns: this.getDefaultCampaignValues()
          });
        }
        this.fetchCampaignProducts();
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected setDefaultCampaignList() {
    if (!!this.defaultCRMId) {
      if (this.defaultCRMId.toString() in this.crmCampaignsMap) {
        this.crmCampaigns = this.crmCampaignsMap[this.defaultCRMId.toString()];
      }
    }
  }

  protected getDefaultCampaignValues() {
    let campaignVals = [];
    let campaignIdMap = {};

    if (this.defaultCRMCampaigns && this.defaultCRMCampaigns.length) {
      this.defaultCRMCampaigns.forEach((campaign: CRMCampaign) => campaignIdMap[campaign.id] = true);

      this.crmCampaigns.forEach((campaign: CRMCampaign) => {
        if (campaign.id in campaignIdMap) {
          campaignVals.push(campaign);
        }
      });
    }

    return campaignVals;
  }

  postFetchCampaignProductIds() {

  }

  getCampaignProductsListIds(filters = {}) {
    this.campaignProductService.getAllIds({
        ...this.filtersApplied,
        ...filters
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Array<{id: number | string, product_id: number | string}>) => {
          data.forEach(row => {
            if (!(row.id in this.selectedProductsMap)) {
              this.selectedProductsMap[row.id] = null; // we don't care about the value, just the key
            }
            if (!(row.product_id in this.selectedBaseProductMap)) {
              this.selectedBaseProductMap[row.product_id] = null; // we don't care about the value, just the key
            }
          });

          if (!filters) {
            this.allSelected = true;
          }
          this.postFetchCampaignProductIds()
        },
        error => {
          this.handleError(error);
        });
  }

  protected onRouteParams(params: {}) {
    this.title = 'Products';
    this.selectProductsFlag = false;
    this.selectedProductsMap = {};
    this.selectedBaseProductMap = {};

    if ('crm' in params) {
      this.defaultCRMId = params['crm'];
      this.form.get('crm').setValue(this.defaultCRMId);
      this.setDefaultCampaignList();
    }
  }

  protected onFilterFieldChange(field: string, value) {
    if (field === 'crm') {
      if (!this.selectProductsFlag) {
        this.setTitleFromCRM(value)
      }

      if (value) {
        this.crmCampaigns = this.crmCampaignsMap[value.toString()];
      } else {
        this.crmCampaigns = [];
      }

      this.form.patchValue({crm_campaigns: null});
    }
  }

  protected setTitleFromCRM(crm) {
    if (crm) {
      crm = crm.toString();
    }

    if (crm && crm in this.crmNames) {
      this.title = this.crmNames[crm] + ' Products';
    } else {
      this.title = 'All Products';
    }
  }

  isEnabled(product: CampaignProduct) {
    return product.id in this.selectedProductsMap;
  }

  enable(product: CampaignProduct, event) {
    const enabled: boolean = event.target.checked;

    if (enabled) {
      this.selectedProductsMap[product.id] = null; // we don't care about the value, just the key
      this.selectedBaseProductMap[product.product.id] = null;
      this.exchangeableProductsMap[product.id] = product.exchangeable_products
    } else if (product.id in this.selectedProductsMap) {
      delete this.selectedProductsMap[product.id];
      delete this.selectedBaseProductMap[product.product.id];
      delete this.exchangeableProductsMap[product.id]
    }
  }

  selectAll(event) {
    const enabled: boolean = event.target.checked;

    if (enabled) {
      // Select all rows
      this.getCampaignProductsListIds();
    } else {
      // Unselect all
      this.allSelected = false;
      this.selectedProductsMap = {};
      this.selectedBaseProductMap = {};
    }
  }

  canBulkEdit() {
    return this.editEnabled && (this.selectedProductIds.length > 1);
  }

  bulkEdit() {
    this.selectedCampaignProduct = null;
    this.selectedExchangeableProducts = [];
    let exchangeablesToCompareWith = null
    let exchangeablesMatch = true

    for (const productId of this.selectedProductIds) {
      let currentExchangeableProducts = this.exchangeableProductsMap[productId]

      if (!exchangeablesToCompareWith) {
        exchangeablesToCompareWith = currentExchangeableProducts
      } else {
        exchangeablesMatch = compareExchangeableProducts(exchangeablesToCompareWith, currentExchangeableProducts)
        if (!exchangeablesMatch) {break}
      }
    }

    if (exchangeablesMatch) {
      this.selectedExchangeableProducts = exchangeablesToCompareWith
    }

    this.bulkIds = this.selectedProductIds;
    this.bulkProductIds = this.selectedBaseProductIds;
    this.modalService.getModal('editCampaignProductDialog').open();
  }

  getProductImageThumbnailUrl(campaignProduct) {
    return getCampaignProductImageUrl(campaignProduct, ImageSize.original);
  }

  getProductImageUrl(campaignProduct) {
    return getCampaignProductImageUrl(campaignProduct, ImageSize.original);
  }

  editCampaignProduct(campaignProduct: CampaignProduct) {
    this.selectedCampaignProduct = campaignProduct;
    this.modalService.getModal('editCampaignProductDialog').open();
  }

  onSaveCampaignProduct(campaignProduct: CampaignProduct) {
    this.selectedCampaignProduct = null;
    this.bulkIds = [];
    this.bulkProductIds = [];
    this.selectedProductsMap = {}
    this.selectedBaseProductMap = {}
    this.exchangeableProductsMap = {}
    this.modalService.getModal('editCampaignProductDialog').close();
    this.queryData();
  }

  onCancelEditCampaignProduct() {
    this.selectedCampaignProduct = null;
    this.bulkIds = [];
    this.bulkProductIds = [];
  }

  createTestOrder(campaignProduct: CampaignProduct) {
    this.selectedCampaignProduct = campaignProduct;
    this.modalService.getModal('productTestOrderDialog').open();
  }

  editCRMCampaign(campaign: CRMCampaign) {
    this.selectedCRMCampaign = campaign;
    this.openEditCRMCampaignDialog();
  }

  protected openEditCRMCampaignDialog() {
    this.modalService.getModal('editCRMCampaignDialog').open();
  }

  onSaveCRMCampaign(campaign: CRMCampaign) {
    this.modalService.getModal('editCRMCampaignDialog').close();
    this.allCRMCampaigns[campaign.id] = campaign;
  }

  onCloseCRMCampaignDialog() {
    this.selectedCRMCampaign = null;
  }

  get selectedProductIds() {
    return Object.keys(this.selectedProductsMap);
  }

  get selectedBaseProductIds() {
    return Object.keys(this.selectedBaseProductMap);
  }

  cancel() {
    !this.loading && this.goBack();
  }

  protected getQueryFilter(): {} {
    let filters = super.getQueryFilter();
    if (this.useAccountIdInFilter) {
      filters['account_id'] = this.accountId
    }
    return filters
  }

  onAccountFilterChange(event) {
    this.accountId = event.target.value;
    this.fetchCRMRelatedData();
    this.queryData();
  }

  getFieldLabel(field: string) : string {
    if (field.startsWith('exclude_')) {
      return ''
    }
    return this.fieldData[field].label;
  }

  getFieldValue(campaignProduct: CampaignProduct, field: string) {
    if (field.startsWith('exclude_')) {
      return ''
    }
    let value = get(campaignProduct, field);
    if ('getter' in this.fieldData[field]) {
      value = this.fieldData[field].getter(campaignProduct, value, field == 'crm' ? this.crmNames : this.allCRMCampaigns);
    }

    return value;
  }

  toggleBooleanField(event, fieldname: string) {
    event.preventDefault();
    event.stopPropagation();
    super.toggleBooleanField(event, fieldname);
  }

  showColumnWithoutLink(field: string) {
    return !([
      'name', 'image', 'price', 'min_price',
      'crm_campaign_name', 'crm_campaign_id',
      'exchangeable_status'
    ].includes(field));
  }

  fetchCampaignProducts(event = null) {
    // Unselect all
    this.allSelected = false;
    this.getCampaignProducts(event);
    this.queryData();
  }

  public queryData() {
    this.loading = true;
    this.filtersApplied = generateFilterQuery(this.form.value);
    let data = Object.assign(this.filtersApplied, {page: this.page, page_size: this.pageSize});
    if (this.ordering.length > 0) {
      data['ordering'] = this.ordering;
    }
    this.campaignProductService.list(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.pageCount = data.num_pages;
          this.page = data.page;
          this._data$.next(data.results);
          if (this.page === 1) {
            this.totalResultCount = data.count;
          }
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this._data$.error(error);
          this.loading = false;
        });
  }

  openExportDialog() {
    this.modalService.getModal('exportCampaignProductsDialog').open();
  }

  export() {
    this.modalService.getModal('exportCampaignProductsDialog').close();
    this.loading = true;
    const filters = Object.assign(generateFilterQuery(this.form.value));
    this.campaignProductService.export(filters)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: any) => {
          this.loading = false;
          if (data.success) {
            this.alertService.success(data.msg);
          }
        },
        error => {
          this.handleError(error);
          this.loading = false;
        });
  }
}
