import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  StepEmulatorDevices,
  StepLayout,
  StepButtons,
  StepMedia,
  StepMediaCategory,
  OfferTypes,
  OfferTypeLabels,
  Breadcrumb,
  StepEmulationTypes,
  OfferIntents,
  OrderType,
  CustomStepCategoryEnum,
  FunnelInputTypeEnum,
  StepCategoryLabels,
  ActionType,
  DiscountTypeEnum,
  ExecutionTypeEnum,
  StepStaticDataImageType,
  StepFixedMediaCategory,
  StepButtonControlTypes,
  Campaign,
  defaultThemeColor,
  StepButtonShapes,
  transparent,
  StepMediaSubCategory,
  StepTextual,
  StepButtonDetails,
  StepTextualBanner,
  CampaignProduct,
  Image,
  Funnel,
  FunnelStep,
  Action,
  Pager,
  Style,
  StepTemplateFolder,
  StepResponseMedia,
  QuantityTypeEnum
} from '../../_models';
import {
  AlertService,
  CampaignService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  StepEmulatorService,
  StyleService,
  StepTemplateService,
  SideNavService,
  TemplateFolderService,
  ImageService
} from '../../_services';
import { Subscription } from 'rxjs';
import {
  addDataAttributes,
  getEmbeddedVideoUrl,
  normalizeObject,
  OfferData,
  stepLayout,
  StepCategoryOfferIntentsMapping,
  SurveyResourceMapping
} from '../step-data';
import { config } from '../../../config/config';
import { getCampaignProductImageUrl } from '../../_helpers';
import { Form } from '../../_forms';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { getTruncatedProductName } from '../step-data';
import { StepFormComponent } from './step-form/step-form.component';
import { StepEmulatorComponent } from './step-emulator/step-emulator.component';
import { CrudSaveComponent } from '../../_directives';

@Component({
  selector: 'app-step-builder',
  templateUrl: './step-builder.component.html',
  styleUrls: ['./step-builder.component.scss']
})
export class StepBuilderComponent extends CrudSaveComponent implements OnInit, OnDestroy, AfterViewInit {
  activeDevice = StepEmulatorDevices.Mobile;
  stepLayout: StepLayout[] = [];
  stepTextual: StepTextual;
  stepMedia: StepMedia;
  stepResponseMedia: StepResponseMedia;
  stepButtons: StepButtons;
  private subscriptions: Subscription[] = [];
  stepTitle: string = '';
  stepType: CustomStepCategoryEnum;
  stepId: string = '';
  funnelId: string | number = '';
  folderId: string = '';
  templateId: string = '';
  funnel: Funnel;
  offerType: OfferTypes;
  offerName: string;
  category: string = '';
  isStepFormActive: boolean = true;
  isDropdownActive: boolean = true;
  showResponseButton: boolean = true;
  isResponseScreen: boolean = false;
  isStepPreview: boolean = true;
  isStepSaving: boolean = false;
  isStepDraftSaving: boolean = false;
  isPreviewLoading: boolean = false;
  isNew: boolean = true;
  breadcrumbs: Breadcrumb[] = [];
  campaigns: Campaign[] = [];
  selectedBrandId: string | number = null;
  selectedProduct: CampaignProduct = {} as CampaignProduct;
  productPlaceholderUrl: string = '';
  campaignsLoading: boolean = true;
  themes: Style[] = [];
  theme: string = defaultThemeColor;
  useExternalButtons = false;
  stepEmulationTypes = StepEmulationTypes;
  isPurchaseType = false;
  enableFormErrors = false;
  folderDetails: StepTemplateFolder;
  isSurveyStep = false;
  isCustomerPortalStep = false;
  @ViewChild(StepEmulatorComponent, { static: false }) stepEmulator: StepEmulatorComponent;
  @ViewChild(StepFormComponent, { static: false }) stepFormComponent: StepFormComponent;

  constructor(
    protected route: ActivatedRoute,
    protected location: Location,
    private stepService: StepTemplateService,
    private stepEmulatorService: StepEmulatorService,
    private funnelService: FunnelService,
    private funnelStepService: FunnelStepService,
    private sideNav: SideNavService,
    protected alertService: AlertService,
    protected router: Router,
    private loader: LoaderService,
    private campaignService: CampaignService,
    protected styleService: StyleService,
    protected templateFolderService: TemplateFolderService,
    protected formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    protected imageService: ImageService,
    private sanitizer: DomSanitizer
  ) {
    super(router, location, route, stepService, alertService);
    this.sideNav.setShowSideNavToggle(false);
    this.sideNav.setSideNavExpanded(false);
    this.route.params.subscribe((params) => {
      this.stepType = parseInt(params['step_type']);
      this.offerType = parseInt(params['offer_type']);
      this.offerName = OfferTypeLabels[this.offerType];
      this.stepId = params['step_id'];
      this.funnelId = params['offer_id'];
      this.folderId = params['folder_id'];
      this.templateId = params['template_id'];
      this.isNew = !this.stepId;
      this.stepLayout = stepLayout[this.stepType] || [];
      this.stepTitle = this.getTitleForStepType(this.stepType, this.isNew);
      this.isPurchaseType = this.purchaseType;
    });
  }

  ngOnInit() {
    this.loader.show('white', 'Gearing up the builder', 998);
    this.initializeForm();
    this.getStyles();
    this.getCampaigns();
    this.generateBreadcrumbs();
    super.ngOnInit();

    const textualSubscription = this.stepEmulatorService.stepTextual$.subscribe((data) => {
      this.stepTextual = data;
    });
    this.addSubscription(textualSubscription);

    const stepMediaSubscription = this.stepEmulatorService.stepMedia$.subscribe((data) => {
      this.stepMedia = data;
    });
    this.addSubscription(stepMediaSubscription);

    const responseMediaSubscription = this.stepEmulatorService.stepResponseMedia$.subscribe((data) => {
      this.stepResponseMedia = data;
    });
    this.addSubscription(responseMediaSubscription);

    const buttonsSubscription = this.stepEmulatorService.stepButtons$.subscribe((data) => {
      this.stepButtons = data;
    });
    this.addSubscription(buttonsSubscription);

    this.isSurveyStep = this.offerType === OfferTypes.Survey;
    this.isCustomerPortalStep = this.offerType === OfferTypes.CustomerPortal;

    if (this.folderId) {
      this.fetchFolderDetails();
    }

    if (this.templateId) {
      this.fetchTemplate();
    }

    if (this.stepId && this.funnelId) {
      this.isNew = false;
      this.funnelStepService.setFunnelId(this.funnelId);
      this.fetchFunnel();
      this.fetchOfferStep();
    } else if (this.funnelId) {
      this.fetchFunnel();
      this.funnelStepService.setFunnelId(this.funnelId);
    } else if (this.stepId) {
      this.isNew = false;
      this.fetchStep();
    }
  }

  ngAfterViewInit() {
    if (this.isNew && !this.templateId) {
      setTimeout(() => {
        this.loader.hide();
        this.stepEmulatorService.hideAllElements();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 3000);
    } else {
      this.loader.loader$.subscribe((state) => {
        if (!state) {
          this.stepEmulatorService.hideAllElements();
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    }
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      id: null,
      subtitle: null,
      style: null,
      classes: [[]],
      is_popup: false,
      show_step_link: false,
      contact_type: null,
      enhanced_content: null,
      enhanced_mode: false,
      fail_if_any_input_invalid: false,
      invalid_message: null,
      hide_status: false,
      action_holdoffs: null,
      allow_undo: this.offerType !== OfferTypes.Downsell && this.offerType !== OfferTypes.Upsell,
      mobile_icon_button_type: null,
      use_bootstrap_spacing: true,
      enhanced_content_components: null,
      icon: null,
      help: null,
      enabled: null,
      enter_product_funnel: false,
      enter_troubleshooter: false,
      error_lifeline: null,
      exit_lifeline: null,
      faq_topic: null,
      is_first_step: false,
      is_template: false,
      is_global: false,
      item_statuses: null,
      lifeline: null,
      lifeline_enabled: true,
      slug: null,
      sms_prompt: null,
      voice_file: null,
      voice_prompt: null,
      num_digits: null,
      offer_intent: OfferIntents.Legacy,
      phone_loop_count: 1,
      previous_inputs: [],
      group: null,
      forward_to_call_center: false,
      hide_if_invalid: true,
      campaign_products: [[]],
      product_funnels: [[]],
      is_alt_child_exit: false,
      matched_step_key: null,
      autopick_next_step: null,
      requires_active_item: true,
      requires_item: true,
      requires_customer: true,
      type: FunnelInputTypeEnum.Choice,
      category: [this.stepType],
      banner: [null],
      label: [null, Validators.required],
      name: [null, Validators.required],
      content: [null, Validators.required],
      button_styles: null
    });

    let inputs, response_data;

    if (this.folderId) {
      this.form.patchValue({
        offer_intent: OfferIntents.Other
      });
    } else {
      this.form.patchValue({
        offer_intent: StepCategoryOfferIntentsMapping[this.stepType]
      });
    }

    switch (this.stepType) {
      case CustomStepCategoryEnum.Survey:
      case CustomStepCategoryEnum.ReturnSurvey:
      case CustomStepCategoryEnum.CancelSubSurvey:
      case CustomStepCategoryEnum.CancelOrderSurvey:
      case CustomStepCategoryEnum.CancelTrialSurvey:
        this.useExternalButtons = true;
        this.form.patchValue({
          type: FunnelInputTypeEnum.Radio
        });

        this.form.setControl('inputs', this.formBuilder.array([], Validators.required));

        this.form.setControl(
          'button_styles',
          this.formBuilder.group({
            confirm: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null]
            }),
            back: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null]
            })
          })
        );
        break;

      case CustomStepCategoryEnum.AdjustShippingFrequency:
      case CustomStepCategoryEnum.PauseSurvey:
        this.useExternalButtons = true;
        this.form.patchValue({
          type: FunnelInputTypeEnum.Radio
        });
        response_data = this.getResponseData();

        this.form.setControl('inputs', this.formBuilder.array([], Validators.required));
        this.form.setControl('response_data', response_data);
        this.form.setControl(
          'button_styles',
          this.formBuilder.group({
            confirm: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null]
            }),
            back: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null]
            })
          })
        );
        break;
      default:
        inputs = this.getDownsellInputs();
        response_data = this.getResponseData();

        this.form.setControl('inputs', inputs);
        this.form.setControl('response_data', response_data);
        break;
    }

    if (this.folderId) {
      this.form.addControl('folder', this.formBuilder.control(+this.folderId));
      this.form.addControl('order_type', this.formBuilder.control(OrderType.OneTime));
    } else {
      this.form.addControl('funnel', this.formBuilder.control(null));
    }
  }

  getSurveyInputs(): FormGroup {
    const input = this.formBuilder.group({
      id: null,
      label: [null, Validators.required],
      help: null,
      next_step: null,
      type: FunnelInputTypeEnum.Radio,
      values: [[]],
      actions: this.offerType === OfferTypes.Survey ? this.getSurveyActions() : this.getDownsellSurveyActions(),
      icon: null,
      hide_if_invalid: true,
      product_funnels: [[]],
      classes: [[]],
      is_alt_child_exit: false,
      matched_step_key: null,
      autopick_next_step: null,
      requires_active_item: true,
      requires_item: true,
      requires_customer: true,
      has_response_step: true
    });

    if (this.offerType === OfferTypes.Survey) {
      input.addControl('reason_category', new FormControl(null, Validators.required));
    }

    return input;
  }

  getSurveyActions(): FormArray {
    const resourcetype = StepCategoryOfferIntentsMapping[this.stepType],
      cancel_subscription =
        this.stepType === CustomStepCategoryEnum.CancelSubSurvey ||
        this.stepType === CustomStepCategoryEnum.CancelTrialSurvey;

    return this.formBuilder.array([
      this.formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.ValidationOnly,
        resourcetype,
        cancel_subscription,
        reason: [null, Validators.required]
      })
    ]);
  }

  getDownsellSurveyActions(): FormArray {
    return this.formBuilder.array([
      this.formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: ActionType.BillingCycle,
        billing_interval_days: null,
        check_last_discount: true,
        pause: false,
        final_billing_cycle_offset: 0,
        bill_now: false,
        next_bill_date_delay: null,
        max_bill_date_delay: null
      }),
      this.formBuilder.group({
        id: null,
        resourcetype: ActionType.CancelDelayed
      })
    ]);
  }

  getDownsellInputs(): FormArray {
    return this.formBuilder.array([
      this.formBuilder.group({
        id: null,
        label: [null, Validators.required],
        help: null,
        next_step: null,
        step: null,
        type: FunnelInputTypeEnum.Choice,
        values: [[]],
        actions: this.getDownsellInputActions(),
        icon: null,
        hide_if_invalid: true,
        product_funnels: [[]],
        classes: [[]],
        is_alt_child_exit: true,
        matched_step_key: null,
        autopick_next_step: null,
        has_response_step: true,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
        builder_id: null,
        enter_product_funnel: false,
        enter_troubleshooter: false,
        item_statuses: null,
        button_styles: this.formBuilder.group({
          style: null,
          control_type: null,
          style_type: null
        })
      }),
      this.formBuilder.group({
        id: null,
        label: [null, Validators.required],
        help: null,
        next_step: null,
        step: null,
        type: FunnelInputTypeEnum.Choice,
        values: this.formBuilder.array([]),
        actions: this.formBuilder.array([]),
        icon: null,
        hide_if_invalid: true,
        product_funnels: [[]],
        classes: [[]],
        is_alt_child_exit: true,
        matched_step_key: null,
        autopick_next_step: null,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
        has_response_step: false,
        builder_id: null,
        enter_product_funnel: false,
        enter_troubleshooter: false,
        item_statuses: null,
        button_styles: this.formBuilder.group({
          style: null,
          control_type: null,
          style_type: null
        })
      })
    ]);
  }

  getDownsellInputActions(): FormArray {
    let discount_index = 0,
      upsale_index = 0,
      exchangeable_index = null,
      action1,
      action2,
      resourcetype,
      final_billing_cycle_offset = 0,
      discount = 20, // % or $
      next_bill_date_delay = 32, // days
      holdoff_delay = null;

    action2 = this.formBuilder.group({
      id: null,
      resourcetype: ActionType.CancelDelayed
    });

    switch (this.stepType) {
      case CustomStepCategoryEnum.FixedAmountDownsell:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.Discount,
          discount: [discount, [Validators.required, Validators.min(1)]],
          discount_type: [DiscountTypeEnum.Fixed, Validators.required]
        });

        break;

      case CustomStepCategoryEnum.PercentageDownsell:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.Discount,
          discount: [discount, [Validators.required, Validators.min(1), Validators.max(100)]],
          discount_type: [DiscountTypeEnum.Percent, Validators.required]
        });

        break;

      case CustomStepCategoryEnum.PauseSub:
      case CustomStepCategoryEnum.ExtendTrial:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.BillingCycle,
          billing_interval_days: null,
          check_last_discount: true,
          pause: true,
          final_billing_cycle_offset,
          bill_now: false,
          next_bill_date_delay: [next_bill_date_delay, Validators.min(0)],
          max_bill_date_delay: null,
          holdoff_delay
        });
        break;

      case CustomStepCategoryEnum.OfferDetails:
      case CustomStepCategoryEnum.LossAversion:
      case CustomStepCategoryEnum.Testimonial:
        action1 = this.formBuilder.group({
          id: null,
          resourcetype: ActionType.CancelDelayed,
          execution_type: ExecutionTypeEnum.Immediate
        });

        break;

      case CustomStepCategoryEnum.RefundWithDiscount:
      case CustomStepCategoryEnum.DiscountFutureOrder:
      case CustomStepCategoryEnum.ReturnOrderRMA:
        resourcetype =
          this.stepType === CustomStepCategoryEnum.RefundWithDiscount ||
          this.stepType === CustomStepCategoryEnum.ReturnOrderRMA
            ? ActionType.Refund
            : ActionType.Discount;

        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: resourcetype,
          discount: [discount, Validators.required],
          discount_index,
          discount_type: [DiscountTypeEnum.Percent, Validators.required],
          check_last_discount: false,
          bill_now: true,
          include_tax: true,
          holdoff_delay,
          final_billing_cycle_offset,
          requires_active_item: true,
          requires_item: true,
          requires_customer: true
        });
        break;

      case CustomStepCategoryEnum.SwapProduct:
        action1 = this.formBuilder.group({
          id: null,
          resourcetype: ActionType.ChangeProduct,
          requires_active_item: true,
          requires_item: true,
          requires_customer: true,
          exchangeable_set_types: [[], Validators.required],
          exchangeable_set: [[], Validators.required],
          exchangeable_index,
          bill_now: false,
          subscription_only: false,
          quantity_type: QuantityTypeEnum.None
        });
        break;

      case CustomStepCategoryEnum.UpsellOrder:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.AddProduct,
          upsale_index,
          holdoff_delay,
          requires_active_item: true,
          requires_item: true,
          requires_customer: true
        });
        break;

      case CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.Reactivate,
          discount: [discount, Validators.required],
          discount_index,
          discount_type: [DiscountTypeEnum.Fixed, Validators.required],
          check_last_discount: false,
          final_billing_cycle_offset,
          billing_interval_days: null,
          next_bill_date_delay: null,
          max_bill_date_delay: null,
          bill_now: true,
          requires_active_item: true,
          requires_item: true,
          requires_customer: true
        });

        break;
    }

    return this.formBuilder.array([action1, action2]);
  }

  getResponseData(): FormGroup {
    const label = 'continue';

    return this.formBuilder.group({
      label: [null, Validators.required],
      help: null,
      name: [null],
      type: FunnelInputTypeEnum.InputActionStatus,
      category: CustomStepCategoryEnum.Response,
      banner: null,
      subtitle: null,
      content: [null, Validators.required],
      offer_intent: OfferIntents.Other,
      style: null,
      classes: [[]],
      is_popup: false,
      allow_undo: false,
      show_step_link: false,
      contact_type: null,
      enhanced_content: null,
      enhanced_mode: false,
      fail_if_any_input_invalid: false,
      invalid_message: null,
      hide_status: false,
      action_holdoffs: null,
      mobile_icon_button_type: null,
      use_bootstrap_spacing: true,
      enhanced_content_components: null,
      inputs: this.formBuilder.array([
        {
          label,
          help: null,
          next_step: null,
          type: FunnelInputTypeEnum.InputActionStatus,
          values: [],
          actions: [],
          icon: null,
          hide_if_invalid: true,
          product_funnels: [],
          classes: [],
          is_alt_child_exit: true,
          matched_step_key: null,
          autopick_next_step: null,
          requires_active_item: false,
          requires_item: true,
          requires_customer: true,
          allow_undo: false
        }
      ])
    });
  }

  getCampaigns() {
    this.campaignService.list({}).subscribe(
      (data: Pager) => {
        this.campaigns = data.results;
        if (this.campaigns.length === 1) {
          this.selectedBrandId = this.campaigns[0].id;
          this.updateBrandTheme();
        }
        this.campaignsLoading = false;
      },
      (error) => {
        this.campaignsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ['steps', 'survey']
        },
        ...(this.isNew
          ? [
              {
                label: 'Choose a Template',
                url: ['steps', 'survey', 'category', this.offerType]
              }
            ]
          : []),
        {
          label: this.stepTitle
        }
      ];
    } else if (this.offerType === OfferTypes.CustomerPortal) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
          url: ['steps', this.offerName]
        },
        ...(!this.isNew
          ? [
              {
                label: ''
              }
            ]
          : []),
        ...(this.isNew
          ? [
              {
                label: 'Choose a Template',
                url: ['steps', this.offerName, 'category', this.offerType]
              }
            ]
          : []),
        {
          label: this.stepTitle
        }
      ];
    } else {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
          url: ['steps', this.offerName]
        },
        {
          label: '',
          url: ['steps', this.offerName, this.offerType, 'details', this.funnelId]
        },
        ...(this.isNew
          ? [
              {
                label: 'Choose a Template',
                url: ['steps', this.offerName, this.funnelId, 'category', this.offerType]
              }
            ]
          : []),
        {
          label: this.stepTitle
        }
      ];
    }
  }

  getStyles() {
    this.styleService.list({ page: 1, page_size: config.maxPageSize }).subscribe((data: Pager) => {
      this.themes = data.results;
    }),
      (err) => this.alertService.error(err.message);
  }

  scroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  fetchFunnel() {
    this.funnelService.get(this.funnelId).subscribe(
      (response: Funnel) => {
        this.funnel = response;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  fetchTemplate() {
    this.loader.show('white', 'Gearing up the builder', 998);

    this.stepService.get(this.templateId).subscribe(
      (response: FunnelStep) => {
        if (response) {
          const data = !this.folderId && this.templateId ? normalizeObject(response) : response;
          this.patchInputs(data);
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  fetchOfferStep() {
    this.loader.show('white', 'Gearing up the builder', 998);

    // Offer Step Fetching
    this.funnelStepService.get(this.stepId).subscribe(
      (data: FunnelStep) => {
        if (data) {
          this.patchInputs(data);
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  fetchStep() {
    this.loader.show('white', 'Gearing up the builder', 998);

    //Survey Step Fetching
    this.stepService.get(this.stepId).subscribe(
      (data: FunnelStep) => {
        if (data) {
          this.patchInputs(data);
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  patchInputs(data: FunnelStep) {
    if (this.useExternalButtons) {
      const inputs = data.inputs.map(() => {
        return this.getSurveyInputs();
      });
      if (inputs) {
        this.form.setControl('inputs', this.formBuilder.array(inputs, Validators.required));
      }
    }

    this.form.patchValue(data);

    const formInputsArray = this.form.get('inputs') as FormArray;
    data.inputs.forEach((dataInput, index) => {
      const formInput = formInputsArray.at(index) as FormGroup;
      const formActions = formInput.get('actions') as FormArray;

      dataInput.actions.forEach((action: Action, actionIndex: number) => {
        if (actionIndex >= formActions.length) {
          formActions.push(new FormControl(action));
        }
      });
    });

    if (data.static_data) {
      this.stepMedia = this.updateMedia(this.stepMedia, data.static_data);
      this.stepEmulatorService.updateStepMedia(this.stepMedia);
    }

    if (data && data.response_data && data.response_data.static_data) {
      this.stepResponseMedia = this.updateMedia(this.stepResponseMedia, data.response_data.static_data);
      this.stepEmulatorService.updateResponseMedia(this.stepResponseMedia);
    }
  }

  updateMedia(media, static_data) {
    const { image, image_mobile, image_tablet, image_type, embed_code } = static_data;

    switch (image_type) {
      /* Fixed Image */
      case StepStaticDataImageType.Fixed:
        const setFixedImage = (device: StepEmulatorDevices, image: Image) => {
          if (image) {
            media.category = StepMediaCategory.Fixed;
            media.fixedImage.category = StepFixedMediaCategory.Upload;
            media.fixedImage[device] = image;
          }
        };

        // Set images for each device
        setFixedImage(StepEmulatorDevices.Mobile, image_mobile);
        setFixedImage(StepEmulatorDevices.Tablet, image_tablet);
        setFixedImage(StepEmulatorDevices.Desktop, image);
        break;

      /* Gallery Image */
      case StepStaticDataImageType.Gallery:
        if (image) {
          media.category = StepMediaCategory.Fixed;
          media.fixedImage.category = StepFixedMediaCategory.Gallery;
          media.fixedImage.productImage = image;
        }
        break;

      /* Product Image */
      case StepStaticDataImageType.Product:
        media.category = StepMediaCategory.Product;
        media.subCategory = StepMediaSubCategory.Product;
        break;

      /* Tagged Image */
      case StepStaticDataImageType.Tagged:
        media.category = StepMediaCategory.Product;
        media.subCategory = StepMediaSubCategory.Tagged;
        break;

      /* Embed Video */
      case StepStaticDataImageType.Embed:
        media.category = StepMediaCategory.Embed;
        media.embedVideo.url = embed_code;
        media.embedVideo.sanitizedURL = getEmbeddedVideoUrl(media.embedVideo.url, this.sanitizer);
        media.embedVideo.isURLValid = !!media.embedVideo.sanitizedURL;
        break;

      /* Media: Disabled */
      case StepStaticDataImageType.None:
        media.category = StepMediaCategory.NoMedia;
        break;
    }
    return media;
  }

  fetchFolderDetails() {
    this.templateFolderService.get(this.folderId).subscribe(
      (response: StepTemplateFolder) => {
        this.folderDetails = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  updateBrandTheme() {
    if (!this.selectedBrandId) {
      this.theme = defaultThemeColor;
      return;
    }

    let styleId, theme;
    this.campaignService.get(this.selectedBrandId).subscribe(
      (campaign: Campaign) => {
        if (campaign) {
          if (campaign.styles && campaign.styles.length) {
            styleId = campaign.styles[0];
          }
        }

        if (styleId && this.themes.length > 0) {
          theme = this.themes.find((theme) => theme.id === styleId).variables.theme_color;
        }

        if (theme) {
          this.theme = theme;
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  updateProductImageUrl() {
    this.productPlaceholderUrl = this.selectedProduct.id ? getCampaignProductImageUrl(this.selectedProduct) : null;
  }

  loadPreview() {
    if (this.form.invalid) {
      this.enableFormErrors = true;
      setTimeout(() => this.scrollToError(), 50);
      return;
    }

    this.enableFormErrors = false;
    this.isPreviewLoading = true;
    const body = this.form.value;
    this.manipulateHTMLContent(body);

    this.funnelService
      .previewStep(this.selectedBrandId || this.campaigns[0].id, body, this.selectedProduct.id)
      .subscribe(
        (data) => {
          window.open(data.toString(), '_blank');
          this.isPreviewLoading = false;
        },
        (error) => {
          this.alertService.error(error);
          this.isPreviewLoading = false;
        }
      );
  }

  onSubmit(draft = false) {
    if (this.folderId) {
      this.saveTemplate();
    } else {
      if (this.form.invalid) {
        this.enableFormErrors = true;
        setTimeout(() => this.scrollToError(), 50);
        return;
      }

      this.enableFormErrors = false;
      switch (this.stepType) {
        case CustomStepCategoryEnum.CancelSubSurvey:
        case CustomStepCategoryEnum.CancelOrderSurvey:
        case CustomStepCategoryEnum.CancelTrialSurvey:
        case CustomStepCategoryEnum.Survey:
        case CustomStepCategoryEnum.ReturnSurvey:
          this.saveSurveyStep(draft);
          break;
        default:
          this.saveOfferStep(draft);
          break;
      }
    }
  }

  saveSurveyStep(draft = false) {
    if (draft) {
      this.isStepDraftSaving = true;
    } else {
      this.isStepSaving = true;
    }

    let body;
    body = this.form.value;

    this.manipulateHTMLContent(body);

    const handleSuccess = () => {
      this.alertService.success('Saved Successfully.', true);
      setTimeout(() => {
        this.navigate(['steps', 'survey']);
      }, 2000);
    };

    const handleError = (err: any) => {
      if (draft) {
        this.isStepDraftSaving = false;
      } else {
        this.isStepSaving = false;
      }

      if (err.status === 400) {
        this.alertService.error(err.error.name);
      } else {
        this.alertService.error(err.message);
      }
    };

    if (this.isNew) {
      /* Create Survey Step */
      this.stepService.create(body).subscribe(handleSuccess, handleError);
    } else {
      /* Save Survey Step */
      this.stepService.update(this.stepId, body).subscribe(handleSuccess, handleError);
    }
  }

  saveOfferStep(draft = false) {
    if (draft) {
      this.isStepDraftSaving = true;
    } else {
      this.isStepSaving = true;
    }

    let body;
    body = this.form.value;
    body = {
      ...body,
      response_data: {
        ...body.response_data,
        name: `${body.name} response`
      }
    };

    this.manipulateHTMLContent(body);

    const handleSuccess = () => {
      if (this.isCustomerPortalStep) {
        const data = {
          resourcetype: this.funnel.resourcetype,
          name: body.name
        };
        /* Update Offer Name */
        this.funnelService.patch(this.funnelId, data).subscribe(() => {
          this.alertService.success('Saved Successfully.', true);

          setTimeout(() => {
            this.navigate(['steps', this.offerName]);
          }, 2000);
        }, handleError);
      } else {
        this.alertService.success('Saved Successfully.', true);

        setTimeout(() => {
          this.navigate(['steps', this.offerName, this.offerType, 'details', this.funnelId]);
        }, 2000);
      }
    };

    const handleError = (err: any) => {
      if (draft) {
        this.isStepDraftSaving = false;
      } else {
        this.isStepSaving = false;
      }

      if (err.status === 400) {
        this.alertService.error(err.error.name);
      } else {
        this.alertService.error(err.message);
      }
    };

    if (this.isNew) {
      if (this.isCustomerPortalStep) {
        /* Creating a new Offer/Funnel */
        const data = {
          ...OfferData,
          name: body.name,
          offer_type: this.offerType
        };

        this.funnelService.create(data).subscribe(
          (data: Funnel) => {
            this.funnelId = data.id;
            this.funnel = data;
            this.funnelStepService.setFunnelId(this.funnelId);

            /* Create New Offer Step */
            this.funnelStepService.create(body).subscribe(handleSuccess, handleError);
          },
          (err: any) => {
            if (err.status === 400) {
              this.alertService.error(err.error.name);
            } else {
              this.alertService.error(err.message);
            }
          }
        );
      } else {
        if (this.funnel.offer_steps && this.funnel.offer_steps.length > 0) {
          const lastStepIndex = this.funnel.offer_steps.length - 1;
          if (
            this.funnel.offer_steps[lastStepIndex].inputs &&
            this.funnel.offer_steps[lastStepIndex].inputs.length > 0
          ) {
            this.funnel.offer_steps[lastStepIndex].inputs.forEach((input) => {
              if (!input.has_response_step) {
                body = {
                  ...body,
                  parent_input: input.id
                };
              }
            });
          }
        }

        /* Create New Offer Step */
        this.funnelStepService.create(body).subscribe(handleSuccess, handleError);
      }
    } else {
      /* Update Offer Step */
      this.funnelStepService.update(this.stepId, body).subscribe(handleSuccess, handleError);
    }
  }

  saveTemplate() {
    if (this.form.invalid) {
      this.enableFormErrors = true;
      setTimeout(() => this.scrollToError(), 50);
      return;
    }

    this.isStepSaving = true;
    this.enableFormErrors = false;

    let body, file;
    body = {
      ...this.form.value,
      is_template: true,
      is_global: this.folderDetails.is_global
    };
    if (this.funnelId) {
      body = {
        ...body,
        response_data: {
          ...body.response_data,
          name: `${body.name} response`
        }
      };
    }

    this.manipulateHTMLContent(body);

    this.loader.show('white', 'Saving');
    this.openDesktop();

    setTimeout(async () => {
      file = await this.captureEmulatorScreenshot();
      this.openMobile();
      setTimeout(() => {
        this.loader.hide();
      }, 500);

      const handleResponse = (response: FunnelStep) => {
        this.alertService.success('Saved Successfully.', true);

        if (file) {
          const renamedFile = new File([file], `${response.id}`, {
            type: file.type
          });
          this.imageService
            .uploadFile(response.pre_signed_url, renamedFile)
            .catch((error) => console.log(error))
            .finally(() => {
              this.navigateFromTemplate();
            });
        }
      };

      const handleError = (err: any) => {
        this.isStepSaving = false;

        if (err.status === 400) {
          this.alertService.error(err.error.name);
        } else {
          this.alertService.error(err.message);
        }
      };

      if (this.folderId && !this.templateId) {
        // Create Template
        this.stepService.create(body).subscribe(handleResponse, handleError);
      } else {
        // Update Template
        this.stepService.update(this.templateId, body).subscribe(handleResponse, handleError);
      }
    }, 600);
  }

  navigateFromTemplate() {
    if (this.funnelId) {
      this.router.navigate([
        'steps',
        this.offerName,
        this.funnelId,
        'category',
        this.offerType,
        'folders',
        this.stepType,
        'folder',
        this.folderId
      ]);
    } else {
      this.router.navigate([
        'steps',
        this.offerName,
        'category',
        this.offerType,
        'folders',
        this.stepType,
        'folder',
        this.folderId
      ]);
    }
  }

  async captureEmulatorScreenshot(): Promise<File | null> {
    this.changePreview(StepEmulationTypes.Preview);
    await new Promise((resolve) => setTimeout(resolve, 200));

    if (this.stepEmulator) {
      try {
        const file = await this.stepEmulator.takeScreenshot();
        return file;
      } catch (error) {
        console.error('Error capturing screenshot:', error);
        return null;
      }
    }

    return null;
  }

  manipulateHTMLContent(body: FunnelStep) {
    if (this.useExternalButtons) {
      body.button_styles.confirm = this.addButtonStyles(this.stepButtons.confirmButton);
      body.button_styles.back = this.addButtonStyles(this.stepButtons.backButton);
    } else {
      body.inputs[0].label = addDataAttributes(body.inputs[0].label);
      body.inputs[0].button_styles = this.addButtonStyles(this.stepButtons.confirmButton);

      body.inputs[1].label = addDataAttributes(body.inputs[1].label);
      body.inputs[1].button_styles = this.addButtonStyles(this.stepButtons.backButton);
    }

    if (body.banner) {
      body.banner = this.addBannerStyle(this.stepTextual.banner);
    }

    body.banner = addDataAttributes(body.banner);
    body.label = addDataAttributes(body.label);
    body.content = addDataAttributes(body.content);

    if (body.response_data) {
      body.response_data = {
        ...body.response_data,
        label: addDataAttributes(body.response_data.label),
        content: addDataAttributes(body.response_data.content)
      };
    }
  }

  addButtonStyles(details: StepButtonDetails) {
    let styleString;
    const outlineShapes = [StepButtonShapes.FlatOutline, StepButtonShapes.ModernOutline, StepButtonShapes.PillOutline];
    const isOutlineShape = outlineShapes.includes(+details.style);
    const isTextOnly = +details.style === StepButtonShapes.TextOnly;
    const themeString = 'var(--theme_color)',
      color =
        +details.type === StepButtonControlTypes.Theme && (isOutlineShape || isTextOnly)
          ? themeString
          : details.fontColor,
      background = +details.type === StepButtonControlTypes.Theme ? themeString : details.backgroundColor,
      borderColor = +details.type === StepButtonControlTypes.Theme ? themeString : details.borderColor,
      borderRadius = details.borderRadius;

    switch (details.style) {
      case StepButtonShapes.Flat:
      case StepButtonShapes.Modern:
      case StepButtonShapes.Pill:
        styleString =
          `color: ${color}; background-color: ${background}; border-color: ${borderColor}; border-radius: ${borderRadius}; min-height: 72px; padding: 16px 10px;`.trim();
        break;
      case StepButtonShapes.FlatOutline:
      case StepButtonShapes.ModernOutline:
      case StepButtonShapes.PillOutline:
        styleString =
          `color: ${color}; background-color: ${transparent}; border-color: ${borderColor}; border-radius: ${borderRadius}; min-height: 72px; padding: 16px 10px;`.trim();
        break;
      case StepButtonShapes.TextOnly:
        styleString = `color: ${color}; background-color: ${transparent}; border-color: ${transparent};`.trim();
        break;
    }

    if (this.useExternalButtons) {
      return {
        label: addDataAttributes(details.content),
        style: styleString,
        style_type: details.style,
        control_type: details.type
      };
    } else
      return {
        style: styleString,
        style_type: details.style,
        control_type: details.type
      };
  }

  addBannerStyle(banner: StepTextualBanner) {
    if (!banner.value) return null;

    const backgroundColorStyle = `background-color: ${banner.background};`;

    const styleRegex = /style=['"]([^'"]*)['"]/i;
    const match = banner.value.match(styleRegex);

    if (match && match[1]) {
      const existingStyles = match[1];
      const updatedStyles = existingStyles.includes('background-color')
        ? existingStyles.replace(/background-color:\s*[^;]+;/i, backgroundColorStyle)
        : `${existingStyles} ${backgroundColorStyle}`;

      return banner.value.replace(styleRegex, `style="${updatedStyles.trim()}"`);
    } else {
      const tagRegex = /^<\w+/;
      const tagMatch = banner.value.match(tagRegex);
      if (tagMatch) {
        const openingTag = tagMatch[0];
        return banner.value.replace(openingTag, `${openingTag} style="${backgroundColorStyle}"`);
      }
      return banner.value;
    }
  }

  openMobile() {
    this.activeDevice = StepEmulatorDevices.Mobile;
    this.isStepFormActive = true;
    this.isDropdownActive = true;
  }

  openTablet() {
    this.activeDevice = StepEmulatorDevices.Tablet;
    this.isStepFormActive = true;
    this.isDropdownActive = true;
  }

  openDesktop() {
    this.activeDevice = StepEmulatorDevices.Desktop;
    this.isStepFormActive = false;
    this.isDropdownActive = false;
  }

  changePreview(config: StepEmulationTypes) {
    if (config === StepEmulationTypes.Preview) {
      this.isStepPreview = true;
      this.isResponseScreen = false;
    } else if (config === StepEmulationTypes.Response) {
      this.isStepPreview = false;
      this.isResponseScreen = true;
    }
  }

  openProductSelector() {
    this.ngxSmartModalService.getModal('selectProductsDialog').open();
  }

  onProductSelected(product: CampaignProduct) {
    if (product) {
      this.selectedProduct = product;
      this.updateProductImageUrl();
    }
    this.ngxSmartModalService.getModal('selectProductsDialog').close();
  }

  getTitleForStepType(stepType: CustomStepCategoryEnum, isNew: boolean): string {
    let action = isNew ? 'Create' : 'Edit';
    if (this.folderId && this.templateId) action = 'Edit';
    else if (this.folderId && !this.templateId) action = 'Create';

    return `${action} ${StepCategoryLabels[stepType]}`;
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  get purchaseType(): boolean {
    return [
      CustomStepCategoryEnum.PercentageDownsell,
      CustomStepCategoryEnum.FixedAmountDownsell,
      CustomStepCategoryEnum.RefundWithDiscount,
      CustomStepCategoryEnum.ReturnOrderRMA
    ].includes(+this.stepType);
  }

  get isMobile() {
    return this.activeDevice === StepEmulatorDevices.Mobile;
  }

  get isTablet() {
    return this.activeDevice === StepEmulatorDevices.Tablet;
  }

  get isDesktop() {
    return this.activeDevice === StepEmulatorDevices.Desktop;
  }

  getProductName(productName: string): string {
    return getTruncatedProductName(productName);
  }

  addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  scrollToError() {
    const elementRef: ElementRef | null = this.stepFormComponent.scrollToFirstErrorComponent();
    if (elementRef && elementRef.nativeElement) {
      const offsetTop = elementRef.nativeElement.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy(): void {
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(true);
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.stepEmulatorService.resetStepData();
  }
}
