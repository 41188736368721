import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {
  AlertService,
  CampaignService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  PusherService,
  StepTemplateService,
  StorageService,
  UserService,
} from '../../_services';
import { CrudPagedListComponent } from '../../_directives';
import {
  CustomStepCategoryEnum,
  Pager,
  StepCategoryLabels,
  OfferIntents,
  OfferTypes,
  StepTableStatusLabels,
  StepDateTypes,
  StepPopupActions,
  StepTableFilters,
  StepTableStatuses,
  NotificationType,
  User,
  OfferTypeLabels,
  StepsSortConfig,
  TableSortDirection,
  StepSortableColumns,
  OfferIntentTypeTableLabels,
  OfferIntentTypeLabels,
} from '../../_models';
import { takeUntil } from 'rxjs/operators';
import { config } from '../../../config/config';
import { Filter } from '../../../app/_shared';

export abstract class OffersAbstractComponent extends CrudPagedListComponent implements OnInit {
  user: User;
  campaignId: number;
  showModal: boolean = false;
  showThreeDotMenu: boolean = false;
  isOffersPage: boolean = true;
  selectedRow: number = -1;
  selectedStatus: StepTableStatuses = null;
  tableStatuses = StepTableStatuses;
  selectedFilter: string;
  tableFilters = StepTableFilters;
  selectedDate: StepDateTypes = StepDateTypes.LastModified;
  tableDateTypes = StepDateTypes;
  searchQuery: string;
  showAddButton: boolean = true;
  showViewButton: boolean = true;
  selectedOfferId: number | null = null;
  selectedIndex: number | null = null;
  tableData: any[] = [];
  offerType: OfferTypes;
  popupTitle: string = '';
  popupData = {
    name: '',
    is_survey: false,
    offer_id: null,
    step_id: null,
    offer_intent: null,
    offer_type: null,
    offer_type_options: [],
    popupType: null,
  };
  stepPopupData = {
    name: '',
    is_survey: false,
    offer_id: null,
    step_id: null,
    offer_intent: null,
    offer_type: null,
    offer_type_options: [],
    popupType: null,
  };
  originalTableData: any[] = [...this.tableData];
  stepOfferList: any[];
  isSurvey = false;
  isCustomerPortal = false;
  isLoading = false;
  areSupportOffers: boolean = false;
  selectedSurveyStep: any;
  stepPopupActions = StepPopupActions;
  offerIntents = OfferIntents;
  stepStatusLabel = StepTableStatusLabels;
  stepCategories = CustomStepCategoryEnum;
  offerPopupTitleMap = {
    [StepPopupActions.Create]: 'Create Step Group',
    [StepPopupActions.Update]: 'Rename Step Group',
    [StepPopupActions.Duplicate]: 'Duplicate Step Group',
    [StepPopupActions.Delete]: 'Delete Step Group',
  };
  surveyPopupTitleMap = {
    [StepPopupActions.Update]: 'Rename Survey',
    [StepPopupActions.Duplicate]: 'Duplicate Survey',
    [StepPopupActions.Delete]: 'Delete Survey',
  };
  filters: Filter[] = [
    {
      name: 'Status',
      values: [
        { key: StepTableStatuses.Active, value: StepTableStatusLabels[StepTableStatuses.Active] },
        { key: StepTableStatuses.Inactive, value: StepTableStatusLabels[StepTableStatuses.Inactive] },
        { key: StepTableStatuses.Draft, value: StepTableStatusLabels[StepTableStatuses.Draft] },
      ],
      selectedValues: [],
      showSubPopup: false,
    },
    {
      name: 'Type',
      values: [
        { key: OfferIntents.CancelOrder, value: OfferIntentTypeTableLabels[OfferIntents.CancelOrder] },
        {
          key: OfferIntents.CancelSubscriptionHold,
          value: OfferIntentTypeTableLabels[OfferIntents.CancelSubscriptionHold],
        },
        { key: OfferIntents.CancelTrialHold, value: OfferIntentTypeTableLabels[OfferIntents.CancelTrialHold] },
        { key: OfferIntents.ReturnOrder, value: OfferIntentTypeTableLabels[OfferIntents.ReturnOrder] },
        { key: OfferIntents.ReturnSubscription, value: OfferIntentTypeTableLabels[OfferIntents.ReturnSubscription] },
        { key: OfferIntents.ReturnTrial, value: OfferIntentTypeTableLabels[OfferIntents.ReturnTrial] },
        { key: OfferIntents.CancelSubscription, value: OfferIntentTypeTableLabels[OfferIntents.CancelSubscription] },
        { key: OfferIntents.CancelTrial, value: OfferIntentTypeTableLabels[OfferIntents.CancelTrial] },
      ],
      selectedValues: [],
      showSubPopup: false,
    },
  ];
  currentSort: StepsSortConfig = {
    column: null,
    direction: null,
  };
  columnDirections = TableSortDirection;
  sortableColumns = StepSortableColumns;
  public offerTypeOptions: { value: string; label: string }[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected funnelStepService: FunnelStepService,
    protected stepService: StepTemplateService,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected pusherService: PusherService,
    protected loader: LoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    protected route: ActivatedRoute,
    protected storageService: StorageService
  ) {
    super(router, location, route, funnelService, alertService);
    this.objectName = 'offers';
  }

  ngOnInit() {
    this.loader.show();
    this.fetchCampaign();
    this.fetchOfferData();

    this.userService.getCurrent().subscribe((user: User) => {
      this.user = user;
    });

    if (this.pusherService.channel) {
      this.pusherService.channel.bind(NotificationType[NotificationType.COPY_FUNNEL], (data: any) => {
        if (this.user && this.user.account && data.account == this.user.account.id) {
          data = data.payload;

          if (data && data.is_completed) {
            this.fetchOfferData();
          }
        }
      });
    }

    const options = Object.keys(OfferIntentTypeLabels)
      .filter((intentType) => parseInt(intentType, 10) !== OfferIntents.Legacy)
      .map((intentType) => ({
        value: intentType,
        label: OfferIntentTypeLabels[intentType],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    this.offerTypeOptions = options;
  }

  fetchCampaign() {
    this.campaignService
      .list(Object.assign({ page: 1, page_size: config.maxPageSize }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.campaignId = data.results[0].id;
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  fetchOfferData() {
    if (!this.isSurvey) {
      this.funnelService.listOffers(this.offerType, this.areSupportOffers ? 0 : undefined).subscribe(
        (response: Pager) => {
          this.loader.hide();
          this.tableData = response.results.map((item: any) => {
            const lastModifiedMoment = moment(item.last_modified).tz('EST');
            const createdMoment = moment(item.created).tz('EST');

            const lastModified = {
              time: lastModifiedMoment.format('hh:mm A'),
              date: lastModifiedMoment.format('MM/DD/YYYY'),
            };

            const created = {
              time: createdMoment.format('hh:mm A'),
              date: createdMoment.format('MM/DD/YYYY'),
            };

            return {
              id: item.id,
              displayId: item.display_id,
              stepName: item.name,
              status: item.is_modified
                ? StepTableStatuses.Draft
                : item.paths.length > 0
                ? StepTableStatuses.Active
                : StepTableStatuses.Inactive,
              typeLabel: OfferIntentTypeTableLabels[item.offer_intent] || '--',
              type: item.offer_intent,
              offerType: item.offer_type,
              lastModified,
              created,
              paths: item.paths.length > 0 ? item.paths : [],
              steps: item.steps || [],
              showAllOffers: item.steps.length ? true : false,
              ...(this.isCustomerPortal ? { previewLoading: false } : {}),
            };
          });
          this.originalTableData = this.tableData;
        },
        (error) => {
          this.loader.hide();
          this.alertService.error(error.message);
        }
      );
    } else {
      this.stepService.list({ folder__isnull: true, is_template: false }).subscribe(
        (response) => {
          this.loader.hide();
          this.stepOfferList = response.results;
          this.populateSurveySteps();
        },
        (error) => {
          this.loader.hide();
          this.alertService.error(error.message);
        }
      );
    }
  }

  populateSurveySteps() {
    this.tableData = this.stepOfferList.map((item: any) => {
      const lastModifiedMoment = moment(item.last_modified).tz('EST');
      const createdMoment = moment(item.created).tz('EST');

      const lastModified = {
        time: lastModifiedMoment.format('hh:mm A'),
        date: lastModifiedMoment.format('MM/DD/YYYY'),
      };

      const created = {
        time: createdMoment.format('hh:mm A'),
        date: createdMoment.format('MM/DD/YYYY'),
      };

      return {
        id: item.id,
        displayId: item.display_id,
        stepName: item.name,
        status: item.paths.length > 0 ? StepTableStatuses.Active : StepTableStatuses.Draft,
        typeLabel: StepCategoryLabels[item.category] || '--',
        type: item.offer_intent,
        lastModified,
        created,
        paths: item.paths.length > 0 ? item.paths : [],
        category: item.category,
        previewLoading: false,
      };
    });
    this.originalTableData = this.tableData;
    const isNewSurvey = this.storageService.get('isNewSurvey');
    if (isNewSurvey) {
      this.openTemplatePopup();
      this.storageService.remove('isNewSurvey');
    }
  }

  navigateToOfferStep(offerId: number, offerType: OfferTypes, stepId: number, category: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        'steps',
        OfferTypeLabels[offerType],
        offerId,
        'category',
        offerType,
        category,
        'step',
        stepId,
      ])
    );
    window.open(url, '_blank');
  }

  navigateToStepBuilder(id: number, category: number, stepId?: number) {
    if (this.isSurvey) {
      this.router.navigate(['steps', 'survey', 'category', this.offerType, category, 'step', id]);
    } else if (this.isCustomerPortal) {
      this.router.navigate(['steps', 'customer-portal', id, 'category', this.offerType, category, 'step', stepId]);
    }
  }

  navigateToPath(pathId) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['new-paths', 'edit', pathId]));
    window.open(url, '_blank');
  }

  openOfferPopup(
    popupType: StepPopupActions,
    offerId?: number | string,
    offerName?: string,
    offerIntent?: OfferIntents
  ): void {
    this.popupTitle = this.offerPopupTitleMap[popupType] || '';
    if (popupType === StepPopupActions.Create) {
      this.popupData.offer_intent = null;
    }

    this.popupData = {
      ...this.popupData,
      name: offerName || '',
      offer_id: offerId || null,
      offer_type: this.offerType,
      popupType: popupType,
      offer_intent: offerIntent || this.popupData.offer_intent || null,
      offer_type_options: this.offerTypeOptions,
    };

    this.openPopup();
  }

  openStepPopup(
    popupType: StepPopupActions,
    stepId?: number | string,
    stepName?: string,
    offerIntent?: OfferIntents
  ): void {
    this.popupTitle = this.surveyPopupTitleMap[popupType] || '';

    this.stepPopupData = {
      ...this.stepPopupData,
      name: stepName || '',
      step_id: stepId || null,
      is_survey: true,
      popupType: popupType,
      offer_intent: offerIntent || null,
    };

    this.ngxSmartModalService.getModal('stepPopup').open();
  }

  openTemplatePopup(offerId?: number, intent?: OfferIntents) {
    this.popupData = {
      ...this.popupData,
      offer_id: offerId || null,
      offer_intent: intent || null,
      is_survey: this.isSurvey,
      popupType: StepPopupActions.CreateTemplate,
    };
    this.ngxSmartModalService.getModal('templatePopup').open();
  }

  openPopup() {
    this.ngxSmartModalService.getModal('offerPopup').open();
  }

  closeOfferPopup() {
    this.ngxSmartModalService.getModal('offerPopup').close();
  }

  closeTemplatePopup() {
    this.ngxSmartModalService.getModal('templatePopup').close();
  }

  closeStepPopup() {
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  saveOffer(event: { id: number; offer_type: number; offer_intent: OfferIntents; isNew: boolean }): void {
    this.fetchOfferData();
    this.ngxSmartModalService.getModal('offerPopup').close();

    if (event.isNew) {
      // if new offer is created, open template popup
      this.popupData = {
        ...this.popupData,
        offer_id: event.id || null,
        offer_type: event.offer_type || null,
        offer_intent: event.offer_intent || null,
        popupType: StepPopupActions.CreateTemplate,
      };

      this.ngxSmartModalService.getModal('templatePopup').open();
    }
  }

  saveStep(): void {
    this.fetchOfferData();
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  onFilterChanged(event: { filterName: string; selectedValues: string[] }): void {
    const { filterName, selectedValues } = event;
    let filteredData = [...this.originalTableData];

    switch (filterName) {
      case 'Status':
        filteredData =
          selectedValues.length === 0 || selectedValues.includes(null)
            ? [...this.originalTableData]
            : this.originalTableData.filter((item) => selectedValues.includes(item.status));
        break;

      case 'Type':
        filteredData =
          selectedValues.length === 0 || selectedValues.includes(null)
            ? [...this.originalTableData]
            : this.originalTableData.filter((item) => selectedValues.includes(item.type));
        break;
    }

    this.tableData = filteredData;
  }

  searchData() {
    this.tableData = this.originalTableData.filter((item) =>
      item.stepName.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  getOfferStepPreview(id: string | number) {
    const offer = this.tableData.find((item) => item.id === id);
    offer.previewLoading = true;
    this.funnelStepService.setFunnelId(offer.id);
    this.funnelStepService.get(offer.steps[0].id).subscribe((resp: any) => {
      if (resp) {
        const static_data = resp.static_data,
          response_static_data = resp.response_data.static_data || null,
          stepDetails = {
            ...resp,
            static_data: {
              ...static_data,
              image: static_data.image ? static_data.image.id : null,
              image_mobile: static_data.image_mobile ? static_data.image_mobile.id : null,
              image_tablet: static_data.image_tablet ? static_data.image_tablet.id : null,
            },
            response_data: {
              ...resp.response_data,
              ...(resp.response_data.static_data && {
                static_data: {
                  ...resp.response_data.static_data,
                  image: response_static_data.image ? response_static_data.image.id : null,
                  image_mobile: response_static_data.image_mobile ? response_static_data.image_mobile.id : null,
                  image_tablet: response_static_data.image_tablet ? response_static_data.image_tablet.id : null,
                },
              }),
            },
          };

        this.funnelService.previewStep(this.campaignId, stepDetails).subscribe(
          (data) => {
            window.open(data.toString(), '_blank');
            offer.previewLoading = false;
          },
          (error) => {
            this.alertService.error(error);
            offer.previewLoading = false;
          }
        );
      }
    });
  }

  getSurveyStepPreview(id: string | number) {
    const step = this.tableData.find((item) => item.id === id);
    step.previewLoading = true;

    this.stepService.get(id).subscribe((resp: any) => {
      if (resp) {
        const stepDetails = {
          ...resp,
          static_data: {
            ...resp.static_data,
            image: resp.static_data.image ? resp.static_data.image.id : null,
            image_mobile: resp.static_data.image_mobile ? resp.static_data.image_mobile.id : null,
            image_tablet: resp.static_data.image_tablet ? resp.static_data.image_tablet.id : null,
          },
        };

        this.funnelService.previewStep(this.campaignId, stepDetails).subscribe(
          (data) => {
            window.open(data.toString(), '_blank');
            step.previewLoading = false;
          },
          (error) => {
            this.alertService.error(error);
            step.previewLoading = false;
          }
        );
      }
    });
  }

  convertToTemplate(stepId: number, is_global: boolean = false, funnelId: number) {
    this.loader.show();
    this.funnelStepService.convertToTemplate(stepId, is_global, funnelId).subscribe(
      (_) => {
        this.loader.hide();
        this.alertService.success('Step converted to template successfully');
      },
      (error) => {
        this.loader.hide();
        this.handleError(error);
      }
    );
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }
  toggleShowAllOffers(item: any): void {
    item.showAllOffers = !item.showAllOffers;
  }

  togglePathList(index: number): void {
    if (this.selectedRow === index) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = index;
    }
  }

  closePathList() {
    this.selectedRow = -1;
    this.tableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }

  get buttonText() {
    return (
      (this.isSurvey && 'New Survey') ||
      (this.isCustomerPortal && 'Create a Step') ||
      (this.isOffersPage && !this.isCustomerPortal && 'Create Step Group') ||
      ''
    );
  }

  getOfferTypeClass(item) {
    const cancelTypes = [
      OfferIntents.CancelSubscription,
      OfferIntents.CancelTrial,
      OfferIntents.CancelOrder,
      OfferIntents.CancelSubscriptionHold,
      OfferIntents.CancelTrialHold,
    ];

    return cancelTypes.includes(item.type) ? 'cancel-type' : '';
  }

  sortByName() {
    if (this.currentSort.column === StepSortableColumns.Name) {
      this.currentSort.direction =
        this.currentSort.direction === TableSortDirection.Asc ? TableSortDirection.Desc : TableSortDirection.Asc;
    } else {
      this.currentSort.column = StepSortableColumns.Name;
      this.currentSort.direction = TableSortDirection.Asc;
    }

    this.tableData.sort((a: any, b: any) => {
      const comparison = a.stepName.localeCompare(b.stepName);
      return this.currentSort.direction === TableSortDirection.Asc ? comparison : -comparison;
    });
  }

  sortByLastModified() {
    if (this.currentSort.column === StepSortableColumns.LastModified) {
      this.currentSort.direction =
        this.currentSort.direction === TableSortDirection.Asc ? TableSortDirection.Desc : TableSortDirection.Asc;
    } else {
      this.currentSort.column = StepSortableColumns.LastModified;
      this.currentSort.direction = TableSortDirection.Asc;
    }

    this.tableData.sort((a: any, b: any) => {
      const dateA = new Date(`${a.lastModified.date} ${a.lastModified.time}`);
      const dateB = new Date(`${b.lastModified.date} ${b.lastModified.time}`);
      return this.currentSort.direction === TableSortDirection.Asc
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });
  }

  isNameSorted(): boolean {
    return this.currentSort.column === StepSortableColumns.Name;
  }

  get isNameAsc(): boolean {
    return this.isNameSorted() && this.currentSort.direction === TableSortDirection.Asc;
  }

  get isNameDesc(): boolean {
    return this.isNameSorted() && this.currentSort.direction === TableSortDirection.Desc;
  }

  isDateSorted(): boolean {
    return this.currentSort.column === StepSortableColumns.LastModified;
  }

  get isDateAsc(): boolean {
    return this.isDateSorted() && this.currentSort.direction === TableSortDirection.Asc;
  }

  get isDateDesc(): boolean {
    return this.isDateSorted() && this.currentSort.direction === TableSortDirection.Desc;
  }

  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Create]: '/assets/stepAssets/create-icon.svg',
    [StepPopupActions.Delete]: '/assets/stepAssets/delete-icon.svg',
    [StepPopupActions.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [StepPopupActions.Update]: '/assets/stepAssets/rename.svg',
  };
}
