import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, WidgetService, FunnelStepService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min";
import {takeUntil} from "rxjs/operators";
import {NgxSmartModalService} from "ngx-smart-modal";
import {Widget} from '../_models';
import {Observable} from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  selector: 'widget-form',
  templateUrl: './widget-edit.component.html'
})
export class WidgetFormComponent extends CrudSavePopupComponent implements OnInit {
  stepList: any[];
  showEditors = false;
  previewData: any = null;
  widgets: Widget[] = [];
  types: { value: string, label: string }[] = [
    {value: 'privacy-policy', label: 'Privacy Policy'},
    {value: 'return-policy', label: 'Return Policy'},
    {value: 'return-address', label: 'Return Address'},
    {value: 'terms-and-conditions', label: 'Terms and Conditions'},
    {value: 'offer-terms', label: 'Offer Terms'},
    {value: 'custom', label: 'Custom'},
  ];

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected widgetService: WidgetService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService,
              protected stepService: FunnelStepService,
  ) {
    super(router, location, route, widgetService, alertService);
    this.objectName = 'Widget';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      type: [null, Validators.required],
      name: [null, Validators.required],
      content: [null],
      enhanced_mode: [false],
      enhanced_content: [null],
      enhanced_content_components: [null],
    }));

    if (this.inputData.length > 0) {
      this.widgets = [...this.inputData];
      this.inputData = this.widgets[0];
    }

    super.ngOnInit();
    this.stepService.getGlobalJumpLinks(false, {resourcetype: 'VisualFunnel'})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        stepList => {
          this.stepList = stepList;
          this.showEditors = true;
          this.loading = false;
        },
        error => {
          this.showEditors = true;
          this.loading = false;
          this.handleError(error);
        }
      );
  }

  protected onInputDataChanged() {
    if (this.inputData) {
      if (this.types.some(type => type.value === this.inputData.slug)) {
        this.form.patchValue({type: this.inputData.slug});
      } else {
        this.form.patchValue({type: 'custom'});
      }
    }
  }

  selectType(event) {
    const newType = event.target.value;

    if (newType === 'custom') {
      this.form.patchValue({name: null});
    } else {
      this.form.patchValue({name: this.types.find(type => type.value === newType).label});
    }
  }

  preview() {
    this.previewData = this.getFormData();
    this.modalService.getModal('widgetFormPreviewDialog').open();
  }

  onPreview(previewUrl: string) {
    this.modalService.getModal('widgetFormPreviewDialog').close();
    window.open(previewUrl, '_blank');
  }

  protected getFormData(): any {
    let data = Object.assign(this.form.value);

    if (data.type !== 'custom') {
      data.name = this.types.find(type => type.value === data.type).label;
    }

    delete data['type'];  // remove type from data - it's a virtual field

    this.widgets.forEach((widget: Widget) => {
      Object.assign(widget, data)
    })

    if (this.widgets.length > 1) {
      return this.widgets
    }

    return this.widgets.length > 0 ? this.widgets[0] : {}
  }

  onSubmit() {
    if (this.isNew && this.widgets.length > 1) {
      this.submitted = true;

      if (this.form.valid) {
        this.loading = true;
        this.widgetService.bulkCreate(this.getFormData())
          .subscribe(
            data => {
              if (this.showSuccessMessage) {
                this.alertService.success('Objects created successfully.', true);
              }

              this.canDeactivateService.setFormDirty(false);
              this.onSaveComplete(data);
            },
            error => {
              this.handleSubmitError(error);
              this.loading = false;
            });
      }
    } else {
      super.onSubmit()
    }
  }
}
