import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Invoice, InvoiceChargeType} from "../_models";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {parseISODateString} from "../_helpers";

@Component({
  moduleId: module.id.toString(),
  selector: 'invoice-split-form',
  templateUrl: './invoice-split.component.html'
})
export class InvoiceSplitComponent extends CrudSavePopupComponent implements OnInit {
  invoice: Invoice;
  splitDate: NgbDateStruct;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  splitDateDisplayString = '';
  ngModelOptions = {standalone: true};

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected invoiceService: InvoiceService,
              protected alertService: AlertService
  ) {
    super(router, location, route, invoiceService, alertService);
    this.objectName = 'Invoice';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.invoice = this.inputData;

    if (this.invoice) {
      const splitDate = new Date();
      const minDate = parseISODateString(this.invoice.start_date);
      const maxDate = parseISODateString(this.invoice.end_date);

      this.splitDate = {
        year: splitDate.getUTCFullYear(), month: splitDate.getUTCMonth() + 1, day: splitDate.getUTCDate()
      };
      this.minDate = {
        year: minDate.getUTCFullYear(), month: minDate.getUTCMonth() + 1, day: minDate.getUTCDate()
      };
      this.maxDate = {
        year: maxDate.getUTCFullYear(), month: maxDate.getUTCMonth() + 1, day: maxDate.getUTCDate()
      };
      this.splitDateDisplayString = splitDate.toLocaleDateString("en-US", {timeZone: 'UTC'});
    }
  }

  parseSplitDate(dateStruct: NgbDateStruct) {
    if (dateStruct) {
      const date = new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);

      if (date) {
        this.splitDateDisplayString = date.toLocaleDateString("en-US", {timeZone: 'UTC'});
        this.splitDate = dateStruct;
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      if (this.splitDate) {
        const splitDate = this.splitDate.year + '-' + this.splitDate.month + '-' + this.splitDate.day
        this.invoiceService.split(this.invoice.id, splitDate)
          .subscribe(
            data => {
              this.alertService.success('Invoice split on ' + this.splitDateDisplayString);
              this.onSaveComplete(data);
            },
            error => {
              this.handleSubmitError(error);
              this.loading = false;
            });
      }
    }
  }

}
