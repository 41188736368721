import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ProductCategoryService, WidgetCategoryService} from '../_services';
import {CategoryComponent} from "./category.component";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './widget-category.component.html'
})
export class WidgetCategoryComponent extends CategoryComponent implements OnInit {
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected widgetCategoryService: WidgetCategoryService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService) {
    super(router, location, route, widgetCategoryService, alertService, modalService);
    this.title = 'Design Template Categories';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeFilter() {
    super.initializeFilter();
    this.filter['is_global'] = true;
  }

}
