import {OnInit, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, StorageService} from '../_services';
import {Form} from './form';
import {UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions} from 'ngx-uploader';
import {environment} from '../../environments/environment';
import {DomSanitizer} from "@angular/platform-browser";

export abstract class FileUpload extends Form implements OnInit {
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean = false;
  loading = false;
  previewSource: any = '';
  options: UploaderOptions;

  protected path = '';
  protected fieldName = 'file';
  private humanizeBytes: Function;

  @Output('upload') uploadComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output('cancel') uploadCancelled: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected storageService: StorageService,
    protected domSanitizer: DomSanitizer
  ) {
    super(alertService, router, location);

    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.title = 'Upload a File';
    this.options = {
      concurrency: 1,
      allowedContentTypes: []
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      // const event: UploadInput = {
      //   type: 'uploadAll',
      //   url: '/upload',
      //   method: 'POST',
      //   data: { foo: 'bar' }
      // };
      // this.uploadInput.emit(event);
    }
    else if (output.type === 'addedToQueue'  && typeof output.file !== 'undefined') { // add file to array when added
      let fr = new FileReader();
      fr.onload = () => {
        this.previewSource = this.domSanitizer.bypassSecurityTrustResourceUrl(<string>fr.result);
      };

      fr.readAsDataURL(output.file.nativeFile);
      output.file.form.set('path', output.file.name); //set the name of the file

      if (this.files.length) {
        this.files[0] = output.file; //only allow single file upload for now
      }
      else {
        this.files.push(output.file);
      }
    }
    else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
      this.loading = true;
    }
    else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    }
    else if (output.type === 'dragOver') {
      this.dragOver = true;
    }
    else if (output.type === 'dragOut') {
      this.dragOver = false;
    }
    else if (output.type === 'drop') {
      this.dragOver = false;
    }
    else if (output.type === 'done') {
      if (output.file.responseStatus == 201) {
        this.alertService.success('Successfully uploaded new file: ' + output.file.name, true);
        this.onUploadComplete(output.file.response);
      }
      else {
        this.handleSubmitError({status: output.file.responseStatus, statusText: output.file.response, error: output.file.response});
        this.loading = false;
      }
    }
    else if (output.type === 'rejected') {
      this.alertService.error('Invalid file type.');
      this.loading = false;
    }
  }

  startUpload(): void {
    const authToken = this.storageService.get('authToken');
    let event: UploadInput = {
      type: 'uploadAll',
      url: environment.apiUrl + '/' + this.path,
      fieldName: this.fieldName,
      method: 'POST',
      headers: { 'authorization': 'Token ' + authToken },
    };

    let formData = this.getFormData();

    if (formData) {
      event.data = formData;
    }

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  cancel() {
    this.onUploadCancelled();
  }

  protected getFormData() {
    if (this.form && Object.keys(this.form.controls).length) {
      return this.form.value;
    }

    return null;
  }

  protected onUploadCancelled() {
    this.uploadCancelled.emit();
  }

  protected onUploadComplete(result) {
    this.uploadComplete.emit(result);
  }
}
