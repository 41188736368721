import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AlertService, FaqTopicService, FaqService } from "../_services";
import { Faq, FaqTopic } from "../_models";
import { FormBuilder } from "@angular/forms";
import { FaqTopicNewComponent } from "./faq-topic-new.component";
import { mergeMap } from "rxjs/operators";
import { EMPTY, forkJoin } from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './faq-topic-edit.component.html'
})
export class FaqTopicEditComponent extends FaqTopicNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected topicService: FaqTopicService,
    protected faqService: FaqService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, topicService, faqService, alertService, formBuilder);
    this.title = 'Edit FAQ Topic';
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.data$.pipe(mergeMap((topic: FaqTopic) => {
      if (topic) {
        this.form.patchValue(topic);
        this.topic = topic;
        this.icon = topic.icon;

        //get the options list of faq topics for this campaign as well as the topic's subtopics and faqs
        return forkJoin([
          this.topicService.getSelectOptions(),
          this.topicService.list({parent: topic.id}),
          this.faqService.list({topics: topic.id})
        ]);
      }

      return EMPTY;
    })).subscribe(
      data => {
        let parentOptions = []
        data[0].forEach(item => {
          if (item.value !== Number(this.id)) {
            parentOptions.push(item)
          }
        })
        this.parentOptions = parentOptions;
        this.subtopics = data[1];
        this.faqs = data[2];
        this.loading = false;
      },
      error => {
        this.handleError(error);
        this.loading = false;
      }
    );

    this.data$.subscribe(
      (topic: FaqTopic) => {
        if (topic) {
          this.form.patchValue(topic);
          this.topic = topic;
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected onRouteParams(params: {}) {
    this.loading = true
  }

  protected getFormData() {
    //convert our arrays of objects to arrays of ids
    let faqs = [];
    let subtopics = [];

    this.faqs.forEach((faq: Faq) => {
      faqs.push(faq.id);
    });

    this.subtopics.forEach((faqTopic: FaqTopic) => {
      subtopics.push(faqTopic.id);
    });

    return Object.assign({}, super.getFormData(), {faqs: faqs, children: subtopics});
  }

}
