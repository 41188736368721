import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {FunnelStep, ChatInactivityMessage} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './chatbot-inactivity-message.component.html',
  selector: 'chatbot-inactivity-message',
  styleUrls: ['./chatbot-inactivity-message.component.css']
})
export class ChatbotInactivityMessageComponent extends Form implements OnInit, OnChanges {
  @Input('campaignId') campaignId;
  @Input('messages') messages = [];
  @Input('defaultMessages') defaultMessages = [];

  steps: FunnelStep[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(alertService, router, location);
  }

  get messageForm() {
    return this.form.get('chat_inactivity_messages') as FormArray;
  }

  addMessage(message?: ChatInactivityMessage) {
    this.messageForm.push(new FormGroup({
      id: new FormControl(message ? message.id : null),
      message: new FormControl(message ? message.message : '', Validators.required),
      duration: new FormControl(message ? message.duration : 60, Validators.required),
    }));
  }

  removeMessage(index: number) {
    this.messageForm.removeAt(index);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      chat_inactivity_messages: new FormArray([])
    });
    super.ngOnInit();
  }

  updateForm() {
    if (this.form) {
      if (!this.messages) {
        this.form.addControl('chat_inactivity_messages', new FormArray([]));
      }
      if (this.defaultMessages) {
        if (this.defaultMessages instanceof Array) {
          this.defaultMessages.forEach((message: ChatInactivityMessage) => {
            this.addMessage(message);
          });
        }
      }
    }
  }

  ngOnChanges(changes) {
    this.updateForm()
  }

  getFormData() {
    return this.messageForm.value;
  }

}
