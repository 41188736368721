import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import { BehaviorSubject } from 'rxjs';
import { ReportsUserConfiguration } from '../_models';


@Injectable()
export class ReportsConfigService extends PaginationService {
  _reportConfigSubject = new BehaviorSubject<ReportsUserConfiguration[]>([]);
  reportConfig$ = this._reportConfigSubject.asObservable();
  
  constructor(protected http: HttpClient) {
    super(http, 'reports_config');
  }

  getDashboardReports() {
    return this.http.get(this.route + '/get_dashboard_reports_config/');
  }
}
