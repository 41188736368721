import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ShippingSystemService} from '../_services';
import {Address, ShippingSystem, ShippingSystemType} from '../_models';
import {FormBuilder, Validators} from '@angular/forms';
import {AddressFieldsComponent} from "../address";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './shipping-system-edit.component.html',
  styleUrls: ['./shipping-system.component.css']
})

export class ShippingSystemNewComponent extends CrudSaveComponent implements OnInit, AfterViewInit {
  public shippingSystemTypes: ShippingSystemType[];
  public selectedShippingSystemType: ShippingSystemType;
  public shippingSystem: ShippingSystem;
  @ViewChild(AddressFieldsComponent, { static: false }) addressFields: AddressFieldsComponent;
  address: Address;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected shippingSystemService: ShippingSystemService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, shippingSystemService, alertService);
    this.isNew = true
    this.objectName = 'Shipping System'
    this.title = 'Shipping System'
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      display_name: [null, [Validators.required]],
      type: [null, [Validators.required]],
      credentials: this.formBuilder.group({}),
      config: this.formBuilder.group({}),
      label_data: this.formBuilder.group({}),
    })
    super.ngOnInit();
    this.shippingSystemService.getTypes()
      .subscribe((types: ShippingSystemType[]) => {
        this.shippingSystemTypes = types;
      }, error => {
        this.handleError(error)
      })
  }

  ngAfterViewInit() {
    setTimeout(() => this.form.addControl('address', this.addressFields.form), 0);
  }

  selectType(event) {
    const selectedTypeId = event.target.value;
    let shippingSystem: any = null
    if (selectedTypeId && !isNaN(Number(selectedTypeId))) {
      shippingSystem = this.shippingSystemTypes.filter((item: ShippingSystemType) => item.id === Number(selectedTypeId));
      if (shippingSystem.length > 0) {
        shippingSystem = shippingSystem[0]
      }
    }
    this.selectedShippingSystemType = shippingSystem
    if (shippingSystem) {
      this.form.patchValue({display_name: this.selectedShippingSystemType.name})
    }
  }

  protected getFormData() {
    return Object.assign({}, this.form.value, this.addressFields.form.value);
  }
}
