import { Component, OnDestroy, OnInit } from '@angular/core';
import { CrudPagedListComponent } from '../../_directives';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  CampaignService,
  CanDeactivateService,
  FunnelService,
  LoaderService,
  SideNavService,
  StepTemplateService,
  UserService,
} from '../../_services';
import { Location } from '@angular/common';
import {
  Breadcrumb,
  OfferTypes,
  StepCategoryLabels,
  CustomStepCategoryEnum,
  StepPopupActions,
  User,
  Pager,
  OfferTypeLabels,
} from '../../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { takeUntil } from 'rxjs/operators';
import { config } from '../../../config/config';
import { CanDeactivateServiceWrapper } from '../../_helpers/can-deactivate-service-wrapper';

@Component({
  selector: 'app-step-templates',
  templateUrl: './step-templates.component.html',
  styleUrls: ['./step-templates.component.scss'],
})
export class StepTemplates extends CrudPagedListComponent implements OnInit, OnDestroy {
  offerType: OfferTypes;
  offerName: string;
  stepType: CustomStepCategoryEnum;
  stepTypeName: string;
  funnelId = '';
  funnelName = '';
  templateDetails: any;
  templates: any;
  templateList: any;
  breadcrumbs: Breadcrumb[] = [];
  stepPopupActions = StepPopupActions;
  popupTitle = '';
  popupData = {
    name: '',
    is_template: false,
    step_id: null,
    popupType: StepPopupActions.Duplicate,
  };
  searchQuery = '';
  user: User;
  campaignId: number;
  isGlobal: boolean = false;
  isTemplateLoading: boolean = false;
  popupTitleMap = {
    [StepPopupActions.Delete]: 'Delete Template',
    [StepPopupActions.Duplicate]: 'Duplicate Template',
  };
  hoveredTemplateID: string | number = null;
  protected canDeactivateService: CanDeactivateService = CanDeactivateServiceWrapper.getObject();

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    private sideNav: SideNavService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService,
    protected funnelService: FunnelService,
    protected campaignService: CampaignService,
    protected stepService: StepTemplateService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, funnelService, alertService);
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(false);

    this.route.params.subscribe((params) => {
      this.funnelId = params['offer_id'];
      this.offerType = Number(params['offer_type']);
      this.offerName = OfferTypeLabels[this.offerType];
      this.stepType = params['step_type'];
      this.stepTypeName = StepCategoryLabels[Number(this.stepType)];
      this.isGlobal = params['global'] === 'global';
    });
  }

  ngOnInit() {
    this.fetchUser();
    this.fetchCampaign();

    if (this.isGlobal) {
      this.fetchGlobalTemplates();
    } else {
      this.fetchMyTemplates();
    }

    if (this.funnelId) {
      this.fetchFunnel();
    }
    this.canDeactivateService.setFormDirty(false);
    this.generateBreadcrumbs();
  }

  fetchUser() {
    this.userService
      .getCurrent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  fetchCampaign() {
    this.campaignService
      .list(Object.assign({ page: 1, page_size: config.maxPageSize }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.campaignId = data.results[0].id;
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  fetchGlobalTemplates(): void {
    this.isGlobal = true;
    this.loader.show();
    this.isTemplateLoading = true;
    this.stepService.list({ is_global: true }).subscribe(
      (response: any) => {
        this.handleTemplateResponse(response);
      },
      (error) => {
        this.isTemplateLoading = false;
        this.alertService.error(error);
      }
    );
  }

  fetchMyTemplates(): void {
    this.isGlobal = false;
    this.loader.show();
    this.isTemplateLoading = true;
    this.stepService.list({ is_template: true, is_global: false }).subscribe(
      (response: any) => {
        this.handleTemplateResponse(response);
      },
      (error) => {
        this.isTemplateLoading = false;
        this.alertService.error(error);
      }
    );
  }

  private handleTemplateResponse(response: any): void {
    this.loader.show();
    this.templateDetails = response.results;
    this.isTemplateLoading = false;

    if (this.offerType === OfferTypes.Survey || this.offerType === OfferTypes.CustomerPortal) {
      this.breadcrumbs[1].label = `${this.stepTypeName} - Templates`;
      this.breadcrumbs[1].url = [];
    } else {
      this.breadcrumbs[2].label = `${this.stepTypeName} - Templates`;
      this.breadcrumbs[2].url = [];
    }

    this.templates = this.templateDetails.map((template) => ({
      ...template,
      thumbnail: this.getThumbnailUrl(template.thumbnail),
      previewLoading: false,
    }));

    this.templateList = this.templates.map((template) => template);

    this.templateList = this.templateList.filter((template) => template.category === Number(this.stepType));

    this.loader.hide();
  }

  fetchFunnel() {
    this.funnelService.list({}, this.funnelId).subscribe(
      (response) => {
        this.funnelName = response.slug;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ['steps', 'survey'],
        },
        {
          label: 'Choose a Template',
          url: ['steps', this.offerName, 'category', this.offerType, 'template', this.stepType],
        },
      ];
    } else if (this.offerType === OfferTypes.CustomerPortal) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ['steps', this.offerName],
        },
        {
          label: 'Choose a Template',
          url: ['steps', this.offerName, 'category', this.offerType, 'template', this.stepType],
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
          url: ['steps', this.offerName],
        },
        {
          label: '',
          url: ['steps', this.offerName, this.offerType, 'details', this.funnelId],
        },
        {
          label: this.stepTypeName,
          url: ['steps', this.offerName, this.funnelId, 'category', this.offerType, 'folders', this.stepType],
        },
      ];
    }
  }

  selectTemplate(templateId?: string | number): void {
    if (!templateId) {
      if (this.funnelId) {
        this.router.navigate([
          'steps',
          this.offerName,
          this.funnelId,
          'category',
          this.offerType,
          'step',
          this.stepType,
        ]);
      } else {
        this.router.navigate(['steps', this.offerName, 'category', this.offerType, 'step', this.stepType]);
      }
    } else {
      if (this.funnelId) {
        this.router.navigate([
          'steps',
          this.offerName,
          this.funnelId,
          'category',
          this.offerType,
          'step',
          this.stepType,
          'edit',
          false,
          'template',
          templateId,
        ]);
      } else {
        this.router.navigate([
          'steps',
          this.offerName,
          'category',
          this.offerType,
          'step',
          this.stepType,
          'edit',
          false,
          'template',
          templateId,
        ]);
      }
    }
  }

  getStepPreview(e: MouseEvent, id: string | number) {
    e.stopPropagation();
    const template = this.templateList.find((item) => item.id === id);
    template.previewLoading = true;

    this.stepService.get(id).subscribe((resp: any) => {
      if (resp) {
        const static_data = resp.static_data,
          response_static_data = resp.response_data.static_data || null,
          stepDetails = {
            ...resp,
            static_data: {
              ...static_data,
              image: static_data.image ? static_data.image.id : null,
              image_mobile: static_data.image_mobile ? static_data.image_mobile.id : null,
              image_tablet: static_data.image_tablet ? static_data.image_tablet.id : null,
            },
            response_data: {
              ...resp.response_data,
              ...(response_static_data && {
                static_data: {
                  ...response_static_data,
                  image: response_static_data.image ? response_static_data.image.id : null,
                  image_mobile: response_static_data.image_mobile ? response_static_data.image_mobile.id : null,
                  image_tablet: response_static_data.image_tablet ? response_static_data.image_tablet.id : null,
                },
              }),
            },
          };

        this.funnelService.previewStep(this.campaignId, stepDetails).subscribe(
          (data) => {
            window.open(data.toString(), '_blank');
            template.previewLoading = false;
          },
          (error) => {
            this.alertService.error(error);
            template.previewLoading = false;
          }
        );
      }
    });
  }

  openStepPopup(e: MouseEvent, popupType: number, step: any): void {
    this.popupTitle = this.popupTitleMap[popupType] || '';

    this.popupData = {
      ...this.popupData,
      name: step.name || '',
      step_id: step.id || null,
      is_template: true,
      popupType: popupType,
    };

    this.ngxSmartModalService.getModal('stepPopup').open();
  }

  saveStep() {
    this.fetchMyTemplates();
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  closeStepPopup() {
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  setHoveredTemplate(id: string | number) {
    this.hoveredTemplateID = id;
  }
  clearHoveredTemplate() {
    this.hoveredTemplateID = null;
  }

  editTemplate(templateId: string | number) {
    if (this.funnelId) {
      this.router.navigate([
        'steps',
        this.offerName,
        this.funnelId,
        'category',
        this.offerType,
        'step',
        this.stepType,
        'edit',
        true,
        'template',
        templateId,
      ]);
    } else {
      this.router.navigate([
        'steps',
        this.offerName,
        'category',
        this.offerType,
        'step',
        this.stepType,
        'edit',
        true,
        'template',
        templateId,
      ]);
    }
  }

  searchData() {
    if (this.searchQuery) {
      this.templateList = this.templates.filter((template) =>
        template.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    } else {
      this.templateList = this.templates;
    }
  }

  getThumbnailUrl(thumbnail: string | null): string {
    const defaultImage = '/assets/stepAssets/template-thumbnail.svg';
    const timestamp = new Date().getTime(); // Cache buster using current timestamp
    const imageUrl = thumbnail || defaultImage;

    return `${imageUrl}?v=${timestamp}`;
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete]: '/assets/stepAssets/delete-icon.svg',
    [StepPopupActions.Duplicate]: '/assets/stepAssets/duplicate.svg',
  };

  ngOnDestroy(): void {
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(true);
  }
}
