import {DEFAULT_FONT_FAMILY} from './editorConfig';

export const THEMES = {
  'button-themes': {
    'blue-btn': {
      'name': 'Blue Button',
      'styles': {
        'color': '#FFFFFF',
        'border-color': '#007bff',
        'background-color': '#007bff',
        'font-family': DEFAULT_FONT_FAMILY
      }
    },
    'green-btn': {
      'name': 'Green Button',
      'styles': {
        'color': '#FFFFFF',
        'border-color': '#28a745',
        'background-color': '#28a745',
        'font-family': DEFAULT_FONT_FAMILY
      }
    },
    'light-btn': {
      'name': 'Light Button',
      'styles': {
        'color': '#212529',
        'border-color': '#f8f9fa',
        'background-color': '#f8f9fa',
        'font-family': DEFAULT_FONT_FAMILY
      }
    },
    'dark-btn': {
      'name': 'Dark Button',
      'styles': {
        'color': '#FFFFFF',
        'border-color': '#343a40',
        'background-color': '#343a40',
        'font-family': DEFAULT_FONT_FAMILY
      }
    },
    'yellow-btn': {
      'name': 'Yellow Button',
      'styles': {
        'color': '#212529',
        'border-color': '#ffc107',
        'background-color': '#ffc107',
        'font-family': 'Comic Sans MS, cursive, sans-serif'
      }
    },
  }
}
