import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelStepService} from '../_services';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {FunnelStep, QuickLink, QuickLinkType} from '../_models';
import {takeUntil} from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './chatbot-quicklink.component.html',
  selector: 'chatbot-quicklink',
  styleUrls: ['./chatbot-quicklink.component.css']
})
export class ChatbotQuicklinkComponent extends Form implements OnInit, OnChanges {
  @Input('campaignId') campaignId;
  @Input('quickLinks') quickLinks = [];
  @Input('defaultQuickLinks') defaultQuickLinks = [];

  steps: FunnelStep[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    private stepService: FunnelStepService,
  ) {
    super(alertService, router, location);
  }

  get quickLinkForm() {
    return this.form.get('quick_links') as FormArray;
  }

  addQuickLink(quickLink?) {
    this.quickLinkForm.push(new FormGroup({
      id: new FormControl(quickLink ? quickLink.id : null),
      title: new FormControl(quickLink ? quickLink.title : '', Validators.required),
      subtitle: new FormControl(quickLink ? quickLink.subtitle : '', Validators.required),
      step_key: new FormControl(quickLink ? quickLink.step_key : null, Validators.required),
    }));
  }

  removeQuickLink(index: number) {
    this.quickLinkForm.removeAt(index);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      quick_links: new FormArray([])
    });
    super.ngOnInit();
  }

  updateForm() {
    if (this.form) {
      if (!this.quickLinks) {
        this.form.addControl('quick_links', new FormArray([]));
      }
      if (this.defaultQuickLinks) {
        if (this.defaultQuickLinks instanceof Array) {
          this.defaultQuickLinks.forEach((quickLink: QuickLink) => {
            this.addQuickLink(quickLink);
          });
        }
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.campaignId && !changes.campaignId.previousValue && changes.campaignId.currentValue) {
      this.stepService.getGlobalJumpSteps(false, {
        resourcetype: 'VisualFunnel',
        funnel__campaigns: this.campaignId
      })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
              (steps: FunnelStep[]) => {
                this.steps = steps;
              },
              error => {
                this.handleError(error);
              }
          );
    }
    this.updateForm()
  }

  getFormData() {
    return this.quickLinkForm.value;
  }

}
