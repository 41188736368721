import {Component} from '@angular/core';
import {RelatedProductSetNewComponent} from './related-product-set-new.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ExchangeableProductsSetService, RelatedProductSetService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {RelatedProductSet} from '../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'related-product-set-edit',
  templateUrl: './related-product-set-edit.component.html'
})
export class RelatedProductSetEditComponent extends RelatedProductSetNewComponent {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected relatedProductSetService: RelatedProductSetService,
    protected exchangeableProductsSetService: ExchangeableProductsSetService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
  ) {
    super(router, location, route, relatedProductSetService, exchangeableProductsSetService, alertService, formBuilder);
    this.isNew = false
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true
    this.data$.subscribe((data: RelatedProductSet) => {
      if (data) {
        this.loading = false
        this.relatedProductSet = data
        this.category = data.category_id
        if (!this.category) {
          this.category = this.id
        }
        this.form.patchValue({name: data.name})
      }
    })
  }

  getUrlParams() {
    if (this.relatedProductSet) {
      return {type: this.relatedProductSet.id}
    }

    return {}
  }
}
