import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CRMService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CRM, NewOrder} from '../_models';
import {Form} from '../_forms';

@Component({
  moduleId: module.id.toString(),
  selector: 'crm-copy-order',
  templateUrl: './crm-copy-order.component.html'
})
export class CRMCopyOrderComponent extends Form implements OnInit {
  newOrder: NewOrder;
  @Input('crm') crm: CRM;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private crmService: CRMService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      order_number: [null, Validators.required],
      decline_transaction: [false],
      mark_shipped: [false],
      force_rebill: [false],
      copy_pricing: [false]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.newOrder = null;

    if (this.crm && this.form.valid) {
      this.loading = true;

      this.crmService.copyOrder(this.crm.id, this.form.value)
        .subscribe(
          (order: NewOrder) => {
            this.alertService.success('Created new test order.', true);
            this.newOrder = order;
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.handleSubmitError(error);
          });

    }
  }
}
