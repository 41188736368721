import {
  Action,
  ActionType,
  CampaignProductFormData, ConfirmationStepActions,
  ConfirmationStepTextType,
  CRMTypeId,
  CRMTypeName,
  CustomStepCategoryEnum,
  ExchangeableProduct,
  ExecutionTypeEnum,
  Funnel,
  FunnelInput,
  FunnelInputSystemTypes,
  FunnelInputTypeEnum,
  FunnelInputTypeLabels,
  FunnelStep,
  FunnelSystemInputChildTypes,
  getFunnelItemTypeLabel,
  ImageSize,
  IntentConfirmationButtonText,
  IntentConfirmationHeadlineText,
  IntentConfirmationIntroductionText,
  RelatedProduct,
  ReportsFormData,
  StepCategory,
  TimeData,
  TranMatchedStatus
} from '../_models';
import * as moment from 'moment-timezone';

export function plainText(content, keep_line_breaks = false) {
  if (keep_line_breaks) {
    return content.replace(/<(?!br\s*\/?)[^>]+>/g, '');
  }

  return content.replace(/<[^>]*>/g, '');
}

export function formatMoney(number, decPlaces?, decSep?, thouSep?) {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
  decSep = typeof decSep === 'undefined' ? '.' : decSep;
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  const sign = number < 0 ? '-' : '';
  const intVal = parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces));
  const i = String(intVal);
  const j = (i.length) > 3 ? i.length % 3 : 0;

  return sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces ? decSep + Math.abs(number - intVal).toFixed(decPlaces).slice(2) : '');
}

export function getDatePart(part) {
  return ('0' + part.toString()).slice(-2)
}

export function parseISODateString(dateStr: string) {
  //dateStr is in YYYY-MM-DD format
  let ds = dateStr.split('-');

  return new Date(parseInt(ds[0]), parseInt(ds[1]) - 1, parseInt(ds[2]), 0, 0, 0, 0);
}

export function formatDateForQuery(date: Date, includeTime: boolean = false) {
  //query expects a date in UTC YYYY-MM-DD HH:MM:SS format
  let result =  date.getUTCFullYear().toString() +
    '-' + getDatePart(date.getUTCMonth() + 1) +
    '-' + getDatePart(date.getUTCDate())

  if (includeTime) {
    result += ' ' + getDatePart(date.getUTCHours()) +
      ':' + getDatePart(date.getUTCMinutes()) +
      ':' + getDatePart(date.getUTCSeconds())
  }

  return result;
}

export function getPlaceholderImageUrl(size: ImageSize) {
  const sizes = {
    original: 400,
    small: 100,
    medium: 200,
    large: 400
  };
  const sizeString = sizes[size].toString();

  return 'https://via.placeholder.com/' + sizeString + 'x' + sizeString + '?text=No+Image';
}

export function getProductImageUrl(product: RelatedProduct, size: ImageSize = ImageSize.original) {
  let image = product.default_image;

  if (!image && product.images.length) {
    image = product.images[0];
  }

  return image && image.file ? image.file[size] : getPlaceholderImageUrl(size);
}

export function getCampaignProductImageUrl(campaignProduct, size: ImageSize = ImageSize.original) {
  if (campaignProduct.image && campaignProduct.image.file) {
    return campaignProduct.image.file[size];
  }

  return getProductImageUrl(campaignProduct.product, size);
}

export function getOrdinal(n: number) {
  const s = ["th","st","nd","rd"];
  const v = n % 100;

  return n.toString() + (s[(v-20)%10] || s[v] || s[0]);
}

export function tinyMCETTextOnly(editor) {
  //if there's a root wrapper element, remove it and replace all line break html elements with text line breaks
  const body = editor.getBody();

  if (body.children.length === 1) {
    const root = body.children[0];

    if (root.tagName.toLowerCase() === 'div') {
      body.style.cssText = root.style.cssText;
      body.removeChild(root);
      body.innerHTML = root.innerHTML;
    }
  }
}

export function tinyMCESingleLine(editor) {
  editor.on('keydown', function (event) {
    if (event.keyCode == 13)  {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  });
}

export function formatISODate(timestamp) {
  const dt = new Date(timestamp);

  return (dt.getMonth() + 1).toString() + '/' + dt.getDate().toString() + '/' + dt.getFullYear();
}

export function formatISOTimestamp(timestamp, includeSeconds = true) {
  const dt = new Date(timestamp);
  const am_pm = dt.getHours() >= 12 ? 'PM' : 'AM';
  const hours = (dt.getHours() === 0) ? 12 : (dt.getHours() > 12) ? dt.getHours() - 12 : dt.getHours();
  const seconds = includeSeconds ? ':' + ('0' + dt.getSeconds().toString()).slice(-2) : '';

  return (dt.getMonth() + 1).toString() + '/' + dt.getDate().toString() + ' ' + hours.toString() + ':' +
    ('0' + dt.getMinutes().toString()).slice(-2) + seconds + ' ' + am_pm;
}

export function timezoneAwareToUtc(timestamp, timezone) {
  return moment.tz(timestamp, timezone).utc();
}

export function normalizeDateForQuery(date: Date, timezone: string = 'UTC') {
  let thisMoment = timezoneAwareToUtc(date.getTime(), timezone);
  return thisMoment.format(TimeData.DefaultFormat);
}

export function normalizeMomentForQuery(date, timezone = 'UTC') {
  if (typeof date === 'string') {
    return date;
  }
  let thisMoment = timezoneAwareToUtc(date.valueOf(), timezone);
  return thisMoment.format(TimeData.DefaultFormat);
}

export function generateReportsQuery(formData: ReportsFormData) {
  let data = {};
  data['fields'] = [];
  let date_range = {...formData.date_range};
  date_range['startDate'] = normalizeMomentForQuery(date_range['startDate'], formData.timezone);
  date_range['endDate'] = normalizeMomentForQuery(date_range['endDate'], formData.timezone);
  data['date_range'] = date_range;
  let accounts = []
  formData.account.forEach(item => {
    accounts.push(item.id);
  })
  if (accounts) {
    data['accounts'] = accounts;
  }
  let dimensions = formData.dimensions;
  let statistics = formData.statistics;
  let calculations = formData.calculations;
  let exclude = formData.exclude;
  let timeIntervals = formData.timeInterval;

  Object.keys(dimensions).forEach(key => {
    if (dimensions[key]) {
      data['fields'].push(key);
    }
  });

  if(statistics){
    Object.keys(statistics).forEach(key => {
      if (statistics[key]) {
        data['fields'].push(key);
      }
    });
  }

  Object.keys(calculations).forEach(key => {
    if (calculations[key]) {
      data['fields'].push(key);
    }
  });

  Object.keys(exclude).forEach(key => {
    if (exclude[key]) {
      data['fields'].push(key);
    }
  });

  Object.keys(timeIntervals).forEach(key => {
    if (timeIntervals[key]) {
      data['fields'].push(key);
    }
  });

  let filters = {};
  let formFilters = formData.filtersData;
  let selectedFilters = formData.filters;
  Object.keys(formFilters).forEach(key => {
    if (key.endsWith('_negation')) {
      selectedFilters[key] = selectedFilters[key.replace('_negation', '')]
    }
  })
  Object.keys(selectedFilters).forEach(key => {
    if (selectedFilters[key]) {
      if (key.endsWith('comparison')) {
        return;
      }
      let value = formFilters[key];

      if (Array.isArray(value)) {
        if (value.length) {
          filters[key] = [];

          value.forEach(val => {
            filters[key].push(((typeof val === 'object') && ('id' in val)) ? val['id'] : val);
          });
        }
      } else {
        filters[key] = (value && (typeof value === 'object') && ('id' in value)) ? value['id'] : value;
      }

      if (`${key}_comparison` in formFilters && formFilters[`${key}_comparison`] !== null) {
        let comparisonPostfix = formFilters[`${key}_comparison`]
        if (comparisonPostfix === 'range') {
          value = [value, formFilters[key + '_comparison_right']]
        }

        // Here "e" represents the "equals to" model present in comparison options
        if (comparisonPostfix === 'e') {
          comparisonPostfix = ''
        } else {
          comparisonPostfix = '__' + comparisonPostfix;
        }

        delete filters[key];
        key = key + comparisonPostfix
        filters[key] = value;
      }
    }
  });

  data['timezone'] = formData.timezone;
  data['date_filter_type'] = formData.dateFilterType;

  if ('sessionType' in formData) {
    if (formData.sessionType === 'all'){
      filters['session_type'] = ['Support', 'Marketplace']
    }
    else {
      filters['session_type'] = [formData.sessionType];
    }
  }

  if ('spTouched' in formData) {
    if (formData.spTouched === "sp_touched") {
      filters['matched_status'] = [
        TranMatchedStatus.MatchByOrder,
        TranMatchedStatus.MatchBySession,
        TranMatchedStatus.MatchByCustomer
      ]
    }
    else if (formData.spTouched === "sp_not_touched") {
      filters['matched_status'] = [TranMatchedStatus.NoMatch]
    }
  }

  data['filters'] = filters;
  return data;
}

export function generateFilterQuery(formData: CampaignProductFormData) {
  let data = {};
  const accounts = [];
  if (Array.isArray(formData.account)) {
    formData.account.forEach(item => {
      accounts.push(item.id);
    });
  } else {
    accounts.push(formData.account);
  }
  if (accounts) {
    data['accounts'] = accounts;
  }

  if (formData.crm) {
    data['crm'] = formData.crm;
  }

  let formFilters = formData.filtersData;
  let selectedFilters = formData.filters || {};

  Object.keys(selectedFilters).forEach(key => {
    if (selectedFilters[key]) {
      let value = formFilters[key];

      if (value !== null || value !== undefined || value !== '') {
        if (Array.isArray(value)) {
          if (value.length) {
            data[key] = [];
            value.forEach(val => {
              data[key].push(((typeof val === 'object') && ('id' in val)) ? val['id'] : val);
            });

            if (value.length > 1) {
              data[key + '__in'] = data[key].join(',');
              delete data[key];
            } else {
              data[key] = data[key][0];
            }
          }
        } else {
          data[key] = (value && (typeof value === 'object') && ('id' in value)) ? value['id'] : value;
        }
      }
    }
  });

  return data;
}

export function getCRMCustomerUrl(customerId: string, crmType: CRMTypeId | CRMTypeName) {
  let url = null;

  switch (crmType) {
    case CRMTypeId.Konnektive:
    case CRMTypeName.Konnektive:
      url = 'https://crm.konnektive.com/customer/cs/details/?customerId=' + customerId;
      break;
  }

  return url;
}

export function getCRMOrderUrl(orderId: string, crmType: CRMTypeId | CRMTypeName) {
  let url = null;

  switch (crmType) {
    case CRMTypeId.Konnektive:
    case CRMTypeName.Konnektive:
      url = 'https://crm.konnektive.com/customer/cs/orders/?orderId=' + orderId;
      break;
  }

  return url;
}

export function getCRMOrderHtml(orderId: string, actualOrderId: string, crmType: CRMTypeId | CRMTypeName) {
  if (!orderId) {
    return '';
  }
  const url = getCRMOrderUrl(actualOrderId, crmType);

  if (url) {
    return '<a class="blue-link" target="_blank" href="' + url + '">' + orderId + '</a>';
  }

  return orderId;
}

export function getCRMCustomerHtml(customerId: string, crmType: CRMTypeId | CRMTypeName) {
  if (!customerId) {
    return '';
  }
  const url = getCRMCustomerUrl(customerId, crmType);
  if (url) {
    return '<a class="blue-link" target="_blank" href="' + url + '">' + customerId + '</a>';
  }

  return customerId;
}

export function compareExchangeableProducts(list1: ExchangeableProduct[], list2: ExchangeableProduct[]) {
  let listsMatch = true

  for (const item1 of list1) {
    let matchFound = false

    for (const item2 of list2) {
      matchFound = item1.to_campaign_product.id === item2.to_campaign_product.id && item1.quantity === item2.quantity &&
        item1.price === item2.price && item1.shipping_price === item2.shipping_price
      if (matchFound) {break}
    }

    if (!matchFound) {
      listsMatch = false
      break
    }

  }

  return listsMatch
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const isObjectEmpty = (obj: any): boolean => {
  return Object.keys(obj).length === 0;
};

export const downloadFromUrl = (url: string) => {
  let dwldLink = document.createElement("a");
  dwldLink.setAttribute("href", url);
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

export function copyToClipboard(data: string) {
  const el = document.createElement('textarea');
  el.value = data;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

function isConfirmation(type: FunnelInputTypeEnum) {
  return [FunnelInputTypeEnum.Confirmation, FunnelInputTypeEnum.ConfirmationNo].indexOf(type) > -1;
}

export function getConfirmationLabel(text_type: ConfirmationStepTextType, step_type: FunnelInputTypeEnum, funnel: Funnel) {
  let library = IntentConfirmationButtonText;

  if (text_type === ConfirmationStepTextType.Headline) {
    library = IntentConfirmationHeadlineText;
  }

  if (isConfirmation(step_type)) {
    return library[funnel.offer_intent];
  }
  return '';
}

export function generateStep(type: FunnelInputTypeEnum, funnel: Funnel, category: StepCategory = CustomStepCategoryEnum.Custom) {
  const step = {
    name: FunnelInputTypeLabels[type] + ' Step ' + category,
    type: type,
    category: category,
    inputs: [],
    funnel: funnel ? funnel.id : null
  } as FunnelStep;

  if (FunnelInputSystemTypes.indexOf(type) !== -1) {
    step.inputs = [{
      type: type,
      label: getFunnelItemTypeLabel(type, type),
      next_step: null,
    } as FunnelInput];

    if (type in FunnelSystemInputChildTypes) {
      FunnelSystemInputChildTypes[type].forEach(childType => {
        if (childType !== FunnelInputTypeEnum.ShipmentReturnLabel) {
          step.inputs.push({
            type: childType,
            label: getFunnelItemTypeLabel(type, childType),
            next_step: null,
          } as FunnelInput);
        }
      });
    }
  }

  if (isConfirmation(step.type)) {
    step.label = IntentConfirmationHeadlineText[funnel.offer_intent];
    step.is_popup = true
    step.content = IntentConfirmationIntroductionText[funnel.offer_intent]

    step.inputs.forEach((input: FunnelInput) => {
      let labelPrefix = '';
      let actions = []

      if (input.type === FunnelInputTypeEnum.Confirmation) {
        labelPrefix = 'Yes, please';
        actions = ConfirmationStepActions[funnel.offer_intent] || []
      } else if (input.type === FunnelInputTypeEnum.ConfirmationNo) {
        labelPrefix = "No, don't";
      }

      input.label = `${labelPrefix} ${IntentConfirmationButtonText[funnel.offer_intent]}`;
      input.actions = actions;
    })
  }
  return step;
}
