import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {
  AccountService,
  AlertService,
  CampaignProductService,
  CampaignService,
  CampaignTrackingService,
  CRMCampaignService,
  FunnelStepService, MerchantService,
  RefundReportService,
  ReportsConfigService,
  StorageService,
  UserService
} from '../_services';
import {ReportIntent} from '../_models';
import {ReportsAbstractComponent} from './reports-abstract.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {generateReportsQuery} from '../_helpers';
import {takeUntil} from 'rxjs/operators';
import {reportsFieldData} from '../session/sessionFieldData';
import * as moment from "moment-timezone";

@Component({
  moduleId: module.id.toString(),
  selector: "refund-report",
  templateUrl: "./templates/refund-report.component.html",
  styleUrls: ['./styles/reports.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RefundReportComponent extends ReportsAbstractComponent {
  public fields = {
    dimensions: [
      [
        {model: "product_path", label: "Product Path", default: false, disabled: false},
        {model: "step_name", label: "Step Impressions", default: false, disabled: false},
        {model: "cancel_reason", label: "Cancel reason", default: false, disabled: false},
      ],
      [
        {model: "campaign_name", label: "Brand", default: false, disabled: false},
        {model: "input_name", label: "Input Name", default: false, disabled: false},
        {model: "crm_campaign_name", label: "CRM campaign", default: false, disabled: false},
      ],
      [
        {model: "crm_campaign_id", label: "CRM campaign ID", default: false, disabled: false},
        {model: "product_name", label: "Product name", default: false, disabled: false},
        {model: "campaign_product_id", label: "Campaign product id", default: false, disabled: false},
      ],
      [
        {model: "username", label: "Session ID", default: false, disabled: false},
        {model: "customer_id", label: "Customer ID", default: false, disabled: false},
        {model: "order_id", label: "Order ID", default: false, disabled: false},
      ],
    ],
    timeInterval: [
      [
        {model: "hour", label: "Hours", default: false, disabled: false},
        {model: "week", label: "Week", default: false, disabled: false},
        {model: "month", label: "Month", default: false, disabled: false},
        {model: "year", label: "Year", default: false, disabled: false},
      ],
    ],
    statistics: [
      [
        {model: "refund_quantity", label: "Refund quantity", default: false, disabled: false},
        {model: "amount_paid", label: "Total billed", default: false, disabled: false},
      ],
      [

        {model: "refund_amount", label: "Refund amount", default: false, disabled: false},
        {model: "partial_refund_amount", label: "Partial refund amount", default: false, disabled: false},
      ],
      [
        {model: "full_refund_amount", label: "Full refund amount", default: false, disabled: false},
        {model: "customer_count", label: "Customer Quantity", default: false, disabled: false}
      ],
      [
        {model: "orders_count", label: "Orders", default: false, disabled: false},
      ]
    ],
    calculations: [],
    exclude: [
      [
        {model: "exclude_tests", label: "Tests", default: true, disabled: false, isGlobal: false},
        {model: "exclude_hangups", label: "Hangups", default: true, disabled: false, isGlobal: false},
        {model: "exclude_unknowns", label: "Unknowns", default: true, disabled: false, isGlobal: false},
        {model: "exclude_child_sessions", label: "Consolidate Sessions", default: true, disabled: false,
          isGlobal: true},
        {model: "exclude_child_orders", label: "Consolidate Orders", default: true, disabled: false, isGlobal: true}
      ]
    ],

    filters: [
      [
        {model: "product_path", label: "Product Path", default: false, disabled: false},
        {model: "step_slug", label: "Step Impressions", default: "", disabled: false},
      ],
      [
        {model: "cancel_reason", label: "Cancel Reason", default: false, disabled: false},
        {model: "input_name", label: "Input Name", default: false, disabled: false},

      ],
      [
        {model: "campaign_id", label: "Brand", default: false, disabled: false},
        {model: "crm_campaign_id", label: "CRM Campaign", default: false, disabled: false},
      ],
      [
        {model: "product_id", label: "Product", default: false, disabled: false},
      ]
    ],
    filtersData: [
      [
        {model: "product_path", filter: "product_path", type: "multi-select", label: "Product Path", default: "",
          options: []},
        {model: "step_slug", filter: "step_slug", type: "multi-select-with-negation", label: "Step Impressions", default: "",
          options: []},
        {model: "cancel_reason", filter: "cancel_reason", type: "multi-select", label: "Cancel Reason", default: "",
          options: []},
        {model: "input_name", filter: "input_name", type: "multi-select", label: "Input Name", default: "",
          options: []},
        {model: "campaign_id", filter: "campaign_id", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "crm_campaign_id", filter: "crm_campaign_id", type: "multi-select", label: "CRM campaign", default: "", options: []},
        {model: "product_id", filter: "product_id", type: "products", label: "Product", default: "", options: []},
      ]
    ]
  };

  @ViewChild('pathReportsTable', {static: false}) pathReportsTable;

  public summary: any = {}
  public pathReport: any[] = []
  public stepReport: any[] = []

  public commonReportsFields = ['refund_quantity', 'amount_paid', 'refund_amount', 'partial_refund_amount', 'full_refund_amount']
  public summaryFields = ['save_amount'].concat(this.commonReportsFields)
  public pathReportFields = ['product_path'].concat(this.commonReportsFields)
  public stepReportFields = ['step_name'].concat(this.commonReportsFields)

  protected fieldData = reportsFieldData;

  public state = {}

  public dateFilterTypes = [
    {value: 'transaction', text: 'Transactions'},
    {value: 'customer', text: 'Customer'}
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: RefundReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService, campaignService,
      reportsConfigService, trackingSourceService, userService, accountService, campaignProductService,
      crmCampaignService, storageService, stepService, refundReportService, merchantService);
  }

  getDefaultDateRange() {
    return {startDate: moment().subtract(8, 'days'),
      endDate: moment().subtract(1, 'days')}
  }

  getSummary() {
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getSummary(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        if (result) {
          this.summary = result
        }
        this.loading = false
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  getPathReport() {
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getPathRefundsReport(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        if (result) {
          this.pathReport = result
        }
        this.loading = false
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  getStepReport(path, pathReportRow) {
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    query['filters'] = {'product_path': [path]}
    this.reportsService.getStepRefundsReport(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        if (result) {
          this.stepReport = result
          if (pathReportRow) {
            pathReportRow.step_report = result
          }
        }
        this.loading = false
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  getFieldLabel(field: string) : string {
    return this.fieldData[field].label;
  }

  getFieldValue(row, field: string) {
    let value = row[field]
    if ('getter' in this.fieldData[field]) {
      value = this.fieldData[field].getter(row, value);
    }

    return value;
  }

  getSummaryFieldRate(field: string) {
    if (['refund_quantity', 'amount_paid'].indexOf(field) > -1) {
      return ''
    }

    let dividorField = 'amount_paid'
    if (['partial_refund_amount', 'full_refund_amount'].indexOf(field) > -1) {
      dividorField = 'refund_amount'
    }

    let value = this.summary[field]
    if (value !== undefined && value !== null) {
      value = ((value / this.summary[dividorField]) * 100).toFixed(2)
    }

    return value
  }

  initializeStepsFilters(date_range) {
    super.initializeStepsFilters(date_range);
    this.getSummary()
    this.getPathReport()
  }

  onPathReportSelect(event) {
    let row = event.selected[0]
    if (this.pathReportsTable) {
      let productPath = row.product_path
      if (!row.step_report) {
        this.getStepReport(productPath, row)
      }
      this.pathReportsTable.rowDetail.toggleExpandRow(row);
    }
  }
  setState(){
    let formData = this.form.value;
    formData['intent'] = ReportIntent.Refund
    formData['startDate'] = moment(formData.date_range.startDate).format()
    formData['endDate'] = moment(formData.date_range.endDate).format()
    formData['date_range']['startDate'] = moment(formData.date_range.startDate).format()
    formData['date_range']['endDate'] = moment(formData.date_range.endDate).format()

    this.state = {
      'load_as_configuration': true,
      'config': {
        'name': "",
        'user': null,
        'is_global': false,
        'configuration': formData
      }
    }
  }

  loadDetailedPathReport(event, row) {
    event.preventDefault()
    event.stopPropagation()
    const productPath = row.product_path
    if (!productPath) {
      return
    }

    this.form.patchValue({'filters': {'product_path': false}})
    const _this = this

    setTimeout(() => {
      _this.form.patchValue({
        'dimensions': {'product_path': true, 'username': true},
        'statistics': {'refund_quantity': true, 'amount_paid': true, 'refund_amount': true,
          'partial_refund_amount': true, 'full_refund_amount': true},
        'filters': {'product_path': true},
        'filtersData': {'product_path': [{id: productPath, text: productPath}]}}
      )
    }, 100)
    setTimeout(() => {
      _this.getReports()
      window.scrollTo(0, document.body.scrollHeight);
    }, 300)
  }

  selectCustomerMode(event) {
    super.selectCustomerMode(event)
    this.getSummary()
    this.getPathReport()
  }

}
