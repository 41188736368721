import {BaseModel} from "./base-model";
import {Campaign} from "./campaign";
import {ReportsFormData} from "./session";
import {TranMatchedStatus} from "./chargeback";
import {timezoneAwareToUtc} from "../_helpers/functions";
import {TimeData} from "./dashboard";

export interface ReportsUserConfiguration extends BaseModel{
  user: number | string;
  name: string;
  time_interval: string;
  timezone: string;
  configuration: any;
  is_global: boolean;
  legacy: boolean;
  report_type: number;
  link?: string;
}

export interface DashboardState {
  campaign?: Campaign;
  crm_id?: number;
  startDate?: Date;
  endDate?: Date;
  includeTest?: boolean;
  includeHangups?: boolean;
  includeUnknowns?: boolean;
  load_as_configuration?: boolean;
  config?: ReportsUserConfiguration
}
export enum ReportIntent {
  General = 0,
  Refund = 1
}

export let ReportIntentLabels = {};
ReportIntentLabels[ReportIntent.General] = 'General'
ReportIntentLabels[ReportIntent.Refund] = 'Refund'


export let ReportIntentServices  = {}
ReportIntentServices[ReportIntent.General] = 'defaultReportsService'
ReportIntentServices[ReportIntent.Refund] = 'refundReportService'

export const ReportTables = {
  Retention: {
    HOLD_CANCELLATION: 1,
    SUBSCRIPTION_CANCELLATION: 2,
    RMA: 3
  }
}

export enum ReportFilters{
  Product,
  ProductPath,
  StepImpressions,
  CancelReason,
  InputName,
  Brand,
  CRMCampaign,
}

export enum ChargebackReportFilters{
  Merchants = 9
}

export function generateReportsQuery(formData: ReportsFormData) {
  let data = {};
  data['fields'] = [];
  let date_range = {...formData.date_range};
  date_range['startDate'] = normalizeMomentForQuery(date_range['startDate'], formData.timezone);
  date_range['endDate'] = normalizeMomentForQuery(date_range['endDate'], formData.timezone);
  data['date_range'] = date_range;
  let accounts = []
  formData.account.forEach(item => {
    accounts.push(item.id);
  })
  if (accounts) {
    data['accounts'] = accounts;
  }
  let dimensions = formData.dimensions;
  let statistics = formData.statistics;
  let calculations = formData.calculations;
  let exclude = formData.exclude;
  let timeIntervals = formData.timeInterval;

  Object.keys(dimensions).forEach(key => {
    if (dimensions[key]) {
      data['fields'].push(key);
    }
  });

  if(statistics){
    Object.keys(statistics).forEach(key => {
      if (statistics[key]) {
        data['fields'].push(key);
      }
    });
  }

  Object.keys(calculations).forEach(key => {
    if (calculations[key]) {
      data['fields'].push(key);
    }
  });

  Object.keys(exclude).forEach(key => {
    if (exclude[key]) {
      data['fields'].push(key);
    }
  });

  Object.keys(timeIntervals).forEach(key => {
    if (timeIntervals[key]) {
      data['fields'].push(key);
    }
  });

  let filters = {};
  let formFilters = formData.filtersData;
  let selectedFilters = formData.filters;
  Object.keys(formFilters).forEach(key => {
    if (key.endsWith('_negation')) {
      selectedFilters[key] = selectedFilters[key.replace('_negation', '')]
    }
  })
  Object.keys(selectedFilters).forEach(key => {
    if (selectedFilters[key]) {
      if (key.endsWith('comparison')) {
        return;
      }
      let value = formFilters[key];

      if (Array.isArray(value)) {
        if (value.length) {
          filters[key] = [];

          value.forEach(val => {
            filters[key].push(((typeof val === 'object') && ('id' in val)) ? val['id'] : val);
          });
        }
      } else {
        filters[key] = (value && (typeof value === 'object') && ('id' in value)) ? value['id'] : value;
      }

      if (`${key}_comparison` in formFilters && formFilters[`${key}_comparison`] !== null) {
        let comparisonPostfix = formFilters[`${key}_comparison`]
        if (comparisonPostfix === 'range') {
          value = [value, formFilters[key + '_comparison_right']]
        }

        // Here "e" represents the "equals to" model present in comparison options
        if (comparisonPostfix === 'e') {
          comparisonPostfix = ''
        } else {
          comparisonPostfix = '__' + comparisonPostfix;
        }

        delete filters[key];
        key = key + comparisonPostfix
        filters[key] = value;
      }
    }
  });

  data['timezone'] = formData.timezone;
  data['date_filter_type'] = formData.dateFilterType;

  if ('sessionType' in formData) {
    if (formData.sessionType === 'all'){
      filters['session_type'] = ['Support', 'Marketplace']
    }
    else {
      filters['session_type'] = [formData.sessionType];
    }
  }

  if ('spTouched' in formData) {
    if (formData.spTouched === "sp_touched") {
      filters['matched_status'] = [
        TranMatchedStatus.MatchByOrder,
        TranMatchedStatus.MatchBySession,
        TranMatchedStatus.MatchByCustomer
      ]
    }
    else if (formData.spTouched === "sp_not_touched") {
      filters['matched_status'] = [TranMatchedStatus.NoMatch]
    }
  }

  data['filters'] = filters;
  return data;
}

export function normalizeMomentForQuery(date, timezone = 'UTC') {
  if (typeof date === 'string') {
    return date;
  }
  let thisMoment = timezoneAwareToUtc(date.valueOf(), timezone);
  return thisMoment.format(TimeData.DefaultFormat);
}

export function normalizeDateForQuery(date: Date, timezone: string = 'UTC') {
  let thisMoment = timezoneAwareToUtc(date.getTime(), timezone);
  return thisMoment.format(TimeData.DefaultFormat);
}
