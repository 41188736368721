import { Component, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Prism from 'prismjs';
import 'prismjs/components/prism-ruby.min.js';
import 'prismjs/components/prism-python.min.js';
import 'prismjs/components/prism-bash.min.js';
import 'prismjs/components/prism-java.min.js';
import 'prismjs/components/prism-json.min.js';
import 'prismjs/components/prism-markup.min.js';
import {Campaign, CustomDomain} from "../_models";

@Component({
  selector: 'app-iframe-documentation',
  templateUrl: './iframe-documentation.component.html',
  styleUrls: ['./iframe-documentation.component.css']
})
export class IframeDocumentationComponent implements AfterViewInit, OnDestroy {
  @Input('brand') brand: Campaign;
  @Input('domain') domain: CustomDomain;

  private highlightInterval: any;

  constructor(
    @Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit() {
    this.highlightInterval = setInterval(() => {
      Prism.highlightAll();
    }, 100);
  }

  selectedLanguage: string = 'Python'; // Default to Python
  openLanguage(language: string): void {
    this.selectedLanguage = language;
    setTimeout(() => {
      Prism.highlightAll();
    }, 0);
  }

  scrollToElementInModal(elementId: string) {
    const element = this.document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnDestroy() {
    if (this.highlightInterval) {
      clearInterval(this.highlightInterval); // Clear the interval on component destruction
    }
  }

  get apiPath() {
    if (this.domain) {
      return 'custom_domains/' + this.domain.id.toString() + '/start_session/';
    }
    if (this.brand) {
      return 'campaigns/' + this.brand.id.toString() + '/start_session/';
    }
  }
}
