import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageCropComponent implements OnInit {
  public croppingImageIndex: number = 0;
  public imagesData: Array<{original: string, cropped: string, fileName: string, fileType: string}> = [];

  @Input('cropperDimensions') cropperMaxDimensions = {
    width: 400,
    height: 400
  }
  @Input('images') images: File[] = [];
  @Input('maintainAspectRatio') maintainAspectRatio: boolean = true;
  @Output('onImageCropped') onImagesCropped: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output('cancel') onCancel: EventEmitter<any> = new EventEmitter<any>();

  async ngOnInit() {
    let images = []
    for (const image of this.images) {
      if (image) {
        let imageData = {}
        let dataUrl = await this.fileToBase64(image)
        imageData['original'] = dataUrl;
        imageData['cropped'] = dataUrl;
        imageData['fileName'] = image.name
        imageData['fileType'] = image.type
        images.push(imageData)
      }
    }
    if (images.length > 0) {
      this.imagesData = images
    }
  }

  imageCropped(event) {
    let currentImageData = this.imagesData[this.croppingImageIndex];
    currentImageData['cropped'] = event.base64;
    this.imagesData[this.croppingImageIndex] = currentImageData;
  }

  async saveImages() {
    let images: File[] = [];
    for (const data of this.imagesData) {
      let imageFile = await this.base64TOFile(data.cropped, data.fileName, data.fileType);
      images.push(imageFile);
    }

    if (images.length > 0) {
      this.onImagesCropped.emit(images)
    }
  }

  changeIndex(index: number) {
    this.croppingImageIndex = index;
  }

  restoreToOriginal() {
    let currentlyWorkingImage = this.imagesData[this.croppingImageIndex];
    this.imagesData[this.croppingImageIndex].cropped = currentlyWorkingImage.original;
  }

  async cancel() {
    let images: File[] = [];
    for (const data of this.imagesData) {
      let imageFile = await this.base64TOFile(data.original, data.fileName, data.fileType);
      images.push(imageFile);
    }

    if (images.length > 0) {
      this.onImagesCropped.emit(images)
    }
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    })
  }

  async base64TOFile(dataUrl, fileName, fileType): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, {lastModified: new Date().getDate(), type: fileType});
  }
}
