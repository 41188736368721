import handleLinkTraits from '../traits/link-traits';
import {getInputTypes, InputTypes, isValidInputType, Types} from '../index';

const link = editor => {
  const inputTypes = getInputTypes();
  const dType = editor.DomComponents.getType('link');
  const dModel = dType.model;
  const dView = dType.view;
  editor.DomComponents.addType('link', {
    model: dModel.extend({
      defaults: Object.assign({}, dModel.prototype.defaults, {
        editable: true,
        traits: [
        {
          type: 'select-link-type',
          label: 'Type',
          name: 'select-type',
          options: [...inputTypes],
        }],
        attributes: { ctype: Types.NONE, elemtype: InputTypes.LINK },
      }),
      init() {
        this.doStuff();
      },
      doStuff() {
        if (this.getAttributes() && isValidInputType(this.getAttributes().ctype)) {
          const type = this.getAttributes().ctype;
          handleLinkTraits(this, type);
        }
      },
    },
    {
      isComponent: function(el) {
        if (el.tagName === 'A' || el.tagName === 'link' || el.elemtype == InputTypes.LINK) {
          return { type: 'link' };
        }
      },
    }),
    view: dView
  });
};

export default link;
