import { DashboardChartData, InfoCards } from "../../../app/_models";
import {
  DefaultTableData,
  InlineTableData,
} from "../data-visualization/data-table/data-table.component";
import { DoughnutChartData } from "../data-visualization/doughnut-chart/doughnut-chart.component";

/* Cards Data */
export const retentionTopCards: InfoCards = [
  {
    title: "Revenue Saved",
    value: "",
    hint: "The money you retained when customers requested refunds, often through Solvpath's negotiation process involving discounts. It's the difference between their initial payments and what was refunded. ",
  },
  {
    title: "Saves",
    value: "",
    hint: "The number of customers who initially requested to cancel an order, subscriptions, or return an item but were successfully retained.",
  },
  {
    title: "Save Rate",
    value: "",
    hint: "The number of customers saved, divided by the total number of customers who canceled an order, subscription, or returned an item.",
  },
  {
    title: "Customers Lost",
    value: "",
    hint: "The number of customers who canceled their orders and/or subscriptions.",
  },
];

export const retentionMidCards: InfoCards = [
  {
    title: "Orders Saved",
    value: "",
    hint: "The number of orders that were saved or retained when customers attempted to cancel them.",
  },
  {
    title: "Orders Cancelled",
    value: "",
    hint: "The number of orders that were canceled by customers.",
  },
  {
    title: "Subscriptions Saved",
    value: "",
    hint: "The number of subscriptions that were retained when customers attempted to cancel them.",
  },
  {
    title: "Subscriptions Cancelled",
    value: "",
    hint: "The number of subscriptions that were canceled by customers.",
  },
];

/* Charts Data */
export const cancellationData: DoughnutChartData = {
  data: [0, 0, 0, 0, 0, 0],
  noData: false,
  loading: true,
  labels: [
    "Unwanted",
    "Technical issues",
    "Price",
    "Product issue",
    "Support isuues",
    "Shipping",
  ],
  backgroundColors: [
    {
      backgroundColor: [
        "#77C2D8",
        "#18374B",
        "#416277",
        "#7393A6",
        "#C3E6F0",
        "#E3E7EE",
      ],
    },
  ],
};

export const cancellationTableData: InlineTableData = [
  {
    name: "Unwanted",
    color: "#77C2D8",
  },
  {
    name: "Technical issues",
    color: "#18374B",
  },
  {
    name: "Price",
    color: "#416277",
  },
  {
    name: "Product issue",
    color: "#7393A6",
  },
  {
    name: "Support issues",
    color: "#C3E6F0",
  },
  {
    name: "Shipping",
    color: "#E3E7EE",
  },
];

export const summmaryData: DoughnutChartData = {
  data: [0, 0, 0, 0, 0, 0],
  noData: false,
  loading: true,
  total: "0",
  labels: ["Saves", "Retracted Saves", "Dropoffs", "Returns", "Cancels"],
  backgroundColors: [
    {
      backgroundColor: ["#10B981", "#EFC654", "#22B8D7", "#163549", "#EB5C5C"],
    },
  ],
};

export const summaryTableData: InlineTableData = [
  {
    name: "Saves",
    color: "#10B981",
  },
  {
    name: "Retracted Saves",
    color: "#EFC654",
  },
  {
    name: "Dropoffs",
    color: "#22B8D7",
  },
  {
    name: "Returns",
    color: "#163549",
  },
  {
    name: "Cancels",
    color: "#EB5C5C",
  },
];

export const surveys = { data: [], loading: true };
export const downsells = { data: [], loading: true };
export const deflection = {
  data: [
    {
      name: "Saves",
      value: 0,
      hint: "Customers who initially considered canceling or returning an order but opted for a downsell offer, ultimately keeping their order.",
    },
    {
      name: "Retracted Saves",
      value: 0,
      hint: "Customers who took a downsell offer when trying to cancel or return but can still change their minds.",
    },
    {
      name: "Deflected Cancels",
      value: 0,
      hint: "Customers who initially took a downsell offer but eventually canceled their order.",
    },
    {
      name: "Deflected Returns",
      value: 0,
      hint: "Customers who initially took a downsell offer but later decided to return their order.",
    },
    {
      name: "Returns",
      value: 0,
      hint: "Customers who initiated a product return without considering a downsell offer.",
    },
    {
      name: "Cancels",
      value: 0,
      hint: "Customers who canceled their order without considering a downsell offer.",
    },
  ] as DefaultTableData,
  loading: true,
  noData: false,
};

export const watchlist = {
  data: [
    {
      name: "Dispute Risks",
      value: 0,
      hint: "Customers at risk of initiating a dispute based on their risk score.",
    },
    {
      name: "Cancel Drop Offs",
      value: 0,
      hint: "Customers who initiated a cancel request but didn't complete it.",
    },
    {
      name: "Return Drop Offs",
      value: 0,
      hint: "Customers who initiated a return request but did not complete it.",
    },
    {
      name: "Pending Saves",
      value: 0,
      hint: "Customers who accepted a downsell offer when attempting to cancel or return but can still change their minds.",
    },
    {
      name: "Paused Subscriptions",
      value: 0,
    },
    {
      name: "Session Errors",
      value: 0,
      hint: "Customers who encountered an error during their session.",
    },
  ] as DefaultTableData,
  total: "0",
  loading: true,
};

export const pathBreakdowns = {
  data: [
    {
      name: "",
      type: "",
      sessions: 0,
      saved: 0,
    },
  ] as DashboardChartData,
  loading: true,
};

export enum RetentionInfoTop {
  RevenueSaved = 0,
  Saves = 1,
  SaveRate = 2,
  CustomersLost = 3,
}

export enum RetentionInfoMid {
  OrdersSaved = 0,
  OrdersCancelled = 1,
  SubscriptionsSaved = 2,
  SubscriptionsCancelled = 3,
}

export enum RetentionWatchlistFields {
  DisputeRisk = 0,
  CancelDropOff = 1,
  ReturnDropOff = 2,
  DeflectedCustomers = 3,
  PausedSubscriptions = 4,
  SessionErrors = 5,
}

export const retentionHints = {
  summary: {
    cancelled:
      "Customers who canceled their order without considering a downsell offer.",
    retracted:
      "Customers who took a downsell offer when trying to cancel or return but can still change their minds.",
    dropoffs:
      "Customers who initiated a cancel/return request but didn’t complete it.",
    returns:
      "Customers who initiated a product return without considering a downsell offer.",
    saved:
      "Customers who initially considered canceling or returning an order but opted for a downsell offer, ultimately keeping their order.",
  },
  downsell:
    "The percentage of customers who accepted a downsell offer among those who viewed it.",
  survey:
    "The most common reasons provided by customers for canceling or returning an order, based on survey responses.",
  cancel_reason:
    "Reasons provided by customers for canceling their orders, categorized based on their stated reason type.",
  deflection:
    "Overview of customer actions when considering cancellations or returns.",
  path: "Performance analysis of each path in terms of sessions and saves.",
  watchlist: "Customers requiring ongoing monitoring.",
};
