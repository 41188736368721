import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from "./crud.service";

@Injectable()
export class CallCenterActionService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http, 'call_center_actions');
  }
}
