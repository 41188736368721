import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignDomainService, PhoneService, UserService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder} from '@angular/forms';
import {
  CustomDomain,
  Campaign,
  Pager,
  Phone,
  getPhoneLabel,
  PhoneUseTypeEnum,
  User
} from "../_models";
import {takeUntil} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {config} from "../../config/config";

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-domain-phone',
  templateUrl: './campaign-domain-phone.component.html'
})
export class CampaignDomainPhoneComponent extends CrudSavePopupComponent implements OnInit {
  domain: CustomDomain;
  errors: string[] = [];
  @Input('campaign') campaign: Campaign;

  assignedPhones: Phone[] = [];
  phones: Phone[] = [];
  ready = false;

  private originalPhoneAssignments = {};

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected domainService: CampaignDomainService,
              protected alertService: AlertService,
              private phoneService: PhoneService,
              private userService: UserService
  ) {
    super(router, location, route, domainService, alertService);
    this.objectName = 'Custom Domain';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({}));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.domain = this.inputData;
    this.isNew = !this.domain || !this.domain.id;
    this.errors = [];

    forkJoin([
      this.phoneService.list({page: 1, page_size: config.maxPageSize}),
      this.userService.getCurrent()
    ]).subscribe(
      (data: [Pager, User]) => {
          if (data) {
            const allPhones = data[0].results;
            const user = data[1];
            let assignedPhones = [];
            let phones = [];

            allPhones.forEach((phone: Phone) => {
              phone.showProvider = user.is_staff;

              if (!phone.campaign) {
                phones.push(phone);
              } else if (phone.campaign === this.campaign.id) {
                phones.push(phone);

                if (this.domain && phone.domain === this.domain.id) {
                  assignedPhones.push(phone);
                  this.originalPhoneAssignments[phone.id] = true;
                }
              }
            });

            this.assignedPhones = assignedPhones;
            this.phones = phones;
          }

          this.ready = true;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  getPhoneLabel(phone: Phone) {
    return getPhoneLabel(phone);
  }

  close() {
    this.errors = [];
    this.onCancel.emit();
  }

  protected onSaveComplete(data) {
    let observables = [];
    let newPhoneAssignments = {};

    this.assignedPhones.forEach((phone: Phone) => {
      if (!(phone.id in this.originalPhoneAssignments)) { // phone changed from unassigned to assigned
        observables.push(this.phoneService.patch(phone.id, {resourcetype: phone.resourcetype,
          campaign: this.campaign.id, domain: this.id}));
      }

      newPhoneAssignments[phone.id] = true; // map the assigned phone for faster lookup
    });

    this.phones.forEach((phone: Phone) => {
      if ((phone.id in this.originalPhoneAssignments) && !(phone.id in newPhoneAssignments)) {
        // phone assignment changed from assigned to unassigned
        if (window.confirm('Are you sure you want to remove ' + phone.phone + ' from this domain?')) {
          observables.push(this.phoneService.patch(phone.id, {resourcetype: phone.resourcetype, domain: null}));
        }
      }

    });

    // call all the update apis to update the attached phones
    if (observables.length) {
      forkJoin(observables)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.onPostSaveComplete(data);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          }
        );
    } else {
      this.onPostSaveComplete(data);
    }
  }

  protected onPostSaveComplete(data) {
    this.loading = false;
    this.domain = data;
    this.errors = [];
    this.onSave.emit(data);
  }

}
