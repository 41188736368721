export enum ReportTypes {
  PATH_PERFORMANCE = 1,
  REFUND = 2,
  CUSTOM = 3,
  FAQ = 4,
  SESSION_SUMMARY = 5,
  CHARGEBACK = 6,
  MARKETPLACE = 7,
  RETENTION = 8,
  DASHBOARD = 9,
  RETENTION_DASHBOARD = 10,
  PAYMENT_UPDATER = 11,
  SP_CHARGEBACK = 12,
  SUBSCRIPTION_RETENTION = 13,
  ORDER_RETENTION = 14,
  RETURN_RETENTION = 15,
  CHATBOT = 16
}

export enum ReportDimensionCategories {
  Dimensions = 0,
}

export enum ReportDimensions {
  ProductPath = "product_path",
  ProductName = "product_name",
  StepImpressions = "step_name",
  CancelReason = "cancel_reason",
  Brand = "campaign_name",
  InputName = "input_name",
  CRMCampaignName = "crm_campaign_name",
  CRMCampaignID = "crm_campaign_id",
  CampaignProductID = "campaign_product_id",
  SessionID = "username",
  CustomerID = "customer_id",
  OrderID = "order_id",
  Hour = "hour",
  Week = "week",
  Month = "month",
  Year = "year",
  RefundQuantity = "refund_quantity",
  AmountPaid = "amount_paid",
  RefundAmount = "refund_amount",
  ParitalRefundAmount = "partial_refund_amount",
  FullRefundAmount = "full_refund_amount",
  CustomerCount = "customer_count",
  OrdersCount = "orders_count",
  PathChannel = "source",
  TrafficSource = "tracking_source",
  SourceSub1 = "sid1",
  SourceSub2 = "sid2",
  SourceSub3 = "sid3",
  CustomerName = "customer_name",
  CustomerEmail = "email",
  CustomerPhone = "phone",
  Error = "error",
  CRMNote = "note",
  PathName = "path_name",
  VideoRecording = "recording_url",
  Affiliate = "aff_id",
  AffSub1 = "sub1",
  AffSub2 = "sub2",
  AffSub3 = "sub3",
  Country = "country",
  DeviceType = "device_type",
  BrowserName = "browser_name",
  DeviceOSVersion = "os_version",
  DeviceBrand = "mobile_vendor",
  DeviceModel = "mobile_model",
  IPAddress = "ip_address",
  SessionActionResult = "action_result",
  SessionResult = "result",
  SessionActionStatus = "session_action_status",
  SessionQuantity = "session_count",
  FailedTries = "failed_tries",
  Tests = "exclude_tests",
  AllZeroes = "exclude_zeroes",
  AlertSource = "alert_source",
  BillingCycle = "billing_cycle",
  CardBIN = "card_bin",
  CardType = "card_type",
  CardLast4 = "card_last4",
  ChargebackDate = "chargeback_date",
  MerchantID = "merchant_id",
  MIDNumber = "mid_number",
  PaymentMethod = "payment_source",
  RefundDate = "refund_date",
  RequestType = "request_type",
  SolvpathSessionID = "sp_session_id",
  SessionSource = "sp_session_source",
  SolvpathMatchedType = "matched_status",
  TransactionAmount = "amount",
  TransactionID = "transaction_id",
  TransactionDate = "created",
  TransactionType = "transaction_type",
  AlertRatio = "alert_ratio",
  ChargebackRatio = "chargeback_ratio",
  DisputeCount = "dispute_count",
  DsiputeRatio = "dispute_ratio",
  TimePeriod = "time_period",
  HoldPath = "hold_path",
  RMAPath = "rma_path",
  SubscriptionPath = "subscription_path",
}

export enum ChatbotIntents { 
  OTHER = 0,
  CANCEL_ORDER = 1,
  CANCEL_SUBSCRIPTION = 2,
  CHANGE_SHIPPING_FREQUENCY = 3,
  SKIP_A_SHIPMENT = 4,
  CHANGE_BILLING_DATE = 5,
  CHANGE_ORDERED_PRODUCT = 6,
  CHANGE_ORDERED_VARIANT = 7,
  CHANGE_ORDERED_ITEM_QUANTITY = 8,
  RETURN_ORDER = 9,
  EXCHANGE_ORDER = 10,
  REORDER = 11,
  REACTIVATE_SUBSCRIPTION = 12,
  PAUSE_SUBSCRIPTION = 13,
  UPDATE_SHIPPING_INFORMATION = 14,
  UPDATE_PAYMENT_INFORMATION = 15,
  CHECK_ORDER_STATUS = 16,
  TRACK_ORDER = 17,
  TROUBLESHOOT_PRODUCT = 18,
  CHECK_PRODUCT_INFORMATION = 19,
  POLICIES_AND_TERMS = 20,
  CONTACT_SUPPORT = 21,
  MODIFY_ACCOUNT_DETAILS = 22,
  MANAGE_PAYMENT_METHOD = 23,
  TRIAL_RELATED_QUERIES = 24,
  EXTEND_TRIAL_PERIOD = 25
}

export let ChatbotIntentLabels = {}
ChatbotIntentLabels[ChatbotIntents.OTHER] = 'Other'
ChatbotIntentLabels[ChatbotIntents.CANCEL_ORDER] = 'Cancel Order'
ChatbotIntentLabels[ChatbotIntents.CANCEL_SUBSCRIPTION] = 'Cancel Subscription'
ChatbotIntentLabels[ChatbotIntents.CHANGE_SHIPPING_FREQUENCY] = 'Change Shipping Frequency'
ChatbotIntentLabels[ChatbotIntents.SKIP_A_SHIPMENT] = 'Skip a Shipment'
ChatbotIntentLabels[ChatbotIntents.CHANGE_BILLING_DATE] = 'Change Billing Date'
ChatbotIntentLabels[ChatbotIntents.CHANGE_ORDERED_PRODUCT] = 'Change Ordered Product'
ChatbotIntentLabels[ChatbotIntents.CHANGE_ORDERED_VARIANT] = 'Change Ordered Variant'
ChatbotIntentLabels[ChatbotIntents.CHANGE_ORDERED_ITEM_QUANTITY] = 'Change Ordered Item Quantity'
ChatbotIntentLabels[ChatbotIntents.RETURN_ORDER] = 'Return Order'
ChatbotIntentLabels[ChatbotIntents.EXCHANGE_ORDER] = 'Exchange Order'
ChatbotIntentLabels[ChatbotIntents.REORDER] = 'Reorder'
ChatbotIntentLabels[ChatbotIntents.REACTIVATE_SUBSCRIPTION] = 'Reactivate Subscription'
ChatbotIntentLabels[ChatbotIntents.PAUSE_SUBSCRIPTION] = 'Pause Subscription'
ChatbotIntentLabels[ChatbotIntents.UPDATE_SHIPPING_INFORMATION] = 'Update Shipping Information'
ChatbotIntentLabels[ChatbotIntents.UPDATE_PAYMENT_INFORMATION] = 'Update Payment Information'
ChatbotIntentLabels[ChatbotIntents.CHECK_ORDER_STATUS] = 'Check Order Status'
ChatbotIntentLabels[ChatbotIntents.TRACK_ORDER] = 'Track Order'
ChatbotIntentLabels[ChatbotIntents.TROUBLESHOOT_PRODUCT] = 'Troubleshoot Product'
ChatbotIntentLabels[ChatbotIntents.CHECK_PRODUCT_INFORMATION] = 'Check Product Information'
ChatbotIntentLabels[ChatbotIntents.POLICIES_AND_TERMS] = 'Policies and Terms'
ChatbotIntentLabels[ChatbotIntents.CONTACT_SUPPORT] = 'Contact Support'
ChatbotIntentLabels[ChatbotIntents.MODIFY_ACCOUNT_DETAILS] = 'Modify Account Details'
ChatbotIntentLabels[ChatbotIntents.MANAGE_PAYMENT_METHOD] = 'Manage Payment Method'
ChatbotIntentLabels[ChatbotIntents.TRIAL_RELATED_QUERIES] = 'Trial Related Queries'
ChatbotIntentLabels[ChatbotIntents.EXTEND_TRIAL_PERIOD] = 'Extend Trial Period'


export enum ChatbotSentiments {
  NEGATIVE = 0,
  NEUTRAL = 1,
  POSITIVE = 2,
  THREAT = 3
}

export let ChatbotSentimentLabels = {}
ChatbotSentimentLabels[ChatbotSentiments.NEGATIVE] = 'Negative'
ChatbotSentimentLabels[ChatbotSentiments.NEUTRAL] = 'Neutral'
ChatbotSentimentLabels[ChatbotSentiments.POSITIVE] = 'Positive'
ChatbotSentimentLabels[ChatbotSentiments.THREAT] = 'Threat'