import {BaseModel, Category} from './index';

export enum WidgetType {
  Account = 'Widget',
  Campaign = 'CampaignWidget',
  Product = 'ProductWidget',
  CampaignProduct = 'CampaignProductWidget'
}

export interface WidgetVariable {
  name: string;
  slug: string;
  image: string;
}

export interface Widget extends BaseModel {
  resourcetype: WidgetType;
  name: string;
  slug: string;
  content: string;
  style: string;
  campaign: string | number;
  product: string | number;
  campaign_product: string | number;
  enhanced_mode: boolean;
  enhanced_content: string;
  thumbnail: string;
  categories: Category[];
  is_global: boolean;
  enhanced_content_components?: string;
  block_id?: string;
  block_category?: BlockCategory;
  folder?: string | number;
  include_in_copy: boolean;
}

export enum WidgetContentType {
  Product = 'ProductFunnel',
  Visual = 'VisualFunnel',
  Content = 'Content',
}

export enum BlockCategory {
  Default = 0,
  Basic = 1,
  Extra = 2,
  Button = 3
}

export const BlockCategoryLabels = [
  'Default',   // 0
  'Basic',     // 1
  'Extra',  // 2
  'Button',   // 3
];

export enum FolderType {
  Widget = 1
}

export interface Folder extends BaseModel {
  name: string,
  children?: Folder[],
  type: FolderType,
  parent: string | number,
  include_in_copy: boolean,
  is_global: boolean
}
