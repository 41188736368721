import {typeSelectLanguage} from './select-language-type';


const selectLanguage = editor => {
  const bm = editor.BlockManager;
  bm.add('language', {
    category: 'Extra',
    label: `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path class="gjs-block-svg-path" d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"/>
                <path class="gjs-block-svg-path" d="M18.5 13l1.5-2h-3zM4 11.5h11v1H4z"/>
            </svg>
            <div class="gjs-block-label">Select Language</div>`,
    content: { type: typeSelectLanguage },
  });
};
export default selectLanguage;
