import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from "./pagination.service";
import { EmailTemplateTest } from "../_models/index";

@Injectable()
export class EmailTemplateService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'emails');
  }

  test(data: EmailTemplateTest) {
    return this.http.post('emails/test/', data);
  }
}
