import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, AccountService, UserService, PlanService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {Plan, User} from '../_models';
import {CreditCardNewFieldsComponent} from "../payment-source";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './subscription.component.html'
})
export class SubscriptionComponent extends Form implements OnInit, AfterViewInit {
  loading = false;
  plans: Plan[];
  user: User;
  @ViewChild(CreditCardNewFieldsComponent, { static: false }) cardFields: CreditCardNewFieldsComponent;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    private userService: UserService,
    private accountService: AccountService,
    private planService: PlanService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
    this.title = 'Choose your subscription';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      plan: [null, Validators.required]
    });

    this.planService.list({is_active: true})
      .subscribe(
        (plans: Plan[]) => {
          this.plans = plans;
        },
        error => {
          this.handleError(error);
        }
      );

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  ngAfterViewInit() {
    setTimeout(() => this.form.addControl('billingAddress', this.cardFields.form), 0);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      this.cardFields.createToken()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result.token) {
            this.accountService.patch(this.user.account.id,
              {plan: this.form.value.plan, new_payment_source: {resourcetype: 'Card', token: result.token.id}})
              .subscribe(
                data => {
                  this.alertService.success("Your account is now active and ready to use.",
                    true);
                  this.userService.clearCache();
                  this.router.navigate(['/']);
                },
                error => {
                  if (error.status === 400) { //bad request
                    if ('token' in error.error) {
                      this.alertService.error(error.error.token);
                    }
                    else {
                      this.handleSubmitError(error);
                    }
                  }
                  else {
                    this.handleError(error);
                  }

                  this.loading = false;
                });
          } else if (result.error) {
            // Error creating the token
            this.alertService.error(result.error.message);
            this.loading = false;
          }
        });
    }
  }
}
