import {EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ImageService} from '../_services';
import {CrudPagedListComponent} from '../_directives';
import {Image, ImageType} from '../_models';
import {FormBuilder} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';

export abstract class ImageAbstractComponent extends CrudPagedListComponent implements OnInit {

  uploadNewFile = false;
  @Output('select') selection: EventEmitter<any> = new EventEmitter<any>();
  @Output('cancel') cancelSelection: EventEmitter<any> = new EventEmitter<any>();
  @Input('selectedImages') selectedImages: Image[];
  @Input('multiple') multiSelect = false;
  @Input('type') imageType = ImageType.Image;

  public typeFilterOptions = [
    {value: 'is_image', text: 'All'},
    {value: 'is_product_image', text: 'Product'},
  ];
  previewUrl = '';
  public componentId = '';

  protected constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected imageService: ImageService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService
  ) {
    super(router, location, route, imageService, alertService);
    this.title = 'Image Gallery';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      type: ['is_image'],
      name__icontains: ['']
    }));
    super.ngOnInit();
  }

  protected runInitialQuery() {
    this.queryData()
  }

  select(image: Image) {
    if (this.selectedImages) {
      if (this.multiSelect) {
        if (this.isSelected(image)) {
          //image is already selected, so unselect it
          this.selectedImages = this.selectedImages.filter(selectedImage => selectedImage.id != image.id);
        } else {
          this.selectedImages.push(image); //image not selected, so select it
        }
      } else {
        this.selectedImages = [image]; //single selection
        this.selection.emit(image); //notify the parent of the selection
      }
    }
  }

  isSelected(image: Image) {
    return this.selectedImages.some((selectedImage: Image) => {
      return selectedImage.id === image.id;
    });
  }

  getImageUrl(image: Image) {
    if (typeof image.file === 'string') {
      return image.file;
    }

    return image.file.original;
  }

  onUploadStart() {
    this.uploadNewFile = true;
  }

  onUploadCancel() {
    this.uploadNewFile = false;
  }

  save() {
    if (this.multiSelect) {
      this.selection.emit(this.selectedImages); //notify the parent of the selections
    }
  }

  cancel() {
    this.cancelSelection.emit();
  }

  onUploadComplete(images: Image[]) {
    const uploadedImages = this.checkResponseDataType(images);
    uploadedImages.forEach((image: Image) => {
      if (!this._data$.getValue().some(function(elem) {
        return elem.id === image.id;
      })) {
        this._data$.next(this._data$.getValue().concat([image]));
        this.select(image);
      }
    });
    this.uploadNewFile = false;
  }

  checkResponseDataType(images) {
    if (Array.isArray(images)) {
      return images;
    }
    return [images];
  }

  previewImage(image) {
    if (this.imageType === ImageType.Image && this.selection.observers.length === 0) {
      this.previewUrl = this.getImageUrl(image);
      this.modalService.getModal(`userImagePopup-${this.componentId}`).open();
    }
  }

  closePopup() {
    this.modalService.getModal(`userImagePopup-${this.componentId}`).close();
  }

  getExtension(name) {
    return name.substring(name.lastIndexOf('.') + 1, name.length);
  }
}
