import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {InvoiceItem, InvoiceChargeType} from "../_models";
import {formatMoney} from "../_helpers";

@Component({
  moduleId: module.id.toString(),
  selector: 'invoice-items-charge-form',
  templateUrl: './invoice-items-charge.component.html'
})
export class InvoiceItemsChargeComponent extends CrudSavePopupComponent implements OnInit {
  items: InvoiceItem[] = [];
  chargeTypes: { value: string, label: string }[] = [];

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected invoiceService: InvoiceService,
              protected alertService: AlertService
  ) {
    super(router, location, route, invoiceService, alertService);
    this.objectName = 'Invoice';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      type: ['full', Validators.required]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.items = this.inputData;
    let amount_due = 0.00;

    if (this.items) {
      this.items.forEach((item: InvoiceItem) => {
        amount_due += Number(item.amount);
      });

      // rebuild the amount control based off the invoice items
      if ('amount' in this.form.controls) {
        this.form.removeControl('amount');
      }

      this.form.addControl('amount', new FormControl(amount_due,[Validators.required,
        Validators.min(0.01), Validators.max(amount_due)]));

      this.chargeTypes = [
        {value: InvoiceChargeType.Full, label: 'Total Amount Due: $' + formatMoney(amount_due)},
        {value: InvoiceChargeType.Custom, label: 'Other Amount'}
      ];
    }
  }

  isCustomCharge() {
    if (this.form) {
      const control = this.form.get('type');

      if (control) {
        return control.value === InvoiceChargeType.Custom;
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      let amount = this.form.value.amount;  // default to custom amount entered
      let items = [];

      this.items.forEach((item: InvoiceItem) => {
        items.push(item.id);
      });

      if (this.form.value.type === InvoiceChargeType.Full) {
        amount = null; // full amount
      }

      this.invoiceService.create({invoice_items: items, amount: amount})
        .subscribe(
          data => {
            this.alertService.success('Charge Successful.');
            this.onSaveComplete(data);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

}
