import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, AutoResponderService, EmailTemplateService } from '../_services';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { CrudSaveComponent } from '../_directives';
import { AutoResponder, AutoResponderType, EmailResponse, SMSResponse, Response,
  EmailTemplate, EmailTemplateType, Pager } from "../_models";
import { config } from '../../config/config';
import { takeUntil } from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './autoresponder-edit.component.html',
  styleUrls: ['./autoresponder.component.css']
})
export class AutoResponderNewComponent extends CrudSaveComponent implements OnInit {
  responder: AutoResponder;
  defaultType = null;
  responderTypes = [];
  selectedType = null;
  emailTemplates: EmailTemplate[] = [];
  emailTemplatesLoading: boolean = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected responderService: AutoResponderService,
    protected alertService: AlertService,
    protected templateService: EmailTemplateService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, responderService, alertService);
    this.isNew = true;
    this.objectName = 'Autoresponder';
    this.title = "Autoresponder"
    this.responderTypes = [
      {value: AutoResponderType.SMS, 'label': 'SMS'},
      {value: AutoResponderType.Email, 'label': 'Email'}
    ]
  }

  ngOnInit() {
    this.defaultType = this.route.snapshot.params['type'] || null;

    if (this.defaultType) {
      this.defaultType = parseInt(this.defaultType);
    }

    this.form = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required],
      type: [this.defaultType, Validators.required],
      responses: this.formBuilder.array([])
    });

    this.setObjectNameFromType(this.defaultType);
    super.ngOnInit();
    this.selectType(this.defaultType);
    this.loadEmailTemplates()
  }

  private loadEmailTemplates() {
    this.emailTemplatesLoading = true
    this.templateService.list({page: 1, page_size: config.maxPageSize,
      type: EmailTemplateType.AutoResponder})
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (pager: Pager) => {
          this.emailTemplatesLoading = false
          this.emailTemplates = pager.results;
        },
        error => {
          this.emailTemplatesLoading = false
          this.handleError(error);
        });
  }

  protected setObjectNameFromType(type) {
    if (type == AutoResponderType.SMS) {
      this.objectName = 'SMS Autoresponder';
    }
    else if (type == AutoResponderType.Email) {
      this.objectName = 'Email Autoresponder';
    }

    this.title = this.isNew ? 'Add a New ' + this.objectName : 'Change Your ' + this.objectName;
  }

  selectType(type) {
    if (this.isNew) { //only new autoresponders can change type
      if (this.selectedType != type) {
        this.selectedType = type;

        //clear the responses and create a single empty one
        const control = <FormArray>this.form.controls['responses'];

        for (let i = control.length - 1; i >= 0; i--) {
          control.removeAt(i);
        }

        control.push(this.initResponse());
      }
    }
  }

  private isProcessableNumber(n) {
    return n !== null && n !== "" && n !== undefined && !isNaN(Number(n))
  }

  private convertDelayToTime(delay) {
    let time = {days: 0, hours: 0, minutes: 0}
    if (!this.isProcessableNumber(delay)) {
      return time
    }
    delay = Number(delay)
    time['days'] = Math.floor(delay / 86400)
    let remainder = delay % 86400
    time['hours'] = Math.floor(remainder / 3600)
    remainder = remainder % 3600
    time['minutes'] = Math.floor(remainder / 60)
    return time
  }

  private convertTimeToDelay(days, hours, minutes) {
    if (!this.isProcessableNumber(days) || !this.isProcessableNumber(hours) ||
      !this.isProcessableNumber(minutes)) {
      return 0
    }
    return (Number(days) * 86400) + (Number(hours) * 3600) + (Number(minutes) * 60)
  }

  protected initResponse(response?: Response) {
    let group: FormGroup;

    if (this.selectedType == AutoResponderType.SMS) {
      const smsResponse = <SMSResponse>response;
      let timeData = this.convertDelayToTime(smsResponse ? smsResponse.delay : null)
      group = this.formBuilder.group({
        id: [smsResponse ? smsResponse.id : null],
        days: [timeData.days, null],
        hours: [timeData.hours, null],
        minutes: [timeData.minutes, null],
        message: [smsResponse ? smsResponse.message : null, Validators.required],
        message_after_hours: [smsResponse ? smsResponse.message_after_hours : null, null]
      });
    }
    else if (this.selectedType == AutoResponderType.Email) {
      const emailResponse = <EmailResponse>response;
      let timeData = this.convertDelayToTime(emailResponse ? emailResponse.delay : null)
      group = this.formBuilder.group({
        id: [emailResponse ? emailResponse.id : null],
        days: [timeData.days, null],
        hours: [timeData.hours, null],
        minutes: [timeData.minutes, null],
        template: [emailResponse ? emailResponse.template : null, Validators.required],
        template_after_hours: [emailResponse ? emailResponse.template_after_hours : null, null]
      });
    }

    return group;
  }

  addResponse(response?: Response) {
    const control = <FormArray>this.form.controls['responses'];
    control.push(this.initResponse(response));
  }

  removeResponse(index: number) {
    // remove address from the list
    const control = <FormArray>this.form.controls['responses'];
    control.removeAt(index);
  }

  get responseControls() {
    return <FormArray>this.form.get('responses')['controls'];
  }

  emailTemplatesChanged() {
    this.loadEmailTemplates()
  }

  protected getFormData(): any {
    let value = super.getFormData()
    let responses = []
    value.responses.forEach(response => {
      response.delay = this.convertTimeToDelay(response.days, response.hours, response.minutes)
      responses.push(response)
    })
    value.responses = responses
    return value
  }
}
