import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {AlertService, PhoneService, AddressService, CampaignService, CampaignDomainService} from '../_services';
import { FormBuilder, Validators } from '@angular/forms';
import {Address, Pager, Phone, PhoneUseTypeEnum, CustomDomain, PhoneStatusLabels} from "../_models";
import {EMPTY, forkJoin} from "rxjs";
import { mergeMap } from "rxjs/operators";
import { config } from '../../config/config';
import {PhoneNewComponent} from "./phone-new.component";


@Component({
  moduleId: module.id.toString(),
  templateUrl: './phone-edit.component.html'
})
export class PhoneEditComponent extends PhoneNewComponent implements OnInit {
  phone: Phone;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected phoneService: PhoneService,
    protected addressService: AddressService,
    protected campaignService: CampaignService,
    protected domainService: CampaignDomainService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService
  ) {
    super(router, location, route, phoneService, addressService, campaignService, domainService, formBuilder,
      alertService);
    this.isNew = false;
    this.title = 'Edit Your Phone Number';
    this.addNewAddress = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.formBuilder.group({
      id: [null],
      phone: [null, Validators.required],
      type: [null, Validators.required],
      campaign: [null],
      domain: [null],
      address_id: [null, Validators.required],
      use_type: [PhoneUseTypeEnum.VoiceAndSMS, Validators.required],
      resourcetype: [null],
    });

    this.data$.pipe(mergeMap((phone: Phone) => {
      if (phone) {
        this.form.patchValue(phone);
        this.phone = phone;

        if (phone.address) {
          this.form.patchValue({address_id: (<Address>phone.address).id})
        }

        return forkJoin([
          this.campaignService.list({page: 1, page_size: config.maxPageSize}),
          this.domainService.list(),
          this.addressService.list({page: 1, page_size: config.maxPageSize}),
        ]);
      }

      return EMPTY;
    })).subscribe(
      (data: [Pager, CustomDomain[], Pager]) => {
        if (data) {
          const campaigns = data[0].results; // campaign results already sorted by name
          const domains = data[1];
          this.buildSelections(campaigns, this.phone.campaign, domains, this.phone.domain);
          this.addresses = data[2].results;
        }
      }
    );
  }

  get status() {
    return this.phone ? PhoneStatusLabels[this.phone.status] : '';
  }
}
