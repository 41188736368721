import {applyTheming, resetTheming} from '../../../web-builder/theming';
import {InputTypes} from '../index';
import {contains} from 'underscore';

const validTypes = ['text', InputTypes.LINK, InputTypes.BUTTON, InputTypes.RADIO_BUTTON];
const appendTypes = ['text'];
export let selectedThemeValue: any = 0;
export let previousSelectedThemeValue: any = 0;
export const resetSelectedThemeValue = () => {
  selectedThemeValue = 0;
};

export enum Theme {
  Apply = 'apply',
  Reset = 'reset',
}

export const insertVariableCommand = {
  run: (editor, options) => {
    const modalContent = document.createElement('div');
    modalContent.style.display = 'flex';
    modalContent.style.alignItems = 'center;';
    const saveButton = document.createElement('button');
    saveButton.innerHTML = 'Add';
    saveButton.className = 'gjs-btn-prim modal-save-btn';
    saveButton.type = 'button';
    saveButton.onclick = () => {
      event.preventDefault();
      const variableDropdown: any = document.getElementById('variableDropdown');
      if (variableDropdown.options && variableDropdown.options.length) {
        const variable = variableDropdown.options[variableDropdown.selectedIndex].value;
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          const type = selectedComponent.getAttributes().elemtype;
          if (validTypes.includes(type)) {
            if (appendTypes.includes(type)) {
              selectedComponent.addAttributes({label: `${variable}`});
            }
            selectedComponent.set('path-name', `${variable}`);
          }
          selectedComponent.components().add(`${variable}`);
        } else {
          editor.addComponents(`<span>${variable}</span>`);
        }
      }
      removeActiveClass(options.attributes.attributes.id);
      editor.Modal.close();
    };

    const variablesDropdown = document.createElement('select');
    variablesDropdown.setAttribute('id', 'variableDropdown');
    variablesDropdown.className = 'gjs-clm-states';
    Object.keys(options.attributes.attributes.opt || {}).forEach(key => {
      const option = document.createElement('option');
      option.setAttribute('value', `${options.attributes.attributes.opt[key].value}`);
      option.appendChild(document.createTextNode(options.attributes.attributes.opt[key].text));
      variablesDropdown.appendChild(option);
    });

    modalContent.appendChild(variablesDropdown);
    modalContent.appendChild(saveButton);
    editor.Modal.setTitle('Add variable').setContent(modalContent).open().onceClose(() => {
      const element = document.getElementById(options.attributes.attributes.id);
      element.click();
    });
  },
  stop: (editor, options) => {
    removeActiveClass(options.attributes.attributes.id);
  }
};

export const themeCommand = {
  run: (editor, options) => {

    const modalContent = document.createElement('div');
    modalContent.style.display = 'flex';
    modalContent.style.alignItems = 'center;';
    const saveButton = document.createElement('button');
    saveButton.innerHTML = 'Select';
    saveButton.className = 'gjs-btn-prim modal-save-btn';
    saveButton.type = 'button';
    let themeName: string;
    saveButton.onclick = () => {
      event.preventDefault();
      const themeDropdown: any = document.getElementById('themeDropdown');

      if (themeDropdown.options && themeDropdown.options.length) {
        const value: string = themeDropdown.options[themeDropdown.selectedIndex].value;
        themeName = themeDropdown.options[themeDropdown.selectedIndex].text;
        if (value !== '') {
          previousSelectedThemeValue = selectedThemeValue;
          selectedThemeValue = value;
          options.attributes.attributes.update_theme(value);
          const styles = options.attributes.attributes.styles;
          let themeStyle: any;
          let prevthemeStyle: any;
          for (let i = 0; i < styles.length; i++) {
            if (styles[i].id === Number(value)) {
              themeStyle = styles[i];
            } else if (styles[i].id === Number(previousSelectedThemeValue)) {
              prevthemeStyle = styles[i];
            }
          }

          let models = getAllComponents(editor.getComponents().models, true);
          for (let i = 0; i < models.length; i++) {
            theming(models[i], themeStyle, editor, Theme.Apply, prevthemeStyle);
          }
        } else {
          selectedThemeValue = 0;
          options.attributes.attributes.update_theme(value);
        }
        if (value !== '') {
          const themeButton: any = document.getElementById('select-theme-id');
          themeButton.innerHTML = '<div style="font-family: Helvetica, sans-serif; font-weight: 600;">&nbsp' + themeName + '</div>';
        }

      }
      removeActiveClass(options.attributes.attributes.id);
      editor.Modal.close();
    };


    const themesDropdown = document.createElement('select');
    themesDropdown.setAttribute('id', 'themeDropdown');
    themesDropdown.className = 'gjs-clm-states';
    Object.keys(options.attributes.attributes.opt || {}).forEach(key => {
      const option = document.createElement('option');
      option.setAttribute('value', `${options.attributes.attributes.opt[key].value}`);
      option.appendChild(document.createTextNode(options.attributes.attributes.opt[key].text));
      themesDropdown.appendChild(option);
    });

    modalContent.appendChild(themesDropdown);
    themesDropdown.value = selectedThemeValue;
    modalContent.appendChild(saveButton);
    editor.Modal.setTitle('Select Theme').setContent(modalContent).open().onceClose(() => {
      removeActiveClass(options.attributes.attributes.id);
      if (selectedThemeValue !== '') {
        const themeDropdown: any = document.getElementById('themeDropdown');
        if (themesDropdown.selectedIndex >= 0) {
          themeName = themeDropdown.options[themeDropdown.selectedIndex].text;
          const themeButton: any = document.getElementById('select-theme-id');
          themeButton.innerHTML = '<div style="font-family: Helvetica, sans-serif; font-weight: 600;">&nbsp' + themeName + '</div>';
        }
      }
      editor.Modal.close();
    });
  },
};

export const getAllComponents = (models, checkValidType = false) => {
  let allModels = [];
  if (models) {
    allModels.push(...models);
    for(const child of models){
      if(child.get('components').models.length > 0){
        allModels = allModels.concat(getAllComponents(child.get('components').models));
      }
    }
  }
  if (checkValidType) {
    allModels = allModels.filter((model) => checkType(model));
  }
  return allModels;
};

export const checkType = (model) => {
  const { type } = model.attributes;
  return type === InputTypes.BUTTON || type === InputTypes.LINK || type === InputTypes.SELECT_LANGUAGE ||
    type === InputTypes.BACK_BUTTON || type === InputTypes.MAIN_MENU || type === InputTypes.USER_DROPDOWN ||
    type === InputTypes.LOGOUT_BUTTON || type === InputTypes.HEADER_LOGO;
};

export const findChildModel = (model, themeStyle, editor, themingValue, prevThemeStyle) => {
  const isValidType = checkType(model);
  if (isValidType) {
    theming(model, themeStyle, editor, themingValue, prevThemeStyle);
  } else {
    const childModel = model.components().models;
    if (childModel) {
      for (let i = 0; i < childModel.length; i++) {
        findChildModel(childModel[i], themeStyle, editor, themingValue, prevThemeStyle);
      }
    }
  }
};

export const theming = (model, themeStyle, editor, themingValue: string, prevThemeStyle) => {
  const state = editor.SelectorManager.getState();
  if (state !== '') {
    editor.SelectorManager.setState('');
  }
  if (themingValue === Theme.Apply) {
    applyTheming(model, themeStyle, editor, prevThemeStyle);
  } else if (themingValue === Theme.Reset) {
    resetTheming(model, themeStyle, editor, prevThemeStyle);
  }
};

const removeActiveClass = (id: string) => {
  const element = document.getElementById(id);
  element.classList.remove('gjs-pn-active', 'gjs-four-color');
};

export const getCss = (editor, id) => {
  const style = editor.CssComposer.getRule(`#${id}`);
  const hoverStyle = editor.CssComposer.getRule(`#${id}:hover`);
  const activeStyle = editor.CssComposer.getRule(`#${id}:active`);
  let styles = '';
  if (style) {
    styles = style.toCSS();
    if (hoverStyle) {
      styles += ' ' + hoverStyle.toCSS();
    }
    if (activeStyle) {
      styles += ' ' + activeStyle.toCSS();
    }
  }
  return styles;
};

export const findComponentStyles = (editor,selected) => {
  let css ='';
  if (selected){
    const childModel =  selected.components().models;
    if (childModel) {
      for (const model of childModel) {
        css = css + findComponentStyles(editor,model)
      }
      return css + getCss(editor, selected.getId());
    }
    return getCss(editor, selected.getId());
  }
  return css;
};

export const getBlockMedia = (block_category_name) => {
  if (block_category_name == 'Button') {
    return `
    <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
      <rect class="gjs-block-svg-path" x="4" y="11.5" width="16" height="1"></rect>
    </svg>`
  }
  else if (block_category_name == 'Extra') {
    return `
    <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
      <rect class="gjs-block-svg-path" x="15" y="10" width="5" height="1"></rect>
      <rect class="gjs-block-svg-path" x="15" y="13" width="5" height="1"></rect>
      <rect class="gjs-block-svg-path" x="15" y="11.5" width="5" height="1"></rect>
    </svg>`
  }
  return `<div class="gjs-fonts gjs-f-b1 gjs-one-bg gjs-four-color-h" style="display:inline-block;"></div>`
};

export const createBlockTemplate = (editor, selected, data) => {
  const bm = editor.BlockManager;
  const blockId = data.blockId;
  const name = data.name;
  const block_category_name = data.block_category_name;

  let elementHTML = selected.getEl().outerHTML;
  let preHtml = elementHTML.substring(0, elementHTML.indexOf(' '));
  let postHtml = elementHTML.substring(elementHTML.indexOf(' ') + 1);
  preHtml += ` custom_block_template=true blockId="${blockId}" `;

  const blockCss = findComponentStyles(editor,selected);
  const css = `<style>${blockCss}</style>`;

  const elementHtmlCss = preHtml + postHtml + css;

  const media = getBlockMedia(block_category_name);
  bm.add(blockId,{
    category: block_category_name,
    attributes: {custom_block_template:true},
    label:  `<p>${name}</p><button type="button" class="deleteBlock" blockId=${blockId}><i class="fa fa-trash" aria-hidden="true"></i></button>`,
    media: media,
    content: elementHtmlCss
  });
};

const processCopies = (model, elClasses, className, alternateClassName = null) => {
  const classes = model.getClasses();
  if (!classes.includes(className)) {
    if (alternateClassName) {
      if (!classes.includes(alternateClassName)) {
        model.remove();
      } else {
        let attributes = model.getAttributes();
        attributes.device_linked = true;
        delete attributes['parentId'];
        delete attributes['newUnlink'];
        model.setAttributes(attributes);
        model.removeClass(alternateClassName);
      }
    } else {
      model.remove();
    }
  } else {
    let attributes = model.getAttributes();
    attributes.device_linked = true;
    delete attributes['parentId'];
    delete attributes['newUnlink'];
    model.setAttributes(attributes);
    model.removeClass(className);
  }
};

export const fixLinkUnlinkCopies = (element, initialElement = null, skipAlternateClass = false) => {
  const elClasses = (initialElement || element).getClasses();
  const models = [...element.components().models];
  if (models.length > 0) {
    for (let i = 0; i < models.length; i++) {
      let model = models[i];
      const attrs = model.getAttributes();
      if (attrs.parentId) {
        if (elClasses.includes('gjs-desk-comp')) {
          processCopies(model, elClasses, 'gjs-desk-comp');
        } else if (elClasses.includes('gjs-tablet-comp')) {
          processCopies(model, elClasses, 'gjs-tablet-comp');
        } else if (elClasses.includes('gjs-tablet-small-comp')) {
          processCopies(model, elClasses, 'gjs-tablet-small-comp', skipAlternateClass ? null : 'gjs-desk-comp');
        } else if (elClasses.includes('gjs-mobile-comp')) {
          processCopies(model, elClasses, 'gjs-mobile-comp');
        } else if (elClasses.includes('gjs-mobile-small-comp')) {
          processCopies(model, elClasses, 'gjs-mobile-small-comp', skipAlternateClass ? null : 'gjs-mobile-comp');
        }
      }

      fixLinkUnlinkCopies(model, element, skipAlternateClass)
    }
  }
  return
};

// Component wise link unlink functionality
export const copyComponent = (editor, clp, className, parentId, desktop=true, skipFixingComponents = false) => {
  const copyable = clp.filter(cop => cop.get('copyable'));
  let newComponent;
  copyable.forEach(element => {
    let elementAttr = element.getAttributes();
    elementAttr.device_linked = false;
    element.setAttributes(elementAttr);
    if (!element.getAttributes().parentId){
      element.addAttributes({parentId: `${parentId}`});
      element.addAttributes({newUnlink: true});
    }
    if (!skipFixingComponents) {
      fixLinkUnlinkCopies(element);
    }
    const elCollection = element.collection;
    const elCollectionIndex = elCollection.indexOf(element) + 1;
    if (contains(clp, element) && element.get('copyable')) {
      newComponent = elCollection.add(element.clone(), {at: elCollectionIndex});
    } else {
      newComponent = elCollection.add(copyable.map(cop => cop.clone()), {at: elCollectionIndex});
    }
    if (newComponent.getClasses().includes('gjs-desk-comp')) {
      newComponent.removeClass('gjs-desk-comp');
    }
    if (!element.getClasses().includes('gjs-desk-comp') && desktop){
      element.addClass('desk-comp');
    }
    newComponent.addClass(className);

    editor.trigger('component:paste', newComponent);
  });
  return newComponent;
};

export const linkUnlinkComponent = (editor, selected, skipFixingComponents = false) => {
  if (selected.getAttributes().device_linked === true) {
    const em = editor.getModel();
    const models = [...editor.getSelectedAll()];
    const parentId = `parentId-${Date.now()}`;
    models.length && em.set('clipboard', models);
    const clp = em.get('clipboard');
    if (clp) {
      let newComponents = [];
      newComponents.push(copyComponent(editor, clp, 'tablet-comp', parentId, true, skipFixingComponents));
      newComponents.push(copyComponent(editor, clp, 'tablet-small-comp', parentId, true, skipFixingComponents));
      newComponents.push(copyComponent(editor, clp, 'mobile-comp', parentId, true, skipFixingComponents));
      newComponents.push(copyComponent(editor, clp, 'mobile-small-comp', parentId, true, skipFixingComponents));

      if (skipFixingComponents && newComponents.length) {
        for(let component of newComponents){
          fixLinkUnlinkCopies(component, null, true);
        }
      }

      fixLinkUnlinkCopies(selected);
      const cssComposer = editor.CssComposer;
      applyLinkUnlinkCssRules(cssComposer);

    }
  } else {
    const models = [...editor.getSelectedAll()];
    for (let i = 0; i < models.length; i++) {
      let elementAttr = models[i].getAttributes();
      elementAttr.device_linked = true;
      models[i].setAttributes(elementAttr);

      const parentId = models[i].getAttributes().parentId;
      let linkedComponents = editor.getWrapper().find(`[parentId=${parentId}]`);
      let compClasses = models[i].getClasses();
      compClasses.forEach(className => {
        if (className === 'gjs-desk-comp') {
          for (let j =0; j< linkedComponents.length; j++){
            let model = linkedComponents[j];
            if (!model.getClasses().includes('gjs-desk-comp')) {
              model.remove();
            }
          }
          models[i].removeClass('gjs-desk-comp');
          models[i].removeAttributes(['parentId', 'newUnlink']);
          return;
        } else if (className === 'gjs-mobile-comp' || className === 'gjs-tablet-comp' || className === 'gjs-tablet-small-comp' || className === 'gjs-mobile-small-comp') {
          for (let j =0; j< linkedComponents.length; j++){
            let model = linkedComponents[j];
            let attributes = model.getAttributes();
            if (model.getClasses().includes('gjs-desk-comp')) {
              attributes.device_linked = true;
              model.setAttributes(attributes);
              model.removeClass('gjs-desk-comp');
              model.removeAttributes(['parentId', 'newUnlink'])
            }
            if (model.getClasses().includes('gjs-mobile-comp') || model.getClasses().includes('gjs-tablet-comp') || model.getClasses().includes('gjs-tablet-small-comp') || model.getClasses().includes('gjs-mobile-small-comp')) {
              model.remove();
            }
          }
          return;
        }
      });
    }
  }
};

export const applyLinkUnlinkCssRules = (cssComposer) => {
  cssComposer.setRule(`.gjs-desk-comp`, {display: 'none'});
  cssComposer.setRule(`.gjs-tablet-comp`, {display: 'none'});
  cssComposer.setRule(`.gjs-tablet-small-comp`, {display: 'none'});
  cssComposer.setRule(`.gjs-mobile-comp`, {display: 'none'});
  cssComposer.setRule(`.gjs-mobile-small-comp`, {display: 'block'});

  // hide small mobile & display large mobile
  cssComposer.setRule(`.gjs-mobile-small-comp`,
    {display: 'none'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 375px)'
    }
  );

  cssComposer.setRule(`.gjs-mobile-comp`,
    {display: 'block'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 375px)'
    }
  );

  // hide large mobile & display small tablet
  cssComposer.setRule(`.gjs-mobile-comp`,
    {display: 'none'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 575px)'
    }
  );

  cssComposer.setRule(`.gjs-tablet-small-comp`,
    {display: 'block'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 575px)'
    }
  );

  // hide small tablet & display large tablet
  cssComposer.setRule(`.gjs-tablet-small-comp`,
    {display: 'none'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 768px)'
    }
  );

  cssComposer.setRule(`.gjs-tablet-comp`,
    {display: 'block'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 768px)'
    }
  );

  // hide large tablet & display large tablet
  cssComposer.setRule(`.gjs-tablet-comp`,
    {display: 'none'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 992px)'
    }
  );

  cssComposer.setRule(`.gjs-desk-comp`,
    {display: 'block'},
    {
      atRuleType: 'media screen and',
      atRuleParams: '(min-width: 992px)'
    }
  );
};

export const updateVideoTraits = (id, model) => {
  const attr = model.getAttributes();
  if(model.attributes.auto || model.attributes.src.search("auto") !== -1) {
    model.attributes.auto = 1;
  }
  if(model.attributes.loop) {
    delete attr.loop;
    model.attributes.loop = 1;
  }
  if(model.attributes.controls) {
    delete attr.controls;
  } else {
    model.attributes.controls = 0;
  }
  model.setAttributes(attr);
};

export const videoTraits = (model) =>{
  const id = model.getId();
  if (model.attributes.type === InputTypes.VIDEO) {
    updateVideoTraits(id, model);
  } else {
    const childModel = model.attributes.components.models;
    if (childModel) {
      for (let i = 0; i < childModel.length; i++) {
        if (childModel[i].attributes.type === InputTypes.VIDEO) {
          updateVideoTraits(id, childModel[i]);
        } else {
          videoTraits(childModel[i]);
        }
      }
    }
  }
};

export const removeDuplicateCss = (editor, model) => {
  // we did this to ignore duplicate css
  const id = model.getId();
  const toRemove = editor.Css.getRules(`#${id}`);
  const removeRules = toRemove.filter(rule=> rule.attributes.atRuleType === 'media' && rule.attributes.mediaText === '(max-width: 575px)' && rule.attributes.state === '');
  let css = {};
  if(removeRules.length > 0) {
    const retainRule = removeRules.pop();
    for(let i = 0; i<removeRules.length; i++) {
      for (let property in removeRules[i].attributes.style) {
        if(retainRule.attributes.style.hasOwnProperty(property)) {
          css[property] = removeRules[i].attributes.style[property];
        }
      }
    }
    editor.Css.remove(removeRules);
    if(css) {
      css = {
        ...css,
        ...retainRule.attributes.style
      };
      editor.CssComposer.setRule(`#${id}`, css, {
        atRuleType: 'media',
        atRuleParams: '(max-width: 575px)',
      });
    }
  }
  const childModel = model.attributes.components.models;
  if(childModel) {
    for(let i = 0; i< childModel.length; i++) {
      removeDuplicateCss(editor, childModel[i])
    }
  }
};

export const loadComponents = (editor, inputData) => {
  let content, parseInvalidContent = false;
  try {
    JSON.parse(inputData.enhanced_content_components);
    content = inputData.enhanced_content_components;
    content = content.replace(/"autoplay":1/gim, '"auto":1');
    editor.Components.load({ components: content });
  } catch (error) {
    content = inputData.enhanced_content.replace(/autoplay=1/gim, "auto=1");
    content = JSON.parse(JSON.stringify(content));
    parseInvalidContent = true;
  }

  if (parseInvalidContent) {
    try {
      JSON.parse(content);
      editor.Components.load({ components: content });
    } catch(error) {
      editor.setComponents(content);
    }
  }
  editor.CssComposer.getAll().reset();
  let style = inputData.style || '';
  let bodyStyles = inputData.style.split('body{');
  let bodyStyle = '';
  if (bodyStyles.length > 1){
    for (let i=1; i<bodyStyles.length; i++){
      let body = bodyStyles[i].split('}')[0];
      bodyStyle += body;
      style = style.replace('body{' + body + '}','');
    }
  }
  editor.setStyle(style);
  if (bodyStyle){
    editor.getWrapper().setStyle(bodyStyle);
  }
  const model = editor.getComponents().models;
  for (let i = 0 ; i <  model.length; i++) {
    videoTraits(model[i]);
    removeDuplicateCss(editor, model[i]);
  }
};
