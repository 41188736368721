import { Component, OnInit } from "@angular/core";
import { InfoCards, User } from "../../../app/_models";
import { StorageService } from "../../../app/_services";
import {
  cancellationData,
  retentionTopCards,
  retentionMidCards,
  cancellationTableData,
  summmaryData,
  summaryTableData,
  retentionHints,
  surveys,
  downsells,
  deflection,
  watchlist,
  pathBreakdowns,
} from "../retention/retention.data";
import { DoughnutChartData } from "../data-visualization/doughnut-chart/doughnut-chart.component";
import { InlineTableData } from "../data-visualization/data-table/data-table.component";

@Component({
  selector: "app-retention",
  templateUrl: "./retention.component.html",
  styleUrls: ["./../dashboard-section.scss"],
})
export class RetentionComponent implements OnInit {
  currentUser: User;

  /* Cards Data */
  retentionTopCards: InfoCards = retentionTopCards;
  retentionMidCards: InfoCards = retentionMidCards;

  /* Charts Data */
  summaryData: DoughnutChartData = summmaryData;
  summaryTableData: InlineTableData = summaryTableData;
  cancellationData: DoughnutChartData = cancellationData;
  cancellationTableData: InlineTableData = cancellationTableData;
  surveyResponses = surveys;
  downsells = downsells;
  deflection = deflection;
  watchlist = watchlist;
  pathBreakdowns = pathBreakdowns;

  retentionHints = retentionHints;

  constructor(private storageService: StorageService) {}

  ngOnInit() {
    this.currentUser = this.storageService.get("currentUser");
    this.retentionTopCards.forEach((card) => (card.value = ""));
    this.retentionMidCards.forEach((card) => (card.value = ""));
    this.deflection.data.forEach((data) => {
      data.value = 0;
      data.percentage = null;
    });

    this.cancellationData.data = [0, 0, 0, 0, 0, 0];
    this.summaryData.data = [0, 0, 0, 0, 0, 0];

    this.summaryTableData.forEach((data) => {
      data.value = 0;
      data.percentage = "0%";
    });

    this.cancellationTableData.forEach((data) => {
      data.value = 0;
      data.percentage = "0%";
    });

    this.surveyResponses.loading = true;
    this.watchlist.loading = true;
    this.deflection.loading = true;
    this.pathBreakdowns.loading = true;
    this.downsells.loading = true;
    this.summaryData.loading = true;
    this.cancellationData.loading = true;

    this.cancellationData.noData = false;
    this.deflection.noData = false;
    this.summaryData.noData = false;
  }
}
