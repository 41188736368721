import {
  BaseModel,
  Image,
  SipDomain,
  SessionType,
  CampaignAutoResponderTrigger,
  TicketSystem,
  ShippingSystem,
} from './index';

export enum CampaignStatus {
  Inactive = 0,
  Active = 1,
  Disabled = 2
}

export enum RestockFeeType {
  Fixed = 1,
  Percent = 2,
}

export enum RestockFeeCalcType {
  Max = 1,
  Sum = 2
}

export enum VerificationMethod {
  FullName = 1,
  LastName = 2,
  Street = 3,
  Zip = 4,
  Address = 5,
  BillingStreet = 6,
  BillingZip = 7,
  BillingAddress = 8,
  PhoneOrEmail = 9,
  PartialPhoneOrEmail = 10,

  //special values
  StreetAndZip = 100000
}

export enum ContactType {
  Sales = 1,
  RMA = 2,
  Cancel = 3
}

export const ContactTypeLabels = [
  'Default',
  'Sales',
  'Returns',
  'Cancellations'
];

export enum LifelinePhoneSetting {
  WhenCallCenterOpen = 0,
  Never = 1,
  Always = 2,
  PhoneSessionOnly = 3,
  PhoneSessionAndCallCenterOpen = 4
}

export const LifelinePhoneSettingOptions = [
  {value: LifelinePhoneSetting.WhenCallCenterOpen, label: 'Show When Call Center is Open'},
  {value: LifelinePhoneSetting.Never, label: 'Never Show'},
  {value: LifelinePhoneSetting.Always, label: 'Always Show'},
  {value: LifelinePhoneSetting.PhoneSessionOnly, label: 'Show Only For Phone Sessions'},
  {value: LifelinePhoneSetting.PhoneSessionAndCallCenterOpen,
    label: 'Show Only For Phone Sessions and When Call Center is Open'}
];

export enum ShowMarketplaceSetting {
  HideIfEmbedded = 0,
  Never = 1,
  Always = 2,
}

export const ShowMarketplaceSettingOptions = [
  {value: ShowMarketplaceSetting.HideIfEmbedded, label: 'Hide if Embedded'},
  {value: ShowMarketplaceSetting.Never, label: 'Never Show'},
  {value: ShowMarketplaceSetting.Always, label: 'Always Show'},
];

export enum MembershipType {
  SpecificSubscription = 1,
  AnyActiveSubscription = 2,
  AnyCompletedPurchase = 3,
  ExistingCustomer = 4
}

export interface CallCenterHours {
  dow: number;
  starttime: string;
  endtime: string;
}

export interface ContactFormField {
  name: string;
  label: string;
  type: string;
  required: boolean;
  values?: string[];
}

export interface PhoneContact extends BaseModel {
  contact_type: ContactType;
  phone: string;
}

export interface NotificationContact extends BaseModel {
  contact_type: ContactType;
  email: string;
  phone_sms: string;
}

export interface CustomDomain extends BaseModel {
  campaign: string | number;
  domain: string;
  is_verified: boolean;
  verified: string;
  cname_target: string;
  type: SessionType;
  name: string;
  visual_entry_funnels: string[] | number[];
  show_phone_link: LifelinePhoneSetting;
}

export interface QuickLink extends BaseModel {
  type: QuickLinkType;
  title: string;
  link?: string;
  subtitle: string;
  step_key: string;
}

export interface Campaign extends BaseModel {
  name: string;
  subdomain: string;
  active_domain: string;
  display_name: string;
  email: string;
  support_phone: string;
  support_email: string;
  email_enabled: boolean;
  email_forward_delay: number;
  support_email_fields: ContactFormField[];
  status: CampaignStatus;
  verify_two_factor: boolean;
  matched_verify_all: boolean;
  unmatched_verify_all: boolean;
  match_phone: boolean;
  match_email: boolean;
  match_order_number: boolean;
  is_verified: boolean;
  categories: string[] | number[];
  styles: string[] | number[];
  visual_entry_funnels: string[] | number[];
  voice_entry_funnels: string[] | number[];
  sms_entry_funnels: string[] | number[];
  email_templates: string[] | number[];
  autoresponder_triggers: CampaignAutoResponderTrigger[]
  logo: Image;
  mobile_logo: Image;
  favicon: Image;
  processing_gif: Image;
  url: string;
  matched_verification_methods: VerificationMethod[];
  unmatched_verification_methods: VerificationMethod[];
  timezone: string;
  call_center_hours: CallCenterHours[];
  crm_campaigns: string[] | number[];
  faq_topic: string | number | null;
  campaign_products: string[] | number[];
  phone_loop_count: number;
  phone_input_timeout: number;
  restock_fee: number;
  restock_fee_type: RestockFeeType;
  restock_fee_calc_type: RestockFeeCalcType;
  rma_shipping_fee: number;
  return_days: number;
  exchange_days: number;
  max_extra_return_days: number;
  auto_cancel_upsells: boolean;
  phone_contacts: PhoneContact[];
  notification_contacts: NotificationContact[];
  return_action: string | number;
  exchange_action: string | number;
  cancel_action: string | number;
  cancel_subscription_action: string | number;
  is_hybrid: boolean;
  hybrid_entry_funnels: string[] | number[];
  filtered_campaigns: string[] | number[];
  custom_domains: CustomDomain[];
  lifeline_phone_setting: LifelinePhoneSetting;
  sip_domain: SipDomain;
  max_order_days: number;
  always_show_active_orders: boolean;
  subdomain_type: SessionType;
  membership_type: MembershipType;
  membership_discount: number;
  action_holdoff_delay: number;
  show_declined_orders: boolean;
  call_recording_enabled: boolean;
  call_frequency_timeout: number;
  call_frequency_threshold: number;
  telnyx_sip_uri: string;
  call_frequency_afterhours: boolean;
  sms_domain: string;
  show_phone_link: LifelinePhoneSetting;
  blacklisted_states: string[];
  ticket_system: TicketSystem;
  show_marketplace: ShowMarketplaceSetting;
  show_replaced_items: boolean;
  shipping_system: string | number;
  alpha_sender_id: string;
  chatbot_enabled: boolean;
  chatbot_icon: Image;
  session_start_ticket_delay: number;
  session_incomplete_ticket_delay: number;
  allowed_countries: string[];
  mouseflow_enabled: boolean;
  quick_links?: QuickLink[];
}

export interface PhoneEntryFunnelConfig {
  welcome_file: string | number;
  welcome_prompt: string;
  after_hours_file: string | number;
  after_hours_prompt: string;
  mobile_file: string | number;
  mobile_prompt: string;
  thankyou_file: string | number;
  thankyou_prompt: string;
  voice_type: string;
  sms_message: string;
  respond_to_sms: boolean;
  autoresponder: string | number;
}

export interface CampaignMenu {
  campaign: Campaign | null;
  activeSection: string;
  isHybrid: boolean;
}

export const CampaignMenuSections = {
  normal: [
    {id: 'edit', label: 'Settings'},
    {id: 'call-center', label: 'Call Center'},
    {id: 'phone', label: 'Phone Setup'},
    {id: 'email', label: 'Email Setup'},
    {id: 'products', label: 'Campaigns'},
    {id: 'paths', label: 'Paths'},
    {id: 'marketplace', label: 'Marketplace'},
    {id: 'styles', label: 'Themes'},
  ],
  hybrid: [
    {id: 'edit', label: 'Settings'},
    {id: 'call-center', label: 'Call Center'},
    {id: 'phone', label: 'Phone Setup'},
    {id: 'campaigns', label: 'Brands'},
    {id: 'paths', label: 'Paths'},
    {id: 'styles', label: 'Themes'}
  ]
};

export function getNextMenuSectionId(menuType: string, sectionId: string) {
  const index = CampaignMenuSections[menuType].findIndex((section) => section.id === sectionId) + 1;

  if ((index > 0) && (index < CampaignMenuSections[menuType].length)) {
    return CampaignMenuSections[menuType][index].id;
  }

  return null;
}

export interface TrackingSource extends BaseModel {
  campaign: string | number;
  source: string;
}

export const CampaignVariables = {
  brand_name: 'Brand Name',
  support_email: 'Support Email',
  support_phone: 'Support Phone Number',
}

export function getCampaignVariables() {
  let items = [];

  Object.keys(CampaignVariables).forEach(key => {
    items.push({value: '{' + key + '}', text: CampaignVariables[key]})
  });

  return items;
}

export enum QuickLinkType {
  ALL = 1,
  MATCHED_SESSION = 2,
  UNMATCHED_SESSION = 3
}

export const QuickLinkTypeLabels = {
  ALL: 'All - Matched/Unmatched Sessions',
  MATCHED_SESSION: 'Matched Sessions',
  UNMATCHED_SESSION: 'Unmatched Sessions'
}
