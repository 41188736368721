import {typeMainMenu} from './main-menu-button-type';

const mainMenu = editor => {
  const bm = editor.BlockManager;
  bm.add('main-menu', {
    category: 'Basic',
    media: '<i class="fa fa-home fa-2x margin-bottom-10"></i>',
    label: 'Main Menu',
    content: { type: typeMainMenu },
  });
};
export default mainMenu;
