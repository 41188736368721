import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignService, CRMCampaignService, CRMService, ShippingSystemService} from '../_services';
import {CrudSaveComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {
  Campaign,
  CRM,
  CRMCampaign,
  CRMTypeId,
  Pager,
  RestockFeeCalcType,
  RestockFeeType,
  MembershipType,
  ShippingSystem,
} from "../_models";
import {config} from '../../config/config';
import {EMPTY, forkJoin} from "rxjs";
import {mergeMap, takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-products.component.html'
})
export class CampaignProductsComponent extends CrudSaveComponent implements OnInit {
  selectedCRMCampaigns: {} = {};
  CRMCampaigns: {} = {};
  isNewCampaign = false;
  campaign: Campaign;
  selectedCRMId = null;
  crms: CRM[];
  shippingSystems: ShippingSystem[] = [];
  restockFeeCalcTypes = [
    {value: RestockFeeCalcType.Max, label: 'Maximum'},
    {value: RestockFeeCalcType.Sum, label: 'Sum'}
  ];
  restockFeeTypes = [
    {value: RestockFeeType.Fixed, label: 'Fixed'},
    {value: RestockFeeType.Percent, label: 'Percent'}
  ];
  membershipTypes = [
    {value: MembershipType.SpecificSubscription, label: 'Specific Subscription'},
    {value: MembershipType.AnyActiveSubscription, label: 'Any Active Subscription'},
    {value: MembershipType.AnyCompletedPurchase, label: 'Any Completed Purchase'},
    {value: MembershipType.ExistingCustomer, label: 'Existing Customer'}
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    private crmCampaignService: CRMCampaignService,
    private shippingSystemService: ShippingSystemService,
    private crmService: CRMService,
    private formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = 'Select CRM Campaigns';
    this.isPartial = true;
    this.objectName = 'Brand';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      restock_fee: [null, Validators.min(0)],
      rma_shipping_fee: [null, Validators.min(0)],
      restock_fee_calc_type: [RestockFeeCalcType.Max, Validators.required],
      restock_fee_type: [RestockFeeType.Fixed, Validators.required],
      return_days: [null, [Validators.required, Validators.min(0)]],
      exchange_days: [null, [Validators.required, Validators.min(0)]],
      max_extra_return_days: [30, [Validators.required, Validators.min(0)]],
      auto_cancel_upsells: [true],
      crm_campaigns: [[]],
      membership_type: [MembershipType.SpecificSubscription, Validators.required],
      membership_discount: [0, [Validators.min(0), Validators.max(100)]],
      shipping_system: [null],
    });

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        this.form.patchValue(campaign);
        this.campaign = campaign;
        this.campaignService.showMenu(campaign, 'products', this.isNewCampaign, campaign.is_hybrid);

        return forkJoin([
          this.crmService.list({page: 1, page_size: config.maxPageSize, 'type!': CRMTypeId.Test}),
          this.crmCampaignService.list({page: 1, page_size: config.maxPageSize,
            'crm__type!': CRMTypeId.Test}),
          this.shippingSystemService.list({page: 1, page_size: config.maxPageSize}),
        ]);
      }

      return EMPTY;
    })).subscribe(
      (data: [Pager, Pager, Pager]) => {
        let selectedCRMCampaigns = {};

        this.crms = data[0].results;

        //initialize empty crm campaign arrays for each crm
        this.crms.forEach((crm: CRM) => {
          this.selectedCRMCampaigns[crm.id] = [];
          this.CRMCampaigns[crm.id] = [];
        });

        if (this.crms.length == 1) {
          this.selectedCRMId = this.crms[0].id;
        }

        //build a map of the selected crm campaign ids for faster lookup
        for (let campaign_id of this.campaign.crm_campaigns) {
          selectedCRMCampaigns[campaign_id] = true;
        }

        //fill in the list of crm campaigns for each crm
        data[1].results.forEach((campaign: CRMCampaign) => {
          if (campaign.id in selectedCRMCampaigns) {
            this.selectedCRMCampaigns[campaign.crm].push(campaign);
          }

          this.CRMCampaigns[campaign.crm].push(campaign);
        });

        this.shippingSystems = data[2].results;
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected getFormData() {
    let campaignIds = [];

    this.crms.forEach((crm: CRM) => {
      this.selectedCRMCampaigns[crm.id].forEach((campaign: CRMCampaign) => {
        campaignIds.push(campaign.id);
      })
    });

    return Object.assign({}, this.form.value, {crm_campaigns: campaignIds});
  }

  protected onSaveComplete(data) {
    if (this.isNewCampaign) {
      this.campaignService.getNextMenuRoute().pipe(takeUntil(this.destroy$)).subscribe((route: string) => {
        this.navigate(['/campaign', route, this.id, {isNewCampaign: true}], {replaceUrl: true});
      });
    }
    else {
      this.goBack();
    }
  }

  displayFormat(row) {
    return `${row.name} (${row.crm_campaign_id})`;
  }

  get canEditMembershipDiscount() {
    return !this.isFieldEqual('membership_type', MembershipType.SpecificSubscription);
  }

}
