import {InputTypes, Types} from '../index';

const buttonType = editor => {
  const dType = editor.DomComponents.getType('link');
  const dModel = dType.model;
  editor.DomComponents.addType('button', {
    model: dModel.extend({
      defaults: Object.assign({}, dModel.prototype.defaults, {
        draggable: true,
        droppable: true,
        resizable: true,
        'stylable-require': ['button-themes'],
        editable: true,
        type: 'button',
        attributes: { ctype: Types.NONE, elemtype: InputTypes.BUTTON},
        components: [
          {
            type: "text",
            copyable: false,
            droppable: false,
            draggable: false,
            removable: false,
            content: "Change Title"
          }
        ]
      }),
    },
    {
      isComponent: function(el) {
        if (el && el.getAttribute && el.getAttribute('elemtype') == InputTypes.BUTTON) {
          return { type: 'button' };
        }
      },
    }),
  });
};
export default buttonType;

