import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, SipCredentialService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {SipCredential, SipDomain} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'sip-credential-form',
  templateUrl: './sip-credential-form.component.html'
})
export class SipCredentialFormComponent extends CrudSavePopupComponent implements OnInit {
  credential: SipCredential;

  @Input('domain') domain: SipDomain;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected credentialService: SipCredentialService,
              protected alertService: AlertService
  ) {
    super(router, location, route, credentialService, alertService);
    this.objectName = 'SIP Credential';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, [Validators.required, Validators.minLength(12)]],
      cpassword: [null, [Validators.required, Validators.minLength(12)]]
    }, {validators: this.mustMatch('password', 'cpassword')}));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    if (this.domain) {
      this.credentialService.setDomainId(this.domain.id);
      this.credential = this.inputData;
    }
  }

}
