import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  channel: any;

  constructor() {
  }

  initialize() {
    let token = localStorage.getItem('authToken');
    if(token){
      token = JSON.parse(token).value;
    }
    let channel = environment.pusher.channel + '-' + token;
    if (environment.pusher && environment.pusher.key && environment.pusher.channel) {
      this.pusher = new Pusher(environment.pusher.key, {
        cluster: environment.pusher.cluster,
      });
      this.channel = this.pusher.subscribe(channel);
    }
  }
}
