import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PhoneService, RegionService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {PhoneNumberType, PhoneNumberTypeEnum, PhoneProviderType, PhoneSearchResult, Region} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './phone-search.component.html'
})
export class PhoneSearchComponent extends Form implements OnInit {
  loading = false;
  phoneNumbers: PhoneSearchResult[];
  types: PhoneNumberType[] = [];
  selectedType: PhoneNumberTypeEnum;
  defaultCampaignId = null;
  providerType: PhoneProviderType;
  countries: Region[] = [{id: "us", name: "United States of America"}];

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    private route: ActivatedRoute,
    private phoneService: PhoneService,
    protected alertService: AlertService,
    private regionService: RegionService
  ) {
    super(alertService, router, location);
    this.title = 'Search For a New Phone Number';
    this.selectTypesForCountry('us');
  }

  ngOnInit() {
    this.defaultCampaignId = this.route.snapshot.params['campaign_id'];
    this.form = this.formBuilder.group({
      type: [PhoneNumberTypeEnum.Local, Validators.required],
      area_code: [null, [Validators.minLength(3), Validators.maxLength(3)]],
      pattern: [null],
      country: ['us', Validators.required],
      provider_type: [PhoneProviderType.Telnyx.toString()],
    });

    this.regionService.getCountries()
      .subscribe(
        (countries: Region[]) => {
          this.countries = countries;
        }
      )
  }

  selectCountry(event) {
    this.selectTypesForCountry(event.target.value);
    this.phoneNumbers = [];
  }

  private selectTypesForCountry(country: string) {
    let types: PhoneNumberType[] = [{value: PhoneNumberTypeEnum.Local, label: "Local"}];

    if (country !== 'us' && country !== 'ca') {
      types.push({value: PhoneNumberTypeEnum.National, label: "National"});
    }

    types.push({value: PhoneNumberTypeEnum.TollFree, label: "Toll-Free"});
    this.types = types;

    if (this.form) {
      this.form.patchValue({type: PhoneNumberTypeEnum.Local});
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      if (this.form.value.area_code == '') {
        this.form.value.area_code = null;
      }

      this.phoneService.search(this.form.value)
        .subscribe(
          (results: PhoneSearchResult[]) => {
            this.phoneNumbers = results;
            this.selectedType = this.form.value.type;
            this.providerType = this.form.value.provider_type;
            this.loading = false;
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }
}
