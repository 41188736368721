import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceItemService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {InvoiceItem, FeeTypeLabels} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'invoice-item-form',
  templateUrl: './invoice-item-form.component.html'
})
export class InvoiceItemFormComponent extends CrudSavePopupComponent implements OnInit {
  item: InvoiceItem;
  contentVariables = null;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected itemService: InvoiceItemService,
              protected alertService: AlertService
  ) {
    super(router, location, route, itemService, alertService);
    this.objectName = 'Additional Invoice Item';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      type: [null, Validators.required],
      description: [null],
      amount: [null, [Validators.required, Validators.min(0)]]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.item = this.inputData;
  }

  getFeeTypes() {
    let types = [];

    FeeTypeLabels.forEach((value, index) => {
      if (value) {
        types.push({id: index, label: value});
      }
    });

    return types;
  }

}
