import {Component} from '@angular/core';
import {CrudPagedListComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, TicketSystemService} from '../_services';
import {TicketSystem} from '../_models';
import {Observable} from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './ticket-system.component.html',
  styleUrls: ['./ticket-system.component.css']
})
export class TicketSystemComponent extends CrudPagedListComponent {
  ticketSystem$: Observable<TicketSystem[]> = this.data$;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected ticketSystemService: TicketSystemService,
              protected alertService: AlertService) {
    super(router, location, route, ticketSystemService, alertService);
    this.title = 'Ticket System'
  }
}
