import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PhoneService, CampaignService, CampaignDomainService, UserService} from '../_services';
import {CrudPagedListComponent} from '../_directives';
import {
  Pager,
  Phone,
  Campaign,
  PhoneUseTypeEnum,
  CustomDomain,
  User,
  PhoneProviderTypeLabels,
  PhoneNumberTypeLabels,
  PhoneStatusLabels
} from '../_models';
import {forkJoin, Observable} from "rxjs";
import {config} from "../../config/config";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './phone.component.html'
})
export class PhoneComponent extends CrudPagedListComponent implements OnInit {
  phoneNumbers$: Observable<Phone[]> = this.data$;
  campaignNames: {[key: string]: string;};
  domains: {[key: string]: string;};
  ready = false;
  user: User;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected phoneService: PhoneService,
    protected alertService: AlertService,
    private campaignService: CampaignService,
    private domainService: CampaignDomainService,
    private userService: UserService,
  ) {
    super(router, location, route, phoneService, alertService);
    this.objectName = 'phone number';
    this.title = 'My Phone Numbers';
  }

  ngOnInit() {
    super.ngOnInit();

    this.campaignService.hideMenu();

    forkJoin([
      this.campaignService.list({page: 1, page_size: config.maxPageSize}),
      this.domainService.list(),
      this.userService.getCurrent()
    ])
      .subscribe(
        (data: [Pager, CustomDomain[], User]) => {
          if (data) {
            let campaignNames = {};
            let domains = {};

            data[0].results.forEach((campaign: Campaign) => {
              campaignNames[campaign.id] = campaign.display_name;
            });

            data[1].forEach((domain: CustomDomain) => {
              domains[domain.id] = domain.domain;
            });

            this.campaignNames = campaignNames;
            this.domains = domains;
            this.user = data[2];
          }

          this.ready = true;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  getType(phone: Phone) {
    return PhoneNumberTypeLabels[phone.type];
  }

  getStatus(phone: Phone) {
    return PhoneStatusLabels[phone.status];
  }

  getUseType(phone: Phone) {
    if (phone.use_type === PhoneUseTypeEnum.SMSOnly) {
      return 'SMS Only';
    }
    else if (phone.use_type === PhoneUseTypeEnum.VoiceOnly) {
      return 'Voice Only';
    }

    return 'Voice & SMS';
  }

  getCampaignName(phone: Phone) {
    return phone.campaign ? this.campaignNames[phone.campaign] : 'Unassigned';
  }

  getDomain(phone: Phone) {
    return phone.domain ? this.domains[phone.domain] : 'Unassigned';
  }

  getProvider(phone: Phone) {
    return PhoneProviderTypeLabels[phone.resourcetype];
  }

  delete(phone: Phone) {
    const message = phone.campaign ?
      'The phone number is assigned to ' + this.getCampaignName(phone) + "\nAre you sure you want to delete it?"
      : 'Are you sure you want to delete this phone number?';

    if (window.confirm(message)) {
      this.loading = true;

      this.phoneService.delete(phone.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          result => {
            this.alertService.success('Deleted phone number.');
            this.onDelete(phone.id);
            this._data$.next(this._data$.getValue().filter(data => data.id != phone.id));
            this.loading = false;
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }
}
