import { Injectable } from "@angular/core";
import { DashboardService } from "./dashboard.service";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "../alert.service";
import { BehaviorSubject } from "rxjs";
import {
  DashboardSessionResultsAPIResponse,
  DashboardSessionsChartData,
} from "../../_models";
import { StorageService } from "../storage.service";

@Injectable({
  providedIn: "root",
})
export class DashboardSessionsService extends DashboardService {
  route = "dashboard/session_results";
  protected data: BehaviorSubject<DashboardSessionResultsAPIResponse> =
    new BehaviorSubject<DashboardSessionResultsAPIResponse>(undefined);

  protected chartData: BehaviorSubject<DashboardSessionsChartData> =
    new BehaviorSubject<DashboardSessionsChartData>({
      chartLegend: false,
      labels: ["Successful", "Bypass", "Dropoffs", "Lifeline"],
      dataset: {
        backgroundColor: [
          "#163549",
          "#33bcd7",
          "#ffcc3e",
          "#e2e7ef",
          "#15c771",
          "#cc0000",
        ],
        hoverBorderColor: ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff"],
        borderWidth: 3,
        data: [],
      },
      self_service_rate: [0, 0],
    });

  constructor(
    protected http: HttpClient,
    protected alertService: AlertService,
    protected storageService: StorageService
  ) {
    super(http, alertService, storageService);
    this.data$.subscribe((data) => {
      let result = [];
      let queryParams = this.getQueryParams();
      if (data) {
        let initialData = this.chartData.getValue();
        let labels = initialData.labels;
        let hangupsIndex = labels.indexOf("Hangups");
        let includeHangups = queryParams.get("include_hangups");
        if (includeHangups && hangupsIndex < 0) {
          labels.push("Hangups");
        } else if (!includeHangups && hangupsIndex > -1) {
          labels.splice(hangupsIndex, 1);
        }
        labels.forEach((label) => {
          if (label in data.session_results) {
            result.push(data.session_results[label]);
          } else {
            result.push(0);
          }
        });
        initialData.dataset.data = result;
        initialData.self_service_rate = data.self_service_rate;
        this.chartData.next(initialData);
      }
    });
  }

  get chartData$() {
    return this.chartData.asObservable();
  }
}
