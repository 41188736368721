import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceItemService} from '../_services';
import {FeeType, FeeTypeLabels, Invoice, InvoiceItem, InvoiceStatus, InvoiceStatusLabels} from '../_models';
import {CrudPagedListComponent} from '../_directives';
import {Observable} from "rxjs";
import {NgxSmartModalService} from 'ngx-smart-modal';
import {takeUntil} from "rxjs/operators";
import {formatISODate} from '../_helpers';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './invoice-item.component.html'
})
export class InvoiceItemComponent extends CrudPagedListComponent implements OnInit {
  items$: Observable<InvoiceItem[]> = this.data$;
  selectedItem: InvoiceItem;
  selectedInvoiceItems: InvoiceItem[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected itemService: InvoiceItemService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, itemService, alertService);
    this.objectName = 'invoice item';
    this.title = 'Additional Invoice Items';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  create() {
    this.selectedItem = {
      id: null,
      type: FeeType.Setup,
      description: null,
      amount: null
    } as InvoiceItem;

    this.openEditDialog();
  }

  edit(item: InvoiceItem) {
    this.selectedItem = item;
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('editInvoiceItemDialog').open();
  }

  onSaveItem(item: InvoiceItem) {
    this.modalService.getModal('editInvoiceItemDialog').close();
    this.queryData();
  }

  canCharge(item: InvoiceItem) {
    return item.status == InvoiceStatus.Pending
  }

  charge(item: InvoiceItem) {
    if (this.canCharge(item)) {
      this.selectedInvoiceItems = [item];
      this.modalService.getModal('invoiceItemsChargeDialog').open();
    }
  }

  onInvoiceCharged(invoice: Invoice) {
    this.modalService.getModal('invoiceItemsChargeDialog').close();
    this.queryData();
    this.selectedInvoiceItems = [];
  }

  getStatus(item: InvoiceItem) {
    return InvoiceStatusLabels[item.status];
  }

  getType(item: InvoiceItem) {
    return FeeTypeLabels[item.type];
  }

  getDate(item: InvoiceItem) {
    return formatISODate(item.created);
  }

  getAmount(item: InvoiceItem) {
    return '$' + item.amount;
  }

  canCancel(item: InvoiceItem) {
    return item.status === InvoiceStatus.Unpaid;
  }

  canDelete(item: InvoiceItem) {
    return item.status === InvoiceStatus.Pending;
  }

  canEdit(item: InvoiceItem) {
    return item.status === InvoiceStatus.Pending;
  }

  deleteItem(item: InvoiceItem) {
    if (this.canDelete(item)) {
      this.delete(item.id);
    }
    else if (this.canCancel(item)) {
      if (window.confirm('Are you sure you want to cancel this ' + this.objectName + '?')) {
        this.loading = true;

        this.itemService.cancel(item.id)
          .pipe(takeUntil(this._destroy$))
          .subscribe(
            result => {
              this.alertService.success('Cancelled ' + this.objectName + '.');
              this.queryData();  // refresh the data
              this.loading = false;
            },
            error => {
              this.handleSubmitError(error);
              this.loading = false;
            });
      }
    }
  }
}
