import { Editor } from 'tinymce';
import {
  StepButtons,
  CancelItemsSelection,
  FunnelType,
  StepDurationUnits,
  StepEmulatorDevices,
  StepMedia,
  StepMediaCategory,
  StepResponseScreen,
  StepTextual,
  StepButtonShapes,
  StepButtonShapeStyles,
  StepFixedMediaCategory,
  StepButtonControlTypes,
  defaultThemeColor,
  whiteColor,
  StepMediaSubCategory,
  EmbedPlatforms,
  embedURLs,
  StepProductsPreview,
  OfferIntents,
  CustomStepCategoryEnum,
  ActionType,
  Image,
  StepResponseMedia,
  CampaignProduct,
  parseFunnelVariableForEmulator,
} from '../../_models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const conversions: { [key in StepDurationUnits]: number } = {
  [StepDurationUnits.Hours]: 60 * 60,
  [StepDurationUnits.Days]: 24 * 60 * 60,
  [StepDurationUnits.Weeks]: 7 * 24 * 60 * 60,
  [StepDurationUnits.Months]: 30 * 24 * 60 * 60,
};

export function convertToSeconds(duration: number, unit: StepDurationUnits): number | null {
  if (duration === null || duration === undefined) return null;
  return unit in conversions ? duration * conversions[unit] : duration;
}

export function convertFromSeconds(seconds: number, unit: StepDurationUnits): number | null {
  if (seconds === null || seconds === undefined) return null;
  return conversions[unit] ? seconds / conversions[unit] : seconds;
}

export function addDummyValues(
  content: string,
  variables: { value: string; text: string }[],
  product: CampaignProduct = null
): string {
  let updatedContent = content;
  if (updatedContent) {
    variables.forEach((variable) => {
      if (content.indexOf(variable.value) !== -1) {
        const regex = new RegExp(escapeRegExp(variable.value), 'g');
        const value = parseFunnelVariableForEmulator(variable.value, product);

        updatedContent = updatedContent.replace(regex, value);
      }
    });
  }
  return updatedContent;
}

export function normalizeObject(obj) {
  const fieldsToNull = ['next_step', 'step', 'name'];
  const fieldsToFalse = ['is_template'];
  const fieldsToDelete = ['id'];

  fieldsToFalse.forEach((field) => {
    obj[field] = false;
  });
  fieldsToNull.forEach((field) => {
    obj[field] = null;
  });
  fieldsToDelete.forEach((field) => {
    delete obj[field];
  });

  const recursiveFields = ['inputs', 'actions'];

  recursiveFields.forEach((field) => {
    if (field in obj) {
      obj[field] = obj[field].map((item) => normalizeObject(item));
    }
  });

  return obj;
}

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function pxToVw(pixels: string, width: number): string {
  const sizeInPixels = parseInt(pixels.replace(/\D/g, ''), 10);
  const sizeInVw = ((sizeInPixels / width) * 100).toFixed(2);
  return sizeInVw;
}

export function vwToPx(vw: string, width: number): number {
  const sizeInPixels = (+vw * width) / 100;
  return sizeInPixels;
}

export function parseStyleString(style: string): any {
  const styleObject = {};
  if (style) {
    style.split(';').forEach((styleRule) => {
      const [property, value] = styleRule.split(':').map((item) => item.trim());
      if (property && value) {
        styleObject[property] = value;
      }
    });
  }
  return styleObject;
}

export function getEmbeddedVideoUrl(videoUrl: string, sanitizer: DomSanitizer): SafeResourceUrl | null {
  let embedUrl: string | null = null;

  if (videoUrl) {
    // YouTube Shorts
    if (videoUrl.includes('youtube.com/shorts/') || videoUrl.includes('youtu.be/shorts/')) {
      const videoId = extractVideoId(videoUrl, EmbedPlatforms.Youtube);
      embedUrl = videoId ? `${embedURLs.youtube}/${videoId}` : null;
    }
    // Regular YouTube
    else if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
      const videoId = extractVideoId(videoUrl, EmbedPlatforms.Youtube);
      embedUrl = videoId ? `${embedURLs.youtube}/${videoId}` : null;
    }
    // Vimeo, DailyMotion cases
    else if (videoUrl.includes('vimeo.com')) {
      const videoId = extractVideoId(videoUrl, EmbedPlatforms.Vimeo);
      embedUrl = videoId ? `${embedURLs.vimeo}/${videoId}` : null;
    } else if (videoUrl.includes('dailymotion.com')) {
      const videoId = extractVideoId(videoUrl, EmbedPlatforms.dailyMotion);
      embedUrl = videoId ? `${embedURLs.dailyMotion}/${videoId}` : null;
    }

    if (!embedUrl) {
      return null;
    }

    return sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
}

export function extractVideoId(url: string, platform: EmbedPlatforms): string | null {
  let videoId: string | null = null;

  switch (platform) {
    case EmbedPlatforms.Youtube:
      const shortsRegex = /(?:youtube\.com\/shorts\/|youtu\.be\/shorts\/)([a-zA-Z0-9_-]{11})/;
      const youtubeRegex =
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

      const shortsMatch = url.match(shortsRegex);
      const youtubeMatch = url.match(youtubeRegex);

      if (shortsMatch && shortsMatch[1]) {
        videoId = shortsMatch[1];
      } else if (youtubeMatch && youtubeMatch[1]) {
        videoId = youtubeMatch[1];
      }
      break;

    case EmbedPlatforms.Vimeo:
      const vimeoRegex = /vimeo\.com\/(?:video\/)?(\d+)/;
      const vimeoMatch = url.match(vimeoRegex);
      if (vimeoMatch && vimeoMatch[1]) {
        videoId = vimeoMatch[1];
      }
      break;

    case EmbedPlatforms.dailyMotion:
      const dailymotionRegex = /dailymotion\.com\/video\/([a-zA-Z0-9]+)/;
      const dailymotionMatch = url.match(dailymotionRegex);
      if (dailymotionMatch && dailymotionMatch[1]) {
        videoId = dailymotionMatch[1];
      }
      break;

    default:
      videoId = null;
      break;
  }

  return videoId;
}

export function getTruncatedProductName(productName: string): string {
  const maxLength = 22;
  if (!productName) {
    return '';
  }

  const trimmedName = productName.trim();
  return trimmedName.length > maxLength ? trimmedName.slice(0, maxLength - 3) + '...' : trimmedName;
}

export const StepBody = {
  subtitle: null,
  style: null,
  classes: [],
  is_popup: false,
  show_step_link: false,
  contact_type: null,
  enhanced_content: null,
  enhanced_mode: false,
  fail_if_any_input_invalid: false,
  invalid_message: null,
  hide_status: false,
  action_holdoffs: null,
  mobile_icon_button_type: null,
  use_bootstrap_spacing: true,
  enhanced_content_components: null,
  icon: null,
  help: null,
  hide_if_invalid: true,
  product_funnels: [],
  is_alt_child_exit: false,
  matched_step_key: null,
  autopick_next_step: null,
  requires_active_item: true,
  requires_item: true,
  requires_customer: true,
};

export const OfferData = {
  first_step: null,
  is_visual: true,
  is_voice: false,
  is_sms: false,
  is_modified: true,
  is_public: false,
  is_template: false,
  steps: [],
  use_product_filter: false,
  item_statuses: null,
  is_3ds: false,
  cancel_items_selection: CancelItemsSelection.UpsellsOnlyIfUpsell,
  min_billing_cycle: 1,
  merchant_ids: null,
  fulfillment_type: null,
  states: null,
  use_crm_campaign_filter: false,
  crm_campaigns: [],
  cc_types: null,
  return_items_selection: CancelItemsSelection.ShowAll,
  resourcetype: FunnelType.Product,
};

export function createStepTextualInitialData(): StepTextual {
  return {
    banner: { value: '', background: whiteColor },
    body: {
      label: '',
      content: '',
    },
  };
}

export function createStepMediaInitialData(): StepMedia {
  return {
    category: StepMediaCategory.NoMedia,
    subCategory: StepMediaSubCategory.Product,
    fixedImage: {
      [StepEmulatorDevices.Mobile]: {} as Image,
      [StepEmulatorDevices.Tablet]: {} as Image,
      [StepEmulatorDevices.Desktop]: {} as Image,
      productImage: null,
      category: StepFixedMediaCategory.Upload,
    },
    embedVideo: { url: null, isURLValid: false, sanitizedURL: null },
  };
}

export function createStepResponseMediaInitialData(): StepResponseMedia {
  return {
    category: StepMediaCategory.NoMedia,
    fixedImage: {
      [StepEmulatorDevices.Mobile]: {} as Image,
      [StepEmulatorDevices.Tablet]: {} as Image,
      [StepEmulatorDevices.Desktop]: {} as Image,
      productImage: null,
      category: StepFixedMediaCategory.Upload,
    },
    embedVideo: { url: null, isURLValid: false, sanitizedURL: null },
  };
}

export function createStepButtonsInitialData(): StepButtons {
  return {
    confirmButton: {
      content: '',
      ...StepButtonShapeStyles[StepButtonShapes.Modern],
      brandBackground: defaultThemeColor,
      brandBorder: whiteColor,
      brandFont: whiteColor,
      fontColor: whiteColor,
      isFontDisabled: false,
      style: StepButtonShapes.Modern,
      type: StepButtonControlTypes.Theme,
      controlPopup: false,
      shapesPopup: false,
    },
    backButton: {
      content: '',
      ...StepButtonShapeStyles[StepButtonShapes.ModernOutline],
      brandBackground: whiteColor,
      brandBorder: defaultThemeColor,
      brandFont: defaultThemeColor,
      fontColor: defaultThemeColor,
      isFontDisabled: true,
      style: StepButtonShapes.ModernOutline,
      type: StepButtonControlTypes.Theme,
      controlPopup: false,
      shapesPopup: false,
    },
  };
}

export function createStepResponseScreenInitialData(): StepResponseScreen {
  return {
    detailButton: {
      borderColor: whiteColor,
      backgroundColor: defaultThemeColor,
      fontColor: whiteColor,
    },
    continueButton: {
      borderColor: defaultThemeColor,
      backgroundColor: whiteColor,
      fontColor: defaultThemeColor,
    },
    titleContent: '',
    responseContent: '',
  };
}

export function createStepProductPreviewInitialData(): StepProductsPreview {
  return {
    data: [],
    loading: false,
  };
}

export const FontSizes = '12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px';

export const FontFamilyFormats =
  "Open Sans='Open Sans', Ubuntu, sans-serif; Ubuntu=ubuntu,sans-serif; Arial=arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Courier New=courier new,courier,monospace; Comic Sans MS=comic sans ms,sans-serif; Georgia=georgia,serif; Trebuchet MS=trebuchet ms,geneva;";

export function initializeEditorStyles(editor: Editor, fontSize: string, fontFamily: string, alignCenter?: boolean) {
  const applyDefaultStyles = () => {
    editor.execCommand('FontSize', false, fontSize);
    editor.execCommand('FontName', false, fontFamily);

    const style = document.createElement('style');
    style.innerHTML = `
      body {
        font-size: ${fontSize};
        font-family: ${fontFamily};
      }
    `;
    editor.getDoc().head.appendChild(style);
  };

  const cleanUpContent = () => {
    const content = editor.getContent();
    if (content.includes('<div>&nbsp;</div>')) {
      editor.setContent(content.replace(/<div>&nbsp;<\/div>/g, ''));
    }
  };

  const applyStylesAndCleanUp = () => {
    const content = editor.getContent();
    if (!content || content.trim() === '' || content === '<p><br></p>') {
      applyDefaultStyles();
      cleanUpContent();

      if (alignCenter) {
        editor.formatter.apply('aligncenter');
      }
    }
  };

  editor.on('init', applyStylesAndCleanUp);
  editor.on('Change KeyUp', applyStylesAndCleanUp);
  editor.on('focus', cleanUpContent);
}

export function addDataAttributes(html: string): string {
  if (!html) return null;
  const fontSizeRegex = /font-size:\s*(\d+px);/g;

  const dataFontSizeRegex = /data-fontsize="[^"]*"/;

  let updatedHtml = html.replace(
    /(<[^>]+)(style="[^"]*font-size:\s*\d+px[^"]*")([^>]*)>/g,
    (match, startTag, styleAttr, endTag) => {
      const fontSizeMatch = styleAttr.match(fontSizeRegex);
      const fontSize = fontSizeMatch ? fontSizeMatch[0].split(':')[1].trim() : null;

      if (fontSize) {
        if (dataFontSizeRegex.test(match)) {
          return match.replace(dataFontSizeRegex, `data-fontsize="${fontSize}"`);
        } else {
          return `${startTag} data-fontsize="${fontSize}" ${styleAttr}${endTag}>`;
        }
      }

      return match;
    }
  );

  return updatedHtml;
}

const MIN_WIDTH = 320;
const MAX_WIDTH = 8000;
const MIN_MULTIPLIER = 1.1;
const MAX_MULTIPLIER = 1.6;
const BASE_WIDTH = 430;

let emulatorWidth: number = BASE_WIDTH;

export function setEmulatorWidth(width: number) {
  emulatorWidth = width;
}

function getDeviceWidth() {
  return emulatorWidth;
}

function calculateScaleMultiplier(deviceWidth: number) {
  deviceWidth = Math.max(MIN_WIDTH, Math.min(deviceWidth, MAX_WIDTH));

  return MIN_MULTIPLIER + ((deviceWidth - MIN_WIDTH) / (MAX_WIDTH - MIN_WIDTH)) * (MAX_MULTIPLIER - MIN_MULTIPLIER);
}

function calculateFontSize(baseFontSize: string | number) {
  const deviceWidth = getDeviceWidth();
  if (baseFontSize) {
    baseFontSize = parseFloat(baseFontSize.toString());
    const scaleMultiplier = calculateScaleMultiplier(deviceWidth); // Maximum font size multiplier
    let scaledFontSize = (baseFontSize / BASE_WIDTH) * deviceWidth;

    if (deviceWidth < BASE_WIDTH) {
      scaledFontSize = Math.floor(scaledFontSize - 1.5);
    }

    const maxFontSize = baseFontSize * scaleMultiplier; // Dynamic max font size
    return Math.min(scaledFontSize, maxFontSize); // Cap at maxFontSize
  }
  
  return baseFontSize;
}

export function updateFontSizes(html: string): string {
  let updatedHtml = html || '';

  if (updatedHtml) {
    const fontSizeRegex = /font-size:\s*((\d+(\.\d+)?)px);/;

    let match;
    while ((match = fontSizeRegex.exec(html)) !== null) {
      const originalFontSize = match[1];
      const newFontSize = calculateFontSize(originalFontSize);
      updatedHtml = updatedHtml.replace(new RegExp(escapeRegExp(match[0]), 'g'), `font-size: ${newFontSize}px;`);
      html = html.slice(match.index + 1);
    }
  }

  return updatedHtml;
}

export const StepCategoryOfferIntentsMapping = {
  [CustomStepCategoryEnum.AdjustShippingFrequency]: OfferIntents.AdjustShippingFrequency,
  [CustomStepCategoryEnum.PauseSurvey]: OfferIntents.PauseSubscription,
  [CustomStepCategoryEnum.SwapProduct]: OfferIntents.ReviseOrder,
  [CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount]: OfferIntents.ReactivateSubscription,
  [CustomStepCategoryEnum.OfferDetails]: OfferIntents.OfferDetails,
  [CustomStepCategoryEnum.CancelOrderSurvey]: OfferIntents.CancelOrder,
  [CustomStepCategoryEnum.CancelTrialSurvey]: OfferIntents.CancelTrial,
  [CustomStepCategoryEnum.CancelSubSurvey]: OfferIntents.CancelSubscription,
  [CustomStepCategoryEnum.ReturnSurvey]: OfferIntents.ReturnOrder,
};

export const SurveyResourceMapping = {
  [CustomStepCategoryEnum.CancelOrderSurvey]: ActionType.Cancel,
  [CustomStepCategoryEnum.CancelSubSurvey]: ActionType.Cancel,
  [CustomStepCategoryEnum.CancelTrialSurvey]: ActionType.Cancel,
  [CustomStepCategoryEnum.ReturnSurvey]: ActionType.RMA,
};

export const StepsInvalidityFields = [
  'nameInvalidity',
  'labelInvalidity',
  'contentInvalidity',
  'discountInvalidity',
  'mediaInvalidity',
  'inputsInvalidity',
  'backInvalidity',
  'holdOffInvalidity',
  'timeDelayInvalidity',
  'confirmInvalidity',
  'settingsInvalidity',
];

export const StepHints = {
  title: 'This is the main question or message customers will see.',
  intro: 'A short message to guide customers.',
  stepName: 'Give your step a name to keep things organized.',
  surveyName: 'Give your survey a name to keep things organized.',
  templateName: 'Give your template a name to keep things organized.',
  responseOption: 'Add choices for customers to select.',
  customButtonLabels: 'Change the text on the buttons to make navigation clearer.',
  surveyNextButton: '"Continue” or “Next"',
  surveyBackButton: '"Go Back” or “Previous"',
};
