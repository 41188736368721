import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FileService} from "./file.service";
import {ClipboardService} from "./clipboard.service";

@Injectable()
export class IconFileService extends FileService {
  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, clipboardService, 'icons');
    this.fileType = 'icon'
  }
}
