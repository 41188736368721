import { Component, OnInit } from '@angular/core';
import { Gateway, GatewayStatus, GatewayStatusLabels } from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudPagedListComponent } from '../../../_directives';
import { config } from '../../../../config/config';
import { Location } from '@angular/common';
import { AlertService, CheckoutGatewayService, LoaderService } from '../../../_services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-checkout-gateway',
  templateUrl: './checkout-gateway.component.html',
  styleUrls: ['./checkout-gateway.component.scss', '../profiles-styles.component.scss'],
})
export class CheckoutGatewayComponent extends CrudPagedListComponent implements OnInit {
  public gatewayProfileStatus = GatewayStatus;
  public selectedFilter: GatewayStatus = null;
  public searchQuery: string;
  pageSize = config.maxPageSize;

  // Static Table Data
  tableData: Gateway[];
  originalTableData: Gateway[];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected gatewayService: CheckoutGatewayService,
    protected alertService: AlertService,
    protected loader: LoaderService
  ) {
    super(router, location, route, gatewayService, alertService);
    this.objectName = 'gateway';
  }

  ngOnInit() {
    super.ngOnInit();
    this.loader.show();
    this.data$.subscribe(
      (data: Gateway[]) => {
        if (data) {
          this.tableData = [];
          this.originalTableData = [];

          data.forEach((row: Gateway) => {
            if (row.is_deleted) {
              row.status = GatewayStatus.Archived;
            }

            this.tableData.push(row);
            this.originalTableData.push(row);
          });
          this.loader.hide();
        }
      },
      (error) => {
        this.handleError(error);
        this.loader.hide();
      }
    );
  }

  protected runInitialQuery() {
    this.queryData();
  }

  delete(id: number): void {
    const idx = this.tableData.findIndex((item: Gateway) => item.id === id);

    const item = this.tableData[idx];
    const isArchived = item.is_deleted;
    const action = isArchived ? 'unarchive' : 'archive';
    const confirmMessage = `Are you sure you want to ${action} this ${this.objectName}?`;

    if (window.confirm(confirmMessage)) {
      this.loader.show();

      const updateObservable = isArchived
        ? this.crudService.patch(id, { is_deleted: false })
        : this.crudService.delete(id);

      updateObservable.pipe(takeUntil(this._destroy$)).subscribe({
        next: () => {
          item.is_deleted = !isArchived;
          item.status = isArchived ? GatewayStatus.Active : GatewayStatus.Archived;
          const successMessage = `${isArchived ? 'Unarchived' : 'Archived'} ${this.objectName}.`;
          this.alertService.success(successMessage);
          this.loader.hide();
        },
        error: (error) => {
          this.handleError(error);
          this.loader.hide();
        },
      });
    }
  }

  searchData() {
    this.tableData = this.originalTableData.filter((item) =>
      item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  filterTableData() {
    if (this.selectedFilter === null) {
      this.tableData = [...this.originalTableData];
    } else if (this.selectedFilter === GatewayStatus.Archived) {
      this.tableData = this.originalTableData.filter((item) => item.is_deleted);
    } else {
      this.tableData = this.originalTableData.filter((item) => item.status === this.selectedFilter);
    }
  }

  getStatusLabel(status: GatewayStatus) {
    return GatewayStatusLabels[status];
  }
}
