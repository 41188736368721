import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProfileUser } from '../../../_models';

@Component({
  selector: 'checkout-processor-user-new',
  templateUrl: './checkout-processor-user-edit.component.html',
})
export class CheckoutProcessorUserNewComponent implements OnInit {
  public isNew: boolean = true;
  public profileUsers = ProfileUser;

  constructor(protected router: Router, protected location: Location) {}

  ngOnInit() {}
}
