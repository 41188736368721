import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, MailLogsService, SessionService} from "../_services";
import {Observable} from "rxjs";
import {MailLogs} from "../_models";
import {LogsAbstractComponent} from "./logs-abstract.component";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  selector: 'email-logs',
  templateUrl: './logs-template.component.html',
  styleUrls: ['./logs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmailLogsComponent extends LogsAbstractComponent {
  logs$: Observable<MailLogs> = this.data$;
  fields = {
    hap: "Status",
    created_at: "Created At",
    type: "Type",
    message: "Message",
    message_id: "Message ID"
  }

  fieldsList = Object.keys(this.fields);
  logName = 'Mailgun'
  public detailsFields = this.fields;
  public detailsFieldsList = Object.keys(this.detailsFields);

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected mailLogsService: MailLogsService,
    protected alertService: AlertService,
    protected modalService: NgxSmartModalService,
    protected sessionService: SessionService
  ) {
    super(router, location, route, mailLogsService, alertService, modalService, sessionService);
  }

  protected generateFilters() {
    this.filter['start_date'] = this.dateRange.startDate.format('Y-MM-DD HH:mm:ss');
    this.filter['end_date'] = this.dateRange.endDate.format('Y-MM-DD HH:mm:ss');
  }

}
