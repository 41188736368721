import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  CampaignProductService,
  CRMCampaignService,
  CRMService,
  ExchangeableProductsSetService,
  FunnelStepService,
  ProductService
} from '../_services';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {
  BillingCycleTypeEnum,
  CampaignProduct,
  CRM,
  CRMCampaign,
  DeviceType,
  Discount,
  DiscountPriceTypeEnum,
  ExchangeableProduct, ExchangeableProductsSet,
  FulfillmentTypeEnum,
  FunnelStep,
  Image,
  ImageSize,
  ImageType, Pager,
  ProcessTypeEnum,
  Product,
  ProductStatusEnum,
  ProductTypeEnum,
  SubscriptionDisplayType,
  TaggedImage,
  TrialTypeEnum,
  UpsaleProduct,
  VariantTypeEnum
} from '../_models';
import {forkJoin} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ProductBasicFieldsComponent, ProductMarketplaceFieldsComponent, ProductPathFieldsComponent} from "../product";
import {formatMoney, getCampaignProductImageUrl, getPlaceholderImageUrl} from "../_helpers";
import {ExchangeableProductFieldsComponent, UpsaleProductFieldsComponent} from "../exchangeable-product";
import {NgxSmartModalService} from 'ngx-smart-modal';
import {config} from '../../config/config';
import {ProductSubtextComponent} from '../product-subtext';

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-product-form',
  templateUrl: './campaign-product-edit.component.html',
  styleUrls: [
    'campaign-product.component.css'
  ]
})
export class CampaignProductEditComponent extends CrudSaveComponent implements OnInit, OnChanges, AfterViewInit {
  crm: CRM;
  selectedCRMCampaign: CRMCampaign;
  crmCampaigns: CRMCampaign[] = [];
  copyExchangeableProducts = false;
  copyUpsaleProducts = false;
  statuses = [
    {label: "Normal", value: ProductStatusEnum.Normal},
    {label: "Cancellable", value: ProductStatusEnum.Cancellable},
    {label: "Read Only", value: ProductStatusEnum.ReadOnly},
    {label: "Hidden", value: ProductStatusEnum.Hidden},
    {label: "Hide if Free First Cycle", value: ProductStatusEnum.HideIfFreeFirstCycle},
  ];
  overrideProductTypes = [
    {label: "Offer", value: ProductTypeEnum.Offer},
    {label: "Upsell", value: ProductTypeEnum.Upsell},
    {label: "Linked Upsell", value: ProductTypeEnum.LinkedUpsell},
  ];
  discountPriceTypes = [
    {label: "Price", value: DiscountPriceTypeEnum.Price},
    {label: "Shipping Price", value: DiscountPriceTypeEnum.ShippingPrice},
    {label: "Price + Shipping", value: DiscountPriceTypeEnum.TotalPrice},
  ];
  processTypes = [
    {value: ProcessTypeEnum.Sale, label: 'Sale'},
    {value: ProcessTypeEnum.Fee, label: 'Fee'},
    {value: ProcessTypeEnum.Warranty, label: 'Warranty'},
    {value: ProcessTypeEnum.Installment, label: 'Installment'},
  ];
  subscriptionDisplayTypes = [
    {value: SubscriptionDisplayType.AfterFirstCycle, label: 'After First Cycle'},
    {value: SubscriptionDisplayType.Always, label: 'Always'},
    {value: SubscriptionDisplayType.Never, label: 'Never'}
  ];
  exchangeableProducts: ExchangeableProduct[] = [];
  selectedExchangeableProductIndex: number = -1;
  upsaleProducts: UpsaleProduct[] = [];
  selectedUpsaleProductIndex: number = -1;
  linkedProducts: CampaignProduct[] = [];
  relatedProducts: CampaignProduct[] = [];
  linkedProductsModalInput: CampaignProduct[] = null;
  relatedProductsModalInput: CampaignProduct[] = null;
  ready = false;
  taggedImagesFields: FormArray;
  tags: {value: string, label: string}[] = [];
  taggedImageMap = {};
  taggedImageMobileMap = {};
  imageType: ImageType = ImageType.ProductImage;
  isMembership = false;
  image: Image;

  @ViewChild(ProductMarketplaceFieldsComponent, { static: false }) productMarketplaceFields: ProductMarketplaceFieldsComponent;
  @ViewChild(ProductBasicFieldsComponent, { static: false }) productBasicFields: ProductBasicFieldsComponent;
  @ViewChild(ProductPathFieldsComponent, { static: false }) productPathFields: ProductPathFieldsComponent;
  @ViewChild(ExchangeableProductFieldsComponent, { static: false }) exchangeableProductFields: ExchangeableProductFieldsComponent;
  @ViewChild("productSubtext", { static: false }) productSubtexts: ProductSubtextComponent;
  @ViewChild(UpsaleProductFieldsComponent, { static: false }) upsaleProductFields: UpsaleProductFieldsComponent;
  @Input('bulkIds') bulkIds: (string | number)[];
  @Input('bulkProductIds') bulkProductIds: (string | number)[];
  @Input('campaignProduct') campaignProduct: CampaignProduct;
  @Output('save') onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output('cancel') onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Input('exchangeableProducts') selectedExchangeableProducts: ExchangeableProduct[] = [];

  private selectedTaggedImageIndex = null;
  private selectedTaggedImageDevice = null;
  private exchangeableProductsEdited = false;

  public assignedExchangeableSets: ExchangeableProductsSet[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected productService: CampaignProductService,
    protected baseProductService: ProductService,
    private crmService: CRMService,
    private campaignService: CRMCampaignService,
    private formBuilder: FormBuilder,
    private modalService: NgxSmartModalService,
    private stepService: FunnelStepService,
    protected alertService: AlertService,
    protected exchangeableProductsSetService: ExchangeableProductsSetService
  ) {
    super(router, location, route, productService, alertService);
    this.isNew = false;
    this.isPartial = true;
    this.objectName = 'Product';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null],
      offer_name: [null],
      offer_terms: [null],
      url: [null],
      subtext: this.formBuilder.array([]),
      discounts: this.formBuilder.array([]),
      status: this.isBulk ? [null] : [ProductStatusEnum.Normal, Validators.required],
      max_quantity: [null, Validators.min(1)],
      bundle_quantity: this.isBulk ? [null, Validators.min(1)] : [1, [Validators.required, Validators.min(1)]],
      min_price: this.isBulk ? [null, Validators.min(0)] : [0, [Validators.required, Validators.min(0)]],
      override_price: [null, Validators.min(0)],
      override_shipping_price: [null, Validators.min(0)],
      member_price: [null, Validators.min(0)],
      retail_price: [null, Validators.min(0)],
      restock_fee: [null, Validators.min(0)],
      exchangeable_products: this.formBuilder.array([]),
      upsale_products: this.formBuilder.array([]),
      tagged_images: this.formBuilder.array([]),
      override_product_type: [null],
      discount_type: this.isBulk ? [null] : [DiscountPriceTypeEnum.Price, Validators.required],
      membership_term: [null, Validators.min(0)],
      membership_discount: [null, [Validators.min(0), Validators.max(100)]],
      meta_description: [null],
      keywords: [null],
      override_process_type: [null],
      subscription_display_type: this.isBulk ? [null] : [SubscriptionDisplayType.AfterFirstCycle, Validators.required],
      show_next_bill_price: this.isBulk ? [null] : [true],
      return_days: [null, Validators.min(0)],
      exchange_days: [null, Validators.min(0)],
      max_extra_return_days: [null, Validators.min(0)],
      third_party_recurring: this.isBulk ? [null] : [false],
      default_crm_campaign: [null],
      change_billing_interval: this.isBulk ? [null] : [false],
    }));
    this.taggedImagesFields = this.form.get('tagged_images') as FormArray;
    this.updateForm();
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  protected resetForm(scrollToTop: boolean = false) {
    //remove all discounts from the form
    let discounts = this.form.get('discounts') as FormArray;

    if (discounts && discounts.length) {
      for (let i = discounts.length - 1; i >= 0; i--) {
        discounts.removeAt(i);
      }
    }

    //remove all tagged images from the form
    let taggedImages = this.taggedImagesFields as FormArray;

    if (taggedImages && taggedImages.length) {
      for (let i = taggedImages.length - 1; i >= 0; i--) {
        taggedImages.removeAt(i);
      }
    }

    super.resetForm(scrollToTop);
  }

  get allFormsValid() {
    return (
      this.form.valid &&
      this.productBasicFields && this.productBasicFields.form.valid &&
      this.productPathFields && this.productPathFields.form.valid &&
      this.productMarketplaceFields && this.productMarketplaceFields.form.valid
    );
  }

  private updateForm() {
    this.ready = false;
    this.tags = [];

    if (this.form) {
      this.resetForm();

      if (this.campaignProduct) {
        this.loading = true;
        this.id = this.campaignProduct.id;

        forkJoin([
          this.crmService.get(this.campaignProduct.crm),
          this.productService.get(this.id)
        ]).subscribe(
          (data: [CRM, CampaignProduct]) => {
            this.crm = data[0];
            this.campaignProduct = data[1];
            this.crmCampaigns = this.campaignProduct.crm_campaigns;
            this.campaignProduct.subtext = this.campaignProduct.subtext || [];

            this.form.patchValue(this.campaignProduct);
            this.linkedProducts = this.campaignProduct.linked_products;
            this.relatedProducts = this.campaignProduct.related_products;
            this.exchangeableProducts = this.campaignProduct.exchangeable_products ?
              this.campaignProduct.exchangeable_products.slice() : [];
            this.upsaleProducts = this.campaignProduct.upsale_products ?
              this.campaignProduct.upsale_products.slice() : [];
            this.addProductFields();
            this.isMembership = this.campaignProduct.membership_term > 0;
            this.image = this.campaignProduct.image;
            this.assignedExchangeableSets = this.campaignProduct.exchangeable_sets

            this.campaignProduct.discounts.forEach((discount: Discount) => {
              this.addDiscount(discount.price);
            });

            if (this.campaignProduct.tagged_images) {
              this.campaignProduct.tagged_images.forEach((taggedImage: TaggedImage, index: number) => {
                if (taggedImage.tag && taggedImage.image) {
                  this.addTaggedImage(taggedImage);
                }
              });
            }
            this.form.updateValueAndValidity();
            this.loading = false;
          },
          error => {
            this.handleError(error);
            this.loading = false;
          }
        );

      }
      else {
        this.addProductFields();
      }
      this.stepService.getGlobalJumpSteps(false, {
        funnel__is_visual: true
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (steps: FunnelStep[]) => {
            steps.forEach((step: FunnelStep) => {
              this.tags.push({value: step.slug, label: step.name});
            });
          },
          error => {
            this.handleError(error);
          }
        );

      setTimeout(() => this.ready = true, 0); // this is needed to force the tinymce editor to reload
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.addProductFields(), 0);
  }

  get selectedExchangeableProduct() {
    return (this.selectedExchangeableProductIndex >= 0) ?
      this.exchangeableProducts[this.selectedExchangeableProductIndex] : null;
  }

  get selectedUpsaleProduct() {
    return (this.selectedUpsaleProductIndex >= 0) ?
      this.upsaleProducts[this.selectedUpsaleProductIndex] : null;
  }

  get isBulk() {
    return this.bulkIds && (this.bulkIds.length > 0);
  }

  private addProductFields() {
    if (this.form && (this.campaignProduct || this.isBulk)) {
      if (this.productBasicFields && !this.form.get('productBasicFields')) {
        this.form.addControl('productBasicFields', this.productBasicFields.form)
      } else if (this.productBasicFields) {
        this.form.controls['productBasicFields'] = this.productBasicFields.form;
      }

      if (this.productMarketplaceFields && !this.form.get('productMarketplaceFields')) {
        this.form.addControl('productMarketplaceFields', this.productMarketplaceFields.form)
      } else if (this.productMarketplaceFields) {
        this.form.controls['productMarketplaceFields'] = this.productMarketplaceFields.form;
      }

      if (this.productPathFields && !this.form.get('productPathFields')) {
        this.form.addControl('productPathFields', this.productPathFields.form)
      } else if (this.productPathFields) {
        this.form.controls['productPathFields'] = this.productPathFields.form;
      }

      if (this.isBulk && this.selectedExchangeableProducts) {
        this.exchangeableProducts = this.selectedExchangeableProducts.slice()
      }
    }
  }

  private createDiscount(discount: number = null): FormGroup {
    return this.formBuilder.group({
      price: [discount, [Validators.required, Validators.min(0.01)]]
    });
  }

  addDiscount(discount: number = null) {
    let discounts = this.form.get('discounts') as FormArray;

    discounts.push(this.createDiscount(discount));
  }

  removeDiscount(index) {
    let discounts = this.form.get('discounts') as FormArray;

    discounts.removeAt(index);
  }

  addRelatedProduct() {
    this.relatedProductsModalInput = this.relatedProducts;
    this.modalService.getModal('relatedProductsDialog').open();
  }

  onRelatedProductsSelected(products: CampaignProduct[]) {
    this.relatedProducts = products;
    this.modalService.getModal('relatedProductsDialog').close();
  }

  removeRelatedProduct(productId) {
    this.relatedProducts = this.relatedProducts.filter(
      (product: CampaignProduct) => product.id != productId);
  }

  getProductImageUrl() {
    return getCampaignProductImageUrl(this.campaignProduct, ImageSize.original);
  }

  getRelatedProductImageUrl(relatedProduct) {
    return getCampaignProductImageUrl(relatedProduct, ImageSize.small);
  }

  addUpsaleProduct(upsaleProduct?: UpsaleProduct) {
    upsaleProduct = {
      to_campaign_product: null,
      quantity: 1
    } as UpsaleProduct;
    this.upsaleProducts.push(upsaleProduct);
    this.editUpsaleProduct(this.upsaleProducts.length - 1);
  }

  editUpsaleProduct(index: number) {
    this.selectedUpsaleProductIndex = index;
    this.openUpsaleProductDialog();
  }

  removeUpsaleProduct(index: number) {
    this.upsaleProducts.splice(index, 1);
  }

  protected openUpsaleProductDialog() {
    this.modalService.getModal('upsaleProductDialog').open();
  }

  onCloseUpsaleProductDialog() {
    this.upsaleProducts[this.selectedUpsaleProductIndex] = this.upsaleProductFields.getFormData();
    this.selectedUpsaleProductIndex = -1;
  }

  addExchangeableProduct(exchangeableProduct?: ExchangeableProduct) {
    exchangeableProduct = {
      to_campaign_product: null,
      quantity: 1
    } as ExchangeableProduct;
    this.exchangeableProducts.push(exchangeableProduct);
    this.editExchangeableProduct(this.exchangeableProducts.length - 1);
  }

  editExchangeableProduct(index: number) {
    this.selectedExchangeableProductIndex = index;
    this.openExchangeableProductDialog();
  }

  removeExchangeableProduct(index: number) {
    this.exchangeableProducts.splice(index, 1);
    this.exchangeableProductsEdited = true
  }

  protected openExchangeableProductDialog() {
    this.modalService.getModal('exchangeableProductDialog').open();
  }

  onCloseExchangeableProductDialog() {
    this.exchangeableProducts[this.selectedExchangeableProductIndex] = this.exchangeableProductFields.getFormData();
    this.selectedExchangeableProductIndex = -1;
    this.exchangeableProductsEdited = true;
  }

  getExchangeableProductImageUrl(exchangeableProduct: UpsaleProduct) {
    return getCampaignProductImageUrl(exchangeableProduct.to_campaign_product, ImageSize.small);
  }

  getExchangeableProductPrice(exchangeableProduct: UpsaleProduct) {
    return exchangeableProduct.total_price ?
      formatMoney(exchangeableProduct.total_price) :
      formatMoney(exchangeableProduct.quantity * exchangeableProduct.to_campaign_product.price)
  }

  addLinkedProduct() {
    this.linkedProductsModalInput = this.linkedProducts;
    this.modalService.getModal('linkedProductsDialog').open();
  }

  onLinkedProductsSelected(products: CampaignProduct[]) {
    this.linkedProducts = products;
    this.modalService.getModal('linkedProductsDialog').close();
  }

  removeLinkedProduct(productId) {
    this.linkedProducts = this.linkedProducts.filter(
      (product: CampaignProduct) => product.id != productId);
  }

  editCRMCampaign(campaign: CRMCampaign) {
    this.selectedCRMCampaign = campaign;
    this.modalService.getModal('editCRMCampaignDialog').open();
  }

  onSaveCRMCampaign(campaign: CRMCampaign) {
    this.modalService.getModal('editCRMCampaignDialog').close();
    this.selectedCRMCampaign = null;
  }

  private createTaggedImage(tag: string = null, image: Image = null, mobile_image: Image = null, id: number | string = null): FormGroup {
    return this.formBuilder.group({
      id: [id],
      tag: [tag, [Validators.required]],
      image: [image ? image.id : null, [Validators.required]],
      mobile_image: [mobile_image ? mobile_image.id : null]
    });
  }

  addTaggedImage(taggedImage: TaggedImage = { tag: null, image: null, mobile_image:null, id: null }) {
    const id = taggedImage.id || 'new-'+ Date.now();
    this.taggedImagesFields.push(this.createTaggedImage(taggedImage.tag, taggedImage.image, taggedImage.mobile_image, id));
    this.taggedImageMap[id] = taggedImage.image || null;
    this.taggedImageMobileMap[id] = taggedImage.mobile_image || null;
  }

  removeTaggedImage(index) {
    const id = this.taggedImagesFields.at(index).get('id').value;
    this.taggedImagesFields.removeAt(index);

    if (this.taggedImageMap[id]) {
      delete this.taggedImageMap[id];
    }
    if (this.taggedImageMobileMap[id]) {
      delete this.taggedImageMobileMap[id];
    }
  }

  onTaggedImageSelectStart(index, device: DeviceType) {
    this.selectedTaggedImageIndex = index;
    this.selectedTaggedImageDevice = device;
    this.modalService.getModal('taggedImageDialog').open();
  }

  onTaggedImageSelectDone(image: Image) {
    if(this.selectedTaggedImageDevice === DeviceType.Desktop) {
      this.taggedImagesFields.at(this.selectedTaggedImageIndex).patchValue({image: image.id});
      const id = this.taggedImagesFields.at(this.selectedTaggedImageIndex).get('id').value;
      this.taggedImageMap[id] = image;
    } else {
      this.taggedImagesFields.at(this.selectedTaggedImageIndex).patchValue({mobile_image: image.id});
      const id = this.taggedImagesFields.at(this.selectedTaggedImageIndex).get('id').value;
      this.taggedImageMobileMap[id] = image;
    }
    this.modalService.getModal('taggedImageDialog').close();
  }

  getTaggedImageUrl(index, device: DeviceType) {
    const id = this.taggedImagesFields.at(index).get('id').value;
    let image;
    if(device === DeviceType.Desktop) {
      image = this.taggedImageMap[id] || null;
    } else {
      image = this.taggedImageMobileMap[id] || null;
    }
    return image ? (image.file.original ? image.file.original : image.file) : getPlaceholderImageUrl(ImageSize.small);
  }

  getCRMProductType() {
    let productTypes = {};

    productTypes[ProductTypeEnum.Offer] = 'Offer';
    productTypes[ProductTypeEnum.Upsell] = 'Upsell';

    return productTypes[this.campaignProduct.crm_product_type];
  }

  getBillingCycleType() {
    let billingCycleTypes = {};

    billingCycleTypes[BillingCycleTypeEnum.OneTime] = 'One Time';
    billingCycleTypes[BillingCycleTypeEnum.Recurring] = 'Recurring';
    billingCycleTypes[BillingCycleTypeEnum.MultiPay] = 'Multi-Pay';

    return billingCycleTypes[this.campaignProduct.billing_cycle_type];
  }

  getFulfillmentType() {
    let fulfillmentTypes = {};

    fulfillmentTypes[FulfillmentTypeEnum.Standard] = 'Standard';
    fulfillmentTypes[FulfillmentTypeEnum.NoShipment] = 'No Shipment';

    return fulfillmentTypes[this.campaignProduct.product.fulfillment_type];
  }

  getTrialType() {
    let trialTypes = {};

    trialTypes[TrialTypeEnum.None] = 'No Trial';
    trialTypes[TrialTypeEnum.Standard] = 'Standard';
    trialTypes[TrialTypeEnum.Delayed] = 'Delayed';
    trialTypes[TrialTypeEnum.Accelerated] = 'Accelerated';

    return trialTypes[this.campaignProduct.trial_type];
  }

  getVariantType() {
    let variantTypes = {};

    variantTypes[VariantTypeEnum.None] = 'None';
    variantTypes[VariantTypeEnum.Standard] = 'Standard';
    variantTypes[VariantTypeEnum.Bundled] = 'Bundled';

    return variantTypes[this.campaignProduct.product.variant_type];
  }

  hasCycles() {
    return this.campaignProduct && (this.campaignProduct.billing_cycle_type === BillingCycleTypeEnum.Recurring) &&
      this.campaignProduct.cycles && this.campaignProduct.cycles.length;
  }

  hasVariants() {
    return this.campaignProduct && (this.campaignProduct.product.variant_type !== VariantTypeEnum.None);
  }

  isBundledVariants() {
    return this.campaignProduct && (this.campaignProduct.product.variant_type === VariantTypeEnum.Bundled);
  }

  onSubmit() {
    this.submitted = true;
    if (this.allFormsValid) {
      this.loading = true;
      if (this.isBulk) {
        //perform bulk update of data
        let data = this.getFormData();
        data['exchangeable_products'] = this.getExchangeableProductFormData(!this.exchangeableProductsEdited)

        this.removeEmptyFields(data['product']);
        this.removeEmptyFields(data);

        const productData = data['product'];
        delete data['product'];
        const bulkRequests = [];
        let campaignProductIds = [...this.bulkIds];
        let baseProductIds = [...this.bulkProductIds];
        while(campaignProductIds.length) {
          bulkRequests.push(this.productService.bulkUpdateWithSameData(
            campaignProductIds.splice(0, 20), data));
        }
        if (productData) {
          while(baseProductIds.length) {
            bulkRequests.push(this.baseProductService.bulkUpdateWithSameData(
              baseProductIds.splice(0, 20), productData));
          }
        }
        forkJoin(bulkRequests).subscribe(
            data => {
              this.alertService.success('Product bulk update successful.', true);
              this.canDeactivateService.setFormDirty(false);
              this.onPostSaveComplete(data);
            },
            error => {
              this.handleSubmitError(error);
              this.loading = false;
            });
      }
      else {
        super.onSubmit(); //normal object update
      }
    } else {
      this.loading = false;
    }
  }

  removeImage() {
    this.image = null;
  }

  onImageSelectStart() {
    this.modalService.getModal('campaignProductImageDialog').open();
  }

  onImageSelectDone(image: Image) {
    this.image = image;
    this.modalService.getModal('campaignProductImageDialog').close();
  }

  protected getFormData() {
    let data: {} = Object.assign({}, this.form.value);
    const allow_blank_fields = ['override_price', 'override_shipping_price', 'restock_fee', 'member_price',
      'retail_price', 'return_days', 'exchange_days', 'max_extra_return_days'];
    data['keywords'] = []

    // convert the keywords string into an array of unique values
    if (typeof this.form.value.keywords === 'string') {
      const keywordsStr = this.form.value.keywords ? this.form.value.keywords.trim() : '';
      if (keywordsStr.length) {
        data['keywords'] = keywordsStr.split(',').filter(
          (value, index, self) => self.indexOf(value) === index);
      }
    }
    //convert empty strings to null for certain fields so the api will accept them
    allow_blank_fields.forEach((field: string) => {
      if (field in data) {
        let value = data[field];

        if (typeof(value) == 'string') {
          if (value.trim().length == 0) {
            data[field] = null;
          }
        }
      }
    });

    data['related_products'] = [];
    data['linked_products'] = [];
    data['exchangeable_products'] = this.getExchangeableProductFormData(true);
    data['subtext'] = this.productSubtexts.getFormData();
    data['upsale_products'] = this.getUpsaleProductFormData(true);
    data['image'] = this.image ? this.image.id : null;

    if (!this.isMembership) {
      data['membership_term'] = 0;
    }

    this.relatedProducts.forEach((product: CampaignProduct) => {
      data['related_products'].push(product.id);
    });

    (data['tagged_images'] || []).forEach((taggedImage) => {
      taggedImage.image = taggedImage.image.id || taggedImage.image;
      taggedImage.mobile_image = taggedImage.mobile_image ? taggedImage.mobile_image.id || taggedImage.mobile_image : null
    });

    this.linkedProducts.forEach((product: CampaignProduct) => {
      data['linked_products'].push(product.id);
    });

    data['product'] = Object.assign({},
      this.productBasicFields.getFormData(),
      this.productMarketplaceFields.getFormData(),
      this.productPathFields.getFormData()
    );

    delete(data['productBasicFields']);
    delete(data['productMarketplaceFields']);
    delete(data['productPathFields']);

    return data;
  }

  private removeEmptyFields(data) {
    const allow_zeroes = ['hold_period', 'hold_period_max_cycle', 'shipping_hold_period']

    Object.keys(data).forEach(key => {
      const value = data[key];

      if (!value) {
        if (value !== 0 || allow_zeroes.indexOf(key) === -1) {
          delete data[key];
        }
      }
      else if (value instanceof Array) {
        if (!value.length) {
          delete data[key];
        }
      }
    });
  }

  private getExchangeableProductFormData(keepIds: boolean) {
    let data = [];

    // get the exchangeable products and convert the to_campaign_product to ids
    this.exchangeableProducts.forEach((exchangeableProduct: ExchangeableProduct) => {
      data.push(Object.assign({}, exchangeableProduct,
        {
          to_campaign_product: exchangeableProduct.to_campaign_product.id,
          id: keepIds ? exchangeableProduct.id : null
        }));
    });

    return data;
  }

  private getUpsaleProductFormData(keepIds: boolean) {
    let data = [];

    // get the upsale products and convert the to_campaign_product to ids
    this.upsaleProducts.forEach((upsaleProduct: UpsaleProduct) => {
      data.push(Object.assign({}, upsaleProduct,
        {
          to_campaign_product: upsaleProduct.to_campaign_product.id,
          id: keepIds ? upsaleProduct.id : null
        }));
    });

    return data;
  }

  protected onSaveComplete(data) {
    let observables = [];

    if (this.copyExchangeableProducts) {
      const productData = {exchangeable_products: this.getExchangeableProductFormData(false)};

      // get observables for each of the api calls to update each exchangeable product with the same list
      this.exchangeableProducts.forEach((exchangeableProduct: ExchangeableProduct) => {
        if (exchangeableProduct.to_campaign_product.id !== this.id) {
          observables.push(this.productService.patch(exchangeableProduct.to_campaign_product.id, productData));
        }
      });
    }

    // call all the update apis to update the exchangeable products
    if (observables.length) {
      forkJoin(observables)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.onPostSaveComplete(data);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          }
        );
    }
    else {
      this.onPostSaveComplete(data);
    }

  }

  private onPostSaveComplete(data) {
    this.loading = false;
    this.removeImage()
    this.onSave.emit(data);
  }

  closeDialog() {
    this.removeImage()
    this.onCancel.emit()
  }

}
