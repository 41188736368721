import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ProfileUserRole, ProfileUser } from '../../../_models';

@Component({
  selector: 'checkout-profile-user',
  templateUrl: './checkout-profile-user.component.html',
  styleUrls: ['./checkout-profile-user.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CheckoutProfileUserComponent implements OnInit {
  @Input() isNew: boolean = true;
  @Input() profileType: ProfileUser;
  public pageTitle: string;
  public profileUsers = ProfileUser;
  public loading: boolean = false;
  public ProfileUserRoles = ProfileUserRole;
  public selectedRole: ProfileUserRole;

  constructor(protected router: Router, protected location: Location) {}

  ngOnInit() {
    this.pageTitle = this.isNew ? 'Add User' : 'Edit User';
  }

  backToUsers() {
    this.location.back();
  }

  selectRole(role: ProfileUserRole) {
    this.selectedRole = role;
  }
}
