import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, EmailTemplateService, FunnelStepService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {EmailTemplateNewComponent} from "./email-template-new.component";
import {EmailTemplate} from "../_models";
import {takeUntil} from "rxjs/operators";


@Component({
  selector: 'EmailTemplateEdit',
  moduleId: module.id.toString(),
  templateUrl: './email-template-edit.component.html'
})
export class EmailTemplateEditComponent extends EmailTemplateNewComponent implements OnInit {
  @Input('emailTemplateId') templateId = null
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected templateService: EmailTemplateService,
    protected alertService: AlertService,
    protected stepService: FunnelStepService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, templateService, alertService, stepService, formBuilder);
    this.isNew = false;
    this.title = 'Edit Email Template';
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe(
      (template: EmailTemplate) => {
        if (template) {
          this.form.patchValue(template);
          this.template = template;
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected onRouteParams(params: {}) {
    if (this.templateId) {
      this.id = this.templateId;
    }
  }

}
