import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudSavePopupComponent } from '../../_directives';
import { AlertService, CheckoutsService } from '../../_services';
import { Location } from '@angular/common';
import { CheckoutGatewayType, CheckoutPopup, PopupActionMap, CheckoutStatus } from '../../_models';
import { Observable } from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  selector: 'checkout-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['../checkout-listing/checkouts.component.scss'],
})
export class CheckoutPopupComponent extends CrudSavePopupComponent implements OnInit {
  ngModelOptions = { standalone: true };
  isCheckoutPopup: boolean = true;
  CheckoutPopup = CheckoutPopup;
  CheckoutGatewayType = CheckoutGatewayType;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected checkoutService: CheckoutsService,
    protected alertService: AlertService
  ) {
    super(router, location, route, checkoutService, alertService);
    this.objectName = 'Checkout';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        name: [null, Validators.required],
        campaign: [null, Validators.required],
        gateway_type: [null, Validators.required],
        subdomain: [null, Validators.required],
      })
    );

    super.ngOnInit();
  }

  onSubmit() {
    this.setValidators();
    this.submitted = true;

    if (
      this.form.valid ||
      this.inputData.popupType === CheckoutPopup.Delete ||
      this.inputData.popupType === CheckoutPopup.Archive
    ) {
      this.loading = true;
      const { popupType, checkout_id, checkout_data, ...formData } = this.getFormData();

      if (popupType === CheckoutPopup.Create) {
        this.checkoutService.checkSubdomain(formData.subdomain).subscribe(
          () => {
            this.onSave.emit(formData);
            this.loading = false;
          },
          (error) => {
            this.form.get('subdomain').setErrors({ string: error.error.subdomain[0] });
            this.loading = false;
          }
        );
        return;
      } else {
        let operation: Observable<Object>;

        if (popupType === CheckoutPopup.Update) {
          operation = this.checkoutService.patch(checkout_id, { name: formData.name });
        } else if (popupType === CheckoutPopup.Duplicate) {
          operation = this.checkoutService.create({ ...checkout_data });
        } else if (popupType === CheckoutPopup.Delete) {
          operation = this.checkoutService.delete(checkout_id);
        } else if (popupType === CheckoutPopup.Archive) {
          operation = this.checkoutService.patch(checkout_id, { status: CheckoutStatus.Archived });
        } else if (popupType === CheckoutPopup.Unarchive) {
          operation = this.checkoutService.patch(checkout_id, { status: CheckoutStatus.Inactive });
        }

        const operationTitle = PopupActionMap[popupType];

        operation.subscribe(
          (data) => {
            if (this.showSuccessMessage) {
              this.alertService.success(`${this.objectName} ${operationTitle} Successfully.`, true);
            }

            this.canDeactivateService.setFormDirty(false);
            this.onSaveComplete(data);
          },
          (error) => {
            this.handleSubmitError(error);
            this.loading = false;
          }
        );
      }
    }
  }

  setValidators(): void {
    if (this.inputData.popupType !== CheckoutPopup.Create) {
      this.form.controls['campaign'].clearValidators();
      this.form.controls['gateway_type'].clearValidators();
      this.form.controls['subdomain'].clearValidators();
    }

    this.form.controls['campaign'].updateValueAndValidity();
    this.form.controls['gateway_type'].updateValueAndValidity();
    this.form.controls['subdomain'].updateValueAndValidity();
  }

  cancel() {
    this.resetForm();
    this.onCancel.emit();
  }

  protected getFormData() {
    const data = {
      ...this.form.value,
      checkout_id: this.inputData.checkout_id,
      popupType: this.inputData.popupType,
      campaigns: this.inputData.campaigns,
      checkout_data: this.inputData.checkout_data,
    };
    return data;
  }
}
