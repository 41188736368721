import { Component, OnInit } from '@angular/core';
import { CampaignMenu, CampaignMenuSections } from "../_models";
import { CampaignService, AuthenticationService } from '../_services';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-campaign-menu',
  templateUrl: './campaign-menu.component.html',
  styleUrls: ['./campaign-menu.component.css']
})
export class CampaignMenuComponent implements OnInit {
  menu: CampaignMenu;
  title = '';
  sections = [];

  private normalSections = CampaignMenuSections.normal;
  private hybridSections = CampaignMenuSections.hybrid;

  constructor(
    private campaignService: CampaignService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.campaignService.getMenu().subscribe((menu: CampaignMenu) => {
      this.menu = menu;

      if (menu) {
        if (!menu.activeSection || !menu.activeSection.length) {
          this.menu.activeSection = 'edit'; //default section
        }

        this.title = menu.campaign ? menu.campaign.name : 'New Brand';
        this.sections = menu.isHybrid ? this.hybridSections : this.normalSections;
      }
    });
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

}
