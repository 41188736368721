import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CheckoutTemplate,
  CheckoutTemplateTypeLabels,
  CheckoutTemplateTypeDescription,
  TemplateTypeMap,
  CheckoutGatewayType,
  CheckoutGatewayTypeLabels,
} from '../../_models';
import { CrudGetComponent } from '../../_directives';
import { Location } from '@angular/common';
import { AlertService, CheckoutsService, CheckoutTemplatesService, LoaderService } from '../../_services';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  selector: 'checkout-template-details',
  templateUrl: './checkout-template-details.component.html',
  styleUrls: ['./checkout-template-details.component.scss'],
})
export class CheckoutTemplateDetailsComponent extends CrudGetComponent implements OnInit {
  showBackdrop: boolean = false;
  hoveredIndex: number | null = null;
  isConfirmationPage: boolean = false;
  CheckoutGatewayType = CheckoutGatewayType;
  CheckoutGatewayTypeLabels = CheckoutGatewayTypeLabels;
  templateData: CheckoutTemplate;
  CheckoutTemplateTypeLabels = CheckoutTemplateTypeLabels;
  CheckoutTemplateTypeDescription = CheckoutTemplateTypeDescription;
  checkoutData = {
    name: '',
    subdomain: '',
    campaign: null,
    account_type: null,
    gateway_type: null,
    template: null,
  };

  // Dummy related templates
  templates = [
    { id: 12, name: 'One-Step Checkout', status: CheckoutGatewayType.Verified },
    { id: 14, name: 'E-commerce Checkout', status: CheckoutGatewayType.Unverified },
    { id: 18, name: 'Inline Checkout', status: CheckoutGatewayType.Verified },
    { id: 21, name: 'Pop-up Checkout', status: CheckoutGatewayType.Unverified },
  ];
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected checkoutTemplates: CheckoutTemplatesService,
    protected alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService,
    protected checkoutService: CheckoutsService
  ) {
    super(router, location, route, checkoutTemplates, alertService);
  }

  ngOnInit(): void {
    this.loading = true;
    super.ngOnInit();
    this.fetchTemplateDetails();
    this.extractRouteParams();
  }

  fetchTemplateDetails(): void {
    this.data$.subscribe(
      (template: CheckoutTemplate) => {
        if (template) {
          this.templateData = template;
          this.loading = false;
        }
      },
      (error) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  createCheckout(): void {
    this.loading = true;
    this.checkoutService.create(this.checkoutData).subscribe(
      (checkout: CheckoutTemplate) => {
        this.navigateToBuilder(checkout.id);
        this.loading = false;
      },
      (error) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  getTemplateType(type: number): string {
    return TemplateTypeMap[type];
  }

  setHoveredIndex(index: number): void {
    this.hoveredIndex = index;
  }

  clearHoveredIndex(): void {
    this.hoveredIndex = null;
  }

  previewTemplate(template: any) {}

  selectTemplate(template: any) {}

  navigateToBuilder(checkoutId: string | number): void {
    this.router.navigate(['/checkouts/checkout-builder', checkoutId]);
  }

  navigateToMainPage(): void {
    this.router.navigate(['/checkouts']);
  }

  navigateToTemplates(): void {
    this.router.navigate([
      'checkouts',
      'templates',
      this.checkoutData.name,
      'campaign',
      this.checkoutData.campaign,
      'account',
      this.checkoutData.account_type,
      'gateway',
      this.checkoutData.gateway_type,
      'subdomain',
      this.checkoutData.subdomain,
    ]);
  }

  navigateToCheckoutBuilder(): void {
    this.router.navigate(['/checkouts', 'checkout-builder']);
  }

  getThumbnailUrl(thumbnail: string | null): string {
    const defaultImage = '/assets/stepAssets/template-thumbnail.svg';
    const timestamp = new Date().getTime(); // Cache buster using current timestamp
    const imageUrl = thumbnail || defaultImage;

    return `${imageUrl}?v=${timestamp}`;
  }

  private extractRouteParams(): void {
    this.route.params.subscribe((params) => {
      this.checkoutData.name = params.name;
      this.checkoutData.subdomain = params.subdomain;
      this.checkoutData.campaign = Number(params.campaign);
      this.checkoutData.account_type = Number(params.account_type);
      this.checkoutData.gateway_type = Number(params.gateway_type);
      this.checkoutData.template = Number(params.id);
    });
  }
}
