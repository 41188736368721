import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, EmailIntegrationService} from '../_services';
import {FormBuilder, FormControl} from '@angular/forms';
import {EmailIntegration} from '../_models';
import {EmailIntegrationForwardingNewComponent} from './email-integration-forwarding-new.component';


@Component({
  selector: 'app-forwarding-integration-edit',
  templateUrl: './email-integration-forwarding-edit.component.html'
})
export class EmailIntegrationForwardingEditComponent extends EmailIntegrationForwardingNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected emailIntegrationService: EmailIntegrationService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, emailIntegrationService, alertService, formBuilder);
    this.isNew = false;
    this.title = 'Edit Email Integration';
  }
  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe((item: EmailIntegration) => {
      if (item) {
        this.emailIntegration = item;
        this.objectName = item.name;
        this.form.removeControl('email');
        this.form.removeControl('type');
        this.form.patchValue({
          name: item.name
        });
        this.form.addControl('email', new FormControl({value: item.email, disabled: true}));
      }
    }, error => {
      this.handleError(error);
    });
  }
}
