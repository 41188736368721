import {formatMoney} from "../_helpers/functions";
import {BaseModel} from "./base-model";
import {Image, ImageSize} from "./image";
import {CRMCampaign} from "./crm";

export interface Section extends BaseModel {
  name: string;
}

export interface ProductInfo extends BaseModel {
  section: string | number;
  content: string;
}

export enum FulfillmentTypeEnum {
  Standard = 0,
  NoShipment = 1
}

export interface SalesPhrase extends BaseModel {
  text: string;
  slug: string;
}

export interface VariantOption extends BaseModel {
  name: string;
  value: string;
}

export interface Variant extends BaseModel {
  variant_id: string;
  sku: string;
  price: number;
  shipping_price: number;
  override_price: number;
  override_shipping_price: number;
  image: Image;
  options: VariantOption[];
  min_price: number;
  member_price: number;
  retail_price: number;
}

export enum VariantTypeEnum {
  None = 0,
  Standard = 1,
  Bundled = 2
}

export enum ProcessTypeEnum {
  Sale = 1,
  Fee = 2,
  Warranty = 3,
  Installment = 4
}

export interface RelatedProduct extends BaseModel {
  name: string;
  product_id: string;
  sku: string;
  description: string;
  msrp: number;
  default_image: Image;
  images: Image[];
  blurb: string;
  sales_phrase: string | number;
  fulfillment_type: FulfillmentTypeEnum;
  quantity: number;
  process_type: ProcessTypeEnum;
  hold_period: number;
  hold_period_max_cycle: number;
  shipping_hold_period: number;
  variant_type: VariantTypeEnum;
}

export interface TaggedImage {
  id?: number;
  tag: string;
  image: Image;
  mobile_image: Image;
}

export interface Product extends RelatedProduct {
  crm: string | number;
  price: number;
  shipping_price: number;
  categories: string[] | number[];
  info: ProductInfo[];
  troubleshooters: string[] | number[];
  min_price: number;
  variants: Variant[];
  crm_quantity: number;
  override_quantity: number;
}

export enum ProductTypeEnum {
  Offer = 0,
  Upsell = 1,
  LinkedUpsell = 2
}

export enum BillingCycleTypeEnum {
  OneTime = 0,
  Recurring = 1,
  MultiPay = 2
}

export const BillingCycleTypeMap = {
  [BillingCycleTypeEnum.OneTime]: 'One Time',
  [BillingCycleTypeEnum.Recurring]: 'Recurring',
  [BillingCycleTypeEnum.MultiPay]: 'Multi-Pay',
};

export interface Discount extends BaseModel {
  price: number;
}

export enum ProductStatusEnum {
  Normal = 0,
  ReadOnly = 1,
  Hidden = 2,
  Cancellable = 3,
  HideIfFreeFirstCycle = 4,
}

export enum TrialTypeEnum {
  None = 0,
  Standard = 1,
  Delayed = 2,
  Accelerated = 3
}

export enum DiscountPriceTypeEnum {
  Price = 1,
  ShippingPrice = 2,
  TotalPrice = 3
}

export const DiscountPriceTypeMap = {
  [DiscountPriceTypeEnum.Price]: 'Price',
  [DiscountPriceTypeEnum.ShippingPrice]: 'Shipping Price',
  [DiscountPriceTypeEnum.TotalPrice]: 'Price + Shipping',
};

export enum SubscriptionDisplayType {
  Never = 1,
  Always = 2,
  AfterFirstCycle = 3
}

interface RelatedCampaignProductBase extends BaseModel {
  name: string;
  campaign_product_id: string;
  price: string;
  shipping_price: string;
  product_type: ProductTypeEnum;
  billing_cycle_type: BillingCycleTypeEnum;
  trial_type: TrialTypeEnum;
  subtext: Array<string>;
  bundle_quantity: number;
  image: Image;
  product_id: string | number;
}

export interface RelatedCampaignProduct extends RelatedCampaignProductBase {
  product: RelatedProduct;
}

export interface UpsaleProduct extends BaseModel {
  from_campaign_product: string | number;
  to_campaign_product: RelatedCampaignProduct;
  quantity: number;
  price: string;
  shipping_price: string;
  total_price: string;
  total_shipping_price: string;
  display_name: string;
  to_variant: Variant;
}

export interface ExchangeableProduct extends UpsaleProduct {
  sales_phrase: string | number;
  subtext: Array<string>;
  styles?: object;
}

export interface Cycle extends BaseModel {
  price: string;
  shipping_price: string;
  is_shippable: boolean;
  bill_delay: number;
  min_price: number;
  product: RelatedProduct;
  combination: RelatedProduct;
  display_name: string;
  return_days: number;
  exchange_days: number;
}

export interface CampaignProduct extends RelatedCampaignProductBase {
  crm: string | number;
  default_crm_campaign: string | number;
  crm_campaigns: CRMCampaign[];
  final_billing_cycle: number;
  offer_terms: string;
  product: Product;
  discounts: Discount[];
  status: ProductStatusEnum;
  max_quantity: number;
  min_price: string;
  url: string;
  related_products: any[];
  upsale_products: UpsaleProduct[];
  exchangeable_products: ExchangeableProduct[];
  has_exchangeable_products: boolean;
  linked_products: any[];
  override_price: string;
  override_shipping_price: string;
  restock_fee: number;
  tagged_images: TaggedImage[];
  crm_product_type: ProductTypeEnum;
  override_product_type: ProductTypeEnum;
  discount_type: DiscountPriceTypeEnum;
  cycles: Cycle[];
  offer_id?: string;
  billing_model_id?: string;
  membership_term: number;
  membership_discount: number;
  meta_description: string;
  keywords: string[];
  override_process_type: ProcessTypeEnum;
  subscription_display_type: SubscriptionDisplayType;
  show_next_bill_price: boolean;
  member_price: string;
  retail_price: string;
  return_days: number;
  exchange_days: number;
  max_extra_return_days: number;
  offer_name: string;
  exchangeable_sets: ExchangeableProductsSet[];
  third_party_recurring: boolean;
  change_billing_interval: boolean;
}

export interface ExchangeableSetItem extends ExchangeableProduct {
  from_campaign_product: null
}

export interface ExchangeableProductsSet extends BaseModel {
  from_campaign_products: CampaignProduct[],
  exchangeable_items: ExchangeableSetItem[],
  name: string,
  set_type_id?: string | number
}

export enum RelatedProductCategoryType {
  NONE = 0,
  EXCHANGEABLE = 1
}

export enum RelatedProductCategoryTypeLabel {
  'None',
  'Exchangeable'
}

export enum RelatedProductSetType {
  NONE = 0,
  EXCHANGEABLE = 1
}

export enum RelatedProductSetTypeLabel {
  'None',
  'Exchangeable'
}

export interface RelatedProductSet extends BaseModel {
  name: string,
  type: RelatedProductSetType,
  category_id?: string | number,
  exchangeable_sets?: ExchangeableProductsSet[]
}

export interface RelatedProductSetCategory extends BaseModel {
  name: string,
  type: RelatedProductCategoryType,
  parent_id?: string | number,
  sub_categories?: RelatedProductSetCategory[],
  set_types?: RelatedProductSet[]
}

export interface CampaignProductFormData {
  dimensions?: object,
  filtersData?: object,
  filters?: object,
  account?: Array<{ id: string | number, text: string }>,
  crm?: Array<{ id: string | number, text: string }>
}

export function getPlaceholderImageUrl(size: ImageSize) {
  const sizes = {
    original: 400,
    small: 100,
    medium: 200,
    large: 400
  };
  const sizeString = sizes[size].toString();

  return 'https://via.placeholder.com/' + sizeString + 'x' + sizeString + '?text=No+Image';
}

export function getProductImageUrl(product: RelatedProduct, size: ImageSize = ImageSize.original) {
  let image = null;

  if (product) {
    image = product.default_image;

    if (!image && product.images.length) {
      image = product.images[0];
    }
  }

  return image && image.file ? image.file[size] : getPlaceholderImageUrl(size);
}

export function getCampaignProductImageUrl(campaignProduct, size: ImageSize = ImageSize.original) {
  if (campaignProduct.image && campaignProduct.image.file) {
    return campaignProduct.image.file[size];
  }

  return getProductImageUrl(campaignProduct.product, size);
}

export function getCampaignProductImageHtml(campaignProduct, size: ImageSize = ImageSize.original) {
  const src = getCampaignProductImageUrl(campaignProduct, size);

  return `<img class="product-image editor-image" src="${src}" title="${campaignProduct.name}" alt="" />`;
}

export function generateFilterQuery(formData: CampaignProductFormData) {
  let data = {};
  const accounts = [];
  if (Array.isArray(formData.account)) {
    formData.account.forEach(item => {
      accounts.push(item.id);
    });
  } else {
    accounts.push(formData.account);
  }
  if (accounts) {
    data['accounts'] = accounts;
  }

  if (formData.crm) {
    data['crm'] = formData.crm;
  }

  let formFilters = formData.filtersData;
  let selectedFilters = formData.filters || {};

  Object.keys(selectedFilters).forEach(key => {
    if (selectedFilters[key]) {
      let value = formFilters[key];

      if (value !== null || value !== undefined || value !== '') {
        if (Array.isArray(value)) {
          if (value.length) {
            data[key] = [];
            value.forEach(val => {
              data[key].push(((typeof val === 'object') && ('id' in val)) ? val['id'] : val);
            });

            if (value.length > 1) {
              data[key + '__in'] = data[key].join(',');
              delete data[key];
            } else {
              data[key] = data[key][0];
            }
          }
        } else {
          data[key] = (value && (typeof value === 'object') && ('id' in value)) ? value['id'] : value;
        }
      }
    }
  });

  return data;
}

export function compareExchangeableProducts(list1: ExchangeableProduct[], list2: ExchangeableProduct[]) {
  let listsMatch = true

  for (const item1 of list1) {
    let matchFound = false

    for (const item2 of list2) {
      matchFound = item1.to_campaign_product.id === item2.to_campaign_product.id && item1.quantity === item2.quantity &&
        item1.price === item2.price && item1.shipping_price === item2.shipping_price &&
        ((item1.to_variant === null && item2.to_variant === null) ||
          (item1.to_variant && item2.to_variant && item1.to_variant.id === item2.to_variant.id))
      if (matchFound) {break}
    }

    if (!matchFound) {
      listsMatch = false
      break
    }

  }

  return listsMatch
}

export function getVariantLabel(variant: Variant, includePrice: boolean = true) {
  let options = [];
  let label = '';

  if (variant) {
    variant.options.forEach((option) => {
      options.push(option.name + ': ' + option.value);
    });

    label = options.join(', ');

    if (includePrice && variant.price > 0) {
      label += ' ($' + formatMoney(variant.price) + ')';
    }
  }

  return label;
}

export function getExchangeableName(exchangeableProduct: UpsaleProduct) {
  let name = exchangeableProduct.to_campaign_product.name;
  const variantLabel = getVariantLabel(exchangeableProduct.to_variant);

  if (variantLabel) {
    name += ' - ' + variantLabel;
  }

  return exchangeableProduct.display_name ? exchangeableProduct.display_name : name;
}

export function getExchangeableProductPrice(exchangeableProduct: UpsaleProduct) {
  const variantPrice = exchangeableProduct.to_variant ? exchangeableProduct.to_variant.price : null;
  return exchangeableProduct.total_price ?
    formatMoney(exchangeableProduct.total_price) :
    formatMoney(exchangeableProduct.quantity * +(variantPrice || exchangeableProduct.to_campaign_product.price))
}
