import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ProxyKeyService} from '../_services';
import {ApiKeyComponent} from '../_directives';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './user-api-key.component.html'
})
export class UserProxyKeyComponent extends ApiKeyComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected keyService: ProxyKeyService,
    protected alertService: AlertService
  ) {
    super(router, location, route, keyService, alertService);
    this.objectName = 'Proxy Key';
    this.title = 'Proxy Keys';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
