import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CheckoutProcessorUserNewComponent } from './checkout-processor-user-new.component';

@Component({
  selector: 'checkout-processor-user-edit',
  templateUrl: './checkout-processor-user-edit.component.html',
})
export class CheckoutProcessorUserEditComponent extends CheckoutProcessorUserNewComponent implements OnInit {
  constructor(protected router: Router, protected location: Location) {
    super(router, location);
  }

  ngOnInit() {
    this.isNew = false;
  }
}
