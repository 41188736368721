import {HttpClient, HttpParams} from '@angular/common/http';
import {CrudService} from './crud.service';
import {BehaviorSubject, Observable} from 'rxjs';

interface DownloadResponse {
  download_url: string
}


export abstract class LogsAbstractService extends CrudService {
  protected _data$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(protected http: HttpClient, protected route: string) {
    super(http, route);
  }

  private getDownloadLogsData(queryParams, id?: string | number) {
    const path = id ? this.route + '/' + id.toString() + '/download/' : this.route + '/download/';
    return this.http.get(path, {params: queryParams});
  }

  get data$() {
    return this._data$.asObservable();
  }

  list(queryParams?: any, id?: string | number): Observable<any> {
    let options: any = {'observe': 'response'};
    const path = id ? this.route + '/' + id.toString() + '/' : this.route + '/';

    if (queryParams) {
      options['params'] = new HttpParams({fromObject: queryParams});
    }

    return this.http.get<any>(path, options);
  }

  downloadLogs(queryParams, elem, id?: string | number) {
    this.getDownloadLogsData(queryParams, id).subscribe((data: DownloadResponse) => {
      let dwldLink = document.createElement("a");
      dwldLink.setAttribute("href", data.download_url);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      elem.loading = false;
    }, error => {
      elem.loading = false;
      if (error.status == 404) {
        elem.alertService.error("No logs to download");
        return;
      }
      elem.alertService.error("An error occurred while downloading logs");
    });
  }

  updateLogs(logsData){
    this._data$.next(logsData)
  }
}
