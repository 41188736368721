import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, StorageService } from '../_services';
import { FileUpload } from '../_forms';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  moduleId: module.id.toString(),
  selector: 'icon-upload',
  templateUrl: 'icon-upload.component.html',
  styleUrls: ['../image/image.component.css', './icon.component.css']
})
export class IconUploadComponent extends FileUpload implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected storageService: StorageService,
    protected domSanitizer: DomSanitizer
  ) {
    super(router, location, alertService, storageService, domSanitizer);
    this.path = 'icons/';
    this.title = 'Upload an Icon';
    this.options.allowedContentTypes = ['image/svg+xml', 'image/png'];
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
