import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {LogsContainerAbstractComponent} from "./logs-container.abstract.component";
import {AlertService, CampaignService} from "../_services";
import {Campaign, Pager} from "../_models";
import {config} from "../../config/config";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  templateUrl: './phone-logs-container.component.html',
  styleUrls: ['./logs.component.css']
})
export class PhoneLogsContainerComponent extends LogsContainerAbstractComponent implements OnInit {
  campaigns: Campaign[] = []

  constructor(
    protected alertService: AlertService,
    protected router: Router,
    protected location: Location,
    protected formBuilder: FormBuilder,
    private campaignService: CampaignService
  ) {
    super(alertService, router, location, formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.addControl('campaign_id', new FormControl(null, Validators.required));

    this.campaignService.list({page: 1, page_size: config.maxPageSize})
      .subscribe((result: Pager) => {
        this.campaigns = result.results

        if (this.campaigns.length === 1) {
          this.form.controls['campaign_id'].patchValue(this.campaigns[0].id)
        }

        this.ready = true;
      },
      error => {
        this.handleError(error);
        this.ready = true;
      });
  }

}
