import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CallCenterActionService, CampaignService} from '../_services';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {
  CallCenterAction,
  CallCenterHours,
  Campaign, CampaignStatus,
  ContactFormField,
  ContactType,
  ContactTypeLabels,
  LifelinePhoneSetting,
  LifelinePhoneSettingOptions,
  NotificationContact,
  PhoneContact,
  SipRegion,
  SipRegionLabels
} from "../_models";
import * as moment from 'moment-timezone';
import {takeUntil} from "rxjs/operators";

interface BusinessHours {
  label: string;
  isOpen: boolean;
  openTime: string;
  closeTime: string;
}

const WEEKDAYS: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-call-center.component.html',
  styleUrls: ['./campaign-edit.component.css']
})
export class CampaignCallCenterComponent extends CrudSaveComponent implements OnInit {
  isNewCampaign = true;
  domain: string = '';
  campaign: Campaign;
  callCenterHours: FormArray;
  supportEmailFields: FormArray;
  phoneContacts: FormArray;
  notificationContacts: FormArray;
  timezones: string[] = [];
  actions: CallCenterAction[] = [];
  sipEnabled = false;
  actionFields = [
    {name: 'return_action', label: 'RMA Return'},
    {name: 'exchange_action', label: 'RMA Exchange'},
    {name: 'cancel_action', label: 'Cancellation (Hold Status)'},
    {name: 'cancel_subscription_action', label: 'Cancel Subscription'},
  ];
  sipRegions: {value: number, label: string}[] = [];
  lifelinePhoneSettings = LifelinePhoneSettingOptions;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    private actionService: CallCenterActionService
  ) {
    super(router, location, route, campaignService, alertService);
    this.isPartial = true;
    this.objectName = 'Brand';
    this.title = 'Call Center Settings';
    this.timezones = moment.tz.names();

    SipRegionLabels.forEach((label, value) => {
      this.sipRegions.push({value: value, label: label});
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      timezone: [null, Validators.required],
      support_phone: [null],
      lifeline_phone_setting: [LifelinePhoneSetting.PhoneSessionAndCallCenterOpen, Validators.required],
      show_phone_link: [LifelinePhoneSetting.Never, Validators.required],
      support_email: [null, Validators.email],
      support_email_fields: this.formBuilder.array([]),
      phone_contacts: this.formBuilder.array([]),
      notification_contacts: this.formBuilder.array([]),
      return_action: [null],
      exchange_action: [null],
      cancel_action: [null],
      cancel_subscription_action: [null],
      call_center_hours: this.formBuilder.array([]),
      sip_domain: this.formBuilder.group({
        outbound_uri: [null],
        outbound_username: [null],
        outbound_password: [null],
        outbound_secure: [false],
        outbound_refer: [false],
        region: [SipRegion.Default]
      }),
    });

    this.supportEmailFields = this.form.get('support_email_fields') as FormArray;
    this.phoneContacts = this.form.get('phone_contacts') as FormArray;
    this.notificationContacts = this.form.get('notification_contacts') as FormArray;
    this.callCenterHours = this.form.get('call_center_hours') as FormArray;

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;
    WEEKDAYS.forEach((day: string) => {
      this.callCenterHours.push(this.createCallCenterHoursFormField({
        label: day,
        isOpen: false,
        openTime: '09:00 am',
        closeTime: '05:00 pm'
      }))
    });

    this.data$.subscribe(
      (campaign: Campaign) => {
        if (campaign) {
          if (!campaign.support_email_fields) {
            campaign.support_email_fields = []
          }

          this.form.patchValue(Object.assign({}, campaign, {sip_domain: campaign.sip_domain || {}}));
          this.campaign = campaign;
          this.campaignService.showMenu(campaign, 'call-center', false, campaign.is_hybrid);
          this.sipEnabled = !!(campaign.sip_domain && campaign.sip_domain.outbound_uri)

          //parse the existing call center hours
          if (campaign.call_center_hours && campaign.call_center_hours.length) {
            campaign.call_center_hours.forEach((hours: CallCenterHours) => {
              this.addCallCenterHours(WEEKDAYS[hours.dow], true, hours.starttime, hours.endtime, hours.dow);
            });
          }

          if (campaign.phone_contacts && campaign.phone_contacts.length) {
            campaign.phone_contacts.forEach((contact: PhoneContact) => {
              this.addPhoneContact(contact.contact_type, contact.phone);
            });
          }

          if (campaign.notification_contacts && campaign.notification_contacts.length) {
            campaign.notification_contacts.forEach((contact: NotificationContact) => {
              this.addNotificationContact(contact.contact_type, contact.phone_sms, contact.email);
            });
          }

          campaign.support_email_fields.forEach((field: ContactFormField) => {
            this.addContactFormField(field.name, field.label, field.type, field.required, field.values);
          });

          this.addDefaultContactFormFields(); // add the default contact form fields if they're missing
        }
      },
      error => {
        this.handleError(error);
      }
    );

    this.actionService.list()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (actions: CallCenterAction[]) => {
          this.actions = actions;
        },
        error => {
          this.handleError(error);
        }
      );

    (this.form.get('call_center_hours') as FormArray).controls[0].setErrors({required: true})
  }

  setCallCenterOpenTime(index, timeStamp) {
    const control = (this.form.get('call_center_hours') as FormArray).at(index).get('openTime');
    control.patchValue(timeStamp);
  }

  setCallCenterCloseTime(index, timeStamp) {
    const control = (this.form.get('call_center_hours') as FormArray).at(index).get('closeTime');
    control.patchValue(timeStamp);  }

  addCallCenterHours(label: string = null, isOpen: boolean = false, openTime: string = null, closeTime: string = null,
                     dow: number = 0) {
    let fields = this.form.get('call_center_hours') as FormArray;
    fields.removeAt(dow);

    fields.insert(dow, this.createCallCenterHoursFormField({
      label: label,
      isOpen: isOpen,
      openTime: this.parseTime(openTime),
      closeTime: this.parseTime(closeTime),
    } as BusinessHours));
  }

  isSipAvailable() {
    return this.campaign && (this.campaign.status !== CampaignStatus.Inactive)
  }

  private createCallCenterHoursFormField(field: BusinessHours): FormGroup {
    return this.formBuilder.group({
      label: [field.label, [Validators.required]],
      isOpen: [field.isOpen, [Validators.required]],
      openTime: [field.openTime, [Validators.required]],
      closeTime: [field.closeTime, [Validators.required]],
    },  {validators: this.callCenterFieldValidator.bind(this)});
  }

  private callCenterFieldValidator(group: FormGroup) {
    if (!group.touched) {
      return;
    }
    let controls = group.controls;
    let openTimeValue = controls.openTime.value;
    let closeTimeValue = controls.closeTime.value;
    openTimeValue = this.formatTime(openTimeValue, false);
    closeTimeValue = this.formatTime(closeTimeValue, true);
    openTimeValue = openTimeValue.split(':').map(value => {return parseInt(value)});
    closeTimeValue = closeTimeValue.split(':').map(value => {return parseInt(value)});
    if (openTimeValue[0] > closeTimeValue[0]) {   // If open time hour is greater than close time hour
      group.controls.closeTime.setErrors({'invalid': 'End time should be greater than start time'});
    }
    // if open time hours are equal to close time hours but open time minutes are greater than close time minutes
    else if (openTimeValue[0] == closeTimeValue[0] && openTimeValue[1] >= closeTimeValue[1]) {
      group.controls.closeTime.setErrors({'invalid': 'End time should be greater than start time'});
    } else {
      group.controls.closeTime.setErrors(null);
    }
  }

  private createContactFormField(field: ContactFormField) : FormGroup {
    return this.formBuilder.group({
      name: [field.name, [Validators.required]],
      label: [field.label, [Validators.required]],
      type: [field.type, [Validators.required]],
      required: [field.required, [Validators.required]],
      values: [field.values && field.values.length ? field.values.join("\n") : '']
    });
  }

  protected addDefaultContactFormFields() {
    let fields = this.form.get('support_email_fields') as FormArray;
    let hasEmailField = false;
    let hasMessageField = false;

    for (let field of fields.controls) {
      const fieldName = (field as FormGroup).controls.name.value;

      if (fieldName === 'email') {
        hasEmailField = true;
      } else if (fieldName === 'message') {
        hasMessageField = true;
      }
    }

    if (!hasEmailField) {
      this.addContactFormField('email', 'Email', 'email', true)
    }

    if (!hasMessageField) {
      this.addContactFormField('message', 'Message', 'textarea', true)
    }
  }

  addContactFormField(name: string = null,
                      label: string = null,
                      type: string = 'text',
                      required: boolean = false,
                      values: string[] = []) {
    let fields = this.form.get('support_email_fields') as FormArray;

    fields.push(this.createContactFormField({
      name: name,
      label: label,
      type: type,
      required: required,
      values: values
    } as ContactFormField));
  }

  removeContactFormField(index) {
    let fields = this.form.get('support_email_fields') as FormArray;

    fields.removeAt(index);
  }

  isContactFieldEditable(index) : boolean {
    const control = (this.form.get('support_email_fields') as FormArray).at(index).get('name');

    return (control.value !== 'email') && (control.value !== 'message');
  }

  isContactFormFieldRequired(index) : boolean {
    const control = (this.form.get('support_email_fields') as FormArray).at(index).get('required');

    return control.value;
  }

  isCallCenterFormFieldOpen(index): boolean {
    const control = (this.form.get('call_center_hours') as FormArray).at(index).get('isOpen');

    return control.value;
  }

  formArrayFieldErrors(index, fieldName) {
    const control = (this.form.get('call_center_hours') as FormArray).at(index).get(fieldName);
    let errors = control.errors;
    if (errors) {
      if ('invalid' in errors) {
        return errors['invalid'];
      }
    }

    return '';
  }

  getContactFormFieldTypes(index) {
    let types = [
      {value: 'text', label: 'Text Input'},
      {value: 'email', label: 'Email'},
      {value: 'phone', label: 'Phone'},
      {value: 'select', label: 'Select Dropdown'},
      {value: 'textarea', label: 'Text Area'}
    ];

    if (!this.isContactFieldEditable(index)) {
      const control = (this.form.get('support_email_fields') as FormArray).at(index).get('type');

      types = types.filter(type => type.value === control.value);
    }

    return types;
  }

  requireContactFormField(event, index) {
    const control = (this.form.get('support_email_fields') as FormArray).at(index).get('required');

    if (this.isContactFieldEditable(index)) {
      control.patchValue(event.target.checked);
      return true;
    }

    event.target.checked = control.value; // field is read-only so restore the checked state
    return false;
  }

  changeCallCenterFormField(event, index) {
    const control = (this.form.get('call_center_hours') as FormArray).at(index).get('isOpen');
    control.patchValue(event.target.checked);
    return true;
  }

  getCallCenterDay(index): string {
    const control = (this.form.get('call_center_hours') as FormArray).at(index).get('label');
    return control.value;
  }

  showContactFormFieldSelectValues(index) {
    const control = (this.form.get('support_email_fields') as FormArray).at(index).get('type');

    return control.value === 'select';
  }

  private createPhoneContact(contact: PhoneContact) : FormGroup {
    return this.formBuilder.group({
      contact_type: [contact.contact_type, [Validators.required]],
      phone: [contact.phone, [Validators.required]]
    });
  }

  addPhoneContact(type: ContactType = null, phone: string = null) {
    let fields = this.form.get('phone_contacts') as FormArray;

    fields.push(this.createPhoneContact({
      contact_type: type,
      phone: phone
    } as PhoneContact));
  }

  removePhoneContact(index) {
    let fields = this.form.get('phone_contacts') as FormArray;

    fields.removeAt(index);
  }

  private createNotificationContact(contact: NotificationContact) : FormGroup {
    return this.formBuilder.group({
      contact_type: [contact.contact_type],
      phone_sms: [contact.phone_sms],
      email: [contact.email, [Validators.email]]
    });
  }

  addNotificationContact(type: ContactType = null, phone: string = null, email: string = null) {
    let fields = this.form.get('notification_contacts') as FormArray;

    fields.push(this.createNotificationContact({
      contact_type: type,
      phone_sms: phone,
      email: email
    } as NotificationContact));
  }

  removeNotificationContact(index) {
    let fields = this.form.get('notification_contacts') as FormArray;

    fields.removeAt(index);
  }

  getContactTypes() {
    let types = [];

    ContactTypeLabels.forEach((value, index) => {
      if (index > 0) {
        types.push({id: index, label: value});
      }
    });

    return types;
  }

  protected getFormData() {
    let callCenterHours = [];
    let contactFormFields = [];
    let sipDomain = this.form.value.sip_domain;

    if (!this.sipEnabled) {
      sipDomain.outbound_uri = ''; // clear the outbound sip uri if sip is disabled
    }

    if (this.form.value.call_center_hours && this.form.value.call_center_hours.length) {
      this.form.value.call_center_hours.forEach((day: BusinessHours, dow: number) => {
        if (day.isOpen) {
          callCenterHours.push({
            dow: dow,
            starttime: this.formatTime(day.openTime, false),
            endtime: this.formatTime(day.closeTime, true),
          })
        }
      })
    }

    //convert support email fields select values from string to array
    if (this.form.value.support_email_fields && this.form.value.support_email_fields.length) {
      this.form.value.support_email_fields.forEach(field => {
        if (field.values && field.values.length) {
          let val = field.values.trim();

          field.values = (val.length) ? val.split("\n") : [];
        }
        contactFormFields.push(field);
      });
    }

    return Object.assign({}, this.form.value, {
      call_center_hours: callCenterHours,
      support_email_fields: contactFormFields,
      sip_domain: sipDomain
    });
  }

  protected onSaveComplete(data) {
    if (this.isNewCampaign) {
      this.campaignService.getNextMenuRoute().pipe(takeUntil(this.destroy$)).subscribe((route: string) => {
        this.navigate(['/campaign', route, this.id, {isNewCampaign: true}], {replaceUrl: true});
      });
    }
    else {
      this.goBack();
    }
  }

  protected parseTime(timeString: string) : string {
    let parts = timeString.split(':');

    //convert end of day timestamp to midnight
    if ((parts[0] === '23') && (parts[1] === '59') && (parts[2] === '59')) {
      return '12:00 am';
    }

    //convert 24 hour timestamp to 12 hour HH:MM am/pm format
    let hour = parseInt(parts[0]);
    let meridian = 'am';
    let pad = '';

    if (hour == 0) {
      // 0 hour is 12 am in midnight
      hour = 12;
      meridian = 'am';
    }
    else if (hour > 12) {
      hour -= 12;
      meridian = 'pm';
    }
    else if (hour == 12) {
      // 12 hour is 12 in after noon, that is pm
      meridian = 'pm'
    }

    if (hour < 10) {
      pad = '0';
    }

    return pad + hour.toString() + ':' + parts[1] + ' ' + meridian;
  }

  protected formatTime(timeStamp: string, isEndTime: boolean) {
    if (isEndTime && (timeStamp === '12:00 am')) {
      return '23:59:59'; //convert midnight end time to the last second of the day
    }

    let parts = timeStamp.split(' ');
    let meridian = parts[1];
    let timeParts = parts[0].split(':');
    let hourString = (timeParts[0][0] == '0') ? timeParts[0].substr(1) : timeParts[0];
    let hour = parseInt(hourString);
    let pad = '';

    //convert 12 hour HH:MM am/pm timestamp to 24 hour HH:MM:SS format
    if (meridian == 'am') { //am
      if (hour == 12) {
        hourString = '00';
      }
      else if (hour < 10) {
        hourString = '0' + hour.toString();
      }
      else {
        hourString = hour.toString();
      }
    }
    else { //pm
      if (hour < 12) {
        hour += 12;
      }

      hourString = hour.toString();
    }

    return hourString + ':' + timeParts[1] + ':00';
  }

  protected handleSubmitError(error: any) {
    super.handleSubmitError(error);
    let mappings = {
      starttime: 'openTime',
      endtime: 'closeTime',
    }
    if ('error' in error && 'call_center_hours' in error.error) {
      let call_center_errors = error.error.call_center_hours;
      call_center_errors.forEach((error, index) => {
        Object.keys(error).forEach(key => {
          let errorMsg = error[key][0]
          key = mappings[key];
          (this.form.get('call_center_hours') as FormArray).at(index).get(key).setErrors({invalid: errorMsg})
        })
      })
    }
  }
}
