import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CallCenterActionService} from '../_services';
import {CallCenterAction} from '../_models';
import {CrudListComponent} from '../_directives';
import {Observable} from "rxjs";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './call-center-action.component.html'
})
export class CallCenterActionComponent extends CrudListComponent implements OnInit {
  actions$: Observable<CallCenterAction[]> = this.data$;
  selectedAction: CallCenterAction;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected actionService: CallCenterActionService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, actionService, alertService);
    this.objectName = 'call center action';
    this.title = 'Call Center Actions';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  create() {
    this.selectedAction = {
      id: null,
      name: null
    } as CallCenterAction;
    this.openEditDialog();
  }

  edit(action: CallCenterAction) {
    this.selectedAction = action;
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('callCenterActionDialog').open();
  }

  onSaveAction(action: CallCenterAction) {
    this.modalService.getModal('callCenterActionDialog').close();
    this.queryData();
  }
}
