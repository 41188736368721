import { BaseModel } from './index';

export enum CRMTypeId {
  Konnektive = 1,
  Test = 2,
  Custom = 3,
  Sticky = 4,
  StickyLegacy = 5,
  Sublytics = 6,
  ShopifyPrivate = 7,
  ShopifyPublic = 8,
  Clickbank = 9,
  WooCommerce = 10,
}

export enum CRMTypeName {
  Konnektive = 'Konnektive',
  Test = 'Test',
  Custom = 'Custom',
  Sticky = 'Sticky',
  StickyLegacy = 'Sticky Legacy',
  Sublytics = 'Sublytics',
  ShopifyPrivate = 'Shopify Private Plugin',
  ShopifyPublic = 'Shopify Public Plugin',
  Clickbank = 'Clickbank',
  WooCommerce = 'WooCommerce',
}

export enum SubscriptionPluginId {
  Recharge = 1,
}

export interface CRMField {
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  admin_only?: boolean;
  default?: string;
  min?: number;
  max?: number;
  showPassword?: boolean;
  rows?: number;
  help?: string;
  read_only?: boolean;
}

export interface CRMFieldSet extends CRMField {
  fieldset: string;
  fields: CRMField[];
}

export interface CRMTrackingField {
  name: string;
  label?: string;
  read_only?: boolean;
}

export interface PluginType extends BaseModel {
  name: string;
  fields: (CRMField | CRMFieldSet)[];
  encrypted_fields: (CRMField | CRMFieldSet)[];
}

export interface CRMType extends BaseModel {
  name: string;
  fields: (CRMField | CRMFieldSet)[];
  encrypted_fields: (CRMField | CRMFieldSet)[];
  tracking_fields: {[key: string]: CRMTrackingField;};
  subscription_plugins: number[];
  auto_config: boolean;
}

export interface CRMTypes {
  crms: CRMType[];
  subscription_plugins: PluginType[];
}

export enum LinkFieldType {
  UniqueId = 1,
  ParentOrderId = 2,
  PreviousOrderId = 3
}

export interface CRM extends BaseModel {
  name: string;
  display_name: string;
  type: number;
  subscription_plugin: number;
  credentials: {[key: string]: string;};
  config: {[key: string]: string;};
  tracking_data: {[key: string]: string | null;};
  max_fulfillments: number;
  max_transactions: number;
  auto_approve: boolean;
  use_partial_refunds: boolean;
  can_update_fulfillment_item: boolean;
  can_partial_refund_on_cancel: boolean;
  allow_update_fulfillment: boolean;
  public_key: string;
  private_key: string;
  link_id_field: string;
  link_field_type: LinkFieldType;
  transaction_sync_status: TransactionSyncStatus;
  dispute_management: boolean;
  historic_data_sync_range: string;
  alert_reasons: { [key: string]: string[] };
  is_deleted: boolean;
}

export interface ShippingType extends BaseModel {
  crm: string | number;
  type_id: string;
  name: string;
  code: string;
  carrier: string;
}

export interface CRMCampaign extends BaseModel {
  name: string;
  crm: string | number;
  crm_campaign_id: string;
  campaign: string | number;
  keep_subscriptions_in_sync: boolean;
  separate_next_cycle_items: boolean;
  shipping_methods?: Array<CRMCampaignShippingMethod>;
}

export interface CRMCampaignShippingMethod extends BaseModel {
  name: string;
  shipping_id?: string | number;
}

export interface Customer extends BaseModel {
  crm: string | number;
  customer_id: string;
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  ship_first_name: string;
  ship_last_name: string;
  ship_company: string;
  ship_address1: string;
  ship_address2: string;
  ship_city: string;
  ship_state: string;
  ship_zip: string;
  ship_country: string;
  email: string;
  phone: string;
  default_payment_source: string | number;
  created: string
}

export interface Merchant extends BaseModel {
  merchant_id: string;
  name: string;
  gateway: string;
  mid_number: string;
  descriptor: string;
  mcc: string;
  processor: string;
  enabled: boolean;
}

export enum TransactionSyncStatus {
  DISABLED = 0,
  SYNCING = 1,
  ENABLED = 2
}
