import {Component, OnInit, ViewChild} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignProductService, ExchangeableProductsSetService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CampaignProduct, ExchangeableProduct, ExchangeableProductsSet, ExchangeableSetItem, ImageSize, Pager} from '../_models';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {formatMoney, getCampaignProductImageUrl, getPlaceholderImageUrl} from '../_helpers';
import {ExchangeableProductFieldsComponent} from '../exchangeable-product';
import {takeUntil} from 'rxjs/operators';
import {forkJoin} from 'rxjs';

@Component({
  moduleId: module.id,
  templateUrl: './exchangeable-products-set-edit.component.html',
  styleUrls: ['./exchangeable-products-set.component.css']
})
export class ExchangeableProductsSetNewComponent extends CrudSaveComponent implements OnInit {
  from_products: CampaignProduct[] = []
  to_products: ExchangeableSetItem[] = []
  selectedExchangeableProductIndex: number = -1;
  exchangeableProductsSet: ExchangeableProductsSet;

  // config for products pagination
  pageCount = 1;
  page = 1;
  pageSize = 15;
  totalResultCount = 0;

  rowsPerPageOptions = [
    {value: 15, label: "15"},
    {value: 25, label: "25"},
    {value: 50, label: "50"},
    {value: 100, label: "100"},
    {value: 250, label: "250"},
  ];

  displayProductsTable: boolean = false

  public set_type: string | number = null
  public showProductsPopup: boolean = false;

  @ViewChild(ExchangeableProductFieldsComponent, { static: false }) exchangeableProductFields: ExchangeableProductFieldsComponent;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected exchangeableProductsSetService: ExchangeableProductsSetService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
    protected campaignProductService: CampaignProductService
  ) {
    super(router, location, route, exchangeableProductsSetService, alertService);
    this.isNew = true
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      from_campaign_products: [null, null],
      exchangeable_items: [null, null]
    })
    super.ngOnInit();

    this.set_type = this.route.snapshot.params['type'] || null
  }

  private getExchangeableProductFormData(keepIds: boolean) {
    let data = [];

    // get the exchangeable products and convert the to_campaign_product to ids
    this.to_products.forEach((exchangeableProduct: ExchangeableSetItem) => {
      data.push(Object.assign({}, exchangeableProduct,
        {
          to_campaign_product: exchangeableProduct.to_campaign_product.id,
          id: keepIds ? exchangeableProduct.id : null,
          from_campaign_product: null
        }));
    });

    return data;
  }

  protected getFormData() {
    let data = this.form.value
    data['from_campaign_products'] = this.from_products.map(({id}) => id)
    data['exchangeable_items'] = this.getExchangeableProductFormData(false)
    data['set_type'] = this.set_type
    return data
  }

  closeProductSelection() {
    this.modalService.getModal('campaignProductSelectDialog').close()
    this.showProductsPopup = false;
  }

  openProductSelection() {
    this.showProductsPopup = true;
    this.modalService.getModal('campaignProductSelectDialog').open()
  }

  onProductsSelected(productIds: string[] | number[]) {
    this.closeProductSelection()
    this.addProductsToExchangeableSet(productIds)
  }

  getCampaignProductImageUrl(product: CampaignProduct) {
    return getCampaignProductImageUrl(product, ImageSize.small);
  }

  get selectedExchangeableProduct() {
    return (this.selectedExchangeableProductIndex >= 0) ?
      this.to_products[this.selectedExchangeableProductIndex] : null;
  }

  getExchangeableProductImageUrl(product: ExchangeableProduct, size = ImageSize.small) {
    return getCampaignProductImageUrl(product.to_campaign_product, size)
  }

  getAlternateExchangeableProductImageUrl(event, product: ExchangeableProduct) {
    if (event && event.currentTarget && !event.currentTarget.showOriginalImage) {
      event.currentTarget.showOriginalImage = true
      event.currentTarget.src = getCampaignProductImageUrl(product.to_campaign_product, ImageSize.original)
    } else {
      event.currentTarget.src = getPlaceholderImageUrl(ImageSize.small);
    }
  }
  getExchangeableProductPrice(exchangeableProduct: ExchangeableProduct) {
    return exchangeableProduct.total_price ?
      formatMoney(exchangeableProduct.total_price) :
      formatMoney(exchangeableProduct.quantity * exchangeableProduct.to_campaign_product.price)
  }

  addExchangeableProduct(exchangeableProduct?: ExchangeableSetItem) {
    exchangeableProduct = {
      to_campaign_product: null,
      quantity: 1
    } as ExchangeableSetItem;
    this.to_products.push(exchangeableProduct);
    this.editExchangeableProduct(this.to_products.length - 1);
  }

  editExchangeableProduct(index: number) {
    this.selectedExchangeableProductIndex = index;
    this.openExchangeableProductDialog();
  }

  removeExchangeableProduct(index: number) {
    this.to_products.splice(index, 1);
  }

  protected openExchangeableProductDialog() {
    this.modalService.getModal('exchangeableProductDialog').open();
  }

  onCloseExchangeableProductDialog() {
    this.to_products[this.selectedExchangeableProductIndex] = this.exchangeableProductFields.getFormData();
    this.selectedExchangeableProductIndex = -1;
    let control = this.form.controls['exchangeable_items']
    if (control.invalid) {
      control.setErrors(null)
      control.updateValueAndValidity({onlySelf: true})
    }
  }

  getProducts() {
    this.loading = true
    if (this.exchangeableProductsSet) {
      this.campaignProductService.list(
        {page: this.page, page_size: this.pageSize, exchangeable_sets: this.exchangeableProductsSet.id}
      ).pipe(takeUntil(this.destroy$))
        .subscribe(
          (page: Pager) => {
            this.loading = false
            this.from_products = page.results;
            this.setPagerData(page)
          },
          error => {
            this.loading = false
            this.handleError(error);
          }
        );
    }
  }

  addProductsToExchangeableSet(productIds: string[] | number[]) {
    this.loading = true
    let requests = []
    while (productIds.length > 0) {
      requests.push(this.exchangeableProductsSetService.add_campaign_products(this.exchangeableProductsSet.id,
        {campaign_products: productIds.splice(0, 200)}))
    }

    forkJoin(requests)
      .subscribe(_ => {
        this.loading = false
        this.getProducts()
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  removeProductFromExchangeableSet(productId) {
    this.loading = true
    this.exchangeableProductsSetService.remove_campaign_product(this.exchangeableProductsSet.id, {campaign_product: productId})
      .subscribe(_ => {
        this.loading = false
        this.getProducts()
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  //  paginator controls
  onPageChange(params) {
    this.page = params.page;
    this.getProducts()
  }

  protected resetPager() {
    this.page = 1;
  }

  protected setPagerData(data: Pager) {
    this.pageCount = data.num_pages;
    this.page = data.page;

    if (this.page === 1) {
      this.totalResultCount = data.count;
    }
  }

  changeRowsPerPage(event) {
    this.pageSize = Number(event.target.value);
    this.resetPager();
    this.getProducts();
  }

  toggleProductsDisplay() {
    this.displayProductsTable = !this.displayProductsTable
    if (this.displayProductsTable) {
      this.getProducts()
    }
  }
}
