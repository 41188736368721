import {InputTypes, Types} from '../index';

const handleLinkTraits = (component, type) => {
  if (type === Types.NONE) {
    component.removeTrait(['path-input', 'path', 'actions', 'action-btn', 'path-name']);
    if (component.getAttributes().elemtype !== InputTypes.RADIO_BUTTON) {
      if (!component.get('traits').where({name: 'path-url'})[0]) {
        component.addTrait({
          name: 'path-url',
          type: 'path-ext-url',
          label: 'Href'
        }, {at: 2});
      }
    }
    if (component.getAttributes().elemtype === InputTypes.LINK || component.getAttributes().elemtype === InputTypes.BUTTON) {
      if (!component.get('traits').where({name: 'target'})[0]) {
        component.addTrait('target');
      }
    }
  } else if (type === Types.PATH_INPUT) {
    if (component.get('traits').where({name: 'target'})[0]) {
      component.removeTrait('target');
    }
    if (component.get('traits').where({name: 'path-url'})[0]) {
      component.removeTrait('path-url');
    }
    if (!component.get('traits').where({name: 'path-name'})[0]) {
      component.addTrait({
        name: 'path-name',
        type: 'path-name-text',
        label: 'Input Label',
        changeProp: 1
      });
    }
    if (!component.get('traits').where({name: 'path-input'})[0]) {
      component.addTrait({
        name: 'path-input',
        type: 'set-path-input',
        label: 'Path Input',
        changeProp: 1
      }, {at: 2});
    }
    if (!component.get('traits').where({name: 'path'})[0]) {
      component.addTrait({
        name: 'path',
        type: 'set-path',
        label: 'Next Step',
        changeProp: 1
      }, {at: 3});
    }
    if (!component.get('traits').where({name: 'actions'})[0]) {
      component.addTrait({
        label: 'Actions',
        name: 'actions',
        changeProp: 1,
        full: true,
        type: 'input-actions'
      });
    }
    if (!component.get('traits').where({name: 'action-btn'})[0]) {
      component.addTrait({
        text: 'Add New Action',
        type: 'button',
        full: true,
        noLabel: true,
        name: 'action-btn',
        command: 'add-new-action'
      });
    }
  }

  return;
};

export default handleLinkTraits;
