import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService } from '../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { Form } from '../_forms'
import { Location } from "@angular/common";

@Component({
  moduleId: module.id.toString(),
  templateUrl: 'password-change.component.html'
})

export class PasswordChangeComponent extends Form implements OnInit {
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      current_password: [null, [Validators.required]],
      new_password: [null, [Validators.required, Validators.minLength(8)]],
      cpassword: [null, [Validators.required, Validators.minLength(8)]]
    }, {validators: this.mustMatch('new_password', 'cpassword')});
  }

  changePassword() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      this.authenticationService.changePassword(this.form.value.current_password, this.form.value.new_password)
        .subscribe(
          () => {
            this.alertService.success('Your password has been changed.', true);
            this.goBack();
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }
}
