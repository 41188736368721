import { Injectable } from "@angular/core";
import { DashboardService } from "../dashboard.service";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "../../alert.service";
import { StorageService } from "../../storage.service";

@Injectable({
  providedIn: "root",
})
export class OverviewPathChannelService extends DashboardService {
  route = "dashboard/path_channels";
  protected data: BehaviorSubject<Object> = new BehaviorSubject<Object>(
    undefined
  );
  constructor(
    protected http: HttpClient,
    protected alertService: AlertService,
    protected storageService: StorageService
  ) {
    super(http, alertService, storageService);
  }
}
