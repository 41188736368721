import {Component, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {ShippingSystemFieldsComponent} from "./shipping-system-fields.component";
import {tinyMCESingleLine, tinyMCETTextOnly} from "../_helpers";

@Component({
  moduleId: module.id.toString(),
  selector: 'shipping-system-label-fields',
  templateUrl: './shipping-system-label-fields.component.html'
})
export class ShippingSystemLabelFieldsComponent extends ShippingSystemFieldsComponent implements OnInit, OnChanges {
  editorInitCallback = tinyMCETTextOnly;
  editorSetupCallback = tinyMCESingleLine;
  labelVariables: any[] = [
    {value: '{order_id}', text: 'Order ID'},
    {value: '{rma_number}', text: 'RMA Number'},
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
  ) {
    super(router, location, alertService);
  }
}
