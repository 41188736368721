import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, VariantService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {Image, ImageType, Variant} from "../_models";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  selector: 'variant-form',
  templateUrl: './variant-form.component.html',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class VariantFormComponent extends CrudSavePopupComponent implements OnInit {
  variant: Variant;
  imageType: ImageType = ImageType.ProductImage;
  image: Image;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected variantService: VariantService,
              protected alertService: AlertService,
              private modalService: NgxSmartModalService
  ) {
    super(router, location, route, variantService, alertService);
    this.objectName = 'Variant';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      override_price: [null, Validators.min(0)],
      override_shipping_price: [null, Validators.min(0)],
      member_price: [null, Validators.min(0)],
      retail_price: [null, Validators.min(0)],
      min_price: [null, Validators.min(0)]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.variant = this.inputData;

    if (this.variant) {
      this.image = this.variant.image;
    }
  }

  removeImage() {
    this.image = null;
  }

  onImageSelectStart() {
    this.modalService.getModal('variantImageDialog').open();
  }

  onImageSelectDone(image: Image) {
    this.image = image;
    this.inputData['selectedImage'] = image.file;
    this.modalService.getModal('variantImageDialog').close();
  }

  getFormData() {
    let data: {} = this.form.value;
    const allow_blank_fields = ['override_price', 'override_shipping_price', 'min_price', 'member_price', 'retail_price'];

    //convert empty strings to null for certain fields so the api will accept them
    allow_blank_fields.forEach((field: string) => {
      if (field in data) {
        let value = data[field];

        if (typeof(value) == 'string') {
          if (value.trim().length == 0) {
            data[field] = null;
          }
        }
      }
    });

    if (this.image) {
      data['image'] = this.image.id;
    }
    else {
      data['image'] = null;
    }

    return data;
  }
}
