import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelStepGroupService} from '../_services';
import {FunnelStepGroup} from '../_models';
import {CrudPagedListComponent} from '../_directives';
import {Observable} from "rxjs";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-step-group.component.html'
})
export class FunnelStepGroupComponent extends CrudPagedListComponent implements OnInit {
  groups$: Observable<FunnelStepGroup[]> = this.data$;
  selectedGroup: FunnelStepGroup;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected groupService: FunnelStepGroupService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, groupService, alertService);
    this.objectName = 'group';
    this.title = 'Path Step Groups';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  create() {
    this.selectedGroup = null;
    this.openEditDialog();
  }

  edit(group: FunnelStepGroup) {
    this.selectedGroup = group;
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('editDialog').open();
  }

  onSaveGroup(group: FunnelStepGroup) {
    this.modalService.getModal('editDialog').close();
    this.queryData();
  }
}
