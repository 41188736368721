import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { StepDurationUnits, StepBasicLayout, StepElements, ActionHoldoffType } from '../../../_models';
import { FormArray, FormGroup } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { StepEmulatorService } from '../../../_services';
import { FormControlStatus } from '../../../_forms';
import { HoldOffAbstractComponent } from '../step-discount-settings/hold-off-abstract.component';

@Component({
  selector: 'step-time-delay',
  templateUrl: './step-time-delay.component.html',
  styleUrls: ['./step-time-delay.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class StepTimeDelayComponent extends HoldOffAbstractComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() layout: StepBasicLayout;
  @Input() enableFormErrors: boolean;
  @Input() isNew: boolean;
  @Input() templateId: string;
  stepElements = StepElements;
  stepDurationUnits = StepDurationUnits;
  unit: StepDurationUnits = StepDurationUnits.Days;
  duration: number;
  actions: FormGroup;
  @ViewChild('timeDelayInputs', { static: false }) timeDelayInputs: ElementRef;

  constructor(protected stepEmulatorService: StepEmulatorService) {
    super();
  }

  ngOnInit() {
    const nextBillDateDelayControl = this.form.get(['inputs', 0, 'actions', 0, 'next_bill_date_delay']);
    if (nextBillDateDelayControl) {
      this.duration = nextBillDateDelayControl.value;
    }

    this.actions = this.form.get(['inputs', 0, 'actions', 0]) as FormGroup;
    super.ngOnInit();
  }

  updateTimeDelay() {
    (this.form.get('inputs') as FormArray)
      .at(0)
      .get('actions')
      .get('0')
      .patchValue({
        next_bill_date_delay: +this.unit === StepDurationUnits.Weeks ? Number(this.duration * 7) : Number(this.duration)
      });
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  get settingsInvalidity() {
    return this.enableFormErrors && this.actions.status === FormControlStatus.Invalid;
  }

  get holdOffInvalidity() {
    return this.enableFormErrors && this.actions.controls.holdoff_delay.status === FormControlStatus.Invalid;
  }

  get timeDelayInvalidity() {
    return this.enableFormErrors && this.actions.controls.next_bill_date_delay.status === FormControlStatus.Invalid;
  }

  getElementRef(): ElementRef | null {
    if (this.holdOffInvalidity || this.timeDelayInvalidity) {
      if (!this.isElementVisible(this.stepElements.TimeDelay)) {
        this.toggleElement(this.stepElements.TimeDelay);
      }

      return this.timeDelayInputs;
    }

    return null;
  }
}
