import {componentOptions, InputTypes} from '../index';
import {typeSelectLanguage} from './select-language-type';
import {typeLogout} from './logout-button-type';
import {typeUserDropdown} from './userdropdown-type';

export const typeHeader = 'header';

export const headerType = editor => {
  const domc = editor.DomComponents;

  // SELECT
  domc.addType(typeHeader, {
    isComponent: el => {
      if (el && el.getAttribute) {
        if (el.getAttribute('elemtype') === InputTypes.HEADER) {
          return {type: typeHeader, editable: false, name: 'Header'};
        }
        else if (el.closest && el.closest(`[elemtype="${InputTypes.HEADER}"]`)) {
          if (el.tagName == 'svg') {
            return { type: 'svg', ...componentOptions };
          } else if (el.tagName == 'path') {
            return { type: 'svg-in'};
          } else if (el.tagName === 'IMG') {
            return { type: 'image', name: 'Logo', identifier: 'logo'};
          } else if (el.getAttribute("identifier") === 'logo-bg' || (!el.classList.contains('gjs-header-dropdown-container') && el.closest('.gjs-header-dropdown-container'))) {
            return { ...componentOptions };
          } else if(el.classList.contains('gjs-header-dropdown-container')) {
            return { name: 'Div' }
          } else if(el.classList.contains('gjs-header-wrapper')) {
            return { name: 'Header' }
          } else if(el.classList.contains('gjs-header-container')) {
            return { ...componentOptions }
          }
          else if (el.hasAttribute('rightcontainer') || el.hasAttribute('leftcontainer')) {
            return { ...componentOptions };
          }
        }
      }
    },
    model: {
      defaults: {
        tagName: 'div',
        name: 'Header',
        attributes: { elemtype: InputTypes.HEADER, class: 'header-wrapper' },
        components: [
          {
            tagName: 'div',
            name: 'Div',
            ...componentOptions,
            attributes: { class: 'header-container' },
            components: [
              {
                tagName: 'div',
                ...componentOptions,
                attributes: {'leftcontainer': '' },
                components: [
                  {type: typeSelectLanguage},
                ]
              },
              {
                tagName: 'div',
                components: [
                  {
                    tagName: 'a',
                    type: 'header-logo',
                    attributes: { target: '_self', href: '#', identifier: InputTypes.IMAGE_LINK },
                    ...componentOptions,
                    components: [
                      {
                        tagName: 'img',
                        type: 'image',
                        name: 'Desktop Logo',
                        attributes: { class: 'logo', id: 'desktoplogo', src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+' },
                      },
                      {
                        tagName: 'img',
                        type: 'image',
                        name: 'Mobile Logo',
                        attributes: { class: 'logo', id: 'mobilelogo', src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+' },
                      }
                    ],
                  }
                ],
                attributes: { identifier: 'logo-bg' },
                ...componentOptions,
              },
              {
                tagName: 'div',
                ...componentOptions,
                attributes: {'rightcontainer': '' },
                style: {
                  'text-align': 'right',
                },
                components: [
                  { type: typeUserDropdown }
                ]
              }
            ]
          }
        ]
      }
    },
  });
};
