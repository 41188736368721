import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-phone",
  templateUrl: "./phone.component.html",
  styleUrls: ["./../dashboard-section.scss"],
})
export class DashboardPhoneComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
