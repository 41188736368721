import {Component} from '@angular/core';
import {PerformanceReportComponent} from './performance-report.component';
import {FormBuilder} from '@angular/forms';
import {CampaignService, CRMCampaignService, PathPerformanceReportService, StorageService, UserService} from '../_services';

@Component({
  moduleId: module.id.toString(),
  selector: "path-performance-report",
  templateUrl: "./templates/performance-report.component.html",
  styleUrls: ['./styles/reports.component.css'],
})
export class PathPerformanceReportComponent extends PerformanceReportComponent {
  showCrmCampaignFilter = false
  constructor(
    protected formBuilder: FormBuilder,
    protected reportService: PathPerformanceReportService,
    protected campaignService: CampaignService,
    protected storageService: StorageService,
    protected crmCampaignService: CRMCampaignService,
    protected userService: UserService,
  ) {
    super(formBuilder, reportService, campaignService, storageService, crmCampaignService, userService);
  }

}
