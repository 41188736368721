import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AccountService, AlertService, PaymentSourceService, UserService} from '../_services';
import {CrudPagedListComponent} from '../_directives';
import {BankAccountStatus, ChargeStatus, PaymentSource, PaymentSourceType, User} from '../_models';
import {Observable} from "rxjs";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './payment-source.component.html'
})
export class PaymentSourceComponent extends CrudPagedListComponent implements OnInit {
  sources$: Observable<PaymentSource[]> = this.data$;
  selectedPaymentSource: PaymentSource;
  user: User;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    private userService: UserService,
    private paymentService: PaymentSourceService,
    private accountService: AccountService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, paymentService, alertService);
    this.objectName = 'payment source';
    this.title = 'My Payment Sources';
  }

  ngOnInit() {
    super.ngOnInit();

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      )
  }

  getType(source: PaymentSource) {
    if (source.resourcetype === PaymentSourceType.BankAccount) {
      return 'ACH Debit';
    }

    return 'Credit Card';
  }

  getIcon(source: PaymentSource) {
    if (source.resourcetype === PaymentSourceType.BankAccount) {
      return 'la-bank';
    }

    return 'la-credit-card';
  }

  getStatus(source: PaymentSource) {
    if (source.resourcetype === PaymentSourceType.BankAccount) {
      if (source.account_status === BankAccountStatus.Error) {
        return 'Error';
      }

      if (source.account_status === BankAccountStatus.VerificationFailed) {
        return 'Verification Failed';
      }

      return source.is_verified ? 'Verified' : 'Unverified';
    }

    if (this.isExpired(source)) {
      return 'Expired';
    }

    return (source.charge_status === ChargeStatus.Decline) ? 'Declined' : 'Active';
  }

  getStatusClass(source: PaymentSource) {
    if (source.resourcetype === PaymentSourceType.BankAccount) {
      if ((source.account_status === BankAccountStatus.Error) ||
        (source.account_status === BankAccountStatus.VerificationFailed)) {
        return 'error';
      }

      return source.is_verified ? 'success' : 'warning';
    }

    if (this.isExpired(source)) {
      return 'error';
    }

    return (source.charge_status === ChargeStatus.Decline) ? 'error' : 'success';
  }

  canVerify(source: PaymentSource) {
    if (source.resourcetype === PaymentSourceType.BankAccount) {
      if ((source.account_status === BankAccountStatus.New) ||
        (source.account_status === BankAccountStatus.Validated)) {
        return true;
      }
    }

    return false;
  }

  isExpired(source: PaymentSource) {
    if (this.isCard(source)) {
      let today = new Date();

      if (source.exp_year < today.getFullYear()) {
        return true;
      }
      else if ((source.exp_year === today.getFullYear()) && (source.exp_month < (today.getMonth() + 1))) {
        return true;
      }
    }

    return false;
  }

  isCard(source: PaymentSource) {
    return source.resourcetype === PaymentSourceType.Card;
  }

  isBankAccount(source: PaymentSource) {
    return source.resourcetype === PaymentSourceType.BankAccount;
  }

  setDefault(source: PaymentSource) {
    if (!source.is_default && source.is_verified) {
      this.accountService.patch(this.user.account.id, {default_payment_source: source.id})
        .subscribe(
          data => {
            this.alertService.success('Changed default payment source.');
            this.userService.clearCache();
            this.queryData();
          },
          error => {
            this.handleSubmitError(error);
          });
    }
  }

  verify(source: PaymentSource) {
    this.selectedPaymentSource = null;

    setTimeout(() => {
      this.selectedPaymentSource = source;
      this.openVerifyDialog();
    }, 0)
  }

  protected openVerifyDialog() {
    this.modalService.getModal('verifyBankAccountDialog').open();
  }

  onVerify(source: PaymentSource) {
    this.modalService.getModal('verifyBankAccountDialog').close();
    this.queryData();
  }

}
