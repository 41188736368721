import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from "./crud.service";

@Injectable()
export class ProductInfoSectionService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http, 'product_info_sections');
  }
}
