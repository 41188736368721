import {componentOptions, InputTypes} from '../index';

export const typeMainMenu = 'main-menu';

export const mainMenuType = editor => {
  const domc = editor.DomComponents;
  const dType = domc.getType('button');
  const dModel = dType.model;
  domc.addType(typeMainMenu, {
    model: dModel.extend({
      defaults: Object.assign({}, dModel.prototype.defaults, {
        attributes: {elemtype: InputTypes.MAIN_MENU, class: 'align-main-menu'},
        name: 'Main Menu',
        components: [
          {
            tagName: 'span',
            ...componentOptions,
            content: `<svg style="width: 1.125em;height: 1em; margin-right: 7px;" data-v-40ab164b="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="footer_btn svg-inline--fa fa-home fa-w-18"><path data-v-40ab164b="" fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" class=""></path></svg>
            `
          },
          {
            type: "text",
            copyable: false,
            droppable: false,
            draggable: false,
            removable: false,
            highlightable: false,
            selectable: false,
            attributes: { content: 'MAIN MENU' },
            content: "MAIN MENU"
          }
        ],
        traits: [{ name: 'id' }]
      }),
      doStuff() {},
    },
    {
      isComponent: el => {
        if (el) {
          if (el.getAttribute && el.getAttribute('elemtype') == InputTypes.MAIN_MENU) {
            return { type: typeMainMenu, name: 'Main Menu' };
          }
          else if (el.closest && el.closest(`[elemtype="${InputTypes.MAIN_MENU}"]`)) {
            if (el.tagName == 'svg') {
              return { type: 'svg', ...componentOptions };
            } else if (el.tagName == 'path') {
              return { type: 'svg-in'};
            } else if (el.hasAttribute('content')) {
              return {
                type: 'text',
                copyable: false,
                droppable: false,
                draggable: false,
                removable: false,
                highlightable: false,
                selectable: false
              };
            }

            return { ...componentOptions };
          }
        }
      }
    }),
  });
};
