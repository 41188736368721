import { Editor } from 'tinymce';
import {
  StepButtons,
  CancelItemsSelection,
  FunnelType,
  StepDurationUnits,
  StepEmulatorDevices,
  StepMedia,
  StepMediaCategory,
  StepResponseScreen,
  StepTextual,
  StepButtonShapes,
  StepButtonShapeStyles,
  VariableDummies,
  StepFixedMediaCategory,
  StepButtonControlTypes,
  defaultThemeColor,
  whiteColor,
  StepMediaSubCategory,
  EmbedPlatforms,
  embedURLs,
  StepProductsPreview,
  OfferIntentLabels,
  OfferIntents,
  CustomStepCategoryEnum,
  ActionType,
  Image,
  StepResponseMedia
} from '../../_models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const conversions: { [key in StepDurationUnits]: number } = {
  [StepDurationUnits.Hours]: 60 * 60,
  [StepDurationUnits.Days]: 24 * 60 * 60,
  [StepDurationUnits.Weeks]: 7 * 24 * 60 * 60,
  [StepDurationUnits.Months]: 30 * 24 * 60 * 60
};

export function convertToSeconds(duration: number, unit: StepDurationUnits): number | null {
  if (duration === null || duration === undefined) return null;
  return unit in conversions ? duration * conversions[unit] : duration;
}

export function convertFromSeconds(seconds: number, unit: StepDurationUnits): number | null {
  if (seconds === null || seconds === undefined) return null;
  return conversions[unit] ? seconds / conversions[unit] : seconds;
}

export function addDummyValues(content: string, variables: { value: string; text: string }[]): string {
  let updatedContent = content;
  if (updatedContent) {
    variables.forEach((variable) => {
      const regex = new RegExp(escapeRegExp(variable.value), 'g');
      updatedContent = updatedContent.replace(regex, VariableDummies[variable.value] || variable.value);
    });
  }
  return updatedContent;
}

export function normalizeObject(obj) {
  const fieldsToNull = ['next_step', 'step', 'name'];
  const fieldsToFalse = ['is_global', 'is_template'];
  const fieldsToDelete = ['id'];

  fieldsToFalse.forEach((field) => {
    obj[field] = false;
  });
  fieldsToNull.forEach((field) => {
    obj[field] = null;
  });
  fieldsToDelete.forEach((field) => {
    delete obj[field];
  });

  const recursiveFields = ['inputs', 'actions'];

  recursiveFields.forEach((field) => {
    if (field in obj) {
      obj[field] = obj[field].map((item) => normalizeObject(item));
    }
  });

  return obj;
}

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function pxToVw(pixels: string, width: number): string {
  const sizeInPixels = parseInt(pixels.replace(/\D/g, ''), 10);
  const sizeInVw = ((sizeInPixels / width) * 100).toFixed(2);
  return sizeInVw;
}

export function vwToPx(vw: string, width: number): number {
  const sizeInPixels = (+vw * width) / 100;
  return sizeInPixels;
}

export function parseStyleString(style: string): any {
  const styleObject = {};
  if (style) {
    style.split(';').forEach((styleRule) => {
      const [property, value] = styleRule.split(':').map((item) => item.trim());
      if (property && value) {
        styleObject[property] = value;
      }
    });
  }
  return styleObject;
}

export function getEmbeddedVideoUrl(videoUrl: string, sanitizer: DomSanitizer): SafeResourceUrl | null {
  let embedUrl: string | null = null;

  if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
    const videoId = extractVideoId(videoUrl, EmbedPlatforms.Youtube);
    embedUrl = videoId ? `${embedURLs.youtube}/${videoId}` : null;
  } else if (videoUrl.includes('vimeo.com')) {
    const videoId = extractVideoId(videoUrl, EmbedPlatforms.Vimeo);
    embedUrl = videoId ? `${embedURLs.vimeo}/${videoId}` : null;
  } else if (videoUrl.includes('dailymotion.com')) {
    const videoId = extractVideoId(videoUrl, EmbedPlatforms.dailyMotion);
    embedUrl = videoId ? `${embedURLs.dailyMotion}/${videoId}` : null;
  }

  if (!embedUrl) {
    return null;
  }

  const safeUrl = sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  return safeUrl;
}

export function extractVideoId(url: string, platform: EmbedPlatforms): string | null {
  let videoId: string | null = null;

  switch (platform) {
    case EmbedPlatforms.Youtube:
      const youtubeRegex =
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const youtubeMatch = url.match(youtubeRegex);
      if (youtubeMatch && youtubeMatch[1]) {
        videoId = youtubeMatch[1];
      }
      break;

    case EmbedPlatforms.Vimeo:
      const vimeoRegex = /vimeo\.com\/(?:video\/)?(\d+)/;
      const vimeoMatch = url.match(vimeoRegex);
      if (vimeoMatch && vimeoMatch[1]) {
        videoId = vimeoMatch[1];
      }
      break;

    case EmbedPlatforms.dailyMotion:
      const dailymotionRegex = /dailymotion\.com\/video\/([a-zA-Z0-9]+)/;
      const dailymotionMatch = url.match(dailymotionRegex);
      if (dailymotionMatch && dailymotionMatch[1]) {
        videoId = dailymotionMatch[1];
      }
      break;

    default:
      videoId = null;
      break;
  }

  return videoId;
}

export function getTruncatedProductName(productName: string): string {
  const maxLength = 22;
  if (!productName) {
    return '';
  }

  const trimmedName = productName.trim();
  return trimmedName.length > maxLength ? trimmedName.slice(0, maxLength - 3) + '...' : trimmedName;
}

export const StepBody = {
  subtitle: null,
  style: null,
  classes: [],
  is_popup: false,
  show_step_link: false,
  contact_type: null,
  enhanced_content: null,
  enhanced_mode: false,
  fail_if_any_input_invalid: false,
  invalid_message: null,
  hide_status: false,
  action_holdoffs: null,
  mobile_icon_button_type: null,
  use_bootstrap_spacing: true,
  enhanced_content_components: null,
  icon: null,
  help: null,
  hide_if_invalid: true,
  product_funnels: [],
  is_alt_child_exit: false,
  matched_step_key: null,
  autopick_next_step: null,
  requires_active_item: true,
  requires_item: true,
  requires_customer: true
};

export const OfferData = {
  first_step: null,
  is_visual: true,
  is_voice: false,
  is_sms: false,
  is_modified: true,
  is_public: false,
  is_template: false,
  steps: [],
  use_product_filter: false,
  item_statuses: null,
  is_3ds: false,
  cancel_items_selection: CancelItemsSelection.UpsellsOnlyIfUpsell,
  min_billing_cycle: 1,
  merchant_ids: null,
  fulfillment_type: null,
  states: null,
  use_crm_campaign_filter: false,
  crm_campaigns: [],
  cc_types: null,
  return_items_selection: CancelItemsSelection.ShowAll,
  resourcetype: FunnelType.Product
};

export const StepTextualInitialData: StepTextual = {
  banner: { value: '', background: whiteColor },
  body: {
    label: '',
    content: ''
  }
};

export const StepMediaInitialData: StepMedia = {
  category: StepMediaCategory.Product,
  subCategory: StepMediaSubCategory.Product,
  fixedImage: {
    [StepEmulatorDevices.Mobile]: {} as Image,
    [StepEmulatorDevices.Tablet]: {} as Image,
    [StepEmulatorDevices.Desktop]: {} as Image,
    productImage: null,
    category: StepFixedMediaCategory.Upload
  },
  embedVideo: { url: null, isURLValid: false, sanitizedURL: null }
};

export const StepResponseMediaInitialData: StepResponseMedia = {
  category: StepMediaCategory.NoMedia,
  fixedImage: {
    [StepEmulatorDevices.Mobile]: {} as Image,
    [StepEmulatorDevices.Tablet]: {} as Image,
    [StepEmulatorDevices.Desktop]: {} as Image,
    productImage: null,
    category: StepFixedMediaCategory.Upload
  },
  embedVideo: { url: null, isURLValid: false, sanitizedURL: null }
};

export const StepButtonsInitialData: StepButtons = {
  confirmButton: {
    content: '',
    ...StepButtonShapeStyles[StepButtonShapes.Modern],
    brandBackground: defaultThemeColor,
    brandBorder: whiteColor,
    brandFont: whiteColor,
    fontColor: whiteColor,
    isFontDisabled: false,
    style: StepButtonShapes.Modern,
    type: StepButtonControlTypes.Theme,
    controlPopup: false,
    shapesPopup: false
  },
  backButton: {
    content: '',
    ...StepButtonShapeStyles[StepButtonShapes.ModernOutline],
    brandBackground: whiteColor,
    brandBorder: defaultThemeColor,
    brandFont: defaultThemeColor,
    fontColor: defaultThemeColor,
    isFontDisabled: true,
    style: StepButtonShapes.ModernOutline,
    type: StepButtonControlTypes.Theme,
    controlPopup: false,
    shapesPopup: false
  }
};

export const StepResponseScreenInitialData: StepResponseScreen = {
  detailButton: {
    borderColor: whiteColor,
    backgroundColor: defaultThemeColor,
    fontColor: whiteColor
  },
  continueButton: {
    borderColor: defaultThemeColor,
    backgroundColor: whiteColor,
    fontColor: defaultThemeColor
  },
  titleContent: '',
  responseContent: ''
};

export const StepProductPreviewInitialData: StepProductsPreview = {
  data: [],
  loading: false
};

export const FontSizes = '12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px';

export const FontFamilyFormats =
  "Open Sans='Open Sans', sans-serif; Ubuntu=ubuntu,sans-serif; Arial=arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Courier New=courier new,courier,monospace; Comic Sans MS=comic sans ms,sans-serif; Georgia=georgia,serif; Trebuchet MS=trebuchet ms,geneva;";

export function initializeEditorStyles(editor: Editor, fontSize: string, fontFamily: string, alignCenter?: boolean) {
  const applyDefaultStyles = () => {
    editor.execCommand('FontSize', false, fontSize);
    editor.execCommand('FontName', false, fontFamily);

    const style = document.createElement('style');
    style.innerHTML = `
      body {
        font-size: ${fontSize};
        font-family: ${fontFamily};
      }
    `;
    editor.getDoc().head.appendChild(style);
  };

  const cleanUpContent = () => {
    const content = editor.getContent();
    if (content.includes('<div>&nbsp;</div>')) {
      editor.setContent(content.replace(/<div>&nbsp;<\/div>/g, ''));
    }
  };

  const applyStylesAndCleanUp = () => {
    const content = editor.getContent();
    if (!content || content.trim() === '' || content === '<p><br></p>') {
      applyDefaultStyles();
      cleanUpContent();

      if (alignCenter) {
        editor.formatter.apply('aligncenter');
      }
    }
  };

  editor.on('init', applyStylesAndCleanUp);
  editor.on('Change KeyUp', applyStylesAndCleanUp);
  editor.on('focus', cleanUpContent);
}

export function addDataAttributes(html: string): string {
  if (!html) return null;
  const fontSizeRegex = /font-size:\s*(\d+px);/g;

  const dataFontSizeRegex = /data-fontsize="[^"]*"/;

  let updatedHtml = html.replace(
    /(<[^>]+)(style="[^"]*font-size:\s*\d+px[^"]*")([^>]*)>/g,
    (match, startTag, styleAttr, endTag) => {
      const fontSizeMatch = styleAttr.match(fontSizeRegex);
      const fontSize = fontSizeMatch ? fontSizeMatch[0].split(':')[1].trim() : null;

      if (fontSize) {
        if (dataFontSizeRegex.test(match)) {
          return match.replace(dataFontSizeRegex, `data-fontsize="${fontSize}"`);
        } else {
          return `${startTag} data-fontsize="${fontSize}" ${styleAttr}${endTag}>`;
        }
      }

      return match;
    }
  );

  return updatedHtml;
}

export const OfferTypeOptions = [
  {
    value: OfferIntents.CancelOrder,
    label: OfferIntentLabels[OfferIntents.CancelOrder]
  },
  {
    value: OfferIntents.ReturnOrder,
    label: OfferIntentLabels[OfferIntents.ReturnOrder]
  },
  {
    value: OfferIntents.CancelSubscription,
    label: OfferIntentLabels[OfferIntents.CancelSubscription]
  },
  {
    value: OfferIntents.CancelTrial,
    label: OfferIntentLabels[OfferIntents.CancelTrial]
  }
];

export const StepCategoryOfferIntentsMapping = {
  [CustomStepCategoryEnum.AdjustShippingFrequency]: OfferIntents.AdjustShippingFrequency,
  [CustomStepCategoryEnum.PauseSurvey]: OfferIntents.PauseSubscription,
  [CustomStepCategoryEnum.SwapProduct]: OfferIntents.ReviseOrder,
  [CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount]: OfferIntents.ReactivateSubscription,
  [CustomStepCategoryEnum.OfferDetails]: OfferIntents.OfferDetails,
  [CustomStepCategoryEnum.CancelOrderSurvey]: OfferIntents.CancelOrder,
  [CustomStepCategoryEnum.CancelTrialSurvey]: OfferIntents.CancelTrial,
  [CustomStepCategoryEnum.CancelSubSurvey]: OfferIntents.CancelSubscription,
  [CustomStepCategoryEnum.ReturnSurvey]: OfferIntents.ReturnOrder
};

export const SurveyResourceMapping = {
  [CustomStepCategoryEnum.CancelOrderSurvey]: ActionType.Cancel,
  [CustomStepCategoryEnum.CancelSubSurvey]: ActionType.Cancel,
  [CustomStepCategoryEnum.CancelTrialSurvey]: ActionType.Cancel,
  [CustomStepCategoryEnum.ReturnSurvey]: ActionType.RMA
};

export const StepsInvalidityFields = [
  'nameInvalidity',
  'labelInvalidity',
  'contentInvalidity',
  'discountInvalidity',
  'mediaInvalidity',
  'inputsInvalidity',
  'backInvalidity',
  'holdOffInvalidity',
  'timeDelayInvalidity',
  'confirmInvalidity',
  'settingsInvalidity'
];
