import {
  SessionSource,
  SessionResult,
  LifelineType,
  SessionActionResult,
  RevenueType,
} from "./index";
import { ChartOptions, ChartType } from "chart.js";
import { Color } from "ng2-charts";

export const DailyDataThreshold = 21;

export interface TopData {
  session_date: string;
  source: SessionSource;
  num_sessions: number;
}

export interface SessionCount {
  source: SessionSource;
  result: SessionResult;
  num_sessions: number;
}

export interface Revenue {
  revenue_type: RevenueType;
  total_revenue: number;
}

export interface FaqResults {
  total_up_votes: number;
  total_down_votes: number;
}

export interface LifelineCount {
  lifeline_type: LifelineType;
  num_lifelines: number;
}

export interface SessionActionCount {
  result: SessionActionResult;
  session_date: string;
  num_sessions: number;
}

export interface Dashboard {
  top: TopData[];
  session_counts: SessionCount[];
  revenues: Revenue[];
  faq_results: FaqResults;
  lifelines: LifelineCount[];
  actions: SessionActionCount[];
}

export interface DashboardChartDataset {
  backgroundColor: Array<string>;
  hoverBorderColor: Array<string>;
  data: Array<any>;
  borderWidth: number;
}

export interface DashboardSessionsChartData {
  chartLegend: boolean;
  labels: Array<string>;
  dataset: DashboardChartDataset;
  self_service_rate: Array<number>;
}

export interface DashboardRevenuesChartData {
  title: string;
  labels: Array<string>;
  dataset: DashboardChartDataset;
  reportName: string;
}

export interface BarChartData {
  label: string;
  data: Array<any>;
}

export interface DashboardOrderOperationsChartData {
  title: string;
  datasets: BarChartData[];
  colors: Color[];
  labels: Array<string>;
  options: ChartOptions;
  plugins: Array<any>;
  legend: boolean;
  type: ChartType;
  reportName: string;
}

export enum TimeData {
  DefaultFormat = "Y-M-D HH:mm:ss",
  SessionListDateFormat = "M/D LT",
  ReportsApiDateFormat = "Y-MM-DD hh:mm:ss A",
  ReportsDisplayDateFormat = "Y-M-D",
}

export interface SessionFilters {
  includeTestSessions: boolean;
  includeHangups: boolean;
  includeUnknowns: boolean;
}

/* Dashboard V2 */
export interface DashboardFilter {
  start_date: string;
  end_date: string;
  campaign_id?: string;
  crm_id?: string;
  includeTest?: boolean;
  includeHangups?: boolean;
  includeUnknowns?: boolean;
  timezone: string;
  account_id?: string;
  section?: string;
}
export interface DateRange {
  startDate: string;
  endDate: string;
}

interface Filters {
  campaign_id?: number[];
}

export interface DashboardReportsFilter {
  fields: string[];
  date_range: DateRange;
  accounts?: number[];
  timezone: string;
  date_filter_type: string;
  filters?: Filters;
}

interface InfoCardData {
  title: string;
  hint?: string;
  value: string | null;
}

interface DataVisualizationCardData {
  title: string;
  subtitle: string;
  hint?: string;
  name?: string;
}

interface EngagementCardData {
  title: string;
  count: string | null;
  icon: "PHONE" | "EMAIL" | "LINK" | "MESSAGE";
  hint?: string;
}

interface DashboardChartRecord {
  name: string;
  value?: number | null;
  type?: string;
  sessions?: string | number;
  saved?: number;
}

export type InfoCards = InfoCardData[];
export type DataVisualizationCards = DataVisualizationCardData[];
export type EngagementCards = EngagementCardData[];
export type DashboardChartData = DashboardChartRecord[];

/* API Responses */
export interface DashboardFAQ {
  question: string;
  views: number;
}

export type DashboardTopFAQsAPIResponse = DashboardFAQ[] | {};

export interface DashboardTrafficSource {
  tracking_source: string;
  session_count: number;
}

export type DashboardTrafficSourcesAPIResponse = DashboardTrafficSource[] | {};

export interface DashboardCampaignBreakdown {
  crm_campaign_id: string;
  crm_campaign_name: string;
  session_count: number;
  diff_percentage?: number;
}

export type DashboardTopCampaignsBreakdownAPIResponse =
  | DashboardCampaignBreakdown[]
  | {};

export interface DashboardCustomerRequests {
  cancel_hold_request: number;
  revise_request: number;
  shipping_frequency_request: number;
  order_status_request: number;
  update_info_request: number;
  faqs_request: number;
  marketplace_request: number;
}

export type DashboardCustomerRequestsAPIResponse =
  | DashboardCustomerRequests
  | {};

interface DashboardSessionResults {
  Bypass?: number;
  Dropoffs?: number;
  Successful?: number;
  Lifeline?: number;
}
export interface DashboardSessionResultsAPIResponse {
  self_service_rate: [number, number];
  session_results: DashboardSessionResults;
}

// Retention Types
export interface RetentionStatistics {
  save_amount: number;
  save_rate: number;
  hold_save: number;
  hold_cancel: number;
  subscription_save: number;
  subscription_cancel: number;
}

export type DashboardRetentionStatisticsAPIResponse =
  | RetentionStatistics[]
  | {};

export interface RetentionPathBreakdown {
  product_path: string;
  saved: number;
  session_count: number;
}

export type DashboardRetentionPathBreakdownAPIResponse =
  | RetentionPathBreakdown[]
  | {};

export interface RetentionWatchlist {
  reason: string;
  count: number;
  trend: number;
}

export type DashboardRetentionWatchlistAPIResponse = RetentionWatchlist[] | {};

export interface RetentionSurvey {
  cancel_reason: string;
  cancelled: number;
}

export type DashboardRetentionSurveyAPIResponse = RetentionSurvey[] | {};

export interface RetentionDeflectionData {
  key: string;
  count: number;
  diff_percentage: number;
}

export type DashboardRetentionDeflectionDataAPIResponse =
  | RetentionDeflectionData[]
  | {};

export interface RetentionCancelCategory {
  cancel_reason_category: string;
  cancelled: number;
  cancelled_percentage: number;
}

export type DashboardRetentionCancelCategoriesAPIResponse =
  | RetentionCancelCategory[]
  | {};

export interface RetentionDownsellData {
  step_category: string;
  downsell: number;
  downsell_attempt: number;
  downsell_take_rate: number;
  diff_percentage?: number;
}

export type RetentionDownsellAPIResponse = RetentionDownsellData[] | {};

export interface RetentionSummaryData {
  extra_for_grouping: number;
  deflected: number;
  cancelled: number;
  saved: number;
  return_quantity: number;
  dropoffs: number;
  total_saves: number;
  retracted_save: number;
}

export type RetentionSummaryAPIResponse = RetentionDownsellData[] | {};

// Marketplace types
export interface DashboardMarketplaceWatchlist {
  total_cart_abandoned: number;
  total_cart_abandoned_trend?: number;
  total_card_declined: number;
  total_card_declined_trend?: number;
  in_cart: number;
  in_cart_trend?: number;
}

export type DashboardMarketplaceWatchlistAPIResponse =
  | DashboardMarketplaceWatchlist
  | {};

export interface MarketplaceVisitor {
  total_marketplace_views: number;
  created: string;
}
export type DashboardMarketplaceTotalVisitorsAPIResponse =
  | MarketplaceVisitor[]
  | {};

export interface MarketplaceTopCards {
  total_revenue: number;
  total_checked_outs: number;
  average_order_value: number;
  conversion_based_on_visits: number;
}
export type DashboardMarketplaceTopCardsAPIResponse = MarketplaceTopCards | {};

export interface MarketplaceSummary {
  total_checked_outs: number;
  total_cart_abandoned: number;
  total_card_declined: number;
}
export type DashboardMarketplaceSummaryAPIResponse = MarketplaceSummary | {};

export interface MarketplaceSales {
  tracking_source: string | null;
  total_sale: number;
}
export type DashboardMarketplaceSalesAPIResponse = MarketplaceTopCards[] | {};

export interface MarketplaceProductConversion {
  product_name: string;
  conversion_based_on_visits: number;
  total_checked_outs: number;
  total_revenue: number;
  total_views: number;
  trend: number;
}
export type DashboardMarketplaceProductConversionsAPIResponse =
  | MarketplaceProductConversion[]
  | {};

export interface MarketplaceCampaign {
  crm_campaign_name: string;
  conversion_based_on_visits: number;
  trend?: number;
}
export type DashboardMarketplaceCampaignAPIResponse =
  | MarketplaceCampaign[]
  | {};

export interface RetentionMiscDataAPIResponse {
  customers_saved: number;
  customers_cancelled: number;
  path_breakdown: RetentionPathBreakdown[] | [];
  survey_responses: RetentionSurvey[] | [];
  cancel_categories: RetentionCancelCategory[] | [];
}
