import { SideNavService } from './../_services/sidenav.service';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  AccountService,
  AlertService,
  CampaignProductService,
  CampaignService,
  CampaignTrackingService,
  ChargebackReportService,
  CRMCampaignService,
  FunnelStepService,
  MerchantService,
  RefundReportService,
  ReportsConfigService,
  StorageService,
  UserService,
} from "../_services";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ReportsAbstractV2Component } from "./reports-abstract-v2.component";
import { MultiOptions } from "../custom-multi-select/multi-select";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { chargebackFields, chargebackFilters } from "./utils/constants";
import { cloneDeep } from "lodash";
import { ReportTypes } from "./models/reports";

@Component({
  moduleId: module.id.toString(),
  selector: "chargeback-report-v2",
  templateUrl: "./templates/reports-v2.component.html",
  styleUrls: ["./styles/reports-v2.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChargebackReportV2Component
  extends ReportsAbstractV2Component
  implements OnInit, AfterViewInit
{
  reportTitle = "Chargeback Report";
  fields: MultiOptions = cloneDeep(chargebackFields);
  filters: MultiOptions = cloneDeep(chargebackFilters);
  reportType = ReportTypes.CHARGEBACK;
  
  predefinedFields = ["exclude_zeroes"];
  commonReportsFields = [
    "transaction_count",
    "chargeback_count",
    "alert_count",
  ];
  summaryFields = [
    "transaction_count",
    "dispute_count",
    "alert_count",
    "chargeback_count",
  ];
  selectedDimensions = [
    {
      model: "mid_number",
      label: "MID Number",
      selected: true,
      disabled: false,
    },
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: ChargebackReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected sideNavService: SideNavService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(
      router,
      location,
      route,
      alertService,
      reportsService,
      campaignService,
      reportsConfigService,
      trackingSourceService,
      userService,
      accountService,
      campaignProductService,
      crmCampaignService,
      storageService,
      stepService,
      merchantService,
      modalService
    );

    this.updatePreSelectedDimensions("mid_number");
    this.filterCommonDimensions(this.commonReportsFields);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isChargebackReport = true;
  }

  ngAfterViewInit() {
    if (this.panel) {
      this.panel.expanded = true;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedDimensions,
      event.previousIndex,
      event.currentIndex
    );
  }

}
