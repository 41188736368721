import {InputTypes, Languages} from '../index';

export const typeOption = 'dropdown-option';
export const typeSelectLanguage = 'select-language';
const componentOptions = {
  editable: false,
  layerable: false,
  droppable: false,
  draggable: false,
  removeable: false,
  selectable: false,
  highlightable: false,
  hoverable: false,
  badgable: false,
  resizeable: false,
};

export const createOption = (value, name) => ({type: typeOption, attributes: {value, name}});

export const selectLanguageType = editor => {
  const domc = editor.DomComponents;

  domc.addType(typeOption, {
    isComponent: el => {
      if (el && el.getAttribute) {
        if (el.tagName == 'A' && el.getAttribute('value') && el.getAttribute('name')) {
          return { type: typeOption, ...componentOptions }
        } else if (el.closest(`[elemtype="${typeOption}"]`)) {
          return { ...componentOptions }
        }
      }
    },

    model: {
      defaults: {
        ...componentOptions,
        tagName: 'a',
        attributes: { elemtype: typeOption, identifier: 'dropdown-option'},
        components: model => {
          const { value, name, header } = model.getAttributes();
          const components = [];
          if (!header) {
            components.push({
              ...componentOptions,
              tagName: 'img',
              attributes: {
                identifier: 'dropdown-option-img',
                src: `${window.location.origin}/assets/images/languages/${value}.png`
              }
            });
          }
          components.push({
            ...componentOptions,
            tagName: 'span',
            style: { flex: 1 },
            content: name
          });
          return components;
        }
      },
    },
  });

  // SELECT
  domc.addType(typeSelectLanguage, {
    isComponent: el => {
      if (el && el.getAttribute) {
        if (el.getAttribute('elemtype') === InputTypes.SELECT_LANGUAGE)
          return { type: typeSelectLanguage, editable: false, name: 'Select Language' };
        else if (el.closest && el.closest('[elemtype=select-language]')) {
          return { ...componentOptions };
        }
      }
    },
    model: {
      defaults: {
        tagName: 'div',
        editable: false,
        name: 'Select Language',
        attributes: { elemtype: InputTypes.SELECT_LANGUAGE, identifier: 'dropdown-container' },
        components: [
          {
            tagName: 'span',
            attributes: { identifier: 'selected-language' },
            content: `<img width="20px" src="${window.location.origin}/assets/images/languages/en.png" width="100%" />`,
            ...componentOptions,
          },
          {
            tagName: 'button',
            content: `
              <span identifier="language-short-title"><span>EN</span> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 483.049 483.049" style="width: 11pt; height: 11pt; margin-left: 8px;" xml:space="preserve">
                <g><polygon style="fill: #8a8a8a;" points="0,121.155 241.524,361.894 241.524,121.155 \t"/>
                <polygon style="fill: #8a8a8a;" points="241.524,121.155 241.524,361.894 483.049,121.155 \t"/>
                </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
              </span>
              <span identifier="language-title-container"><span identifier="language-title">Select&nbsp;</span>Language</span>`,
            attributes: { identifier: 'btn-options' },
            ...componentOptions,
          },
          {
            tagName: 'div',
            ...componentOptions,
            attributes: { identifier: 'dropdown-content' },
            components: [].concat(Languages.map((language) => createOption(language.code, language.label))),

          }
        ]
      },
    },
  });
};
