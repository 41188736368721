import {typeLogout} from './logout-button-type';

const logoutButton = editor => {
  const bm = editor.BlockManager;
  bm.add('logout-button', {
    category: 'Basic',
    media: '<i class="fa fa-sign-out fa-2x margin-bottom-10"></i>',
    label: `Logout Button`,
    content: { type: typeLogout },
  });
};
export default logoutButton;
