import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudSavePopupComponent } from '../../_directives';
import { AlertService, FunnelService } from '../../_services';
import { Location } from '@angular/common';
import { CheckoutPopup } from '../../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'upsell-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['../checkout-listing/checkouts.component.scss'],
})
export class UpsellPopupComponent extends CrudSavePopupComponent implements OnInit {
  isUpsellPopup: boolean = true;
  isCheckoutPopup: boolean = false;
  CheckoutPopup = CheckoutPopup;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected funnelService: FunnelService,
    protected alertService: AlertService
  ) {
    super(router, location, route, funnelService, alertService);
    this.objectName = 'Upsell';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        name: [this.inputData.name || '', Validators.required],
      })
    );
  }

  onSubmit() {}

  protected getFormData() {}
}
