import {typeUserDropdown} from './userdropdown-type';


const userDropdownBlock = editor => {
  const bm = editor.BlockManager;
  bm.add('user-dropdown', {
    category: 'Basic',
    media: '<i class="fa fa-user fa-2x margin-bottom-10"></i>',
    label: `User Dropdown`,
    content: { type: typeUserDropdown },
  });
};
export default userDropdownBlock;
