import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {LogsContainerAbstractComponent} from "./logs-container.abstract.component";
import {AlertService, CampaignDomainService, CampaignService, PhoneService} from "../_services";
import {forkJoin} from "rxjs";
import {config} from "../../config/config";
import {Campaign, CustomDomain, Pager, Phone, PhoneUseTypeEnum} from "../_models";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  templateUrl: './sms-logs-container.component.html',
  styleUrls: ['./logs.component.css']
})
export class SmsLogsContainerComponent extends LogsContainerAbstractComponent implements OnInit {
  campaignNames: {[key: string]: string;};
  domains: {[key: string]: string;};
  phones: Phone[] = [];
  selectedPhone: Phone = null;

  constructor(
    protected alertService: AlertService,
    protected router: Router,
    protected location: Location,
    protected formBuilder: FormBuilder,
    protected campaignService: CampaignService,
    protected phoneService: PhoneService,
    protected domainService: CampaignDomainService,
  ) {
    super(alertService, router, location, formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.addControl('phone_id', new FormControl(null, Validators.required));

    forkJoin([
      this.phoneService.list({page: 1, page_size: config.maxPageSize,
        'use_type!': PhoneUseTypeEnum.VoiceOnly}),
      this.campaignService.list({page: 1, page_size: config.maxPageSize}),
      this.domainService.list(),
    ])
      .subscribe(
        (data: [Pager, Pager, CustomDomain[]]) => {
          if (data) {
            let campaignNames = {};
            let domains = {};

            data[1].results.forEach((campaign: Campaign) => {
              campaignNames[campaign.id] = campaign.display_name;
            });

            data[2].forEach((domain: CustomDomain) => {
              domains[domain.id] = domain.domain;
            });

            this.campaignNames = campaignNames;
            this.domains = domains;
            this.phones = data[0].results

            if (this.phones.length === 1) {
              this.form.controls['phone_id'].patchValue(this.phones[0].id)
              this.selectedPhone = this.phones[0];
            }
          }

          this.ready = true;
        },
        error => {
          this.handleError(error);
          this.ready = true;
        }
      );

  }

  onSelectPhone(event) {
    const phoneId = event.target.value;
    this.phones.forEach((phone: Phone) => {
      if (phone.id.toString() === phoneId.toString()) {
        this.selectedPhone = phone;
      }
    });
  }

  getPhoneLabel(phone: Phone) {
    let label = phone.phone + ' (';

    if (phone.campaign) {
      label += this.campaignNames[phone.campaign];

      if (phone.domain) {
        label += ' - ' + this.domains[phone.domain];
      }
    }
    else {
      label += 'Master Account';
    }

    label += ')';

    return label;
  }

}
