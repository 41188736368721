import { FunnelService } from '../../_services/funnel.service';
import { AlertService } from '../../_services/alert.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CrudSavePopupComponent } from '../../_directives/crud-save-popup.component';
import { OfferTypes, PathPopup } from '../../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'path-crud-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['../path.component.scss'],
})
export class PathCrudPopupComponent
  extends CrudSavePopupComponent
  implements OnInit
{
  ngModelOptions = { standalone: true };
  PathPopup = PathPopup;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected funnelService: FunnelService,
    protected alertService: AlertService
  ) {
    super(router, location, route, funnelService, alertService);
    this.objectName = 'Path';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        name: [this.inputData.name || '', Validators.required],
      })
    );

    super.ngOnInit();
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid || this.inputData.popupType === PathPopup.Delete || this.inputData.popupType === PathPopup.Duplicate ) {
      this.loading = true;
      const { popupType, path_id, path_steps, path_audience, ...formData } = this.getFormData();

      let operation: Observable<Object>;

      if (popupType === PathPopup.Update) {
        operation = this.funnelService.update(path_id, formData);
      } else if (popupType === PathPopup.Duplicate) {
        operation = this.funnelService.clone(path_id);
      } else if (popupType === PathPopup.Delete) {
        operation = this.funnelService.delete(path_id);
      }

      const operationTitle =
        popupType === PathPopup.Update
          ? 'Updated'
          : popupType === PathPopup.Duplicate
          ? 'Duplicated'
          : popupType === PathPopup.Delete
          ? 'Deleted'
          : '';

      operation.subscribe(
        (data) => {
          if (this.showSuccessMessage) {
            this.alertService.success(
              `${this.objectName} ${operationTitle} Successfully.`,
              true
            );
          }

          this.canDeactivateService.setFormDirty(false);
          this.onSaveComplete(data);
        },
        (error) => {
          this.handleSubmitError(error);
          this.loading = false;
        }
      );
    }
  }

  protected getFormData() {
    const data = {
      ...this.form.value,
      name: this.inputData.name,
      path_id: this.inputData.path_id,
      popupType: this.inputData.popupType,
      offer_intent: this.inputData.offer_intent,
      offer_type: OfferTypes.SubPath,
      resourcetype: 'ProductFunnel',
      is_visual: true,
      path_steps: this.inputData.path_steps,
      path_audience: this.inputData.path_audience,
    };
    return data;
  }

  popupTypeTextMap: Partial<Record<PathPopup, string>> = {
    [PathPopup.Delete]: 'Delete',
    [PathPopup.Duplicate]: 'Duplicate',
    [PathPopup.Update]: 'Rename'
  };
}
