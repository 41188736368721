import {BaseModel, Account} from './index';

export enum EventStatus {
  IS_COMPLETED = 1,
  IN_PROGRESS = 2,
  HAS_ERROR = 3,
}

export enum NotificationType {
  CRM_SYNCING = 1,
  PATH_PUBLISHING = 2,
  GENERATING_REPORT = 3,
  COPY_FUNNEL = 4,
  REGEN_INVOICE = 5,
  CRM_TRANSACTION_SYNCING = 6,
  DEV_ENV_CREATION = 7,
  CRM_DELETION = 8,
}

export interface Notification extends BaseModel {
  type: NotificationType;
  event_name: string;
  account: Account;
  payload: object;
  message: string;
  created_at: string;
  updated_at: string;
  is_read: boolean;
  event_status: EventStatus;
}

export interface DevelopmentEnvironment extends BaseModel {
  hash: string;
  angular_branch: string;
  python_branch: string;
  vue_branch: string;
  is_created: boolean;
}
