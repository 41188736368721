import {Component, OnInit, Input, ViewEncapsulation, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelStepService, FunnelService, FunnelInputService} from '../_services';
import {Funnel, FunnelStep, FunnelInput, Action, getExitLabel, getAlternateExitLabel} from '../_models';
import {Form} from '../_forms';
import {curveBundle} from 'd3-shape';
import {takeUntil, mergeMap} from "rxjs/operators";
import {plainText as getPlainText} from "../_helpers";
import {FunnelActionFieldsComponent} from "../funnel-action";

@Component({
  moduleId: module.id.toString(),
  encapsulation: ViewEncapsulation.None,
  selector: 'funnel-steps',
  templateUrl: './funnel-steps.component.html',
  styleUrls: ['./funnel-steps.component.scss']
})
export class FunnelStepsComponent extends Form implements OnInit, OnChanges {
  @Input('funnel') funnel: Funnel;
  @Input('showActions') showActions = false;
  plainText = getPlainText;

  chart: any;
  hierarchialGraph: { links: any[], nodes: any[] };

  view: any[];
  autoZoom = false;
  panOnZoom = true;
  draggingEnabled = true;
  panningEnabled = true;
  zoomLevel = 0.7;

  // options
  showLegend = false;
  orientation: string = 'TB'; // LR, RL, TB, BT

  // line interpolation
  curve: any = curveBundle.beta(1);

  // link dragging
  private selectedLink = null;
  private selectedLinkOriginalTarget = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private funnelService: FunnelService,
    private stepService: FunnelStepService,
    private inputService: FunnelInputService
  ) {
    super(alertService, router, location);

    this.hierarchialGraph = {nodes: [], links: []};
    this.chart = {
      name: 'Directed Graph',
      selector: 'directed-graph',
      inputFormat: 'graph',
      options: ['colorScheme', 'showLegend']
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.stepService.setFunnelId(this.funnel.id);
    this.buildGraph();
  }

  ngOnChanges(): void {
    this.buildGraph();
  }

  private buildGraph() {
    this.selectedLink = null;
    this.selectedLinkOriginalTarget = null;
    this.hierarchialGraph.nodes = [];
    this.hierarchialGraph.links = [];
    const firstId = '0';
    let has_exit = false;
    let has_alternate_exit = false;

    if (this.funnel.steps && this.funnel.steps.length) {
      //show each step in the funnel
      this.funnel.steps.forEach((step: FunnelStep) => {
        let node = {
          id: 'step_' + step.id.toString(),
          stepId: step.id.toString(),
          label: [FunnelStepService.getStepName(step)],
          tooltip: ''
        };

        if (step.label) {
          node.tooltip += '<div>' + step.label + '</div>';
        }

        if (step.content) {
          node.tooltip += '<div>' + step.content + '</div>';
        }

        this.hierarchialGraph.nodes.push(node);

        if (step.inputs && step.inputs.length) {
          //show each input link to their next steps
          step.inputs.forEach((input: FunnelInput) => {
            let targetId = input.next_step ? input.next_step : null;
            let target = '';
            let actionLines = [];

            if (input.actions) {
              input.actions.forEach((action: Action) => {
                actionLines = actionLines.concat(FunnelActionFieldsComponent.getActionDescriptions(action));
              });
            }

            if (targetId) {
              target = 'step_' + targetId.toString();
            }
            else {
              if (input.is_alt_child_exit) {
                target = 'alt_exit';
                has_alternate_exit = true;
              }
              else {
                target = 'exit';
                has_exit = true;
              }
            }

            if (this.showActions && input.actions && input.actions.length) {
              //input has actions, create a new node for the actions
              this.hierarchialGraph.nodes.push({
                id: 'actions_' + input.id.toString(),
                label: actionLines,
                stepId: input.step,
                inputId: input.id
              });

              //create a link for the input on the step to its actions
              this.hierarchialGraph.links.push({
                id: 'inputactions_' + input.id.toString(),
                source: 'step_' + step.id.toString(),
                target: 'actions_' + input.id.toString(),
                targetId: null,
                label: input.label,
                stepId: input.step,
                inputId: input.id,
                autopick: false
              });

              //create another link from the actions to the next step
              this.hierarchialGraph.links.push({
                id: 'input_' + input.id.toString(),
                source: 'actions_' + input.id.toString(),
                target: target,
                targetId: targetId,
                label: '',
                stepId: input.step,
                inputId: input.id,
                autopick: false
              });
            }
            else {
              let inputTooltip = '<p>' + input.label + '</p>';

              if (actionLines.length) {
                inputTooltip += '<p><div>' + actionLines.join('</div><div>') + '</div></p>';
              }

              //input has no actions, so show single link between the steps
              this.hierarchialGraph.links.push({
                id: 'input_' + input.id.toString(),
                source: 'step_' + step.id.toString(),
                target: target,
                targetId: targetId,
                label: input.label,
                tooltip: inputTooltip,
                stepId: input.step,
                inputId: input.id,
                autopick: false
              });
            }

            if (input.autopick_next_step) {
              //create another link for the autopick next step
              this.hierarchialGraph.links.push({
                id: 'input_' + input.id.toString() + '_autopick',
                source: 'step_' + step.id.toString(),
                target: 'step_' + input.autopick_next_step.toString(),
                targetId: input.autopick_next_step,
                label: input.label + ' (Autopick)',
                stepId: input.step,
                inputId: input.id,
                autopick: true
              });
            }
          });
        }
      });

      if (has_exit) {
        this.hierarchialGraph.nodes.push({
          id: 'exit',
          label: [getExitLabel(this.funnel.resourcetype)],
          stepId: null
        });
      }

      if (has_alternate_exit) {
        this.hierarchialGraph.nodes.push({
          id: 'alt_exit',
          label: [getAlternateExitLabel(this.funnel.resourcetype)],
          stepId: null
        });
      }
    }

    this.hierarchialGraph.nodes.forEach(node => {
      if (node.id === 'step_' + this.funnel.first_step.toString()) {
        node.id = firstId;
      }
    });

    this.hierarchialGraph.links.forEach(link => {
      if (link.source === 'step_' + this.funnel.first_step.toString()) {
        link.source = firstId;
      }

      if (link.target === 'step_' + this.funnel.first_step.toString()) {
        link.target = firstId;
      }
    });
  }

  selectNode(node) {
    if (!!node.stepId) {
      if (!!node.inputId) { //product funnel entry or input actions
        this.router.navigate(['/path', this.funnel.id, 'step', node.stepId, 'input', 'edit', node.inputId]);
      }
      else { //normal step
        this.router.navigate(['/path', this.funnel.id, 'step', 'edit', node.stepId]);
      }
    }
  }

  copyStep(stepId, event) {
    event.stopPropagation();
    this.stepService.copy(stepId);
  }

  canPasteStep() {
    return this.stepService.canPaste();
  }

  pasteStep() {
    if (this.funnel) {
      this.stepService.paste()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (step: FunnelStep) => {
            this.funnel.steps.push(step);
            this.buildGraph();
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  isSelectedForDrag(linkOrNode) {
    return this.selectedLink && linkOrNode.inputId && (this.selectedLink.inputId === linkOrNode.inputId);
  }

  startDragLink(link) {
    if (!!link && !this.selectedLink) {
      if (this.isLinkToStep(link)) {
        // select the link that we started to drag
        console.log('start dragging link');
        this.selectedLink = link;
        this.selectedLinkOriginalTarget = link.target;
      }
      else {
        // this is a link to an input's actions, so start dragging with the matching link to the next step
        this.startDragLink(this.getLinkToNextNode(link.inputId));
      }
    }
  }

  startDragNode(node) {
    if (!this.selectedLink && this.isNodeForActions(node)) {
      // this is a node for input actions, so start dragging with the matching link to the next step
      this.startDragLink(this.getLinkToNextNode(node.inputId));
    }
  }

  dragLink(node) {
    if (this.selectedLink && this.isNodeForStep(node)) {
      // if we dragged a link to a node, update the link's target to the node
      console.log('dragged link to node ' + node.id);
      this.selectedLink.targetId = node.stepId;
      this.selectedLink.target = node.id;

      this.hierarchialGraph.links = this.hierarchialGraph.links.filter(
        link => link.id != this.selectedLink.id);
      this.hierarchialGraph.links.push(this.selectedLink);
    }
  }

  endDragLink() {
    if (this.selectedLink) { // if we finished dragging a link
      console.log('end dragging link');

      if (this.selectedLink.target === this.selectedLinkOriginalTarget) {
        // if link's target didn't change, then there's nothing to do - just clear the selected link
        this.selectedLink = null;
        this.selectedLinkOriginalTarget = null;
      } else {
        // if link's target changed, update the target
        if (this.selectedLink.inputId) {
          // link points to a step input - so update the input's next step
          const patchData = this.selectedLink.autopick ? {autopick_next_step: this.selectedLink.targetId}
            : {next_step: this.selectedLink.targetId};
          this.inputService.setStepId(this.selectedLink.stepId);
          this.inputService.patch(this.selectedLink.inputId, patchData).pipe(
            mergeMap(() => {
              return this.funnelService.get(this.funnel.id);
            }),
            takeUntil(this._destroy$)
          ).subscribe(
            (result: Funnel) => {
              this.funnel = result;
              this.buildGraph();
            },
            error => {
              this.handleSubmitError(error);
            });
        }
      }
    }
  }

  selectLink(link) {
    if (!!link.inputId) {
      this.router.navigate(['/path', this.funnel.id, 'step', link.stepId, 'input', 'edit', link.inputId]);
    }
  }

  deleteStep(step, event) {
    event.stopPropagation();

    if (!!step.stepId) {
      if (window.confirm('Are you sure you want to delete this step?')) {
        this.stepService.delete(step.stepId).pipe(
          mergeMap(() => {
            return this.funnelService.get(this.funnel.id);
          }),
          takeUntil(this._destroy$)
        ).subscribe(
          (result: Funnel) => {
            this.alertService.success('Deleted Step.');
            this.funnel = result;
            this.buildGraph();
          },
          error => {
            this.handleSubmitError(error);
          });
      }
    }
  }

  getNodeHeight(node) {
    let height = node.height;

    if (node.label.length > 1) {
      height += (node.label.length - 1) * 10;
    }

    return height;
  }

  getNodeLineYPosition(node, i) {
    let height = this.getNodeHeight(node);
    let lineCount = node.label.length;

    return ((height - (15 * lineCount)) / 2) + (15 * i) + 12;
  }

  getNodeTooltip(node) {
    return node.tooltip || '<div>' + node.label.join('</div><div>') + '</div>';
  }

  isNodeForStep(node) {
    return node.id.startsWith('step_');
  }

  isNodeForActions(node) {
    return node.id.startsWith('actions_');
  }

  isLinkToActions(link) {
    return link.id.startsWith('inputactions_');
  }

  isLinkToStep(link) {
    return link.id.startsWith('input_');
  }

  getLinkTooltip(link) {
    return link.tooltip || link.label;
  }

  getLinkToNextNode(inputId) {
    return this.hierarchialGraph.links.find(link => (link.inputId === inputId) && this.isLinkToStep(link));
  }

}
