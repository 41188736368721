import {Component} from '@angular/core';
import {CrudPagedListComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, RelatedProductSetCategoryService} from '../_services';
import {Observable} from 'rxjs';
import {RelatedProductCategoryType, RelatedProductCategoryTypeLabel, RelatedProductSetCategory} from '../_models';
import {config} from '../../config/config';

@Component({
  moduleId: module.id,
  selector: 'related-product-set-category',
  templateUrl: './related-product-set-category.component.html'
})
export class RelatedProductSetCategoryComponent extends CrudPagedListComponent {
  relatedProductSetCategory$: Observable<RelatedProductSetCategory[]> = this.data$

  // hard-coded for now
  public typeLabel: string = RelatedProductCategoryTypeLabel[RelatedProductCategoryType.EXCHANGEABLE]

  pageSize = config.maxPageSize

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected relatedProductSetCategoryService: RelatedProductSetCategoryService,
    protected alertService: AlertService
  ) {
    super(router, location, route, relatedProductSetCategoryService, alertService);
    this.title = `${this.typeLabel} Products set category`
  }


  protected runInitialQuery() {
    this.queryData()
  }

  protected getQueryFilter() {
    let filter = super.getQueryFilter()
    filter['parent__isnull'] = true
    return filter
  }
}
