import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  AccountService,
  AlertService,
  CampaignProductService,
  CampaignService,
  CampaignTrackingService,
  CRMCampaignService,
  FunnelStepService,
  MerchantService,
  RefundReportService,
  ReportsConfigService,
  RetentionReportService,
  StorageService,
  UserService,
} from "../_services";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ReportsAbstractV2Component } from "./reports-abstract-v2.component";
import { MultiOptions } from "../custom-multi-select/multi-select";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { fields, filters } from "./utils/constants";
import { cloneDeep } from "lodash";
import { ReportDimensionCategories, ReportDimensions, ReportTypes } from "./models/reports";

@Component({
  moduleId: module.id.toString(),
  selector: "return-retention-report-v2",
  templateUrl: "./templates/reports-v2.component.html",
  styleUrls: ["./styles/reports-v2.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReturnRetentionReportV2Component
  extends ReportsAbstractV2Component
  implements OnInit, AfterViewInit
{
  reportTitle = "Return Retention Report";
  fields: MultiOptions = cloneDeep(fields);
  filters: MultiOptions = cloneDeep(filters);
  reportType = ReportTypes.RETURN_RETENTION;

  commonReportsFields = [
    "rma_request",
    "return_quantity",
    "deflected_rma",
    "rma_save",
  ];
  summaryFields = [
    "session_count",
    "rma_request",
    "return_quantity",
    "deflected_rma",
    "rma_save",
    "save_amount",
    "refund_amount",
    "rma_save_rate",
  ];
  selectedDimensions = [
    {
      model: "rma_path",
      label: "Product Path",
      selected: true,
      disabled: false,
    },
    {
      model: "product_name",
      label: "Product Name",
      selected: true,
      disabled: false,
    },
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: RetentionReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(
      router,
      location,
      route,
      alertService,
      reportsService,
      campaignService,
      reportsConfigService,
      trackingSourceService,
      userService,
      accountService,
      campaignProductService,
      crmCampaignService,
      storageService,
      stepService,
      merchantService,
      modalService
    );
    
    this.fields[ReportDimensionCategories.Dimensions].options.find((x) => x.model === ReportDimensions.ProductPath).model =
    ReportDimensions.RMAPath;
    this.updatePreSelectedDimensions([ReportDimensions.RMAPath, ReportDimensions.ProductName]);
    this.filterCommonDimensions(this.commonReportsFields);
  }

  ngOnInit() {
    this.predefinedFilters = { rma_request__gt: 0 };
    super.ngOnInit();

  }

  ngAfterViewInit() {
    if (this.panel) {
      this.panel.expanded = true;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedDimensions,
      event.previousIndex,
      event.currentIndex
    );
  }
}
