import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {
  AccountService,
  AlertService, CampaignProductService,
  CampaignService,
  CampaignTrackingService, CRMCampaignService, FunnelStepService,
  ReportsConfigService, ChargebackReportService,
  StorageService, UserService, RefundReportService, MerchantService
} from '../_services';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {NgxSmartModalService} from "ngx-smart-modal";
import {ReportsAbstractComponent} from "./reports-abstract.component";
import {resolve} from '@angular/compiler-cli/src/ngtsc/file_system';
import {ReportSessionInput, TranMatchedStatus} from '../_models';

@Component({
  selector: 'chargeback-report',
  templateUrl: './templates/chargeback-report.component.html',
  styleUrls: ['./styles/reports.component.css'],
})
export class ChargebackReportComponent extends ReportsAbstractComponent {
  fields = {
    dimensions: [
      [
        {model: "affiliate", label: "Affiliate", default: false, disabled: false},
        {model: "sub1", label: "Aff sub 1", default: false, disabled: false},
        {model: "sub2", label: "Aff sub 2", default: false, disabled: false},
        {model: "sub3", label: "Aff sub 3", default: false, disabled: false},
        {model: "alert_source", label: "Alert Source", default: false, disabled: false},
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
        {model: "campaign", label: "Brand", default: false, disabled: false},
        {model: "card_bin", label: "Card BIN", default: false, disabled: false},
      ],
      [
        {model: "card_type", label: "Card Type", default: false, disabled: false},
        {model: "card_last4", label: "Card Last 4", default: false, disabled: false},
        {model: "chargeback_date", label: "Chargeback Date", default: false, disabled: false},
        {model: "crm_campaign", label: "CRM campaign", default: false, disabled: false},
        {model: "customer_id", label: "Customer ID", default: false, disabled: false},
        {model: "sp_session_latest_result", label: "Latest Result", default: false, disabled: false},
        {model: "merchant_id", label: "Merchant Processor", default: false, disabled: false},
        {model: "mid_number", label: "MID number", default: false, disabled: false},
      ],
      [
        {model: "payment_source", label: "Payment Method", default: false, disabled: false},
        {model: "sp_session_path", label: "Product Path", default: false, disabled: false},
        {model: "refund_date", label: "Refund Date", default: false, disabled: false},
        {model: "request_type", label: "Request Type", default: false, disabled: false},
        {model: "sp_session_id", label: "Solvpath Session ID", default: false, disabled: false},
        {model: "sp_session_result", label: "Session Result", default: false, disabled: false},
        {model: "sp_session_source", label: "Session Source", default: false, disabled: false},
        {model: "sp_session_status", label: "Session Status", default: false, disabled: false},
      ],
      [
        {model: "matched_status", label: "Solvpath Matched Type", default: false, disabled: false},
        {model: "sp_session_step", label: "Step", default: false, disabled: false},
        {model: "sp_session_steps_count", label: "Steps Count", default: false, disabled: false},
        {model: "amount", label: "Transaction Amount", default: false, disabled: false},
        {model: "transaction_id", label: "Transaction ID", default: false, disabled: false},
        {model: "created", label: "Transaction Date", default: false, disabled: false},
        {model: "transaction_type", label: "Transaction Type", default: false, disabled: false},
      ]
    ],
    timeInterval: [],
    statistics: [
      [
        {model: "alert_count", label: "Alert Count", default: false, disabled: false},
        {model: "alert_ratio", label: "Alert Ratio", default: false, disabled: false},
      ],
      [
        {model: "chargeback_count", label: "Chargeback Count", default: false, disabled: false},
        {model: "chargeback_ratio", label: "Chargeback Ratio", default: false, disabled: false},
      ],
      [
        {model: "dispute_count", label: "Dispute Count", default: false, disabled: false},
        {model: "dispute_ratio", label: "Dispute Ratio", default: false, disabled: false},
      ],
      [
        {model: "transaction_count", label: "Transaction Count", default: false, disabled: false},
        {model: "time_period", label: "Time Period", default: false, disabled: false},
      ]
    ],
    calculations: [],
    exclude: [
      [
        {model: "exclude_zeroes", label: "All Zeroes", default: true, disabled: false, isGlobal: false}
      ]
    ],
    filters: [
      [
        {model: "alert_source", label: "Alert Source", default: false, disabled: false},
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
        {model: "campaign", label: "Brand", default: false, disabled: false},
        {model: "card_bin", label: "Card BIN", default: false, disabled: false},
      ],
      [
        {model: "card_type", label: "Card Type", default: false, disabled: false},
        {model: "card_last4", label: "Card Last 4", default: false, disabled: false},
        {model: "crm_campaign", label: "CRM Campaign", default: false, disabled: false},
        {model: "customer_email", label: "Customer Email", default: false, disabled: false},
      ],
      [
        {model: "customer_id", label: "Customer ID", default: false, disabled: false},
        {model: "merchant_id", label: "Merchant Processor", default: false, disabled: false},
        {model: "mid_number", label: "MID Number", default: false, disabled: false},
        {model: "matched_status", label: "Solvpath Matched Type", default: false, disabled: false},
      ],
      [
        {model: "amount", label: "Transaction Amount", default: false, disabled: false},
        {model: "transaction_id", label: "Transaction ID", default: false, disabled: false},
      ]
    ],
    filtersData: [
      [
        {model: "merchant_id", filter: "merchant_id", type: "multi-select", label: "Merchant Processor", default: "", options: []},
        {model: "mid_number", filter: "mid_number", type: "number", label: "MID number", default: ""},
        {model: "card_last4", filter: "card_last4", type: "number", label: "MID number", default: "", allowCharacters: 4},
        {model: "card_bin", filter: "card_bin", type: "number", label: "Card BIN", default: "", allowCharacters: 6},
        {model: "customer_email", filter: "customer_email", type: "email", label: "Customer Email", default: ""},
        {model: "customer_id", filter: "customer_id", type: "number", label: "Customer ID", default: ""},
        {model: "billing_cycle", filter: "billing_cycle", type: "arithmetic-comparison-input", label: "Billing Cycle",
          default: "", options: [], comparisonDefault: 'e', comparisonOptions: [
            {id: "e", text: "Equals to"},
            {id: "gt", text: "Greater than"},
            {id: "gte", text: "Greater than or equals to"},
            {id: "lt", text: "Less than"},
            {id: "lte", text: "Less than or equals to"},
            {id: "range", text: "Between"},
          ]},
        {model: "campaign", filter: "campaign", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "crm_campaign", filter: "crm_campaign", type: "multi-select", label: "CRM campaign", default: "", options: []},
        {model: "campaign_product_ids", filter: "campaign_product_ids", type: "multi-select", label: "Campaign Product Id", options: [], default: ""},
        {model: "transaction_id", filter: "transaction_id", label: "Transaction ID", type: "number"},
        {model: "amount", filter: "amount", type: "arithmetic-comparison-input", label: "Transaction Amount",
          default: "", options: [], comparisonDefault: 'e', comparisonOptions: [
            {id: "e", text: "Equals to"},
            {id: "gt", text: "Greater than"},
            {id: "gte", text: "Greater than or equals to"},
            {id: "lt", text: "Less than"},
            {id: "lte", text: "Less than or equals to"},
            {id: "range", text: "Between"},
          ]},
        {model: "card_type", filter: "card_type", label: "Card Type", type: "multi-select", default: 0, options: [
            {id: 0, text: "NONE"},
            {id: 1, text: "VISA"},
            {id: 2, text: "MASTERCARD"},
            {id: 3, text: "AMEX"},
            {id: 4, text: "DISCOVER"},
            {id: 5, text: "OTHER"},
          ]},
        {model: "alert_source", filter: "alert_source", label: "Alert Source", type: "multi-select", default: 0, options: [
            {id: 0, text: "NONE"},
            {id: 1, text: "ETHOCA"},
            {id: 2, text: "VERIFI"},
            {id: 3, text: "RDR"},
            {id: 4, text: "OTHER"}
          ]},
        {model: "matched_status", filter: "matched_status", label: "Solvpath Matched Status", type: "multi-select", default: 0, options: [
            {id: 0, text: "No Match"},
            {id: 1, text: "Match By Customer"},
            {id: 2, text: "Match By Session"},
            {id: 3, text: "Match By Order"}
          ]}
      ]
    ]
  };
  showDateFilterTypeField = false;
  useReportsConfiguration = false;
  displayIntent = false;
  chargebackRatioDependantFields = {
    'statistics': ['time_period'],
    'dimensions': ['merchant_id']
  };
  anyFieldCheckError = "Please select at least one of the fields from dimensions and statistics to generate report";
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: ChargebackReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService,
      campaignService, reportsConfigService, trackingSourceService, userService, accountService,
      campaignProductService, crmCampaignService, storageService, stepService, refundReportService,
      merchantService);
    this.title = 'Chargeback Report';
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.addControl('spTouched', new FormControl('all'))
  }

  initializeStepsFilters(date_range){
  }

  anyFieldChecked(){
    let fieldsThatMustBeChecked = ['dimensions', 'statistics'];
    let formData = this.form.value;
    let anyDimensionChecked = false;
    let anyStatisticsChecked = false;

    Object.keys(formData).forEach(mainKey => {
      if (fieldsThatMustBeChecked.indexOf(mainKey) > -1) {
        let fields = formData[mainKey];
        Object.keys(fields).forEach(key => {
          if (fields[key]) {
            if (mainKey === 'dimensions'){
              anyDimensionChecked = true;
            }
            else if (mainKey === 'statistics'){
              anyStatisticsChecked = true;
            }
          }
        })
      }
    });

    return anyDimensionChecked && anyStatisticsChecked;
  }
  getReports(displayOnScreen: boolean = true){
    if(this.form.controls['statistics']['controls']['chargeback_ratio']['value'] ||
      this.form.controls['statistics']['controls']['alert_ratio']['value'] ||
      this.form.controls['statistics']['controls']['dispute_ratio']['value']
    ){
      for( let i= 0; i < Object.keys(this.chargebackRatioDependantFields).length; i++){
        let controlName = Object.keys(this.chargebackRatioDependantFields)[i];
        const controlGroup = this.form.controls[controlName];
        if (controlGroup['controls']){
          for(let j = 0; j < this.chargebackRatioDependantFields[controlName].length; j++) {
            let formControlName = this.chargebackRatioDependantFields[controlName][j];
            const control = controlGroup['controls'][formControlName];
            control.enable();
          }
        }
      }
      // intentionally set this to 0
      setTimeout(()=> {
        super.getReports(displayOnScreen)
      }, 0)
    } else {
      super.getReports(displayOnScreen)
    }
  }

  toggleBooleanField(event, fieldName: string) {
    super.toggleBooleanField(event, fieldName);
    let ratiosArray = ['statistics.chargeback_ratio', 'statistics.alert_ratio',
      'statistics.dispute_ratio']
    if(ratiosArray.includes(fieldName)){
      Object.keys(this.chargebackRatioDependantFields).forEach(controlName => {
        let controlGroup = this.form.controls[controlName];
        let statisticsControlGroup = this.form.controls['statistics']['controls']
        if (controlGroup['controls']) {
          this.chargebackRatioDependantFields[controlName].forEach(formControlName => {
            let control = controlGroup['controls'][formControlName];
            if(event.target.checked){
              control.patchValue(true)
              control.disable()
            }
            else if (!statisticsControlGroup['dispute_ratio']['value'] &&
                !statisticsControlGroup['alert_ratio']['value'] &&
                !statisticsControlGroup['chargeback_ratio']['value'])
              {
                control.patchValue(false);
                control.enable();
              }
          })
        }
      })
    }
  }

  getSessionFieldValue(sessionInput: ReportSessionInput, field: string) {
    if (field === "campaign" || field === "crm_campaign" || field === "merchant_id"){
      let data = this.filtersDynamicOptions[field].find(data => data.id === sessionInput[field])
      return data ? data.text : '';
    } else {
      return super.getSessionFieldValue(sessionInput, field);
    }
  }
}
