import {Component, OnInit} from '@angular/core';
import {StorageService, AlertService} from "../_services";
import {Location} from "@angular/common";
import {ActivatedRoute} from "@angular/router";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './profiler.component.html',
  styleUrls: ['./profiler.component.css']
})
export class ProfilerDisableComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private alertService: AlertService,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const key = this.route.snapshot.params['type'] === 'cprofiler' ? 'cprofilerEnabled' : 'profilerEnabled';
    this.storageService.set(key, false);
    this.alertService.success('Profiler Disabled', true);
    this.location.back();
  }

}
