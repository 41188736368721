import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AccountService,
  AlertService,
  CampaignService,
  CampaignTrackingService,
  ReportsConfigService,
  UserService,
  CampaignProductService,
  CRMCampaignService,
  StorageService,
  FunnelStepService,
  RefundReportService,
  MarketplaceReportService,
  MerchantService
} from '../_services';
import {FormBuilder, FormControl} from '@angular/forms';
import {NgxSmartModalService} from "ngx-smart-modal";
import {ReportsAbstractComponent} from "./reports-abstract.component";
import {config} from '../../config/config';
import {takeUntil} from 'rxjs/operators';
import {Campaign, Pager, TrackingSource, generateReportsQuery} from '../_models';

@Component({
  selector: 'marketplace-reports',
  templateUrl: './templates/marketplace-report.component.html',
  styleUrls: ['./styles/reports.component.css'],
})
export class MarketplaceReportComponent extends ReportsAbstractComponent{
  public fields = {
    dimensions: [
      [
        {model: "username", label: "Session ID", default: false, disabled: false},
        {model: "campaign_name", label: "Brand", default: false, disabled: false},
        {model: "upsell", label: "Upsell", default: false, disabled: true},
        {model: "product_name", label: "Product Name", default: false, disabled: false},
      ],
      [
        {model: "subscription", label: "Subscription", default: false, disabled: true},
        {model: "source", label: "Path channel", default: false, disabled: false},
        {model: "payment_method", label: "Payment Method", default: false, disabled: false},
        {model: "watchlist", label: "Watch list", default: false, disabled: false},
      ],
      [
        {model: "tracking_source", label: "Traffic Source", default: false, disabled: false},
        {model: "sid1", label: "Source sub 1", default: false, disabled: false},
        {model: "sid2", label: "Source sub 2", default: false, disabled: false},
        {model: "sid3", label: "Source sub 3", default: false, disabled: false},
      ],
      [
        {model: "crm_campaign_name", label: "CRM Campaign", default: false, disabled: false},
      ],
    ],
    timeInterval: [],
    statistics: [
      [
        {model: "total_views", label: "Total Views", default: false, disabled: false},
        {model: "total_add_to_carts", label: "Total Add to carts", default: false, disabled: false},
        {model: "total_marketplace_views", label: "Visitors", default: false, disabled: false},
      ],
      [
        {model: "total_checked_outs", label: "Total Checked outs", default: false, disabled: false},
        {model: "total_buy_nows", label: "Total Buy nows", default: false, disabled: false},
        {model: "total_cart_abandoned", label: "Total Cart Abandoned", default: false, disabled: false},
      ],
      [
        {model: "total_revenue", label: "Revenue", default: false, disabled: false},
        {model: "conversion_based_on_add_to_cart", label: "Interested Buyers Converted", default: false, disabled: false},
        {model: "total_card_declined", label: "Total Card Declined", default: false, disabled: false},
      ],
      [
        {model: "total_sale", label: "Total Sales", default: false, disabled: false},
        {model: "conversion_based_on_visits", label: "Visits Converted", default: false, disabled: false},
      ]
    ],
    calculations: [],
    exclude: [
      [
        {model: "exclude_tests", label: "Tests", default: true, disabled: false, isGlobal: false},
      ],
      [
        {model: "exclude_zeroes", label: "All Zeroes", default: true, disabled: false, isGlobal: false},
      ], [], [],
    ],
    filters: [
      [
        {model: "campaign_id", label: "Brand", default: false, disabled: false},
      ],
      [
        {model: "product_id", label: "Product", default: false, disabled: false},
      ],
      [
        {model: "tracking_source", label: "Traffic source", default: "", disabled: false},
      ], []
    ],
    filtersData: [
      [
        {model: "campaign_id", filter: "campaign_id", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "product_id", filter: "product_id", type: "products", label: "Product", default: "", options: []},
        {model: "source", filter: "source", type: "multi-select", label: "Channel source", default: "",
          options: [
            {id: "Web", text: "Web"},
            {id: "Email", text: "Email"},
            {id: "Phone", text: "Phone"},
            {id: "Social Media", text: "Social Media"},
          ]},
        {model: "tracking_source", filter: "tracking_source", type: "multi-select", label: "Traffic source", default: "",
          options: []},
      ]
    ]
  };
  public showDateFilterTypeField = false;
  public sessionTypes = [
    {value: 'all', text: 'All'},
    {value: 'Support', text: 'Support'},
    {value: 'Marketplace', text: 'Marketplace'}
  ];
  public revenue = 0
  public brandSummary:any= []
  public productSummary:any= []
  public brandSummaryFields = ['campaign_name', 'total_marketplace_views',
    'total_checked_outs', 'conversion_based_on_marketplace_views',
    'total_revenue', 'average_order_value' ]
  public productSummaryFields = ['product_name', 'total_views', 'total_quantity',
    'conversion_based_on_views', 'total_revenue' ]

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: MarketplaceReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService,
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService, campaignService,
      reportsConfigService, trackingSourceService, userService, accountService, campaignProductService,
      crmCampaignService, storageService, stepService, refundReportService, merchantService)
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.addControl('sessionType', new FormControl('Support'))
  }

  fillAccountDependantFilters(items?) {
    let accounts = [];
    if (items === undefined) {
      items = this.form.value.account;
    }
    items.forEach(item => {
      accounts.push(item.id);
    })
    let queryParams = {account_id: accounts, page: 1, page_size: config.maxPageSize};
    let campaignFormData = [];
    this.initiateAccountFiltersOperations();
    this.campaignService.list(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          data.results.forEach((item: Campaign) => {
            campaignFormData.push({id: item.id, text: item.display_name});
          })
          if (this.loadedConfiguration) {
            this.loadConfiguration(this.loadedConfiguration);
          }
        },
        error => {
          this.handleError(error);
        }
      );

    let trackingSourcesFromData = [];
    this.trackingSourceService.list(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: TrackingSource[]) => {
          data.forEach((item: TrackingSource) => {
            trackingSourcesFromData.push({id: item.source, text: item.source});
          })
        },
        error => {
          this.handleError(error);
        }
      );

    this.filtersDynamicOptions['campaign_id'] = campaignFormData;
    this.filtersDynamicOptions['campaign'] = campaignFormData;
    this.filtersDynamicOptions['tracking_source'] = trackingSourcesFromData;
  }

  getFieldLabel(field: string) : string {
    return this.fieldData[field].label;
  }

  getFieldValue(row, field: string) {
    let value = row[field]

    if ('getter' in this.fieldData[field]) {
      value = this.fieldData[field].getter(row, value);
    }

    return value;
  }

  initializeStepsFilters(date_range) {
    super.initializeStepsFilters(date_range);
    this.populateStaticTables()
  }
  populateStaticTables(){
    // adding settimeout so the custom form fields get initialized
    setTimeout(()=>{
      this.getTotalRevenue()
      this.getBrandSummary()
      this.getProductSummary()
    },0)
  }

  getTotalRevenue(){
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getTotalRevenue(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {

        if (result) {
          this.revenue = this.getFieldValue(result, 'total_revenue')
        }

        this.loading = false
      }, error => {
          this.loading = false
          this.handleError(error)
        })
  }

  getBrandSummary(){
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getBrandSummary(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {

        if (result) {
          this.brandSummary = result
        }

        this.loading = false
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  getProductSummary(){
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getProductSummary(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {

        if (result) {
          this.productSummary = result
        }

        this.loading = false
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  downloadReport() {
    this.loading = true;
    this.getReports(false);

    if (this.formValid) {
      setTimeout(()=>{
        let data = generateReportsQuery(this.form.value);
        data['start_date_formatted'] = this.form.value.date_range.startDate.format('MMM Do, YYYY')
        data['end_date_formatted'] = this.form.value.date_range.endDate.format('MMM Do, YYYY')
        this.reportsService.downloadFile(data, this);
      },0)

    }
  }


}
