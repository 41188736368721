import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  OfferIntentChildTypeLabels,
  OfferIntentChildTypes,
  OfferIntents,
  OrderType,
  StepEditorIds,
  StepElements,
  StepTextual,
  StepTextualLayout
} from '../../../_models';
import { getFunnelPathVariables } from '../../../_models/funnel';
import { FormGroup } from '@angular/forms';
import { Form, FormControlStatus } from '../../../_forms';
import { AlertService, StepEmulatorService } from '../../../_services';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { addDummyValues, FontFamilyFormats, FontSizes, initializeEditorStyles } from '../../step-data';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as tinymce from 'tinymce';

@Component({
  selector: 'step-textual',
  templateUrl: './step-textual.component.html',
  styleUrls: ['./step-textual.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))])
    ])
  ]
})
export class StepTextualComponent extends Form implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() layout: StepTextualLayout;
  @Input() isPurchaseType: boolean;
  @Input() folderId: string = '';
  @Input() templateId: string = '';
  @Input() stepId: string = '';
  stepTextual: StepTextual;
  stepEditorIds = StepEditorIds;
  stepElements = StepElements;
  private subscription: Subscription;
  funnelPathVariables: { value: string; text: string }[] = [];
  orderTypes = OrderType;
  intentChildTypes = OfferIntentChildTypes[OfferIntents.CancelOrder];
  @ViewChild('nameInputElement', { static: false }) nameInputElement: ElementRef;
  @ViewChild('bodyInputElements', { static: false }) bodyInputElements: ElementRef;

  constructor(
    protected location: Location,
    protected alertService: AlertService,
    protected stepEmulatorService: StepEmulatorService,
    protected router: Router
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.subscription = this.stepEmulatorService.stepTextual$.subscribe((data) => {
      this.stepTextual = data;
    });

    this.funnelPathVariables = getFunnelPathVariables();
    this.initalizeValues();
  }

  initalizeValues() {
    this.stepTextual.banner.value = addDummyValues(this.form.get('banner').value, this.funnelPathVariables);
    this.stepTextual.body.label = addDummyValues(this.form.get('label').value, this.funnelPathVariables);
    this.stepTextual.body.content = addDummyValues(this.form.get('content').value, this.funnelPathVariables);

    const banner = this.form.get('banner').value;
    if (banner) {
      const { backgroundColor, updatedHtml } = this.extractAndRemoveBackgroundColor(banner);
      this.stepTextual.banner.background = backgroundColor;
      this.stepTextual.banner.value = updatedHtml;
      this.form.get('banner').setValue(updatedHtml);
    }
  }

  extractAndRemoveBackgroundColor(htmlString: string): {
    backgroundColor: string;
    updatedHtml: string;
  } {
    const backgroundColorMatch = htmlString.match(/background-color:\s*([^;]+);/i);
    const backgroundColor = backgroundColorMatch ? backgroundColorMatch[1] : '';
    const updatedHtml = backgroundColor ? htmlString.replace(/background-color:\s*[^;]+;/i, '') : htmlString;
    return { backgroundColor, updatedHtml };
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  bannerEditor = {
    height: 150,
    resize: false,
    plugins: 'line-height emoticons paste',
    toolbar:
      'bold italic underline strikethrough | fontsizeselect fontselect lineheightselect| forecolor   | alignleft aligncenter alignright | emoticons',
    fontsize_formats: FontSizes,
    font_formats: FontFamilyFormats,
    paste_as_text: true,
    setup: (editor: tinymce.Editor) => {
      initializeEditorStyles(editor, '16px', "'Open Sans', sans-serif", true);

      editor.on('Change KeyUp', () => {
        const content = editor.getContent();
        this.onBannerChange(content);
      });
    }
  };

  labelEditor = {
    height: 140,
    resize: false,
    plugins: 'line-height emoticons link paste',
    toolbar:
      'bold italic underline strikethrough link | fontsizeselect fontselect lineheightselect| forecolor backcolor | alignleft aligncenter alignright | emoticons',
    fontsize_formats: FontSizes,
    font_formats: FontFamilyFormats,
    paste_as_text: true,
    auto_focus: this.stepEditorIds.Label,
    setup: (editor: tinymce.Editor) => {
      initializeEditorStyles(editor, '24px', "'Open Sans', sans-serif", true);

      editor.on('Change KeyUp', () => {
        const content = editor.getContent();
        this.onLabelChange(content);
      });
    }
  };

  contentEditor = {
    height: 200,
    plugins: 'lists line-height emoticons link paste',
    toolbar:
      'bold italic underline strikethrough link | fontsizeselect fontselect lineheightselect | forecolor backcolor | alignleft aligncenter alignright | emoticons',
    fontsize_formats: FontSizes,
    font_formats: FontFamilyFormats,
    paste_as_text: true,
    auto_focus: this.stepEditorIds.Label,
    setup: (editor: tinymce.Editor) => {
      initializeEditorStyles(editor, '22px', "'Open Sans', sans-serif", true);

      editor.on('Change KeyUp', () => {
        const content = editor.getContent();
        this.onContentChange(content);
      });
    }
  };

  onBannerChange(content: string) {
    const updatedContent = addDummyValues(content, this.funnelPathVariables);
    this.stepTextual.banner.value = updatedContent;
  }

  onLabelChange(content: string) {
    const updatedContent = addDummyValues(content, this.funnelPathVariables);
    this.stepTextual.body.label = updatedContent;
  }

  onContentChange(content: string) {
    const updatedContent = addDummyValues(content, this.funnelPathVariables);
    this.stepTextual.body.content = updatedContent;
  }

  addBannerVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = event.target.value;
    const editorInstance = tinymce.get(this.stepEditorIds.Banner);
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }

    selectElement.selectedIndex = 0;
  }

  addLabelVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = event.target.value;
    const editorInstance = tinymce.get(this.stepEditorIds.Label);
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }

    selectElement.selectedIndex = 0;
  }

  addContentVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = event.target.value;

    const editorInstance = tinymce.get(this.stepEditorIds.Content);
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }

    selectElement.selectedIndex = 0;
  }

  openColorPicker(colorInput: any) {
    colorInput.click();
  }

  getChildLabel(offerIntent) {
    return OfferIntentChildTypeLabels[offerIntent] || '';
  }

  get isCancelOrder() {
    const cancelIntents = [OfferIntents.CancelOrder, OfferIntents.CancelSubscriptionHold, OfferIntents.CancelTrialHold];
    return cancelIntents.includes(+this.form.get('offer_intent').value);
  }

  get nameInvalidity() {
    return this.enableFormErrors && this.form.controls.name.status === FormControlStatus.Invalid;
  }

  get labelInvalidity() {
    return this.enableFormErrors && this.form.controls.label.status === FormControlStatus.Invalid;
  }

  get contentInvalidity() {
    return this.enableFormErrors && this.form.controls.content.status === FormControlStatus.Invalid;
  }

  public getElementRef(): ElementRef | null {
    if (this.nameInvalidity) {
      return this.nameInputElement;
    }

    if (this.labelInvalidity || this.contentInvalidity) {
      if (!this.isElementVisible(this.stepElements.Body)) {
        this.toggleElement(this.stepElements.Body);
      }

      return this.bodyInputElements;
    }

    return null;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
