import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {
  CheckoutBankComponent,
  CheckoutBankEditComponent,
  CheckoutBankNewComponent,
  CheckoutBankUserEditComponent,
  CheckoutBankUserNewComponent,
  CheckoutGatewayComponent,
  CheckoutGatewayEditComponent,
  CheckoutGatewayNewComponent,
  CheckoutProcessorComponent,
  CheckoutProcessorEditComponent,
  CheckoutProcessorNewComponent,
  CheckoutProcessorUserEditComponent,
  CheckoutProcessorUserNewComponent,
  CheckoutProfileUserComponent,
  ProcessorDocumentPopupComponent,
  CheckoutsComponent,
  CheckoutTemplatesComponent,
  CheckoutTemplateDetailsComponent,
  CheckoutBuilderComponent,
  CheckoutPopupComponent,
  UpsellPopupComponent,
  CheckoutMerchantPopupComponent,
} from '.';
import { CheckoutsRoutingModule } from './checkouts.routing';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SharedModule } from '../_shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatExpansionModule, MatTreeModule } from '@angular/material';

@NgModule({
  declarations: [
    CheckoutsComponent,
    CheckoutTemplatesComponent,
    CheckoutTemplateDetailsComponent,
    CheckoutBuilderComponent,
    CheckoutPopupComponent,
    UpsellPopupComponent,
    CheckoutMerchantPopupComponent,
    CheckoutGatewayComponent,
    CheckoutGatewayNewComponent,
    CheckoutGatewayEditComponent,
    CheckoutBankComponent,
    CheckoutBankEditComponent,
    CheckoutBankNewComponent,
    CheckoutBankUserNewComponent,
    CheckoutBankUserEditComponent,
    CheckoutProcessorComponent,
    CheckoutProcessorEditComponent,
    CheckoutProcessorNewComponent,
    CheckoutProcessorUserEditComponent,
    CheckoutProcessorUserNewComponent,
    ProcessorDocumentPopupComponent,
    CheckoutProfileUserComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CheckoutsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    SharedModule,
    NgbModule,
    MatExpansionModule,
    MatTreeModule,
    NgxSmartModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
  bootstrap: [],
})
export class CheckoutsModule {}
