import { Component, OnInit } from '@angular/core';
import { CheckoutGatewayNewComponent } from './checkout-gateway-new.component';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import {
  AlertService,
  CheckoutBankService,
  CheckoutGatewayService,
  CheckoutProcessorService,
} from '../../../_services';
import { FormBuilder } from '@angular/forms';
import { FieldsToDisable, Gateway } from '../../../_models';

@Component({
  selector: 'app-checkout-gateway-edit',
  templateUrl: './checkout-gateway-edit.component.html',
  styleUrls: ['./checkout-gateway-edit.component.scss', '../profiles-styles.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CheckoutGatewayEditComponent extends CheckoutGatewayNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected gatewayService: CheckoutGatewayService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected bankService: CheckoutBankService,
    protected processorService: CheckoutProcessorService
  ) {
    super(router, location, route, gatewayService, alertService, formBuilder, bankService, processorService);
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this._data$.subscribe(
      (gateway: Gateway) => {
        if (gateway) {
          this.form.patchValue(gateway);
          this.selected_options = gateway.additional_options;
          this.gateway = gateway;

          FieldsToDisable.forEach((field_name) => {
            this.form.controls[field_name].disable();
          });
          this.loading = false;
        }
      },
      (error) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  protected getFormData(): any {
    // enable fields to include their values in form.value
    FieldsToDisable.forEach((field_name) => {
      this.form.controls[field_name].enable();
    });
    let data = super.getFormData();

    // disable them again
    FieldsToDisable.forEach((field_name) => {
      this.form.controls[field_name].disable();
    });

    return data;
  }
}
