
export const environment = {
  production: false,
  apiUrl: "https://api.staging.solvpath.com",
  cbApiUrl: "https://cb.staging.solvpath.com",
  cbDocsUrl: "",
  stripePublicKey: "pk_test_51Hr7vYDWscBUSafciSlE7jpfec9PodfZX9urmtSRlhnzIWp11SAgnDrAKmCswNM3IMbbgpwZhHzwF0DIms5USfy000LcqS7XJk",
  captchaKey: "6Ld1eFIUAAAAALdEKdKIM0c5ejB_d2py9fuKunxB",
  sentryDsn: "https://88f81f5ff36e4fe5979710df8c610f2b@o338987.ingest.sentry.io/5599513",
  sentryDebugMode: false,
  emailDomain: "",
  pusher: {
    key: "5f58037267cd751d3b32",
    cluster: "us2",
    channel: "solvpath-staging",
  }
};
