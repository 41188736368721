import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from "./crud.service";

@Injectable()
export class CampaignDomainService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http, 'custom_domains');
  }

  verify(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/verify/', {});
  }

}
