import {Component, Input, OnInit, OnChanges, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignTrackingService, FunnelStepService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {Form} from '../_forms';
import {Campaign, FunnelStep, TrackingSource, CustomDomain, TicketSystemIDVariables} from "../_models";
import {NgxSmartModalService} from "ngx-smart-modal";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-links.component.html',
  selector: 'campaign-links',
  styleUrls: ['./campaign-edit.component.css']
})
export class CampaignLinksComponent extends Form implements OnInit, OnChanges {
  trackingSources: TrackingSource[] = [];
  steps: FunnelStep[] = [];
  selectedSource: TrackingSource;
  generatedUrl = '';
  @Input('campaign') campaign: Campaign;
  @Input('domain') domain: CustomDomain;
  @Output('cancel') onCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private trackingService: CampaignTrackingService,
    private stepService: FunnelStepService,
    private modalService: NgxSmartModalService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      step: [null],
      tracking_source: [null],
      sid1: [null],
      sid2: [null],
      sid3: [null],
      include_ticket_id: [false, null]
    }));

    super.ngOnInit();
    this.updateForm();
  }

  ngOnChanges() {
    this.updateForm();
  }

  private updateForm() {
    if (this.form) {
      this.resetForm();
      this.trackingSources = [];

      if (this.campaign) {
        this.generatedUrl = this.campaignUrl;
        this.trackingService.list({campaign: this.campaign.id})
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            sources => {
              this.trackingSources = sources;
            },
            error => {
              this.handleError(error);
            }
          );

        this.stepService.getGlobalJumpSteps(false, {
          resourcetype: 'VisualFunnel',
          funnel__campaigns: this.campaign.id
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (steps: FunnelStep[]) => {
              this.steps = steps;
            },
            error => {
              this.handleError(error);
            }
          );
      }
    }
  }

  get campaignUrl() {
    const active_domain = this.domain ? this.domain.domain : this.campaign.active_domain;

    return 'https://' + active_domain + '/';
  }

  createTrackingSource() {
    this.selectedSource = {
      id: null,
      campaign: this.campaign.id,
      source: null
    };
    this.openEditTrackingSourceDialog();
  }

  editTrackingSource(sourceId: number | string) {
    this.trackingSources.some((source: TrackingSource) => {
      if (sourceId.toString() === source.id.toString()) {
        this.selectedSource = source;
        this.openEditTrackingSourceDialog();

        return true;
      }
    });
  }

  private openEditTrackingSourceDialog() {
    this.modalService.getModal('editTrackingSourceDialog').open();
  }

  onSaveTrackingSource(savedSource: TrackingSource) {
    let isNew = true;

    this.modalService.getModal('editTrackingSourceDialog').close();

    if (!this.trackingSources.some((source: TrackingSource, index: number) => {
      if (savedSource.id === source.id) {
        //existing source, update our local value
        this.trackingSources[index] = savedSource;
        return true;
      }
    })) {
      // new source, add it to our list and select it
      this.trackingSources.push(savedSource);
      this.form.patchValue({tracking_source: savedSource.id})
    }

    // re-sort the sources list
    this.trackingSources.sort((a, b): number => {
      if (a.source < b.source) return -1;
      if (a.source > b.source) return 1;
      return 0;
    });
  }

  generateUrl() {
    let url = this.campaignUrl;

    if (this.form.value.step && this.form.value.step.length) {
      url += 'start/' + encodeURIComponent(this.form.value.step);
    }

    let separator = '?';

    if (this.form.value.tracking_source) {
      url += separator + 'tracking_source=' + this.form.value.tracking_source.toString();
      separator = '&';
    }

    if (this.form.value.include_ticket_id && this.campaign.ticket_system && this.campaign.ticket_system.type) {
      url += separator + 'ticket_id=' + TicketSystemIDVariables[this.campaign.ticket_system.type];
      separator = '&'
    }

    ['sid1', 'sid2', 'sid3'].forEach((key: string) => {
      let value: string = this.form.value[key];

      if (value && value.length) {
        value = value.trim();

        if (value.length) {
          url += separator + key + '=' + encodeURIComponent(value.trim().toLowerCase());
          separator = '&';
        }
      }
    });

    this.generatedUrl = url;
  }

}
