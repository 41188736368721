import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, AccountService, UserService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {takeUntil} from "rxjs/operators";
import {User} from '../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'user-invite',
  templateUrl: './user-invite.component.html'
})
export class UserInviteComponent extends Form implements OnInit {
  @Output('invite') onInvite: EventEmitter<any> = new EventEmitter<any>();

  user: User;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private userService: UserService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      group: ['employees', [Validators.required]],
    });

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      this.accountService.invite([this.form.value.email], this.form.value.group)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.alertService.success('Invitations sent.');
            this.loading = false;
            this.onInvite.emit();
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }
}
