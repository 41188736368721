import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {ReportsAbstractComponent} from './reports-abstract.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AccountService,
  AlertService, CampaignProductService,
  CampaignService,
  CampaignTrackingService, CRMCampaignService, FunnelStepService, MerchantService,
  RefundReportService,
  ReportsConfigService, RetentionReportService, StorageService,
  UserService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {reportsFieldData} from '../session/sessionFieldData';
import {ReportIntent, ReportTables, generateReportsQuery} from '../_models';
import {forkJoin} from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  selector: "retention-report",
  templateUrl: "./templates/retention-report.component.html",
  styleUrls: ['./styles/reports.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RetentionReportComponent extends ReportsAbstractComponent {
  public fields = {
    dimensions: [
      [
        {model: "step_name", label: "Step Impressions", default: false, disabled: false},
        {model: "input_name", label: "Input Name", default: false, disabled: false},
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
        {model: "product_path", label: "Product Path", default: false, disabled: false},
      ],
      [
        {model: "crm_campaign_name", label: "CRM campaign", default: false, disabled: false},
        {model: "crm_campaign_id", label: "CRM campaign ID", default: false, disabled: false},
        {model: "product_name", label: "Product name", default: false, disabled: false},
        {model: "campaign_product_id", label: "Campaign product id", default: false, disabled: false},
      ],
      [
        {model: "cancel_reason", label: "Cancel reason", default: false, disabled: false},
        {model: "campaign_name", label: "Brand", default: false, disabled: false},
        {model: "username", label: "Session ID", default: false, disabled: false},
        {model: "order_id", label: "Order ID", default: false, disabled: false},
      ],
      [
        {model: "latest_result", label: "Latest Result", default: false, disabled: false},
        {model: "downsell_step_name", label: "Downsell Variation", default: false, disabled: false},
      ],
    ],
    timeInterval: [
      [
        {model: "hour", label: "Hours", default: false, disabled: false},
        {model: "week", label: "Week", default: false, disabled: false},
        {model: "month", label: "Month", default: false, disabled: false},
        {model: "year", label: "Year", default: false, disabled: false},
      ],
    ],
    statistics: [
      [
        {model: "saved", label: "Total saves", default: false, disabled: false},
        {model: "cancelled", label: "Total cancels", default: false, disabled: false},
        {model: "return_quantity", label: "Returns executed", default: false, disabled: false},
        {model: "orders_count", label: "Orders", default: false, disabled: false},
      ],
      [
        {model: "deflected_hold", label: "Orders Deflected", default: false, disabled: false},
        {model: "deflected_hold_cancel", label: "Deflected Hold Cancels", default: false, disabled: false},
        {model: "subscription_save", label: "Saves on Subscriptions", default: false, disabled: false},
        {model: "rma_save", label: "Saves on RMAs", default: false, disabled: false},
      ],
      [
        {model: "subscription_reactivate", label: "Subscription Reactivates", default: false, disabled: false},
        {model: "hold_save", label: "New Order Saved", default: false, disabled: false},
        {model: "reorder", label: "Reorder", default: false, disabled: true},
        {model: "rma_request", label: "Return Requests", default: false, disabled: false},
      ],
      [
        {model: "hold_cancel", label: "Cancels on Holds", default: false, disabled: false},
        {model: "subscription_cancel", label: "Cancels on Subscriptions", default: false, disabled: false},
        {model: "deflected_subscription_cancel", label: "Deflected Subscription Cancels", default: false, disabled: false},
        {model: "deflected_rma", label: "Deflected RMAs", default: false, disabled: false},
      ]
    ],
    calculations: [
      [
        {model: "downsell_take_rate", label: "Downsell take rate", default: false, disabled: false},
      ]
    ],
    exclude: [
      [
        {model: "exclude_tests", label: "Tests", default: true, disabled: false, isGlobal: false},
        {model: "exclude_no_refunds", label: "No refunds", default: false, disabled: false, isGlobal: false},
        {model: "exclude_bypass", label: "Bypass", default: false, disabled: false, isGlobal: false},
      ],
      [
        {model: "exclude_hangups", label: "Hangups", default: true, disabled: false, isGlobal: false},
        {model: "exclude_lifeline", label: "Lifeline", default: false, disabled: false, isGlobal: false},
        {model: "exclude_unknowns", label: "Unknowns", default: true, disabled: false, isGlobal: false}
      ],
      [
        {model: "exclude_no_activity", label: "No CRM Actions", default: false, disabled: false, isGlobal: false},
        {model: "exclude_any_empty_column", label: "Rows with empty cells", default: false, disabled: false, isGlobal: false},
        {model: "exclude_autopick", label: "Autopicks", default: false, disabled: false, isGlobal: false}
      ],
      [
        {model: "exclude_zeroes", label: "All Zeroes", default: false, disabled: false, isGlobal: false},
        {model: "exclude_child_sessions", label: "Consolidate Sessions", default: true, disabled: false,
          isGlobal: true},
        {model: "exclude_child_orders", label: "Consolidate Orders", default: true, disabled: false, isGlobal: true}
      ],
    ],
    filters: [
      [
        {model: "step_slug", label: "Step Impressions", default: "", disabled: false},
        {model: "input_name", label: "Input Name", default: false, disabled: false},
        {model: "step_category", label: "Step Category", default: false, disabled: false},
      ],
      [
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
        {model: "product_path", label: "Product Path", default: false, disabled: false},
      ],
      [
        {model: "crm_campaign_id", label: "CRM Campaign", default: false, disabled: false},
        {model: "campaign_id", label: "Brand", default: false, disabled: false},
      ],
      [
        {model: "product_id", label: "Product", default: false, disabled: false},
        {model: "cancel_reason", label: "Cancel Reason", default: false, disabled: false},
      ]
    ],
    filtersData: [
      [
        {model: "step_slug", filter: "step_slug", type: "multi-select-with-negation", label: "Step Impressions", default: "",
          options: []},
        {model: "input_name", filter: "input_name", type: "multi-select", label: "Input Name", default: "",
          options: []},
        {model: "billing_cycle", filter: "billing_cycle", type: "arithmetic-comparison-input", label: "Billing Cycle",
          default: "", options: [], comparisonDefault: 'e', comparisonOptions: [
            {id: "e", text: "Equals to"},
            {id: "gt", text: "Greater than"},
            {id: "gte", text: "Greater than or equals to"},
            {id: "lt", text: "Less than"},
            {id: "lte", text: "Less than or equals to"},
          ]},
        {model: "product_path", filter: "product_path", type: "multi-select", label: "Product Path", default: "",
          options: []},
        {model: "crm_campaign_id", filter: "crm_campaign_id", type: "multi-select", label: "CRM campaign", default: "", options: []},
        {model: "campaign_id", filter: "campaign_id", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "product_id", filter: "product_id", type: "products", label: "Product", default: "", options: []},
        {model: "cancel_reason", filter: "cancel_reason", type: "multi-select", label: "Cancel Reason", default: "",
          options: []},
        {model: "step_category", filter: "step_category", type: "multi-select",
          label: "Step Category", default: "",
          options: [
            {id: "Custom", text: "Custom"},
            {id: "Reasons", text: "Reasons"},
            {id: "Order Options", text: "Order Options"},
            {id: "Discount Future Order", text: "Discount Future Order"},
            {id: "Refund Order", text: "Refund Order"},
            {id: "Return Order RMA", text: "Return Order RMA"},
            {id: "Upsell Order", text: "Upsell Order"},
            {id: "Pause Sub", text: "Pause Sub"},
            {id: "Offer Details/Info", text: "Offer Details/Info"},
            {id: "Revise Order", text: "Revise Order"},
            {id: "Need More Help", text: "Need More Help"},
            {id: "Adjust Shipping Frequency", text: "Adjust Shipping Frequency"},
            {id: "Item Selection", text: "Item Selection"},
            {id: "Marketplace", text: "Marketplace"},
            {id: "Life Line Step", text: "Life Line Step"},
            {id: "Sub Path", text: "Sub Path"},
            {id: "Troubleshooter", text: "Troubleshooter"},
            {id: "FAQs", text: "FAQs"},
            {id: "Response Step", text: "Response Step"},
            {id: "Match and Verify", text: "Match and Verify"},
            {id: "Order Status", text: "Order Status"},
            {id: "Tagging Customer for Repeating Action", text: "Tagging Customer for Repeating Action"},
            {id: "Update Info", text: "Update Info"},
            {id: "Survey", text: "Survey"},
          ]},
      ]
    ]
  };
  public summary: any = {}
  public summaryFields: string[] = []
  public summaryLoading: boolean = false
  public state = {}
  public ReportTables = ReportTables
  public subTableFields = ['downsell_step_name', 'downsell']

  public productPathFilterSettings = {
    singleSelection: true,
    allowSearchFilter: true,
    enableCheckAll: false,

  };

  @ViewChild('holdStatusCancellationsTable', {static: false}) holdStaticTable;
  @ViewChild('subscriptionCancellationsTable', {static: false}) subscriptionStaticTable;
  @ViewChild('returnRequestsTable', {static: false}) returnStaticTable;

  protected fieldData = reportsFieldData;
  public productPath = ""

  public orderOptions: any = []
  public orderOptionsFields: string[] = ['product_path', 'downsell', 'cancel_hold_request', 'cancel_subscription_request',
    'handoff', 'cancelled', 'deflected', 'deflected_hold_cancel', 'deflected_subscription_cancel', 'saved', 'save_rate']

  public holdStatusCancellations: any = []
  public holdStatusCancellationsFields: string[] = ['cancel_reason', 'cancel_hold_request',
    'handoff', 'hold_cancel', 'deflected_hold', 'deflected_hold_cancel', 'hold_save', 'save_rate']

  public subscriptionCancellations: any = []
  public subscriptionCancellationsFields: string[] = ['cancel_reason',
    'cancel_subscription_request', 'handoff', 'subscription_cancel', 'deflected_subscription',
    'deflected_subscription_cancel', 'subscription_save', 'save_rate']

  public returnRequests: any = []
  public returnRequestsFields: string[] = ['cancel_reason', 'rma_request',
    'handoff', 'return_quantity', 'deflected_rma_request', 'deflected_rma', 'rma_save', 'save_rate']

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: RetentionReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService, campaignService,
      reportsConfigService, trackingSourceService, userService, accountService, campaignProductService,
      crmCampaignService, storageService, stepService, refundReportService, merchantService);
  }

  getDefaultDateRange() {
    let currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 8,
      0, 0, 0);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1,
      23, 59, 59);

    return {startDate, endDate}
  }

  getSummary() {
    this.summaryLoading = true
    let query = generateReportsQuery({...this.form.value})
    query['filters'] = this.updateFilters()
    this.reportsService.getSummary(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        if (result) {
          if(result.length > 0){
            result = result[0]
          }
          else {
            result = {
              "cancel_hold_request" : 0,
              "cancel_subscription_request" : 0,
              "cancelled" : 0,
              "deflected" : 0,
              "deflected_hold_cancel" : 0,
              "deflected_subscription_cancel" : 0,
              "dropoffs" : 0,
              "extra_for_grouping" : 0,
              "return_quantity" : 0,
              "revisions" : 0,
              "saved" : 0,
              "subscription_adjustment" : 0
            }
          }
          const summaryFields = []
          const summary = {}

          for (let key in result) {
            if (key in this.fieldData) {
              if (result[key]) {
                summaryFields.push(key)
                summary[key] = result[key]
              }
            }
          }

          this.summaryFields = summaryFields
          this.summary = summary
          this.summaryLoading = false
        }
      }, error => {
        this.summaryLoading = false
        this.handleError(error)
      })
  }

  getOrderOptionsBreakdown() {
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    this.reportsService.getOrderOptionsBreakdown(query)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        if (result) {
          this.loading = false
          this.orderOptions = result
        }
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  updateFilters(cancelReason = null) {
    let filters = {}
    if (this.productPath !== ""){
      filters['product_path'] = [this.productPath]
    }

    if (cancelReason) {
      filters['cancel_reason'] = [cancelReason]
    }
    return filters
  }

  getFieldLabel(field: string, table?: string) : string {
    if (table === "return" && field === "cancel_reason")
      return "Return reason"

    let customLabel = this.fieldData[field].custom_label
    if (table &&  customLabel !== undefined)
      return customLabel;

    return this.fieldData[field].label;
  }

  getFieldValue(row, field: string) {
    let value = row[field]
    if ('getter' in this.fieldData[field]) {
      value = this.fieldData[field].getter(row, value);
    }
    return value;
  }

  initializeStepsFilters(date_range) {
    super.initializeStepsFilters(date_range);
    this.populateStaticTables()
  }

  populateStaticTables() {
    this.getSummary()
    // this.getOrderOptionsBreakdown()
    if (this.productPath !== "") {
      this.loading = true
      let query = generateReportsQuery({...this.form.value})
      query['filters'] = this.updateFilters()
      forkJoin([
        this.reportsService.getHoldStatusCancellationsBreakdown(query),
        this.reportsService.getSubscriptionCancellationsBreakdown(query),
        this.reportsService.getReturnRequestsBreakdown(query)
      ])
        .subscribe((data: Array<any>) => {
          if (data && data.length === 3) {
            this.holdStatusCancellations = data[0]
            this.subscriptionCancellations = data[1]
            this.returnRequests = data[2]
            this.loading = false
          }
        }, error => {
          this.loading = false
          this.handleError(error)
        })
    }
  }

  onStaticTableRowSelect(table_type, event) {
    let row = event.selected[0]

    const typeToTableMapping = {}
    typeToTableMapping[ReportTables.Retention.HOLD_CANCELLATION] = this.holdStaticTable
    typeToTableMapping[ReportTables.Retention.SUBSCRIPTION_CANCELLATION] = this.subscriptionStaticTable
    typeToTableMapping[ReportTables.Retention.RMA] = this.returnStaticTable

    let table = typeToTableMapping[table_type]

    if (table) {
      if (!row.sub_table_data) {
        this.populateStaticTableRow(table_type, row, table)
      }
      table.rowDetail.toggleExpandRow(row);
    }
  }

  populateStaticTableRow(table_type, row, table) {
    let cancelReason = row['cancel_reason']
    let method: any;
    this.loading = true
    let query = generateReportsQuery({...this.form.value})
    query['filters'] = this.updateFilters(cancelReason)
    query['is_sub_table'] = true

    switch (table_type) {
      case ReportTables.Retention.SUBSCRIPTION_CANCELLATION:
        method = this.reportsService.getSubscriptionCancellationsBreakdown(query)
        break
      case ReportTables.Retention.RMA:
        method = this.reportsService.getReturnRequestsBreakdown(query)
        break
      default:
        method = this.reportsService.getHoldStatusCancellationsBreakdown(query)
        break
    }
    method.subscribe(data => {
      this.loading = false
      if (data && data.length > 0) {
        row.sub_table_data = data
      } else {
        table.rowDetail.toggleExpandRow(row)
      }
    }, error => {
      this.loading = false
      this.handleError(error)
    })
  }

  setState() {
    let formData = this.form.value;
    formData['intent'] = ReportIntent.General
    formData['startDate'] = moment(formData.date_range.startDate).format()
    formData['endDate'] = moment(formData.date_range.endDate).format()
    formData['date_range']['startDate'] = moment(formData.date_range.startDate).format()
    formData['date_range']['endDate'] = moment(formData.date_range.endDate).format()

    this.state = {
      'load_as_configuration': true,
      'config': {
        'name': "",
        'user': null,
        'is_global': false,
        'configuration': formData
      }
    }
  }

  updateResultsBasedOnProduct(obj, value=""){
    this.productPath = obj.id
    if (value === "all_paths") {
      this.productPath = ""
    }
    this.populateStaticTables()
  }

  downloadReport() {
    this.loading = true;
    this.getReports(false);

    if (this.formValid) {
      let data = generateReportsQuery(this.form.value);
      data['start_date_formatted'] = this.form.value.date_range.startDate.format('MMM Do, YYYY')
      data['end_date_formatted'] = this.form.value.date_range.endDate.format('MMM Do, YYYY')
      if (this.productPath !== ""){
        data['filters']['main_product_path'] =  [this.productPath]
      }
      this.reportsService.downloadFile(data, this);
    }
  }

}
