import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, InvoiceService, StorageService, TransactionService, UserService} from "../_services";
import {Form} from '../_forms';
import {
  ChargeStatusLabels,
  DashboardFilter,
  FeeTypeLabels,
  Invoice,
  InvoiceItem,
  InvoiceStatusLabels,
  Transaction,
  TransactionType,
  TransactionTypeLabels,
  User
} from "../_models";
import {formatISODate, parseISODateString} from "../_helpers";
import {NgxSmartModalService} from 'ngx-smart-modal';
import {takeUntil} from "rxjs/operators";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import * as momentTz from "moment-timezone";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice.component.css'],
  selector: 'invoice-detail'
})
export class InvoiceDetailComponent extends Form implements OnInit, OnChanges {
  @Input('invoiceId') invoiceId: string | number = null;
  @Input('pricingOnly') pricingOnly = false
  invoice: Invoice = null;
  user: User;
  downloadingInvoice: boolean = false
  timezone: string = ''
  filters: DashboardFilter = null

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected invoiceService: InvoiceService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService,
    private userService: UserService,
    private tranService: TransactionService,
    protected storageService: StorageService
  ) {
    super(alertService, router, location);
    this.title = 'Invoice Details';
  }

  ngOnInit() {
    super.ngOnInit();

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );

    if (!this.invoiceId) {
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.invoiceId = params['id'];
          this.updateData();
        },
        error => {
          this.handleError(error);
        }
      );
    }

    let timezone = this.storageService.get('timezone');
    if (!timezone) {
      timezone = momentTz.tz.guess();
    }
    this.timezone = timezone;
  }

  ngOnChanges() {
    this.updateData()
  }

  updateData() {
    if (this.invoice && this.invoice.id === this.invoiceId) {
      return;
    }
    this.invoice = null;

    let result = this.pricingOnly ? this.invoiceService.price(this.invoiceId) : this.invoiceService.get(this.invoiceId);

    result.pipe(takeUntil(this.destroy$)).subscribe(
      (invoice: Invoice) => {
        this.invoice = invoice;
        this.filters = {start_date: invoice.start_date,
          end_date: invoice.end_date, timezone: this.timezone}
      },
      error => {
        this.handleError(error);
      }
    );
  }

  getInvoiceDate() {
    const date = parseISODateString(this.invoice.settle_date);

    return date.toLocaleDateString("en-US", {timeZone: 'UTC'});
  }

  getDateRange() {
    const startDate = parseISODateString(this.invoice.start_date);
    const endDate = parseISODateString(this.invoice.last_date);

    return startDate.toLocaleDateString("en-US", {timeZone: 'UTC'}) + ' - ' +
      endDate.toLocaleDateString("en-US", {timeZone: 'UTC'});
  }

  getSubscriptionDateRange() {
    const startDate = parseISODateString(this.invoice.subscription_start_date);
    const endDate = parseISODateString(this.invoice.subscription_end_date);

    return startDate.toLocaleDateString("en-US", {timeZone: 'UTC'}) + ' - ' +
      endDate.toLocaleDateString("en-US", {timeZone: 'UTC'});
  }

  getLastSubscriptionDateRange() {
    const startDate = parseISODateString(this.invoice.last_subscription_start_date);
    const endDate = parseISODateString(this.invoice.last_subscription_end_date);

    return startDate.toLocaleDateString("en-US", {timeZone: 'UTC'}) + ' - ' +
      endDate.toLocaleDateString("en-US", {timeZone: 'UTC'});
  }

  getStatus() {
    return InvoiceStatusLabels[this.invoice.status];
  }

  getAmount(item, field, prefix: string = '$') {
    let fields = field.split('.');
    let amount = item;
    fields.forEach(key => {
      if (key in amount && amount[key]) {
        amount = amount[key];
      }
    })
    if (typeof amount === "object") {
      amount = 0;
    }
    return prefix + amount;
  }

  hasAmount(item, field) {
    return Math.abs(parseFloat(this.getAmount(item, field, ''))) > 0.000000001
  }

  getItemType(item: InvoiceItem) {
    return FeeTypeLabels[item.type];
  }

  formatTimestampAsDate(timestamp: string) {
    return formatISODate(timestamp);
  }

  getTransactionType(tran: Transaction) {
    return TransactionTypeLabels[tran.type];
  }

  getTransactionStatus(tran: Transaction) {
    return ChargeStatusLabels[tran.status];
  }

  canDeleteTransaction(tran: Transaction) {
    return (tran.type === TransactionType.ExternalPayment) && this.user && this.user.is_staff;
  }

  deleteTransaction(tran: Transaction) {
    if (this.canDeleteTransaction(tran) && window.confirm('Are you sure you want to delete this transaction?')) {
      this.loading = true;

      this.tranService.delete(tran.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          result => {
            this.alertService.success('Deleted transaction.');
            this.invoice = null;
            this.updateData();
            this.loading = false;
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  downloadPDF() {
    let data = document.getElementById('invoiceData');
    this.downloadingInvoice = true;

    html2canvas(data, {
      scrollX: 0,
      scrollY: 0
    }).then(canvas => {
      let margin = 10
      let fileWidth = 208 - 2 * margin;
      let fileHeight = canvas.height * fileWidth / canvas.width;

      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = margin;
      PDF.addImage(FILEURI, 'PNG', margin, position, fileWidth, fileHeight)
      let str = "Solvpath LLC   244 Pettit Ave Bellmore, NY 11710   P. 877-805-4520   E. billing@solvpath.com";
      PDF.setFontSize(8);// optional
      PDF.text(str, 45, PDF.internal.pageSize.height - 5);
      PDF.save(`invoice-${this.invoice.id}.pdf`);
      this.downloadingInvoice = false;
    });
  }
}
