import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {AlertService, DevEnvService, UserService} from '../_services';
import { CrudPagedListComponent } from '../_directives';
import {DevelopmentEnvironment, User} from '../_models';
import { Observable } from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './dev-env.component.html',
})
export class DevEnvComponent extends CrudPagedListComponent implements OnInit {
  devEnvs$: Observable<DevelopmentEnvironment[]> = this.data$;
  public user: User = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected devEnvService: DevEnvService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, devEnvService, alertService);
    this.objectName = 'development environment';
    this.title = 'Development Environments';
  }

  ngOnInit() {
    super.ngOnInit();

    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user
      })
  }

  protected runInitialQuery() {
    this.queryData()
  }

  navigateToEnv(hash: string){
    const url = `https://my.${hash}.ivrpath.com`;
    window.open(url, '_blank');
  }

  copyToClipboard(hash: string) {
    const el = document.createElement('textarea');
    el.value = `https://api.${hash}.ivrpath.com`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.alertService.success('API URL copied to clipboard.', true)
  }

  update(env: DevelopmentEnvironment) {
    this.devEnvService.update(env.id, env)
      .subscribe(_ => {
        this.alertService.success('The update has been started. Please wait for 10-15 minutes for updates to happen')
      }, error => {this.handleError(error)});
  }
}
