import { Component, OnInit } from '@angular/core';
import { CheckoutBankNewComponent } from './checkout-bank-new.component';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AlertService, CheckoutBankService, RegionService } from '../../../_services';
import { FormBuilder } from '@angular/forms';
import { Bank } from '../../../_models';

@Component({
  selector: 'app-checkout-bank-edit',
  templateUrl: './checkout-bank-edit.component.html',
  styleUrls: ['./checkout-bank-edit.component.scss', '../profiles-styles.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CheckoutBankEditComponent extends CheckoutBankNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected bankService: CheckoutBankService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected regionService: RegionService
  ) {
    super(router, location, route, bankService, alertService, formBuilder, regionService);
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this._data$.subscribe((bank: Bank) => {
      if (bank) {
        this.form.patchValue(bank);
        this.isExclude = bank.exclude_types;
        this.bank = bank;
        Object.keys(this.fields_to_permission_types_map).forEach((field_name) => {
          this.selected_permissions[this.fields_to_permission_types_map[field_name]] = this.bank[field_name];
        });
        this.loading = false;
      }
    });
  }
}
