import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutTemplateType, CheckoutTemplate, CheckoutGatewayType, CheckoutGatewayTypeLabels } from '../../_models';
import { Location } from '@angular/common';
import { AlertService, LoaderService } from '../../_services';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CrudPagedListComponent } from '../../_directives';
import { CheckoutTemplatesService } from '../../_services/checkout-templates.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'checkout-templates',
  templateUrl: './checkout-templates.component.html',
  styleUrls: ['./checkout-templates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutTemplatesComponent extends CrudPagedListComponent implements OnInit {
  selectedOption: CheckoutTemplateType = CheckoutTemplateType.OneTimePurchase;
  showBackdrop: boolean = false;
  hoveredIndex: number | null = null;
  CheckoutTemplateType = CheckoutTemplateType;
  CheckoutGatewayType = CheckoutGatewayType;
  CheckoutGatewayTypeLabels = CheckoutGatewayTypeLabels;
  checkoutData = {
    name: '',
    subdomain: '',
    campaign: null,
    account_type: null,
    gateway_type: null,
    template_id: null,
  };
  templates = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected checkoutTemplateService: CheckoutTemplatesService,
    protected alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService
  ) {
    super(router, location, route, checkoutTemplateService, alertService);
    this.objectName = 'checkout';
  }

  ngOnInit(): void {
    this.extractRouteParams();
    this.fetchTemplatesList();
  }

  fetchTemplatesList(): void {
    this.loader.show();
    this.checkoutTemplateService.list({}).subscribe(
      (response) => {
        this.loader.hide();
        this.templates = response.results.map((template: CheckoutTemplate) => {
          return {
            id: template.id,
            name: template.name,
            status: template.is_verified ? CheckoutGatewayType.Verified : CheckoutGatewayType.Unverified,
            type: template.type,
            thumbnail: this.getThumbnailUrl(template.thumbnail),
          };
        });
      },
      (error) => {
        this.handleError(error);
        this.loader.hide();
      }
    );
  }

  setHoveredIndex(index: number): void {
    this.hoveredIndex = index;
  }

  clearHoveredIndex(): void {
    this.hoveredIndex = null;
  }

  previewTemplate(templateId: number): void {
    this.router.navigate([
      'checkouts',
      'template-details',
      this.checkoutData.name,
      'campaign',
      this.checkoutData.campaign,
      'account',
      this.checkoutData.account_type,
      'gateway',
      this.checkoutData.gateway_type,
      'subdomain',
      this.checkoutData.subdomain,
      templateId,
    ]);
  }

  isChecked(option: CheckoutTemplateType): boolean {
    return this.selectedOption === option;
  }

  selectTemplate(templateId: number): void {
    this.router.navigate([
      'checkouts',
      'template-details',
      this.checkoutData.name,
      'campaign',
      this.checkoutData.campaign,
      'account',
      this.checkoutData.account_type,
      'gateway',
      this.checkoutData.gateway_type,
      'subdomain',
      this.checkoutData.subdomain,
      templateId,
    ]);
  }

  navigateToMainPage(): void {
    this.router.navigate(['/checkouts']);
  }

  get filteredTemplates(): CheckoutTemplate[] {
    return this.templates.filter(
      ({ type, status }) => type === Number(this.selectedOption) && status === this.checkoutData.gateway_type
    );
  }

  getThumbnailUrl(thumbnail: string | null): string {
    const defaultImage = '/assets/stepAssets/template-thumbnail.svg';
    const timestamp = new Date().getTime(); // Cache buster using current timestamp
    const imageUrl = thumbnail || defaultImage;

    return `${imageUrl}?v=${timestamp}`;
  }

  private extractRouteParams(): void {
    this.route.params.subscribe((params) => {
      this.checkoutData.name = params.name;
      this.checkoutData.subdomain = params.subdomain;
      this.checkoutData.campaign = Number(params.campaign);
      this.checkoutData.account_type = Number(params.account_type);
      this.checkoutData.gateway_type = Number(params.gateway_type);
    });
  }
}
