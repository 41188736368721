import { Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import {Notification} from '../_models'
import {tap} from 'rxjs/operators';

@Injectable()
export class NotificationService extends PaginationService {

  private _notifications = new BehaviorSubject<Notification[]>(null);
  protected route = 'notifications';

  constructor(protected http: HttpClient) { 
    super(http, 'notifications');
  }

  list(queryParams: any): Observable<any> {
    let params = new HttpParams({fromObject: queryParams});

    return this.http.get<any>(this.route + '/', {params: params}).pipe(
      tap(
        data => {
          if(!this._notifications.value){
            this._notifications.next(data.results)
          }
          else {
            let currentNotifications = this._notifications.value;
            let newNotifications = data.results;
            let updatedNotifications = [...currentNotifications,...newNotifications]
            this._notifications.next(updatedNotifications)
          }
        }) 
    )
  }


  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }
  
  setNotification(notification: Notification) {
    let updatedNotifications:Notification[];
    let currentNotifications = this._notifications.value;
    const notificationIndex = currentNotifications.findIndex(notif => notif.id == notification.id)
    if(notificationIndex>=0){
      currentNotifications[notificationIndex] = notification
      updatedNotifications = currentNotifications;
    }
    else {
      updatedNotifications = [notification,...currentNotifications];
    }
    return this._notifications.next(updatedNotifications)
  }

  setInitialNotifications(notifications: Notification[]){
    return this._notifications.next(notifications)
  }

  updateNotification(notification:Notification){
    notification.is_read = true
    let body = {is_read: notification.is_read}
    return this.http.patch(this.route + '/' + notification.id.toString() + '/', body)
  }

  clearNotifications(){
    return this._notifications.next(null)
  }
}
