import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignCategoryService, CampaignService, UserService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {Campaign, CampaignStatus, Category, Image, Pager, SessionType, SessionTypeLabels, User} from "../_models";
import {config} from '../../config/config';
import {takeUntil} from "rxjs/operators";
import {NgxSmartModalService} from "ngx-smart-modal";
import {CampaignDomainComponent} from '../campaign-domain';
import * as moment from 'moment-timezone';

interface ImageField {
  name: string;
  label: string;
  help: string;
}

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.css']
})
export class CampaignNewComponent extends CrudSaveComponent implements OnInit {
  categories: Category[] = [];
  isNewCampaign = true;
  domain: string = '';
  campaign: Campaign;
  imageFields: ImageField[] = [];
  images: {} = {};
  selectedImageField: ImageField = null;
  timezones: string[] = [];
  isHybrid = false;
  sessionTypes = [
    {value: SessionType.Support, label: SessionTypeLabels[SessionType.Support]},
    {value: SessionType.Marketplace, label: SessionTypeLabels[SessionType.Marketplace]},
    {value: SessionType.PaymentUpdate, label: SessionTypeLabels[SessionType.PaymentUpdate]},
  ]

  @ViewChild(CampaignDomainComponent, { static: false }) domainsComponent: CampaignDomainComponent;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    protected categoryService: CampaignCategoryService,
    protected userService: UserService,
    protected modalService: NgxSmartModalService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService);
    this.isNew = true;
    this.objectName = 'Brand';
    this.showSuccessMessage = false;
    this.title = 'New Brand';
    this.imageFields = [
      {name: 'logo', label: 'Logo', help: 'Select a logo image to use for desktop and tablet browsers.'},
      {name: 'mobile_logo', label: 'Mobile Logo', help: 'Select a logo image to use for mobile devices.'},
      {name: 'favicon', label: 'Favicon', help: 'Select a favicon image.'},
      {name: 'processing_gif', label: 'Processing GIF', help: 'Select a processing GIF to show.'},
      {name: 'chatbot_icon', label: 'Chatbot Icon', help: 'Select an icon image to use in the chatbot.'},
    ];
    this.timezones = moment.tz.names();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      subdomain: (this.isHybrid && this.isNew) ? [null] : [null, Validators.required],
      timezone: [moment.tz.guess(), Validators.required],
      display_name: [null],
      status: [CampaignStatus.Active],
      categories: [[]],
      subdomain_type: [SessionType.Support],
    });

    this.campaignService.showMenu(null, 'edit', true, this.isHybrid);
    super.ngOnInit();

    this.categoryService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.categories = data.results;
        },
        error => {
          this.handleError(error);
        }
      );

    this.userService.getCurrent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.domain = user.account.domain;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  checkSubdomain(event) {
    if (event.target.value) {
      let subdomain = event.target.value.trim().toLowerCase();

      if (subdomain.length && (!this.campaign || (subdomain != this.campaign.subdomain))) {
        this.campaignService.checkSubdomain(subdomain)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {

            },
            error => {
              this.handleSubmitError(error);
            }
          );
      }
    }
  }

  getImage(field: ImageField) {
    if (field.name in this.images) {
      return this.images[field.name];
    }

    return null;
  }

  removeImage(field: ImageField) {
    if (field.name in this.images) {
      delete this.images[field.name];
    }
  }

  onImageSelectStart(field: ImageField) {
    this.selectedImageField = field;
    this.modalService.getModal('imageDialog').open();
  }

  onImageSelectDone(image: Image) {
    if (this.selectedImageField) {
      this.images[this.selectedImageField.name] = image;
      this.modalService.getModal('imageDialog').close();
    }
  }

  isCampaignEnabled() {
    return this.campaign.status === CampaignStatus.Active
  }

  isCampaignActive() {
    return this.campaign && (this.campaign.status !== CampaignStatus.Inactive)
  }

  enableCampaign(event) {
    if (this.campaign.status !== CampaignStatus.Inactive) {
      this.campaign.status = event.target.checked ? CampaignStatus.Active : CampaignStatus.Disabled
    }
  }

  getLinks() {
    this.modalService.getModal('linksDialog').open();
  }

  showDocumentationPopup() {
    this.modalService.getModal('iframeDocumentationDialog').open();
  }

  protected getFormData() {
    //add our selected images to the form data
    let imageData = {};
    let customDomains = [];

    this.imageFields.forEach((field: ImageField) => {
      if (field.name in this.images) {
        imageData[field.name] = this.images[field.name].id;
      } else {
        imageData[field.name] = null;
      }
    });

    if (this.campaign) {
      this.form.value.status = this.campaign.status;
      customDomains = this.domainsComponent.getFormData();
    }

    return Object.assign({is_hybrid: this.isHybrid, custom_domains: customDomains}, this.form.value, imageData);
  }

  protected onSaveComplete(data) {
    this.campaignService.getNextMenuRoute().pipe(takeUntil(this.destroy$)).subscribe((route: string) => {
      this.navigate(['/campaign', route, this.id, {isNewCampaign: true}], {replaceUrl: true});
    });
  }

}
