import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from "./crud.service";

@Injectable()
export class FunnelActionService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http, 'funnel_input');
  }

  setInputId(id: string | number) {
    this.route = 'funnel_input/' + id.toString() + '/actions';
  }
}
