import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService, PaymentSourceService } from '../_services';
import { CrudSaveComponent } from '../_directives';
import {Address, PaymentSource, PaymentSourceType} from '../_models';
import { CreditCardValidator } from 'angular-cc-library';
import {AddressFieldsComponent} from "../address";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './credit-card-edit.component.html'
})
export class CreditCardEditComponent extends CrudSaveComponent implements OnInit, AfterViewInit {
  providerName: string;
  last4: string;
  address: Address;
  @ViewChild(AddressFieldsComponent, { static: false }) addressFields: AddressFieldsComponent;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected paymentService: PaymentSourceService,
    protected alertService: AlertService
  ) {
    super(router, location, route, paymentService, alertService);
    this.isNew = false;
    this.objectName = 'Credit Card';
    this.title = 'Change your card information';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      resourcetype: [PaymentSourceType.Card, Validators.required],
      id: [null, Validators.required],
      cardExpDate: [null, <any>CreditCardValidator.validateExpDate],
      name: [null, Validators.required],
      exp_month: [null],
      exp_year: [null],
    });

    super.ngOnInit();

    this.data$.subscribe((card: PaymentSource) => {
      if (card) {
        let expDate = '';

        if (card.exp_month < 10) {
          expDate = '0';
        }

        expDate += card.exp_month.toString() + ' / ' + card.exp_year.toString().slice(-2);

        this.providerName = card.provider_name;
        this.last4 = card.last4;
        this.form.setValue({
          id: card.id,
          cardExpDate: expDate,
          name: card.name,
          exp_month: card.exp_month,
          exp_year: card.exp_year
        });
        this.address = {
          id: null,
          address1: card.address1,
          address2: card.address2,
          city: card.city,
          state: card.state,
          zip: card.zip,
          country: card.country,
        }
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.form.addControl('address', this.addressFields.form), 0);
  }

  protected getFormData() {
    let expDateStr = this.form.value.cardExpDate.replace('/', '/01/'); //convert mm/yy to mm/dd/yy
    let expDate = new Date(Date.parse(expDateStr));

    this.form.value.exp_month = expDate.getMonth() + 1;
    this.form.value.exp_year = expDate.getFullYear();

    return Object.assign({}, this.form.value, this.addressFields.form.value);
  }

}
