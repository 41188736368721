import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReportsAbstractService} from './reports-abstract.service';

@Injectable()
export class PaymentUpdaterReportService extends ReportsAbstractService {
  public reportRoute: string = 'payment_updater_report'

  constructor(protected http: HttpClient) {
    super(http);
    this.route = this.reportRoute;
  }
}
