import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, SessionService, SmsLogsService} from "../_services";
import {Observable} from "rxjs";
import {CallLogs, Phone, PhoneResourceType, SmsLogs} from '../_models';
import {LogsAbstractComponent} from "./logs-abstract.component";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  selector: 'sms-logs',
  templateUrl: './logs-template.component.html',
  styleUrls: ['./logs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SmsLogsComponent extends LogsAbstractComponent {
  logs: SmsLogs[];
  public fields = {
    status: "Status",
    body: "Body",
    direction: "Direction",
    date_sent: "Date Sent",
    from_: "From",
    to: "To",
    error_code: "Error Code",
    error_message: "Error Message",
  }

  public fieldsList = Object.keys(this.fields);
  public logName = 'Sms';
  public detailsFields = {'sid': 'SID', ...this.fields};
  public detailsFieldsList = Object.keys(this.detailsFields);

  @Input('phone') phone: Phone = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected smsLogsService: SmsLogsService,
    protected alertService: AlertService,
    protected modalService: NgxSmartModalService,
    protected sessionService: SessionService
  ) {
    super(router, location, route, smsLogsService, alertService, modalService, sessionService);
  }

  getDetailValue(fieldName): string {
    let value = super.getDetailValue(fieldName);

    if (fieldName === 'sid') {
      if (this.phone) {
        if (this.phone.resourcetype === PhoneResourceType.Twilio) {
          value = `https://www.twilio.com/console/sms/${value}`;
        }
      }
    }

    return value
  }

  protected queryData() {
    if (this.id) {
      super.queryData();
    }
  }

  protected generateFilters() {
    super.generateFilters();
    this.id = this.phone ? this.phone.id : null;
  }
}
