import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  StepEmulatorDevices,
  StepLayout,
  StepButtons,
  StepMedia,
  OfferTypes,
  OfferTypeLabels,
  Breadcrumb,
  StepEmulationTypes,
  CustomStepCategoryEnum,
  StepCategoryLabels,
  StepButtonControlTypes,
  Campaign,
  defaultThemeColor,
  StepButtonShapes,
  transparent,
  StepTextual,
  StepButtonDetails,
  StepTextualBanner,
  CampaignProduct,
  Funnel,
  FunnelStep,
  Pager,
  Style,
  StepTemplateFolder,
  StepResponseMedia,
  User,
  getCampaignProductImageUrl,
} from '../../_models';
import {
  AlertService,
  CampaignService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  StepEmulatorService,
  StyleService,
  StepTemplateService,
  SideNavService,
  ImageService,
  UserService,
  StorageService,
} from '../../_services';
import { Subscription } from 'rxjs';
import {
  addDataAttributes,
  normalizeObject,
  OfferData,
  stepLayout,
  initializeForm,
  patchInputs,
  externalButtonUsage,
} from '../step-util';
import { config } from '../../../config/config';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { getTruncatedProductName } from '../step-util';
import { StepFormComponent } from './step-form/step-form.component';
import { StepEmulatorComponent } from './step-emulator/step-emulator.component';
import { CrudSaveComponent } from '../../_directives';

@Component({
  selector: 'app-step-builder',
  templateUrl: './step-builder.component.html',
  styleUrls: ['./step-builder.component.scss'],
})
export class StepBuilderComponent extends CrudSaveComponent implements OnInit, OnDestroy, AfterViewInit {
  activeDevice = StepEmulatorDevices.Mobile;
  stepLayout: StepLayout[] = [];
  stepTextual: StepTextual;
  stepMedia: StepMedia;
  stepResponseMedia: StepResponseMedia;
  stepButtons: StepButtons;
  private subscriptions: Subscription[] = [];
  stepTitle: string = '';
  stepType: CustomStepCategoryEnum;
  stepId: string = '';
  funnelId: string | number = '';
  editTemplate: boolean;
  templateId: string = '';
  funnel: Funnel;
  offerType: OfferTypes;
  offerName: string;
  category: string = '';
  isStepFormActive: boolean = true;
  isDropdownActive: boolean = true;
  showResponseButton: boolean = true;
  isResponseScreen: boolean = false;
  isStepPreview: boolean = true;
  isStepSaving: boolean = false;
  isStepDraftSaving: boolean = false;
  isPreviewLoading: boolean = false;
  isGlobalTemplate: boolean = false;
  isNew: boolean = true;
  breadcrumbs: Breadcrumb[] = [];
  campaigns: Campaign[] = [];
  selectedCampaign: Campaign;
  selectedCampaignId: string | number = null;
  selectedProduct: CampaignProduct = {} as CampaignProduct;
  productPlaceholderUrl: string = '';
  campaignsLoading: boolean = true;
  themes: Style[] = [];
  theme: string = defaultThemeColor;
  useExternalButtons = false;
  user: User;
  stepEmulationTypes = StepEmulationTypes;
  enableFormErrors = false;
  folderDetails: StepTemplateFolder;
  isSurveyStep = false;
  isCustomerPortalStep = false;
  showSaveMenu: boolean = false;
  @ViewChild(StepEmulatorComponent, { static: false }) stepEmulator: StepEmulatorComponent;
  @ViewChild(StepFormComponent, { static: false }) stepFormComponent: StepFormComponent;

  constructor(
    protected route: ActivatedRoute,
    protected location: Location,
    private stepService: StepTemplateService,
    private stepEmulatorService: StepEmulatorService,
    private funnelService: FunnelService,
    private funnelStepService: FunnelStepService,
    private sideNav: SideNavService,
    protected alertService: AlertService,
    protected router: Router,
    private loader: LoaderService,
    private campaignService: CampaignService,
    protected styleService: StyleService,
    protected userService: UserService,
    protected formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    protected imageService: ImageService,
    private sanitizer: DomSanitizer,
    protected storageService: StorageService
  ) {
    super(router, location, route, stepService, alertService);

    if (window.innerWidth < 1530) {
      this.sideNav.setSideNavExpanded(false);
    }

    this.route.params.subscribe((params) => {
      this.stepType = parseInt(params['step_type']);
      this.offerType = parseInt(params['offer_type']);
      this.offerName = OfferTypeLabels[this.offerType];
      this.stepId = params['step_id'];
      this.funnelId = params['offer_id'];
      this.editTemplate = params['edit'] === 'true';
      this.templateId = params['template_id'];
      this.isNew = !this.stepId;
      this.stepLayout = stepLayout[this.stepType] || [];
      this.stepTitle = this.getTitleForStepType(this.stepType, this.isNew);
    });
  }

  ngOnInit() {
    this.loader.show('white', 'Gearing up the builder', 998);
    this.form = initializeForm(this.formBuilder, this.stepType, this.offerType, this.editTemplate);
    this.useExternalButtons = externalButtonUsage(this.stepType);
    this.getCampaigns();
    this.generateBreadcrumbs();
    super.ngOnInit();

    this.userService.getCurrent().subscribe((user: User) => {
      this.user = user;
    });

    const textualSubscription = this.stepEmulatorService.stepTextual$.subscribe((data) => {
      this.stepTextual = data;
    });
    this.addSubscription(textualSubscription);

    const stepMediaSubscription = this.stepEmulatorService.stepMedia$.subscribe((data) => {
      this.stepMedia = data;
    });
    this.addSubscription(stepMediaSubscription);

    const responseMediaSubscription = this.stepEmulatorService.stepResponseMedia$.subscribe((data) => {
      this.stepResponseMedia = data;
    });
    this.addSubscription(responseMediaSubscription);

    const buttonsSubscription = this.stepEmulatorService.stepButtons$.subscribe((data) => {
      this.stepButtons = data;
    });
    this.addSubscription(buttonsSubscription);

    this.isSurveyStep = this.offerType === OfferTypes.Survey;
    this.isCustomerPortalStep = this.offerType === OfferTypes.CustomerPortal;

    if (this.templateId) {
      this.fetchTemplate();
    }

    if (this.stepId && this.funnelId) {
      this.isNew = false;
      this.funnelStepService.setFunnelId(this.funnelId);
      this.fetchFunnel();
      this.fetchOfferStep();
    } else if (this.funnelId) {
      this.fetchFunnel();
      this.funnelStepService.setFunnelId(this.funnelId);
    } else if (this.stepId) {
      this.isNew = false;
      this.fetchStep();
    }
  }

  ngAfterViewInit() {
    if (this.isNew && !this.templateId) {
      setTimeout(() => {
        this.loader.hide();
        this.stepEmulatorService.hideAllElements();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 3000);
    } else {
      this.loader.loader$.subscribe((state) => {
        if (!state) {
          this.stepEmulatorService.hideAllElements();
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    }
  }

  getCampaigns() {
    this.campaignService.list({}).subscribe(
      (data: Pager) => {
        this.campaigns = data.results;

        if (this.storageService.get('selectedStepCampaignId')) {
          this.selectedCampaignId = this.storageService.get('selectedStepCampaignId');
        } else {
          this.selectedCampaignId = this.campaigns[0].id;
          this.storageService.set('selectedStepCampaignId', this.selectedCampaignId);
        }

        this.getStyles();
        this.campaignsLoading = false;

        if (this.storageService.get('selectedStepProduct') && this.storageService.get('selectedStepProduct').id) {
          setTimeout(() => {
            this.onProductSelected(this.storageService.get('selectedStepProduct'));
          }, 1000);
        }
      },
      (error) => {
        this.campaignsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ['steps', 'survey'],
        },
        ...(this.isNew
          ? [
              {
                label: 'Choose a Template',
                url: ['steps', 'survey', 'category', this.offerType, 'template', this.stepType],
              },
            ]
          : []),
        {
          label: this.stepTitle,
        },
      ];
    } else if (this.offerType === OfferTypes.CustomerPortal) {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
          url: ['steps', this.offerName],
        },
        ...(!this.isNew
          ? [
              {
                label: '',
              },
            ]
          : []),
        ...(this.isNew
          ? [
              {
                label: 'Choose a Template',
                url: ['steps', this.offerName, 'category', this.offerType, 'template', this.stepType],
              },
            ]
          : []),
        {
          label: this.stepTitle,
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
          url: ['steps', this.offerName],
        },
        {
          label: '',
          url: ['steps', this.offerName, this.offerType, 'details', this.funnelId],
        },
        ...(this.isNew
          ? [
              {
                label: 'Choose a Template',
                url: ['steps', this.offerName, this.funnelId, 'category', this.offerType, 'template', this.stepType],
              },
            ]
          : []),
        {
          label: this.stepTitle,
        },
      ];
    }
  }

  getStyles() {
    this.styleService.list({ page: 1, page_size: config.maxPageSize }).subscribe((data: Pager) => {
      this.themes = data.results;
      this.updateBrandTheme();
    }),
      (err) => this.alertService.error(err.message);
  }

  scroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  fetchFunnel() {
    this.funnelService.get(this.funnelId).subscribe(
      (response: Funnel) => {
        this.funnel = response;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  fetchTemplate() {
    this.loader.show('white', 'Gearing up the builder', 998);

    this.stepService.get(this.templateId).subscribe(
      (response: FunnelStep) => {
        if (response) {
          const data = !this.editTemplate && this.templateId ? normalizeObject(response) : response;
          patchInputs(
            this.form,
            this.formBuilder,
            data,
            this.useExternalButtons,
            this.stepMedia,
            this.stepResponseMedia,
            this.sanitizer,
            this.stepEmulatorService
          );
          this.isGlobalTemplate = data.is_global;
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  fetchOfferStep() {
    this.loader.show('white', 'Gearing up the builder', 998);

    // Offer Step Fetching
    this.funnelStepService.get(this.stepId).subscribe(
      (data: FunnelStep) => {
        if (data) {
          patchInputs(
            this.form,
            this.formBuilder,
            data,
            this.useExternalButtons,
            this.stepMedia,
            this.stepResponseMedia,
            this.sanitizer,
            this.stepEmulatorService
          );
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  fetchStep() {
    this.loader.show('white', 'Gearing up the builder', 998);

    //Survey Step Fetching
    this.stepService.get(this.stepId).subscribe(
      (data: FunnelStep) => {
        const step_data = {
          ...data,
          offer_type: this.offerType,
        };

        if (data) {
          patchInputs(
            this.form,
            this.formBuilder,
            step_data,
            this.useExternalButtons,
            this.stepMedia,
            this.stepResponseMedia,
            this.sanitizer,
            this.stepEmulatorService
          );
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  updateBrandTheme() {
    this.selectedCampaign = null;
    
    if (!this.selectedCampaignId) {
      this.theme = defaultThemeColor;
    } else {
      let styleId, theme;
      this.storageService.set('selectedStepCampaignId', this.selectedCampaignId);
      this.campaignService.get(this.selectedCampaignId).subscribe(
        (campaign: Campaign) => {
          if (campaign) {
            this.selectedCampaign = campaign;
            if (campaign.styles && campaign.styles.length) {
              styleId = campaign.styles[0];
            }
          }

          if (styleId && this.themes.length > 0) {
            theme = this.themes.find((theme) => theme.id === styleId).variables.theme_color;
          }

          if (theme) {
            this.theme = theme;
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    }
  }

  updateProductImageUrl() {
    this.productPlaceholderUrl = this.selectedProduct.id ? getCampaignProductImageUrl(this.selectedProduct) : null;
  }

  loadPreview() {
    if (this.form.invalid) {
      this.enableFormErrors = true;
      setTimeout(() => this.scrollToError(), 50);
      this.showSaveMenu = false;
      return;
    }

    this.enableFormErrors = false;
    this.isPreviewLoading = true;
    const body = this.form.value;
    this.manipulateHTMLContent(body);

    this.funnelService
      .previewStep(this.selectedCampaignId || this.campaigns[0].id, body, this.selectedProduct.id)
      .subscribe(
        (data) => {
          window.open(data.toString(), '_blank');
          this.isPreviewLoading = false;
          this.showSaveMenu = false;
        },
        (error) => {
          this.alertService.error(error);
          this.isPreviewLoading = false;
          this.showSaveMenu = false;
        }
      );
  }

  onSubmit(draft = false, publish = false) {
    if (this.editTemplate) {
      this.saveTemplate();
    } else {
      if (this.form.invalid) {
        this.enableFormErrors = true;
        setTimeout(() => this.scrollToError(), 50);
        return;
      }

      this.enableFormErrors = false;
      switch (this.stepType) {
        case CustomStepCategoryEnum.CancelSubSurvey:
        case CustomStepCategoryEnum.CancelOrderSurvey:
        case CustomStepCategoryEnum.CancelTrialSurvey:
        case CustomStepCategoryEnum.Survey:
        case CustomStepCategoryEnum.ReturnSurvey:
          this.saveSurveyStep(draft);
          break;
        default:
          this.saveOfferStep(draft, publish);
          break;
      }
    }
  }

  saveSurveyStep(draft = false) {
    if (draft) {
      this.isStepDraftSaving = true;
    } else {
      this.isStepSaving = true;
    }

    let body;
    body = this.form.value;

    this.manipulateHTMLContent(body);

    const handleSuccess = () => {
      this.alertService.success('Saved Successfully.', true);
      this.canDeactivateService.setFormDirty(false);

      setTimeout(() => {
        this.navigate(['steps', 'survey']);
      }, 2000);
    };

    const handleError = (err: any) => {
      if (draft) {
        this.isStepDraftSaving = false;
      } else {
        this.isStepSaving = false;
      }

      if (err.status === 400) {
        this.alertService.error(err.error.name);
      } else {
        this.alertService.error(err.message);
      }
    };

    if (this.isNew) {
      /* Create Survey Step */
      this.stepService.create(body).subscribe((step: FunnelStep) => {
        // Save the survey step id in local storage
        this.storageService.set(OfferTypeLabels[this.offerType], step.id);
        handleSuccess();
      }, handleError);
    } else {
      /* Save Survey Step */
      this.stepService.update(this.stepId, body).subscribe(handleSuccess, handleError);
    }
  }

  saveOfferStep(draft = false, publish = false) {
    if (draft) {
      this.isStepDraftSaving = true;
    } else {
      this.isStepSaving = true;
    }

    let body;
    body = this.form.value;
    body = {
      ...body,
      response_data: {
        ...body.response_data,
        name: `${body.name} response`,
      },
    };

    this.manipulateHTMLContent(body);

    const handleSuccess = () => {
      if (publish) {
        this.funnelService.publish(this.funnelId).subscribe(() => {
          this.alertService.success('Saved & Published Successfully.', true);
        }, handleError);
      } else {
        this.alertService.success('Saved Successfully.', true);
      }
      this.canDeactivateService.setFormDirty(false);
      if (this.isCustomerPortalStep) {
        setTimeout(() => {
          this.navigate(['steps', this.offerName]);
        }, 2000);
      } else {
        setTimeout(() => {
          this.navigate(['steps', this.offerName, this.offerType, 'details', this.funnelId]);
        }, 2000);
      }
    };

    const handleError = (err: any) => {
      if (draft) {
        this.isStepDraftSaving = false;
      } else {
        this.isStepSaving = false;
      }

      if (err.status === 400) {
        this.alertService.error(err.error.name || err.error[0]);
      } else {
        this.alertService.error(err.message);
      }
    };

    if (this.isNew) {
      if (this.isCustomerPortalStep) {
        /* Creating a new Offer/Funnel */
        const data = {
          ...OfferData,
          name: body.name,
          offer_type: this.offerType,
          offer_intent: body.offer_intent,
        };

        this.funnelService.create(data).subscribe(
          (data: Funnel) => {
            this.funnelId = data.id;
            this.funnel = data;
            this.funnelStepService.setFunnelId(this.funnelId);
            body.name = data.name;

            /* Create New Offer Step */
            this.funnelStepService.create(body).subscribe(handleSuccess, handleError);
          },
          (err: any) => {
            if (err.status === 400) {
              this.alertService.error(err.error.name);
            } else {
              this.alertService.error(err.message);
            }
          }
        );
      } else {
        if (this.funnel.offer_steps && this.funnel.offer_steps.length > 0) {
          const lastStepIndex = this.funnel.offer_steps.length - 1;
          if (
            this.funnel.offer_steps[lastStepIndex].inputs &&
            this.funnel.offer_steps[lastStepIndex].inputs.length > 0
          ) {
            this.funnel.offer_steps[lastStepIndex].inputs.forEach((input) => {
              if (!input.has_response_step) {
                body = {
                  ...body,
                  parent_input: input.id,
                };
              }
            });
          }
        }

        /* Create New Offer Step */
        this.funnelStepService.create(body).subscribe(handleSuccess, handleError);
      }
    } else {
      if (this.isCustomerPortalStep) {
        const data = {
          resourcetype: this.funnel.resourcetype,
          name: body.name,
        };
        /* Update Offer Name */
        this.funnelService.patch(this.funnelId, data).subscribe(() => {
          this.funnelStepService.update(this.stepId, body).subscribe(handleSuccess, handleError);
        }, handleError);
      } else {
        /* Update Offer Step */
        this.funnelStepService.update(this.stepId, body).subscribe(handleSuccess, handleError);
      }
    }
  }

  toggleSaveMenu() {
    this.showSaveMenu = !this.showSaveMenu;
  }

  closeOutside() {
    this.showSaveMenu = false;
  }

  saveTemplate() {
    if (this.form.invalid) {
      this.enableFormErrors = true;
      setTimeout(() => this.scrollToError(), 50);
      return;
    }

    this.isStepSaving = true;
    this.enableFormErrors = false;

    let body, file;
    body = {
      ...this.form.value,
      is_template: true,
      is_global: this.isGlobalTemplate,
    };
    if (this.funnelId) {
      body = {
        ...body,
        response_data: {
          ...body.response_data,
          name: `${body.name} response`,
        },
      };
    }

    this.manipulateHTMLContent(body);
    this.captureEmulatorScreenshot().then((file) => {
      const handleResponse = (response: FunnelStep) => {
        this.alertService.success('Saved Successfully.', true);
        if (file) {
          const renamedFile = new File([file], `${response.id}`, {
            type: file.type,
          });
          this.imageService
            .uploadFile(response.pre_signed_url, renamedFile)
            .catch((error) => console.log(error))
            .finally(() => {
              this.canDeactivateService.setFormDirty(false);
              this.navigateFromTemplate();
            });
        }
      };

      const handleError = (err: any) => {
        this.isStepSaving = false;

        if (err.status === 400) {
          this.alertService.error(err.error.name);
        } else {
          this.alertService.error(err.message);
        }
      };

      if (this.editTemplate && !this.templateId) {
        // Create Template
        this.stepService.create(body).subscribe(handleResponse, handleError);
      } else {
        // Update Template
        this.stepService.update(this.templateId, body).subscribe(handleResponse, handleError);
      }
    });
  }

  navigateFromTemplate() {
    const baseUrl = this.funnelId
      ? ['steps', this.offerName, this.funnelId, 'category', this.offerType, 'template', this.stepType]
      : ['steps', this.offerName, 'category', this.offerType, 'template', this.stepType];

    if (this.isGlobalTemplate) {
      baseUrl.push('global');
    }

    this.router.navigate(baseUrl);
  }

  async captureEmulatorScreenshot(): Promise<File | null> {
    this.changePreview(StepEmulationTypes.Preview);
    await new Promise((resolve) => setTimeout(resolve, 200));

    if (this.stepEmulator) {
      try {
        const file = await this.stepEmulator.takeScreenshot();
        return file;
      } catch (error) {
        console.error('Error capturing screenshot:', error);
        return null;
      }
    }

    return null;
  }

  manipulateHTMLContent(body: FunnelStep) {
    if (this.useExternalButtons) {
      body.button_styles.confirm = this.addButtonStyles(this.stepButtons.confirmButton);
      body.button_styles.back = this.addButtonStyles(this.stepButtons.backButton);
    } else {
      body.inputs[0].label = addDataAttributes(body.inputs[0].label);
      body.inputs[0].button_styles = this.addButtonStyles(this.stepButtons.confirmButton);

      body.inputs[1].label = addDataAttributes(body.inputs[1].label);
      body.inputs[1].button_styles = this.addButtonStyles(this.stepButtons.backButton);
    }

    if (body.banner) {
      body.banner = this.addBannerStyle(this.stepTextual.banner);
    }

    body.banner = addDataAttributes(body.banner);
    body.label = addDataAttributes(body.label);
    body.content = addDataAttributes(body.content);

    if (body.response_data) {
      body.response_data = {
        ...body.response_data,
        label: addDataAttributes(body.response_data.label),
        content: addDataAttributes(body.response_data.content),
      };
    }
  }

  addButtonStyles(details: StepButtonDetails) {
    let styleString;
    const outlineShapes = [StepButtonShapes.FlatOutline, StepButtonShapes.ModernOutline, StepButtonShapes.PillOutline];
    const isOutlineShape = outlineShapes.includes(+details.style);
    const isTextOnly = +details.style === StepButtonShapes.TextOnly;
    const themeString = 'var(--theme_color)',
      color =
        +details.type === StepButtonControlTypes.Theme && (isOutlineShape || isTextOnly)
          ? themeString
          : details.fontColor,
      background = +details.type === StepButtonControlTypes.Theme ? themeString : details.backgroundColor,
      borderColor = +details.type === StepButtonControlTypes.Theme ? themeString : details.borderColor,
      borderRadius = details.borderRadius;

    switch (details.style) {
      case StepButtonShapes.Flat:
      case StepButtonShapes.Modern:
      case StepButtonShapes.Pill:
        styleString =
          `color: ${color}; background-color: ${background}; border-color: ${borderColor}; border-radius: ${borderRadius}; min-height: 72px; padding: 16px 10px;`.trim();
        break;
      case StepButtonShapes.FlatOutline:
      case StepButtonShapes.ModernOutline:
      case StepButtonShapes.PillOutline:
        styleString =
          `color: ${color}; background-color: ${transparent}; border-color: ${borderColor}; border-radius: ${borderRadius}; min-height: 72px; padding: 16px 10px;`.trim();
        break;
      case StepButtonShapes.TextOnly:
        styleString = `color: ${color}; background-color: ${transparent}; border-color: ${transparent};`.trim();
        break;
    }

    if (this.useExternalButtons) {
      return {
        label: addDataAttributes(details.content),
        style: styleString,
        style_type: details.style,
        control_type: details.type,
      };
    }

    return {
      style: styleString,
      style_type: details.style,
      control_type: details.type,
    };
  }

  addBannerStyle(banner: StepTextualBanner) {
    if (!banner.value) {
      return null;
    }

    const backgroundColorStyle = `background-color: ${banner.background};`;
    const styleRegex = /style=['"]([^'"]*)['"]/i;
    const match = banner.value.match(styleRegex);

    if (match && match[1]) {
      const existingStyles = match[1];
      const updatedStyles = existingStyles.includes('background-color')
        ? existingStyles.replace(/background-color:\s*[^;]+;/i, backgroundColorStyle)
        : `${existingStyles} ${backgroundColorStyle}`;

      return banner.value.replace(styleRegex, `style="${updatedStyles.trim()}"`);
    }

    const tagRegex = /^<\w+/;
    const tagMatch = banner.value.match(tagRegex);

    if (tagMatch) {
      const openingTag = tagMatch[0];
      return banner.value.replace(openingTag, `${openingTag} style="${backgroundColorStyle}"`);
    }

    return banner.value;
  }

  openMobile() {
    this.activeDevice = StepEmulatorDevices.Mobile;
    this.isStepFormActive = true;
    this.isDropdownActive = true;
  }

  openTablet() {
    this.activeDevice = StepEmulatorDevices.Tablet;
    this.isStepFormActive = true;
    this.isDropdownActive = true;
  }

  openDesktop() {
    this.activeDevice = StepEmulatorDevices.Desktop;
    this.isStepFormActive = false;
    this.isDropdownActive = false;
  }

  changePreview(config: StepEmulationTypes) {
    if (config === StepEmulationTypes.Preview) {
      this.isStepPreview = true;
      this.isResponseScreen = false;
    } else if (config === StepEmulationTypes.Response) {
      this.isStepPreview = false;
      this.isResponseScreen = true;
    }
  }

  openProductSelector() {
    this.ngxSmartModalService.getModal('selectProductsDialog').open();
  }

  onProductSelected(product: CampaignProduct) {
    if (product) {
      this.selectedProduct = product;
      this.storageService.set('selectedStepProduct', product);
      this.updateProductImageUrl();
    }
    this.ngxSmartModalService.getModal('selectProductsDialog').close();
  }

  getTitleForStepType(stepType: CustomStepCategoryEnum, isNew: boolean): string {
    let action = isNew ? 'Create' : 'Edit';

    if (this.editTemplate && this.templateId) {
      action = 'Edit';
    } else if (this.editTemplate && !this.templateId) {
      action = 'Create';
    }

    return `${action} ${StepCategoryLabels[stepType]}`;
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  get isMobile() {
    return this.activeDevice === StepEmulatorDevices.Mobile;
  }

  get isTablet() {
    return this.activeDevice === StepEmulatorDevices.Tablet;
  }

  get isDesktop() {
    return this.activeDevice === StepEmulatorDevices.Desktop;
  }

  getProductName(productName: string): string {
    return getTruncatedProductName(productName);
  }

  addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  scrollToError() {
    const elementRef: ElementRef | null = this.stepFormComponent.scrollToFirstErrorComponent();

    if (elementRef && elementRef.nativeElement) {
      const offsetTop = elementRef.nativeElement.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }

  navigateToTemplates() {
    setTimeout(() => {
      const baseRoute = ['steps', this.offerName];
      const route =
        this.isSurveyStep || this.isCustomerPortalStep
          ? [...baseRoute, 'category', this.offerType, 'template', this.stepType]
          : [...baseRoute, this.funnelId, 'category', this.offerType, 'template', this.stepType];

      this.router.navigate(route);
    }, 2000);
  }

  convertToTemplate(is_global: boolean = false) {
    if (this.form.invalid) {
      this.enableFormErrors = true;
      setTimeout(() => this.scrollToError(), 50);
    } else {
      this.enableFormErrors = false;
      this.loader.show('white', 'Saving');
      this.funnelStepService.convertToTemplate(this.stepId, is_global).subscribe(
        (_) => {
          this.loader.hide();
          this.showSaveMenu = false;
          this.alertService.success('Step converted to template successfully');
          this.navigateToTemplates();
        },
        (error) => {
          this.loader.hide();
          this.showSaveMenu = false;
          this.handleError(error);
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.sideNav.setSideNavExpanded(true);
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.stepEmulatorService.resetStepData();
  }
}
