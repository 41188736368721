import {PaginationService} from "./pagination.service";
import {HttpClient} from "@angular/common/http";

interface DownloadResponse {
  download_url: string
}


export abstract class LogsAbstractService extends PaginationService {
  constructor(protected http: HttpClient, protected route: string) {
    super(http, route);
  }

  private getDownloadLogsData(queryParams, id?: string | number) {
    const path = id ? this.route + '/' + id.toString() + '/download/' : this.route + '/download/';
    return this.http.get(path, {params: queryParams});
  }

  downloadLogs(queryParams, elem, id?: string | number) {
    this.getDownloadLogsData(queryParams, id).subscribe((data: DownloadResponse) => {
      let dwldLink = document.createElement("a");
      dwldLink.setAttribute("href", data.download_url);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      elem.loading = false;
    }, error => {
      elem.loading = false;
      if (error.status == 404) {
        elem.alertService.error("No logs to download");
        return;
      }
      elem.alertService.error("An error occurred while downloading logs");
    });
  }
}
