import { CrudSaveComponent } from '../_directives';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  CardType,
  CRM,
  CRMCampaign,
  CRMTypeId,
  FunnelFulfillmentType,
  ImageSize,
  Pager,
  Region,
  Audience,
  AudienceConditionType,
  TrafficSource,
  AudienceBillingCycle,
  getCampaignProductImageUrl,
} from '../_models';
import {
  AlertService,
  AudienceService,
  CampaignProductService,
  CRMCampaignService,
  CRMService,
  RegionService,
} from '../_services';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { takeUntil } from 'rxjs/operators';
import { config } from '../../config/config';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './audience-edit.component.html',
  styleUrls: ['./audience-edit.component.scss'],
  selector: 'app-audience-new',
  encapsulation: ViewEncapsulation.None,
})
export class AudienceNewComponent extends CrudSaveComponent implements OnInit {
  private isPublishing: {} = {};
  pageTitle = 'Create Audience';
  popupTitle: string = 'Add a condition';
  isNewComponent: boolean = true;
  FunnelFulfillmentType = FunnelFulfillmentType;
  audience: Audience;
  AudienceConditionType = AudienceConditionType;
  AudienceBillingCycle = AudienceBillingCycle;
  billingOptions: number[] = Array.from({ length: 9 }, (_, i) => i + 1);
  crms: CRM[];
  allCRMCampaigns: CRMCampaign[];
  selectedCRMCampaigns: {} = {};
  CRMCampaigns: {} = {};
  isProductFiltersSelected: boolean = false;
  isMaxBillingCycleEmpty: boolean = false;
  selectedCRMId = null;
  products: any[] = null;
  fulfillmentTypeSelected: FunnelFulfillmentType = null;
  selectedStates = [];
  selectedCampaigns = [];
  availableStates: { id: number; text: string }[] = [];
  availableCampaigns: { id: number; text: string }[] = [];
  dropdownSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  billingCycleObject = {
    minimum: 1,
    maximum: null,
    isCustom: false,
  };
  transactionFiltersObject = {
    is3dsVerified: false,
    merchantId: null,
  };
  creditCardTypes = {
    none: false,
    visa: false,
    mastercard: false,
    amex: false,
    discover: false,
    other: false,
  };
  trafficSourceObject = {
    email: false,
    phone: false,
    directLink: false,
  };
  conditions = {
    fulfillmentType: '',
    billingCycle: '',
    transactionFilters: '',
    location: '',
    campaignFilters: '',
    productFilters: '',
    trafficSource: '',
  };

  productsModalInput: any[] = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected audienceService: AudienceService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected regionService: RegionService,
    protected crmService: CRMService,
    protected crmCampaignService: CRMCampaignService,
    protected campaignProductService: CampaignProductService,
    protected modalService: NgxSmartModalService
  ) {
    super(router, location, route, audienceService, alertService);
    this.isNew = true;
    this.objectName = 'audience';
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        name: [null, [Validators.required]],
        use_product_filter: [false],
        is_3ds: [false],
        min_billing_cycle: [1, [Validators.required, Validators.min(1)]],
        max_billing_cycle: [null],
        merchant_ids: [null],
        crm_campaigns: [null],
        fulfillment_type: [null],
        states: [null],
        cc_types: [null],
        use_crm_campaign_filter: [false],
        traffic_sources: [null],
        aff_ids: [null],
      },
      {
        validator: this.minLessThanMaxValidator('min_billing_cycle', 'max_billing_cycle'),
      }
    );
    super.ngOnInit();

    this.regionService.list('us').subscribe((states: Region[]) => {
      const availableStates = [];

      states.forEach((state: Region) => {
        availableStates.push({ id: state.id, text: state.name });
      });
      this.availableStates = availableStates;
    });

    forkJoin([
      this.crmService.list({
        page: 1,
        page_size: config.maxPageSize,
        'type!': CRMTypeId.Test,
      }),
      this.crmCampaignService.list({
        page: 1,
        page_size: config.maxPageSize,
        'crm__type!': CRMTypeId.Test,
      }),
    ]).subscribe(
      (data: [Pager, Pager]) => {
        this.crms = data[0].results;

        this.allCRMCampaigns = data[1].results;
        this.buildCRMCampaignLists();
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  protected getFormData(): any {
    let merchantIds = null;
    let affIds = null;
    let states = null;
    let cardTypes = null;
    let trafficSources = [];
    let crmCampaigns = [];

    if (!this.fulfillmentTypeSelected) {
      this.form.value.fulfillment_type = null;
    }

    if (!this.billingCycleObject.maximum) {
      this.form.value.max_billing_cycle = null;
    }

    if (!this.transactionFiltersObject.is3dsVerified) {
      this.form.value.is_3ds = false;
    }

    if (!this.transactionFiltersObject.merchantId) {
      this.form.value.merchant_ids = null;
    }

    const selectedCreditCards = this.convertToCardTypeArray(this.creditCardTypes);

    const selectedTrafficSources = this.convertToTrafficSourceArray(this.trafficSourceObject);

    if (selectedCreditCards.length) {
      cardTypes = selectedCreditCards;
    }

    if (selectedTrafficSources.length) {
      trafficSources = selectedTrafficSources;
    }

    if (this.selectedStates.length) {
      states = [];
      this.form.value.states.forEach((state: { id: number; text: string }) => {
        states.push(state.id);
      });
    }

    if (this.selectedCampaigns.length) {
      this.selectedCampaigns.forEach((campaign: { id: number; text: string }) => {
        crmCampaigns.push(campaign.id);
      });

      this.form.value.use_crm_campaign_filter = true;
    } else {
      this.form.value.use_crm_campaign_filter = false;
      crmCampaigns = [];
    }

    if (this.products && this.products.length && this.isProductFiltersSelected) {
      this.form.value.use_product_filter = true;
    } else {
      this.form.value.use_product_filter = false;
    }

    if (this.form.value.merchant_ids && this.form.value.merchant_ids.length) {
      merchantIds = this.form.value.merchant_ids;
      if (!Array.isArray(merchantIds)) {
        merchantIds = merchantIds.replace(/(\r?\n|;)/g, ',').split(',');
      }
      merchantIds = merchantIds.map((id) => id.trim()).filter((id) => id.length);
    }

    if (this.form.value.aff_ids && this.form.value.aff_ids.length) {
      affIds = this.form.value.aff_ids;
      if (!Array.isArray(affIds)) {
        affIds = affIds.replace(/(\r?\n|;)/g, ',').split(',');
      }
      affIds = affIds.map((id) => id.trim()).filter((id) => id.length);
    }

    const filters = {
      merchant_ids: merchantIds,
      states: states,
      cc_types: cardTypes,
      traffic_sources: trafficSources,
      crm_campaigns: crmCampaigns,
      aff_ids: affIds,
    };
    return Object.assign({}, this.form.value, filters);
  }

  protected buildCRMCampaignLists() {
    if (this.crms && this.allCRMCampaigns) {
      const selectedCRMCampaigns = {};

      // initialize empty crm campaign arrays for each crm
      this.crms.forEach((crm: CRM) => {
        this.selectedCRMCampaigns[crm.id] = [];
        this.CRMCampaigns[crm.id] = [];
      });

      if (this.crms.length === 1) {
        this.selectedCRMId = this.crms[0].id;
      }

      // build a map of the selected crm campaign ids for faster lookup
      if (this.audience && this.audience.crm_campaigns) {
        for (const campaign_id of this.audience.crm_campaigns) {
          selectedCRMCampaigns[campaign_id] = true;
        }
      }

      // fill in the list of crm campaigns for each crm
      this.allCRMCampaigns.forEach((campaign: CRMCampaign) => {
        if (campaign.id in selectedCRMCampaigns) {
          this.selectedCRMCampaigns[campaign.crm].push(campaign);
        }

        this.CRMCampaigns[campaign.crm].push(campaign);
      });

      const availableCampaigns = [];

      this.allCRMCampaigns.forEach((campaign: CRMCampaign) => {
        availableCampaigns.push({
          id: campaign.id,
          text: `${campaign.name} (${campaign.crm_campaign_id})`,
        });
      });
      this.availableCampaigns = availableCampaigns;
    }
  }

  navigateBackToAudiences() {
    this.router.navigate(['audiences']);
  }

  onConditionSelect(conditionType: string) {
    switch (conditionType) {
      case AudienceConditionType.FulfillmentType:
        this.conditions.fulfillmentType = AudienceConditionType.FulfillmentType;
        break;
      case AudienceConditionType.BillingCycle:
        this.conditions.billingCycle = AudienceConditionType.BillingCycle;
        break;
      case AudienceConditionType.TransactionFilters:
        this.conditions.transactionFilters = AudienceConditionType.TransactionFilters;
        break;
      case AudienceConditionType.Location:
        this.conditions.location = AudienceConditionType.Location;
        break;
      case AudienceConditionType.CampaignFilters:
        this.conditions.campaignFilters = AudienceConditionType.CampaignFilters;
        break;
      case AudienceConditionType.ProductFilters:
        this.conditions.productFilters = AudienceConditionType.ProductFilters;
        break;
      case AudienceConditionType.TrafficSource:
        this.conditions.trafficSource = AudienceConditionType.TrafficSource;
        break;
      default:
        break;
    }

    this.modalService.getModal('conditionsPopup').close();
  }

  onConditionRemove(conditionType: string) {
    switch (conditionType) {
      case AudienceConditionType.FulfillmentType:
        this.conditions.fulfillmentType = '';
        this.fulfillmentTypeSelected = null;
        this.form.value.fulfillment_type = null;
        break;
      case AudienceConditionType.BillingCycle:
        this.conditions.billingCycle = '';
        this.billingCycleObject.minimum = 1;
        this.form.get('min_billing_cycle').setValue(1);
        this.billingCycleObject.maximum = null;
        this.billingCycleObject.isCustom = false;
        this.isMaxBillingCycleEmpty = false;
        break;
      case AudienceConditionType.TransactionFilters:
        this.conditions.transactionFilters = '';
        this.clearTransactionFilters();
        break;
      case AudienceConditionType.Location:
        this.conditions.location = '';
        this.selectedStates = [];
        break;
      case AudienceConditionType.CampaignFilters:
        this.conditions.campaignFilters = '';
        // if condition is closed we remove selected filters
        this.crms.forEach((crm: CRM) => {
          this.selectedCRMCampaigns[crm.id] = [];
        });
        this.selectedCampaigns = [];
        break;
      case AudienceConditionType.ProductFilters:
        this.conditions.productFilters = '';
        this.isProductFiltersSelected = false;
        if (this.products.length) {
          this.loading = true;
          // if product condition is closed we delete all the selected products
          this.products.forEach((product) => {
            this.removeProductFromFunnel(product.id);
          });
          this.products = [];
        }
        break;
      case AudienceConditionType.TrafficSource:
        this.conditions.trafficSource = '';
        this.clearTrafficSourceFilters();
        break;
      default:
        break;
    }
  }

  onAllConditionsSelected(): boolean {
    return Object.entries(this.conditions).every(([key, value]) => {
      if (this.isNew && key === 'productFilters') {
        return true;
      }
      return value !== '';
    });
  }

  isConditionSelected(): boolean {
    const formData = this.getFormData();

    return Object.entries(formData).some(([key, value]) => {
      if (key === 'min_billing_cycle' || key === 'name') {
        return false;
      }

      // Check if the value is not null, not false, and not an empty array
      if (value !== null && value !== false && (!Array.isArray(value) || value.length > 0)) {
        return true;
      }

      return false;
    });
  }

  closeConditionsPopup() {
    this.modalService.getModal('conditionsPopup').close();
  }

  openConditionsPopup() {
    if (!this.audience) {
      this.createAudience();
    } else {
      this.modalService.getModal('conditionsPopup').open();
    }
  }

  selectCustom() {
    this.billingCycleObject.isCustom = true;
    this.billingCycleObject.maximum = 4;
  }

  clearTransactionFilters() {
    this.transactionFiltersObject.is3dsVerified = false;
    this.transactionFiltersObject.merchantId = null;
    this.creditCardTypes.none = false;
    this.creditCardTypes.visa = false;
    this.creditCardTypes.mastercard = false;
    this.creditCardTypes.amex = false;
    this.creditCardTypes.discover = false;
    this.creditCardTypes.other = false;
  }

  clearTrafficSourceFilters() {
    this.trafficSourceObject.email = false;
    this.trafficSourceObject.phone = false;
    this.trafficSourceObject.directLink = false;
  }

  onbillingCycleObject(selected: AudienceBillingCycle) {
    this.billingCycleObject.minimum = 1;
    this.form.get('min_billing_cycle').setValue(1);
    this.isMaxBillingCycleEmpty = false;
    switch (selected) {
      case AudienceBillingCycle.One:
        this.billingCycleObject.maximum = 1;
        this.billingCycleObject.isCustom = false;
        break;
      case AudienceBillingCycle.Two:
        this.billingCycleObject.maximum = 2;
        this.billingCycleObject.isCustom = false;
        break;
      case AudienceBillingCycle.Three:
        this.billingCycleObject.maximum = 3;
        this.billingCycleObject.isCustom = false;
        break;
      default:
        break;
    }
  }

  onMaxRangeChange(value: number) {
    if (!value) {
      this.isMaxBillingCycleEmpty = true;
    } else {
      this.isMaxBillingCycleEmpty = false;
    }
    this.billingCycleObject.maximum = value;
  }

  private convertToCardTypeArray(creditCardTypes: any): number[] {
    const cardTypesArray: number[] = [];

    Object.keys(creditCardTypes).forEach((key) => {
      if (creditCardTypes[key] === true) {
        cardTypesArray.push(CardType[key.charAt(0).toUpperCase() + key.slice(1)]);
      }
    });

    return cardTypesArray;
  }

  private convertToTrafficSourceArray(trafficSourceObject: any): number[] {
    const trafficSourceArray: number[] = [];

    Object.keys(trafficSourceObject).forEach((key) => {
      if (trafficSourceObject[key] === true) {
        // Convert the object key to match the enum key
        const enumKey = key.toUpperCase();
        if (TrafficSource[enumKey] !== undefined) {
          trafficSourceArray.push(TrafficSource[enumKey]);
        }
      }
    });

    return trafficSourceArray;
  }

  displayFormat(row) {
    return `${row.name} (${row.crm_campaign_id})`;
  }

  crmSelected(event) {
    this.selectedCRMId = parseInt(event.target.value, 10);
  }

  addProductFilters() {
    if (!this.audience) {
      this.createAudience();
    } else {
      this.isProductFiltersSelected = true;
      this.getProducts();
      this.modalService.getModal('productsDialog').open();
    }
  }

  protected getProducts() {
    if (this.audience) {
      this.loading = true;
      this.campaignProductService
        .list({
          audiences: this.audience.id,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (page: Pager) => {
            this.loading = false;
            this.products = page.results;
          },
          (error) => {
            this.loading = false;
            this.handleError(error);
          }
        );
    }
  }

  getProductImageUrl(product) {
    return getCampaignProductImageUrl(product, ImageSize.small);
  }

  removeProductFromFunnel(productId: number) {
    this.loading = true;
    this.audienceService
      .remove_campaign_product(this.audience.id, {
        campaign_product: productId,
      })
      .subscribe(
        (_) => {
          this.loading = false;
          this.getProducts();
        },
        (error) => {
          this.loading = false;
          this.handleError(error);
        }
      );
  }

  onProductsSelected(productIds: string[] | number[]) {
    this.modalService.getModal('productsDialog').close();
    this.addProductsToFunnel(productIds);
  }

  private populateCampaignProductRequests(productIds: string[] | number[]) {
    const requests = [];
    while (productIds.length > 0) {
      requests.push(
        this.audienceService.add_campaign_products(this.audience.id, {
          campaign_products: productIds.splice(0, 200),
        })
      );
    }

    return requests;
  }

  addProductsToFunnel(productIds: string[] | number[]) {
    forkJoin(this.populateCampaignProductRequests(productIds)).subscribe(
      (_) => {
        this.loading = false;
        this.getProducts();
      },
      (error) => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }

  createAudience() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;
      this.crudService.create(this.getFormData()).subscribe(
        (data: Audience) => {
          if ('id' in data) {
            this.id = data['id'];
          }
          this.audience = data;

          this.loading = false;
          this.isNew = false;
          this.isNewComponent = false;
          this.modalService.getModal('conditionsPopup').open();
          this.canDeactivateService.setFormDirty(false);
        },
        (error) => {
          this.handleSubmitError(error);
          this.loading = false;
        }
      );
    }
  }

  publish() {
    const audienceId = this.id || this.audience.id;
    if (audienceId) {
      this.audienceService.publish(audienceId).subscribe(
        () => {
          this.loading = false;
          this.alertService.success('Audience Published Successfully.', true);
          this.navigate(['/audiences'], { replaceUrl: true });
        },
        (error) => {
          this.handleError(error);
          this.loading = false;
        }
      );
    }
  }

  protected onSaveComplete(data) {
    this.publish();
  }

  isAudiencePublishing(audience: Audience) {
    return audience.id in this.isPublishing && this.isPublishing[audience.id];
  }

  isAudiencePublished(audience: Audience): boolean {
    return audience ? audience.is_modified : false;
  }

  minLessThanMaxValidator(minKey: string, maxKey: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const minControl = formGroup.controls[minKey];
      const maxControl = formGroup.controls[maxKey];

      if (minControl.value != null && maxControl.value != null && +minControl.value > +maxControl.value) {
        return { minGreaterThanMax: true };
      }

      return null;
    };
  }
}
