import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, AudienceService, FunnelService, ProductService } from '../_services';
import { FormArray, FormBuilder } from '@angular/forms';
import { Funnel, FunnelInputTypeEnum } from '../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CustomerPortalNewComponent } from './customer-portal-new.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './customer-portal-edit.component.html',
  styleUrls: ['../funnel/funnel.component.css', './customer-portal.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerPortalEditComponent extends CustomerPortalNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected modalService: NgxSmartModalService,
    protected audienceService: AudienceService
  ) {
    super(
      router,
      location,
      route,
      funnelService,
      alertService,
      formBuilder,
      productService,
      modalService,
      audienceService
    );
    this.isNew = false;
    this.title = '';
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.data$.subscribe(
      (funnel: Funnel) => {
        if (funnel) {
          this.funnel = funnel;
          this.form.patchValue(funnel);
          this.useMatchedWelcomeMessage = !!(funnel.matched_welcome_msg && funnel.matched_welcome_msg.length);
          this.buildProductFunnelList();

          if (funnel.customer_portal_settings.side_menu_inputs) {
            let inputs = [];
            let labels = {};

            this.availableSideMenuInputs.forEach((input: { id: FunnelInputTypeEnum; text: string }) => {
              labels[input.id] = input.text;
            });

            funnel.customer_portal_settings.side_menu_inputs.forEach((value) => {
              inputs.push({ id: value, text: labels[value] });
            });

            this.form.patchValue({ side_menu_inputs: inputs });
            delete funnel.customer_portal_settings.side_menu_inputs; // delete this so patchValue works
          }

          if (funnel.customer_portal_settings.holdoff_actions_config) {
            const holdoff_actions_config = [];

            Object.entries(
              funnel.customer_portal_settings.holdoff_actions_config
            ).forEach(([intent, holdoff_actions]) => {
              holdoff_actions_config.push({
                intent: parseInt(intent),
                holdoff_actions: this.availableActionHoldoffs.filter(
                  (item) => holdoff_actions.indexOf(item['id']) > -1
                ),
              });
            });

            if (holdoff_actions_config.length) {
              const holdoffActionsFormArray = this.formBuilder.array(
                holdoff_actions_config.map((config) =>
                  this.formBuilder.group({
                    intent: [config.intent || null],
                    holdoff_actions: [config.holdoff_actions || []],
                  })
                )
              );

              this.form.setControl('holdoff_actions_config', holdoffActionsFormArray);
            }

            delete funnel.customer_portal_settings.holdoff_actions_config; // delete this so patchValue works
          }

          this.form.patchValue(funnel.customer_portal_settings);
          this.toggleSetting(this.customerPortalSettingType.General);
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }
}
