import {Form} from "../_forms";
import {OnInit, ViewChild} from "@angular/core";
import {ReportTableComponent} from "./report-table.component";
import {ReportsConfigComponent} from "./reports-config.component";
import * as moment from "moment";
import {DashboardState, ReportsUserConfiguration} from "../_models/reports";
import {
  Account,
  Campaign, CampaignProduct,
  CRMCampaign,
  CRMTypeId,
  FunnelStep, ImageSize,
  Pager,
  ReportsFormData,
  TrackingSource,
  User, ReportIntentServices, ReportIntent, ReportIntentLabels, ReportSessionInput
} from '../_models';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {
  AccountService,
  AlertService, CampaignProductService,
  CampaignService,
  CampaignTrackingService, CRMCampaignService, FunnelStepService, RefundReportService, ReportsAbstractService,
  ReportsConfigService, StorageService, UserService, MerchantService
} from '../_services';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgxSmartModalService} from "ngx-smart-modal";
import * as momentTz from "moment-timezone";
import {config} from "../../config/config";
import {takeUntil} from "rxjs/operators";
import {generateReportsQuery, getCampaignProductImageUrl, normalizeMomentForQuery} from '../_helpers';
import {forkJoin} from 'rxjs';
import {get} from 'lodash';
import {Moment} from 'moment';
import {reportsFieldData} from '../session/sessionFieldData';

export abstract class ReportsAbstractComponent extends Form implements OnInit {
  @ViewChild(ReportTableComponent, { static: false }) reportsTable: ReportTableComponent;
  @ViewChild(ReportsConfigComponent, { static: false }) reportsConfig: ReportsConfigComponent;
  public getReportsFlag = false;
  public ranges = {
    'Today': this.setDateRangeToToday(),
    'Yesterday': this.setDateRangeToYesterday(),
    'Past 7 Days': this.setDateRangeToLastNDays(7),
    'Month to Date': this.setDateRangeToCurrentMonth(),
    'Last Month': this.setDateRangeToLastMonth(),
    'Last 30 days': this.setDateRangeToLastNDays(31),
    'Last 3 Months to Date': this.setDateRangeToNPastMonthsToDate(3),
    'Last 6 Months to Date': this.setDateRangeToNPastMonthsToDate(6),
    'Last 12 Months to Date': this.setDateRangeToNPastMonthsToDate(12),
    'Year to Date': this.setDateRangeToCurrentYear(),
    'Last Year': this.setDateRangeToLastYear()
  };
  public maxDate = moment();
  public alwaysShowCalendars = true;
  public formValid = true;
  public showClearButton = true;
  public saveConfiguration = false;
  public loadedConfiguration: ReportsUserConfiguration = null;
  public filtersDynamicOptions = {
    tracking_source: [],
    client: [],
    campaign_id: []
  };
  public productsFieldCurrentModel = '';
  public productsModalInput: any[] = null;
  public fields: any = [];
  public checkedFieldsMapped = [];
  public browsers = ['Chrome', 'Mobile Safari', 'Samsung Browser', 'Firefox', 'Safari'];
  public user: User = null;
  public formInitialValue: ReportsFormData = null;
  public defaultFilterSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  public accountFilterSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: true,
    selectAllText: 'All Accounts'
  };
  public campaignProducts = [];
  public exchangeableProducts = [];
  public upsellProducts = [];
  public currentState: DashboardState = {};
  public timezones: string[] = [];
  public dateFilterTypes = [
    {value: 'session', text: 'Session'},
    {value: 'order', text: 'Order'}
  ];
  public dateFilterTypeDefaultValue = 'session';
  public productFilters = ['product_id', 'exchangeable_product_id', 'upsell_product_id']
  public productFilterTitles = {
    product_id: 'Campaign Products',
    exchangeable_product_id: 'Exchangeable Products',
    upsell_product_id: 'Upsell Products'
  }
  public displayIntent = true;
  public intents = Object.values(ReportIntent);
  public intentLabels = ReportIntentLabels;

  public fillAccountDependantFiltersAutomatically: boolean = true;

  public accounts = [];
  public showDateFilterTypeField = true;
  public useReportsConfiguration = true;

  public defaultReportsService = null;

  public anyFieldCheckError = "Please select any of the fields to generate report";
  protected fieldData = reportsFieldData;


  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: ReportsAbstractService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(alertService, router, location);
    this.title = "Reports";
    this.currentState = this.router.getCurrentNavigation().extras.state;
    this.timezones = momentTz.tz.names();
    this.defaultReportsService = this.reportsService;
  }

  ngOnInit() {
    let timezone = this.storageService.get('timezone');
    if (!timezone) {
      timezone = momentTz.tz.guess();
    }

    this.form = this.formBuilder.group({
      date_range: new FormControl(this.getDefaultDateRange(), Validators.required),
      timezone: [timezone, Validators.required],
      dateFilterType: [this.dateFilterTypeDefaultValue, Validators.required],
      account: [null, null],
      intent: [ReportIntent.General, Validators.required]
    });
    for (let key of Object.keys(this.fields)) {
      let currentFieldsList = this.fields[key];
      let formGroupName = key;
      let formGroupFields = {};
      for (let group of currentFieldsList) {
        for (let field of group) {
          formGroupFields[field.model] = new FormControl({value: field.default, disabled: field.disabled});
          if (field.type && field.type.startsWith("arithmetic-comparison")) {
            formGroupFields[`${field.model}_comparison`] = new FormControl({value: field.comparisonDefault,
              disabled: false});
              if (field.comparisonOptions.find(e => e.id == 'range')){
                formGroupFields[`${field.model}_comparison_right`] = new FormControl({value: "",
                  disabled: false});
              }
          } else if (field.type && field.type.endsWith("with-negation")) {
            formGroupFields[`${field.model}_negation`] = new FormControl({value: field.default,
              disabled: field.disabled});
          }
        }
      }
      this.form.addControl(formGroupName, new FormGroup(formGroupFields));

    }

    this.formInitialValue = this.form.value;
    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user;
        this.form.patchValue({account: [{id: this.user.account.id, text: this.user.account.name}]});
        if (this.fillAccountDependantFiltersAutomatically) {
          this.fillAccountDependantFilters();
          this.initializeStepsFilters(this.form.value.date_range)
        }
      }, error => {
        this.handleError(error);
      });
    let clientsFromData = [];
    this.accountService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.accounts = data.results;
          data.results.forEach((item: Account) => {
            clientsFromData.push({id: item.id, text: item.name});
          })
          this.accounts = clientsFromData;
        },
        error => {
          this.handleError(error);
        }
      );

    this.filtersDynamicOptions['client'] = clientsFromData;
    this.route.params.subscribe(params => {
      if (params.id) {
        this.reportsConfigService.get(params.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe((config: ReportsUserConfiguration) => {
            this.loadConfiguration(config);
          }, error => {
            this.alertService.error(error.message);
          })
      }

    })
    if(this.currentState && 'load_as_configuration' in this.currentState && this.currentState['load_as_configuration']){
      this.loadConfiguration(this.currentState['config'])
    }
  };

  getDefaultDateRange(): {startDate: Date | Moment, endDate: Date | Moment} {
    let currentDate = new Date();
    let normalizedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
      0, 0, 0);

    return {startDate: normalizedDate, endDate: normalizedDate}
  }

  fillAccountDependantFilters(items?) {
    let accounts = [];
    if (items === undefined) {
      items = this.form.value.account;
    }
    items.forEach(item => {
      accounts.push(item.id);
    })
    let queryParams = {account_id: accounts, page: 1, page_size: config.maxPageSize};
    let campaignFormData = [];
    this.initiateAccountFiltersOperations();
    this.campaignService.list(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          data.results.forEach((item: Campaign) => {
            campaignFormData.push({id: item.id, text: item.display_name});
          })
          if (this.loadedConfiguration) {
            this.loadConfiguration(this.loadedConfiguration);
          }
        },
        error => {
          this.handleError(error);
        }
      );

    let trackingSourcesFromData = [];
    this.trackingSourceService.list(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: TrackingSource[]) => {
          data.forEach((item: TrackingSource) => {
            trackingSourcesFromData.push({id: item.source, text: item.source});
          })
        },
        error => {
          this.handleError(error);
        }
      );

    let crmCampaignFormData = [];
    this.crmCampaignService.listAll({...queryParams, 'crm__type!': CRMTypeId.Test})
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          data.results.forEach((item: CRMCampaign) => {
            crmCampaignFormData.push({id: item.id, text: `${item.name} (${item.crm_campaign_id})`});
          })
        },
        error => {
          this.handleError(error);
        }
      );

    let stepSnapshotSlugFormData = [];
    this.stepService.getGlobalJumpSteps(true, queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: FunnelStep[]) => {
          data.forEach((item: FunnelStep) => {
            stepSnapshotSlugFormData.push({id: item.slug, text: item.name});
          })
        },
        error => {
          this.handleError(error);
        }
      );

    let cancelReasonFormData = [];
    this.reportsService.getCancelReasons(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          data.forEach((item) => {
            if (item) {
              cancelReasonFormData.push({id: item, text: item});
            }
          })
        },
        error => {
          this.handleError(error);
        }
      );

    let productPathFormData = [];
    this.reportsService.getProductPaths(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          data.forEach((item) => {
            if (item) {
              productPathFormData.push({id: item, text: item});
            }
          })
        },
        error => {
          this.handleError(error);
        }
      );

    let merchantsData = [];
    this.merchantService.list({...queryParams, 'crm__type!': CRMTypeId.Test})
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          data.results.forEach((item) => {
            merchantsData.push({id: item.merchant_id, text: `${item.name}`});
          })
        },
        error => {
          this.handleError(error);
        }
      );


    this.filtersDynamicOptions['campaign_id'] = campaignFormData;
    this.filtersDynamicOptions['campaign'] = campaignFormData;
    this.filtersDynamicOptions['tracking_source'] = trackingSourcesFromData;
    this.filtersDynamicOptions['crm_campaign_id'] = crmCampaignFormData;
    this.filtersDynamicOptions['crm_campaign'] = crmCampaignFormData;
    this.filtersDynamicOptions['downsell_step_slug'] = stepSnapshotSlugFormData;
    this.filtersDynamicOptions['cancel_reason'] = cancelReasonFormData;
    this.filtersDynamicOptions['product_path'] = productPathFormData;
    this.filtersDynamicOptions['merchant_id'] = merchantsData;
  }

  initializeStepsFilters(date_range) {
    let accounts = [];
    this.form.value.account.forEach(item => {
      accounts.push(item.id);
    })

    let queryParams = {account_id: accounts, page: 1, page_size: config.maxPageSize,
      start_date: normalizeMomentForQuery(date_range['startDate'], this.form.value.timezone),
      end_date: normalizeMomentForQuery(date_range['endDate'], this.form.value.timezone)};
    let stepSlugFormData = [];
    let inputNameFormData = [];
    this.loading = true
    forkJoin(this.reportsService.getStepNames(queryParams),
      this.reportsService.getInputNames(queryParams))
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          data[0].forEach((item) => {
            if (item) {
              stepSlugFormData.push({id: item.step_slug, text: item.step_name});
            }
          })

          data[1].forEach(item => {
            if (item) {
              inputNameFormData.push({id: item.input_name, text: item.input_name.replace(/<[^>]*>/g, '')});
            }
          })
          this.loading = false
          this.filtersDynamicOptions['step_slug'] = stepSlugFormData;
          this.filtersDynamicOptions['input_name'] = inputNameFormData;

          let filtersGroup = this.form.get('filters')
          let stepSlugControl = filtersGroup.get('step_slug')
          if (stepSlugControl) {stepSlugControl.setValue(false)}
          let inputNameControl = filtersGroup.get('input_name')
          if (inputNameControl) {inputNameControl.setValue(false)}
        },
        error => {
          this.loading = false
          this.handleError(error);
        }
      );
  }

  initiateAccountFiltersOperations() {
    let accountFieldOps = {
      'filters.campaign_id': 'reset',
      'filters.tracking_source': 'reset',
      'filters.crm_campaign_id': 'reset',
      'filters.downsell_step_slug': 'reset',
      'filters.cancel_reason': 'reset',
      'filters.product_path': 'reset',
      'filters.step_slug': 'reset',
      'filters.input_name': 'reset',
    }
    Object.keys(accountFieldOps).forEach(fieldname => {
      let control: any = this.form;
      let operation = accountFieldOps[fieldname];
      fieldname.split(".").forEach(field => {
        control = control.get(field);
      });

      if (operation === 'reset' && control) {
        control.patchValue(false);
      }

    })
  }

  get globalExcludes() {
    let excludes = []
    if (this.fields.exclude.length > 0) {
      this.fields.exclude.forEach(group => {
        group.forEach(item => {
          if (item.isGlobal) {
            excludes.push(item)
          }
        })
      })
    }
    return excludes;
  }

  setDateRangeToToday() {
    return [new Date(), new Date()];
  }

  setDateRangeToYesterday() {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    return [date, date]
  }

  setDateRangeToLastNDays(numDays: number) {
    let date = new Date();

    date.setDate((date.getDate() + 1) - numDays);

    return [date, new Date()];
  }

  setDateRangeToLastMonth() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1st of last month
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);

    //end date = last day of previous month
    endDate.setDate(0);

    return [startDate, endDate];
  }

  setDateRangeToCurrentMonth() {
    return this.setDateRangeToNPastMonthsToDate(0);
  }

  setDateRangeToNPastMonthsToDate(numPastMonths: number) {
    let date = new Date();

    date.setDate(1);
    date.setMonth(date.getMonth() - numPastMonths);

    return [date, new Date()];
  }

  setDateRangeToCurrentYear() {
    let date = new Date();

    date.setDate(1);
    date.setMonth(0);

    return [date, new Date()];
  }

  setDateRangeToLastYear() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1/1 of last year
    startDate.setMonth(0);
    startDate.setDate(1);
    startDate.setFullYear(startDate.getFullYear() - 1);

    //end date = 12/31 of last year
    endDate.setMonth(11);
    endDate.setDate(31);
    endDate.setFullYear(endDate.getFullYear() - 1);

    return [startDate, endDate];
  }

  anyFieldChecked() {
    let fieldsThatMustBeChecked = ['dimensions', 'statistics', 'calculations'];
    let formData = this.form.value;
    let anyFieldChecked = false;

    Object.keys(formData).forEach(mainKey => {
      if (fieldsThatMustBeChecked.indexOf(mainKey) > -1) {
        let fields = formData[mainKey];
        Object.keys(fields).forEach(key => {
          if (fields[key]) {
            anyFieldChecked = true;
          }
        })
      }
    });

    return anyFieldChecked;
  }

  getReports(displayOnScreen: boolean = true) {
    this.submitted = true;
    if (!this.form.valid) {
      this.formValid = false;
      this.alertService.error("Please see the errors and try again");
      return;
    }

    if (!this.anyFieldChecked()) {
      this.formValid = false;
      this.loading = false;
      this.alertService.error(this.anyFieldCheckError)
      return;
    }

    this.formValid = true;
    let dimensionFields = this.form.value.dimensions;
    let timeIntervalFields = this.form.value.timeInterval;
    let statisticsFields = this.form.value.statistics;
    let calculationsFields = this.form.value.calculations;
    this.checkedFieldsMapped = [];
    let scopedFields = [];

    Object.keys(timeIntervalFields).forEach(key => {
      if (timeIntervalFields[key]) {
        scopedFields.push(key);
      }
    });
    Object.keys(dimensionFields).forEach(key => {
      if (dimensionFields[key]) {
        scopedFields.push(key);
      }
    });

    if(statisticsFields != undefined) {
      Object.keys(statisticsFields).forEach(key => {
        if (statisticsFields[key]) {
          scopedFields.push(key);
        }
      });
    }
    Object.keys(calculationsFields).forEach(key => {
      if (calculationsFields[key]) {
        scopedFields.push(key);
      }
    });
    if (displayOnScreen) {
      this.checkedFieldsMapped = scopedFields;
    }

    this.getReportsFlag = displayOnScreen || this.getReportsFlag;
    if (displayOnScreen && this.reportsTable) {
      this.reportsTable.resetPager();
      this.reportsTable.queryData();
    }
  }

  downloadReport() {
    this.loading = true;
    this.getReports(false);

    if (this.formValid) {
      let data = generateReportsQuery(this.form.value);
      data['start_date_formatted'] = this.form.value.date_range.startDate.format('MMM Do, YYYY')
      data['end_date_formatted'] = this.form.value.date_range.endDate.format('MMM Do, YYYY')
      this.reportsService.downloadFile(data, this);
    }
  }

  loadConfiguration(userConfig: ReportsUserConfiguration) {
    if ('account' in userConfig.configuration) {
      delete userConfig.configuration.account
    }
    this.loadedConfiguration = userConfig;
    let formValues = userConfig.configuration;
    if (typeof formValues === 'string') {
      formValues = JSON.parse(formValues);
    }
    let currentDate = new Date();
    formValues['date_range']['startDate'] = new Date(currentDate.getFullYear(), currentDate.getMonth(),
      currentDate.getDate(), 0, 0, 0);
    formValues['date_range']['endDate'] = new Date(currentDate.getFullYear(), currentDate.getMonth(),
      currentDate.getDate(), 23, 59, 59);
    if (this.currentState && 'campaign' in this.currentState && this.filtersDynamicOptions['campaign_id'].length > 0) {
      formValues['filters']['campaign_id'] = true;
      formValues['filtersData']['campaign_id'] = [{id: this.currentState.campaign.id,
        text: this.currentState.campaign.display_name}];
    }

    if (formValues) {
      if ('startDate' in formValues){
        formValues['date_range']['startDate'] = new Date(formValues.startDate);
      }
      if ('endDate' in formValues){
        formValues['date_range']['endDate'] = new Date(formValues.endDate);
      }
    }

    if (this.currentState && 'startDate' in this.currentState) {
      formValues['date_range']['startDate'] = this.currentState.startDate;
    }

    if (this.currentState && 'endDate' in this.currentState) {
      formValues['date_range']['endDate'] = this.currentState.endDate;
    }
    if (!('timezone' in formValues)) {
      let timezone = this.storageService.get('timezone');
      if (!timezone) {
        timezone = momentTz.tz.guess();
      }
      formValues['timezone'] = timezone;
    }
    this.form.patchValue(formValues);
    this.campaignProducts = [];

    //load the campaign products that are included in the filter so we can display them
    if (formValues.filtersData && formValues.filtersData.product_id && formValues.filtersData.product_id.length) {
      this.campaignProductService.list({
        id__in: formValues.filtersData.product_id.join(','),
        page: 1,
        page_size: config.maxPageSize
      }).pipe(takeUntil(this._destroy$))
        .subscribe(
          (data: Pager) => {
            const products = [];

            data.results.forEach((product: CampaignProduct) => {
              products.push(product);
            });

            this.campaignProducts = products;
          },
          error => {
            this.handleError(error);
          }
        );
    }
    if ('intent' in formValues){
      this.reportsService = get(this, ReportIntentServices[formValues.intent])
    }

    if (this.currentState) {
      // we will wait for the campaigns api to get data and then query for reports because when campaign is coming from
      // the dashboard, reports are fetched before the campaigns request is completed
      if (this.filtersDynamicOptions['campaign_id'].length > 0) {
        this.getReports();
      }
    }
  }

  deleteConfiguration(id: Number | string) {
    this.reportsConfigService.delete(id)
      .subscribe(result => {
        this.loadedConfiguration = null;
        this.form.reset(this.formInitialValue);
        this.alertService.success("Configuration successfully deleted!");
        this.reportsConfig.deleteConfiguration(id);
      }, error => {
        this.alertService.error("Configuration deletion failed!")
      });
  }

  updateConfiguration(id: any) {
    this.loadedConfiguration.configuration = JSON.stringify(this.form.value);
    this.reportsConfigService.update(id, this.loadedConfiguration)
      .subscribe(result => {
        this.alertService.success("Configuration successfully updated!")
      }, error => {
        this.alertService.error("Failed to update configuration!")
      });
  }

  enableReportConfigurationControl() {
    if (!this.loadedConfiguration) {
      return false;
    }
    if (this.loadedConfiguration.is_global && this.user.is_staff) {
      return true;
    }

    if (!this.loadedConfiguration.is_global && this.loadedConfiguration.user === this.user.id) {
      return true;
    }

    return false;
  }

  clearProductIdFilterData() {
    this.form.value.filtersData[this.productsFieldCurrentModel] = '';
  }

  isControlDisabled(fieldname) {
    let control: any = this.form;
    fieldname.split(".").forEach(field => {
      control = control.get(field);
    });
    return control.disabled;
  }

  changeTimezone(event) {
    this.storageService.set('timezone', event.target.value);
  }

  addCampaignProduct(model: string) {
    this.productsFieldCurrentModel = model;
    let productsModalInput = this.campaignProducts;
    if (model === 'exchangeable_product_id') {
      productsModalInput = this.exchangeableProducts;
    } else if (model === 'upsell_product_id') {
      productsModalInput = this.upsellProducts;
    }
    this.productsModalInput = productsModalInput;
    this.modalService.getModal('selectProductsDialog').open();
  }

  onCampaignProductsSelected(products: CampaignProduct[]) {
    if (this.productsFieldCurrentModel === 'exchangeable_product_id') {
      this.exchangeableProducts = products
    } else if (this.productsFieldCurrentModel === 'upsell_product_id') {
      this.upsellProducts = products;
    } else {
      this.campaignProducts = products;
    }
    this.updateProductIdFilterData(this.productsFieldCurrentModel);
    this.modalService.getModal('selectProductsDialog').close();
  }

  removeCampaignProduct(productId, model) {
    if (model === 'exchangeable_product_id') {
      this.exchangeableProducts = this.exchangeableProducts.filter(
        (product: CampaignProduct) => product.id != productId);
    } else if (model === 'upsell_product_id') {
      this.upsellProducts = this.upsellProducts.filter(
        (product: CampaignProduct) => product.id != productId);
    } else {
      this.campaignProducts = this.campaignProducts.filter(
        (product: CampaignProduct) => product.id != productId);
    }
    this.updateProductIdFilterData(model);
  }

  protected updateProductIdFilterData(model) {
    const productIds = [];

    if (model === 'exchangeable_product_id') {
      this.exchangeableProducts.forEach((product: CampaignProduct) => {
        productIds.push(product.id);
      });
    } else if (model === 'upsell_product_id') {
      this.upsellProducts.forEach((product: CampaignProduct) => {
        productIds.push(product.campaign_product_id);
      });
    } else {
      this.campaignProducts.forEach((product: CampaignProduct) => {
        productIds.push(product.id);
      });
    }

    this.form.value.filtersData[model] = productIds;
  }

  getCampaignProductImageUrl(campaignProduct) {
    return getCampaignProductImageUrl(campaignProduct, ImageSize.small);
  }

  toggleProductsBooleanField(event, fieldName) {
    this.toggleBooleanField(event, fieldName);
    let modelName = '';
    fieldName.split(".").forEach(field => {
      modelName = field;
    });
    if (this.isBooleanFieldChecked(fieldName)) {
      this.addCampaignProduct(modelName);
    } else {
      this.clearProductIdFilterData();
    }
  }

  getProductNames(productFieldName: string) {
    let products = this.campaignProducts;
    if (productFieldName === 'exchangeable_product_id') {
      products = this.exchangeableProducts;
    } else if (productFieldName === 'upsell_product_id') {
      products = this.upsellProducts;
    }
    let productNames = [];
    products.slice(0, 20).forEach(product => {
      productNames.push(product.name)
    })

    return productNames.join(', ');
  }

  allowNumbersOnly(event, allowCharacters = -1) {
    var charCode = (event.which) ? event.which : event.keyCode;
    let allowedCodes = [8, 9, 13, 49, 50, 51, 52, 53, 54, 55,
      56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 144]
    // Only Numbers 0-9, backspace, tab and enter
    if (!allowedCodes.includes(charCode)) {
      event.preventDefault();
      return false;
    }
    else if(allowCharacters !== -1){
      if(event.target.value.length >= allowCharacters){
        return false;
      }
    }
    else {
      return true
    }
  }
  getValueOfField(id){
    if(document.getElementById(id)){
      return document.getElementById(id)['value']
    }
    return ''
  }

  changeReportService(event){
    this.reportsService = get(this, ReportIntentServices[event.target.value])
  }

  selectCustomerMode(event) {
    this.reportsService.selectCustomerMode(event.target.value)
  }

  getSessionFieldValue(sessionInput: ReportSessionInput, field: string) {
    if (field.startsWith('exclude_')) {
      return ''
    }
    let value = get(sessionInput, field);
    let args = [sessionInput, value]
    if (['date', 'month', 'year', 'week', 'hour', 'order_date'].indexOf(field) > -1) {
      args.push(this.storageService.get('timezone'));
    }
    if ('getter' in this.fieldData[field]) {
      value = this.fieldData[field].getter(...args);
    }

    return value;
  }
}
