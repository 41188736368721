export abstract class BasePlugin {
  constructor(
    protected tinymce
  ) {

  }

  public create() {

  }
}
