import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as moment from 'moment-timezone';
import {
  createStepMediaInitialData,
  externalButtonUsage,
  stepLayout,
  createStepResponseMediaInitialData,
  steps,
} from '../../step-util';
import {
  Breadcrumb,
  OfferTypeLabels,
  OfferTypes,
  StepDateTypes,
  StepPopupActions,
  StepStatusLabels,
  StepStatuses,
  CampaignProduct,
  StepCategoryLabels,
  User,
  StepEmulatorDevices,
  FunnelType,
  StepStaticDataImageType,
  getCampaignProductImageUrl,
  Campaign,
  Pager,
  Style,
  defaultThemeColor,
} from '../../../_models';
import {
  AlertService,
  CampaignService,
  FunnelInputService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  StepEmulatorService,
  StorageService,
  StyleService,
  UserService,
} from '../../../_services';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getTruncatedProductName } from '../../step-util';
import { Form } from '../../../_forms';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { initializeForm, patchInputs } from '../../step-util';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { config } from '../../../../config/config';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent extends Form implements OnInit, OnDestroy {
  steps = steps;
  isTableVisible: boolean = true;
  showThreeDotMenu: boolean = false;
  offer: any;
  offerId: number;
  offerType: OfferTypes;
  offerName: string;
  selectedDate: StepDateTypes = StepDateTypes.LastModified;
  tableDateTypes = StepDateTypes;
  tableData: any[];
  originalTableData: any[];
  offerSteps: any[];
  originalOfferSteps: any[];
  hoveredStepIndex: string | null = null;
  StepPopupActions = StepPopupActions;
  popupTitle: string = '';
  breadcrumbs: Breadcrumb[] = [];
  popupData = {
    name: '',
    is_survey: false,
    offer_id: null,
    step_id: null,
    is_step: true,
    offer_intent: null,
    offer_type: null,
    popupType: null,
    step_response: {},
  };
  stepStatuses = StepStatuses;
  stepStatusLabels = StepStatusLabels;
  stepPopupTitleMap = {
    [StepPopupActions.Update]: 'Rename Step',
    [StepPopupActions.Duplicate]: 'Duplicate Step',
    [StepPopupActions.Delete]: 'Delete Step',
  };
  selectedCampaign: Campaign;
  selectedCampaignId: string | number = null;
  selectedProduct: CampaignProduct = {} as CampaignProduct;
  campaigns: any[] = null;
  campaignsLoading: boolean = true;
  statuses = [
    { value: StepStatuses.All, label: StepStatusLabels[StepStatuses.All] },
    { value: StepStatuses.Enabled, label: StepStatusLabels[StepStatuses.Enabled] },
    { value: StepStatuses.Disabled, label: StepStatusLabels[StepStatuses.Disabled] },
  ];
  selectedStatus = StepStatuses.All;
  activeDevice = StepEmulatorDevices.Mobile;
  productPlaceholderUrl: string = '';
  theme: string = defaultThemeColor;
  themes: Style[] = [];
  stepsReordering = false;
  useExternalButtons = false;
  protected _destroy$: Subject<boolean> = new Subject<boolean>();
  public user: User;
  stepsPerView = 3;
  currentIndex = 0;
  private resizeTimeout: NodeJS.Timeout | null = null;

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private funnelService: FunnelService,
    private funnelStepService: FunnelStepService,
    private campaignService: CampaignService,
    private stepEmulatorService: StepEmulatorService,
    protected styleService: StyleService,
    public ngxSmartModalService: NgxSmartModalService,
    public loader: LoaderService,
    protected alertService: AlertService,
    private sanitizer: DomSanitizer,
    protected inputService: FunnelInputService,
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    protected location: Location,
    protected storageService: StorageService
  ) {
    super(alertService, router, location);
    this.route.params.subscribe((params) => {
      this.offerId = params['offer_id'];
      this.offerType = Number(params['offer_type']);
      this.offerName = OfferTypeLabels[this.offerType];
    });
  }

  ngOnInit() {
    this.updateStepsPerView();
    this.addResizeListener();
    this.funnelStepService.setFunnelId(this.offerId);
    this.generateBreadcrumbs();
    this.getCampaigns();
    this.fetchOffer();

    this.userService.getCurrent().subscribe(
      (user: User) => {
        this.user = user;
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  fetchOffer() {
    this.loader.show();
    this.funnelService.get(this.offerId).subscribe(
      (response: any) => {
        if (response) {
          this.offer = response;
          this.breadcrumbs[1].label = response.slug;

          this.offer.offer_steps.forEach((step) => {
            step.layout = stepLayout[step.category];
            step.form = initializeForm(this.formBuilder, step.category, this.offerType, false);
            step.form.setControl(
              'static_data',
              this.formBuilder.group({
                image: null,
                image_tablet: null,
                image_mobile: null,
                embed_code: null,
                image_type: StepStaticDataImageType.None,
              })
            );
            this.useExternalButtons = externalButtonUsage(step.category);
            const stepMedia = createStepMediaInitialData();
            const stepResponseMedia = createStepResponseMediaInitialData();

            patchInputs(
              step.form,
              this.formBuilder,
              step,
              this.useExternalButtons,
              stepMedia,
              stepResponseMedia,
              this.sanitizer,
              this.stepEmulatorService
            );
          });
        }
        this.fetchOfferSteps();
      },
      (error) => {
        this.loader.hide();
        this.alertService.error(error);
      }
    );
  }

  fetchOfferSteps() {
    this.funnelStepService.getOfferSteps().subscribe(
      (response) => {
        if (response.length) {
          this.offerSteps = response;
          this.offerSteps.forEach((step) => {
            step.loading = false;
          });
          this.populateTableData();
          this.stepsReordering = false;
        } else {
          this.offerSteps = [];
          this.stepsReordering = false;
          this.loader.hide();
        }
      },
      (error) => {
        this.loader.hide();
        this.alertService.error(error);
      }
    );
  }

  populateTableData() {
    this.tableData = this.offerSteps.map((item: any) => {
      this.loader.hide();
      const lastModifiedMoment = moment(item.last_modified).tz('EST');
      const lastModifiedObject = {
        date: lastModifiedMoment.format('DD MMM, YYYY'),
        time: lastModifiedMoment.format('HH:mm:ss z'),
      };

      const stepInput = this.getStepInput(item.id);

      return {
        id: item.id,
        stepName: item.name,
        status: stepInput.enabled
          ? StepStatuses.Enabled
          : !stepInput.enabled
          ? StepStatuses.Disabled
          : StepStatuses.Draft,
        type: StepCategoryLabels[item.category],
        stepInput: stepInput,
        lastModified: lastModifiedObject,
        visitors: item.visitors || 0,
        category: item.category,
        clicks: item.clicks || 0,
        takeRates: item.clicks && item.visitors ? (item.clicks / item.visitors) * 100 : 0,
        averageTime: item.averageTime || '--', // not getting this from backend
        usages: item.path_names.length
          ? `${item.path_names.length} ${item.path_names.length > 1 ? 'Paths' : 'Path'}`
          : '0 Paths',
        displayId: item.display_id,
      };
    });
    this.originalTableData = this.tableData;
  }

  onStatusChange(event: any): void {
    const status = +event.target.value;
    if (status === StepStatuses.All) {
      this.tableData = [...this.originalTableData];
      this.offerSteps = [...this.originalOfferSteps];
    } else {
      this.tableData = this.originalTableData.filter((item) => item.status === status);
      this.offerSteps = this.originalOfferSteps.filter((step) => this.tableData.find((item) => item.id === step.id));
    }
  }

  getCampaigns() {
    this.campaignService.list({}).subscribe(
      (data: Pager) => {
        this.campaigns = data.results;

        if (this.storageService.get('selectedStepCampaignId')) {
          this.selectedCampaignId = this.storageService.get('selectedStepCampaignId');
        } else {
          this.selectedCampaignId = this.campaigns[0].id;
          this.storageService.set('selectedStepCampaignId', this.selectedCampaignId);
        }

        this.getStyles();

        if (this.storageService.get('selectedStepProduct') && this.storageService.get('selectedStepProduct').id) {
          setTimeout(() => {
            this.onProductSelected(this.storageService.get('selectedStepProduct'));
          }, 1000);
        }
        this.campaignsLoading = false;
      },
      (error) => {
        this.campaignsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  getStyles() {
    this.styleService.list({ page: 1, page_size: config.maxPageSize }).subscribe((data: Pager) => {
      this.themes = data.results;
      this.updateBrandTheme();
    }),
      (err) => this.alertService.error(err.message);
  }

  updateBrandTheme() {
    this.selectedCampaign = null;

    if (!this.selectedCampaignId) {
      this.theme = defaultThemeColor;
    } else {
      let styleId, theme;
      this.storageService.set('selectedStepCampaignId', this.selectedCampaignId);
      this.campaignService.get(this.selectedCampaignId).subscribe(
        (campaign: Campaign) => {
          if (campaign) {
            this.selectedCampaign = campaign;
            if (campaign.styles && campaign.styles.length) {
              styleId = campaign.styles[0];
            }
          }

          if (styleId && this.themes.length > 0) {
            theme = this.themes.find((theme) => theme.id === styleId).variables.theme_color;
          }

          if (theme) {
            this.theme = theme;
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    }
  }

  toggleStepStatus(stepInputId: number, hasInputStep: boolean = false, isEnabled?: boolean): void {
    if (!hasInputStep) {
      const stepInput = this.getStepInput(stepInputId);
      this.toggleStatus(stepInput.id, stepInput.enabled);
    } else {
      this.toggleStatus(stepInputId, isEnabled);
    }
  }

  toggleStatus(id: number, currentStatus: boolean): void {
    this.inputService.patch(id, { enabled: !currentStatus }).subscribe(
      () => {
        this.fetchOfferSteps();
        this.alertService.success('Step status updated successfully', true);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  isStepEnabled(stepId: number): boolean {
    const stepInput = this.getStepInput(stepId);
    return stepInput.enabled;
  }

  getStepInput(stepId: number) {
    const offerStep = this.offer.offer_steps.find(({ id }) => id === stepId);
    if (offerStep) {
      return offerStep.inputs.find(({ has_response_step }) => has_response_step);
    }
  }

  generateBreadcrumbs() {
    this.breadcrumbs = [
      {
        label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
        url: ['steps', this.offerName],
      },
      {
        label: '',
      },
    ];
  }

  openPopup(popupType: number, stepId?: number | string, stepName?: string): void {
    this.popupTitle = this.stepPopupTitleMap[popupType] || '';
    const stepResponse = this.offerSteps.find((step) => step.id === stepId);
    this.popupData = {
      ...this.popupData,
      name: stepName || '',
      step_id: stepId || null,
      offer_id: this.offerId || null,
      step_response: stepResponse || {},
      popupType: popupType,
    };

    if (popupType === StepPopupActions.Duplicate) {
      this.popupData['parent_input'] = this.getParentInputForNewStep();
    }
    this.ngxSmartModalService.getModal('stepPopup').open();
  }

  getParentInputForNewStep() {
    let parent_input = null;
    if (this.offer.offer_steps && this.offer.offer_steps.length > 0) {
      const lastStepIndex = this.offer.offer_steps.length - 1;
      if (this.offer.offer_steps[lastStepIndex].inputs && this.offer.offer_steps[lastStepIndex].inputs.length > 0) {
        this.offer.offer_steps[lastStepIndex].inputs.forEach((input) => {
          if (!input.has_response_step) {
            parent_input = input.id;
          }
        });
      }
    }

    return parent_input;
  }

  closeStepPopup() {
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  saveStep(): void {
    this.loader.show();
    this.fetchOfferSteps();
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  saveTemplate(event: { use_existing_template: boolean }): void {
    this.ngxSmartModalService.getModal('templatePopup').close();
    if (this.offerType === OfferTypes.Downsell) {
      this.router.navigate([
        'steps',
        this.offerName,
        this.popupData.offer_id,
        'category',
        this.offerType,
        'intent',
        this.popupData.offer_intent,
        'useExisting',
        event.use_existing_template,
      ]);
    } else {
      this.router.navigate([
        'steps',
        this.offerName,
        this.popupData.offer_id,
        'category',
        this.offerType,
        'useExisting',
        event.use_existing_template,
      ]);
    }
  }

  openTemplatePopup() {
    this.popupData = {
      ...this.popupData,
      offer_id: this.offerId,
      offer_intent: this.offer.offer_intent,
      popupType: StepPopupActions.CreateTemplate,
    };
    this.ngxSmartModalService.getModal('templatePopup').open();
  }

  closeTemplatePopup() {
    this.ngxSmartModalService.getModal('templatePopup').close();
  }

  openProductSelector() {
    this.ngxSmartModalService.getModal('selectProductsDialog').open();
  }

  onProductSelected(product: CampaignProduct) {
    if (product) {
      this.selectedProduct = product;
      this.storageService.set('selectedStepProduct', product);
      this.productPlaceholderUrl = this.selectedProduct.id ? getCampaignProductImageUrl(this.selectedProduct) : null;
    }
    this.ngxSmartModalService.getModal('selectProductsDialog').close();
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  navigateToStepBuilder(category: number, stepId: number) {
    this.router.navigate(['steps', this.offerName, this.offerId, 'category', this.offerType, category, 'step', stepId]);
  }

  openPreview(stepId: number) {
    const productId = (this.selectedProduct && this.selectedProduct.id) || null;
    const offerStep = this.offerSteps.find((step) => step.id === stepId);
    const step = this.offer.steps.find((offer_step) => offer_step.id === stepId);
    offerStep.loading = true;

    this.funnelStepService.preview(this.selectedCampaignId, step, productId).subscribe(
      (data: string) => {
        window.open(data, '_blank');
        offerStep.loading = false;
      },
      (error) => {
        this.alertService.error(error);
        offerStep.loading = false;
      }
    );
  }

  toggleTable() {
    this.isTableVisible = !this.isTableVisible;
  }

  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  closeOutside() {
    if (this.tableData) {
      this.tableData.forEach((item) => {
        item.showThreeDotMenu = false;
      });
    }
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }

  setHoveredStep(id: string) {
    this.hoveredStepIndex = id;
  }
  clearHoveredStep() {
    this.hoveredStepIndex = null;
  }

  navigateToStepTemplates() {
    if (this.offerType === OfferTypes.Downsell)
      this.router.navigate([
        'steps',
        this.offerName,
        this.offerId,
        'category',
        this.offerType,
        'intent',
        this.offer.offer_intent,
      ]);
    else this.router.navigate(['steps', this.offerName, this.offerId, 'category', this.offerType]);
  }

  private getSanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  get destroy$() {
    return this._destroy$.asObservable();
  }

  getProductName(productName: string): string {
    return getTruncatedProductName(productName);
  }

  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete]: '/assets/stepAssets/delete-icon.svg',
    [StepPopupActions.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [StepPopupActions.Update]: '/assets/stepAssets/rename.svg',
  };

  convertToTemplate(stepId: string | number, is_global: boolean = false) {
    this.loader.show();
    this.funnelStepService.convertToTemplate(stepId, is_global).subscribe(
      (_) => {
        this.loader.hide();
        this.alertService.success('Step converted to template successfully');
      },
      (error) => {
        this.loader.hide();
        this.handleError(error);
      }
    );
  }

  drop(event: CdkDragDrop<any[]>): void {
    if (!this.stepsReordering) {
      const previousIndex = this.offer.offer_steps.findIndex(
        (step) => step.id === this.tableData[event.previousIndex].id
      );
      const currentIndex = this.offer.offer_steps.findIndex(
        (step) => step.id === this.tableData[event.currentIndex].id
      );

      moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
      moveItemInArray(this.offer.offer_steps, previousIndex, currentIndex);

      this.offer.offer_steps.forEach((step, index) => {
        step.is_first_step = index === 0;
        const inputIndex = step.inputs.findIndex((input) => !input.has_response_step);
        if (inputIndex !== -1) {
          if (index < this.offer.offer_steps.length - 1) {
            step.inputs[inputIndex].next_step = this.offer.offer_steps[index + 1].id;
          } else {
            step.inputs[inputIndex].next_step = null;
          }
        }
      });

      const data = {
        id: this.offerId,
        resourcetype: FunnelType.Product,
        steps: this.offer.offer_steps.map((step) => ({
          id: step.id,
          is_first_step: step.is_first_step,
          inputs: step.inputs.map((input) => ({
            id: input.id,
            next_step: input.next_step,
          })),
        })),
      };

      this.stepsReordering = true;
      this.funnelService.drop(this.offerId, data).subscribe(
        (_) => {
          this.alertService.success('Steps reordered successfully');
          this.fetchOfferSteps();
        },
        (error) => {
          this.stepsReordering = false;
          this.handleError(error);
        }
      );
    }
  }

  isStepLoading(stepId: number): boolean {
    const step = this.offerSteps.find((s) => s.id === stepId);
    return step ? step.loading : false;
  }

  get visibleSteps() {
    if (!this.offer || !this.offer.offer_steps) {
      return [];
    }
    return this.offer.offer_steps.slice(this.currentIndex, this.currentIndex + this.stepsPerView);
  }

  get hasMoreSteps() {
    if (!this.offer || !this.offer.offer_steps) {
      return false;
    }
    return this.currentIndex + this.stepsPerView < this.offer.offer_steps.length;
  }

  next() {
    if (this.hasMoreSteps) {
      this.currentIndex++;
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  updateStepsPerView() {
    const width = window.innerWidth;
    if (width > 2150) {
      this.stepsPerView = 5;
    } else if (width > 1770) {
      this.stepsPerView = 4;
    } else if (width > 1400) {
      this.stepsPerView = 3;
    } else if (width > 720) {
      this.stepsPerView = 2;
    } else {
      this.stepsPerView = 1;
    }
  }

  private addResizeListener() {
    window.addEventListener('resize', this.debouncedUpdateStepsPerView.bind(this));
  }

  private debouncedUpdateStepsPerView = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updateStepsPerView();
    }, 250);
  };

  isFirstStep(item: any): boolean {
    if (!this.offer || !this.offer.offer_steps || !this.offer.offer_steps.length) return true;
    return this.offer.offer_steps[0].id === item.id;
  }

  isLastStep(item: any): boolean {
    if (!this.offer || !this.offer.offer_steps || !this.offer.offer_steps.length) return true;
    return this.offer.offer_steps[this.offer.offer_steps.length - 1].id === item.id;
  }

  moveToPosition(item: any, position: 'top' | 'bottom') {
    if (!(position === 'top' && this.isFirstStep(item)) && !(position === 'bottom' && this.isLastStep(item))) {
      const currentIndex = this.offer.offer_steps.findIndex((step) => step.id === item.id);
      const targetIndex = position === 'top' ? 0 : this.offer.offer_steps.length - 1;

      this.drop({
        previousIndex: currentIndex,
        currentIndex: targetIndex,
        container: null,
        item: null,
        previousContainer: null,
        isPointerOverContainer: false,
        distance: { x: 0, y: 0 },
      });
    }
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.debouncedUpdateStepsPerView.bind(this));
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }
  }
}
