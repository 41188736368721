import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {Product} from "../_models";
import {Form} from '../_forms';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-widgets.component.html',
  selector: 'product-widgets',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class ProductWidgetsComponent extends Form implements OnInit, OnChanges {
  @Input('productIds') productIds: (string | number)[];
  @Input('allowUpdate') allowUpdate: boolean = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(): void {
  }
}
