import { Component, Input } from "@angular/core";

export interface DefaultTableRecord {
  id?: string;
  name: string;
  value: any;
  percentage?: number;
  is_negative?: boolean;
  visitors?: string;
  sales?: string;
  revenue?: string;
  hint?: string;
}

export interface InlineTableRecord {
  name: string;
  value?: any;
  id?: string;
  percentage?: string;
  color?: string;
}

export type DefaultTableData = DefaultTableRecord[];
export type InlineTableData = InlineTableRecord[];
export type dataTableType = "INLINE" | "DEFAULT";

@Component({
  selector: "data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent {
  @Input("inlineTableData") inlineTableData: InlineTableData[] = null;
  @Input("defaultTableData") defaultTableData: DefaultTableData[] = null;
  @Input("defaultTableHeaders") defaultTableHeaders: string[] = null;
  @Input("tableType") tableType: dataTableType;
  @Input("isReport") isReport = false;
}
