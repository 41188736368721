import { CrudPagedListComponent } from '../_directives';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {AlertService, FunnelService, LoaderService, PusherService, UserService} from '../_services';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {
  Path,
  PathDateTypes,
  PathPopup,
  PathStatus,
  PathTableTypes,
  PathTableStatusLabels,
  PathTableStatuses,
} from '../_models/funnel';
import { PathService } from '../_services/path.service';
import {Audience, NotificationType, OfferIntentLabels, OfferIntentParents, OfferIntents, Pager} from '../_models';
import { AudienceService } from '../_services/audience.service';
import { config } from '../../config/config';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss'],
  selector: 'app-path-list',
})
export class PathComponent extends CrudPagedListComponent implements OnInit {
  pathsList: Path[] = [];
  pageTitle: string = 'Customer Paths';
  showModal: boolean = false;
  pathStatus = PathStatus;
  pathDateTypes = PathDateTypes;
  pathTableTypes = PathTableTypes;
  showThreeDotMenu: boolean = false;
  selectedRow: number = -1;
  selectedStatus: string = PathTableStatusLabels[PathTableStatuses.All];
  selectedFilter: string = 'name';

  selectedDate: string = PathDateTypes.LastModified;
  searchQuery: string;
  showAddButton: boolean = true;
  showViewButton: boolean = true;
  selectedIndex: number | null = null;
  tableData: any[] = [];
  popupData = {
    name: '',
    path_id: null,
    popupType: null,
    offer_intent: OfferIntents.CancelOrder,
    path_steps: [],
    path_audience: null,
  };
  originalTableData: any[] = [...this.tableData];
  isLoading = false;
  PathPopup = PathPopup;
  popupTitle: string = '';

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected pathService: PathService,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected loader: LoaderService,
    protected ngxSmartModalService: NgxSmartModalService,
    protected audienceService: AudienceService,
    protected pusherService: PusherService,
  ) {
    super(router, location, route, pathService, alertService);
    this.objectName = 'Path';
  }
  ngOnInit() {
    super.ngOnInit();
    this.queryData();
    this.loader.show();
    this.fetchPathList();

    this.pusherService.channel.bind(NotificationType[NotificationType.PATH_PUBLISHING], (data: any) => {
      data = data.payload;
      if (data && data.is_completed) {
        this.fetchPathList()
      }
    });
  }

  fetchPathList() {
    this.pathService.list({}).subscribe(
      (response) => {
        this.loader.hide();
        this.pathsList = response.results;
        this.tableData = response.results.map((item: Path) => {
          if (item.offer_intent in OfferIntentParents) {
            item.offer_intent = OfferIntentParents[item.offer_intent]
          }
          const lastModifiedMoment = moment(item.last_modified).tz('EST');

          const lastModified = {
            date: lastModifiedMoment.format('DD MMM, YYYY'),
            time: lastModifiedMoment.format('HH:mm:ss z'),
          };

          return {
            id: item.id,
            pathName: item.name,
            offerIntent: item.offer_intent,
            allSteps: item.steps,
            type: OfferIntentLabels[item.offer_intent] || '--',
            status:
              !item.is_modified
                ? PathStatus.Active
                : PathStatus.Draft,
            audience: item.audience || null,
            steps: item.steps.map((step) => step.name) || [],
            lastModified,
            showAllConditions: item.steps.length ? false : true,
          };
        });
        this.originalTableData = this.tableData;
        this.filterTableData();
      },
      (error) => {
        this.loader.hide();
        this.alertService.error(error.message);
      }
    );
  }

  createPath() {
    this.router.navigate(['new-paths', 'new']);
  }

  editPath(id: number) {
    this.router.navigate(['new-paths', 'edit', id]);
  }

  openPathPopup(
    popupType: number,
    pathId?: number | string,
    pathName?: string,
    offerIntent?: number,
    pathSteps?: { id: number; name: string }[] | null,
    pathAudience?: string | null
  ): void {
    switch (popupType) {
      case PathPopup.Update:
        this.popupTitle = 'Rename Path';
        break;
      case PathPopup.Duplicate:
        this.popupTitle = 'Duplicate Path';
        break;
      case PathPopup.Delete:
        this.popupTitle = 'Delete Path';
        break;
    }

    if (pathAudience) {
      this.audienceService
        .list({ page: 1, page_size: config.maxPageSize })
        .subscribe(
          (result: Pager) => {
            const audienceObject = result.results.find(
              (audience: Audience) => audience.name === pathAudience
            );
            this.popupData.path_audience = audienceObject.id || null;
          },
          (error) => {
            this.handleError(error);
          }
        );
    } else {
      this.popupData.path_audience = null;
    }

    this.popupData.name = pathName || '';
    this.popupData.path_id = pathId || null;
    this.popupData.popupType = popupType;
    this.popupData.offer_intent = offerIntent;
    this.popupData.path_steps = pathSteps && pathSteps.length ? pathSteps : [];
    this.openPopup();
  }

  openPopup() {
    this.ngxSmartModalService.getModal('pathPopup').open();
  }
  closePathPopup() {
    this.ngxSmartModalService.getModal('pathPopup').close();
  }

  savePath() {
    this.fetchPathList();
    this.ngxSmartModalService.getModal('pathPopup').close();
  }

  filterData() {
    this.tableData =
      this.selectedStatus === PathTableStatusLabels[PathTableStatuses.All]
        ? [...this.originalTableData]
        : this.originalTableData.filter(
            (item) => item.status.toLowerCase() === this.selectedStatus
          );
  }

  filterTableData() {
    const filteredData = [...this.originalTableData];
    switch (this.selectedFilter) {
      case 'name':
        filteredData.sort((a, b) => a.pathName.localeCompare(b.pathName));
        break;
      case 'type':
        filteredData.sort((a, b) => a.type.localeCompare(b.type));
        break;
      case 'status':
        filteredData.sort((a, b) => a.status.localeCompare(b.status));
        break;
      case 'audience':
        filteredData.sort((a, b) => a.audience.localeCompare(b.audience));
        break;
      case 'lastModified':
        this.selectedDate = 'lastModified';
        filteredData.sort((a, b) => {
          const dateA = moment(
            a.lastModified.date + ' ' + a.lastModified.time,
            'DD MMM, YYYY HH:mm'
          );
          const dateB = moment(
            b.lastModified.date + ' ' + b.lastModified.time,
            'DD MMM, YYYY HH:mm'
          );
          return dateB.diff(dateA);
        });
        break;
      default:
        break;
    }
    this.tableData = filteredData;
  }

  searchData() {
    this.tableData = this.originalTableData.filter((item) =>
      item.pathName.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }
  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }
  toggleShowAllConditions(item: any): void {
    item.showAllConditions = !item.showAllConditions;
  }

  togglePathList(index: number): void {
    if (this.selectedRow === index) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = index;
    }
  }

  closePathList() {
    this.selectedRow = -1;
    this.tableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }

  popupIconMap: Partial<Record<PathPopup, string>> = {
    [PathPopup.Delete]: '/assets/stepAssets/delete-icon.svg',
    [PathPopup.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [PathPopup.Update]: '/assets/stepAssets/rename.svg',
  };
}
