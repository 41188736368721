export interface CallLogs {
  direction: string,
  duration: string,
  end_time: string,
  from_: string,
  start_time: string,
  status: string,
  to: string
}

export interface SmsLogs {
  body: string,
  date_sent: string,
  direction: string,
  error_code: string,
  error_message: string,
  from_: string,
  status: string,
  to: string
}

export interface MailLogs {
  hap: string,
  created_at: string,
  type: string,
  message: string,
  message_id: string
}

export enum LogType {
  PhoneLogs = 1,
  EmailLogs = 2,
  SmsLogs = 3
}
