import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, EmailTemplateService, FunnelStepService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {EmailTemplate, EmailTemplateType, getEmailPathVariables} from "../_models";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'EmailTemplateCreate',
  moduleId: module.id.toString(),
  templateUrl: './email-template-edit.component.html'
})
export class EmailTemplateNewComponent extends CrudSaveComponent implements OnInit {
  template: EmailTemplate;
  defaultCampaignId = null;
  pathVariables = getEmailPathVariables();
  stepList: any[];
  showEditors = false;
  @Input('templateType') type: EmailTemplateType = null
  @Output('actionCompleted') actionCompleted: EventEmitter<EmailTemplate> = new EventEmitter<EmailTemplate>()
  @Input('emitOnBack') emitOnBack = false
  @Input('index') index = 0;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected templateService: EmailTemplateService,
    protected alertService: AlertService,
    protected stepService: FunnelStepService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, templateService, alertService);
    this.isNew = true;
    this.objectName = 'Email Template';
    this.title = 'Create New Email Template';
  }

  ngOnInit() {
    this.defaultCampaignId = this.route.snapshot.params['campaign_id'] || null;

    if (!this.type) {
      this.type = this.route.snapshot.params['type'] || EmailTemplateType.Match;
    }

    this.form = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required],
      type: [this.type, Validators.required],
      subject: [null, Validators.required],
      body: [null, Validators.required],
      campaign: [this.defaultCampaignId]
    });

    super.ngOnInit();

    this.stepService.getGlobalJumpLinks(false, {resourcetype: 'VisualFunnel'})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        stepList => {
          this.stepList = stepList;
          this.showEditors = true;
        },
        error => {
          this.handleError(error);
        }
      );

    if (this.route.snapshot.params['load_default']) {
      this.populateDefaultInboundResponseContent();
    }
  }

  test() {
    if (this.form.valid) {
      this.loading = true;

      let params = {
        subject: this.form.value.subject,
        body: this.form.value.body,
        campaign: this.form.value.campaign
      };

      this.templateService.test(params)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          () => {
            this.alertService.success('Test message sent.');
            this.loading = false;
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  goBack() {
    if (this.emitOnBack) {
      this.actionCompleted.emit()
    } else {
      super.goBack()
    }
  }

  protected onSaveComplete(data) {
    this.loading = false
    if (this.emitOnBack) {
      this.actionCompleted.emit(data)
    } else {
      super.onSaveComplete(data)
    }
  }

  populateDefaultInboundResponseContent() {
    this.loading = true
    this.templateService.getDefaultInboundResponse()
      .subscribe((data: EmailTemplate) => {
        if (data) {
          this.loading = false
          this.form.patchValue({subject: data.subject, body: data.body});
        }
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

}
