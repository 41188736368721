import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelActionService, FunnelService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {FunnelActionNewComponent} from "./funnel-action-new.component";
import {Action, ActionTypeLabels} from "../_models";

@Component({
  templateUrl: './funnel-action-edit.component.html',
  styleUrls: ['./funnel-action.component.css']
})
export class FunnelActionEditComponent extends FunnelActionNewComponent implements OnInit {

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected actionService: FunnelActionService,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, actionService, alertService, funnelService, formBuilder);
    this.isNew = false;
    this.title = 'Edit Path Input Action';
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe(
      (action: Action) => {
        if (action) {
          this.action = action;
          this.objectName = ActionTypeLabels[this.action.resourcetype] + ' Action';
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

}
