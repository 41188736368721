import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from './pagination.service';
import {CampaignProduct} from '../_models';
import {ClipboardService} from './clipboard.service';

@Injectable()
export class AudienceService extends PaginationService {
  constructor(protected http: HttpClient, protected clipboardService: ClipboardService) {
    super(http, 'audiences');
  }

  copyCampaignProducts(products: CampaignProduct[]) {
    this.clipboardService.copy('campaign_products', products);
  }

  canPasteCampaignProducts() : boolean {
    return this.clipboardService.canPaste('campaign_products');
  }

  pasteCampaignProducts() {
    return this.clipboardService.paste('campaign_products');
  }

  add_campaign_products(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/add_campaign_products/', data)
  }

  remove_campaign_product(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/remove_campaign_product/', data)
  }

  publish(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/publish/', {});
  }
}
