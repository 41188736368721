import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CheckoutProcessorNewComponent } from './checkout-processor-new.component';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import {
  AlertService,
  CheckoutBankService,
  CheckoutProcessorService,
  ProcessorDocumentService,
  RegionService,
} from '../../../_services';
import { FormBuilder } from '@angular/forms';
import { Bank, Processor } from '../../../_models';
import { config } from '../../../../config/config';
import { Pager } from '../../../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-checkout-processor-edit',
  templateUrl: './checkout-processor-edit.component.html',
  styleUrls: ['./checkout-processor-edit.component.scss', '../profiles-styles.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutProcessorEditComponent extends CheckoutProcessorNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected processorService: CheckoutProcessorService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected bankService: CheckoutBankService,
    protected documentService: ProcessorDocumentService,
    protected modalService: NgxSmartModalService,
    protected regionService: RegionService
  ) {
    super(
      router,
      location,
      route,
      processorService,
      alertService,
      formBuilder,
      bankService,
      modalService,
      documentService,
      regionService
    );
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.loading = true;
    this._data$.subscribe((processor: Processor) => {
      if (processor) {
        this.isExclude = processor.exclude_types;
        this.bank = processor;
        Object.keys(this.fields_to_permission_types_map).forEach((field_name) => {
          this.selected_permissions[this.fields_to_permission_types_map[field_name]] = this.bank[field_name];
        });

        // @ts-ignore
        processor.acquiring_banks = this.acquiring_banks.filter(
          (item) => processor.acquiring_banks.indexOf(item['id']) > -1
        );
        this.acquiredBanks = processor.acquiring_banks;
        this.form.patchValue(processor);

        this.documentService.setProcessorId(processor.id);
        this.documentService.list({ page: 1, page_size: config.maxPageSize }).subscribe(
          (data: Pager) => {
            if (data) {
              this.documents = data.results;
              this.selectedDocumentIndex = data.results.length;
            }
          },
          (error) => {
            this.handleError(error);
          }
        );
        this.loading = false;
      }
    });
  }
}
