import { BaseModel, ContactType } from './index';

export enum ActionType {
  Discount = 'DiscountAction',
  BillingCycle = 'BillingCycleAction',
  AddProduct = 'AddProductAction',
  Note = 'NoteAction',
  Notification = 'NotificationAction',
  CloseTicket = 'CloseTicketAction',
  Cancel = 'CancelAction',
  SMS = 'SMSAction',
  ExecuteDelayed = 'ExecuteDelayedAction',
  CancelDelayed = 'CancelDelayedAction',
  MatchCustomer = 'MatchCustomerAction',
  ChangeQuantity = 'ChangeQuantityAction',
  ChangeProduct = 'ReplaceProductAction',
  ChangeVariant = 'ChangeVariantAction',
  Refund = 'RefundAction',
  RMA = 'RMAAction',
  CancelSubscription = 'CancelSubscriptionAction',
  Checkout = 'CheckoutAction',
  Rebill = 'RebillAction',
  ExtendReturn = 'ExtendReturnAction',
  Reactivate = 'ReactivateAction',
  DiscountHistory = 'DiscountActionHistory',
  BillingCycleHistory = 'BillingCycleActionHistory',
  AddProductHistory = 'AddProductActionHistory',
  NoteHistory = 'NoteActionHistory',
  NotificationHistory = 'NotificationActionHistory',
  CloseTicketHistory = 'CloseTicketActionHistory',
  CancelHistory = 'CancelActionHistory',
  SMSHistory = 'SMSActionHistory',
  ExecuteDelayedHistory = 'ExecuteDelayedActionHistory',
  CancelDelayedHistory = 'CancelDelayedActionHistory',
  MatchCustomerHistory = 'MatchCustomerActionHistory',
  ChangeQuantityHistory = 'ChangeQuantityActionHistory',
  ChangeProductHistory = 'ReplaceProductActionHistory',
  ChangeVariantHistory = 'ChangeVariantActionHistory',
  RefundHistory = 'RefundActionHistory',
  RMAHistory = 'RMAActionHistory',
  CancelSubscriptionHistory = 'CancelSubscriptionActionHistory',
  CheckoutHistory = 'CheckoutActionHistory',
  RebillHistory = 'RebillActionHistory',
  ExtendReturnHistory = 'ExtendReturnActionHistory',
  ReactivateHistory = 'ReactivateActionHistory',
}

export const FunnelActionTypeLabels = {
  DiscountAction: 'Apply Order Item Discount',
  BillingCycleAction: 'Change Billing Cycle',
  AddProductAction: 'Add Upsale Product',
  NoteAction: 'Add Customer Note to CRM',
  NotificationAction: 'Notify Support',
  CloseTicketAction: 'Close Support Ticket',
  CancelAction: 'Cancel Order Item',
  SMSAction: 'Send SMS Message',
  ExecuteDelayedAction: 'Execute Delayed Actions',
  CancelDelayedAction: 'Cancel Delayed Actions',
  MatchCustomerAction: 'Match Customer to CRM',
  ChangeQuantityAction: 'Change Order Item Quantity',
  ChangeVariantAction: 'Select Item Variants',
  ReplaceProductAction: 'Change Order Product',
  RefundAction: 'Apply Refund to Order',
  RMAAction: 'Return Order Item',
  CancelSubscriptionAction: 'Cancel Subscription',
  CheckoutAction: 'Checkout',
  RebillAction: 'Rebill Declined Subscription',
  ExtendReturnAction: 'Extend Return Date',
  ReactivateAction: 'Reactivate Subscription',
};

let actionLabels = {};

Object.entries(FunnelActionTypeLabels).forEach(([key, label]) => {
  actionLabels[key] = label;
  actionLabels[key + 'History'] = label;
});
export const ActionTypeLabels = actionLabels;

export enum DiscountTypeEnum {
  Fixed = 1,
  Percent = 2,
  Index = 3,
  IndexOrFixed = 4,
  IndexOrPercent = 5,
  PercentOfOrder = 6
}

export enum ExecutionTypeEnum {
  Immediate = 0,
  Delayed = 1,
  ValidationOnly = 2
}

export enum QuantityTypeEnum {
  Any = 1,
  None = 2,
  LowerOnly = 3,
  HigherOnly = 4
}

export enum BillDateDelayFrom {
  Today = 1,
  NextBillDate = 2,
}

export enum RefundType {
  NormalAfterCredit = 1,
  NormalReduceCredit = 2,
  CreditOnly = 3,
}

export interface Action extends BaseModel {
  resourcetype: ActionType;
  execution_type: ExecutionTypeEnum;
  price: number;
  shipping_price: number;
  discount: number;
  shipping_discount: number;
  discount_type: DiscountTypeEnum;
  discount_index: number;
  product_index: number;
  upsale_index: number;
  exchangeable_index: number;
  billing_interval_days: number;
  next_bill_date_delay: number;
  max_bill_date_delay: number;
  final_billing_cycle_offset: number;
  bill_now: boolean;
  reason: string;
  message: string;
  reship: boolean;
  refund_original_shipping: boolean;
  charge_restock_fee: boolean;
  return_shipping_refund: number;
  contact_type: ContactType;
  quantity_type: QuantityTypeEnum;
  pause: boolean;
  cancel_subscription: boolean;
  apply_refund: boolean;
  check_last_discount: boolean;
  call_center_action: string | number;
  holdoff_delay: number;
  return_days: number;
  exchangeable_set: string[] | number[];
  exchangeable_set_types: string[] | number[];
  third_party: boolean;
  void_authorized_transaction: boolean;
  include_tax: boolean;
  subscription_only: boolean;
  bill_date_delay_from: BillDateDelayFrom;
  refund_type: RefundType;
  message_delay: number;
}
