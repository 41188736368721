import {Component, OnInit} from '@angular/core';
import {CrudListComponent} from "../_directives";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, FaqService, FaqTopicService} from '../_services';
import {takeUntil} from "rxjs/operators";
import {FaqTopic} from "../_models";
import {Observable} from "rxjs";

@Component({
  moduleId: module.id.toString(),
  selector: 'faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent extends CrudListComponent implements OnInit {
  faqTopics$: Observable<FaqTopic[]> = this.data$;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected topicService: FaqTopicService,
    protected faqService: FaqService,
  ) {
    super(router, location, route, topicService, alertService);
    this.objectName = 'FAQ topic';
    this.title = 'FAQs';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeFilter() {
    this.filter = {'parent__isnull': true}; //only show top level topics
  }

  copyTopic(topic: FaqTopic) {
    this.topicService.copy(topic.id);
  }

  canPasteTopic() {
    return this.topicService.canPaste();
  }

  pasteTopic() {
    this.loading = true
    this.topicService.paste(null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (topic: FaqTopic) => {
          this.loading = false
          this._data$.getValue().push(topic);
        },
        error => {
          this.loading = false
          this.handleError(error);
        }
      );
  }

  syncFaqs(){
    this.faqService.sync().subscribe(
      (response) => {
        this.alertService.success('Sync completed successfully.', true)
      },
      (error) => {
        this.handleError(error);
      }
    );
  };
}
