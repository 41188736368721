import {Component, Input, OnInit} from "@angular/core";
import {
  AutoResponder,
  AutoResponderType,
  CampaignAutoResponderTrigger,
  CampaignAutoResponderTriggersList, Pager
} from "../_models";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AutoResponderService} from "../_services";
import {config} from "../../config/config";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './autoresponder-triggers.component.html',
  selector: 'autoresponder-triggers'
})
export class AutoresponderTriggersComponent implements OnInit{
  @Input('type') autoresponderType : AutoResponderType.SMS | AutoResponderType.Email = AutoResponderType.Email;
  @Input('triggers') triggers: CampaignAutoResponderTrigger[] = []
  @Input('campaignId') campaignId: string | number = null;
  @Input('controls') formControls: FormArray;
  public autoresponders: AutoResponder[] = [];
  public CampaignAutoResponderTriggersList = CampaignAutoResponderTriggersList;
  constructor(protected formBuilder: FormBuilder, protected responderService: AutoResponderService) {
  }
  ngOnInit() {
    CampaignAutoResponderTriggersList.forEach((_, index) => {
      let trigger_type = index + 1;
      let autoresponderId = null
      this.triggers.forEach((trigger: CampaignAutoResponderTrigger) => {
        if (trigger.campaign === this.campaignId && trigger.trigger_type === trigger_type &&
          trigger.autoresponder_type === this.autoresponderType && trigger.autoresponder) {
          autoresponderId = trigger.autoresponder
        }
      })
      this.formControls.push(this.formBuilder.group({
        campaign: [this.campaignId, Validators.required],
        autoresponder: [autoresponderId, null],
        trigger_type: [trigger_type, Validators.required],
        autoresponder_type: [this.autoresponderType, Validators.required]
      }))
    })
    this.responderService.list({page: 1, page_size: config.maxPageSize,
      type: this.autoresponderType})
      .subscribe((data: Pager) => {
        this.autoresponders = data.results;
      })
  }

  getTriggerTypeFieldValue(control: FormGroup) {
    return control.get('trigger_type').value
  }

  getAutoResponderFieldLabel(control: FormGroup) {
    return this.CampaignAutoResponderTriggersList[this.getTriggerTypeFieldValue(control) - 1]
  }

  getAutoResponderControl(control: FormGroup){
    return control.get('autoresponder')
  }
}
