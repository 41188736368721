import { BaseModel, CRMField, CRMFieldSet } from './index';

export const EmailVariables = {
  first_name: 'Customer First Name',
  campaign_name: 'Brand Name',
  campaign_url: 'Session URL'
};

export function getEmailPathVariables() {
  let items = [];

  Object.keys(EmailVariables).forEach(key => {
    items.push({value: '{' + key + '}', text: EmailVariables[key]})
  });

  return items;
}

export enum EmailTemplateType {
  Match = 0,
  NoMatch = 1,
  AutoResponder = 2
}

export interface EmailTemplate extends BaseModel {
  name: string;
  type: EmailTemplateType;
  subject: string;
  body: string;
  campaign: string | number;
  is_system: boolean;
}

export interface EmailTemplateTest {
  subject: string;
  body: string;
  campaign: string | number;
}

export enum TicketSystemTypeName {
  Gorgias = 1
}
export interface TicketSystem extends BaseModel {
  display_name: string;
  credentials: {[key: string]: string;};
  config: {[key: string]: string;};
  type: TicketSystemTypeName
}
export interface TicketSystemField extends CRMField {}
export interface TicketSystemType extends BaseModel {
  name: string;
  fields: TicketSystemField[];
  encrypted_fields: TicketSystemField[];
}
export const TicketSystemIDVariables = {}
TicketSystemIDVariables[TicketSystemTypeName.Gorgias] = '{{ticket.id}}'
