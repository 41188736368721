import { Component, OnInit } from "@angular/core";
import { DashboardFilter, InfoCards, User } from "../../_models";
import {
  campaignConversions,
  marketplaceDoughnutData,
  marketplaceInfoCards,
  marketplaceTableData,
  productConversions,
  visitors,
  marketplaceWatchlist,
  marketplaceHints,
  saleResponses,
} from "./marketplace.data";
import { InlineTableData } from "../data-visualization/data-table/data-table.component";
import { DoughnutChartData } from "../data-visualization/doughnut-chart/doughnut-chart.component";
import { StorageService } from "../../_services";

@Component({
  selector: "app-marketplace",
  templateUrl: "./marketplace.component.html",
  styleUrls: ["./../dashboard-section.scss"],
})
export class MarketplaceComponent implements OnInit {
  currentUser: User;

  /* Cards Data */
  marketplaceInfoCardsData: InfoCards = marketplaceInfoCards;

  /* Charts Data */
  marketplaceDoughnutData: DoughnutChartData = marketplaceDoughnutData;
  marketplaceTableData: InlineTableData = marketplaceTableData;
  saleResponses = saleResponses;
  visitors = visitors;
  productConversions = productConversions;
  campaignConversions = campaignConversions;
  activeWatchlists = marketplaceWatchlist;

  /* Misc */
  marketplaceHints = marketplaceHints;

  constructor(private storageService: StorageService) {}

  ngOnInit() {
    this.currentUser = this.storageService.get("currentUser");
    this.marketplaceInfoCardsData.forEach((card) => (card.value = ""));
    this.saleResponses.loading = true;
    this.visitors.loading = true;
    this.marketplaceDoughnutData.loading = true;
    this.productConversions.loading = true;
    this.campaignConversions.loading = true;
    this.activeWatchlists.loading = true;
  }
}
