import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudService} from "./crud.service";
import {ClipboardService} from "./clipboard.service";
import {Faq} from "../_models";

@Injectable()
export class FaqService extends CrudService {
  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, 'faqs');
  }

  copy(faq: Faq) {
    this.clipboardService.copy('faq', faq);
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('faq');
  }

  paste(topicId: string | number) {
    let faq = this.clipboardService.paste('faq');
    faq.topics = [topicId];

    return this.http.post(this.route + '/', faq);
  }

  sync(){
    return this.http.post(this.route + '/sync/',{});
  }

  getIntents() {
    return this.http.get(this.route + '/intents/');
  }
}
