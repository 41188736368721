import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PlanService} from '../_services';
import {FormBuilder } from '@angular/forms';
import {PlanNewComponent} from "./plan-new.component";
import {Plan} from "../_models";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './plan-edit.component.html'
})
export class PlanEditComponent extends PlanNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected planService: PlanService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService
  ) {
    super(router, location, route, planService, formBuilder, alertService);
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe((plan: Plan) => {
      if (plan) {
        this.plan = plan;
        plan.handoff_rate *= 100;
        this.form.patchValue(plan);
      }
    });
  }
}
