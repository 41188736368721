import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, ProductInfoSectionService } from '../_services';
import { FormBuilder } from '@angular/forms';
import { ProductInfoSectionNewComponent } from './product-info-section-new.component'
import { Section } from "../_models";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-info-section-edit.component.html'
})
export class ProductInfoSectionEditComponent extends ProductInfoSectionNewComponent implements OnInit {
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected sectionService: ProductInfoSectionService,
              protected alertService: AlertService) {
    super(router, location, route, formBuilder, sectionService, alertService);
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe((section: Section) => {
      if (section) {
        this.form.patchValue({name: section.name});
      }
    });
  }

}
