import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelStepService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {
  AutoResponderType,
  EmailResponse,
  SMSResponse,
  EmailTemplate,
  EmailTemplateType,
  getFunnelPathVariables
} from '../_models';
import {Form} from '../_forms';
import {FormGroup} from '@angular/forms';
import {tinyMCETTextOnly} from "../_helpers";
import {takeUntil} from 'rxjs/operators';


@Component({
  moduleId: module.id.toString(),
  selector: 'autoresponder-response',
  templateUrl: './autoresponder-response.component.html',
  styleUrls: ['./autoresponder.component.css']
})
export class AutoResponderResponseComponent extends Form implements OnInit {
  templateType = EmailTemplateType.AutoResponder;
  smsEditorInitCallback = tinyMCETTextOnly;
  showEmailTemplate = false
  selectedEmailTemplate: null
  minutesOptions = [
    {key: 0, value: 0},
    {key: 15, value: 15},
    {key: 30, value: 30},
    {key: 45, value: 45}
  ]
  smsPathVariables = getFunnelPathVariables()
  showEditors: boolean = false

  showAfterHourControls: boolean = false

  @Input('response') response: EmailResponse | SMSResponse;
  @Input('type') type: AutoResponderType;
  @Input('templates') emailTemplates: EmailTemplate[];
  @Input('index') index: number;
  @Input('group') responseForm: FormGroup;
  @Output('emailTemplatesChanged') templatesChanged: EventEmitter<any> = new EventEmitter()
  @Input('templatesLoading') templatesLoading: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected stepService: FunnelStepService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.responseForm;
    let existingValue = this.form.value[this.isSMS() ? 'message_after_hours' : 'template_after_hours']
    this.showAfterHourControls = !!existingValue
    this.stepService.getGlobalJumpVariables({resourcetype: 'VisualFunnel'})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        stepList => {
          this.smsPathVariables = this.smsPathVariables.concat(stepList);
          this.showEditors = true;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  isSMS() {
    return (this.type == AutoResponderType.SMS);
  }

  isEmail() {
    return (this.type == AutoResponderType.Email);
  }

  isTemplateSelected(template_id, afterHours: boolean = false) {
    let control = this.form.get(afterHours ? 'template_after_hours': 'template')
    if (control) {
      return Number(control.value) === Number(template_id)
    }
  }

  selectEmailTemplate(template_id?) {
    this.selectedEmailTemplate = template_id
    this.showEmailTemplate = true
  }

  unselectEmailTemplate(data?: EmailTemplate) {
    if (data) {
      this.templatesChanged.emit(data)
    }
    this.selectedEmailTemplate = null
    this.showEmailTemplate = false
  }

  toggleShowAfterHourControls(event) {
    this.showAfterHourControls = event.target.checked
    if (!this.showAfterHourControls) {
      let fieldName = this.isSMS() ? 'message_after_hours' : 'template_after_hours'
      let value = {}
      value[fieldName] = null
      this.form.patchValue(value)
    }
  }
}
