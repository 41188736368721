import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, WidgetCategoryService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  selector: 'widget-category-form',
  templateUrl: './category-edit.component.html'
})
export class WidgetCategoryFormComponent extends CrudSavePopupComponent implements OnInit {
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected widgetCategoryService: WidgetCategoryService,
              protected alertService: AlertService) {
    super(router, location, route, widgetCategoryService, alertService);
    this.objectName = 'Category';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null, Validators.required],
      is_global: [true]
    }));

    super.ngOnInit();
  }

}
