import * as moment from 'moment-timezone';

export function plainText(content, keep_line_breaks = false) {
  if (keep_line_breaks) {
    return content.replace(/<(?!br\s*\/?)[^>]+>/g, '');
  }

  return content.replace(/<[^>]*>/g, '');
}

export function formatMoney(number, decPlaces?, decSep?, thouSep?) {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
  decSep = typeof decSep === 'undefined' ? '.' : decSep;
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  const sign = number < 0 ? '-' : '';
  const intVal = parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces));
  const i = String(intVal);
  const j = (i.length) > 3 ? i.length % 3 : 0;

  return sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces ? decSep + Math.abs(number - intVal).toFixed(decPlaces).slice(2) : '');
}

export function getDatePart(part) {
  return ('0' + part.toString()).slice(-2)
}

export function parseISODateString(dateStr: string) {
  //dateStr is in YYYY-MM-DD format
  let ds = dateStr.split('-');

  return new Date(parseInt(ds[0]), parseInt(ds[1]) - 1, parseInt(ds[2]), 0, 0, 0, 0);
}

export function formatDateForQuery(date: Date, includeTime: boolean = false) {
  //query expects a date in UTC YYYY-MM-DD HH:MM:SS format
  let result =  date.getUTCFullYear().toString() +
    '-' + getDatePart(date.getUTCMonth() + 1) +
    '-' + getDatePart(date.getUTCDate())

  if (includeTime) {
    result += ' ' + getDatePart(date.getUTCHours()) +
      ':' + getDatePart(date.getUTCMinutes()) +
      ':' + getDatePart(date.getUTCSeconds())
  }

  return result;
}

export function getOrdinal(n: number) {
  const s = ["th","st","nd","rd"];
  const v = n % 100;

  return n.toString() + (s[(v-20)%10] || s[v] || s[0]);
}

export function tinyMCETTextOnly(editor) {
  //if there's a root wrapper element, remove it and replace all line break html elements with text line breaks
  const body = editor.getBody();

  if (body.children.length === 1) {
    const root = body.children[0];

    if (root.tagName.toLowerCase() === 'div') {
      body.style.cssText = root.style.cssText;
      body.removeChild(root);
      body.innerHTML = root.innerHTML;
    }
  }
}

export function tinyMCESingleLine(editor) {
  editor.on('keydown', function (event) {
    if (event.keyCode == 13)  {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  });
}

export function formatISODate(timestamp) {
  const dt = new Date(timestamp);

  return (dt.getMonth() + 1).toString() + '/' + dt.getDate().toString() + '/' + dt.getFullYear();
}

export function formatISOTimestamp(timestamp, includeSeconds = true) {
  const dt = new Date(timestamp);
  const am_pm = dt.getHours() >= 12 ? 'PM' : 'AM';
  const hours = (dt.getHours() === 0) ? 12 : (dt.getHours() > 12) ? dt.getHours() - 12 : dt.getHours();
  const seconds = includeSeconds ? ':' + ('0' + dt.getSeconds().toString()).slice(-2) : '';

  return (dt.getMonth() + 1).toString() + '/' + dt.getDate().toString() + ' ' + hours.toString() + ':' +
    ('0' + dt.getMinutes().toString()).slice(-2) + seconds + ' ' + am_pm;
}

export function timezoneAwareToUtc(timestamp, timezone) {
  return moment.tz(timestamp, timezone).utc();
}

export function formatTime(input) {
  if (!input) return '';
  const date = new Date(input);
  return date.toLocaleTimeString();
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const isObjectEmpty = (obj: any): boolean => {
  return Object.keys(obj).length === 0;
};

export const downloadFromUrl = (url: string) => {
  let dwldLink = document.createElement("a");
  dwldLink.setAttribute("href", url);
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

export function copyToClipboard(data: string) {
  const el = document.createElement('textarea');
  el.value = data;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function pad(num) {
  return ('0' + num).slice(-2);
}

export function convertSecondsToSessionTime(secs) {
  if (secs) {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(Math.floor(secs))}`;
  }

  return null
}
