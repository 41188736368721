import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PaymentSourceService, StorageService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {Form} from '../_forms';
import {takeUntil} from "rxjs/operators";
import {PaymentSource} from "../_models";
import {NgxSmartModalService} from 'ngx-smart-modal';
import {
  NgxPlaidLinkService,
} from "ngx-plaid-link";
import {PlaidLinkHandler} from "ngx-plaid-link/lib/ngx-plaid-link-handler";
import {PlaidConfig} from "ngx-plaid-link/lib/interfaces";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './plaid-redirect.component.html'
})
export class PlaidRedirectComponent extends Form implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected paymentService: PaymentSourceService,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgxSmartModalService,
    private plaidLinkService: NgxPlaidLinkService,
    private storageService: StorageService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();
    const token = this.storageService.get('plaidToken');

    if (token) {
      this.loading = true;

      this.plaidLinkService
        .createPlaid(Object.assign({} as PlaidConfig, {
          token: token,
          receivedRedirectUri: window.location.href,
          onSuccess: (token, metadata) => this.onSuccess(token, metadata),
          onExit: (error, metadata) => this.onExit(error, metadata)
        }))
        .then((handler: PlaidLinkHandler) => {
          handler.open();
          this.loading = false;
        })
        .catch(err => {
          this.handleError(err);
          this.loading = false;
        });
    }
    else {
      this.alertService.error('Missing Plaid token', true);
      this.router.navigate(['/payment', 'bank-account', 'new'],{replaceUrl: true});
    }
  }

  onSuccess(token, metadata) {
    this.loading = true;

    this.paymentService.createFromPlaidToken(token, metadata.account_id)
      .subscribe(
        data => {
          this.alertService.success('Added New Bank Account.', true);
          this.loading = false;
          this.storageService.remove('plaidToken');
          this.router.navigate(['/payment', 'sources'],{replaceUrl: true});
        },
        error => {
          if (error.status === 400) { //bad request
            if ('token' in error.error) {
              this.alertService.error(error.error.token);
            } else {
              this.handleSubmitError(error, true);
            }
          } else {
            this.handleError(error, true);
          }

          this.loading = false;
          this.storageService.remove('plaidToken');
          this.router.navigate(['/payment', 'bank-account', 'new'],{replaceUrl: true});
        });
  }

  onExit(error, metadata) {
    if (error) {
      this.alertService.error(error, true);
    }
    this.storageService.remove('plaidToken');
    this.router.navigate(['/payment', 'bank-account', 'new'],{replaceUrl: true});
  }
}
