import { Injectable } from "@angular/core";
import { DashboardService } from "../dashboard.service";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "../../alert.service";
import { StorageService } from "../../storage.service";
import { DashboardMarketplaceSalesAPIResponse } from "../../../../app/_models";

@Injectable({
  providedIn: "root",
})
export class MarketplaceSalesService extends DashboardService {
  route = "marketplace_dashboard/sales_by_traffic_source";
  protected data: BehaviorSubject<DashboardMarketplaceSalesAPIResponse> =
    new BehaviorSubject<DashboardMarketplaceSalesAPIResponse>(undefined);
  constructor(
    protected http: HttpClient,
    protected alertService: AlertService,
    protected storageService: StorageService
  ) {
    super(http, alertService, storageService);
  }

  getData() {
    if (!this.route) {
      return;
    }

    this.http.post(this.route + "/", this.getBody()).subscribe(
      (data) => {
        if (data) {
          this.data.next(data);
        }
      },
      (error) => {
        this.data.next({});
        if (error.status !== 401) {
          this.alertService.error(error.message);
        }
      }
    );
  }
}
