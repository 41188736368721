import { Component } from "@angular/core";
import {
  campaigns,
  customerRequests,
  engagementCardData,
  faqs,
  overviewInfoCardsData,
  sessionsDoughnutData,
  sessionsTableData,
} from "../../overview/overview.data";
import { EngagementCards, InfoCards } from "../../../_models";
import { InlineTableData } from "../../data-visualization/data-table/data-table.component";
import { DoughnutChartData } from "../../data-visualization/doughnut-chart/doughnut-chart.component";

@Component({
  selector: "app-overview-report",
  templateUrl: "./overview-report.component.html",
  styleUrls: ["./../dashboard-report.component.scss"],
})
export class DashboardOverviewReportComponent {
  /* Cards Data */
  infoCards: InfoCards = overviewInfoCardsData;
  engagementCards: EngagementCards = engagementCardData;

  /* Charts Data */
  campaigns = campaigns;
  faqs = faqs;
  sessionsDoughnutData: DoughnutChartData = sessionsDoughnutData;
  customerRequests = customerRequests;
  sessionsTableData: InlineTableData = sessionsTableData;

  chartOptions = {
    cutoutPercentage: 85,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "rgba(47, 49, 66, 0.8)",
      titleFontSize: 13,
      titleFontColor: "#fff",
      caretSize: 0,
      cornerRadius: 4,
      xPadding: 10,
      displayColors: true,
      yPadding: 10,
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 4,
      },
    },
  };

  constructor() {}
}
