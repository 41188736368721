import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {
  Bank,
  BankStatus,
  BankStatusLabels,
  BusinessTypeLabels,
  BusinessType,
  CheckoutTypeLabels,
  CheckoutType,
  PermissionType,
  ProductTypeLabels,
  ProductType,
} from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudPagedListComponent } from '../../../_directives';
import { Location } from '@angular/common';
import { AlertService, CheckoutBankService, LoaderService } from '../../../_services';
import { takeUntil } from 'rxjs/operators';
import { config } from '../../../../config/config';

@Component({
  selector: 'app-checkout-bank',
  templateUrl: './checkout-bank.component.html',
  styleUrls: ['./checkout-bank.component.scss', '../profiles-styles.component.scss'],
})
export class CheckoutBankComponent extends CrudPagedListComponent implements OnInit {
  public bankProfileStatus = BankStatus;
  selectedFilter: BankStatus = null;
  selectedRow: number = -1;
  PermissionTypes = PermissionType;
  selectedType: PermissionType | null = null;
  searchQuery: string;

  // Static Table Data
  tableData: Bank[];
  originalTableData: Bank[];
  isLoading = false;
  permissionTypesMainEntriesLimit: number = 2;
  pageSize = config.maxPageSize;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected bankService: CheckoutBankService,
    protected alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService
  ) {
    super(router, location, route, bankService, alertService);
    this.objectName = 'bank';
  }

  ngOnInit() {
    super.ngOnInit();
    this.data$.subscribe(
      (data: Bank[]) => {
        if (data) {
          this.tableData = [];
          this.originalTableData = [];

          data.forEach((row: Bank) => {
            if (row.is_deleted) {
              row.status = BankStatus.Archived;
            }

            this.tableData.push(row);
            this.originalTableData.push(row);
          });
        }
      },
      (error) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  protected runInitialQuery() {
    this.queryData();
  }

  toggleList(index: number, type: PermissionType): void {
    if (this.selectedRow === index && this.selectedType === type) {
      this.selectedRow = -1;
      this.selectedType = null;
    } else {
      this.selectedRow = index;
      this.selectedType = type;
    }
  }

  closeTypePopup() {
    this.selectedRow = -1;
    this.selectedType = null;
  }

  searchData() {
    this.tableData = this.originalTableData.filter((item) =>
      item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  filterTableData() {
    if (this.selectedFilter === null) {
      this.tableData = [...this.originalTableData];
    } else if (this.selectedFilter === BankStatus.Archived) {
      this.tableData = this.originalTableData.filter((item) => item.is_deleted);
    } else {
      this.tableData = this.originalTableData.filter((item) => item.status === this.selectedFilter);
    }
  }

  delete(id: number): void {
    const idx = this.tableData.findIndex((item: Bank) => item.id === id);

    const item = this.tableData[idx];
    const isArchived = item.is_deleted;
    const action = isArchived ? 'unarchive' : 'archive';
    const confirmMessage = `Are you sure you want to ${action} this ${this.objectName}?`;

    if (window.confirm(confirmMessage)) {
      this.loading = true;

      const updateObservable = isArchived
        ? this.crudService.patch(id, { is_deleted: false })
        : this.crudService.delete(id);

      updateObservable.pipe(takeUntil(this._destroy$)).subscribe({
        next: () => {
          item.is_deleted = !isArchived;
          item.status = isArchived ? BankStatus.Active : BankStatus.Archived;
          const successMessage = `${isArchived ? 'Unarchived' : 'Archived'} ${this.objectName}.`;
          this.alertService.success(successMessage);
          this.loading = false;
        },
        error: (error) => {
          this.handleError(error);
          this.loading = false;
        },
      });
    }
  }

  getStatusLabel(status: BankStatus) {
    return BankStatusLabels[status];
  }

  getPermissionTypeMainEntries(types: BusinessType[] | CheckoutType[] | ProductType[], permission: PermissionType) {
    let mains: any = types.slice(0, this.permissionTypesMainEntriesLimit);

    switch (permission) {
      case PermissionType.BusinessType:
        mains = mains.map((item) => BusinessTypeLabels[item]);
        break;
      case PermissionType.ProductType:
        mains = mains.map((item) => ProductTypeLabels[item]);
        break;
      case PermissionType.CheckoutType:
        mains = mains.map((item) => CheckoutTypeLabels[item]);
        break;
      default:
        mains = [];
        break;
    }

    return mains.join(', ');
  }

  getPermissionTypeExtraEntries(types: BusinessType[] | CheckoutType[] | ProductType[]) {
    return types.slice(this.permissionTypesMainEntriesLimit);
  }

  getPermissionTypeLabel(type: BusinessType | CheckoutType | ProductType, permission: PermissionType) {
    let label = '';

    switch (permission) {
      case PermissionType.BusinessType:
        label = BusinessTypeLabels[type];
        break;
      case PermissionType.ProductType:
        label = ProductTypeLabels[type];
        break;
      case PermissionType.CheckoutType:
        label = CheckoutTypeLabels[type];
        break;
      default:
        break;
    }

    return label;
  }
}
