import { Component, OnInit } from '@angular/core';
import { PathNewComponent } from './path-new.component';
import {
  AlertService,
  FunnelInputService,
  FunnelService,
  FunnelStepService,
} from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import {Funnel, OfferIntentParents, OfferIntents} from '../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AudienceService } from '../_services/audience.service';
import { PathNewStepSelection } from '../_models';

@Component({
  moduleId: module.id.toString(),
  styleUrls: ['./path-edit.component.scss'],
  templateUrl: './path-edit.component.html',
})
export class PathEditComponent extends PathNewComponent implements OnInit {
  public pageTitle: string = 'Edit path';
  public pathNewStepSelection = PathNewStepSelection;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected audienceService: AudienceService,
    protected modalService: NgxSmartModalService,
    protected stepService: FunnelStepService,
    protected inputService: FunnelInputService
  ) {
    super(
      router,
      location,
      route,
      funnelService,
      alertService,
      formBuilder,
      audienceService,
      modalService,
      stepService,
      inputService
    );
    this.isNew = false;
    this.objectName = 'Path';
  }
  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.isNewPath = false;
    this.data$.subscribe(
      (funnel: Funnel) => {
        if (funnel) {
          this.loading = false;
          let updatedData: {} = {child_intent: funnel.offer_intent}
          // if offer intent is child intent then replace it by the parent intent in the form
          if (funnel.offer_intent in OfferIntentParents) {
            updatedData['offer_intent'] = OfferIntentParents[funnel.offer_intent]
          }

          this.form.patchValue({...funnel, ...updatedData});
          this.onOfferIntentChanged(this.form.value.offer_intent)
          this.funnel = funnel;
          this.stepService.setFunnelId(this.funnel.id);
          this.populateExistingSteps(this.funnel.steps);
          if (!this.isNew) {
            this.form.get('child_intent').disable();
          }
        }
      },
      (error) => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }

  protected onSaveComplete(data) {
    if (this.isPublish) {
      this.publish()
    } else {
      this.router.navigate(['/new-paths'])
      this.loading = false;
    }
  }
  publish() {
    if (this.funnel) {
      this.loading = true;
      this.funnelService.publish(this.funnel.id).subscribe(
        (data) => {
          this.router.navigate(['/new-paths'])
          this.funnel.is_modified = false;
          this.loading = false;
          this.alertService.success('Path has been published');
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    }

    this.loading = false;
    this.router.navigate(['/new-paths'])
  }
}
