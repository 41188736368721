import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, StorageService } from '../_services';
import { FileUpload } from '../_forms';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  moduleId: module.id.toString(),
  selector: 'audio-file-upload',
  templateUrl: 'audio-file-upload.component.html',
  styleUrls: ['./audio-file.component.css']
})
export class AudioFileUploadComponent extends FileUpload implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected storageService: StorageService,
    protected domSanitizer: DomSanitizer
  ) {
    super(router, location, alertService, storageService, domSanitizer);
    this.path = 'audio/';
    this.title = 'Upload an Audio File';
    this.options.allowedContentTypes = ['audio/mpeg', 'audio/mpeg3', 'audio/x-mpeg-3', 'audio/wav', 'audio/x-wav', 'audio/mp3', 'audio/x-m4a'];
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected onUploadComplete(result) {
    this.goBack();
  }
}
