import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FileService} from './file.service';
import {Observable, of as observableOf} from 'rxjs';
import {map} from 'rxjs/operators';
// import {Image} from "../_models";
import {ClipboardService} from './clipboard.service';
import {Image} from '../_models';

@Injectable()
export class ImageService extends FileService {
  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, clipboardService, 'images');
    this.fileType = 'image'
  }

  create(image, resourceType?: string) {
    const formData = new FormData();

    image.forEach((item, key) => {
      formData.append(`file_id-${key}`, item.id);
      formData.append(`is_public-${key}`, item.is_public ? item.is_public : false);
      formData.append(`path-${key}`, item.path);
      formData.append(`resourcetype-${key}`, item.resourcetype ? item.resourcetype : 'Image');
      formData.append(`ppoi-${key}`, item.ppoi ? item.ppoi : '0.5x0.5');
      formData.append(`file_type-${key}`, item.file.type);
      formData.append(`width-${key}`, item.width || 400);
      formData.append(`height-${key}`, item.height || 400);
    });

    return this.http.post(this.route + '/', formData);
  }

  uploadFile(image, file) {
    const url = `${image.url}`;
    const formData = new FormData();
    for (const field in image.fields) {
      formData.append(`${field}`, image.fields[field]);
    }
    formData.append('file', file);
    return this.http.post(url, formData).toPromise();
  }

  getThumbnailSizes(type?: string) : Observable<{}> {
    let sizesString = sessionStorage.getItem('thumbnailSizes');

    if (sizesString) {
      //get the cached thumbnail sizes
      let sizes = JSON.parse(sizesString);

      if (type && type in sizes) {
        sizes = sizes[type];
      }

      return observableOf(sizes);
    }
    else {
      //thumbnail sizes not cached, so get it from the server
      return this.http.get<any>('images/sizes/').pipe(map((sizes: {}) => {
        //cache the thumbnail sizes returned from the server
        sessionStorage.setItem('thumbnailSizes', JSON.stringify(sizes));

        if (type && type in sizes) {
          sizes = sizes[type];
        }

        return sizes;
      }));
    }
  }

  getImageAttributes(file) {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = (e) => {
        let img = new Image();
        img.onload = () => {
          return resolve({
            src: <string>fr.result, file,
            height: img.height, width: img.width,
            size: file.size,
            sizeInMB: (file.size / 1024) / 1024,
          });
        };
        img.src = <string>fr.result;
      };
      fr.readAsDataURL(file.nativeFile || file);
    });
  }

  postUploadProcess(originalFiles, images: Image[]) {
    return new Promise((resolve, reject) => {
      let promises = [];
      if (!Array.isArray(images)) {
        images = [images];
      }
      for (let image of images) {
        let item = originalFiles.find((img) => img.id == image.pre_signed_url.file_id);
        if (!item) { continue; }
        promises.push(this.uploadFile(image.pre_signed_url, item.file));
      }
      Promise.all(promises).then(() => {
        const uploadedImages = images.map((image) => {
          image.file = image.pre_signed_url.url + image.pre_signed_url.fields.key;
          delete image.pre_signed_url;
          return image;
        });

        return resolve(uploadedImages);
      });
    });
  }
}
