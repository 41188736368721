import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from "./pagination.service";

@Injectable()
export class ProductService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'products');
  }

  getVariants(id: string | number) {
    return this.http.get(this.route + '/' + id.toString() + '/variants/');
  }
}
