import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, EmailTemplateService, CampaignService } from '../_services';
import { CrudPagedListComponent } from '../_directives';
import { EmailTemplate } from '../_models';
import { Observable } from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent extends CrudPagedListComponent implements OnInit {
  templates$: Observable<EmailTemplate[]> = this.data$;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected templateService: EmailTemplateService,
    protected alertService: AlertService,
    private campaignService: CampaignService
  ) {
    super(router, location, route, templateService, alertService);
    this.objectName = 'template';
    this.title = 'Email Templates';
  }

  ngOnInit() {
    super.ngOnInit();

    this.campaignService.hideMenu();
  }

}
