import { ImageGalleryComponent } from '../../../image/image-gallery.component';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  StepMedia,
  StepImagesLayout,
  StepMediaCategory,
  StepEmulatorDevices,
  StepStaticDataImageType,
  StepFixedMediaCategory,
  StepMediaSubCategory,
  StepElements,
  StepMediaPanels,
  StepResponseMedia,
} from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { StepEmulatorService, StepTemplateService, AlertService, ImageService } from '../../../_services';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormControlStatus } from '../../../_forms';
import { getEmbeddedVideoUrl } from '../../step-data';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'step-media',
  templateUrl: './step-media.component.html',
  styleUrls: ['./step-media.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepMediaComponent extends ImageGalleryComponent implements OnInit, OnDestroy {
  @Input() layout: StepImagesLayout;
  @Input() stepForm: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() isSurveyStep: boolean;
  stepMedia: StepMedia;
  stepResponseMedia: StepResponseMedia;
  stepMediaPanels = StepMediaPanels;
  stepElements = StepElements;
  stepMediaCategory = StepMediaCategory;
  stepMediaSubCategory = StepMediaSubCategory;
  emulatorDevices = StepEmulatorDevices;
  stepId: string = '';
  templateId: string = '';
  private subscriptions: Subscription[] = [];

  @ViewChild('stepMediaElement', { static: false })
  stepMediaElement: ElementRef;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected stepService: StepTemplateService,
    protected stepEmulatorService: StepEmulatorService,
    protected imageService: ImageService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
    private sanitizer: DomSanitizer
  ) {
    super(router, location, route, imageService, alertService, formBuilder, modalService);
    this.route.params.subscribe((params) => {
      this.stepId = params['step_id'];
      this.templateId = params['template_id'];
    });
  }

  ngOnInit() {
    this.pageSize = 14;

    this.updatePanel(StepMediaPanels.Step);
    const stepMediaSubscription = this.stepEmulatorService.stepMedia$.subscribe((data) => {
      this.stepMedia = data;
    });
    this.addSubscription(stepMediaSubscription);
    const responseMediaSubscription = this.stepEmulatorService.stepResponseMedia$.subscribe((data) => {
      this.stepResponseMedia = data;
    });
    this.addSubscription(responseMediaSubscription);

    if (!this.templateId && !this.stepId && !this.layout.enabled) {
      this.stepMedia.category = StepMediaCategory.NoMedia;
    }

    super.ngOnInit();
    this.setDefaultImage();
    this.updateStepMedia();
    if (!this.isSurveyStep) {
      this.updateResponseMedia();
    }
  }

  setDefaultImage() {
    if (this.layout.placeholderImage) {
      this.stepMedia.placeholderImage = this.layout.placeholderImage;
    }
    this.updateStepMedia();
  }

  updateMedia() {
    if (this.isStepMedia) {
      this.updateStepMedia();
    } else if (this.isResponseMedia) {
      this.updateResponseMedia();
    }
  }

  updateStepMedia() {
    switch (this.stepMedia.category) {
      case StepMediaCategory.NoMedia:
        this.stepForm.setControl(
          'static_data',
          this.formBuilder.group({
            image: null,
            image_tablet: null,
            image_mobile: null,
            embed_code: null,
            image_type: StepStaticDataImageType.None,
          })
        );
        break;

      case StepMediaCategory.Product:
        if (this.isProductSub) {
          this.stepForm.setControl(
            'static_data',
            this.formBuilder.group({
              image: null,
              image_tablet: null,
              image_mobile: null,
              embed_code: null,
              image_type: StepStaticDataImageType.Product,
            })
          );
        } else if (this.isTagged) {
          this.stepForm.setControl(
            'static_data',
            this.formBuilder.group({
              image: null,
              image_tablet: null,
              image_mobile: null,
              embed_code: null,
              image_type: StepStaticDataImageType.Tagged,
            })
          );
        }
        break;

      case StepMediaCategory.Fixed:
        switch (this.stepMedia.fixedImage.category) {
          case StepFixedMediaCategory.Gallery:
            const image = (this.stepMedia.fixedImage.productImage && this.stepMedia.fixedImage.productImage.id) || null;

            this.stepForm.setControl(
              'static_data',
              this.formBuilder.group({
                image: [image, Validators.required],
                image_tablet: [image, Validators.required],
                image_mobile: [image, Validators.required],
                embed_code: null,
                image_type: StepStaticDataImageType.Gallery,
              })
            );
            break;

          case StepFixedMediaCategory.Upload:
            const desktopImageId = this.stepMedia.fixedImage[StepEmulatorDevices.Desktop].id;
            const tabletImageId = this.stepMedia.fixedImage[StepEmulatorDevices.Tablet].id;
            const mobileImageId = this.stepMedia.fixedImage[StepEmulatorDevices.Mobile].id;

            this.stepForm.setControl(
              'static_data',
              this.formBuilder.group({
                image: [desktopImageId || mobileImageId || tabletImageId, Validators.required],
                image_tablet: tabletImageId,
                image_mobile: mobileImageId,
                embed_code: null,
                image_type: StepStaticDataImageType.Fixed,
              })
            );
            break;
        }
        break;

      case StepMediaCategory.Embed:
        const embed_code = this.stepMedia.embedVideo.isURLValid
          ? this.stepMedia.embedVideo.sanitizedURL['changingThisBreaksApplicationSecurity']
          : null;

        this.stepForm.setControl(
          'static_data',
          this.formBuilder.group({
            image: null,
            image_tablet: null,
            image_mobile: null,
            embed_code: [embed_code, Validators.required],
            image_type: StepStaticDataImageType.Embed,
          })
        );
        break;
    }

    this.stepEmulatorService.updateStepMedia(this.stepMedia);
  }

  updateResponseMedia() {
    const responseDataControl = this.stepForm.get('response_data') as FormGroup;

    switch (this.stepResponseMedia.category) {
      case StepMediaCategory.NoMedia:
        responseDataControl.setControl(
          'static_data',
          this.formBuilder.group({
            image: null,
            image_tablet: null,
            image_mobile: null,
            embed_code: null,
            image_type: StepStaticDataImageType.None,
          })
        );
        break;

      case StepMediaCategory.Fixed:
        switch (this.stepResponseMedia.fixedImage.category) {
          case StepFixedMediaCategory.Upload:
            const desktopImageId = this.stepResponseMedia.fixedImage[StepEmulatorDevices.Desktop].id;
            const tabletImageId = this.stepResponseMedia.fixedImage[StepEmulatorDevices.Tablet].id;
            const mobileImageId = this.stepResponseMedia.fixedImage[StepEmulatorDevices.Mobile].id;

            responseDataControl.setControl(
              'static_data',
              this.formBuilder.group({
                image: [desktopImageId || mobileImageId || tabletImageId, Validators.required],
                image_tablet: tabletImageId,
                image_mobile: mobileImageId,
                embed_code: null,
                image_type: StepStaticDataImageType.Fixed,
              })
            );

            break;

          case StepFixedMediaCategory.Gallery:
            const image =
              (this.stepResponseMedia.fixedImage.productImage && this.stepResponseMedia.fixedImage.productImage.id) ||
              null;

            responseDataControl.setControl(
              'static_data',
              this.formBuilder.group({
                image: [image, Validators.required],
                image_tablet: [image, Validators.required],
                image_mobile: [image, Validators.required],
                embed_code: null,
                image_type: StepStaticDataImageType.Gallery,
              })
            );

            break;
        }
        break;

      case StepMediaCategory.Embed:
        const embed_code = this.stepResponseMedia.embedVideo.isURLValid
          ? this.stepResponseMedia.embedVideo.sanitizedURL['changingThisBreaksApplicationSecurity']
          : null;

        responseDataControl.setControl(
          'static_data',
          this.formBuilder.group({
            image: null,
            image_tablet: null,
            image_mobile: null,
            embed_code: [embed_code, Validators.required],
            image_type: StepStaticDataImageType.Embed,
          })
        );
        break;
    }

    this.stepEmulatorService.updateResponseMedia(this.stepResponseMedia);
  }

  checkEmbedURL() {
    if (this.isStepMedia) {
      this.stepMedia.embedVideo.sanitizedURL = getEmbeddedVideoUrl(this.stepMedia.embedVideo.url, this.sanitizer);

      this.stepMedia.embedVideo.isURLValid = this.stepMedia.embedVideo.sanitizedURL ? true : false;
    } else if (this.isResponseMedia) {
      this.stepResponseMedia.embedVideo.sanitizedURL = getEmbeddedVideoUrl(
        this.stepResponseMedia.embedVideo.url,
        this.sanitizer
      );

      this.stepResponseMedia.embedVideo.isURLValid = this.stepResponseMedia.embedVideo.sanitizedURL ? true : false;
    }

    this.updateMedia();
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  updatePanel(panel: StepMediaPanels) {
    this.stepEmulatorService.selectedMediaPanel = panel;
  }

  get isStepMedia() {
    return this.stepEmulatorService.selectedMediaPanel === StepMediaPanels.Step;
  }

  get isResponseMedia() {
    return this.stepEmulatorService.selectedMediaPanel === StepMediaPanels.Response;
  }

  get isNoMedia() {
    if (this.isStepMedia) {
      return this.stepMedia.category === StepMediaCategory.NoMedia;
    }
    return this.stepResponseMedia.category === StepMediaCategory.NoMedia;
  }

  get isProduct() {
    return this.isStepMedia && this.stepMedia.category === StepMediaCategory.Product;
  }

  get isProductSub() {
    return this.stepMedia.subCategory === StepMediaSubCategory.Product;
  }

  get isTagged() {
    return this.stepMedia.subCategory === StepMediaSubCategory.Tagged;
  }

  get isFixed() {
    if (this.isStepMedia) {
      return this.stepMedia.category === StepMediaCategory.Fixed;
    }
    return this.stepResponseMedia.category === StepMediaCategory.Fixed;
  }

  get isUpload() {
    if (this.isStepMedia) {
      return this.stepMedia.fixedImage.category === StepFixedMediaCategory.Upload;
    }
    return this.stepResponseMedia.fixedImage.category === StepFixedMediaCategory.Upload;
  }

  get isEmbed() {
    if (this.isStepMedia) {
      return this.stepMedia.category === StepMediaCategory.Embed;
    }
    return this.stepResponseMedia.category === StepMediaCategory.Embed;
  }

  get isStepEmbed() {
    return this.isStepMedia && this.stepMedia.category === StepMediaCategory.Embed;
  }

  get isResponseEmbed() {
    return this.isResponseMedia && this.stepResponseMedia.category === StepMediaCategory.Embed;
  }

  get selectedCategory() {
    return this.isStepMedia ? this.stepMedia.category : this.stepResponseMedia.category;
  }

  set selectedCategory(value: any) {
    if (this.isStepMedia) {
      this.stepMedia.category = value;
    } else {
      this.stepResponseMedia.category = value;
    }
  }

  get mediaInvalidity() {
    return this.stepMediaInvalidity || this.responseMediaInvalidity;
  }

  get stepMediaInvalidity() {
    return this.enableFormErrors && this.stepForm.controls.static_data.status === FormControlStatus.Invalid;
  }

  get responseMediaInvalidity() {
    const response_data = this.stepForm.controls.response_data as FormGroup;
    return (
      this.enableFormErrors &&
      response_data &&
      response_data.get('static_data') &&
      response_data.get('static_data').status === FormControlStatus.Invalid
    );
  }

  addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  getElementRef(): ElementRef | null {
    if (this.mediaInvalidity) {
      if (!this.isElementVisible(this.stepElements.Media)) {
        this.toggleElement(this.stepElements.Media);
      }

      return this.stepMediaElement;
    }

    return null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
