import { Component, ViewEncapsulation } from "@angular/core";
import {
  campaignConversions,
  marketplaceDoughnutData,
  marketplaceInfoCards,
  marketplaceTableData,
  marketplaceWatchlist,
  productConversions,
  saleResponses,
  visitors,
} from "../../marketplace/marketplace.data";
import { InfoCards } from "../../../../app/_models";
import { DoughnutChartData } from "../../data-visualization/doughnut-chart/doughnut-chart.component";
import { InlineTableData } from "../../data-visualization/data-table/data-table.component";

@Component({
  selector: "app-marketplace-report",
  templateUrl: "./marketplace-report.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./../dashboard-report.component.scss"],
})
export class DashboardMarketplaceReportComponent {
  /* Cards Data */
  marketplaceInfoCards: InfoCards = marketplaceInfoCards;

  /* Charts Data */
  marketplaceDoughnutData: DoughnutChartData = marketplaceDoughnutData;
  marketplaceTableData: InlineTableData = marketplaceTableData;
  saleResponses = saleResponses;
  visitors = visitors;
  productConversions = productConversions;
  campaignConversions = campaignConversions;
  activeWatchlists = marketplaceWatchlist;

  chartOptions = {
    cutoutPercentage: 85,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "rgba(47, 49, 66, 0.8)",
      titleFontSize: 13,
      titleFontColor: "#fff",
      caretSize: 0,
      cornerRadius: 4,
      xPadding: 10,
      displayColors: true,
      yPadding: 10,
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 10,
      },
    },
  };

  xAxis: boolean = true;
  yAxis: boolean = true;
  timeline: boolean = true;

  colorScheme = {
    domain: ["#22b8d7"],
  };

  constructor() {}
}
