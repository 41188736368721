import {
  DashboardState,
  EngagementCards,
  InfoCards,
  SessionFilters,
} from "../../../app/_models";
import { InlineTableData } from "../data-visualization/data-table/data-table.component";
import { DoughnutChartData } from "../data-visualization/doughnut-chart/doughnut-chart.component";

/* Cards Data */
export const overviewInfoCardsData: InfoCards = [
  {
    title: "Self-Service Rate",
    value: "",
    hint: "The self-service rate is determined by dividing the total number of successful customer support sessions by the overall number of sessions, expressed as a percentage. ",
  },
  {
    title: "Cost Savings",
    value: "",
    hint: "We determine estimated savings by comparing current customer support costs per inquiry with Solvpath's cost per inquiry. Multiply this by the number of inquiries resolved through Solvpath for an overall estimate.",
  },
  {
    title: "Sessions",
    value: "",
    hint: "The overall count of user inquiries.",
  },
  {
    title: "Successful Sessions",
    value: "",
    hint: "Customer sessions completed successfully, meeting their objectives.",
  },
];

export const engagementCardData: EngagementCards = [
  { title: "Phone Calls", count: "", icon: "PHONE" },
  { title: "Emails", count: "", icon: "EMAIL" },
  { title: "Direct Links", count: "", icon: "LINK" },
  { title: "Social Messages", count: "", icon: "MESSAGE" },
];

/* Charts Data */
export const sessionsDoughnutData: DoughnutChartData = {
  data: [100, 50, 60, 20, 10],
  noData: false,
  labels: ["Successful", "Bypass", "Dropoffs", "Lifeline"],
  loading: true,
  backgroundColors: [
    {
      backgroundColor: ["#163549", "#22B8D7", "#FFCC3E", "#B1BEC4", "#15C771"],
    },
  ],
};

export const sessionsTableData: InlineTableData = [
  {
    name: "Successful",
    color: "#163549",
  },
  {
    name: "Bypass",
    color: "#22B8D7",
  },
  {
    name: "Dropoffs",
    color: "#FFCC3E",
  },
  {
    name: "Lifeline",
    color: "#B1BEC4",
  },
];

const sessionFilters: SessionFilters = {
  includeTestSessions: false,
  includeHangups: false,
  includeUnknowns: false,
};

const state: DashboardState = {};

export const sessions = {
  filter: null,
  flag: false,
  filters: sessionFilters,
  display: false,
  state,
};

export const customerRequests = {
  data: [],
  loading: true,
  noData: false,
};

export const faqs = {
  data: [],
  loading: true,
};

export const campaigns = {
  data: [],
  loading: true,
  title: "Top Campaigns",
};

export enum PathChannelSource {
  Web = 0,
  Email = 1,
  Phone = 2,
  SocialMedia = 3,
}

export enum OverviewInfo {
  SelfService = 0,
  CostSavings = 1,
  Sessions = 2,
  SuccessfulSessions = 3,
}

export const overviewHints = {
  sessions: {
    success: `Customer sessions completed successfully, meeting their objectives.`,
    bypass: `Customers who opted to be directed to a live customer support agent.`,
    dropoff: `Customers who abandoned their support session.`,
    lifeline: `Customers offered website popup help through phone, email or chat due to friction score or errors for quick resolutions.`,
    unmatch: `Customers not found or identified in their ecommerce platform.`,
  },
  inquiry: "The type of requests customers are submitting.",
  faq: "The most frequently asked questions that your customers are inquiring about.",
  campaign:
    "Most active ecommerce campaigns submitting customer support inquiries.",
  sessionsTable: "Real-time session details",
  steps: "The count of unique steps taken by a customer during their session.",
  frequency: "The number of times a customer initiated a session."
};
