import {Component, OnInit} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PaginationService, RelatedProductSetCategoryService, RelatedProductSetService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {RelatedProductCategoryType, RelatedProductCategoryTypeLabel, RelatedProductSetCategory} from '../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'exchangeable-product-set-category-new',
  templateUrl: './related-product-set-category-edit.component.html'
})
export class RelatedProductSetCategoryNewComponent extends CrudSaveComponent implements OnInit {
  // hard-coded for now
  public type: RelatedProductCategoryType = RelatedProductCategoryType.EXCHANGEABLE
  public typeLabel: string = RelatedProductCategoryTypeLabel[RelatedProductCategoryType.EXCHANGEABLE]

  public parent: string | number = null

  public relatedProductSetCategory: RelatedProductSetCategory = null

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected relatedProductSetCategoryService: RelatedProductSetCategoryService,
    protected relatedProductSetService: RelatedProductSetService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
  ) {
    super(router, location, route, relatedProductSetCategoryService, alertService);
    this.isNew = true
    this.objectName = `${this.typeLabel} Products category`
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]]
    })
    super.ngOnInit()

    this.parent = this.route.snapshot.params['parent'] || null
  }

  get subCategories() {
    return this.relatedProductSetCategory ? this.relatedProductSetCategory.sub_categories : []
  }

  get setTypes() {
    return this.relatedProductSetCategory ? this.relatedProductSetCategory.set_types : []
  }

  getUrlParams(fieldName: string) {
    let obj = {}
    if (this.parent) {
      obj[fieldName] = this.parent
    }

    return obj
  }

  protected getFormData(): any {
    let data = super.getFormData();
    data['type'] = this.type
    data['parent'] = this.parent
    return data
  }

  protected onSaveComplete(data) {
    this.loading = false
    this.goBack()
  }

  private subCategoryDeleted(id) {
    let subCategories = this.relatedProductSetCategory.sub_categories
    subCategories = subCategories.filter(item => item.id !== id)
    this.relatedProductSetCategory.sub_categories = subCategories
  }

  private setTypeDeleted(id) {
    let setTypes = this.relatedProductSetCategory.set_types
    setTypes = setTypes.filter(item => item.id !== id)
    this.relatedProductSetCategory.set_types = setTypes
  }

  delete(id, objectName = '', model = 'category') {
    let service: PaginationService = this.relatedProductSetCategoryService
    if (model === 'set') {
      service = this.relatedProductSetService
    }

    if(window.confirm('Are you sure you want to delete ' + objectName + '?')) {
      this.loading = true

      service.delete(id)
        .subscribe(_ => {
          this.alertService.success('Successfully deleted ' + objectName)
          this.loading = false
          if (model === 'category') {
            this.subCategoryDeleted(id)
          } else if (model === 'set') {
            this.setTypeDeleted(id)
          }
        })
    }
  }
}
