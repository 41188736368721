import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Form} from '../_forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {Folder, FolderType} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './design-template.component.html',
  selector: 'design-template'
})
export class DesignTemplateComponent extends Form implements OnInit {

  widgetType: FolderType = FolderType.Widget;
  @Input('isTemplateSelection') isTemplateSelection: boolean = false;
  @Output('onTemplateSelection') onTemplateSelection: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected alertService: AlertService) {
    super(alertService, router, location);
    this.title = 'Template';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  selectTemplate(template) {
    this.onTemplateSelection.emit(template);
  }

}
