import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, CampaignService } from '../_services/index';

@Component({
  moduleId: module.id.toString(),
  template: ''
})

export class CampaignVerifyComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    let campaign_id = this.route.snapshot.queryParams['campaign_id'];
    let token = this.route.snapshot.queryParams['token'];

    this.campaignService.verify(campaign_id, token)
      .subscribe(
        data => {
          this.alertService.success('Brand email is now verified.',
            true);
          this.router.navigate(['/crms']);
        },
        error => {
          this.alertService.error("error " + error.status.toString() + ": " + error.statusText)
        });
  }
}
