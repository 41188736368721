import {
  Checkout,
  CheckoutAccountTypeLabels,
  CheckoutDateType,
  CheckoutPopup,
  CheckoutReviewStatus,
  CheckoutReviewStatusTypesLabels,
  CheckoutType,
  CheckoutStatus,
  TableStatusLabels,
  CheckoutAccountType,
  TemplateTypeMap,
  UpsellPopupTitleMap,
  CheckoutPopupTitleMap,
  DeploymentStatusLabels,
  DeploymentStatus,
} from '../../_models';
import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CheckoutsService } from '../../_services/checkouts.service';
import { OfferIntents } from '../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudPagedListComponent } from '../../_directives';
import { Location } from '@angular/common';
import { AlertService, CampaignService, LoaderService } from '../../_services';
import * as moment from 'moment';

@Component({
  selector: 'checkouts',
  templateUrl: './checkouts.component.html',
  styleUrls: ['./checkouts.component.scss'],
})
export class CheckoutsComponent extends CrudPagedListComponent implements OnInit {
  isCheckoutPage: boolean = true;
  selectedFilter: CheckoutStatus = null;
  selectedDate = CheckoutDateType.LastModified;
  checkoutTypes = CheckoutType;
  CheckoutAccountType = CheckoutAccountType;
  checkoutReviewStatusTypes = CheckoutReviewStatus;
  checkoutReviewStatusTypesLabels = CheckoutReviewStatusTypesLabels;
  checkoutDateTypes = CheckoutDateType;
  CheckoutPopup = CheckoutPopup;
  selectedRow: number = -1;
  searchQuery: string;
  offerIntents = OfferIntents;
  tableStatuses = CheckoutStatus;
  tableStatusLabels = TableStatusLabels;
  campaigns: { id: number; name: string }[] = [];
  checkoutAccountTypeLabels = CheckoutAccountTypeLabels;
  popupData = {
    name: '',
    checkout_id: null,
    upsell_id: null,
    popupType: null,
    checkout_data: {},
    upsell_data: {},
    campaigns: [],
  };
  checkoutData = {
    name: '',
    subdomain: '',
    gateway_type: null,
    campaign: null,
  };
  tableData: Checkout[] = [];
  upsellTableData: any[] = [];
  originalTableData: Checkout[] = [...this.tableData];
  originalUpsellTableData: any[] = [...this.upsellTableData];
  isLoading = false;
  popupTitle: string = '';
  deploymentStatusLabels = DeploymentStatusLabels;
  deploymentStatus = DeploymentStatus;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected checkoutService: CheckoutsService,
    protected alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService,
    protected campaignService: CampaignService
  ) {
    super(router, location, route, checkoutService, alertService);
    this.objectName = 'checkout';
  }

  ngOnInit() {
    this.fetchCampaigns();
    this.fetchCheckoutList();
  }

  fetchCheckoutList() {
    this.loader.show();
    this.checkoutService.list({}).subscribe(
      (response) => {
        this.loader.hide();
        this.tableData = response.results.map((item: Checkout) => {
          const lastModifiedMoment = moment(item.updated).tz('EST');

          const lastModified = {
            time: lastModifiedMoment.format('hh:mm A'),
            date: lastModifiedMoment.format('MM/DD/YYYY'),
          };

          return {
            id: item.id,
            name: item.name,
            url: item.url.replace('https://', ''),
            template_type: item.template_type,
            account_type: item.account_type,
            status: this.getCheckoutStatus(item.status, item.deployment_status),
            review_status: item.review_status,
            visitors: item.visitors || '--',
            orders: item.orders || '--',
            revenue: item.revenue || '--',
            aov: item.aov || '--',
            cvr: item.cvr || '--',
            deployment_status: item.deployment_status,
            lastModified,
            campaign: item.campaign || null,
          };
        });

        this.originalTableData = this.tableData;
        this.filterTableData();
      },
      (error) => {
        this.loader.hide();
        this.handleError(error);
      }
    );
  }

  fetchCampaigns() {
    this.loader.show();
    this.campaignService.list({}).subscribe(
      (response) => {
        this.campaigns = response.results.map(({ id, name }) => ({ id, name }));
        this.loader.hide();
      },
      (error) => {
        this.loader.hide();
        this.handleError(error);
      }
    );
  }

  getCheckoutStatus(status: CheckoutStatus, deploymentStatus: DeploymentStatus): CheckoutStatus {
    return deploymentStatus === DeploymentStatus.Deployed ? CheckoutStatus.Active : status;
  }

  togglePathList(index: number): void {
    if (this.selectedRow === index) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = index;
    }
  }

  saveCheckout(data: any): void {
    if (data && this.popupData.popupType === CheckoutPopup.Create) {
      this.checkoutData.name = data.name;
      this.checkoutData.campaign = data.campaign;
      this.checkoutData.gateway_type = data.gateway_type;
      this.checkoutData.subdomain = data.subdomain;
      this.closeCheckoutPopup();
      this.ngxSmartModalService.getModal('merchantAccount').open();
    } else {
      this.fetchCheckoutList();
      this.closeCheckoutPopup();
    }
  }

  saveUpsell(data: any): void {
    this.closeUpsellPopup();
  }

  saveMerchant(data: any): void {
    this.closeMerchantPopup();
    if (data) {
      this.router.navigate([
        'checkouts',
        'templates',
        this.checkoutData.name,
        'campaign',
        this.checkoutData.campaign,
        'account',
        data.checkoutAccountType,
        'gateway',
        this.checkoutData.gateway_type,
        'subdomain',
        this.checkoutData.subdomain,
      ]);
    }
  }

  searchData(): void {
    if (this.isCheckoutPage) {
      this.tableData = this.originalTableData.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    } else {
      this.upsellTableData = this.originalUpsellTableData.filter((item) =>
        item.stepName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }

  editCheckout(checkoutId: number) {
    this.router.navigate(['checkouts', 'checkout-builder', checkoutId]);
  }

  openCreateUpsellPopup(): void {
    this.popupTitle = 'Create New Upsell';
    this.popupData.popupType = CheckoutPopup.Create;
    this.ngxSmartModalService.getModal('upsellPopup').open();
  }

  openCheckoutPopup(popupType: number, checkoutId?: number | string, checkoutName?: string, checkout_data?: any): void {
    this.popupTitle = CheckoutPopupTitleMap[popupType];

    this.closeMenu();

    this.popupData = {
      ...this.popupData,
      name: checkoutName || '',
      checkout_id: checkoutId || null,
      campaigns: this.campaigns.length ? this.campaigns : [],
      popupType: popupType,
      checkout_data: checkout_data || null,
    };

    this.ngxSmartModalService.getModal('checkoutPopup').open();
  }

  openUpsellPopup(popupType: number, upsellId?: number | string, upsellName?: string, upsell_data?: any): void {
    this.popupTitle = UpsellPopupTitleMap[popupType];
    this.closeUpsellMenu();

    this.popupData.name = upsellName || '';
    this.popupData.upsell_id = upsellId || null;
    this.popupData.popupType = popupType;
    this.popupData.upsell_data = upsell_data || null;
    this.ngxSmartModalService.getModal('upsellPopup').open();
  }

  closeCheckoutPopup(): void {
    this.ngxSmartModalService.getModal('checkoutPopup').close();
  }

  closeUpsellPopup(): void {
    this.ngxSmartModalService.getModal('upsellPopup').close();
  }

  closeMerchantPopup(): void {
    this.ngxSmartModalService.getModal('merchantAccount').close();
  }

  onUpsell(): void {
    this.isCheckoutPage = false;
  }

  closeOtherMenus(currentItem: any, tableType: string): void {
    this.tableData.forEach((item) => {
      if (item !== currentItem) {
        item.showThreeDotMenu = false;
      }
    });
  }

  closeOtherUpsellMenus(currentItem: any): void {
    this.upsellTableData.forEach((item) => {
      if (item !== currentItem) {
        item.showThreeDotMenu = false;
      }
    });
  }

  toggleThreeDotMenu(item: any, tableType: string): void {
    this.closeOtherMenus(item, tableType);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  toggleUpsellThreeDotMenu(item: any): void {
    this.closeOtherUpsellMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  openDeclinedStatusModal(): void {
    this.ngxSmartModalService.getModal('declinedStatusPopup').open();
  }

  closeThreeDotMenu(showMenu: boolean): void {
    showMenu = false;
  }

  closeMenu(): void {
    this.selectedRow = -1;
    this.tableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }

  closeUpsellMenu(): void {
    this.selectedRow = -1;
    this.upsellTableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }

  getTemplateType(type: number): string {
    return TemplateTypeMap[type];
  }

  filterTableData(): void {
    this.tableData = this.applyFilter(this.originalTableData);
    this.upsellTableData = this.applyFilter(this.originalUpsellTableData);
  }

  isArchived(status: CheckoutStatus): boolean {
    return status === CheckoutStatus.Archived;
  }

  applyFilter(checkoutList: Checkout[]): Checkout[] {
    if (this.selectedFilter === null) {
      return [...checkoutList];
    } else if (this.selectedFilter === CheckoutStatus.Archived) {
      return checkoutList.filter((item) => item.status === CheckoutStatus.Archived);
    }

    return checkoutList.filter((item) => item.status === this.selectedFilter);
  }

  openUrl(url: string, deploymentStatus: DeploymentStatus): void {
    if (deploymentStatus === DeploymentStatus.Deployed) {
      window.open('https://' + url, '_blank');
    } else {
      this.alertService.error('Checkout is not deployed yet');
    }
  }

  popupIconMap: Partial<Record<CheckoutPopup, string>> = {
    [CheckoutPopup.Create]: '/assets/stepAssets/create-icon.svg',
    [CheckoutPopup.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [CheckoutPopup.Update]: '/assets/stepAssets/rename.svg',
    [CheckoutPopup.Delete]: '/assets/stepAssets/delete-icon.svg',
    [CheckoutPopup.Archive]: '/assets/IconsLibrary/archive-icon.svg',
    [CheckoutPopup.Unarchive]: '/assets/IconsLibrary/unarchive-icon.svg',
  };
}
