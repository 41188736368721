import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FileService} from "./file.service";
import {ClipboardService} from "./clipboard.service";

@Injectable()
export class AudioFileService extends FileService {
  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, clipboardService, 'audio');
    this.fileType = 'audio'
  }
}
