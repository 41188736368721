import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../_services";

@Component({
  selector: "solvpath-loader",
  templateUrl: 'loader.component.html',
  styleUrls:['loader.component.css'],
})
export class LoaderComponent implements OnInit {
  constructor(public loader: LoaderService) {}

  ngOnInit(): void {}
}
