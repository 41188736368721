import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from "./pagination.service";
import {BaseModel} from '../_models';

@Injectable()
export class WidgetCategoryService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'widget_categories');
  }
}
