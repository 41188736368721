import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  CampaignService,
  FunnelService,
  PhoneService,
  SipCredentialService,
  UserService
} from '../_services';
import {CrudSaveComponent} from '../_directives';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {
  AutoResponderType,
  Campaign,
  CampaignStatus,
  Funnel,
  FunnelType,
  getPhoneLabel,
  Pager,
  Phone,
  PhoneCarrierType,
  PhoneUseTypeEnum,
  SipCredential,
  SipIpAddress,
  User
} from "../_models";
import {config} from '../../config/config';
import {EMPTY, forkJoin} from "rxjs";
import {mergeMap, takeUntil} from "rxjs/operators";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-phone.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignPhoneComponent extends CrudSaveComponent implements OnInit {
  assignedFunnels: Funnel[] = [];
  funnels: Funnel[] = [];
  assignedPhones: Phone[] = [];
  phones: Phone[] = [];
  showPhoneSetup = false;
  isNewCampaign = false;
  ready = false;
  campaign: Campaign;
  selectedSipCredential: SipCredential;
  user: User;

  private originalPhoneAssignments = {};
  private ipAddressMap = {};
  autoResponderType = AutoResponderType.SMS

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    private funnelService: FunnelService,
    private phoneService: PhoneService,
    private formBuilder: FormBuilder,
    private modalService: NgxSmartModalService,
    private sipCredentialService: SipCredentialService,
    private userService: UserService
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = 'Setup Brand Phone';
    this.isPartial = true;
    this.objectName = 'Brand';
  }

  ngOnInit() {
    let selectedFunnelMap = {};

    this.form = this.formBuilder.group({
      phone_input_timeout: [5, [Validators.required, Validators.min(1)]],
      call_recording_enabled: [false],
      call_frequency_timeout: [60, [Validators.required, Validators.min(0)]],
      call_frequency_threshold: [3, [Validators.required, Validators.min(0)]],
      call_frequency_afterhours: [false],
      sms_domain: [null],
      sip_domain: this.formBuilder.group({
        inbound_secure: [false],
        ip_addresses: [null]
      }),
      autoresponder_triggers: this.formBuilder.array([]),
      alpha_sender_id: [null, [Validators.maxLength(11)]],
    });

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        let ipAddresses = [];
        let sipDomain = {};

        this.campaign = campaign;
        this.campaignService.showMenu(campaign, 'phone', this.isNewCampaign, campaign.is_hybrid);

        if (campaign.sip_domain) {
          sipDomain = campaign.sip_domain;

          if (campaign.sip_domain.ip_addresses && Array.isArray(campaign.sip_domain.ip_addresses)) {
            campaign.sip_domain.ip_addresses.forEach((ipAddress: SipIpAddress) => {
              const cidrAddress = ipAddress.ip_address + '/' + ipAddress.cidr_length.toString();
              this.ipAddressMap[cidrAddress] = ipAddress.id;
              ipAddresses.push(cidrAddress);
            });

            ipAddresses.sort();
            sipDomain['ip_addresses'] = ipAddresses.join("\n");
          }
        }

        this.form.patchValue(Object.assign({}, campaign,
          {
            sip_domain: sipDomain,
            call_frequency_timeout: Math.floor(campaign.call_frequency_timeout / 60),
            alpha_sender_id: campaign.alpha_sender_id,
          }));

        for (let funnel_id of campaign.voice_entry_funnels) {
          selectedFunnelMap[funnel_id] = true;
        }

        return forkJoin([
          this.phoneService.list({page: 1, page_size: config.maxPageSize}),
          this.funnelService.list({resourcetype: 'VoiceFunnel', page: 1, page_size: config.maxPageSize}),
          this.userService.getCurrent()
        ]);
      }

      return EMPTY;
    })).subscribe(
      (data: [Pager, Pager, User]) => {
        if (data) {
          const allPhones = data[0].results;
          this.user = data[2];
          let assignedPhones = [];
          let phones = [];

          allPhones.forEach((phone: Phone) => {
            phone.showProvider = this.user.is_staff;

            if (!phone.campaign) {
              phones.push(phone);
            } else if (phone.campaign === this.campaign.id) {
              phones.push(phone);
              assignedPhones.push(phone);
              this.originalPhoneAssignments[phone.id] = true;
            }
          });

          this.assignedPhones = assignedPhones;
          this.phones = phones;

          if (this.assignedPhones && this.assignedPhones.length) {
            this.showPhoneSetup = true;
          }

          for (let funnel of data[1].results) {
            this.funnels.push(funnel);

            if (funnel.id in selectedFunnelMap) {
              this.assignedFunnels.push(funnel);
            }
          }
        }

        this.ready = true
      },
      error => {
        this.handleError(error);
      }
    );
  }

  getFunnelLabel(funnel: Funnel) : string {
    let label = funnel.is_template ? 'System Template - ' + funnel.name : funnel.name;
    let features = [];

    if (funnel.carrier_type === PhoneCarrierType.Mobile) {
      features.push('Mobile');
    }
    else if (funnel.carrier_type === PhoneCarrierType.Landline) {
      features.push('Landline');
    }

    if (funnel.is_after_hours) {
      features.push('After Hours');
    }

    if (funnel.match_required) {
      features.push('Match Required');
    }

    if (features.length) {
      label += ' (' + features.join(', ') + ')'
    }

    return label;
  }

  isSipEnabled() {
    return !!this.campaign.sip_domain
  }

  private isSipAvailable() {
    return this.campaign && (this.campaign.status !== CampaignStatus.Inactive)
  }

  enableSip(event) {
    if (this.isSipAvailable() && !this.isSipEnabled()) {
      this.loading = true;

      //save empty sip data so we get a sip domain
      this.campaignService.patch(this.campaign.id, {sip_domain: {}})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (campaign: Campaign) => {
            this.campaign = campaign;
            this.form.patchValue(campaign);
            this.loading = false;
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  private isTwilioSipConfigured() {
    return this.campaign && this.campaign.sip_domain && (
      (this.campaign.sip_domain.ip_addresses && this.campaign.sip_domain.ip_addresses.length) ||
      (this.campaign.sip_domain.credentials && this.campaign.sip_domain.credentials.length)
    )
  }

  isTwilioSipAvailable() {
    return this.isSipAvailable() && this.isTwilioSipConfigured();
  }

  isTelnyxSipAvailable() {
    return this.isSipAvailable()
  }

  addSipCredential() {
    this.selectedSipCredential = {
      id: null,
      username: null,
      password: null
    } as SipCredential;
    this.openSipCredentialDialog();
  }

  editSipCredential(credential: SipCredential) {
    this.selectedSipCredential = credential;
    this.openSipCredentialDialog();
  }

  deleteSipCredential(credential: SipCredential) {
    if (this.isSipEnabled() &&
        window.confirm("Are you sure you want to delete SIP credentials for user '" + credential.username + "'?")) {
      this.loading = true;
      this.sipCredentialService.setDomainId(this.campaign.sip_domain.id);
      this.sipCredentialService.delete(credential.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          result => {
            this.alertService.success('Deleted SIP user.');
            this.loading = false;
            this.campaign.sip_domain.credentials = this.campaign.sip_domain.credentials.filter(
              data => data.id != credential.id);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  protected openSipCredentialDialog() {
    this.modalService.getModal('sipCredentialDialog').open();
  }

  onSaveSipCredential(credential: SipCredential) {
    this.modalService.getModal('sipCredentialDialog').close();

    // add the credential to our list if it's new
    if (!this.campaign.sip_domain.credentials.some((selectedCredential: SipCredential) => {
      return selectedCredential.id === credential.id;
    })) {
      this.campaign.sip_domain.credentials.push(credential);
    }
  }

  getPhoneLabel(phone: Phone) {
    return getPhoneLabel(phone);
  }

  protected getFormData() {
    let voice_funnel_ids = [];
    let data = {
      call_frequency_timeout: this.form.value.call_frequency_timeout * 60
    };
    let ipAddresses = [];

    if (this.form.value.sip_domain) {
      if (this.form.value.sip_domain.ip_addresses) {
        this.form.value.sip_domain.ip_addresses.split("\n").forEach(line => {
          line = line.trim();

          if (line) {
            const parts = line.split('/');

            if (parts && parts.length) {
              const ipAddress = parts[0];
              const cidrLength = (parts.length === 2) ? parseInt(parts[1]) : 32;
              const cidrAddress = ipAddress + '/' + cidrLength.toString();

              ipAddresses.push({
                id: (cidrAddress in this.ipAddressMap) ? this.ipAddressMap[cidrAddress] : null,
                ip_address: ipAddress,
                cidr_length: cidrLength
              });
            }
          }
        });
      }

      data['sip_domain'] = Object.assign({}, this.form.value.sip_domain, {ip_addresses: ipAddresses});
    }

    for (let funnel of this.assignedFunnels) {
      if (funnel.resourcetype == FunnelType.Voice) {
        voice_funnel_ids.push(funnel.id);
      }
    }

    data['voice_entry_funnels'] = voice_funnel_ids;

    let autoresponder_triggers = []
    this.form.get('autoresponder_triggers')['controls'].forEach(item => {
      let value = {...item.value};
      if (value['autoresponder'] === 'null') {
        value['autoresponder'] = null
      }
      autoresponder_triggers.push(value);
    })
    if (this.canConfigureAutoresponders) {
      data['autoresponder_triggers'] = autoresponder_triggers
    }

    return Object.assign({}, this.form.value, data);
  }

  protected onSaveComplete(data) {
    let observables = [];
    let newPhoneAssignments = {};

    this.assignedPhones.forEach((phone: Phone) => {
      if (!(phone.id in this.originalPhoneAssignments)) { // phone changed from unassigned to assigned
        observables.push(this.phoneService.patch(phone.id, {resourcetype: phone.resourcetype,
          campaign: this.id}));
      }

      newPhoneAssignments[phone.id] = true; // map the assigned phone for faster lookup
    });

    this.phones.forEach((phone: Phone) => {
      if ((phone.id in this.originalPhoneAssignments) && !(phone.id in newPhoneAssignments)) {
        // phone assignment changed from assigned to unassigned
        if (window.confirm('Are you sure you want to remove ' + phone.phone + ' from this campaign?')) {
          observables.push(this.phoneService.patch(phone.id, {resourcetype: phone.resourcetype,
            campaign: null, service: null}));
        }
      }

    });

    // call all the update apis to update the phones
    if (observables.length) {
      forkJoin(observables)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.onPostSaveComplete(data);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          }
        );
    } else {
      this.onPostSaveComplete(data);
    }
  }

  private onPostSaveComplete(data) {
    if (this.isNewCampaign) {
      this.campaignService.getNextMenuRoute().pipe(takeUntil(this.destroy$)).subscribe((route: string) => {
        this.navigate(['/campaign', route, this.id, {isNewCampaign: true}], {replaceUrl: true});
      });
    }
    else {
      this.goBack();
    }
  }
  get autoresponder_triggers() {
    return <FormArray>this.form.get('autoresponder_triggers')['controls'];
  }

  get canConfigureAutoresponders() {
    return this.assignedPhones && this.assignedPhones.length &&
      this.assignedPhones.some((phone: Phone) => phone.use_type !== PhoneUseTypeEnum.VoiceOnly);
  }

  get isBypassed() {
    return this.isFieldEqual('call_frequency_threshold', '1') &&
      this.isBooleanFieldChecked('call_frequency_afterhours');
  }

  forceBypass(event) {
    if (this.isBypassed) {
      this.form.patchValue({call_frequency_threshold: 2});
    }
    else {
      this.form.patchValue({call_frequency_threshold: 1, call_frequency_afterhours: true});
    }
  }
}
