import {typeBackButton} from './back-button-type';

const backButton = editor => {
  const bm = editor.BlockManager;
  bm.add('back-button', {
    category: 'Basic',
    media: '<i class="fa fa-2x fa-arrow-left"></i>',
    label: `Back Button`,
    content: { type: typeBackButton },
  });
};
export default backButton;
