import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

export interface FilterValue {
  key: any; 
  value: string; 
}

export interface Filter {
  name: string;
  values: FilterValue[]; 
  activeValue: any;
  showSubPopup?: boolean;
}

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit {
  @Input() filters: Filter[] = [];
  @Output() filterChanged = new EventEmitter<{ filterName: string; key: any }>();

  showFilterPopup = false;
  protected _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  ngOnInit() {}

  toggleMainFilterPopup(): void {
    if (this.showFilterPopup) {
      this.closeAllPopups();
    } else {
      this.showFilterPopup = true;
    }
  }

  toggleSubPopup(filter: any): void {
    this.filters.forEach((f) => {
      if (f !== filter) {
        f.showSubPopup = false;
      }
    });

    filter.showSubPopup = !filter.showSubPopup;
  }

  onFilterValueClick(filterName: string, key: any): void {
    const filter = this.filters.find((f) => f.name === filterName);
    if (filter) {
      filter.activeValue = key;
    }

    this.filterChanged.emit({ filterName, key });
    this.closeAllPopups();
  }

  getActiveFilters(): string {
    return this.filters
      .filter(filter => filter.activeValue !== undefined && filter.activeValue !== null) 
      .map(filter => {
        const activeKey = filter.activeValue;
        const activeValue = filter.values.find(value => value.key === activeKey);
        return activeValue ? activeValue.value : '';  
      })
      .filter(value => value)  
      .join(', ');  
  }
  

  closeAllPopups(): void {
    this.showFilterPopup = false;
    this.filters.forEach((filter) => (filter.showSubPopup = false));
  }
}
