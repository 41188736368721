import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import {PhoneCall} from "../_models";

@Injectable()
export class PhoneCallService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'phone_calls');
  }

  getRecordingUrl(phoneCall: PhoneCall) {
    return this.http.get(this.route + '/' + phoneCall.id.toString() + '/recording_url/');
  }
}
