import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import {BankAccountVerification, PaymentSourceType} from "../_models";

@Injectable()
export class PaymentSourceService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'account/payment_sources');
  }

  createFromToken(type: PaymentSourceType, token: string) {
    return this.http.post(this.route + '/', {resourcetype: type, token: token});
  }

  getPlaidLinkToken() {
    return this.http.post(this.route + '/plaid_token/', {});
  }

  createFromPlaidToken(publicToken: string, accountId: string) {
    return this.http.post(this.route + '/', {
      resourcetype: PaymentSourceType.BankAccount,
      token: publicToken,
      plaid_account_id: accountId
    });
  }

  verifyDeposits(sourceId: string | number, data: BankAccountVerification) {
    return this.http.post(this.route + '/' + sourceId.toString() + '/verify_bank/', data);
  }
}
