import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertService, InvoiceTransactionService, UserService} from "../_services";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {Form} from "../_forms";
import {
  ChargeStatusLabels,
  Transaction,
  TransactionSource,
  TransactionType,
  TransactionTypeLabels,
  User
} from "../_models";
import {takeUntil} from "rxjs/operators";
import {NgxSmartModalService} from "ngx-smart-modal";
import {formatISODate} from "../_helpers";

@Component({
  moduleId: module.id.toString(),
  selector: 'invoice-transactions',
  templateUrl: './invoice-transaction.component.html',
  styleUrls: ['./invoice-transaction.component.css']
})
export class InvoiceTransactionComponent extends Form implements OnInit {
  user: User;
  selectedTransaction: Transaction;
  @Input('transactions') transactions: Transaction[] = [];
  @Output('update') onUpdate: EventEmitter<any> = new EventEmitter<any>();
  protected readonly formatISODate = formatISODate;

  constructor(
    protected alertService: AlertService,
    protected router: Router,
    protected location: Location,
    private tranService: InvoiceTransactionService,
    private userService: UserService,
    private modalService: NgxSmartModalService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  getTransactionType(tran: Transaction) {
    return TransactionTypeLabels[tran.type];
  }

  getTransactionStatus(tran: Transaction) {
    return ChargeStatusLabels[tran.status];
  }

  canDeleteTransaction(tran: Transaction) {
    return (tran.source == TransactionSource.External || tran.source == TransactionSource.BalanceAdjustment) &&
      this.user &&
      this.user.is_staff;
  }

  deleteTransaction(tran: Transaction) {
    if (this.canDeleteTransaction(tran) && window.confirm('Are you sure you want to delete this transaction?')) {
      this.loading = true;

      this.tranService.delete(tran.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          result => {
            this.alertService.success('Deleted transaction.');
            this.onUpdate.emit();
            this.loading = false;
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  canEditTransaction(tran: Transaction) {
    return this.user && this.user.is_superuser && (
      [
        TransactionType.Charge,
        TransactionType.UsageCharge,
        TransactionType.SubscriptionAdjustment
      ].indexOf(tran.type) >= 0
    );
  }

  canRefundTransaction(tran: Transaction) {
    return tran.is_refundable && this.user && this.user.is_superuser;
  }

  refund(tran: Transaction) {
    if (this.canRefundTransaction(tran)) {
      this.selectedTransaction = tran;
      this.modalService.getModal('tranRefundDialog').open();
    }
  }

  onTransactionRefunded(tran: Transaction) {
    this.modalService.getModal('tranRefundDialog').close();
    this.onUpdate.emit();
    this.selectedTransaction = null;
  }

  edit(tran: Transaction) {
    if (this.canEditTransaction(tran)) {
      this.selectedTransaction = tran;
      this.modalService.getModal('tranEditDialog').open();
    }
  }

  onTransactionEdited(tran: Transaction) {
    this.modalService.getModal('tranEditDialog').close();
    this.onUpdate.emit();
    this.selectedTransaction = null;
  }
}
