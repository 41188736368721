import {Component, OnInit} from '@angular/core';
import {ShippingSystemNewComponent} from './shipping-system-new.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ShippingSystemService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {ShippingSystem, ShippingSystemType} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './shipping-system-edit.component.html',
  styleUrls: ['./shipping-system.component.css']
})

export class ShippingSystemEditComponent extends ShippingSystemNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected shippingSystemService: ShippingSystemService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, shippingSystemService, alertService, formBuilder);
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.data$.subscribe((item: ShippingSystem) => {
      if (item) {
        this.shippingSystem = item
        this.objectName = item.display_name
        this.form.patchValue({
          display_name: item.display_name,
          type: item.type
        })
        this.selectShippingSystemType(item.type)
        this.address = {
          id: null,
          address1: item.address1,
          address2: item.address2,
          city: item.city,
          state: item.state,
          zip: item.zip,
          country: item.country,
        }
      }
    }, error => {
      this.handleError(error)
    })
  }

  selectShippingSystemType(type) {
    if (this.shippingSystemTypes) {
      let shippingSystemType = this.shippingSystemTypes.filter((shippingType: ShippingSystemType) => shippingType.id === type)
      if (shippingSystemType.length > 0) {
        this.selectedShippingSystemType = shippingSystemType[0]
      }
    } else {
      let _this = this
      setTimeout(() => {
        _this.selectShippingSystemType(type)
      }, 100)
    }
  }
}
