import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudService} from "./crud.service";
import {ClipboardService} from "./clipboard.service";

@Injectable()
export class FunnelInputService extends CrudService {
  private inputsNeedingNewStep: {id: string, autopick: boolean}[] = [];

  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, 'funnel_inputs');
  }

  clearStepId() {
    this.route = 'funnel_inputs';
  }

  setStepId(id: string | number) {
    this.route = 'funnel_step/' + id.toString() + '/inputs';
  }

  copy(id: string | number) {
    this.http.post(this.route + '/' + id.toString() + '/copy/', {}).subscribe(
      data => {
        this.clipboardService.copy('funnel_input', data);
      }
    );
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('funnel_input');
  }

  paste() {
    return this.http.post(this.route + '/', this.clipboardService.paste('funnel_input'));
  }

  queueInputForNewStep(id: string | number, autopick: boolean) {
    this.inputsNeedingNewStep.push({id: id.toString(), autopick: autopick});
  }

  getNextInputForNewStep() {
    return this.inputsNeedingNewStep.shift();
  }
}
