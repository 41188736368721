import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CRMService, UserService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {CRM, CRMType, CRMTypes, PluginType, CRMTypeId, LinkFieldType, User, CRMField, CRMFieldSet} from '../_models';
import {CRMTrackingComponent} from "./crm-tracking.component";
import {takeUntil} from "rxjs/operators";
import {CrmChargebackConfigComponent} from './crm-chargeback-config.component';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './crm-edit.component.html',
  styleUrls: ['./crm.component.css']
})
export class CRMNewComponent extends CrudSaveComponent implements OnInit, AfterViewInit {
  types: CRMType[] = [];
  subscriptionPlugins: PluginType[] = [];
  crm: CRM;
  selectedType: CRMType;
  selectedSubscriptionPlugin: PluginType;
  viewPrivateKey = false;
  user: User
  fields: (CRMField | CRMFieldSet)[] = [];
  encryptedFields: (CRMField | CRMFieldSet)[] = [];
  @ViewChild(CRMTrackingComponent, { static: false }) trackingFields: CRMTrackingComponent;
  @ViewChild(CrmChargebackConfigComponent, { static: false }) chargebackConfig: CrmChargebackConfigComponent;

  linkFieldTypes = [
    {value: LinkFieldType.UniqueId, label: 'Unique ID'},
    {value: LinkFieldType.ParentOrderId, label: 'Parent Order ID'},
    {value: LinkFieldType.PreviousOrderId, label: 'Previous Order ID'},
  ]

  protected typeMap: {} = {};
  protected subscriptionPluginMap: {} = {};

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected crmService: CRMService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, crmService, alertService);
    this.isNew = true;
    this.objectName = 'CRM';
    this.title = 'Add New CRM';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      type: [null, Validators.required],
      subscription_plugin: [null],
      max_fulfillments: [null, [Validators.min(0)]],
      max_transactions: [null, [Validators.min(0)]],
      auto_approve: [true],
      use_partial_refunds: [true],
      allow_update_fulfillment: [true],
      link_id_field: [null],
      link_field_type: [LinkFieldType.UniqueId],
      credentials: this.formBuilder.group({}),
      config: this.formBuilder.group({}),
      display_name: [null]
    });

    super.ngOnInit();

    this.crmService.getTypes()
      .subscribe(
        (types: CRMTypes) => {
          this.types = types.crms;

          types.crms.forEach((type: CRMType) => {
            this.typeMap[type.id] = type;
          });

          types.subscription_plugins.forEach((plugin: PluginType) => {
            this.subscriptionPluginMap[plugin.id] = plugin;
          });

          if (this.crm) {
            this.selectedType = this.typeMap[this.crm.type];

            if (this.crm.subscription_plugin) {
              this.selectedSubscriptionPlugin = this.subscriptionPluginMap[this.crm.subscription_plugin];
            }

            if (!this.crm.display_name) {
              this.form.patchValue({display_name: this.selectedType.name})
            }
          }

          this.updateFields();
        },
        error => {
          this.handleError(error);
        }
      );

    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user
      })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.trackingFields) {
        this.form.addControl('tracking_data', this.trackingFields.form);
      }
    }, 0);
  }

  sync() {
    if (this.crm) {
      this.loading = true;

      this.crmService.sync(this.crm.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {this.loading = false;},
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  get allowedTypes() {
    return this.types.filter((type: CRMType) => (this.crm && this.crm.type === type.id) || !type.auto_config);
  }

  selectType(event) {
    const selectedTypeId = event.target.value;

    if (selectedTypeId) {
      this.selectedType = this.typeMap[selectedTypeId];
    }
    else {
      this.selectedType = null
    }

    if (this.selectedType) {
      this.form.patchValue({display_name: this.selectedType.name});
    }

    this.form.patchValue({subscription_plugin: null}); // clear this when type changes
    this.buildSubscriptionPluginList();
    this.selectedSubscriptionPlugin = null;
    this.updateFields();
  }

  protected buildSubscriptionPluginList() {
    let subscriptionPlugins = [];

    if (this.selectedType && this.selectedType.subscription_plugins) {
      this.selectedType.subscription_plugins.forEach((pluginId: number) => {
        subscriptionPlugins.push(this.subscriptionPluginMap[pluginId]);
      });
    }

    this.subscriptionPlugins = subscriptionPlugins;
  }

  selectSubscriptionPlugin(event) {
    const selectedId = event.target.value;

    if (selectedId) {
      this.selectedSubscriptionPlugin = this.subscriptionPluginMap[selectedId];
    }
    else {
      this.selectedSubscriptionPlugin = null;
    }

    this.updateFields();
  }

  isCustomType() {
    return this.selectedType && (this.selectedType.id === CRMTypeId.Custom);
  }

  protected updateFields() {
    let fields = [];
    let encryptedFields = [];

    if (this.selectedType) {
      fields = this.selectedType.fields.slice();
      encryptedFields = this.selectedType.encrypted_fields.slice();

      if (this.selectedSubscriptionPlugin) {
        fields.push({fieldset: 'subscription_plugin', label: this.selectedSubscriptionPlugin.name,
          fields: this.selectedSubscriptionPlugin.fields});
        encryptedFields.push({fieldset: 'subscription_plugin', label: this.selectedSubscriptionPlugin.name,
          fields: this.selectedSubscriptionPlugin.encrypted_fields});
      }
    }

    this.fields = fields;
    this.encryptedFields = encryptedFields;
  }

  protected getFormData() {
    ['max_transactions', 'max_fulfillments'].forEach(field => {
      if ((field in this.form.value) && this.form.value[field] && (this.form.value[field].toString().trim() === '')) {
        this.form.value[field] = null;
      }
    });

    let data = this.form.value

    if (this.chargebackConfig) {
      data = {...data, ...this.chargebackConfig.getFormData()}
    }

    return data;
  }

  protected onSaveComplete(data) {
    if (this.isCustomType()) {
      this.loading = false;
      this.goBack();
    }
    else {
      this.crmService.sync(this.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.loading = false;
            this.goBack();
          },
          error => {
            this.loading = false;
            this.handleSubmitError(error);
          });
    }
  }
}
