import { Component, OnInit } from "@angular/core";
import { EngagementCards, InfoCards, User } from "../../../app/_models";
import { StorageService } from "../../../app/_services";
import { InlineTableData } from "../data-visualization/data-table/data-table.component";
import { DoughnutChartData } from "../data-visualization/doughnut-chart/doughnut-chart.component";
import {
  campaigns,
  customerRequests,
  engagementCardData,
  faqs,
  overviewHints,
  overviewInfoCardsData,
  sessions,
  sessionsDoughnutData,
  sessionsTableData,
} from "./overview.data";

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./../dashboard-section.scss"],
})
export class OverviewComponent implements OnInit {
  currentUser: User;
  isDashboardSession: boolean = true

  /* Cards Data */
  infoCards: InfoCards = overviewInfoCardsData;
  engagementCards: EngagementCards = engagementCardData;

  /* Charts Data */
  campaigns = campaigns;
  faqs = faqs;
  sessionsDoughnutData: DoughnutChartData = sessionsDoughnutData;
  customerRequests = customerRequests;
  sessionsTableData: InlineTableData = sessionsTableData;
  sessions = sessions;

  /* Misc */
  overviewHints = overviewHints;

  constructor(private storageService: StorageService) {}

  async ngOnInit() {
    this.currentUser = this.storageService.get("currentUser");
    this.infoCards.forEach((card) => (card.value = ""));
    this.engagementCards.forEach((card) => (card.count = ""));
    this.customerRequests.loading = true;
    this.faqs.loading = true;
    this.campaigns.loading = true;

    this.sessionsDoughnutData.noData = false;
    this.sessionsDoughnutData.loading = true;
    this.sessionsTableData.forEach((session) => {
      session.value = null;
      session.percentage = null;
    });
  }
}
