import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  MultiOption,
  MultiOptions,
  MultiSubOption,
  MultiSubOptions,
} from "./multi-select";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-custom-multi-select",
  templateUrl: "./custom-multi-select.component.html",
  styleUrls: ["./custom-multi-select.component.scss"],
})
export class CustomMultiSelectComponent implements OnInit, OnChanges {
  @Input() options: MultiOptions = [];
  @Input() isFilterOption: boolean;
  @Output() onGetProducts = new EventEmitter<{
    searchTerm: string;
    nextPage: boolean;
  }>();
  @Output() selectedOptionsChange = new EventEmitter<{
    option: MultiOption;
    subOption: MultiSubOption;
  }>();
  @Output() selectedInputsChange = new EventEmitter<{
    option: MultiOption;
    value: string;
  }>();
  @Output() selectedComparisonInputsChange = new EventEmitter<{
    option: MultiOption;
    value: string;
    comparingOption: string;
  }>();
  private _destroy$ = new Subject<void>();
  private searchInputSubject = new Subject<string>();
  isDropdownVisible = false;
  filteredOptions: MultiOptions = [];
  subOptions: MultiSubOptions = [];
  filteredSubOptions: MultiSubOptions = [];
  selectedMainOption: MultiOption = null;
  comparisonDropDownOption: MultiOption;
  comparisonDropDownInput: string;
  comparisonBetweenInput: string;

  constructor() {}

  ngOnInit() {
    this.searchInputSubject
      .pipe(
        debounceTime(800), // adjust the debounce time as needed
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe((searchTerm) => {
        this.handleSubSearch(searchTerm);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.filteredOptions = [...this.options];
    }
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  closeMultiSelect() {
    this.isDropdownVisible = false;
    this.filteredOptions.map((option) => (option.selected = false));
  }

  toggleSubDropdown(option: MultiOption) {
    this.filteredOptions.map((option) => (option.selected = false));
    option.selected = !option.selected;
  }

  onSearchInputChange(searchTerm: string) {
    if (searchTerm.trim() === "") {
      this.filteredOptions = [...this.options];
    } else {
      this.filteredOptions = this.options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  onOptionSelected(option: MultiOption) {
    if (option.loading) return;
    this.subOptions = option.options;
    this.filteredSubOptions = option.options;
    this.toggleSubDropdown(option);
    this.selectedMainOption = option;
  }

  updateFilterOptionState() {
    const activeFilters = this.options.filter(
      (option) => option.activeCount > 0
    );

    if (activeFilters.length >= 10) {
      this.options.forEach((currentOption) => {
        if (currentOption.options && currentOption.activeCount === 0)
          currentOption.options.forEach((sub) => {
            currentOption.disabled = true;
            if (!sub.selected) {
              sub.disabled = true;
            }
          });
        else if (!currentOption.selectedOption) {
          if (currentOption.searchTerm) {
            currentOption.disabled = false;
          } else {
            currentOption.disabled = true;
          }
        }
      });
    } else {
      this.options.forEach((currentOption) => {
        currentOption.disabled = false;
        if (currentOption.options)
          currentOption.options.forEach((sub) => {
            sub.disabled = false;
          });
        else currentOption.disabled = false;
      });
    }
  }

  onSubOptionSelected(option: MultiOption, subOption: MultiSubOption) {
    subOption.selected = !subOption.selected;

    const isRatioField =
      typeof subOption.model === "string" && subOption.model.includes("ratio");

    if (subOption.selected) {
      if (isRatioField) this.onRatioChange(subOption.selected);
    } else if (isRatioField) this.onRatioChange(subOption.selected);
    const selectedCount = this.options.reduce((count, option) => {
      return (
        count +
        (option.options &&
          option.options.filter((suboption) => suboption.selected).length)
      );
    }, 0);

    option.activeCount = subOption.selected
      ? ++option.activeCount
      : --option.activeCount;
    if (option.activeCount < 0) option.activeCount = 0;

    if (!this.isFilterOption) {
      if (selectedCount >= 10) {
        this.options.forEach((option) => {
          option.options.forEach((sub) => {
            if (!sub.selected) {
              sub.disabled = true;
            }
          });
        });
      } else {
        this.options.forEach((option) => {
          if (option.options)
            option.options.forEach((sub) => {
              sub.disabled = false;
            });
        });
      }
    } else {
      this.updateFilterOptionState();
    }

    this.selectedOptionsChange.emit({ option, subOption });
  }

  onRatioChange(selected = false) {
    this.options.forEach((otherOption) => {
      const timePeriodOption = otherOption.options.find(
        (x) => x.model === "time_period"
      );
      const merchantIdOption = otherOption.options.find(
        (x) => x.model === "merchant_id"
      );

      if (timePeriodOption) {
        timePeriodOption.selected = selected;
        timePeriodOption.disabled = selected;
      }

      if (merchantIdOption) {
        merchantIdOption.selected = selected;
        merchantIdOption.disabled = selected;
      }
    });
  }

  onInputChange(option: MultiOption, value: string) {
    option.selected = true;
    this.selectedInputsChange.emit({ option, value });

    option.activeCount = value ? 1 : 0;

    this.updateFilterOptionState();
  }

  onComparisonDropdownChange(option: MultiOption) {
    this.comparisonDropDownOption = option;
    option.selected = true;
    option.activeCount = option.selectedOption ? 1 : 0;

    this.emitSelectedComparisonInputs(
      option,
      this.comparisonDropDownInput,
      this.comparisonBetweenInput || ""
    );
    this.updateFilterOptionState();
  }

  onComparisonInputChange(value: string) {
    this.comparisonDropDownInput = value;
    this.emitSelectedComparisonInputs(
      { ...this.comparisonDropDownOption },
      value,
      this.comparisonBetweenInput || ""
    );
  }

  onComparisonBetweenInputChange(betweenValue: string) {
    this.comparisonBetweenInput = betweenValue;
    this.emitSelectedComparisonInputs(
      { ...this.comparisonDropDownOption },
      this.comparisonDropDownInput,
      betweenValue
    );
  }

  private emitSelectedComparisonInputs(
    option: MultiOption,
    value: string,
    betweenValue?: string
  ) {
    this.selectedComparisonInputsChange.emit({
      option,
      value,
      comparingOption: betweenValue || "",
    });
  }

  onSubSearchInputChange(searchTerm: string) {
    if (this.selectedMainOption.pagination) {
      this.searchInputSubject.next(searchTerm);
    } else {
      this.handleSubSearch(searchTerm);
    }
  }

  handleSubSearch(searchTerm: string) {
    if (this.selectedMainOption.pagination) {
      this.onGetProducts.emit({ searchTerm, nextPage: false });
    } else {
      if (searchTerm.trim() === "") {
        this.filteredSubOptions = [...this.subOptions];
      } else {
        this.filteredSubOptions = this.subOptions.filter((subOption) =>
          subOption.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
    }
  }

  onSubDropdownScroll(event: any, options: MultiSubOptions) {
    if (!this.selectedMainOption.pagination) return;
    const subDropdown = event.target;
    const atBottom =
      subDropdown.scrollHeight - subDropdown.scrollTop ===
      subDropdown.clientHeight;

    if (atBottom && options.length > 49) {
      this.onGetProducts.emit({
        searchTerm: this.selectedMainOption.searchTerm,
        nextPage: true,
      });
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
