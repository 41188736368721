import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudService} from "./crud.service";
import {Observable} from "rxjs";
import {FaqTopic, SelectOption} from "../_models";
import {map} from "rxjs/operators";
import {ClipboardService} from "./clipboard.service";

@Injectable()
export class FaqTopicService extends CrudService {
  private topicMap = {};

  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, 'faq_topics');
  }

  copy(id: string | number) {
    this.http.post(this.route + '/' + id.toString() + '/copy/', {}).subscribe(
      data => {
        this.clipboardService.copy('faq_topic', data);
      }
    );
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('faq_topic');
  }

  paste(parentId: string | number | null) {
    let topic: FaqTopic = this.clipboardService.paste('faq_topic');
    topic.parent = parentId

    return this.http.post(this.route + '/', topic);
  }

  getSelectOptions(): Observable<SelectOption[]> {
    return this.list().pipe(map((topics: FaqTopic[]) => {
      let options = [];
      this.topicMap = {};

      //get all the topics grouped by their parents
      topics.forEach((topic: FaqTopic) => {
        if (topic.parent in this.topicMap) {
          this.topicMap[topic.parent].push(topic);
        } else {
          this.topicMap[topic.parent] = [topic];
        }
      });

      // get all the topics in hierarchical order
      this.addTopicOptions(options);

      return options;
    }));
  }

  private addTopicOptions(options: SelectOption[], rootId = null, rootLabel = '') {
    if (rootId in this.topicMap) {
      this.topicMap[rootId].forEach((topic: FaqTopic) => {
        const label = rootLabel + topic.title;
        options.push({value: topic.id, label: label});
        this.addTopicOptions(options, topic.id, label + ' --> ');
      });
    }
  }

}
