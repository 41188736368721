import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignCategoryService, CampaignService, UserService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CampaignNewComponent} from "../campaign";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: '../campaign/campaign-edit.component.html',
  styleUrls: ['../campaign/campaign-edit.component.css']
})
export class HybridCampaignNewComponent extends CampaignNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    protected categoryService: CampaignCategoryService,
    protected userService: UserService,
    protected modalService: NgxSmartModalService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService, categoryService, userService, modalService,
      formBuilder);
    this.objectName = 'Hybrid Brand';
    this.title = 'New Hybrid Brand';
    this.isHybrid = true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
