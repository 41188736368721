import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {
  AccountService,
  AlertService, CampaignProductService,
  CampaignService,
  CampaignTrackingService, CRMCampaignService, FunnelStepService,
  ReportsConfigService,
  FaqReportsService, StorageService, UserService, RefundReportService, MerchantService, CRMService
} from '../_services';
import {FormBuilder} from "@angular/forms";
import {NgxSmartModalService} from "ngx-smart-modal";
import {ReportsAbstractComponent} from "./reports-abstract.component";
import {CampaignProduct} from "../_models";

@Component({
  selector: 'faq-reports',
  templateUrl: './templates/reports.component.html',
  styleUrls: ['./styles/reports.component.css'],
})
export class FaqReportsComponent extends ReportsAbstractComponent {
  fillAccountDependantFiltersAutomatically = true;
  fields = {
    dimensions: [
      [
        {model: "question", label: "Faq Question", default: false, disabled: false},
        {model: "source", label: "Path channel", default: false, disabled: false},
        {model: "sid1", label: "Source sub 1", default: false, disabled: false},
        {model: "sid2", label: "Source sub 2", default: false, disabled: false},
        {model: "search_phrase", label: "Search Phrase", default: false, disabled: false},
      ],
      [
        {model: "order_status", label: "Order status", default: false, disabled: false},
        {model: "campaign_name", label: "Brand", default: false, disabled: false},
        {model: "crm_campaign_name", label: "CRM campaign", default: false, disabled: false},
        {model: "username", label: "Session ID", default: false, disabled: false},
        {model: "product_name", label: "Product name", default: false, disabled: false},
      ],
      [
        {model: "campaign_product_id", label: "Campaign product id", default: false, disabled: false},
        {model: "result", label: "Session result", default: false, disabled: false},
        {model: "status", label: "Status", default: false, disabled: false},
        {model: "affiliate", label: "Affiliate", default: false, disabled: false},
        {model: "sub1", label: "Aff sub 1", default: false, disabled: false},
      ],
      [
        {model: "sub2", label: "Aff sub 2", default: false, disabled: false},
        {model: "sub3", label: "Aff sub 3", default: false, disabled: false},
        {model: "tracking_source", label: "Path channel source", default: false, disabled: false},
        {model: "session_action_result", label: "Session Action Result", default: false, disabled: false},
        {model: "product_path", label: "Product Path", default: false, disabled: false},
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
      ]
    ],
    timeInterval: [],
    statistics: [
      [
        {model: "number_of_searches", label: "Number of Searches", default: false, disabled: false},
        {model: "results_shown", label: "Number of results shown", default: false, disabled: false},
      ],
      [
        {model: "views", label: "Views", default: false, disabled: false},
      ],
      [
        {model: "upvotes", label: "UP Votes", default: false, disabled: false},
      ],
      [
        {model: "downvotes", label: "Down Votes", default: false, disabled: false},
      ]
    ],
    calculations: [],
    exclude: [],
    filters: [
      [
        {model: "campaign_id", label: "Brand", default: false, disabled: false},
        {model: "crm_campaign_id", label: "CRM Campaign", default: false, disabled: false},
        {model: "product_id", label: "Product", default: false, disabled: false},
      ],
      [
        {model: "tracking_source", label: "Traffic Source", default: false, disabled: false},
        {model: "source", label: "Channel Source", default: false, disabled: false},
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
      ],
      [
        {model: "session_action_result", label: "Session Action Result", default: false, disabled: false},
        {model: "result", label: "Session Result", default: false, disabled: false},
      ],
      [
        {model: "order_status", label: "Order Status", default: false, disabled: false},
        {model: "product_path", label: "Product Path", default: false, disabled: false},
      ]
    ],
    filtersData: [
      [
        {model: "campaign_id", filter: "campaign_id", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "crm_campaign_id", filter: "crm_campaign_id", type: "multi-select", label: "CRM campaign", default: "", options: []},
        {model: "product_id", filter: "product_id", type: "products", label: "Product", default: "", options: []},
        {model: "tracking_source", filter: "tracking_source", type: "multi-select", label: "Traffic source", default: "",
          options: []},
        {model: "source", filter: "source", type: "multi-select", label: "Channel source", default: "",
          options: [
            {id: "Web", text: "Web"},
            {id: "Email", text: "Email"},
            {id: "Phone", text: "Phone"},
            {id: "Social Media", text: "Social Media"},
          ]},
        {model: "session_action_result", filter: "session_action_result", type: "multi-select", label: "Session Action Result", default: "",
          options: [
            {id: 'Cancel', text: 'Cancel'},
            {id: 'RMA for Refund', text: 'RMA for Refund'},
            {id: 'RMA for Exchange', text: 'RMA for Exchange'},
            {id: 'Save', text: 'Save'},
            {id: 'Product Revision', text: 'Product Revision'},
            {id: 'Upsell', text: 'Upsell'},
            {id: 'Subscription Adjustment', text: 'Subscription Adjustment'},
            {id: 'Subscription Downsell', text: 'Subscription Downsell'},
            {id: 'Downsell with Refund', text: 'Downsell with Refund'},
            {id: 'Cancel Subscription', text: 'Cancel Subscription'},
          ]},
        {model: "result", filter: "result", type: "multi-select", label: "Session result", default: "",
          options: [
            {id: "Unknown", text: "Unknown"},
            {id: "Active Session", text: "Active Session"},
            {id: "Timeout", text: "Timeout"},
            {id: "Abandoned", text: "Abandoned"},
            {id: "Complete", text: "Complete"},
            {id: "Lifeline - Timeout", text: "LifelineTimeout"},
            {id: "Lifeline - Undo", text: "Lifeline - Undo"},
            {id: "Lifeline - Exit", text: "Lifeline - Exit"},
            {id: "Lifeline - Error", text: "Lifeline - Error"},
            {id: "Lifeline Contact Failed", text: "Lifeline Contact Failed"},
            {id: "Logged Out", text: "Logged Out"},
            {id: "Bypassed", text: "Bypassed"},
            {id: "Invalid Token", text: "Invalid Token"},
            {id: "Error", text: "Error"},
            {id: "Expired Token", text: "Expired Token"},
            {id: "Lifeline - Search", text: "Lifeline - Search"},
            {id: "Lifeline - No Match", text: "Lifeline - No Match"},
          ]},
        {model: "order_status", filter: "order_status", type: "multi-select", label: "Order Status", default: "",
          options: [
            {id: 'Subscription Active', text: 'Subscription Active'},
            {id: 'Subscription In Trial', text: 'Subscription In Trial'},
            {id: 'One Time Purchase Shipped', text: 'One Time Purchase Shipped'},
            {id: 'One Time Trial Shipped', text: 'One Time Trial Shipped'},
            {id: 'Shipping Processing', text: 'Shipping Processing'},
            {id: 'Shipping Hold', text: 'Shipping Hold'},
            {id: 'Subscription Hold', text: 'Subscription Hold'},
            {id: 'Trial Hold', text: 'Trial Hold'},
            {id: 'InActive', text: 'InActive'},
            {id: 'Rebill Hold', text: 'Rebill Hold'},
            {id: 'Rebill Active', text: 'Rebill Active'},
          ]
        },
        {model: "product_path", filter: "product_path", type: "multi-select", label: "Product Path", default: "",
          options: []},
        {model: "billing_cycle", filter: "billing_cycle", type: "arithmetic-comparison-input", label: "Billing Cycle",
          default: "", options: [], comparisonDefault: 'e', comparisonOptions: [
            {id: "e", text: "Equals to"},
            {id: "gt", text: "Greater than"},
            {id: "gte", text: "Greater than or equals to"},
            {id: "lt", text: "Less than"},
            {id: "lte", text: "Less than or equals to"},
          ]},
      ]
    ]
  }
  showDateFilterTypeField = false;
  useReportsConfiguration = false;
  search_related_fields = ['search_phrase', 'username', 'number_of_searches', 'results_shown'];
  checkedFields = [];
  productsFieldCurrentModel = 'product_id'
  productFilterTitles = {
    product_id: 'Products',
    exchangeable_product_id: '',
    upsell_product_id: ''
  }
  displayIntent = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: FaqReportsService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService,
    protected crmService: CRMService
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService,
      campaignService, reportsConfigService, trackingSourceService, userService, accountService,
      campaignProductService, crmCampaignService, storageService, stepService, refundReportService,
      merchantService, crmService);
    this.title = 'FAQ Reports';
  }

  toggleBooleanField(event, fieldname: string) {
    super.toggleBooleanField(event, fieldname);
    let snapshotRelatedFields = ['source', 'sid1', 'sid2', 'order_status', 'campaign_name', 'crm_campaign_name',
      'campaign_id', 'crm_campaign_id', 'product_id', 'product_name',
      'campaign_product_id', 'status', 'result', 'username', 'affiliate',
      'sub1', 'sub2', 'sub3', 'tracking_source', 'session_action_result', 'product_path', 'billing_cycle',
      'billing_cycle_comparison'
    ]
    if (snapshotRelatedFields.indexOf(fieldname.split('.').slice(-1)[0]) > -1) {
      return;
    }
    if (this.isBooleanFieldChecked(fieldname)) {
      this.checkedFields.push(fieldname);
    } else {
      this.checkedFields.splice(this.checkedFields.indexOf(fieldname), 1);
    }
    Object.keys(this.form.controls).forEach(controlName => {
      let controlGroup = this.form.controls[controlName];
      if (controlGroup['controls']) {
        Object.keys(controlGroup['controls']).forEach(formControlName => {
          let control = controlGroup['controls'][formControlName];
          if (this.search_related_fields.indexOf(fieldname.split('.').slice(-1)[0]) > -1
            && snapshotRelatedFields.indexOf(formControlName) < 0) {
            if (this.search_related_fields.indexOf(formControlName) > -1 || this.checkedFields.length === 0) {
              control.enable();
            } else {
              control.patchValue(false)
              control.disable();
            }
          } else if (snapshotRelatedFields.indexOf(formControlName) < 0) {
            if (this.search_related_fields.indexOf(formControlName) > -1 && this.checkedFields.length > 0) {
              control.disable();
              control.patchValue(false);
            } else {
              control.enable();
            }
          }
        })
      }
    })
  }

  protected updateProductIdFilterData(model) {
    const productIds = [];
    if (model === 'product_id') {
      this.campaignProducts.forEach((product: CampaignProduct) => {
        productIds.push(product.product.id);
      });
    }

    this.form.value.filtersData[model] = productIds;
  }
}
