import {Injectable} from '@angular/core';
import {PaginationService} from '../pagination.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CheckoutGatewayService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'gateways');
  }
}
