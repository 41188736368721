import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, SessionService, CallLogsService} from "../_services";
import {NgxSmartModalService} from "ngx-smart-modal";
import {LogsAbstractComponent} from "./logs-abstract.component";
import {Observable} from "rxjs";
import {CallLogs} from "../_models";
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'phone-logs',
  templateUrl: './logs-template.component.html',
  styleUrls: ['./logs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PhoneLogsComponent extends LogsAbstractComponent {
  logs: CallLogs[];
  fields = {
    direction: "Direction",
    duration: "Duration",
    status: "Status",
    start_time: "Start Time",
    end_time: "End Time",
    from_: "From",
    to: "To"
  }

  fieldsList = Object.keys(this.fields);
  logName = 'Call'
  public detailsFields = {'sid': 'SID', ...this.fields};
  public detailsFieldsList = Object.keys(this.detailsFields);

  @Input('campaign_id') campaignId = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected phoneLogsService: CallLogsService,
    protected alertService: AlertService,
    protected modalService: NgxSmartModalService,
    protected sessionService: SessionService
  ) {
    super(router, location, route, phoneLogsService, alertService, modalService, sessionService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getDetailValue(fieldName): string {
    let value = super.getDetailValue(fieldName);

    if (fieldName === 'sid') {
      if (value.startsWith('CA')) { // if this is a twilio ID
        value = `https://www.twilio.com/console/voice/calls/logs/${value}`;
      }
    }

    return value
  }

  protected generateFilters() {
    super.generateFilters();
    this.id = this.campaignId;
  }

  protected queryData() {
    if (this.id) {
      super.queryData();
    }
  }
}
