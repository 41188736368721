import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, MerchantService } from '../_services';
import { CrudPagedListComponent } from '../_directives';
import { Merchant, CRM } from '../_models';
import { Observable } from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './merchant.component.html',
  selector: 'merchants'
})
export class MerchantComponent extends CrudPagedListComponent implements OnInit, OnChanges {
  merchants$: Observable<Merchant[]> = this.data$;
  @Input('crm') crm: CRM;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected merchantService: MerchantService,
    protected alertService: AlertService
  ) {
    super(router, location, route, merchantService, alertService);
    this.objectName = 'address';
    this.title = 'My Addresses';
  }

  ngOnInit() {
    this.onInputDataChanged();
  }

  ngOnChanges(): void {
    this.onInputDataChanged();
  }

  protected onInputDataChanged() {
    if (this.crm) {
      this.setFilter({crm: this.crm.id});
    }
  }

}
