import {OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CrudService} from '../_services';
import {ApiKey} from '../_models';
import {Observable} from "rxjs";
import {CrudListComponent} from "./crud-list.component";

export abstract class ApiKeyComponent extends CrudListComponent implements OnInit {
  keys$: Observable<ApiKey[]> = this.data$;
  campaignId: string | number = null;
  visibleKeys = {};

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected keyService: CrudService,
    protected alertService: AlertService
  ) {
    super(router, location, route, keyService, alertService);
    this.objectName = 'API Key';
    this.title = 'API Keys';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  isKeyVisible(key: ApiKey) {
    return key.id in this.visibleKeys;
  }

  toggleKeyVisible(key: ApiKey) {
    if (this.isKeyVisible(key)) {
      delete this.visibleKeys[key.id];
    } else {
      this.visibleKeys[key.id] = true;
    }
  }

  getKey(key: ApiKey) {
    return (this.isKeyVisible(key)) ? key.key : key.key.substr(0, 6).padEnd(key.key.length, '*');
  }

  copy(key: ApiKey) {
    const _this = this;

    navigator.clipboard.writeText(key.key).then(function() {
      _this.alertService.success('Copied API Key to clipboard', true)
    }, function (err) {
      _this.handleError(err);
    });
  }

  create() {
    this.keyService.create({}).subscribe((data: ApiKey) => {
      this.queryData();
      this.alertService.success('Created new API Key', true)
    }, error => {
      this.handleError(error);
    });
  }
}
