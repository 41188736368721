import { Component } from "@angular/core";
import { InfoCards } from "../../../../app/_models";
import {
  cancellationData,
  cancellationTableData,
  deflection,
  downsells,
  pathBreakdowns,
  retentionMidCards,
  retentionTopCards,
  summaryTableData,
  summmaryData,
  surveys,
  watchlist,
} from "../../retention/retention.data";
import { InlineTableData } from "../../data-visualization/data-table/data-table.component";
import { DoughnutChartData } from "../../data-visualization/doughnut-chart/doughnut-chart.component";

@Component({
  selector: "app-retention-report",
  templateUrl: "./retention-report.component.html",
  styleUrls: ["./../dashboard-report.component.scss"],
})
export class DashboardRetentionReportComponent {
  /* Cards Data */
  retentionTopCards: InfoCards = retentionTopCards;
  retentionMidCards: InfoCards = retentionMidCards;

  /* Charts Data */
  summaryData: DoughnutChartData = summmaryData;
  summaryTableData: InlineTableData = summaryTableData;
  cancellationData: DoughnutChartData = cancellationData;
  cancellationTableData: InlineTableData = cancellationTableData;
  surveyResponses = surveys;
  downsells = downsells;
  deflection = deflection;
  watchlist = watchlist;
  pathBreakdowns = pathBreakdowns;

  chartOptions = {
    cutoutPercentage: 85,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "rgba(47, 49, 66, 0.8)",
      titleFontSize: 13,
      titleFontColor: "#fff",
      caretSize: 0,
      cornerRadius: 4,
      xPadding: 10,
      displayColors: true,
      yPadding: 10,
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 10,
      },
    },
  };

  constructor() {}
}
