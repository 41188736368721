import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, FaqTopicService, FaqService, FunnelStepService, WidgetService} from "../_services";
import {FormBuilder} from "@angular/forms";
import {Faq, FaqIntent} from "../_models";
import {FaqNewComponent} from "./faq-new.component";
import {mergeMap} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqEditComponent extends FaqNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected topicService: FaqTopicService,
    protected faqService: FaqService,
    protected alertService: AlertService,
    protected stepService: FunnelStepService,
    protected widgetService: WidgetService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, topicService, faqService, alertService, stepService, widgetService, formBuilder);
    this.title = 'Edit FAQ';
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getDependencies();
  }

  protected onDependenciesReady(params?: {}) {
    this.data$.subscribe((faq: Faq) => {
      if (faq) {
        this.faq = faq;
        const intents = this.getSelectedIntents(faq);
        const topics = [];

        if (faq.topics && faq.topics.length) {
          faq.topics.forEach(topicId => {
            topics.push({id: topicId, text: this.topicLabels[topicId]});
          });

          delete faq.topics;
        }

        if (faq.intents) {
          delete faq.intents;
        }

        this.form.patchValue(faq);
        this.form.patchValue({topics: topics, intents: intents});

        if (faq.keywords !== null) {
          // convert the keywords array into a string
          this.form.patchValue({keywords: faq.keywords.join(',')});
        }
      }

      this.loading = false;
    },
    error => {
      this.loading = false
      this.handleError(error);
    });
  }

  protected onRouteParams(params: {}) {
    this.loading = true
  }

  private getSelectedIntents(faq: Faq) {
    let intents: FaqIntent[] = [];

    if (this.intents && this.intents.length && faq.intents && faq.intents.length) {
      let intentsMap = {}

      this.intents.forEach((intent: FaqIntent) => {
        intentsMap[intent.id] = intent;
      });

      faq.intents.forEach((intentId: number) => {
        intents.push(intentsMap[intentId]);
      });
    }

    return intents;
  }
}
