import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  AudioFileService,
  CampaignProductService,
  FaqTopicService,
  FunnelInputService,
  FunnelService,
  FunnelStepGroupService,
  FunnelStepService,
  WidgetCategoryService,
  WidgetService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {FunnelInputTypeEnum, FunnelStep} from "../_models";
import {FunnelStepNewComponent} from "./funnel-step-new.component";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  encapsulation: ViewEncapsulation.None,
  templateUrl: './funnel-step-edit.component.html',
  styleUrls: ['./funnel-step.component.scss']
})
export class FunnelStepEditComponent extends FunnelStepNewComponent implements OnInit {

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected stepService: FunnelStepService,
    protected alertService: AlertService,
    protected inputService: FunnelInputService,
    protected faqTopicService: FaqTopicService,
    protected productService: CampaignProductService,
    protected funnelService: FunnelService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
    protected groupService: FunnelStepGroupService,
    protected audioFileService: AudioFileService,
    protected widgetService: WidgetService,
    protected widgetCategoryService: WidgetCategoryService
  ) {
    super(router, location, route, stepService, alertService, inputService, faqTopicService, productService,
      funnelService, formBuilder, modalService, groupService, audioFileService, widgetService, widgetCategoryService);
    this.isNew = false;
    this.title = 'Edit Path Step';
  }

  ngOnInit() {
    super.ngOnInit();
    this.stepLoading = true
    this.data$.subscribe((step: FunnelStep) => {
      if (step) {
        this.funnelId = step.funnel;
        this.step = step;
        this.type = step.type;
        this.category = step.category;
        this.inputs = step.inputs.slice();
        this.inputService.setStepId(step.id);
        this.contentData.style = step.style;
        this.contentData.enhanced_content = step.enhanced_content;
        this.contentData.enhanced_content_components = step.enhanced_content_components;
        this.contentData.use_bootstrap_spacing = step.use_bootstrap_spacing;
        this.enhancedMode = this.step.enhanced_mode;
        this.sipEnabled = !!step.call_center_sip_uri;

        if (step.item_statuses && step.item_statuses.length) {
          let itemStatuses = [];

          step.item_statuses.forEach(value => {
            itemStatuses.push({id: value, text: this.itemStatusLabels[value]});
          });

          this.buildItemStatusList();
          this.form.patchValue({item_statuses: itemStatuses});
          delete step.item_statuses;
        }

        if (step.action_holdoffs && step.action_holdoffs.length) {
          let actionHoldoffs = [];

          step.action_holdoffs.forEach(value => {
            actionHoldoffs.push({id: value, text: this.actionHoldoffLabels[value]});
          });

          this.buildActionHoldoffList();
          this.form.patchValue({action_holdoffs: actionHoldoffs});
          delete step.action_holdoffs;
        }

        this.form.patchValue(step);
        this.isFirstStepDisabled = step.is_first_step; // can't clear this attribute

        if (step.type === FunnelInputTypeEnum.FAQs) {
          this.getFaqTopics();
        }

        if (step.classes !== null) {
          // convert the classes array into a class string
          this.form.patchValue({classes: step.classes.join(' ')});
        }

        if (step.voice_file !== null) {
          this.form.patchValue({voice_file: step.voice_file.id});
        }

        if (step.type === FunnelInputTypeEnum.ContactCallCenter) {
          this.buildContentVariables(); // rebuild the content variables to add call center contact
        }

        this.stepLoading = false

        this.getCampaignProducts();
        this.buildProductFunnelList();
        this.setStepCategoryInput()
      }
    });
  }

  protected onSaveComplete(data) {
    this.queueInputsNeedingNextStep(data);
    this.inputs = [...data.inputs];
  }

}
