import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelStepService, ProductInfoSectionService} from '../_services';
import {FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import {Product, Image, ProductInfo, Section, ImageType} from "../_models";
import {takeUntil} from "rxjs/operators";
import {Form} from '../_forms';
import {NgxSmartModalService} from "ngx-smart-modal";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-marketplace-fields.component.html',
  selector: 'product-marketplace-fields',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class ProductMarketplaceFieldsComponent extends Form implements OnInit, OnChanges {
  sections: Section[] = [];
  stepList: any[];
  infoFields: FormArray;
  showEditors = false;
  imageType: ImageType = ImageType.ProductImage;
  images: Image[] = [];

  @Input('product') product: Product;

  constructor(
    protected router: Router,
    protected location: Location,
    private sectionService: ProductInfoSectionService,
    private formBuilder: FormBuilder,
    private stepService: FunnelStepService,
    private modalService: NgxSmartModalService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.updateForm();
    super.ngOnInit();

    this.sectionService.list()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (sections: Section[]) => {
          this.sections = sections;
        },
        error => {
          this.handleError(error);
        }
      );

    this.stepService.getGlobalJumpLinks(true, {resourcetype: 'VisualFunnel'})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        stepList => {
          this.stepList = stepList;
          this.showEditors = true;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm() {
    this.setForm(this.formBuilder.group({
      description: [null],
      blurb: [null],
      msrp: [null, Validators.min(0)],
      info: this.formBuilder.array([])
    }));

    this.infoFields = this.form.get('info') as FormArray;

    if (this.product) {
      this.form.patchValue(this.product);
      this.images = this.product.images;

      if (this.product.info) {
        this.product.info.forEach((info: ProductInfo) => {
          this.addProductInfo(info.section, info.content);
        });
      }
    }
  }

  private createProductInfo(sectionId: string | number = null, content: string = null): FormGroup {
    return this.formBuilder.group({
      section: [sectionId, [Validators.required]],
      content: [content || null, [Validators.required]]
    });
  }

  getSectionName(sectionId: string | number): string {
    let section = this.sections.find((section: Section) => {
      return section.id === sectionId;
    });

    return section.name;
  }

  addProductInfo(sectionId: string | number = null, content: string = null) {
    let info = this.form.get('info') as FormArray;

    info.push(this.createProductInfo(sectionId, content));
  }

  removeProductInfo(index) {
    let info = this.form.get('info') as FormArray;

    info.removeAt(index);
  }

  onImagesSelectStart() {
    this.modalService.getModal('imagesDialog').open();
  }

  onImagesSelectDone(images: Image[]) {
    this.images = images;
    this.modalService.getModal('imagesDialog').close();
  }

  removeImage(imageId) {
    this.images = this.images.filter(
      (image: Image) => image.id != imageId); //image is already selected, so unselect it
  }

  getFormData() {
    let data: {} = this.form.value;
    const allow_blank_fields = ['msrp'];

    //convert empty strings to null for certain fields so the api will accept them
    allow_blank_fields.forEach((field: string) => {
      if (field in data) {
        let value = data[field];

        if (typeof(value) == 'string') {
          if (value.trim().length == 0) {
            data[field] = null;
          }
        }
      }
    });
    
    data['images'] = [];

    this.images.forEach((image: Image) => {
      data['images'].push(image.id);
    });

    return data;
  }

  dragStart() {
    this.showEditors = false;
  }

  dropSuccess() {
    this.showEditors = true;
  }

  onDrop(event: CdkDragDrop<FormGroup[]>) {
    moveItemInArray(this.infoFields.controls, event.previousIndex, event.currentIndex);
  }

}
