import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Invoice, InvoiceChargeType, TransactionType} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'invoice-charge-form',
  templateUrl: './invoice-charge.component.html'
})
export class InvoiceChargeComponent extends CrudSavePopupComponent implements OnInit {
  invoice: Invoice;
  chargeTypes: { value: string, label: string }[] = [];
  transactionTypes: { value: TransactionType, label: string }[] = [
    {value: TransactionType.Charge, label: 'Charge'},
    {value: TransactionType.UsageCharge, label: 'Usage Charge'},
    {value: TransactionType.SubscriptionAdjustment, label: 'Subscription Adjustment'}
  ];

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected invoiceService: InvoiceService,
              protected alertService: AlertService
  ) {
    super(router, location, route, invoiceService, alertService);
    this.objectName = 'Invoice';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      amount_type: [InvoiceChargeType.Full, Validators.required],
      type: [TransactionType.Charge, Validators.required],
      is_external: [false]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.invoice = this.inputData;

    if (this.invoice) {
      let chargeTypes = [];
      let defaultAmount = '0.00';

      if (Number(this.invoice.amount_due) > 0.001) {
        chargeTypes.push({value: InvoiceChargeType.Full, label: 'Balance Due: $' + this.invoice.amount_due});
        defaultAmount = this.invoice.amount_due;
      }

      if (Number(this.invoice.past_amount_due) > 0.001) {
        chargeTypes.push({value: InvoiceChargeType.PastBalance,
          label: 'Past Amount Due: $' + this.invoice.past_amount_due});
      }

      chargeTypes.push({value: InvoiceChargeType.Custom, label: 'Other Amount'});

      // rebuild the amount control based off the invoice
      if ('amount' in this.form.controls) {
        this.form.removeControl('amount');
      }

      this.form.addControl('amount', new FormControl(defaultAmount,[Validators.required,
        Validators.min(0.01)]));

      this.chargeTypes = chargeTypes;
    }
  }

  isCustomCharge() {
    if (this.form) {
      const control = this.form.get('amount_type');

      if (control) {
        return control.value === InvoiceChargeType.Custom;
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      let amount = this.form.value.amount;  // default to custom amount entered

      if (this.form.value.amount_type === InvoiceChargeType.Full) {
        amount = null; // full amount
      }
      else if (this.form.value.amount_type === InvoiceChargeType.PastBalance) {
        amount = this.invoice.past_amount_due;
      }

      this.invoiceService.charge(this.invoice.id, amount, this.form.value.is_external)
        .subscribe(
          data => {
            this.alertService.success('Charge Successful.');
            this.onSaveComplete(data);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

}
