import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, CampaignService, EmailTemplateService, TicketSystemService } from '../_services';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import { CrudSaveComponent } from '../_directives';
import { Campaign, EmailTemplate, EmailTemplateType, Pager, AutoResponderType, TicketSystem } from "../_models";
import { EMPTY, forkJoin } from "rxjs";
import {mergeMap, takeUntil} from "rxjs/operators";
import { config } from '../../config/config';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-email.component.html'
})
export class CampaignEmailComponent extends CrudSaveComponent implements OnInit {
  isNewCampaign = false;
  campaign: Campaign;
  matchType = EmailTemplateType.Match;
  noMatchType = EmailTemplateType.NoMatch;
  matchTemplates: EmailTemplate[] = [];
  noMatchTemplates: EmailTemplate[] = [];
  useSameBodyIfNoMatch = true;
  showEmailSetup = false;
  ready = false;
  autoResponderType = AutoResponderType.Email
  ticketSystems: TicketSystem[] = []

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    private emailService: EmailTemplateService,
    protected ticketSystemService: TicketSystemService
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = "Setup Brand Email";
    this.isPartial = true;
    this.objectName = 'Brand';
  }

  ngOnInit() {
    let selectedTemplateMap = {};

    this.form = this.formBuilder.group({
      template_match: [null, Validators.required],
      template_no_match: [null],
      email_enabled: [false],
      email_forward_delay: [null, [Validators.required, Validators.min(1), Validators.max(360)]],
      autoresponder_triggers: this.formBuilder.array([]),
      ticket_system: [null]
    });

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        this.campaign = campaign;
        this.form.patchValue({
          email_enabled: campaign.email_enabled,
          email_forward_delay: campaign.email_forward_delay,
          ticket_system: campaign.ticket_system ? campaign.ticket_system.id : null
        });
        this.campaignService.showMenu(campaign, 'email', this.isNewCampaign, campaign.is_hybrid);

        //build a map of the campaign's selected templates
        for (let template_id of campaign.email_templates) {
          selectedTemplateMap[template_id] = true;
        }

        return forkJoin([
          this.emailService.list({page: 1, page_size: config.maxPageSize,
            type__in: [EmailTemplateType.Match, EmailTemplateType.NoMatch].join(',')}),
          this.ticketSystemService.list({page: 1, page_size: config.maxPageSize})
        ])
      }
      return EMPTY;
    })).subscribe(
      (data: [Pager, Pager]) => {
        if (data.length > 0) {
          let emailTemplates: EmailTemplate[] = data[0].results;
          this.ready = true;

          //list of all templates returned
          if (emailTemplates && emailTemplates.length) {
            for (let template of emailTemplates) {
              if (template.type == EmailTemplateType.Match) {
                this.matchTemplates.push(template);

                if (template.id in selectedTemplateMap) {
                  //matched template is selected (we only support 1 selection for now)
                  this.form.patchValue({template_match: template.id});
                }
              }
              else if (template.type == EmailTemplateType.NoMatch) {
                this.noMatchTemplates.push(template);

                if (template.id in selectedTemplateMap) {
                  //unmatched template is selected (we only support 1 selection for now)
                  this.form.patchValue({template_no_match: template.id});
                  this.useSameBodyIfNoMatch = false;
                }
              }
            }

            this.showEmailSetup = true;
          }
          this.ticketSystems = data[1].results
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  enableSameBodyIfNoMatch(event) {
    this.useSameBodyIfNoMatch = event.target.checked;
  }

  deleteTemplate(id) {
    if(window.confirm('Are you sure you want to delete this email template?')) {
      this.loading = true;

      this.emailService.delete(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          result => {
            this.alertService.success('Deleted email template.');
            this.loading = false;
            this.matchTemplates = this.matchTemplates.filter(data => data.id != id);
            this.noMatchTemplates = this.noMatchTemplates.filter(data => data.id != id);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  protected getFormData() {
    let selectedTemplates = [this.form.value.template_match];

    //get the selected email templates
    if (!this.useSameBodyIfNoMatch && this.form.value.template_no_match) {
      selectedTemplates.push(this.form.value.template_no_match);
    }
    let autoresponder_triggers = []
    this.form.get('autoresponder_triggers')['controls'].forEach(item => {
      let value = {...item.value};
      if (value['autoresponder'] === 'null') {
        value['autoresponder'] = null
      }
      autoresponder_triggers.push(value);
    })
    return {
      email_templates: selectedTemplates,
      email_enabled: this.form.value.email_enabled,
      email_forward_delay: this.form.value.email_forward_delay,
      autoresponder_triggers: autoresponder_triggers,
      ticket_system: this.form.value.ticket_system
    };
  }

  protected onSaveComplete(data) {
    if (this.isNewCampaign) {
      this.navigate(['/campaign', 'products', this.id, {isNewCampaign: true}]);
    }
    else {
      this.goBack();
    }
  }

  get autoresponder_triggers() {
    return <FormArray>this.form.get('autoresponder_triggers')['controls'];
  }

}
