import {Component, OnInit, Input, OnChanges} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, RegionService } from '../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { Region, Address } from '../_models';
import { Form } from '../_forms';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-address-fields',
  templateUrl: './address-fields.component.html'
})
export class AddressFieldsComponent extends Form implements OnInit, OnChanges {
  states: Region[];
  countries: Region[];
  country: string = 'us';
  @Input('address') address: Address;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private regionService: RegionService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      id: [null],
      address1: [null, Validators.required],
      address2: [null],
      city: [null, Validators.required],
      state: [null, Validators.required],
      zip: [null, Validators.required],
      country: ['us', Validators.required],
    }));

    this.regionService.getCountries()
      .subscribe(
        (countries: Region[]) => {
          this.countries = countries;
        }
      )

    super.ngOnInit();
    this.updateForm();
  }

  ngOnChanges() {
    this.updateForm();
  }

  protected updateForm() {
    if (this.form) {
      if (this.address) {
        if (this.address.country) {
          this.address.country = this.address.country.toLowerCase();
        }
        this.country = this.address.country;
        this.form.patchValue(this.address);
      }
      else {
        this.country = 'us';
        this.resetForm();
      }
    }

    this.updateStates()
  }

  protected updateStates() {
    this.states = [];
    this.regionService.list(this.country)
      .subscribe(
        (states: Region[]) => {
          this.states = states;

          if (this.states && this.states.length) {
            this.form.controls['state'].setValidators([Validators.required]);
          }
          else {
            this.form.controls['state'].setValidators([]);
          }
        },
        error => {
          this.handleError(error);
        }
      )
  }

  selectCountry(event) {
    if (event.target.value !== this.country) {
      this.country = event.target.value;
      this.form.patchValue({state: null})
      this.updateStates();
    }
  }
}
