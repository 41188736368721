import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { CrudPagedListComponent } from "../_directives";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  AlertService,
  ProductCategoryService,
  StorageService,
} from "../_services";
import { Observable } from "rxjs";
import { Pager, ReportSessionInput } from "../_models";
import { ReportsService } from "../_services/reports.service";
import { takeUntil } from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";
import { isEmpty } from "lodash";
import { reportsFieldDataV2 } from "./utils/reportsFieldData";

@Component({
  moduleId: module.id.toString(),
  selector: "reports-table-v2",
  templateUrl: "./templates/report-table-v2.component.html",
  styleUrls: ["./styles/report-table-v2.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReportTableV2Component
  extends CrudPagedListComponent
  implements OnInit, OnChanges
{
  report$: Observable<ReportSessionInput[]> = this.data$;
  @Input("reportReq") reportReq: any;
  @Input("fields") fields: string[] = [];
  @Input("isCustomReportLoading") isCustomReportLoading: boolean;
  @Input("inputReportService") inputReportService: ReportsService;
  @Input("getFieldValue") getFieldValue;
  @Input("isChatbotReport") isChatbotReport: boolean;
  @Output() isReportGenerated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  selectedFields: string[] = [];
  isTableLoading: boolean = true;
  public hasTotalsRow: boolean = false;
  public selectedSessionId: number | string = null;
  public isQuestionExpanded: boolean[] = [];
  public isAnswerExpanded: boolean[] = [];

  rowsPerPageOptions = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 250, label: "250" },
    { value: 500, label: "500" },
    { value: 1000, label: "1000" },
  ];

  private defaultFields = [
    "date_joined",
    "campaign.name",
    "result",
    "source",
    "customers.first_name",
    "customers.last_name",
    "device_info.device_type",
    "is_billable",
  ];
  pageSize = 50;
  noTableData = false;
  isSavedReport = false;

  protected fieldData = reportsFieldDataV2;

  public campaignCategoriesMap = {};
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected reportsService: ReportsService,
    protected alertService: AlertService,
    protected categoryService: ProductCategoryService,
    protected modalService: NgxSmartModalService,
    protected storageService: StorageService
  ) {
    super(router, location, route, reportsService, alertService);
    this.objectName = "report-table";

    if (!this.fields || !this.fields.length) {
      this.fields = this.defaultFields;
    } else {
      if (this.fields.includes("exclude_zeroes")) {
        this.fields = this.fields.filter((field) => field !== "exclude_zeroes");
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fields.includes("exclude_zeroes")) {
      this.fields = this.fields.filter((field) => field !== "exclude_zeroes");
    }

    if (changes.isCustomReportLoading) {
      if (!this.isCustomReportLoading && this.isSavedReport) {
        this.queryData();
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.inputReportService) {
      this.reportsService = this.inputReportService;
    }
    this.route.params.subscribe((params) => {
      if (!params.id) {
        this.queryData();
      } else this.isSavedReport = true;
    });
  }

  public queryData(selectedList?: string[]) {
    this.isTableLoading = true;
    this.noTableData = false;
    this.selectedFields = selectedList || this.fields;
    if (this.selectedFields.includes("exclude_zeroes")) {
      this.selectedFields = this.selectedFields.filter(
        (field) => field !== "exclude_zeroes"
      );
    }

    this._data$.next([]);

    this.reportsService
      .getFilteredReport({
        ...this.reportReq,
        page: this.page,
        page_size: this.pageSize,
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.pageCount = data.num_pages;
          this.page = data.page;
          const updatedData = this.updateTransactionAmount(data);
          this._data$.next(updatedData);
          if (isEmpty(data.results)) this.noTableData = true;
          this.hasTotalsRow = data.results && data.has_total_row;

          if (this.page === 1) {
            this.totalResultCount = data.count;
          }
          this.isTableLoading = false;
          this.isReportGenerated.emit(true);
        },
        (error) => {
          this.isReportGenerated.emit(true);
          this.noTableData = true;
          this.handleError(error);
          this._data$.error(error);
          this.isTableLoading = false;
        }
      );
  }

  getFieldLabel(field: string): string {
    if (field.startsWith("exclude_") || this.fieldData[field] == undefined) {
      return "";
    }
    return this.fieldData[field].label;
  }

  toggleExpandQuestion(rowIndex: number) {
    this.isQuestionExpanded.forEach((_, index) => {
      if (index !== rowIndex) {
        this.isQuestionExpanded[index] = false;
      }
    });
  
    this.isQuestionExpanded[rowIndex] = !this.isQuestionExpanded[rowIndex];
  }

  isQuestionRowExpanded(rowIndex: number): boolean {
    return !!this.isQuestionExpanded[rowIndex]; 
  }

  isAnswerRowExpanded(rowIndex: number): boolean {
    return !!this.isAnswerExpanded[rowIndex];
  }

  toggleExpandAnswer(rowIndex: number) {
    this.isAnswerExpanded.forEach((_, index) => {
      if (index !== rowIndex) {
        this.isAnswerExpanded[index] = false;
      }
    });
    
    this.isAnswerExpanded[rowIndex] = !this.isAnswerExpanded[rowIndex]; 
  } 

  truncateText(text: string, charLimit: number = 22): string {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + '...';
    }
    return text;
  }

  shouldShowMore(text: string, charLimit: number = 22): boolean {
    return text.length > charLimit;
  }

  openSessionDetails(event, sessionId) {
    event.preventDefault();
    this.selectedSessionId = sessionId;
    this.modalService.getModal('sessionDetailModal').open();
  }

  updateTransactionAmount(data: Pager) {
    const updatedData = data.results.map((obj) => {
      const updatedObj = { ...obj };

      if ("amount" in updatedObj) {
        updatedObj["amount"] = "$" + updatedObj.amount;
      }
      return updatedObj;
    });
    return updatedData;
  }

  protected orderBy(ordering: string[]) {
    let currentFields = this.fields;
    this.fields = [];
    super.orderBy(ordering);
    this.fields = currentFields;
  }

  public resetPager() {
    super.resetPager();
  }

  formatHeader(header: string): string {
    return (
      reportsFieldDataV2[header].custom_label ||
      reportsFieldDataV2[header].label
    );
  }
}
