import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {StripeService} from "ngx-stripe";

@Component({
  moduleId: module.id.toString(),
  selector: 'app-bank-account-new-fields',
  templateUrl: './bank-account-new-fields.component.html'
})
export class BankAccountNewFieldsComponent extends Form implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private stripeService: StripeService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      routing_number: [null, Validators.required],
      account_number: [null, Validators.required],
      account_holder_name: [null, Validators.required],
      account_holder_type: ['company', Validators.required],
      country: ['US', Validators.required],
      currency: ['usd', Validators.required],
    });
  }

  createToken() {
    return this.stripeService.createToken('bank_account', this.form.value);
  }
}
