import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, ImageService } from '../_services';
import { Image } from '../_models';
import { Observable } from 'rxjs';
import {ImageAbstractComponent} from './image-abstract.component';
import {FormBuilder} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  selector: 'image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageGalleryComponent extends ImageAbstractComponent implements OnInit {
  images$: Observable<Image[]> = this.data$;
  isPublic = false;
  componentId = 'local';

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected imageService: ImageService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
  ) {
    super(router, location, route, imageService, alertService, formBuilder, modalService);
    this.objectName = 'image';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected onDelete(id) {
    //image was deleted, so make sure it's not selected
    this.selectedImages = this.selectedImages.filter(selectedImage => selectedImage.id != id);
    this.queryData()
  }

  protected initializeFilter() {
    super.initializeFilter();
    this.filter['is_public'] = false;
  }
}
