import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { AlertService } from "../alert.service";
import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { StorageService } from "../storage.service";
import { DashboardFilter, DashboardReportsFilter } from "../../_models";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  protected filter: BehaviorSubject<DashboardFilter> =
    new BehaviorSubject<DashboardFilter>({
      start_date: moment()
        .subtract(7, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      section: 'OVERVIEW',
      end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      includeTest: false,
      includeHangups: false,
      includeUnknowns: false,
      timezone: moment.tz.guess(),
    });

  protected data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  protected route: string = "";

  constructor(
    protected http: HttpClient,
    protected alertService: AlertService,
    protected storageService: StorageService
  ) {}

  getQueryParams() {
    let filters = this.filter.getValue();
    let startDate = filters.start_date;
    let endDate = filters.end_date;
    let query_params = {};

    query_params["start_date"] = moment(startDate)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    query_params["end_date"] = moment(endDate)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    if (filters.campaign_id) {
      query_params["campaign_id"] = filters.campaign_id;
    }

    if (filters.account_id) {
      query_params["account_id"] = filters.account_id;
    }

    query_params["include_tests"] = filters.includeTest;
    query_params["include_hangups"] = filters.includeHangups;
    query_params["include_unknowns"] = filters.includeUnknowns;
    return new HttpParams({ fromObject: query_params });
  }

  getBody(): DashboardReportsFilter {
    const filterValues = this.getFilter();
    const body: DashboardReportsFilter = {
      fields: [
        "exclude_tests",
        "exclude_hangups",
        "exclude_unknowns",
        "exclude_child_sessions",
        "exclude_child_orders",
      ],
      date_range: {
        startDate: moment(filterValues.start_date)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(filterValues.end_date)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      },
      timezone: filterValues.timezone,
      date_filter_type: "session",
      filters: {},
    };

    if (filterValues.account_id && filterValues.account_id !== "null") {
      body.accounts = [Number(filterValues.account_id)];
    }

    if (filterValues.campaign_id) {
      body.filters = { campaign_id: [Number(filterValues.campaign_id)] };
    }

    return body;
  }

  getData() {
    if (!this.route) {
      return;
    }
    this.http
      .get(this.route + "/", { params: this.getQueryParams() })
      .subscribe(
        (data) => {
          if (data) {
            this.data.next(data);
          }
        },
        (error) => {
          if (error.status !== 401) {
            this.data.next({});
            this.alertService.error(error.message);
          }
        }
      );
  }

  get data$() {
    return this.data.asObservable();
  }

  get filters$() {
    return this.filter.asObservable();
  }

  setFilter(data) {
    if ("start_date" in data) {
      data["start_date"] = moment(data["start_date"])
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if ("end_date" in data) {
      data["end_date"] = moment(data["end_date"])
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    this.filter.next(data);
  }

  getFilter(): DashboardFilter {
    return this.filter.getValue();
  }
}
