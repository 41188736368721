import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Form} from '../_forms';
import {StripeService} from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';import {takeUntil} from "rxjs/operators";
import {AddressFieldsComponent} from "../address";

@Component({
  moduleId: module.id.toString(),
  selector: 'app-credit-card-new-fields',
  templateUrl: './credit-card-new-fields.component.html'
})
export class CreditCardNewFieldsComponent extends Form implements OnInit, AfterViewInit {
  elements: StripeElements;
  card: StripeCardElement;
  @ViewChild('card', { static: false }) cardRef: ElementRef;
  @ViewChild(AddressFieldsComponent, { static: false }) addressFields: AddressFieldsComponent;

  private cardOptions: StripeCardElementOptions = {
    style: {
    },
    hidePostalCode: true
  };

  private elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private stripeService: StripeService,
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
    });

    this.stripeService.elements(this.elementsOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', this.cardOptions);
          this.card.mount(this.cardRef.nativeElement);
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => this.form.addControl('address', this.addressFields.form), 0);
  }

  createToken() {
    const value = Object.assign({name: this.form.value.name}, {
      address_line1: this.addressFields.form.value.address1 || '',
      address_line2: this.addressFields.form.value.address2 || '',
      address_city: this.addressFields.form.value.city || '',
      address_state: this.addressFields.form.value.state || '',
      address_zip: this.addressFields.form.value.zip || '',
      address_country: this.addressFields.form.value.country,
    });
    return this.stripeService.createToken(this.card, value);
  }
}
