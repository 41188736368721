import {Injectable} from '@angular/core';
import {PaginationService} from './pagination.service';
import {HttpClient} from '@angular/common/http';
import {BaseModel} from '../_models';

@Injectable()
export class EmailIntegrationService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'campaign');
  }

  setCampaignId(id: string | number) {
    this.route = 'campaign/' + id.toString() + '/email_integrations';
  }

  verify(id, obj) {
    return this.http.post(this.route + '/' + id.toString() + '/verify/', obj);
  }

  verifSPF(id) {
    return this.http.post(this.route + '/' + id.toString() + '/verify_spf/', {});
  }
}
