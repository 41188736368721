import {Component, OnInit, OnChanges, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {FormControl, FormBuilder} from '@angular/forms';
import {CRMType, CRM} from '../_models';
import {Form} from '../_forms';
import {tinyMCETTextOnly, tinyMCESingleLine} from "../_helpers";

@Component({
  moduleId: module.id.toString(),
  selector: 'crm-tracking-data',
  templateUrl: './crm-tracking.component.html'
})
export class CRMTrackingComponent extends Form implements OnInit, OnChanges {
  @Input('type') type: CRMType;
  @Input('crm') crm: CRM;
  private fieldLabels: {[key: string]: string;} = {};
  fields: string[];
  editorInitCallback = tinyMCETTextOnly;
  editorSetupCallback = tinyMCESingleLine;
  trackingVariables: any = [];

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.updateForm();
  }

  ngOnChanges() {
    this.updateForm();
  }

  private updateForm() {
    if (this.form) {
      this.trackingVariables = [];
      this.fields = [];
      let trackingVariables = [
        {value: '{order_reason}', text: 'Order Reason'}
      ];

      //remove the current fields
      for (let field in this.form.controls) {
        this.form.removeControl(field);
      }

      if (this.type && this.type.tracking_fields) {
        Object.entries(this.type.tracking_fields).forEach(([key, field]) => {
          if (!field.read_only) {
            let defaultValue = null;

            if (this.crm && this.crm.tracking_data && (key in this.crm.tracking_data)) {
              defaultValue = this.crm.tracking_data[key];
            }

            this.form.addControl(key, new FormControl(defaultValue));
          }
          
          trackingVariables.push({value: '{' + key + '}', text: field.label || key})
        });
      }

      this.trackingVariables = trackingVariables;
      this.fields = Object.keys(this.form.controls);
    }
  }

  getFieldLabel(field: string) {
    if (field in this.fieldLabels) {
      return this.fieldLabels[field];
    }

    return field;
  }

}
