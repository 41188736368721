import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Processor } from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutBankComponent } from '../bank/checkout-bank.component';
import { Location } from '@angular/common';
import { AlertService, CheckoutProcessorService, LoaderService } from '../../../_services';

@Component({
  selector: 'app-checkout-processor',
  templateUrl: './checkout-processor.component.html',
  styleUrls: ['./checkout-processor.component.scss', '../profiles-styles.component.scss'],
})
export class CheckoutProcessorComponent extends CheckoutBankComponent implements OnInit {
  // @ts-ignore
  tableData: Processor[];
  // @ts-ignore
  originalTableData: Processor[];
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected processorService: CheckoutProcessorService,
    protected alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService
  ) {
    // @ts-ignore
    super(router, location, route, processorService, alertService, ngxSmartModalService, loader);
    this.objectName = 'processor';
  }

  getBankMainEntries(banks: string[]) {
    return banks.slice(0, this.permissionTypesMainEntriesLimit);
  }

  getBankExtraEntries(banks: string[]) {
    return banks.splice(this.permissionTypesMainEntriesLimit);
  }
}
