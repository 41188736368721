import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from "./pagination.service";

@Injectable()
export class InvoiceTransactionService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'transactions');
  }

  refund(id: string | number, amount: string | number | null = null, isExternal: boolean = false) {
    return this.http.post(this.route + '/' + id.toString() + '/refund/',
      {amount: amount, is_external: isExternal});
  }
}
