import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, SalesPhraseService} from '../_services';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {SalesPhrase} from '../_models';
import {NgxSmartModalService} from "ngx-smart-modal";
import {takeUntil} from "rxjs/operators";
import {UpsaleProductFieldsComponent} from "./upsale-product-fields.component";
import {ProductSubtextComponent} from '../product-subtext';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './exchangeable-product-fields.component.html',
  selector: 'exchangeable-product-fields',
  styleUrls: ['./exchangeable-product.component.css']
})
export class ExchangeableProductFieldsComponent extends UpsaleProductFieldsComponent implements OnInit {
  salesPhraseOptions: any[] = [];
  styles = null;
  @ViewChild("exchangeableSubtext", { static: false }) productSubtexts: ProductSubtextComponent;
  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
    private salesPhraseService: SalesPhraseService
  ) {
    super(router, location, alertService, formBuilder, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.addControl('sales_phrase', new FormControl(null));
    this.form.addControl('retail_price', new FormControl(null, Validators.min(0)));
    this.form.addControl('subtext', new FormArray([]));

    this.salesPhraseService.list()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (salesPhrases: SalesPhrase[]) => {
          let phrases = [];

          salesPhrases.forEach((salesPhrase: SalesPhrase) => {
            phrases.push({value: salesPhrase.id, text: salesPhrase.text});
          });

          phrases.sort((a, b): number => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          });

          this.salesPhraseOptions = phrases;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  ngOnChanges() {
    super.ngOnChanges();
    if (this.exchangeableProduct && !this.styles) {
      this.styles = this.exchangeableProduct.styles || {};
    }
  }

  getSalesPhraseStyle(style: string) {
    return this.styles && style in this.styles ? this.styles[style] : null;
  }

  setSalesPhraseStyle(style: string, value: string) {
    this.styles[style] = value.trim();
  }

  onCreateSalesPhraseStart() {
    this.modalService.getModal('exchangeSalesPhraseDialog').open();
  }

  onCreateSalesPhraseDone(salesPhrase: SalesPhrase) {
    let phrases = this.salesPhraseOptions;

    phrases.push({value: salesPhrase.id, text: salesPhrase.text});
    phrases.sort((a, b): number => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    this.salesPhraseOptions = phrases;
    this.form.patchValue({sales_phrase: salesPhrase.id});
    this.modalService.getModal('exchangeSalesPhraseDialog').close();

    if (this.exchangeableProduct) {
      this.exchangeableProduct.sales_phrase = salesPhrase.id;
    }
  }

  getFormData() {
    return Object.assign({}, this.form.value, {
      styles: this.styles,
      subtext: this.productSubtexts.getFormData()
    });
  }

}
