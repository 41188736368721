import {Component, OnInit, OnChanges, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, UserService} from '../_services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CRMFieldSet, CRMField, User} from '../_models';
import {Form} from '../_forms';
import {takeUntil} from "rxjs/operators";
import * as moment from 'moment-timezone';

@Component({
  moduleId: module.id.toString(),
  selector: 'crm-config',
  templateUrl: './crm-config.component.html',
  styleUrls: ['./crm.component.css']
})
export class CRMConfigComponent extends Form implements OnInit, OnChanges {
  @Input('formGroup') formGroup: FormGroup;
  @Input('fields') inputFields: (CRMField | CRMFieldSet)[];
  @Input('values') values: {[key: string]: string;};

  fields: (CRMField | CRMFieldSet)[] = [];
  currentUser: User;
  timezones: string[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private userService: UserService
  ) {
    super(alertService, router, location);
    this.timezones = moment.tz.names();
  }

  ngOnInit() {
    this.userService.getCurrent().pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        },
        error => {
          this.handleError(error);
        }
      );

    this.updateForm();
  }

  ngOnChanges() {
    this.updateForm();
  }

  private updateForm() {
    if (this.formGroup) {
      let fields = [];

      //remove the current fields
      for (let field in this.formGroup.controls) {
        this.formGroup.removeControl(field);
      }

      //add the fields to the form group
      if (this.inputFields) {
        this.inputFields.forEach((field: CRMFieldSet) => {
          if (field.fieldset) {
            this.formGroup.addControl(field.fieldset, new FormGroup({}));
          }
          else {
            let defaultValue = field.default || null;
            let validators = [];

            if (this.values && field.name in this.values) {
              defaultValue = this.values[field.name];
            }

            if (field.required) {
              validators.push(Validators.required);
            }

            if (field.min !== undefined) {
              validators.push(Validators.min(field.min));
            }

            if (field.max !== undefined) {
              validators.push(Validators.max(field.max));
            }

            this.formGroup.addControl(field.name, new FormControl(defaultValue, validators));
            field.showPassword = false;
          }
          fields.push(field);
        });
      }

      this.fields = fields;
      this.form = this.formGroup;
    }
  }

  getFieldLabel(field: CRMField) {
    if (field.label) {
      return field.label;
    }

    if (field.name.length > 0) {
      return 'API ' + field.name[0].toUpperCase() + field.name.slice(1).toLowerCase().replace('_', ' ');
    }

    return field.name;
  }

  getFieldType(field: CRMField) {
    if (field.admin_only && (!this.currentUser || !this.currentUser.is_staff)) {
      return 'hidden';
    }

    if ((field.type === 'password') && field.showPassword) {
      return 'text';
    }

    return field.type || 'text';
  }

  toggleBooleanField(event, fieldname: string) {
    const control = this.getControl(fieldname);
    control.patchValue(!!event.target.checked ? '1' : '');
  }
}
