import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {
  StepDiscountSettingsComponent,
  OfferDetailsComponent,
  StepBuilderComponent,
  StepButtonComponent,
  StepDiscountComponent,
  StepEmulatorComponent,
  StepExchangeableProductsComponent,
  StepFormComponent,
  StepMediaComponent,
  StepSurveyOptionsComponent,
  StepResponseScreenComponent,
  StepTextualComponent,
  SupportOffersComponent,
  SurveyStepsComponent,
  UpsellOffersComponent,
  DownsellOffersComponent,
  OfferPopupComponent,
  StepPopupComponent,
  StepTimeDelayComponent,
  StepFixedImagesComponent,
  StepTimeOptionsComponent,
  StepCategorySelectionComponent,
  StepActionsComponent,
  TemplatePopupComponent,
  StepTemplates,
  StepProductImagesComponent,
  CustomerPortalOffersComponent
} from '.';
import { StepsRoutingModule } from './steps-library.routing';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SharedModule } from '../_shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatExpansionModule, MatTreeModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    SurveyStepsComponent,
    UpsellOffersComponent,
    SupportOffersComponent,
    StepCategorySelectionComponent,
    DownsellOffersComponent,
    CustomerPortalOffersComponent,
    StepDiscountSettingsComponent,
    StepDiscountComponent,
    StepExchangeableProductsComponent,
    StepButtonComponent,
    StepMediaComponent,
    StepFixedImagesComponent,
    StepSurveyOptionsComponent,
    StepTimeOptionsComponent,
    StepResponseScreenComponent,
    StepTextualComponent,
    StepActionsComponent,
    StepTimeDelayComponent,
    OfferDetailsComponent,
    StepBuilderComponent,
    StepFormComponent,
    StepEmulatorComponent,
    OfferPopupComponent,
    StepPopupComponent,
    TemplatePopupComponent,
    StepTemplates,
    StepProductImagesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    StepsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    SharedModule,
    NgbModule,
    MatExpansionModule,
    MatTreeModule,
    DragDropModule,
    NgxSmartModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [],
  bootstrap: []
})
export class StepsLibraryModule {}
