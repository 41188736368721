import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { CheckoutAccountType, CheckoutGatewayType, CheckoutMerchantPopupText } from '../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudSavePopupComponent } from '../../_directives';
import { FormBuilder } from '@angular/forms';
import { AlertService, CheckoutsService } from '../../_services';
import { Location } from '@angular/common';

@Component({
  moduleId: module.id.toString(),
  selector: 'checkout-merchant-popup',
  templateUrl: './checkout-merchant-popup.component.html',
  styleUrls: ['./checkout-merchant-popup.component.scss'],
})
export class CheckoutMerchantPopupComponent extends CrudSavePopupComponent implements OnInit, OnDestroy {
  @Output() next: EventEmitter<any> = new EventEmitter<any>();
  ngModelOptions = { standalone: true };
  verifiedText: string = CheckoutMerchantPopupText.VerifiedText;
  fullText: string = CheckoutMerchantPopupText.UnverifiedText;
  truncatedText = this.fullText.substring(0, 80) + '...';
  truncatedVerifiedText = this.verifiedText.substring(0, 80) + '...';
  isExpanded: boolean = false;
  isTextExpanded = false;
  CheckoutAccountType = CheckoutAccountType;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected checkoutService: CheckoutsService,
    protected alertService: AlertService
  ) {
    super(router, location, route, checkoutService, alertService);
    this.objectName = 'Checkout';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {}

  onAccountSelect(accountType: CheckoutAccountType) {
    const data = { checkoutAccountType: accountType };
    this.onSave.emit(data);
  }

  toggleText() {
    this.isTextExpanded = !this.isTextExpanded;
  }

  isUnverified(gatewayType: CheckoutGatewayType): boolean {
    return gatewayType === CheckoutGatewayType.Unverified;
  }

  toggleVerifiedText() {
    this.isExpanded = !this.isExpanded;
  }
}
