import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PaginationService } from "./pagination.service";

@Injectable({
  providedIn: "root",
})
export class StepTemplateService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, "step_templates");
  }

  copy(stepId: string | number): Observable<any> {
    return this.http.post(`${this.route}/${stepId}/copy/`, {});
  }

  paste(data: any): Observable<any> {
    return this.http.post(`${this.route}/paste/`, data);
  }
}
