import {Component, OnInit} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, UserService, DevEnvService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {User} from '../_models';

@Component({
  moduleId: module.id,
  templateUrl: './dev-env-new.component.html',
})
export class DevEnvNewComponent extends CrudSaveComponent implements OnInit {
  public user: User = null
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected devEnvService: DevEnvService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected userService: UserService
  ) {
    super(router, location, route, devEnvService, alertService);
    this.isNew = true
    this.objectName = 'development environment'
    this.title = 'Create new Development Environment'
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      angular_branch: ['master', [Validators.required]],
      python_branch: ['master', [Validators.required]],
      vue_branch: ['master', [Validators.required]],
      email: [null, [Validators.required]],
    })
    super.ngOnInit();

    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user
        this.form.patchValue({email: user.email})
      })
  }
}
