import {Component, OnInit} from '@angular/core';
import {TicketSystemNewComponent} from './ticket-system-new.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, TicketSystemService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {TicketSystem, TicketSystemType} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './ticket-system-edit.component.html',
  styleUrls: ['./ticket-system.component.css']
})

export class TicketSystemEditComponent extends TicketSystemNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected ticketSystemService: TicketSystemService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, ticketSystemService, alertService, formBuilder);
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.data$.subscribe((item: TicketSystem) => {
      if (item) {
        this.ticketSystem = item
        this.objectName = item.display_name
        this.form.patchValue({
          display_name: item.display_name,
          type: item.type
        })
        this.selectTicketSystemType(item.type)
      }
    }, error => {
      this.handleError(error)
    })
  }

  selectTicketSystemType(type) {
    if (this.ticketSystemTypes) {
      let ticketSystemType = this.ticketSystemTypes.filter((ticketType: TicketSystemType) => ticketType.id === type)
      if (ticketSystemType.length > 0) {
        this.selectedTicketSystemType = ticketSystemType[0]
      }
    } else {
      let _this = this
      setTimeout(() => {
        _this.selectTicketSystemType(type)
      }, 100)
    }
  }
}
