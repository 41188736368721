import {themeCommand} from '../commands';
import grapesjs from 'grapesjs';


export default grapesjs.plugins.add('themes', (editor, options) => {
  // proper way to add button into above options bar.
  editor.Panels.addButton('options', {
    id: 'theme-button',
    className: 'fa fa-adjust',
    context: 'select-theme',
    command: themeCommand,
    attributes: {
      'title': 'Select Theme',
      'data-tooltip-pos': 'bottom',
      'id': 'select-theme-id',
      'opt': options.variables,
      'styles': options.styles,
      'selectedTheme': options.selectedTheme,
      'update_theme' : (selectedTheme: string) => {
        return options.update_theme(selectedTheme);
      }
    },
  });
});
