import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AccountService, AlertService, PlanService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder} from '@angular/forms';
import {
  Account,
  AccountBillingStatusLabels,
  AccountStatusLabels,
  AccountBillingStatus,
  Plan
} from "../_models";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {parseISODateString, formatDateForQuery} from "../_helpers";

@Component({
  moduleId: module.id.toString(),
  selector: 'account-form',
  templateUrl: './account-form.component.html'
})
export class AccountFormComponent extends CrudSavePopupComponent implements OnInit {
  account: Account;
  setupComplete: boolean = false;
  plans: Plan[] = [];
  startDate: NgbDateStruct;
  startDateDisplayString = '';
  ngModelOptions = {standalone: true};

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected accountService: AccountService,
              protected alertService: AlertService,
              private planService: PlanService
  ) {
    super(router, location, route, accountService, alertService);
    this.objectName = 'Account';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null],
      status: [null],
      billing_status: [null],
      plan: [null],
      regenerate_invoices: [false]
    }));

    this.planService.list()
      .subscribe(
        (plans: Plan[]) => {
          this.plans = plans;
        },
        error => {
          this.handleError(error);
        }
      );

    super.ngOnInit();
  }

  get statuses() {
    let statuses = [];

    AccountStatusLabels.forEach((value, index) => {
      statuses.push({id: index, label: value});
    });

    return statuses;
  }

  get billingStatuses() {
    let statuses = [];

    AccountBillingStatusLabels.forEach((value, index) => {
      statuses.push({id: index, label: value});
    });

    return statuses;
  }

  get currentPlanLabel() {
    return (this.account && this.account.plan) ? this.account.plan.name + ' (current plan)' : 'None';
  }

  getPlanLabel(plan: Plan) {
    let label = plan.name;

    if (this.account && this.account.plan && (this.account.plan.name === plan.name)) {
      label += ' (update to latest)';
    }

    return label;
  }

  protected onInputDataChanged() {
    let startDate = new Date();

    this.account = this.inputData;

    if (this.account) {
      let data = {name: this.account.name, plan: null}

      if (this.account.plan) {
        data.plan = this.account.plan.id;
      }

      this.form.patchValue(data);
    }

    if (this.account && this.account.billing_start_date) {
      startDate = parseISODateString(this.account.billing_start_date);
    }

    this.startDate = {
      year: startDate.getUTCFullYear(), month: startDate.getUTCMonth() + 1,
      day: startDate.getUTCDate()
    };
    this.startDateDisplayString = startDate.toLocaleDateString("en-US", {timeZone: 'UTC'});
  }

  canSetStartDate() {
    if (this.form) {
      const control = this.form.get('billing_status');

      if (control && (control.value || control.value === 0)) {
        const status = control.value.toString();

        return status === AccountBillingStatus.Billable.toString() ||
          status === AccountBillingStatus.InTrial.toString();
      }
    }

    return false;
  }

  parseStartDate(dateStruct: NgbDateStruct) {
    if (dateStruct) {
      const date = new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);

      if (date) {
        this.startDateDisplayString = date.toLocaleDateString("en-US", {timeZone: 'UTC'});
        this.startDate = dateStruct;
      }
    }
  }

  protected getFormData() {
    let data = this.form.value;

    if (this.canSetStartDate() && this.startDate) {
      data['billing_start_date'] = this.startDate.year + '-' + this.startDate.month + '-' + this.startDate.day
    }

    return data;
  }

}
