import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { OffersAbstractComponent } from './offers-abstract.component';
import {
  AlertService,
  CampaignService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  PusherService,
  StepTemplateService,
  StorageService,
  UserService,
} from '../../_services';
import { OfferIntents, OfferTypes } from '../../../app/_models';

@Component({
  selector: 'upsell-offers',
  templateUrl: './../steps.component.html',
  styleUrls: ['./../steps.component.scss'],
})
export class UpsellOffersComponent extends OffersAbstractComponent implements OnInit {
  stepsTitle = 'Upsell Offers';
  offerType = OfferTypes.Upsell;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected funnelStepService: FunnelStepService,
    protected stepService: StepTemplateService,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected pusherService: PusherService,
    protected loader: LoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    protected route: ActivatedRoute,
    protected storageService: StorageService
  ) {
    super(
      router,
      location,
      alertService,
      funnelService,
      funnelStepService,
      stepService,
      campaignService,
      userService,
      pusherService,
      loader,
      ngxSmartModalService,
      route,
      storageService
    );
    this.popupData.offer_intent = OfferIntents.CancelOrder;
  }

  navigateToStepTemplates(offerId?: number) {
    this.router.navigate(['steps', 'upsell', offerId, 'category', this.offerType]);
  }

  navigateToViewDetails(offerId?: number) {
    this.router.navigate(['steps', 'upsell', this.offerType, 'details', offerId]);
  }

  saveTemplate(event: { use_existing_template: boolean }): void {
    this.ngxSmartModalService.getModal('templatePopup').close();
    this.router.navigate([
      'steps',
      'upsell',
      this.popupData.offer_id,
      'category',
      this.offerType,
      'useExisting',
      event.use_existing_template,
    ]);
  }
}
