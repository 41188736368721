import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CRMService, ProductService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {CRM, Product} from "../_models";
import {EMPTY} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {ProductMarketplaceFieldsComponent} from './product-marketplace-fields.component'
import {ProductBasicFieldsComponent} from './product-basic-fields.component'
import {ProductPathFieldsComponent} from './product-path-fields.component'

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-edit.component.html'
})
export class ProductEditComponent extends CrudSaveComponent implements OnInit, AfterViewInit {
  product: Product;
  crm: CRM;
  @ViewChild(ProductMarketplaceFieldsComponent, { static: false }) productMarketplaceFields: ProductMarketplaceFieldsComponent;
  @ViewChild(ProductBasicFieldsComponent, { static: false }) productBasicFields: ProductBasicFieldsComponent;
  @ViewChild(ProductPathFieldsComponent, { static: false }) productPathFields: ProductPathFieldsComponent;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected productService: ProductService,
    private crmService: CRMService,
    private formBuilder: FormBuilder,
    protected alertService: AlertService
  ) {
    super(router, location, route, productService, alertService);
    this.isNew = false;
    this.isPartial = true;
    this.objectName = 'Product';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    super.ngOnInit();

    this.data$.pipe(mergeMap((product: Product) => {
      if (product) {
        this.product = product;
        this.addProductFields();

        return this.crmService.get(product.crm);
      }

      return EMPTY;
    })).subscribe(
      (crm: CRM) => {
        this.crm = crm;
      },
      error => {
        this.handleError(error);
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => this.addProductFields(), 0);
  }

  private addProductFields() {
    if (this.product) {
      if (this.productBasicFields && !this.form.get('productBasicFields')) {
        this.form.addControl('productBasicFields', this.productBasicFields.form)
      }

      if (this.productMarketplaceFields && !this.form.get('productMarketplaceFields')) {
        this.form.addControl('productMarketplaceFields', this.productMarketplaceFields.form)
      }

      if (this.productPathFields && !this.form.get('productPathFields')) {
        this.form.addControl('productPathFields', this.productPathFields.form)
      }
    }
  }

  protected getFormData() {
    return Object.assign({},
      this.productBasicFields ? this.productBasicFields.getFormData() : {},
      this.productMarketplaceFields ? this.productMarketplaceFields.getFormData() : {},
      this.productPathFields ? this.productPathFields.getFormData() : {}
    );
  }

}
