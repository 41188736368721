import {Component} from '@angular/core';
import {CrudPagedListComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ExchangeableProductsSetService} from '../_services';
import {Observable} from 'rxjs';
import {ExchangeableProductsSet} from '../_models';

@Component({
  moduleId: module.id,
  selector: 'exchangeable-products-set',
  templateUrl: './exchangeable-products-set.component.html'
})
export class ExchangeableProductsSetComponent extends CrudPagedListComponent {
  exchangeableProductsSets$: Observable<ExchangeableProductsSet[]> = this.data$

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected exchangeableProductsSetService: ExchangeableProductsSetService,
    protected alertService: AlertService
  ) {
    super(router, location, route, exchangeableProductsSetService, alertService);
    this.title = 'Exchangeable Products Set'
  }

  protected runInitialQuery() {
    this.queryData()
  }
}
