import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {Form} from '../_forms';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent extends Form implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
    this.title = 'Image Gallery';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
