import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, UserService, FolderService} from '../_services';
import {FolderNewComponent} from './folder-new.component';
import {FormBuilder} from '@angular/forms';
import {Folder} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './folder-edit.component.html',
  selector: 'folder-info'
})
export class FolderEditComponent extends FolderNewComponent {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected folderService: FolderService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected userService: UserService
  ) {
    super(router, location, route, folderService, alertService, formBuilder, userService);
    this.isNew = false;
    this.objectName = "folder";
  }

  ngOnInit() {
    super.ngOnInit();
    this.data$.subscribe((folder: Folder) => {
      if (folder) {
        this.folder = folder;
        this.type = folder.type;
        this.parent = folder.parent;
        Object.keys(this.form.controls).forEach(controlName => {
          this.form.controls[controlName].patchValue(folder[controlName])
        })
      }
    })
  }

  getUrlParams() {
    let obj = {};

    if (this.folder) {
      obj['type'] = this.folder.type;
      obj['parent'] = this.folder.id;
      return obj
    }

    return obj
  }
}
