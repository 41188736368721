import { InfoCards } from "../../_models";
import { InlineTableData } from "../data-visualization/data-table/data-table.component";
import { DoughnutChartData } from "../data-visualization/doughnut-chart/doughnut-chart.component";

/* Cards Data */
export const marketplaceInfoCards: InfoCards = [
  {
    title: "Revenue",
    value: "",
    hint: "The overall income generated from completed marketplace orders.",
  },
  {
    title: "Completed Orders",
    value: "",
    hint: "The count of successful orders that were placed.",
  },
  {
    title: "Average Order Value",
    value: "",
    hint: "The average amount buyers spend on your marketplace.",
  },
  {
    title: "Conversion Rate",
    value: "",
    hint: "The total conversion rate of your Marketplace, calculated as total sales divided by total visitors.",
  },
];

/* Charts Data */
export const marketplaceDoughnutData: DoughnutChartData = {
  data: [0, 0, 0],
  noData: false,
  loading: true,
  labels: ["Checkouts", "Cart Abandons", "Payment Declines"],
  backgroundColors: [
    {
      backgroundColor: ["#10B981", "#EFC654", "#EF4444"],
    },
  ],
};

export const marketplaceTableData: InlineTableData = [
  {
    name: "Checkouts",
    color: "#10B981",
  },
  {
    name: "Cart Abandons",
    color: "#EFC654",
  },
  {
    name: "Payment Declines",
    color: "#EF4444",
  },
];

export const productConversions = {
  data: [],
  headers: ["PRODUCT NAME", "VISITORS", "SALES", "REVENUE", "CONVERSION RATE"],
  loading: true,
};

export const campaignConversions = {
  data: [],
  loading: true,
};

export const saleResponses = {
  data: [],
  loading: true,
};

export const marketplaceWatchlist = {
  data: [
    {
      name: "Cart Abandons",
      value: "",
      percentage: 0,
      is_negative: false,
      hint: "Visitors who added items to their cart but did not proceed to checkout.",
    },
    {
      name: "Payment Declines",
      value: "",
      percentage: 0,
      hint: "Individuals whose payment cards were declined during the checkout process.",
    },
    {
      name: "In Carts",
      value: "",
      percentage: 0,
      hint: "Visitors who added items to their shopping cart.",
    },
  ],
  loading: true,
  noData: false,
  total: "0",
};

export const visitors = {
  data: [],
  loading: true,
  noData: false,
  total: "0",
};

export enum MarketplaceInfo {
  Revenue = 0,
  CompletedOrders = 1,
  AverageOrderValue = 2,
  ConversionRate = 3,
}

export const marketplaceHints = {
  summary: {
    detail: "An overview of possible outcomes during marketplace sessions.",
    checkout: "Visitors who successfully placed new orders in the marketplace.",
    abandon:
      "Visitors who added items to their cart but did not proceed to checkout.",
    decline:
      "Individuals whose payment cards were declined during the checkout process.",
  },
  visitors: "The number of visitors categorized by time.",
  sales:
    "Sales organized by the locations or platforms that led customers to your store.",
  product_conversion:
    "The products with the highest sales in relation to the total number of views.",
  campaign_conversion:
    "Evaluating the effectiveness of outbound campaigns by comparing sales to visitors.",
  watchlist: "Customers requiring ongoing monitoring.",
};
