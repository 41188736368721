import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CallCenterActionService, WidgetService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {
  CallCenterAction,
  getFunnelPathVariables,
  WidgetVariable,
  DelayActionType,
  DelayActionTypeLabels
} from "../_models";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  selector: 'call-center-action-form',
  templateUrl: './call-center-action-form.component.html'
})
export class CallCenterActionFormComponent extends CrudSavePopupComponent implements OnInit {
  action: CallCenterAction;
  contentVariables = null;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected actionService: CallCenterActionService,
              protected alertService: AlertService,
              private widgetService: WidgetService
  ) {
    super(router, location, route, actionService, alertService);
    this.objectName = 'Call Center Action';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null, Validators.required],
      prevent_action: [false],
      action_delay: [24, [Validators.required, Validators.min(1)]],
      delay_type: [DelayActionType.Normal, Validators.required],
      min_price: [0, [Validators.required, Validators.min(0)]],
      contact_popup: [null],
      after_hours_popup: [null]
    }));

    super.ngOnInit();

    //get the content variables
    this.widgetService.getVariables()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (widgets: WidgetVariable[]) => {
          let items = [{value: '{support_phone}', text: 'Call Center Phone'}].concat(getFunnelPathVariables());

          widgets.forEach((widget: WidgetVariable) => {
            items.push({value: '{' + widget.slug + '}', text: widget.name});
          });

          items.sort((a, b): number => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          });

          this.contentVariables = items;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  protected onInputDataChanged() {
    this.action = this.inputData;
    this.isNew = !this.action || !this.action.id;
  }

  getDelayActionTypes() {
    let types = [];

    DelayActionTypeLabels.forEach((value, index) => {
      types.push({id: index, label: value});
    });

    return types;
  }

}
