import { Injectable } from '@angular/core';
import { PaginationService } from './pagination.service';
import { HttpClient } from '@angular/common/http';
import { BaseModel } from '../_models';

@Injectable()
export class CheckoutsService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'checkouts');
  }

  deploy(id: string | number, obj: BaseModel) {
    return this.http.put(`${this.route}/${id}/deploy/`, obj);
  }
  
  checkSubdomain(subdomain: string) {
    return this.http.post(this.route + '/validate_subdomain/', { subdomain });
  }
}
