import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelStepService, CampaignService, StorageService} from '../_services';
import {PreviewFormComponent} from '../preview';
import {FormBuilder} from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  selector: 'funnel-step-preview-form',
  templateUrl: '../preview/preview-form.component.html'
})
export class FunnelStepPreviewFormComponent extends PreviewFormComponent implements OnInit {
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected stepService: FunnelStepService,
              protected alertService: AlertService,
              protected campaignService: CampaignService,
              protected storageService: StorageService
  ) {
    super(router, location, route, formBuilder, stepService, alertService, campaignService, storageService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
