import {fontFamilies} from '../../web-builder/editorConfig';

export enum Types {
  NONE = '',
  PATH_INPUT = 'path-input'
}

export enum InputTypes {
  LINK = 'link',
  BUTTON =  'button',
  MAIN_MENU =  'main-menu',
  BACK_BUTTON =  'back-button',
  RADIO_BUTTON = 'radio-button',
  RADIO_SELECT = 'radio-select-button',
  SELECT = 'select',
  LANGUAGE = 'language',
  USER_DROPDOWN = 'user-dropdown',
  HEADER = 'header',
  FOOTER = 'footer',
  NONE = '',
  SELECT_LANGUAGE = 'select-language',
  LOGOUT_BUTTON = 'logout',
  IMAGE = 'image',
  HEADER_LOGO = 'header-logo',
  IMAGE_LINK = 'image-link',
  VIDEO = 'video'
}

export enum Identifiers {
  HeaderLogo = 'logo',
  DesktopLogo = 'desktoplogo',
  Mobilelogo = 'mobilelogo',
  Logobg = 'logo-bg'
}

export const getInputTypes = (type?, hasInputs = true) => {
  let defaultTypes = [
    { value: Types.NONE, name: 'None (External Link)' }
  ];
  if (hasInputs) {
    defaultTypes.push({ value: Types.PATH_INPUT, name: 'Path Input' });
  }

  if (type == InputTypes.RADIO_BUTTON) {
    let noneType = defaultTypes.find((t) => t.value === Types.NONE);
    if (noneType) {
      noneType.name = '-- Default';
    }
  }

  return defaultTypes;
};

export const isValidInputType  = (type) => {
  return Object.values(Types).includes(type);
};


export const postInputTraitsChange = (component, txt) => {
  const label = component.getEl().querySelector('label') || component.getEl().querySelector('span');
  // Update Valid input types button, radio button label/text
  switch(component.getAttributes().elemtype) {
    case InputTypes.RADIO_BUTTON:
      if (label) label.textContent = txt;
      break;

    case InputTypes.BUTTON:
    case InputTypes.LINK:
      component.set('content', txt);
      break;
  }
};

export const Languages = [
  {code: 'en', label: 'English'},
  {code: 'es', label: 'Español'},
  {code: 'fr', label: 'Français'},
  {code: 'de', label: 'Deutsche'},
  {code: 'pt', label: 'Português'},
  {code: 'ru', label: 'Pусский'},
  {code: 'zh-CN', label: '中文'},
  {code: 'ko', label: '한국어'},
  {code: 'ja', label: '日本語'},
  {code: 'hi', label: 'हिंदी'}
];

export const setEditorFontFamilies = (editor) => {
  const prop = editor.StyleManager.getProperty('typography', 'font-family');
  prop.set('options', fontFamilies);
};

export const componentOptions = {
  editable: false,
  layerable: false,
  droppable: false,
  draggable: false,
  removeable: false,
  selectable: false,
  highlightable: false,
  hoverable: false,
  badgable: false,
  resizeable: false,
};

export const selectLanguageCss = `

`;
