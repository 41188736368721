import { Component, Input, OnInit } from '@angular/core';

import { FormArray, FormGroup } from '@angular/forms';
import { ExecutionTypeEnum, StepDiscountSettingsLayout, StepElements } from '../../../_models';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CallCenterActionService, StepEmulatorService } from '../../../_services';

@Component({
  selector: 'step-actions',
  templateUrl: './step-actions.component.html',
  styleUrls: ['./step-actions.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class StepActionsComponent implements OnInit {
  @Input() layout: StepDiscountSettingsLayout;
  @Input() form: FormGroup;
  actions: FormGroup;
  stepElements = StepElements;
  executionTypes = ExecutionTypeEnum;
  callCenterActions = [];
  selectedCallCenterAction: string | number;
  callCenterActionsLoading = false;

  constructor(
    protected callCenterActionService: CallCenterActionService,
    protected stepEmulatorService: StepEmulatorService
  ) {}

  ngOnInit() {
    const inputs = this.form.get('inputs') as FormArray;
    const firstInput = inputs.at(0) as FormGroup;
    this.actions = (firstInput.get('actions') as FormArray).at(0) as FormGroup;
    this.callCenterActionsLoading = true;
    this.callCenterActionService.list().subscribe(
      (data) => {
        this.callCenterActionsLoading = false;
        this.callCenterActions = data;
      },
      (error) => {
        this.callCenterActionsLoading = false;
        console.error('Error fetching call center actions:', error);
      }
    );
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  get isImmediate() {
    return this.actions.get('execution_type').value === ExecutionTypeEnum.Immediate;
  }
  get isDelayed() {
    return this.actions.get('execution_type').value === ExecutionTypeEnum.Delayed;
  }
  get isValidationOnly() {
    return this.actions.get('execution_type').value === ExecutionTypeEnum.ValidationOnly;
  }
}
