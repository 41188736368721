import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, OrderService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CampaignProduct, NewOrder, Order} from '../_models';
import {Form} from '../_forms';
import {takeUntil} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-product-test-order',
  templateUrl: './campaign-product-test-order.component.html'
})
export class CampaignProductTestOrderComponent extends Form implements OnInit {
  newOrder: NewOrder;
  @Input('product') product: CampaignProduct;
  @Input('order') order: Order;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private orderService: OrderService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      decline_transaction: [false],
      mark_shipped: [false],
      force_rebill: [false],
      copy_pricing: [false]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.newOrder = null;

    if ((this.product || this.order) && this.form.valid) {
      this.loading = true;
      let observable: Observable<NewOrder[]>;
      let orderData = {
        decline_transaction: this.form.value.decline_transaction,
        mark_shipped: this.form.value.mark_shipped,
        force_rebill: this.form.value.force_rebill,
      }

      if (this.order) {
        const orderData = Object.assign({}, this.form.value);
        observable = this.orderService.copyTestOrder(this.order, orderData);
      }
      else {
        const orderData = Object.assign({}, this.form.value, {
          campaign_products: [this.product.id],
        });
        delete orderData['copy_pricing'];
        observable = this.orderService.sendTestOrder(orderData);
      }

      observable.pipe(takeUntil(this.destroy$))
        .subscribe(
          (orders: NewOrder[]) => {
            this.alertService.success('Created new test order.', true);
            this.newOrder = orders[0];
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.handleSubmitError(error);
          });

    }
  }
}
