import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ReportsAbstractService} from './reports-abstract.service';
import {Observable} from 'rxjs';

@Injectable()
export class SessionSummaryReportService extends ReportsAbstractService {
  public reportRoute: string = 'session_summary_report'
  constructor(protected http: HttpClient) {
    super(http);
    this.route = this.reportRoute;
  }

  getSummary(params: any): Observable<any> {
    params['include_hangups'] = 'false';
    params['include_unknowns'] = 'false';
    params['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    return this.http.post<any>(this.route + "/summary/", params);
  }

  getOrderOptionsBreakdown(params: any): Observable<any> {
    params['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    return this.http.post<any>(this.route + "/order_options_breakdown/", params);
  }

  getMainMenuBreakdown(params: any): Observable<any> {
    params['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    return this.http.post<any>(this.route + "/main_menu_breakdown/", params);
  }

  downloadFile(queryParams, elem) {
    queryParams['customer_mode'] = this.customerMode.getValue() ? 'true' : 'false';
    this.getDownloadReportData(queryParams)
      .subscribe(_ => {
        elem.loading = false
      }, error => {
        elem.loading = false
      })
  }

  getDeviceTypes(queryParams: any): Observable<any> {
    let params = new HttpParams({fromObject: queryParams});
    return this.http.get(this.route + "/device_types/", {params: params});
  }
}
