import { Audience, AudienceConditionType, AudienceDateTypes, AudiencePopup, AudienceStatus } from '../_models';
import { CrudPagedListComponent } from '../_directives';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, AudienceService, LoaderService } from '../_services';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss'],
  selector: 'app-audiences-list'
})
export class AudienceComponent extends CrudPagedListComponent implements OnInit {
  audiencesList: Audience[] = [];
  showModal: boolean = false;
  showThreeDotMenu: boolean = false;
  selectedRow: number = -1;
  audienceStatus = AudienceStatus;
  audienceDateTypes = AudienceDateTypes;
  audienceTableStatuses = AudienceStatus.All;
  selectedStatus: string = AudienceStatus.All;
  selectedFilter: string = AudienceDateTypes.LastModified;
  selectedDate: string = AudienceDateTypes.LastModified;
  searchQuery: string;
  showAddButton: boolean = true;
  showViewButton: boolean = true;
  selectedIndex: number | null = null;
  tableData: any[] = [];
  pageTitle: string = 'Advance Audience Filters';
  popupData = {
    name: '',
    audience_id: null,
    popupType: null,
    audience_data: {}
  };
  originalTableData: any[] = [...this.tableData];
  AudiencePopup = AudiencePopup;
  popupTitle: string = '';
  pageSize = 20;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected audienceService: AudienceService,
    protected alertService: AlertService,
    protected loader: LoaderService,
    public ngxSmartModalService: NgxSmartModalService
  ) {
    super(router, location, route, audienceService, alertService);
    this.objectName = 'audience';
  }
  ngOnInit() {
    super.ngOnInit();
    this.queryData();
    this.loader.show();
  }

  queryData() {
    let params = Object.assign({}, this.getQueryFilter(), { page: this.page, page_size: this.pageSize });
    this.loader.show();
    this.audienceService.list(params).subscribe(
      (response) => {
        this.loader.hide();
        this.audiencesList = response.results;
        this.setPagerData(response);
        this.tableData = response.results.map((item: Audience) => {
          const lastModifiedMoment = moment(item.updated).tz('EST');

          const lastModified = {
            time: lastModifiedMoment.format('hh:mm A'), 
            date: lastModifiedMoment.format('MM/DD/YYYY'), 
          };

          const conditions = [
            item.fulfillment_type && AudienceConditionType.FulfillmentType,
            (item.is_3ds && AudienceConditionType.TransactionFilters) ||
              (item.merchant_ids && item.merchant_ids.length > 0 && AudienceConditionType.TransactionFilters) ||
              (item.cc_types && item.cc_types.length && AudienceConditionType.TransactionFilters),
            item.max_billing_cycle && AudienceConditionType.BillingCycle,
            item.states && item.states.length > 0 && AudienceConditionType.Location,
            item.traffic_sources && item.traffic_sources.length > 0 && AudienceConditionType.TrafficSource,
            item.use_product_filter && AudienceConditionType.ProductFilters,
            item.use_crm_campaign_filter && AudienceConditionType.CampaignFilters
          ].filter(Boolean);

          return {
            audience: item,
            id: item.id,
            audienceName: item.name,
            status: item.paths.length > 0 ? AudienceStatus.Assigned : AudienceStatus.Unassigned,
            conditions,
            paths: item.paths.length > 0 ? item.paths : [],
            lastModified,
            showAllConditions: conditions.length ? false : true
          };
        });
        this.originalTableData = this.tableData;
        this.filterTableData();
      },
      (error) => {
        this.loader.hide();
        this.alertService.error(error.message);
      }
    );
  }

  createAudience() {
    this.router.navigate(['audiences', 'new']);
  }

  editAudience(id: number) {
    this.router.navigate(['audiences', 'edit', id]);
  }

  navigateToPath(pathId: number) {
    this.router.navigate(['new-paths', 'edit', pathId]);
  }

  openAudiencePopup(
    popupType: number,
    audienceId?: number | string,
    audienceName?: string,
    audience_data?: Audience | null
  ): void {
    switch (popupType) {
      case AudiencePopup.Update:
        this.popupTitle = 'Rename Audience';
        break;
      case AudiencePopup.Duplicate:
        this.popupTitle = 'Duplicate Audience';
        break;
      case AudiencePopup.Delete:
        this.popupTitle = 'Delete Audience';
        break;
    }

    this.popupData.name = audienceName || '';
    this.popupData.audience_id = audienceId || null;
    this.popupData.popupType = popupType;
    this.popupData.audience_data = audience_data || null;
    this.ngxSmartModalService.getModal('audiencePopup').open();
  }

  closeAudiencePopup() {
    this.ngxSmartModalService.getModal('audiencePopup').close();
  }

  saveAudience() {
    this.queryData();
    this.ngxSmartModalService.getModal('audiencePopup').close();
  }

  filterData() {
    this.tableData =
      this.selectedStatus === AudienceStatus.All
        ? [...this.originalTableData]
        : this.originalTableData.filter((item) => item.status.toLowerCase() === this.selectedStatus);
  }

  filterTableData() {
    const filteredData = [...this.originalTableData];
    switch (this.selectedFilter) {
      case 'name':
        filteredData.sort((a, b) => a.audienceName.localeCompare(b.audienceName));
        break;
      case 'status':
        filteredData.sort((a, b) => a.status.localeCompare(b.status));
        break;
      case 'lastModified':
        this.selectedDate = AudienceDateTypes.LastModified;
        filteredData.sort((a, b) => {
          const dateA = moment(a.lastModified.date + ' ' + a.lastModified.time, 'DD MMM, YYYY HH:mm');
          const dateB = moment(b.lastModified.date + ' ' + b.lastModified.time, 'DD MMM, YYYY HH:mm');
          return dateB.diff(dateA);
        });
        break;
      default:
        break;
    }
    this.tableData = filteredData;
  }

  searchData() {
    this.setFilter({ name__icontains: this.searchQuery });
  }
  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }
  toggleShowAllConditions(item: any): void {
    item.showAllConditions = !item.showAllConditions;
  }

  togglePathList(index: number): void {
    if (this.selectedRow === index) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = index;
    }
  }

  closePathList() {
    this.selectedRow = -1;
    this.tableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }
  popupIconMap: Partial<Record<AudiencePopup, string>> = {
    [AudiencePopup.Delete]: '/assets/stepAssets/delete-icon.svg',
    [AudiencePopup.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [AudiencePopup.Update]: '/assets/stepAssets/rename.svg'
  };
}
