import { Identifiers, InputTypes } from "../_helpers/grapesjs";

enum RuleType {
  BorderColor = 'border'
}

const updateCssRule = (styles, ruleType, ruleValue) => {
  let css = {};
  delete styles[ruleType];
  delete styles[`${ruleType}-color`];
  delete styles[`${ruleType}-top-color`];
  delete styles[`${ruleType}-right-color`];
  delete styles[`${ruleType}-bottom-color`];
  delete styles[`${ruleType}-left-color`];
  css[`${ruleType}-color`] = ruleValue;
  css[`${ruleType}-top-color`] = ruleValue;
  css[`${ruleType}-right-color`] = ruleValue;
  css[`${ruleType}-bottom-color`] = ruleValue;
  css[`${ruleType}-left-color`] = ruleValue;

  if (styles) {
    css = {...css, ...styles};
  }

  // remove false styles
  for (let key in Object.keys(css)) {
    if (css[key] && css[key] == '[object Object]') {
      delete css[key]
    }
  }

  return css;
};

const applyPseudoStyles = (styles, themeStyle, defaultColor) => {
  let css = {};
  if (styles) {
    if (styles['background-color'] && styles['bg-color-type'] !== 'custom') {
      let backgroundColor = styles['background-color'];
      if (backgroundColor == themeStyle.variables.theme_color) {
        css['background-color'] = 'var(--theme_color)';
      } else if (backgroundColor == themeStyle.variables.button_active_color) {
        css['background-color'] = 'var(--button_active_color)';
      } else if (backgroundColor == themeStyle.variables.button_hover_color){
        css['background-color'] = 'var(--button_hover_color)';
      }

      if (css['background-color']) {
        delete styles['background-color'];
      }
    }
    if (styles['border-color-type'] !== 'custom' && (styles['border-color'] || styles['border-top-color'])) {
      let borderColor = styles['border-color'] || styles['border-top-color'];
      if (borderColor == themeStyle.variables.theme_color) {
        css['border-color'] = 'var(--theme_color)';
      } else if (borderColor == themeStyle.variables.button_active_color) {
        css['border-color'] = 'var(--button_active_color)';
      } else if (borderColor == themeStyle.variables.button_hover_color){
        css['border-color'] = 'var(--button_hover_color)';
      }

      if (css['border-color']) {
        delete styles['border'];
        delete styles['border-top-color'];
        delete styles['border-right-color'];
        delete styles['border-bottom-color'];
        delete styles['border-left-color'];
        css = {
          ...css,
          'border-top-color': css['border-color'],
          'border-right-color': css['border-color'],
          'border-bottom-color': css['border-color'],
          'border-left-color': css['border-color'],
        };
      }
    }

    if (css && styles) {
      css = {
        ...styles,
        ...css
      };
    }

    // remove false styles
    for (let key in Object.keys(css)) {
      if (css[key] && css[key] == '[object Object]') {
        delete css[key]
      }
    }
  }
  return css;
};


export const applyTheming = (model, themeStyle, editor, prevThemeStyle) => {
  const cssComposer = editor.CssComposer;

  const { type: modelType, attributes } = model.attributes;
  const { variables } = themeStyle;

  if (modelType === InputTypes.BUTTON) {
    if (attributes.identifier !== InputTypes.IMAGE_LINK) {
      buttonTheming(model, themeStyle, editor, prevThemeStyle);
    }
  }
  else if (modelType === InputTypes.BACK_BUTTON) {
    const id = model.getId();
    let css = {};
    if (themeStyle.variables.theme_color) {
      const cssRule = cssComposer.getRule(`#${id}`);
      if (cssRule && (cssRule.attributes.style['color'] === 'var(--theme_color)' || cssRule.attributes.style['color'] === prevThemeStyle.variables.theme_color)) {
        delete cssRule.attributes.style['color'];
        css['color'] = themeStyle.variables.theme_color;
      }
      if (cssRule && (cssRule.attributes.style['border-color'] === 'var(--theme_color)' || cssRule.attributes.style['border-color'] === prevThemeStyle.variables.theme_color
          || cssRule.attributes.style['border-top-color'] === 'var(--theme_color)' || cssRule.attributes.style['border-top-color'] === prevThemeStyle.variables.theme_color)) {
        css = {...css, ...updateCssRule(cssRule, RuleType.BorderColor, themeStyle.variables.theme_color)};
      }
      if (cssRule && cssRule.attributes.style) {
        css = {
          ...css,
          ...cssRule.attributes.style
        };
      }
      cssComposer.setRule(`#${id}`, css);
    }
  }
  else if (model.attributes.type === InputTypes.HEADER_LOGO) {
    const childModel = model.attributes.components.models;
    if (childModel) { // find header logo
      for ( let i = 0; i < childModel.length; i++) {
        if (childModel[i].attributes.type === InputTypes.IMAGE) {
          const id = childModel[i].getId();
          if ( childModel[i].attributes.attributes.id === Identifiers.DesktopLogo) {
            const cssRule = cssComposer.getRule(`#${id}`);
            let css = {};
            if (variables.desktop_logo_height) {
              delete cssRule.attributes.style['height'];
              css['height'] = variables.desktop_logo_height;
            }
            if (variables.desktop_logo_top_margin) {
              delete cssRule.attributes.style['margin-top'];
              css['margin-top'] = variables.desktop_logo_top_margin;
            }
            css = {
              ...css,
              ...cssRule.attributes.style
            };
            cssComposer.setRule(`#${id}`, css);
          } else if (childModel[i].attributes.attributes.id === Identifiers.Mobilelogo) {
            let css = {};
            const cssRule = cssComposer.getRule(`#${id}`, {
              atRuleType: 'media',
              atRuleParams: '(max-width: 575px)'
            });

            if (variables.mobile_logo_height) {
              delete cssRule.attributes.style['height'];
              css['height'] = variables.mobile_logo_height;
            }
            if (variables.mobile_logo_top_margin) {

              delete cssRule.attributes.style['margin-top'];
              css['margin-top'] = variables.mobile_logo_top_margin;
            }
            if (variables.mobile_logo_bottom_margin) {
              delete cssRule.attributes.style['margin-bottom'];
              css['margin-bottom'] = variables.mobile_logo_bottom_margin;
            }
            css = {
              ...css,
              ...cssRule.attributes.style
            };
            cssComposer.setRule(`#${id}`, css, {
              atRuleType: 'media',
              atRuleParams: '(max-width: 575px)',
            });

          }
        } else {
          buttonTheming(model, themeStyle, editor, prevThemeStyle);
        }
      }
    } else {
      buttonTheming(model, themeStyle, editor, prevThemeStyle);
    }
  }
  else if (model.attributes.type === InputTypes.SELECT_LANGUAGE ) {
    const childModel = model.attributes.components.models;
    for (let j = 0 ; j < childModel.length; j++) {
      if (childModel[j].attributes.tagName === InputTypes.BUTTON) {
        if (themeStyle.variables.button_hover_color) {
          const id = childModel[j].getId();
          cssComposer.setRule(`#${id}:hover`, { 'color': themeStyle.variables.button_hover_color });
        }
      }
    }
  }
  else if (model.attributes.type === InputTypes.MAIN_MENU) {
    const id = model.getId();
    cssComposer.setRule(`#${id}:hover`, { 'color': themeStyle.variables.button_hover_color });
  }
  else if (model.attributes.type === InputTypes.USER_DROPDOWN) {
    const childModel = model.attributes.components.models;
    for (let j = 0 ; j < childModel.length; j++) {
      const nestedChild = childModel[j].attributes.components.models;
      if (childModel[j].attributes.tagName === 'a') {
        if (themeStyle.variables.button_hover_color) {
          const id = childModel[j].getId();
          cssComposer.setRule(`#${id}:hover`, { 'color': themeStyle.variables.button_hover_color });
        }
      } else if (nestedChild) {
        for ( let i = 0; i < nestedChild.length; i++ ) {
          if (nestedChild[i].attributes.type === InputTypes.LOGOUT_BUTTON && themeStyle.variables.button_hover_color) {
            const id = nestedChild[i].getId();
            cssComposer.setRule(`#${id}:hover`, { 'color': themeStyle.variables.button_hover_color });
          }
        }

      }
    }
  }
  else if (model.attributes.type === InputTypes.LOGOUT_BUTTON) {
    const id = model.getId();
    cssComposer.setRule(`#${id}:hover`, { 'color': themeStyle.variables.button_hover_color });
  }
};

const buttonTheming = (model, themeStyle, editor, prevThemeStyle) => {
  const id = model.getId();
  const cssComposer =  editor.CssComposer;
  if (themeStyle) {
    if (themeStyle.variables.theme_color) {
      let css = {};
      const Id = model.getId();
      const cssRule = cssComposer.getRule(`#${id}`);
      if (cssRule) {
        const styles = cssRule.getStyle();
        if (styles['bg-color-type'] !== 'custom' &&
          (styles['background-color'] === 'var(--theme_color)' || styles['background-color'] === prevThemeStyle.variables.theme_color)) {
          delete styles['background-color'];
          css['background-color'] = themeStyle.variables.theme_color;
        }
        if (styles['border-color-type'] !== 'custom' && (styles['border-top-color'] === 'var(--theme_color)' || styles['border-top-color'] === prevThemeStyle.variables.theme_color
            || styles['border-color'] === 'var(--theme_color)' || styles['border-color'] === prevThemeStyle.variables.theme_color)) {
          delete styles['border-color'];
          delete styles['border'];
          delete styles['border-top-color'];
          delete styles['border-right-color'];
          delete styles['border-bottom-color'];
          delete styles['border-left-color'];
          css['border-color'] = themeStyle.variables.theme_color;
          css['border-top-color'] = themeStyle.variables.theme_color;
          css['border-right-color'] = themeStyle.variables.theme_color;
          css['border-bottom-color'] = themeStyle.variables.theme_color;
          css['border-left-color'] = themeStyle.variables.theme_color;
        }
        if (cssRule && styles ) {
          css = {
            ...css,
            ...styles
          };
        }
        cssComposer.setRule(`#${Id}`, css);
      }
    }
    if (themeStyle.variables.button_hover_color) {
      let css = {};
      const cssRule = cssComposer.getRule(`#${id}:hover`);
      if (cssRule) {
        const styles = cssRule.getStyle();
        if (
          styles['bg-color-type'] !== 'custom' &&
          (
            styles['background-color'] === 'var(--button_hover_color)' ||
            styles['background-color'] === prevThemeStyle.variables.button_hover_color
          ) ||
          (
            styles['background-color'] === 'var(--button_active_color)' ||
            styles['background-color'] === prevThemeStyle.variables.button_active_color
          ) ||
          (
            styles['background-color'] === 'var(--theme_color)' ||
            styles['background-color'] === prevThemeStyle.variables.theme_color
          )
        ) {
          delete styles['background-color'];
          css['background-color'] = themeStyle.variables.button_hover_color;
        }
        if (cssRule && styles['border-color-type'] !== 'custom' && (styles['border-color'] === 'var(--button_hover_color)' || styles['border-color'] === prevThemeStyle.variables.button_hover_color
            || styles['border-top-color'] === 'var(--button_hover_color)' || styles['border-top-color'] === prevThemeStyle.variables.button_hover_color)) {
          delete styles['border-color'];
          delete styles['border'];
          delete styles['border-top-color'];
          delete styles['border-right-color'];
          delete styles['border-bottom-color'];
          delete styles['border-left-color'];
          css['border-color'] = themeStyle.variables.button_hover_color;
          css['border-top-color'] = themeStyle.variables.button_hover_color;
          css['border-right-color'] = themeStyle.variables.button_hover_color;
          css['border-bottom-color'] = themeStyle.variables.button_hover_color;
          css['border-left-color'] = themeStyle.variables.button_hover_color;
        }
        if (cssRule && styles ) {
          css = {
            ...css,
            ...styles
          };
        }
      }
      cssComposer.setRule(`#${id}:hover`, css);
    }

    if (themeStyle.variables.button_active_color) {
      let css = {};
      const cssRule = cssComposer.getRule(`#${id}:active`);
      if (cssRule) {
        const styles = cssRule.getStyle();
        if (
          styles['bg-color-type'] !== 'custom' &&
          (
            styles['background-color'] === 'var(--button_hover_color)' ||
            styles['background-color'] === prevThemeStyle.variables.button_hover_color
          ) ||
          (
            styles['background-color'] === 'var(--button_active_color)' ||
            styles['background-color'] === prevThemeStyle.variables.button_active_color
          ) ||
          (
            styles['background-color'] === 'var(--theme_color)' ||
            styles['background-color'] === prevThemeStyle.variables.theme_color
          )
        ) {
          delete styles['background-color'];
          css['background-color'] = themeStyle.variables.button_active_color;
        }
        if (styles['border-color-type'] !== 'custom' && (styles['border-color'] === 'var(--theme_color)' || styles['border-color'] === prevThemeStyle.variables.theme_color
            || styles['border-top-color'] === 'var(--theme_color)' || styles['border-top-color'] === prevThemeStyle.variables.theme_color)) {
          delete styles['border-color'];
          delete styles['border'];
          delete styles['border-top-color'];
          delete styles['border-right-color'];
          delete styles['border-bottom-color'];
          delete styles['border-left-color'];
          css['border-color'] = themeStyle.variables.theme_color;
          css['border-top-color'] = themeStyle.variables.theme_color;
          css['border-right-color'] = themeStyle.variables.theme_color;
          css['border-bottom-color'] = themeStyle.variables.theme_color;
          css['border-left-color'] = themeStyle.variables.theme_color;
          css['border-color'] = themeStyle.variables.theme_color;
        }
        if (cssRule && styles ) {
          css = {
            ...css,
            ...styles
          };
        }
      }
      cssComposer.setRule(`#${id}:active`, css);
    }
  }

};

const resetButtonTheming = (model, themeStyle, editor) => {
  const id = model.getId();
  const cssComposer =  editor.CssComposer;

  // normal state rule
  const cssRule = cssComposer.getRule(`#${id}`);
  let css = {};
  if (cssRule) {
    css = applyPseudoStyles(cssRule.attributes.style, themeStyle, 'var(--theme_color)');
    cssComposer.setRule(`#${id}`, css);
  }

  // hover rule
  const cssRuleHover = cssComposer.getRule(`#${id}:hover`);
  if (cssRuleHover) {
    css = applyPseudoStyles(cssRuleHover.attributes.style, themeStyle, 'var(--button_hover_color)');
    cssComposer.setRule(`#${id}:hover`, css);
  }

  // active rule
  const cssRuleActive = cssComposer.getRule(`#${id}:active`);
  css = {};
  if (cssRuleActive) {
    css = applyPseudoStyles(cssRuleActive.attributes.style, themeStyle, 'var(--button_active_color)');
    cssComposer.setRule(`#${id}:active`, css);
  }
};

export const resetTheming = (model, themeStyle, editor, prevthemeStyle) => {

  const cssComposer = editor.CssComposer;

  if (themeStyle !== undefined) {
      if (model.attributes.type === InputTypes.BUTTON) {
        if (model.attributes.attributes.identifier !== InputTypes.IMAGE_LINK) {
          resetButtonTheming(model, themeStyle, editor);
        }
      } else if (model.attributes.type === InputTypes.BACK_BUTTON) {
        const id = model.getId();
        let css = {};
        if (themeStyle.variables.theme_color) {
          const cssRule = cssComposer.getRule(`#${id}`);
            if (cssRule && cssRule.attributes.style['color'] &&
              (cssRule.attributes.style['color'] === themeStyle.variables.theme_color)) {
                delete cssRule.attributes.style['color'];
                css['color'] = 'var(--theme_color)';
            }
            if (cssRule && cssRule.attributes.style['border-color'] &&
               (cssRule.attributes.style['border-color'] === themeStyle.variables.theme_color || cssRule.attributes.style['border-top-color'] === themeStyle.variables.theme_color)) {
                delete cssRule.attributes.style['border-color'];
                css['border-color'] = 'var(--theme_color)';
                css['border-top-color'] = 'var(--theme_color)';
                css['border-right-color'] = 'var(--theme_color)';
                css['border-bottom-color'] = 'var(--theme_color)';
                css['border-left-color'] = 'var(--theme_color)';
            }
            if (cssRule && cssRule.attributes.style ) {
              css = {
                ...css,
                ...cssRule.attributes.style
              };
            }
            cssComposer.setRule(`#${id}`, css);
        }
      } else if (model.attributes.type === InputTypes.HEADER_LOGO) {
        const childModel = model.attributes.components.models;
        if (childModel) { // find header logo
          for ( let i = 0; i < childModel.length; i++) {
            if (childModel[i].attributes.type === InputTypes.IMAGE) {
              const id = childModel[i].getId();
              if ( childModel[i].attributes.attributes.id === Identifiers.DesktopLogo) {
                let css = {};
                const cssRule = cssComposer.getRule(`#${id}`);
                if (cssRule && cssRule.attributes.style['height'] && themeStyle.variables.desktop_logo_height &&
                (cssRule.attributes.style['height'] === themeStyle.variables.desktop_logo_height)) {
                  delete cssRule.attributes.style['height'];
                  css['height'] = 'var(--desktop_logo_height)';
                }
                if (cssRule && cssRule.attributes.style['margin-top'] && themeStyle.variables.desktop_logo_top_margin &&
                (cssRule.attributes.style['margin-top'] === themeStyle.variables.desktop_logo_top_margin)) {
                  delete cssRule.attributes.style['margin-top'];
                  css['margin-top'] = 'var(--desktop_logo_top_margin)';
                }
                if (cssRule && cssRule.attributes.style ) {
                  css = {
                    ...css,
                    ...cssRule.attributes.style
                  };
                }
                cssComposer.setRule(`#${id}`, css);

              } else if (childModel[i].attributes.attributes.id === Identifiers.Mobilelogo) {
                let css = {};
                const cssRule = cssComposer.getRule(`#${id}`, {
                  atRuleType: 'media',
                  atRuleParams: '(max-width: 575px)'
                });
                if (cssRule && cssRule.attributes.style['height'] && themeStyle.variables.mobile_logo_height &&
                (cssRule.attributes.style['height'] === themeStyle.variables.mobile_logo_height)) {
                  delete cssRule.attributes.style['height'];
                  css ['height'] = 'var(--mobile_logo_height)';
                }
                if (cssRule && cssRule.attributes.style['margin-bottom'] && themeStyle.variables.mobile_logo_bottom_margin &&
                (cssRule.attributes.style['margin-bottom'] === themeStyle.variables.mobile_logo_bottom_margin)) {
                  delete cssRule.attributes.style['margin-bottom'];
                  css['margin-bottom'] = 'var(--mobile_logo_bottom_margin)';
                }
                if (cssRule && cssRule.attributes.style['margin-top'] && themeStyle.variables.mobile_logo_top_margin &&
                (cssRule.attributes.style['margin-top'] === themeStyle.variables.mobile_logo_top_margin)) {
                  delete cssRule.attributes.style['margin-top'];
                  css['margin-top'] = 'var(--mobile_logo_top_margin)';
                }
                if (cssRule && cssRule.attributes.style ) {
                  css = {
                    ...css,
                    ...cssRule.attributes.style
                  };
                }
                cssComposer.setRule(`#${id}`, css, {
                  atRuleType: 'media',
                  atRuleParams: '(max-width: 575px)'
                });
              }
            }
          }
        }
      } else if (model.attributes.type === InputTypes.SELECT_LANGUAGE) {
        const childModel = model.attributes.components.models;
        for (let j = 0 ; j < childModel.length; j++) {
          if (childModel[j].attributes.tagName === InputTypes.BUTTON) {
            const id = childModel[j].getId();
            const cssRuleHover = cssComposer.getRule(`#${id}:hover`);
            if (cssRuleHover && cssRuleHover.attributes.style['color'] && themeStyle.variables.button_hover_color &&
            (cssRuleHover.attributes.style['color'] === themeStyle.variables.button_hover_color )) {
              cssComposer.setRule(`#${id}:hover`, { 'color':  'var(--theme_color)' });
            }
          }
        }
      } else if (model.attributes.type === InputTypes.USER_DROPDOWN) {
        const childModel = model.attributes.components.models;
        for (let j = 0 ; j < childModel.length; j++) {
          const nestedChild = childModel[j].attributes.components.models;
          if (childModel[j].attributes.tagName === 'a') {
            const id = childModel[j].getId();
            const cssRuleHover = cssComposer.getRule(`#${id}:hover`);
            if (cssRuleHover && cssRuleHover.attributes.style['color'] && themeStyle.variables.button_hover_color &&
            (cssRuleHover.attributes.style['color'] === themeStyle.variables.button_hover_color )) {
              cssComposer.setRule(`#${id}:hover`, { 'color':  'var(--theme_color)' });
            }
          } else if (nestedChild) {
            for ( let i = 0; i < nestedChild.length; i++ ) {
              if (nestedChild[i].attributes.type === InputTypes.LOGOUT_BUTTON) {
                const id = nestedChild[i].getId();
                const cssRuleHover = cssComposer.getRule(`#${id}:hover`);
                if (cssRuleHover && cssRuleHover.attributes.style['color'] && themeStyle.variables.button_hover_color &&
                (cssRuleHover.attributes.style['color'] === themeStyle.variables.button_hover_color )) {
                  cssComposer.setRule(`#${id}:hover`, { 'color':  'var(--theme_color)' });
                }
              }
            }
          }
        }
      } else if (model.attributes.type === InputTypes.MAIN_MENU) {
        const id = model.getId();
        const cssRuleHover = cssComposer.getRule(`#${id}:hover`);
            if (cssRuleHover && cssRuleHover.attributes.style['color'] && themeStyle.variables.button_hover_color &&
             (cssRuleHover.attributes.style['color'] === themeStyle.variables.button_hover_color )) {
              cssComposer.setRule(`#${id}:hover`, { 'color':  'var(--theme_color)' });
            }
      } else if (model.attributes.type === InputTypes.LOGOUT_BUTTON) {
        const id = model.getId();
        const cssRuleHover = cssComposer.getRule(`#${id}:hover`);
        if (cssRuleHover && cssRuleHover.attributes.style['color'] && themeStyle.variables.button_hover_color &&
         (cssRuleHover.attributes.style['color'] === themeStyle.variables.button_hover_color )) {
          cssComposer.setRule(`#${id}:hover`, { 'color':  'var(--theme_color)' });
        }
      }
  }

};
