import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Form, FormError} from '../_forms';
import {CRM} from '../_models';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CRMService} from '../_services';
import {environment} from '../../environments/environment';
import {takeUntil} from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  selector: 'crm-chargeback-config',
  templateUrl: './crm-chargeback-config.component.html'
})
export class CrmChargebackConfigComponent extends Form implements OnInit, OnChanges {
  @Input('crm') crm: CRM;
  @Input('form') parentForm: FormGroup;
  chargebackDocsURL = ' https://solvpath.atlassian.net/wiki/external/Y2E4ZGNjNDI5ZjM4NDkzNDk3OGQ3MzBiNGEwZTlkNzY'
  refundReasonNames = ['Verifi', 'Ethoca', 'RDR', 'Others'];
  historicDataSyncRangeOptions = [
    {value: 'mtd', label: 'Month to Date'},
    {value: '1mtd', label: '1 Month to Date'},
    {value: '2mtd', label: '2 Months to Date'},
    {value: '3mtd', label: '3 Months to Date'},
  ]

  refundReasonRowId: number = 0

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected crmService: CRMService
  ) {
    super(alertService, router, location);
  }

  openChargebackDocs() {
    window.open(this.chargebackDocsURL, '_blank');
  }
  
  ngOnInit() {
    this.form = this.formBuilder.group({
      dispute_management: [false, null],
      historic_data_sync_range: [null, null],
      alert_reasons: this.formBuilder.array([], {validators: Validators.required})
    });
    this.updateForm();
  }

  ngOnChanges() {
    this.updateForm();
  }

  addRefundReasonControl(name: string = '', value: string = '') {
    let alert_reasons = <FormArray>this.form.get('alert_reasons')['controls'];
    this.refundReasonRowId += 1
    alert_reasons.push(this.formBuilder.group({
      name: [name, null],
      value: [value, null],
      rowId: [this.refundReasonRowId, null]
    }, {validators: this.shouldBeUnique(), updateOn: "change"}))
  }

  removeRefundReasonControl(index: number) {
    if (index > -1) {
      (this.form.get('alert_reasons') as FormArray).removeAt(index)
    }
  }

  isDisputeManagementEnabled() {
    return this.form.value && this.form.value['dispute_management']
  }

  private updateForm() {
    if (this.form && this.crm && this.crm.dispute_management) {
      this.form.patchValue({dispute_management: this.crm.dispute_management,
        historic_data_sync_range: this.crm.historic_data_sync_range})

      if (this.crm.alert_reasons) {
        Object.keys(this.crm.alert_reasons).forEach((name) => {
          let reasons: string[] = this.crm.alert_reasons[name]

          reasons.forEach(reason => {
            this.addRefundReasonControl(name, reason)
          })
        })
      }
    }
  }

  getFormData() {
    if (!this.form.valid) {
      return {}
    }

    let values = this.form.value
    let result: any = {}

    if (values['dispute_management']) {
      result = {...this.form.value}
    } else {
      Object.keys(this.form.value).forEach(key => {
        result[key] = null

        if (key == 'dispute_management') {
          result[key] = false
        }
      })
      return result
    }

    let refund_controls = this.form.get('alert_reasons')['controls']

    if (refund_controls.length > 0) {
      result['alert_reasons'] = {}
      for (let refund_control of refund_controls) {
        let value = refund_control.value

        if (value.name in result['alert_reasons']) {
          result['alert_reasons'][value.name].push(value.value)
        } else {
          result['alert_reasons'][value.name] = [value.value]
        }
      }
      result['alert_reasons'] = result['alert_reasons']
    }

    return result
  }

  get postbackURL() {
    if (environment.cbApiUrl && this.crm) {
      return `${environment.cbApiUrl}/crm/${this.crm.id}/chargeback/`
    }

    return null
  }

  get postbackAlertURL() {
    if (environment.cbApiUrl && this.crm) {
      return `${environment.cbApiUrl}/crm/${this.crm.id}/alerts/`
    }

    return null
  }

  get chargebackDocsUrl() {
    return environment.cbDocsUrl
  }

  shouldBeUnique(): ValidatorFn {
    return (group: FormGroup): {[key: string]: any} | null => {
      group.setErrors(null)
      let refund_controls = this.form.get('alert_reasons')['controls']
      this.parentForm.setErrors(null)

      if (!group.value['name'] || !group.value['value']) {
        setTimeout(() => {group.setErrors({string: 'Please select an alert type and then enter refund reason.'})}, 1)
        this.parentForm.setErrors({required: false})
        return
      }

      refund_controls.forEach((control: FormGroup) => {
        if (group.value['rowId'] !== control.value['rowId'] && control.value['value'].toLowerCase() === group.value['value'].toLowerCase()) {
          setTimeout(() => {group.setErrors({string: 'Refund reason must be unique'})}, 1)
          this.parentForm.setErrors({refundReasonMatch: false})
        }
      })
      return
    }
  }

  syncTransactionData() {
    this.crmService.syncTransactionData(this.crm.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {},
        error => {
          this.handleSubmitError(error);
        });
  }

  canSyncTransactionData() {
    return !this.crm.transaction_sync_status && this.crm.dispute_management && this.crm.historic_data_sync_range && this.crm.alert_reasons
      && Object.keys(this.crm.alert_reasons).length > 0
  }
}
