import {Injectable} from '@angular/core';
import {PerformanceReportService} from './performance-report.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PathPerformanceReportService extends PerformanceReportService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  get_excel(data) {
    return this.http.post("path_performance_report/", data)
  }

  downloadPerformanceReport(data, elem, fileType: string = 'excel') {
    this.get_excel(data).subscribe(_ => {
      elem.loading = false
    })
  }
}
