import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrudService } from "./crud.service";

export abstract class PaginationService extends CrudService {
  constructor(protected http: HttpClient, protected route: string) {
    super(http, route);
  }

  list(queryParams: any, id?: string | number): Observable<any> {
    let params = new HttpParams({fromObject: queryParams});
    const path = id ? this.route + '/' + id.toString() + '/' : this.route + '/';

    return this.http.get<any>(path, {params: params});
  }
}
