import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignDomainService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {
  CustomDomain,
  Campaign,
  SessionType,
  SessionTypeLabels,
  LifelinePhoneSetting,
  LifelinePhoneSettingOptions
} from "../_models";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-domain-form',
  templateUrl: './campaign-domain-form.component.html'
})
export class CampaignDomainFormComponent extends CrudSavePopupComponent implements OnInit {
  domain: CustomDomain;
  errors: string[] = [];
  @Output('verify') onVerify: EventEmitter<any> = new EventEmitter<any>();
  @Input('campaign') campaign: Campaign;
  justVerified: boolean = false;
  sessionTypes = [
    {value: SessionType.Support, label: SessionTypeLabels[SessionType.Support]},
    {value: SessionType.Marketplace, label: SessionTypeLabels[SessionType.Marketplace]},
    {value: SessionType.PaymentUpdate, label: SessionTypeLabels[SessionType.PaymentUpdate]},
  ];
  lifelinePhoneSettings = LifelinePhoneSettingOptions;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected domainService: CampaignDomainService,
              protected alertService: AlertService
  ) {
    super(router, location, route, domainService, alertService);
    this.objectName = 'Custom Domain';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      campaign: [null, Validators.required],
      domain: [null, Validators.required],
      type: [SessionType.Support, Validators.required],
      name: [null],
      show_phone_link: [LifelinePhoneSetting.Never, Validators.required],
    }));

    super.ngOnInit();
  }

  verify() {
    if (!this.loading && this.domain) {
      this.loading = true;
      this.domainService.verify(this.domain.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: CustomDomain) => {
            this.alertService.success('Successfully verified ' + this.domain.domain);
            this.onVerify.emit(data);
            this.justVerified = true;
            this.errors = [];
            this.loading = false;
          },
          error => {
            this.errors = error.error;
            this.loading = false;
          });
    }
  }

  protected onInputDataChanged() {
    this.domain = this.inputData;
    this.isNew = !this.domain || !this.domain.id;
    this.errors = [];
  }

  protected onSaveComplete(data) {
    this.loading = false;
    this.domain = data;
    this.errors = [];
    this.onSave.emit(data);
  }

  close() {
    this.errors = [];
    this.onCancel.emit();
  }

}
