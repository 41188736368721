import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from './pagination.service';

@Injectable()
export class ExchangeableProductsSetService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'exchangeable_products_set');
  }

  add_campaign_products(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/add_campaign_products/', data)
  }

  remove_campaign_product(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/remove_campaign_product/', data)
  }

  preview(id: string| number){
    return this.http.get(this.route + '/' + id + '/preview')
  }
}
