import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelService, SalesPhraseService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Funnel, Pager, Product, SalesPhrase} from "../_models";
import {config} from '../../config/config';
import {takeUntil} from "rxjs/operators";
import {Form} from '../_forms';
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-path-fields.component.html',
  selector: 'product-path-fields',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class ProductPathFieldsComponent extends Form implements OnInit, OnChanges {
  selectedTroubleshooterId = null;
  troubleshooters: Funnel[] = [];
  salesPhraseOptions: any[] = [];

  @Input('product') product: Product;

  constructor(
    protected router: Router,
    protected location: Location,
    private formBuilder: FormBuilder,
    private funnelService: FunnelService,
    private modalService: NgxSmartModalService,
    private salesPhraseService: SalesPhraseService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.updateForm();
    super.ngOnInit();

    this.funnelService.list({resourcetype: 'Troubleshooter', page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.troubleshooters = data.results;
        },
        error => {
          this.handleError(error);
        }
      );

    this.salesPhraseService.list()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (salesPhrases: SalesPhrase[]) => {
          let phrases = [];

          salesPhrases.forEach((salesPhrase: SalesPhrase) => {
            phrases.push({value: salesPhrase.id, text: salesPhrase.text});
          });

          phrases.sort((a, b): number => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          });

          this.salesPhraseOptions = phrases;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm() {
    this.setForm(this.formBuilder.group({
      sales_phrase: [null],
      min_price: [0, [Validators.required, Validators.min(0)]]
    }));

    if (this.product) {
      this.form.patchValue(this.product);

      if (this.product.troubleshooters && this.product.troubleshooters.length) {
        this.selectedTroubleshooterId = this.product.troubleshooters[0];
      }
    }
  }

  onCreateSalesPhraseStart() {
    this.modalService.getModal('salesPhraseDialog').open();
  }

  onCreateSalesPhraseDone(salesPhrase: SalesPhrase) {
    let phrases = this.salesPhraseOptions;

    phrases.push({value: salesPhrase.id, text: salesPhrase.text});
    phrases.sort((a, b): number => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    this.salesPhraseOptions = phrases;
    this.form.patchValue({sales_phrase: salesPhrase.id});
    this.modalService.getModal('salesPhraseDialog').close();

    if (this.product) {
      this.product.sales_phrase = salesPhrase.id;
    }
  }

  getFormData() {
    let data: {} = this.form.value;

    data['troubleshooters'] = [];

    // can only select 1 troubleshooter for a product for now
    if (this.selectedTroubleshooterId && this.selectedTroubleshooterId !== 'null') {
      data['troubleshooters'] = [this.selectedTroubleshooterId];
    }

    return data;
  }

}
