import {Component, OnInit, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, WidgetService} from '../_services';
import {Funnel, Pager, Widget, WidgetType} from '../_models';
import {CrudPagedListComponent} from '../_directives';
import {forkJoin, Observable} from 'rxjs';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {takeUntil} from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  selector: 'widgets',
  templateUrl: './widget-table.component.html'
})
export class WidgetTableComponent extends CrudPagedListComponent implements OnInit, OnChanges {
  widgets$: Observable<Widget[]> = this.data$;
  selectedWidgets: Widget[] = [];
  @Input('campaignIds') campaignIds: string[] | number[] | null = null;
  @Input('productIds') productIds: string[] | number[] | null = null;
  @Input('campaignProductIds') campaignProductIds: string[] | number[] | null = null;
  @Input('allowUpdate') allowUpdate: boolean = true;
  @Output('save') onSave: EventEmitter<any> = new EventEmitter<any>();

  private resourcetype = null;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected widgetService: WidgetService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService) {
    super(router, location, route, widgetService, alertService);
    this.objectName = 'content';
    this.title = 'Content';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(): void {
    this.initializeFilter();
  }

  protected initializeFilter() {
    let filter = {};

    if (this.campaignProductIds && this.campaignProductIds.length > 0) {
      filter = {campaign_product: this.campaignProductIds};
      this.resourcetype = WidgetType.CampaignProduct;
    } else if (this.productIds && this.productIds.length > 0) {
      filter = {product: this.productIds};
      this.resourcetype = WidgetType.Product
    } else if (this.campaignIds && this.campaignIds.length > 0) {
      filter = {campaign: this.campaignIds};
      this.resourcetype = WidgetType.Campaign;
    } else {
      filter = {
        'campaign__isnull': true,
        'product__isnull': true,
        'campaign_product__isnull': true
      };
      filter['block_id__isnull'] = true;
      this.resourcetype = WidgetType.Account;
    }

    filter['enhanced_mode'] = false;
    this.setFilter(filter);
  }

  create() {
    let idsList: string[] | number[]  = [null];  // default for account level widgets
    let field = null
    if (this.productIds && this.productIds.length > 0) {
      idsList = this.productIds
      field = 'product'
    } else if (this.campaignProductIds && this.campaignProductIds.length > 0) {
      idsList = this.campaignProductIds
      field = 'campaign_product'
    } else if (this.campaignIds && this.campaignIds.length > 0) {
      idsList = this.campaignIds
      field = 'campaign'
    }

    let selectedWidgets: Widget[] = []
    idsList.forEach(id => {
      let widget = {
        resourcetype: this.resourcetype,
        name: null,
        content: '',
        campaign_product: null,
        product: null,
        campaign: null,
        style: '',
        enhanced_mode: false,
        enhanced_content: ''
      } as Widget
      if (field) {
        widget[field] = id
      }
      selectedWidgets.push(widget)
    })
    this.selectedWidgets = selectedWidgets
    this.openEditDialog();
  }

  edit(widget: Widget) {
    this.selectedWidgets = [widget];
    this.openEditDialog();
  }

  protected openEditDialog() {
    setTimeout(() => this.modalService.getModal('editWidgetDialog').open(), 0);
  }

  onCloseEditDialog() {
    this.selectedWidgets = [];
  }

  protected queryData() {
    if (!this.allowUpdate) {
      return
    }
    super.queryData();
  }

  onSaveWidget(widget: Widget) {
    this.modalService.getModal('editWidgetDialog').close();
    this.queryData();
    this.onSave.emit(widget);

    if (!this.allowUpdate && Array.isArray(widget) && widget.length > 0) {
      // only show 1 widget at the time of bulk create
      this._data$.next([widget[0]])
    }
  }

  preview(widget: Widget) {
    this.selectedWidgets = [widget];
    setTimeout(() => this.modalService.getModal('widgetPreviewDialog').open(), 0);
  }

  onPreview(previewUrl: string) {
    this.modalService.getModal('widgetPreviewDialog').close();
    window.open(previewUrl, '_blank');
  }

}
