import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignService} from '../_services';
import {CrudSaveComponent} from '../_directives';
import {FormBuilder} from '@angular/forms';
import {Pager, Campaign} from "../_models";
import {config} from '../../config/config';
import {EMPTY} from "rxjs";
import {mergeMap, takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-campaigns.component.html'
})
export class CampaignCampaignsComponent extends CrudSaveComponent implements OnInit {
  selectedFilteredCampaigns: Campaign[] = [];
  filteredCampaigns: Campaign[] = [];
  allCampaigns = true;
  isNewCampaign = false;
  campaign: Campaign;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = 'Select Filtered Brands';
    this.isPartial = true;
    this.objectName = 'Brand';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      filtered_campaigns: [[]]
    });

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        this.form.patchValue(campaign);
        this.campaign = campaign;
        this.campaignService.showMenu(campaign, 'campaigns', this.isNewCampaign, campaign.is_hybrid);

        return this.campaignService.list({is_hybrid: false, page: 1, page_size: config.maxPageSize});
      }

      return EMPTY;
    })).subscribe(
      (data: Pager) => {
        let selectedCampaigns = {};

        //build a map of the selected crm campaign ids for faster lookup
        if (this.campaign.filtered_campaigns) {
          for (let campaign_id of this.campaign.filtered_campaigns) {
            selectedCampaigns[campaign_id] = true;
          }
        }

        //fill in the list of crm campaigns for each crm
        data.results.forEach((campaign: Campaign) => {
          if (campaign.id in selectedCampaigns) {
            this.selectedFilteredCampaigns.push(campaign);
          }

          this.filteredCampaigns.push(campaign);
        });
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected getFormData() {
    let campaignIds = [];

    if (!this.allCampaigns) {
      this.selectedFilteredCampaigns.forEach((campaign: Campaign) => {
        campaignIds.push(campaign.id);
      });
    }

    return Object.assign({}, this.form.value, {filtered_campaigns: campaignIds});
  }

  protected onSaveComplete(data) {
    if (this.isNewCampaign) {
      this.campaignService.getNextMenuRoute().pipe(takeUntil(this.destroy$)).subscribe((route: string) => {
        this.navigate(['/campaign', route, this.id, {isNewCampaign: true}], {replaceUrl: true});
      });
    }
    else {
      this.goBack();
    }
  }

}
