import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {CampaignService, CRMCampaignService, PerformanceReportService, StorageService, UserService} from "../_services";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import * as momentTz from "moment-timezone";
import * as moment from "moment";
import {normalizeDateForQuery, normalizeMomentForQuery} from "../_helpers";
import {takeUntil} from "rxjs/operators";
import {Campaign, CRMCampaign, CRMTypeId, Pager, User} from "../_models";
import {config} from "../../config/config";
import {forkJoin} from "rxjs";

@Component({
  moduleId: module.id.toString(),
  selector: "performance-report",
  templateUrl: "./templates/performance-report.component.html",
  styleUrls: ['./styles/reports.component.css'],
})
export class PerformanceReportComponent implements OnInit{
  form: FormGroup;
  user: User = null
  public ranges = {
    'Today': this.setDateRangeToToday(),
    'Yesterday': this.setDateRangeToYesterday(),
    'Past 7 Days': this.setDateRangeToLastNDays(7),
    'Month to Date': this.setDateRangeToCurrentMonth(),
    'Last Month': this.setDateRangeToLastMonth(),
    'Last 30 days': this.setDateRangeToLastNDays(31),
    'Last 3 Months to Date': this.setDateRangeToNPastMonthsToDate(3),
    'Last 6 Months to Date': this.setDateRangeToNPastMonthsToDate(6),
    'Last 12 Months to Date': this.setDateRangeToNPastMonthsToDate(12),
    'Year to Date': this.setDateRangeToCurrentYear(),
    'Last Year': this.setDateRangeToLastYear()
  };
  public loading: boolean = false;
  public maxDate = moment();
  public alwaysShowCalendars = true;
  public campaignFormData = [];
  public crmCampaignFormData = [];
  public defaultFilterSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  public showCrmCampaignFilter: boolean = true
  constructor(
    protected formBuilder: FormBuilder,
    protected reportService: PerformanceReportService,
    protected campaignService: CampaignService,
    protected storageService: StorageService,
    protected crmCampaignService: CRMCampaignService,
    protected userService: UserService) {
  }

  ngOnInit() {
    let currentDate = new Date();
    let normalizedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
      0, 0, 0);
    let timezone = this.storageService.get('timezone');
    if (!timezone) {
      timezone = momentTz.tz.guess();
    }
    this.form = this.formBuilder.group({
      date_range: new FormControl({startDate: moment(), endDate: moment()}, Validators.required),
      timezone: [timezone, Validators.required],
      campaign_id: [null, null],
      crm_campaign_id: [null, null]
    });
    this.loading = true
    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user
        let campaignFormData = [];
        let crmCampaignFormData = [];
        let queryParams = {account_id: user.account.id, page: 1, page_size: config.maxPageSize};
        forkJoin(this.campaignService.list(queryParams),
          this.crmCampaignService.listAll({...queryParams, 'crm__type!': CRMTypeId.Test}))
          .subscribe(
            (data: [Pager, Pager]) => {
              data[0].results.forEach((item: Campaign) => {
                campaignFormData.push({id: item.id, text: item.display_name});
              })
              data[1].results.forEach((item: CRMCampaign) => {
                crmCampaignFormData.push({id: item.id, text: item.name});
              })
              this.campaignFormData = campaignFormData;
              this.crmCampaignFormData = crmCampaignFormData;
              this.loading = false;
            }
          );
      })
  }

  setDateRangeToToday() {
    return [new Date(), new Date()];
  }

  setDateRangeToYesterday() {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    return [date, date]
  }

  setDateRangeToLastNDays(numDays: number) {
    let date = new Date();

    date.setDate((date.getDate() + 1) - numDays);

    return [date, new Date()];
  }

  setDateRangeToLastMonth() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1st of last month
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);

    //end date = last day of previous month
    endDate.setDate(0);

    return [startDate, endDate];
  }

  setDateRangeToCurrentMonth() {
    return this.setDateRangeToNPastMonthsToDate(0);
  }

  setDateRangeToNPastMonthsToDate(numPastMonths: number) {
    let date = new Date();

    date.setDate(1);
    date.setMonth(date.getMonth() - numPastMonths);

    return [date, new Date()];
  }

  setDateRangeToCurrentYear() {
    let date = new Date();

    date.setDate(1);
    date.setMonth(0);

    return [date, new Date()];
  }

  setDateRangeToLastYear() {
    let startDate = new Date();
    let endDate = new Date();

    //start date = 1/1 of last year
    startDate.setMonth(0);
    startDate.setDate(1);
    startDate.setFullYear(startDate.getFullYear() - 1);

    //end date = 12/31 of last year
    endDate.setMonth(11);
    endDate.setDate(31);
    endDate.setFullYear(endDate.getFullYear() - 1);

    return [startDate, endDate];
  }

  downloadFile() {
    let data = {}
    data['start_date_formatted'] = this.form.value.date_range.startDate.format('MMM Do, YYYY')
    data['end_date_formatted'] = this.form.value.date_range.endDate.format('MMM Do, YYYY')
    data['start_date'] = normalizeMomentForQuery(this.form.value.date_range.startDate, this.form.value.timezone)
    data['end_date'] = normalizeMomentForQuery(this.form.value.date_range.endDate, this.form.value.timezone)
    let extraFilterFields = ['campaign_id', 'crm_campaign_id']
    extraFilterFields.forEach(field => {
      let form_value = this.form.value[field];
      if (form_value) {
        let values = [];
        form_value.forEach(data => {
          values.push(data.id);
        })
        if (values.length > 0) {
          data[field] = values
        }
      }
    })
    this.loading = true
    this.reportService.downloadPerformanceReport(data, this)
  }
}
