import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, ProductInfoSectionService } from '../_services';
import { CrudSaveComponent } from '../_directives';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './product-info-section-edit.component.html'
})
export class ProductInfoSectionNewComponent extends CrudSaveComponent implements OnInit {
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected sectionService: ProductInfoSectionService,
              protected alertService: AlertService) {
    super(router, location, route, sectionService, alertService);
    this.isNew = true;
    this.objectName = 'Product Info Section';
    this.title = "Product info"
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required]
    });

    super.ngOnInit();
  }

}
