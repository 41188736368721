import { Component, HostListener, Input, OnInit } from "@angular/core";

@Component({
  selector: "line-graph",
  templateUrl: "./line-graph.component.html",
  styleUrls: ["./line-graph.component.scss"],
})
export class LineGraphComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.updateDynamicView();
  }
  @Input() totalSessions: string = "0";
  @Input() chartData = [];
  customYAxisTicks: number[] = [];
  dynamicView: [number, number] = [600, 370];

  // options
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  timeline: boolean = true;

  colorScheme = {
    domain: ["#22b8d7"],
  };

  ngOnInit(): void {
    this.updateDynamicView();
    this.customYAxisTicks = this.calculateTicks();
  }

  updateDynamicView() {
    const parentContainer = document.getElementById("line-graph");

    if (parentContainer) {
      const width = parentContainer.clientWidth;
      const height = 300;
      this.dynamicView = [width, height];
    }
  }

  calculateTicks(): number[] {
    const maxValue = Math.max(...this.chartData[0].series.map((d) => d.value));
    let roundedMaxValue = 0;

    if (maxValue < 10) {
      return Array.from({ length: maxValue + 1 }, (_, index) => index);
    } else if (maxValue < 15) {
      roundedMaxValue = 15;
    } else if (maxValue < 20) {
      roundedMaxValue = 20;
    } else if (maxValue < 50) {
      roundedMaxValue = Math.ceil(maxValue / 10) * 10;
    } else if (maxValue < 100) {
      roundedMaxValue = Math.ceil(maxValue / 100) * 100;
    } else if (maxValue < 200) {
      roundedMaxValue = Math.ceil(maxValue / 50) * 50;
    } else if (maxValue < 1000) {
      roundedMaxValue = Math.ceil(maxValue / 100) * 100;
    } else {
      roundedMaxValue = Math.ceil(maxValue / 500) * 500;
    }

    if (roundedMaxValue <= 15) return [0, 3, 6, 9, 12, 15];
    else if (roundedMaxValue <= 20) return [0, 4, 8, 12, 16, 20];
    else {
      const intervalSize = Math.ceil(roundedMaxValue / 5 / 5) * 5;
      const numberOfIntervals = Math.ceil(roundedMaxValue / intervalSize); // Maximum of 6 intervals, or less if roundedMaxValue is smaller
      const ticks = [];

      for (let i = 0; i < numberOfIntervals; i++) {
        ticks.push(i * intervalSize);
      }

      ticks.push(roundedMaxValue); // Add the roundedMaxValue as the last tick
      return ticks;
    }
  }
}
