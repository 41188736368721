import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, AutoResponderService, CampaignService } from '../_services';
import { CrudPagedListComponent } from '../_directives';
import { AutoResponder, AutoResponderType } from '../_models';
import { Observable } from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './autoresponder.component.html',
  styleUrls: ['./autoresponder.component.css']
})
export class AutoResponderComponent extends CrudPagedListComponent implements OnInit {
  autoresponders$: Observable<AutoResponder[]> = this.data$;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected responderService: AutoResponderService,
    protected alertService: AlertService,
    private campaignService: CampaignService
  ) {
    super(router, location, route, responderService, alertService);
    this.objectName = 'autoresponder';
    this.title = 'Autoresponders';
  }

  ngOnInit() {
    super.ngOnInit();

    this.campaignService.hideMenu();
  }

  getTypeString(autoresponder: AutoResponder) {
    if (autoresponder.type == AutoResponderType.Email) {
        return 'Email';
    }
    else if (autoresponder.type == AutoResponderType.SMS) {
      return 'SMS';
    }

    return '';
  }

}
