import {Component, OnInit} from '@angular/core';
import {CrudSaveComponent} from "../_directives";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, FaqTopicService, FaqService} from "../_services";
import {FormBuilder, Validators} from "@angular/forms";
import {FaqTopic, Faq, IconFile, SelectOption} from "../_models";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './faq-topic-edit.component.html'
})
export class FaqTopicNewComponent extends CrudSaveComponent implements OnInit {
  topic: FaqTopic;
  parentOptions: SelectOption[] = [];
  faqs: Faq[] = [];
  subtopics: FaqTopic[] = [];
  icon: IconFile;
  selectIcon = false;

  protected originalTitle;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected topicService: FaqTopicService,
    protected faqService: FaqService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, topicService, alertService);
    this.title = 'Create FAQ Topic';
    this.objectName = 'FAQ Topic';
    this.isNew = true;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: [null, Validators.required],
      include_in_copy: [false],
      parent: [null]
    });

    super.ngOnInit();
    this.originalTitle = this.title;
  }

  protected onRouteParams(params: {}) {
    let data = {};
    this.loading = true

    //get the list of faq topics
    this.topicService.getSelectOptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        options => {
          this.loading = false
          this.parentOptions = options;
        },
        error => {
          this.loading = false
          this.handleError(error);
        }
      );

    if ('parent' in params) {
      data['parent'] = params['parent'];
    }

    this.form.patchValue(data);
  }

  deleteSubtopic(id) {
    if (this.topic && window.confirm('Are you sure you want to delete this topic and all its children?')) {
      this.loading = true;

      this.topicService.delete(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          result => {
            this.alertService.success('Deleted topic.');
            this.loading = false;
            this.subtopics = this.subtopics.filter(data => data.id != id);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  deleteFaq(faq: Faq) {
    if (this.topic) {
      if (faq.topics.length > 1) {
        this.faqs = this.faqs.filter(data => data.id != faq.id);
      } else {
        if (window.confirm('Are you sure you want to delete this FAQ?')) {
          this.loading = true;

          this.faqService.delete(faq.id)
            .pipe(takeUntil(this._destroy$))
            .subscribe(
              result => {
                this.alertService.success('Deleted FAQ.');
                this.loading = false;
                this.faqs = this.faqs.filter(data => data.id != faq.id);
              },
              error => {
                this.handleSubmitError(error);
                this.loading = false;
              });
        }
      }
    }
  }

  copyTopic(topic: FaqTopic) {
    this.topicService.copy(topic.id);
  }

  canPasteTopic() {
    return this.topicService.canPaste();
  }

  pasteTopic() {
    if (this.topic) {
      this.loading = true
      this.topicService.paste(this.topic.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (topic: FaqTopic) => {
            this.loading = false
            this.subtopics.push(topic);
          },
          error => {
            this.loading = false
            this.handleError(error);
          }
        );
    }
  }

  copyFAQ(faq: Faq) {
    this.faqService.copy(faq);
  }

  canPasteFAQ() {
    return this.faqService.canPaste();
  }

  pasteFAQ() {
    if (this.topic) {
      this.loading = true
      this.faqService.paste(this.topic.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (faq: Faq) => {
            this.loading = false
            this.faqs.push(faq);
          },
          error => {
            this.loading = false
            this.handleError(error);
          }
        );
    }
  }

  removeIcon() {
    this.icon = null;
  }

  onIconSelectStart() {
    this.selectIcon = true;
    this.title = 'Select Icon';
  }

  onIconSelectDone(icon: IconFile) {
    if (this.selectIcon) {
      this.icon = icon;
      this.title = this.originalTitle;
      this.selectIcon = false;
    }
  }

  onIconSelectCancel() {
    this.title = this.originalTitle;
    this.selectIcon = false;
  }

  protected getFormData() {
    let data = {};

    if (this.icon) {
      data['icon'] = this.icon.id;
    }

    return Object.assign({}, this.form.value, data);
  }
}
