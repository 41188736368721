export const CRMChoices = [
  "Shopify",
  "WooCommerce",
  "Big Commerce",
  "Konnektive",
  "Sticky.io",
  "Magento",
  "Opencart",
  "WIX",
  "SquareSpace",
  "Salesforce Commerce",
  "Click Funnels",
  "Groove Funnels",
  "Your own-Proprietary",
  "Other",
]

export const SupportTickets = [
  "100 - 999",
  "1,000 - 1,999",
  "2,000 - 4,999",
  "5,000 - 9,999",
  "10,000 - 19,999",
  "20,000+",
]
