import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PaymentSourceService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {PaymentSource} from "../_models";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  selector: 'bank-account-verify-form',
  templateUrl: './bank-account-verify-form.component.html'
})
export class BankAccountVerifyFormComponent extends CrudSavePopupComponent implements OnInit {
  paymentSource: PaymentSource;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected paymentService: PaymentSourceService,
              protected alertService: AlertService,
  ) {
    super(router, location, route, paymentService, alertService);
    this.objectName = 'Bank Account';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      amount1: [null, Validators.required],
      amount2: [null, Validators.required]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.paymentSource = this.inputData;
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      this.paymentService.verifyDeposits(this.id, this.getFormData())
        .subscribe(
          data => {
            if (this.showSuccessMessage) {
              this.alertService.success('Verified ' + this.objectName.toLowerCase() + '.', true);
            }

            this.canDeactivateService.setFormDirty(false);
            this.onSaveComplete(data);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
            this.cancel();
          });
    }
  }

}
