import {
  StepStaticDataImageType,
  StepMediaCategory,
  StepFixedMediaCategory,
  StepEmulatorDevices,
  StepMediaSubCategory,
  CustomStepCategoryEnum,
  FunnelInputTypeEnum,
  OfferIntents,
  OfferTypes,
  ExecutionTypeEnum,
  ActionType,
  DiscountTypeEnum,
  QuantityTypeEnum,
  StepMedia,
  StepResponseMedia,
  Action,
  RefundType,
} from '../../_models';
import { DomSanitizer } from '@angular/platform-browser';
import { getEmbeddedVideoUrl, StepCategoryOfferIntentsMapping } from './steps-helper';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { StepEmulatorService } from '../../_services';

export function initializeForm(
  formBuilder: FormBuilder,
  stepType: CustomStepCategoryEnum,
  offerType: OfferTypes,
  editTemplate: boolean
): FormGroup {
  const form = formBuilder.group({
    id: null,
    subtitle: null,
    style: null,
    classes: [[]],
    is_popup: false,
    show_step_link: false,
    contact_type: null,
    enhanced_content: null,
    enhanced_mode: false,
    fail_if_any_input_invalid: false,
    invalid_message: null,
    hide_status: false,
    action_holdoffs: null,
    allow_undo: offerType !== OfferTypes.Downsell && offerType !== OfferTypes.Upsell,
    mobile_icon_button_type: null,
    use_bootstrap_spacing: true,
    enhanced_content_components: null,
    icon: null,
    help: null,
    enabled: null,
    enter_product_funnel: false,
    enter_troubleshooter: false,
    error_lifeline: null,
    exit_lifeline: null,
    faq_topic: null,
    is_first_step: false,
    is_template: false,
    is_global: false,
    item_statuses: null,
    lifeline: null,
    lifeline_enabled: true,
    slug: null,
    sms_prompt: null,
    voice_file: null,
    voice_prompt: null,
    num_digits: null,
    offer_intent: OfferIntents.Legacy,
    phone_loop_count: 1,
    previous_inputs: [],
    group: null,
    forward_to_call_center: false,
    hide_if_invalid: true,
    campaign_products: [[]],
    product_funnels: [[]],
    is_alt_child_exit: false,
    matched_step_key: null,
    autopick_next_step: null,
    requires_active_item: true,
    requires_item: true,
    requires_customer: true,
    type: FunnelInputTypeEnum.Choice,
    category: [stepType],
    banner: [null],
    label: [null, Validators.required],
    name: [null, Validators.required],
    content: [null, Validators.required],
    button_styles: null,
  });

  const categoryToIntentMapping = {
    [CustomStepCategoryEnum.CancelOrderSurvey]: OfferIntents.CancelOrder,
    [CustomStepCategoryEnum.CancelSubSurvey]: OfferIntents.CancelSubscription,
    [CustomStepCategoryEnum.CancelTrialSurvey]: OfferIntents.CancelTrial,
    [CustomStepCategoryEnum.ReturnSurvey]: OfferIntents.ReturnOrder,
  };

  if (editTemplate) {
    if (Object.keys(categoryToIntentMapping).includes(stepType.toString())) {
      form.patchValue({
        offer_intent: categoryToIntentMapping[stepType],
      });
    } else {
      form.patchValue({
        offer_intent: OfferIntents.Other,
      });
    }
  } else {
    form.patchValue({
      offer_intent: StepCategoryOfferIntentsMapping[stepType],
    });
  }

  switch (stepType) {
    case CustomStepCategoryEnum.Survey:
    case CustomStepCategoryEnum.ReturnSurvey:
    case CustomStepCategoryEnum.CancelSubSurvey:
    case CustomStepCategoryEnum.CancelOrderSurvey:
    case CustomStepCategoryEnum.CancelTrialSurvey:
      form.patchValue({
        type: FunnelInputTypeEnum.Radio,
      });

      form.setControl('inputs', formBuilder.array([], Validators.required));

      form.setControl(
        'button_styles',
        formBuilder.group({
          confirm: formBuilder.group({
            label: [null, Validators.required],
            style: [null],
            control_type: [null],
            style_type: [null],
          }),
          back: formBuilder.group({
            label: [null, Validators.required],
            style: [null],
            control_type: [null],
            style_type: [null],
          }),
        })
      );
      break;

    case CustomStepCategoryEnum.AdjustShippingFrequency:
    case CustomStepCategoryEnum.PauseSurvey:
      form.patchValue({
        type: FunnelInputTypeEnum.Radio,
      });

      form.setControl('inputs', formBuilder.array([], Validators.required));
      form.setControl('response_data', getResponseData(formBuilder));
      form.setControl(
        'button_styles',
        formBuilder.group({
          confirm: formBuilder.group({
            label: [null, Validators.required],
            style: [null],
            control_type: [null],
            style_type: [null],
          }),
          back: formBuilder.group({
            label: [null, Validators.required],
            style: [null],
            control_type: [null],
            style_type: [null],
          }),
        })
      );
      break;
    default:
      form.setControl('inputs', getDownsellInputs(formBuilder, stepType));
      form.setControl('response_data', getResponseData(formBuilder));
      break;
  }

  if (!editTemplate) {
    form.addControl('funnel', formBuilder.control(null));
  }

  return form;
}

export function getSurveyInputs(formBuilder: FormBuilder, offerType: OfferTypes): FormGroup {
  const input = formBuilder.group({
    id: null,
    label: [null, Validators.required],
    help: null,
    next_step: null,
    type: FunnelInputTypeEnum.Radio,
    values: [[]],
    actions: offerType === OfferTypes.Survey ? getSurveyActions(formBuilder) : getDownsellSurveyActions(formBuilder),
    icon: null,
    hide_if_invalid: true,
    product_funnels: [[]],
    classes: [[]],
    is_alt_child_exit: false,
    matched_step_key: null,
    autopick_next_step: null,
    requires_active_item: true,
    requires_item: true,
    requires_customer: true,
    has_response_step: true,
  });

  if (offerType === OfferTypes.Survey) {
    input.addControl('reason_category', new FormControl(null, Validators.required));
  }

  return input;
}

function getSurveyActions(formBuilder: FormBuilder): FormArray {
  return formBuilder.array([
    formBuilder.group({
      id: null,
      execution_type: ExecutionTypeEnum.ValidationOnly,
      resourcetype: StepCategoryOfferIntentsMapping[CustomStepCategoryEnum.Survey],
      cancel_subscription: false,
      reason: [null, Validators.required],
    }),
  ]);
}

function getDownsellSurveyActions(formBuilder: FormBuilder): FormArray {
  return formBuilder.array([
    formBuilder.group({
      id: null,
      execution_type: ExecutionTypeEnum.Immediate,
      resourcetype: ActionType.BillingCycle,
      billing_interval_days: null,
      check_last_discount: true,
      pause: false,
      final_billing_cycle_offset: 0,
      bill_now: false,
      next_bill_date_delay: [null, [Validators.required, Validators.min(1)]],
      max_bill_date_delay: null,
    }),
    formBuilder.group({
      id: null,
      resourcetype: ActionType.CancelDelayed,
    }),
  ]);
}

function getDownsellInputs(formBuilder: FormBuilder, stepType: CustomStepCategoryEnum): FormArray {
  return formBuilder.array([
    formBuilder.group({
      id: null,
      label: [null, Validators.required],
      help: null,
      next_step: null,
      step: null,
      type: FunnelInputTypeEnum.Choice,
      values: [[]],
      actions: getDownsellInputActions(formBuilder, stepType),
      icon: null,
      hide_if_invalid: true,
      product_funnels: [[]],
      classes: [[]],
      is_alt_child_exit: true,
      matched_step_key: null,
      autopick_next_step: null,
      has_response_step: true,
      requires_active_item: true,
      requires_item: true,
      requires_customer: true,
      builder_id: null,
      enter_product_funnel: false,
      enter_troubleshooter: false,
      item_statuses: null,
      button_styles: formBuilder.group({
        style: null,
        control_type: null,
        style_type: null,
      }),
    }),
    formBuilder.group({
      id: null,
      label: [null, Validators.required],
      help: null,
      next_step: null,
      step: null,
      type: FunnelInputTypeEnum.Choice,
      values: formBuilder.array([]),
      actions: formBuilder.array([]),
      icon: null,
      hide_if_invalid: true,
      product_funnels: [[]],
      classes: [[]],
      is_alt_child_exit: true,
      matched_step_key: null,
      autopick_next_step: null,
      requires_active_item: true,
      requires_item: true,
      requires_customer: true,
      has_response_step: false,
      builder_id: null,
      enter_product_funnel: false,
      enter_troubleshooter: false,
      item_statuses: null,
      button_styles: formBuilder.group({
        style: null,
        control_type: null,
        style_type: null,
      }),
    }),
  ]);
}

function getDownsellInputActions(formBuilder: FormBuilder, stepType: CustomStepCategoryEnum): FormArray {
  let discount_index = 0,
    upsale_index = 0,
    exchangeable_index = null,
    action1,
    action2,
    resourcetype,
    final_billing_cycle_offset = 0,
    discount = 20, // % or $
    next_bill_date_delay = 30, // days
    holdoff_delay = null;

  action2 = formBuilder.group({
    id: null,
    resourcetype: ActionType.CancelDelayed,
  });

  switch (stepType) {
    case CustomStepCategoryEnum.FixedAmountDownsell:
      action1 = formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: ActionType.Discount,
        discount: [discount, [Validators.required, Validators.min(1)]],
        discount_type: [DiscountTypeEnum.Fixed, Validators.required],
      });

      break;

    case CustomStepCategoryEnum.PercentageDownsell:
      action1 = formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: ActionType.Discount,
        discount: [discount, [Validators.required, Validators.min(1), Validators.max(100)]],
        discount_type: [DiscountTypeEnum.Percent, Validators.required],
      });

      break;

    case CustomStepCategoryEnum.PauseSub:
    case CustomStepCategoryEnum.ExtendTrial:
      action1 = formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: ActionType.BillingCycle,
        billing_interval_days: null,
        check_last_discount: true,
        pause: true,
        final_billing_cycle_offset,
        bill_now: false,
        next_bill_date_delay: [next_bill_date_delay, Validators.min(0)],
        max_bill_date_delay: null,
        holdoff_delay,
      });
      break;

    case CustomStepCategoryEnum.OfferDetails:
    case CustomStepCategoryEnum.LossAversion:
    case CustomStepCategoryEnum.Testimonial:
      action1 = formBuilder.group({
        id: null,
        resourcetype: ActionType.CancelDelayed,
        execution_type: ExecutionTypeEnum.Immediate,
      });

      break;

    case CustomStepCategoryEnum.RefundWithDiscount:
    case CustomStepCategoryEnum.DiscountFutureOrder:
    case CustomStepCategoryEnum.ReturnOrderRMA:
      resourcetype =
        stepType === CustomStepCategoryEnum.RefundWithDiscount || stepType === CustomStepCategoryEnum.ReturnOrderRMA
          ? ActionType.Refund
          : ActionType.Discount;

      action1 = formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: resourcetype,
        discount: [discount, Validators.required],
        discount_index,
        discount_type: [DiscountTypeEnum.Percent, Validators.required],
        ...(stepType === CustomStepCategoryEnum.RefundWithDiscount
          ? { refund_type: [RefundType.NormalAfterCredit, Validators.required] }
          : {}),
        check_last_discount: false,
        bill_now: true,
        include_tax: true,
        holdoff_delay,
        final_billing_cycle_offset,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
      });
      break;

    case CustomStepCategoryEnum.SwapProduct:
      action1 = formBuilder.group({
        id: null,
        resourcetype: ActionType.ChangeProduct,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
        exchangeable_set_types: [[], Validators.required],
        exchangeable_set: [[], Validators.required],
        exchangeable_index,
        bill_now: false,
        subscription_only: false,
        quantity_type: QuantityTypeEnum.None,
      });
      break;

    case CustomStepCategoryEnum.UpsellOrder:
      action1 = formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: ActionType.AddProduct,
        upsale_index,
        holdoff_delay,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
      });
      break;

    case CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount:
      action1 = formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: ActionType.Reactivate,
        discount: [discount, Validators.required],
        discount_index,
        discount_type: [DiscountTypeEnum.Fixed, Validators.required],
        check_last_discount: false,
        final_billing_cycle_offset,
        billing_interval_days: null,
        next_bill_date_delay: null,
        max_bill_date_delay: null,
        bill_now: true,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
      });

      break;
  }

  return formBuilder.array([action1, action2]);
}

function getResponseData(formBuilder: FormBuilder): FormGroup {
  const label = 'continue';

  return formBuilder.group({
    label: [null, Validators.required],
    help: null,
    name: [null],
    type: FunnelInputTypeEnum.InputActionStatus,
    category: CustomStepCategoryEnum.Response,
    banner: null,
    subtitle: null,
    content: [null, Validators.required],
    offer_intent: OfferIntents.Other,
    style: null,
    classes: [[]],
    is_popup: false,
    allow_undo: false,
    show_step_link: false,
    contact_type: null,
    enhanced_content: null,
    enhanced_mode: false,
    fail_if_any_input_invalid: false,
    invalid_message: null,
    hide_status: false,
    action_holdoffs: null,
    mobile_icon_button_type: null,
    use_bootstrap_spacing: true,
    enhanced_content_components: null,
    inputs: formBuilder.array([
      {
        label,
        help: null,
        next_step: null,
        type: FunnelInputTypeEnum.InputActionStatus,
        values: [],
        actions: [],
        icon: null,
        hide_if_invalid: true,
        product_funnels: [],
        classes: [],
        is_alt_child_exit: true,
        matched_step_key: null,
        autopick_next_step: null,
        requires_active_item: false,
        requires_item: true,
        requires_customer: true,
        allow_undo: false,
      },
    ]),
  });
}

export function patchInputs(
  form: FormGroup,
  formBuilder: FormBuilder,
  data: any,
  useExternalButtons: boolean,
  stepMedia: StepMedia,
  stepResponseMedia: StepResponseMedia,
  sanitizer: DomSanitizer,
  stepEmulatorService: StepEmulatorService
) {
  if (useExternalButtons) {
    const inputs = data.inputs.map(() => {
      return getSurveyInputs(formBuilder, data.offer_type);
    });
    if (inputs) {
      form.setControl('inputs', formBuilder.array(inputs, Validators.required));
    }
  }

  form.patchValue(data);

  const formInputsArray = form.get('inputs') as FormArray;
  data.inputs.forEach((dataInput, index) => {
    const formInput = formInputsArray.at(index) as FormGroup;
    const formActions = formInput.get('actions') as FormArray;

    dataInput.actions.forEach((action: Action, actionIndex: number) => {
      if (actionIndex >= formActions.length) {
        formActions.push(new FormControl(action));
      }
    });
  });

  if (data.static_data) {
    stepMedia = updateMedia(stepMedia, data.static_data, sanitizer);
    stepEmulatorService.updateStepMedia(stepMedia);
  }

  if (data && data.response_data && data.response_data.static_data) {
    stepResponseMedia = updateMedia(stepResponseMedia, data.response_data.static_data, sanitizer);
    stepEmulatorService.updateResponseMedia(stepResponseMedia);
  }
}

export function updateMedia(media, static_data: any, sanitizer: DomSanitizer) {
  const { image, image_mobile, image_tablet, image_type, embed_code } = static_data;

  switch (image_type) {
    case StepStaticDataImageType.Fixed:
      const setFixedImage = (device: StepEmulatorDevices, image: any) => {
        if (image) {
          media.category = StepMediaCategory.Fixed;
          media.fixedImage.category = StepFixedMediaCategory.Upload;
          media.fixedImage[device] = image;
        }
      };

      setFixedImage(StepEmulatorDevices.Mobile, image_mobile);
      setFixedImage(StepEmulatorDevices.Tablet, image_tablet);
      setFixedImage(StepEmulatorDevices.Desktop, image);
      break;

    case StepStaticDataImageType.Gallery:
      if (image) {
        media.category = StepMediaCategory.Fixed;
        media.fixedImage.category = StepFixedMediaCategory.Gallery;
        media.fixedImage.productImage = image;
      }
      break;

    case StepStaticDataImageType.Product:
      media.category = StepMediaCategory.Product;
      media.subCategory = StepMediaSubCategory.Product;
      break;

    case StepStaticDataImageType.Tagged:
      media.category = StepMediaCategory.Product;
      media.subCategory = StepMediaSubCategory.Tagged;
      break;

    case StepStaticDataImageType.Embed:
      media.category = StepMediaCategory.Embed;
      media.embedVideo.url = embed_code;
      media.embedVideo.sanitizedURL = getEmbeddedVideoUrl(media.embedVideo.url, sanitizer);
      media.embedVideo.isURLValid = !!media.embedVideo.sanitizedURL;
      break;

    case StepStaticDataImageType.None:
      media.category = StepMediaCategory.NoMedia;
      break;
  }
  return media;
}

export function externalButtonUsage(stepType: CustomStepCategoryEnum): boolean {
  const specialStepTypes = [
    CustomStepCategoryEnum.Survey,
    CustomStepCategoryEnum.ReturnSurvey,
    CustomStepCategoryEnum.CancelSubSurvey,
    CustomStepCategoryEnum.CancelOrderSurvey,
    CustomStepCategoryEnum.CancelTrialSurvey,
    CustomStepCategoryEnum.AdjustShippingFrequency,
    CustomStepCategoryEnum.PauseSurvey,
  ];

  return specialStepTypes.includes(stepType);
}
