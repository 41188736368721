import {OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PaginationService} from '../_services';
import {Category} from '../_models';
import {CrudPagedListComponent} from '../_directives';
import {Observable} from "rxjs";
import {NgxSmartModalService} from 'ngx-smart-modal';

export abstract class CategoryComponent extends CrudPagedListComponent implements OnInit {
  categories$ : Observable<Category[]> = this.data$;
  selectedCategory: Category;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected paginationService: PaginationService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService) {
    super(router, location, route, paginationService, alertService);
    this.objectName = 'category';
    this.title = 'Categories';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  create() {
    this.selectedCategory = null;
    this.openEditDialog();
  }

  edit(category: Category) {
    this.selectedCategory = category;
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('editDialog').open();
  }

  onSaveCategory(category: Category) {
    this.modalService.getModal('editDialog').close();
    this.queryData();
  }
}
