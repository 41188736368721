import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { PaginationService } from './pagination.service';
import {ClipboardService} from './clipboard.service';
import {Folder} from '../_models';

@Injectable()
export class FolderService extends PaginationService {
  constructor(protected http: HttpClient,
              protected clipboardService: ClipboardService) {
    super(http, 'folders');
  }

  copy(id: string | number) {
    this.http.post(this.route + '/' + id.toString() + '/copy/', {}).subscribe(
      data => {
        this.clipboardService.copy('folder', data);
      }
    );
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('folder');
  }

  paste(parentId: string | number | null) {
    let folder: Folder = this.clipboardService.paste('folder');
    folder.parent = parentId
    return this.http.post(this.route + '/paste/', folder);
  }
}
