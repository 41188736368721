import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AlertService, CustomerService} from "../_services";
import {CrudPagedListComponent} from "../_directives";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {Observable} from "rxjs";
import {Customer, TimeData} from "../_models";
import * as moment from "moment";

@Component({
  selector: 'customers',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerComponent extends CrudPagedListComponent implements OnInit {
  @Input('showNewest') showNewest: boolean = true;
  public customers$: Observable<Customer[]> = this.data$;
  public fields = [
    {name: 'created', label: 'Created At'},
    {name: 'customer_id', label: 'Customer ID'},
    {name: 'first_name', label: 'First Name'},
    {name: 'last_name', label: 'Last Name'},
    {name: 'company', label: 'Company'},
    {name: 'email', label: 'Email'},
    {name: 'phone', label: 'Phone'},
    {name: 'city', label: 'City'},
    {name: 'state', label: 'State'},
    {name: 'zip', label: 'Zip'},
    {name: 'country', label: 'Country'},
  ]
  rowsPerPageOptions = [
    {value: 25, label: "25"},
    {value: 50, label: "50"},
    {value: 100, label: "100"},
    {value: 250, label: "250"},
    {value: 500, label: "500"},
    {value: 1000, label: "1000"},
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected customerService: CustomerService,
    protected alertService: AlertService
  ) {
    super(router, location, route, customerService, alertService)
  }

  ngOnInit() {
    super.ngOnInit();
    this.queryData();
  }

  getFieldValue(row, fieldName) {
    if (fieldName in row) {
      let value = row[fieldName];
      if (fieldName === 'created') {
        value = moment(value).format(TimeData.SessionListDateFormat);
      }
      return value
    }
    return '';
  }

  protected getQueryFilter() {
    let filter = super.getQueryFilter();
    if (this.showNewest && !filter['ordering']) {
      filter['ordering'] = '-created';
    }
    return filter;
  }

}
