import { Form } from "../_forms";
import { OnInit, ViewChild } from "@angular/core";
import { downloadFromUrl } from "../_helpers";
import * as moment from "moment";
import {
  Campaign,
  CRMCampaign,
  CRMTypeId,
  FunnelStep,
  Pager,
  User,
  ReportSessionInput,
  ReportsUserConfiguration,
} from "../_models";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  AccountService,
  AlertService,
  CampaignProductService,
  CampaignService,
  CampaignTrackingService,
  CRMCampaignService,
  FunnelStepService,
  MerchantService,
  ReportsAbstractService,
  ReportsConfigService,
  StorageService,
  UserService,
} from "../_services";
import * as momentTz from "moment-timezone";
import { config } from "../../config/config";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { Subject, forkJoin, of } from "rxjs";
import { Moment } from "moment";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import {
  MultiOption,
  MultiOptions,
  MultiSubOption,
  MultiSubOptions,
} from "../custom-multi-select/multi-select";
import { ReportTableV2Component } from "./report-table-v2.component";
import { get, isEmpty } from "lodash";
import { MatExpansionPanel } from "@angular/material";
import { CampaignProductsRequest } from "./models/campaignProducts";
import { chargebackFilters, ignoredSummaryFields } from "./utils/constants";
import { reportsFieldDataV2 } from "./utils/reportsFieldData";
import { ReportDimensionCategories } from "./models/reports";
import { NgxSmartModalService } from "ngx-smart-modal";

export abstract class ReportsAbstractV2Component
  extends Form
  implements OnInit
{
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective: DaterangepickerDirective;
  @ViewChild(ReportTableV2Component, { static: false })
  reportsTable: ReportTableV2Component;
  @ViewChild("panel", { static: false }) panel: MatExpansionPanel;
  currentUser: User;
  campaigns: Campaign[] = [];
  isMenuVisible: boolean = false;
  reportTitle = "";
  fields: MultiOptions = [];
  predefinedFields = [
    "exclude_hangups",
    "exclude_unknowns",
    "exclude_child_sessions",
    "exclude_child_orders",
    "exclude_zeroes",
  ];
  filters: MultiOptions = [];
  predefinedFilters;
  selectedDimensions: MultiSubOptions = [];
  selectedDimensionsList: string[] = [];
  selectedFilters: MultiOptions = [];
  reportsData: any;
  selectedCampaign = "";
  selectedMode = "transaction";
  isReportGenerating = true;
  isReportDownloading = false;
  isSummaryLoading = true;
  dropdownsLoading = true;
  ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Past 7 Days": [moment().subtract(7, "days"), moment()],
    "Month to Date": [moment().startOf("month"), moment()],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last 30 days": [moment().subtract(30, "days"), moment()],
    "Last 3 Months to Date": [
      moment().subtract(3, "months").startOf("month"),
      moment(),
    ],
    "Last 6 Months to Date": [
      moment().subtract(6, "months").startOf("month"),
      moment(),
    ],
    "Last 12 Months to Date": [
      moment().subtract(12, "months").startOf("month"),
      moment(),
    ],
    "Year to Date": [moment().startOf("year"), moment()],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };
  maxDate = moment();
  date: { startDate: Moment; endDate: Moment };
  timezone;
  dataFilterTypes = [
    { value: "sessions", text: "Sessions" },
    { value: "customer", text: "Customer" },
  ];

  summary: any = {};
  summaryFields = [];
  commonReportsFields = [];
  queryParams = { account_id: null, page: 1, page_size: config.maxPageSize };
  protected fieldData = reportsFieldDataV2;
  productsPage = 1;
  isConfigChanged = false;
  isSpChargebackReport = false;
  isChargebackReport = false;
  timeIntervals = Object.entries(this.ranges).map(([label, value]) => ({
    label,
    value,
  }));
  timezones: string[];
  saveParams = {
    reportName: "",
    selectedTimezone: "",
    selectedTimeInterval: [moment().subtract(7, "days"), moment()],
  };
  reportReq: any;
  reportType: number;
  isReportSaving: boolean = false;
  isReportDeleting: boolean = false;
  isCustomReport: boolean = false;
  customReportId = "";
  customReportName = "";
  isCustomReportLoading: boolean = false;
  isEmpty = (val) => isEmpty(val);
  protected _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    public reportsService: ReportsAbstractService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected merchantService: MerchantService,
    protected modalService: NgxSmartModalService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.timezones = momentTz.tz.names();
    this.currentUser = this.storageService.get("currentUser");
    this.queryParams.account_id = this.currentUser.account.id;
    this.initializeDate();
    this.getFilterData();

    this.route.params.subscribe((params) => {
      if (params.id) {
        this.isCustomReport = true;
        this.customReportId = params.id;
        this.isCustomReportLoading = true;
        this.reportsConfigService
          .get(params.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (config: ReportsUserConfiguration) => {
              this.isCustomReportLoading = false;
              this.customReportName = config.name;
              this.selectedDimensions = config.configuration.dimensions;
              this.selectedFilters = config.configuration.filters;
              const [startDateString, endDateString] =
                config.time_interval.split(",");

              this.date = {
                startDate: moment(startDateString.trim()),
                endDate: moment(endDateString.trim()),
              };
              this.timezone = config.timezone;
              this.saveParams.selectedTimezone = this.timezone;

              this.filters.forEach((filter, index) => {
                const selectedFilter = this.selectedFilters.find(
                  (f) => f.model === filter.model
                );
                if (selectedFilter) {
                  this.filters[index].activeCount = selectedFilter.activeCount;
                  this.filters[index].options = selectedFilter.options;
                }
              });

              this.selectedDimensions.forEach((field) => {
                this.fields.forEach((x) => {
                  x.options = x.options.map((option) =>
                    option.model === field.model ? field : option
                  );
                });
              });

              const fields = [];
              config.configuration.dimensions.forEach((dimension) => {
                fields.push(dimension.model);
              });
              this.updatePreSelectedDimensions(fields);
              this.reportReq = this.getQueryForReport();
              this.getSummary();
            },
            (error) => {
              this.alertService.error(error.message);
            }
          );
      } else {
        this.timezone = this.storageService.get("timezone");
        if (!this.timezone) {
          this.timezone = momentTz.tz.guess();
        }
        this.getSummary();
        this.reportReq = this.getQueryForReport();
      }

      this.saveParams.selectedTimezone = this.timezone;
    });
  }

  initializeDate() {
    this.date = {
      startDate: moment().subtract(7, "days").startOf("day"),
      endDate: moment().endOf("day"),
    };
    this.maxDate = this.date.endDate;
  }

  getFilterData() {
    this.filters.forEach((filter) => {
      switch (filter.model) {
        case "product_id":
          this.getProducts({ searchTerm: "", nextPage: false });
          break;
        case "product_path":
          this.getProductPaths();
          break;
        case "campaign":
          this.getCampaignsList();
          break;
        case "step_slug":
          this.getStepImpressions();
          break;
        case "cancel_reason":
          this.getCancelReasons();
          break;
        case "input_name":
          this.getNames();
          break;
        case "crm_campaign":
          this.getCRMCampaigns();
          break;
        case "merchant_id":
          this.getMerchants();
          break;
        case "tracking_source":
          this.getTrackingSources();
          break;
      }
    });
  }

  getCampaignsList() {
    if (this.currentUser.account.id)
      this.campaignService
        .list({ is_hybrid: false, account_id: this.currentUser.account.id })
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (result: Pager) => {
            this.campaigns = result.results;
            this.dropdownsLoading = false;
            this.filters.find((filter) => filter.model === "campaign").options =
              this.campaigns.map((d) => ({
                model: d.id.toString(),
                label: d.name,
                selected: false,
                disabled: false,
              }));
            this.filters.find((filter) => filter.model === "campaign").loading =
              false;
          },
          (error) => {
            this.filters.find((filter) => filter.model === "campaign").loading =
              false;
            this.alertService.error(error.message);
          }
        );
  }

  getCancelReasons() {
    this.reportsService
      .getCancelReasons(this.queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.filters.find(
            (filter) => filter.model === "cancel_reason"
          ).options = data.map((d) => ({
            model: d,
            label: d,
            selected: false,
            disabled: false,
          }));
          this.filters.find(
            (filter) => filter.model === "cancel_reason"
          ).loading = false;
        },
        (error) => {
          this.filters.find(
            (filter) => filter.model === "cancel_reason"
          ).loading = false;
          this.handleError(error);
        }
      );
  }

  getProductPaths() {
    this.reportsService
      .getProductPaths(this.queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.filters.find(
            (filter) => filter.model === "product_path"
          ).options = data.map((d) => ({
            model: d,
            label: d,
            selected: false,
            disabled: false,
          }));
          this.filters.find(
            (filter) => filter.model === "product_path"
          ).loading = false;
        },
        (error) => {
          this.filters.find(
            (filter) => filter.model === "product_path"
          ).loading = false;
          this.handleError(error);
        }
      );
  }

  getProducts({ searchTerm, nextPage }) {
    const index = this.filters.findIndex(
      (filter) => filter.model === "product_id"
    );
    if (nextPage && this.filters[index].options.length >= 50) {
      this.productsPage++;
    } else this.productsPage = 1;

    const queryParams: CampaignProductsRequest = {
      accounts: this.queryParams.account_id,
      page: this.productsPage,
    };

    this.filters[index].paginationLoading = true;
    if (searchTerm) {
      if (this.productsPage === 1) this.filters[index].options = [];

      const productsApiCall = this.reportsService.getProducts({
        name__icontains: searchTerm,
        ...queryParams,
      });
      const campaignProductsApiCall = this.reportsService.getProducts({
        campaign_product_id: searchTerm,
        ...queryParams,
      });

      forkJoin([productsApiCall, campaignProductsApiCall])
        .pipe(
          takeUntil(this._destroy$),
          catchError((error) => {
            this.filters[index].loading = false;
            this.filters[index].paginationLoading = false;
            if (error.error.detail !== "Invalid page.") this.handleError(error);
            return of([]);
          })
        )
        .subscribe(([productsData, campaignProductsData]: any) => {
          const uniqueProductIds = new Set<number>();

          if (this.productsPage > 1 && productsData) {
            this.filters[index].options.push(
              ...productsData.results
                .filter((p) => !uniqueProductIds.has(p.id))
                .map((p) => {
                  uniqueProductIds.add(p.id);
                  return {
                    model: p.id,
                    label: `${p.name} (${p.campaign_product_id})`,
                    selected: false,
                    disabled: false,
                  };
                })
            );
          } else {
            if (productsData)
              this.filters[index].options = productsData.results.map((p) => ({
                model: p.id,
                label: `${p.name} (${p.campaign_product_id})`,
                selected: false,
                disabled: false,
              }));
          }

          if (campaignProductsData)
            this.filters[index].options.push(
              ...campaignProductsData.results
                .filter((p) => !uniqueProductIds.has(p.id))
                .map((p) => {
                  uniqueProductIds.add(p.id);
                  return {
                    model: p.id,
                    label: `${p.name} (${p.campaign_product_id})`,
                    selected: false,
                    disabled: false,
                  };
                })
            );

          this.filters[index].loading = false;
          this.filters[index].paginationLoading = false;
        });
    } else {
      this.reportsService
        .getProducts(queryParams)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (data: any) => {
            if (this.productsPage > 1) {
              this.filters[index].options.push(
                ...data.results.map((p) => ({
                  model: p.id,
                  label: `${p.name} (${p.campaign_product_id})`,
                  selected: false,
                  disabled: false,
                }))
              );
            } else {
              this.filters[index].options = data.results.map((p) => ({
                model: p.id,
                label: `${p.name} (${p.campaign_product_id})`,
                selected: false,
                disabled: false,
              }));
            }

            this.filters[index].loading = false;
            this.filters[index].paginationLoading = false;
          },
          (error) => {
            this.filters[index].loading = false;
            this.filters[index].paginationLoading = false;
            if (error.error.detail !== "Invalid page.") this.handleError(error);
          }
        );
    }
  }

  getStepImpressions() {
    this.stepService
      .getGlobalJumpSteps(true, this.queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: FunnelStep[]) => {
          if (
            this.filters.find((filter) => filter.model === "step_slug").options
              .length
          ) {
            return;
          }

          this.filters.find((filter) => filter.model === "step_slug").options =
            data.map((d: FunnelStep) => ({
              model: d.slug,
              label: `${d.name}`,
              selected: false,
              disabled: false,
            }));
          this.filters.find((filter) => filter.model === "step_slug").loading =
            false;
        },
        (error) => {
          this.filters.find((filter) => filter.model === "step_slug").loading =
            false;
          this.handleError(error);
        }
      );
  }

  getCRMCampaigns() {
    this.crmCampaignService
      .listAll({ ...this.queryParams, "crm__type!": CRMTypeId.Test })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          if (
            this.filters.find((filter) => filter.model === "crm_campaign")
              .options &&
            this.filters.find((filter) => filter.model === "crm_campaign")
              .options.length
          ) {
            return;
          }

          this.filters.find(
            (filter) => filter.model === "crm_campaign"
          ).options = data.results.map((d: CRMCampaign) => ({
            model: d.id,
            label: `${d.name}(${d.crm_campaign_id})`,
            selected: false,
            disabled: false,
          }));
          this.filters.find(
            (filter) => filter.model === "crm_campaign"
          ).loading = false;
        },
        (error) => {
          this.filters.find(
            (filter) => filter.model === "crm_campaign"
          ).loading = false;
          this.handleError(error);
        }
      );
  }

  getNames() {
    const params = {
      ...this.queryParams,
      start_date: moment(this.date.startDate)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(this.date.endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
    };

    this.reportsService
      .getInputNames(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          if (
            this.filters.find((filter) => filter.model === "input_name").options
              .length
          ) {
            return;
          }

          this.filters.find((filter) => filter.model === "input_name").options =
            data.map((d) => ({
              model: d.input_name,
              label: d.input_name,
              selected: false,
              disabled: false,
            }));
          this.filters.find((filter) => filter.model === "input_name").loading =
            false;
        },
        (error) => {
          this.filters.find((filter) => filter.model === "input_name").loading =
            false;
          this.handleError(error);
        }
      );
  }

  getMerchants() {
    this.merchantService
      .list({ ...this.queryParams, "crm__type!": CRMTypeId.Test })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          if (
            this.filters.find((filter) => filter.model === "merchant_id")
              .options.length
          ) {
            return;
          }

          this.filters.find(
            (filter) => filter.model === "merchant_id"
          ).options = data.results.map((d) => ({
            model: d.merchant_id,
            label: d.name,
            selected: false,
            disabled: false,
          }));
          this.filters.find(
            (filter) => filter.model === "merchant_id"
          ).loading = false;
        },
        (error) => {
          this.filters.find(
            (filter) => filter.model === "merchant_id"
          ).loading = false;
          this.handleError(error);
        }
      );
  }

  getTrackingSources() {
    this.trackingSourceService
      .list({ ...this.queryParams, "crm__type!": CRMTypeId.Test })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          if (
            this.filters.find((filter) => filter.model === "tracking_source")
              .options.length
          ) {
            return;
          }

          this.filters.find(
            (filter) => filter.model === "tracking_source"
          ).options = data.map((d) => ({
            model: d.source,
            label: d.source,
            selected: false,
            disabled: false,
          }));
          this.filters.find(
            (filter) => filter.model === "tracking_source"
          ).loading = false;
        },
        (error) => {
          this.filters.find(
            (filter) => filter.model === "tracking_source"
          ).loading = false;
          this.handleError(error);
        }
      );
  }

  onCampaignChange(event) {
    this.isConfigChanged = true;
    this.selectedCampaign = event.target.value;
  }

  onCustomerModeChange(event) {
    this.isConfigChanged = true;
    this.selectedMode = event.target.value;
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  onDateChange = (dateRange: { startDate: Moment; endDate: Moment }) => {
    if (!isEmpty(this.summary)) this.isConfigChanged = true;
    this.date = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };
  };

  clearAllDimensions() {
    this.selectedDimensions = this.selectedDimensions.filter(
      (d) => d.selected === false
    );
    this.fields.forEach((option) => {
      option.options.forEach((sub) => {
        sub.selected = false;
        sub.disabled = false;
      });
    });
  }

  removeDimension(dimension: MultiSubOption) {
    this.selectedDimensions = this.selectedDimensions.filter(
      (d) => d.model !== dimension.model
    );
    const isRatioField =
      typeof dimension.model === "string" && dimension.model.includes("ratio");

    this.fields.forEach((option) => {
      option.options.forEach((sub) => {
        if (
          isRatioField &&
          (sub.model === "time_period" || sub.model === "merchant_id")
        ) {
          sub.selected = false;
          sub.disabled = false;
        }
        if (sub.model === dimension.model) {
          sub.selected = false;
        }
      });
    });
  }

  onDimensionsChange({ option, subOption }) {
    this.isConfigChanged = true;
    if (subOption.selected) this.selectedDimensions.push(subOption);
    else
      this.selectedDimensions = this.selectedDimensions.filter(
        (d) => d.model !== subOption.model
      );
  }

  onFiltersChange({ option, subOption }) {
    this.isConfigChanged = true;
    const isActive = this.selectedFilters.find(
      (filter) => filter.model === option.model
    );

    if (isActive) {
      if (option.activeCount === 0)
        this.selectedFilters = this.selectedFilters.filter(
          (filter) => filter.model !== option.model
        );
    } else {
      if (option.activeCount > 0) this.selectedFilters.push(option);
    }
  }

  onInputFiltersChange({ option, value }) {
    this.isConfigChanged = true;

    // Check if the option already exists in selectedFilters
    const existingFilter = this.selectedFilters.find(
      (filter) => filter.model === option.model
    );

    if (!existingFilter) this.selectedFilters.push({ ...option, value });
    else existingFilter.searchTerm = value;
  }

  onComparisonInputFiltersChange({ option, value, comparingOption }) {
    this.isConfigChanged = true;

    const existingFilter = this.selectedFilters.find(
      (filter) => filter.model === option.model
    );

    if (!existingFilter) {
      this.selectedFilters.push({ ...option, value, comparingOption });
    } else {
      existingFilter.searchTerm = value;
      existingFilter.selectedOption = option.selectedOption;
      existingFilter.comparingOption = comparingOption;
    }
  }

  removeFilter(filter: MultiOption) {
    this.selectedFilters = this.selectedFilters.filter(
      (d) => d.model !== filter.model
    );

    const activeFilters = this.filters.filter(
      (option) => option.activeCount > 0
    );

    this.filters.forEach((option) => {
      if (option.label === filter.label) {
        option.searchTerm = "";
        option.activeCount = 0;
        option.selectedOption = null;
        option.selected = false;

        option.options.forEach((sub) => {
          sub.selected = false;
        });
      }
    });

    if (activeFilters.length === 4) {
      this.filters.forEach((currentOption) => {
        currentOption.disabled = false;
        if (currentOption.options)
          currentOption.options.forEach((sub) => {
            sub.disabled = false;
          });
        else currentOption.disabled = false;
      });
    }
  }

  clearAllFilters() {
    this.selectedFilters = [];
    this.filters.forEach((option) => {
      option.disabled = false;
      option.searchTerm = "";
      option.selectedOption = null;
      option.activeCount = 0;
      option.selected = false;

      if (option.options)
        option.options.forEach((sub) => {
          sub.selected = false;
          sub.disabled = false;
        });
    });
  }

  clearSearchInputs() {
    this.filters.forEach((option) => {
      if (!(option.model === "billing_cycle" || option.model === "amount")) {
        option.searchTerm = "";
      }
    });
    this.fields.forEach((option) => {
      option.searchTerm = "";
    });
  }

  getQueryForSummary() {
    let filters = {};

    const selectedFilters = this.selectedFilters.reduce(
      (result, selectedFilter) => {
        const matchingFilter = this.filters.find(
          (filter) => filter.model === selectedFilter.model
        );

        if (matchingFilter) {
          if (matchingFilter.type === "DROPDOWN") {
            const selectedOptions = matchingFilter.options
              .filter((option) => option.selected)
              .map((option) => option.model);

            result[selectedFilter.model] = selectedOptions;
          } else if (matchingFilter.type === "COMPARISON") {
            if (
              "searchTerm" in selectedFilter &&
              "selectedOption" in selectedFilter &&
              selectedFilter.selectedOption !== "range" &&
              selectedFilter.selectedOption !== "e" // Here "e" represents the "equals to" model present in comparison options
            ) {
              result[
                `${selectedFilter.model}__${selectedFilter.selectedOption}`
              ] = Number(selectedFilter.searchTerm);
            } else if (
              "searchTerm" in selectedFilter &&
              "selectedOption" in selectedFilter &&
              "comparingOption" in selectedFilter &&
              selectedFilter.selectedOption !== "e"
            ) {
              result[
                `${selectedFilter.model}__${selectedFilter.selectedOption}`
              ] = [
                Number(selectedFilter.searchTerm),
                Number(selectedFilter.comparingOption),
              ];
            } else
              result[selectedFilter.model] = Number(selectedFilter.searchTerm);
          } else result[selectedFilter.model] = selectedFilter.searchTerm;
        }
        return result;
      },
      {}
    );

    if (this.predefinedFilters) {
      filters = { ...this.predefinedFilters, ...selectedFilters };
    } else {
      filters = { ...selectedFilters };
    }

    if (
      this.predefinedFields &&
      this.predefinedFields.length > 0 &&
      this.predefinedFields.every(
        (field) => !this.summaryFields.includes(field)
      )
    ) {
      this.summaryFields = this.summaryFields.concat(this.predefinedFields);
    }

    return {
      accounts: [this.queryParams.account_id],
      customer_mode: this.selectedMode === "customer",
      date_filter_type: "sessions",
      date_range: {
        startDate: moment(this.date.startDate)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(this.date.endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      },
      fields: this.summaryFields,
      filters,
      timezone: this.timezone,
    };
  }

  getQueryForReport() {
    let filters = {};

    this.selectedDimensionsList = [].concat
      .apply(
        [],
        this.selectedDimensions.map((subItem) => subItem.model)
      )
      .concat(this.commonReportsFields);

    let selectedDimensions = this.selectedDimensionsList;

    const selectedFilters = this.selectedFilters.reduce(
      (result, selectedFilter) => {
        const matchingFilter = this.filters.find(
          (filter) => filter.model === selectedFilter.model
        );

        if (matchingFilter) {
          if (matchingFilter.type === "DROPDOWN") {
            const selectedOptions = matchingFilter.options
              .filter((option) => option.selected)
              .map((option) => option.model);

            result[selectedFilter.model] = selectedOptions;
          } else if (matchingFilter.type === "COMPARISON") {
            if (
              "searchTerm" in selectedFilter &&
              "selectedOption" in selectedFilter &&
              selectedFilter.selectedOption !== "range" &&
              selectedFilter.selectedOption !== "e" // Here "e" represents the "equals to" model present in comparison options
            ) {
              result[
                `${selectedFilter.model}__${selectedFilter.selectedOption}`
              ] = Number(selectedFilter.searchTerm);
            } else if (
              "searchTerm" in selectedFilter &&
              "selectedOption" in selectedFilter &&
              "comparingOption" in selectedFilter &&
              selectedFilter.selectedOption !== "e"
            ) {
              result[
                `${selectedFilter.model}__${selectedFilter.selectedOption}`
              ] = [
                Number(selectedFilter.searchTerm),
                Number(selectedFilter.comparingOption),
              ];
            } else
              result[selectedFilter.model] = Number(selectedFilter.searchTerm);
          } else result[selectedFilter.model] = selectedFilter.searchTerm;
        }
        return result;
      },
      {}
    );

    if (this.predefinedFilters) {
      filters = { ...this.predefinedFilters, ...selectedFilters };
    } else {
      filters = { ...selectedFilters };
    }

    if (
      this.predefinedFields &&
      this.predefinedFields.length > 0 &&
      this.predefinedFields.every(
        (field) => !selectedDimensions.includes(field)
      )
    ) {
      selectedDimensions = selectedDimensions.concat(this.predefinedFields);
    }

    if (!this.date.startDate || !this.date.endDate) this.initializeDate();

    return {
      accounts: [this.queryParams.account_id],
      customer_mode: this.selectedMode === "customer",
      date_filter_type: "sessions",
      date_range: {
        startDate: this.date.startDate.utc().format("YYYY-MM-DD HH:mm:ss"),
        endDate: this.date.endDate.utc().format("YYYY-MM-DD HH:mm:ss"),
      },
      fields: selectedDimensions,
      filters,
      timezone: this.timezone,
    };
  }

  getReport() {
    this.isConfigChanged = false;
    this.isReportGenerating = true;
    if (this.reportsTable) {
      this.reportsTable.reportReq = this.getQueryForReport();
      this.reportsTable.resetPager();
      this.reportsTable.queryData(this.selectedDimensionsList);
      this.getSummary();
      this.clearSearchInputs();
    }
  }

  getFieldValue(sessionInput: ReportSessionInput, field: string) {
    if (
      field === "campaign" ||
      field === "crm_campaign" ||
      field === "merchant_id"
    ) {
      let data = chargebackFilters
        .find((data) => data.model === field)
        .options.find(
          (data) => String(data.model) === String(sessionInput[field])
        );
      return data ? data.label : "";
    }
    if (field.startsWith("exclude_")) {
      return "";
    }
    let value = get(sessionInput, field);
    let args = [sessionInput, value];
    if (
      ["date", "month", "year", "week", "hour", "order_date"].indexOf(field) >
      -1
    ) {
      args.push(this.storageService.get("timezone"));
    }
    if ("getter" in this.fieldData[field]) {
      value = this.fieldData[field].getter(...args);
      if (value !== 0 && !value) value = "";
    }

    return value;
  }

  getSummaryApiCalls() {
    return this.reportsService.getFilteredReport(this.getQueryForSummary(), "")
  }

  getSummary() {
    this.isSummaryLoading = true;
    this.getSummaryApiCalls()
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (result) => {
          const summaryData = {};
          const resp = result[0];

          this.summaryFields.forEach((key) => {
            if (ignoredSummaryFields.includes(key)) {
              return;
            }

            const formattedKey =
              this.fieldData[key].summary_label ||
              this.fieldData[key].custom_label ||
              this.fieldData[key].label;

            if (
              resp &&
              (resp[key] || resp[key] === null || resp[key] === 0) &&
              resp[key] !== undefined &&
              "getter" in this.fieldData[key]
            ) {
              summaryData[formattedKey] = this.fieldData[key].getter(
                {},
                resp[key]
              );
              if (!summaryData[formattedKey] && summaryData[formattedKey] !== 0)
                summaryData[formattedKey] = "--";
            } else {
              summaryData[formattedKey] = "--";
            }
          });

          this.summary = summaryData;
          this.isSummaryLoading = false;
        },
        (error) => {
          const summaryData = {};
          for (const key of this.summaryFields) {
            if (!ignoredSummaryFields.includes(key)) {
              const formattedKey =
                this.fieldData[key].summary_label ||
                this.fieldData[key].custom_label ||
                this.fieldData[key].label;
              summaryData[formattedKey] = "--";
            }
          }

          this.summary = summaryData;
          this.isSummaryLoading = false;
          this.handleError(error);
        }
      );
  }

  updateReportFlag() {
    this.isReportGenerating = false;
  }

  openSaveDialog() {
    this.modalService.open("saveReportModal");
    if (this.isCustomReport) this.saveParams.reportName = this.customReportName;
  }

  onSave(): void {
    const body = {
      name: this.saveParams.reportName,
      currency: "USD",
      time_interval: `${this.saveParams.selectedTimeInterval}`,
      timezone: this.saveParams.selectedTimezone,
      report_type: this.reportType,
      legacy: false,
      configuration: {
        dimensions: this.selectedDimensions,
        filters: this.selectedFilters,
      },
    };

    this.isReportSaving = true;
    if (!this.isCustomReport) {
      this.reportsService
        .saveReport(body)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          () => {
            this.isReportSaving = false;
            this.fetchReportConfing();
            const [startDateString, endDateString] =
              body.time_interval.split(",");

            this.date = {
              startDate: moment(startDateString.trim()),
              endDate: moment(endDateString.trim()),
            };
            this.timezone = body.timezone;
            this.modalService.getModal("saveReportModal").close();
            this.alertService.success("Report Saved Successfully", true);
          },
          (error) => {
            this.isReportSaving = false;
            this.handleError(error);
          }
        );
    } else {
      this.reportsService
        .updateReport(this.customReportId, body)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          () => {
            this.isReportSaving = false;
            this.fetchReportConfing();
            const [startDateString, endDateString] =
              body.time_interval.split(",");

            this.date = {
              startDate: moment(startDateString.trim()),
              endDate: moment(endDateString.trim()),
            };
            this.timezone = body.timezone;
            this.modalService.getModal("saveReportModal").close();
            this.customReportName = this.saveParams.reportName;
            this.alertService.success("Report Updated Successfully", true);
          },
          (error) => {
            this.isReportSaving = false;
            this.handleError(error);
          }
        );
    }
  }

  onCancel(): void {
    this.modalService.getModal("saveReportModal").close();
  }

  openDeleteDialog() {
    this.modalService.open("deleteReportModal");
  }

  onDelete() {
    this.isReportDeleting = true;
    this.reportsService
      .deleteReport(this.customReportId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.isReportDeleting = false;
          this.fetchReportConfing(true);
          this.alertService.success("Report Deleted Successfully");
          this.modalService.getModal("deleteReportModal").close();
        },
        (error) => {
          this.isReportDeleting = false;
          this.handleError(error);
        }
      );
  }

  onDeleteCancel(): void {
    this.modalService.getModal("deleteReportModal").close();
  }

  fetchReportConfing(routing = false) {
    this.reportsConfigService
      .list({})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.reportsConfigService._reportConfigSubject.next(data.results);
          if (routing) {
            const currentUrl = this.router.url;
            const baseUrl = currentUrl.split("/")[1];
            this.router.navigate(["/" + baseUrl]);
          }
        },
        (error) => {
          this.alertService.error(error.message);
        }
      );
  }

  downloadReport() {
    this.isReportDownloading = true;
    const req = {
      ...this.getQueryForReport(),
      start_date_formatted: this.date.startDate.format("MMM Do, YYYY"),
      end_date_formatted: this.date.endDate.format("MMM Do, YYYY"),
    };

    this.reportsService
      .getDownloadReportData(req)
      .pipe(tap(() => (this.isReportDownloading = false)))
      .subscribe(
        (data) => {
          downloadFromUrl(data.download_url);
        },
        (error) => {
          this.isReportDownloading = false;
          if (error.status === 404) {
            this.alertService.error("No reports to download");
            return;
          }
          this.alertService.error("An error occurred while downloading report");
        }
      );
  }

  getDimensionsLength() {
    let count = 0;
    this.selectedDimensions.forEach(() => {
      count++;
    });
    return count;
  }

  closeMenu() {
    this.isMenuVisible = false;
  }

  keepOrder = (a, b) => {
    return a;
  };

  updatePreSelectedDimensions(model: string | string[]) {
    if (Array.isArray(model)) {
      model.forEach((singleModel) => {
        const option = this.fields[
          ReportDimensionCategories.Dimensions
        ].options.find((opt) => opt.model === singleModel);
        if (option) {
          option.selected = true;
        }
      });
    } else {
      const option = this.fields[
        ReportDimensionCategories.Dimensions
      ].options.find((opt) => opt.model === model);
      if (option) {
        option.selected = true;
      }
    }
  }

  filterCommonDimensions(dimensions: string[]) {
    this.fields.forEach((field) => {
      for (let i = field.options.length - 1; i >= 0; i--) {
        if (dimensions.includes(field.options[i].model.toString())) {
          field.options.splice(i, 1);
        }
      }
    });
  }
}
