import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CrudService, CampaignService, StorageService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {Campaign, Pager} from "../_models";
import {config} from "../../config/config";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  selector: 'preview-form',
  templateUrl: './preview-form.component.html'
})
export class PreviewFormComponent extends CrudSavePopupComponent implements OnInit {
  campaigns: Campaign[] = [];

  @Input('campaignId') defaultCampaignId: string | number | null;
  @Input('filter') campaignFilter: any;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected crudService: CrudService,
              protected alertService: AlertService,
              protected campaignService: CampaignService,
              protected storageService: StorageService
  ) {
    super(router, location, route, crudService, alertService);
    this.objectName = 'preview';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      campaign: [null, Validators.required]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    if (this.inputData) {
      const defaultCampaignId = this.defaultCampaignId || this.storageService.get('previewCampaignId');
      const filter = this.campaignFilter || {};

      if (defaultCampaignId) {
        this.form.patchValue({campaign: defaultCampaignId});
      }

      this.campaignService.list(Object.assign({page: 1, page_size: config.maxPageSize}, filter))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: Pager) => {
            this.campaigns = data.results;
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      const selectedCampaignId = this.form.value.campaign;

      this.loading = true;
      this.storageService.set('previewCampaignId', selectedCampaignId);

      this.crudService.preview(selectedCampaignId, this.inputData)
        .subscribe(
          data => {
            this.onSaveComplete(data);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

}
