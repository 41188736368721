import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PhoneService, AddressService, CampaignService, CampaignDomainService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {
  PhoneNumberTypeEnum,
  Address,
  Pager,
  PhoneSearchResult,
  Campaign,
  PhoneUseTypeEnum,
  CustomDomain, PhoneResourceType, PhoneProviderType,
} from "../_models";
import {AddressFieldsComponent} from "../address";
import {config} from '../../config/config';
import {takeUntil} from "rxjs/operators";
import {forkJoin} from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './phone-new.component.html'
})
export class PhoneNewComponent extends CrudSaveComponent implements OnInit, AfterViewInit {
  addNewAddress = true;
  selectedAddress = null;
  type: PhoneNumberTypeEnum;
  phoneNumber: PhoneSearchResult;
  addresses: Address[] = [];
  defaultCampaignId = null;
  defaultDomainId = null;
  useTypes = [
    {value: PhoneUseTypeEnum.VoiceAndSMS, label: 'Voice & SMS'},
    {value: PhoneUseTypeEnum.VoiceOnly, label: 'Voice Only'},
    {value: PhoneUseTypeEnum.SMSOnly, label: 'SMS Only'}
  ];
  campaigns: {value: string, label: string}[] = [];
  domains: {value: string, label: string}[] = [];

  @ViewChild(AddressFieldsComponent, { static: false }) addressFields: AddressFieldsComponent;

  protected domainMap: {} = {};
  protected selectedCampaignId = null;
  protected resourceTypeMap: {} = {}
  private resourceType: PhoneResourceType;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected phoneService: PhoneService,
    protected addressService: AddressService,
    protected campaignService: CampaignService,
    protected domainService: CampaignDomainService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService
  ) {
    super(router, location, route, phoneService, alertService);
    this.isNew = true;
    this.objectName = 'Phone Number';
    this.title = 'Add New Phone Number';

    this.resourceTypeMap[PhoneProviderType.Twilio] = PhoneResourceType.Twilio;
    this.resourceTypeMap[PhoneProviderType.Telnyx] = PhoneResourceType.Telnyx;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.type = +params.get('type');
      this.phoneNumber = {value: params.get('value'), label: params.get('label')};
      this.resourceType = this.resourceTypeMap[+params.get('provider_type')];
    });

    this.defaultCampaignId = this.route.snapshot.params['campaign_id'] || '';
    this.defaultDomainId = this.route.snapshot.params['domain_id'] || '';

    this.form = this.formBuilder.group({
      phone: [this.phoneNumber.value, Validators.required],
      type: [this.type, Validators.required],
      campaign: [null],
      domain: [null],
      address_id: ['new', Validators.required],
      use_type: [PhoneUseTypeEnum.VoiceAndSMS, Validators.required],
      resourcetype: [this.resourceType],
    });

    super.ngOnInit();

    forkJoin([
      this.campaignService.list({page: 1, page_size: config.maxPageSize}),
      this.domainService.list()
    ]).subscribe(
      (data: [Pager, CustomDomain[]]) => {
        console.log(data);
        if (data) {
          const campaigns = data[0].results; // campaign results already sorted by name
          const domains = data[1];
          this.buildSelections(campaigns, this.defaultCampaignId, domains, this.defaultDomainId);
        }
      },
      error => {
        this.handleError(error);
      }
    );

    this.addressService.list({page: 1, page_size: config.maxPageSize})
      .subscribe(
        (data: Pager) => {
          this.addresses = data.results;
        },
        error => {
          this.handleError(error);
        }
      );

    this.form.get('address_id').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      let addNewAddress = (val == 'new') ? true : false;
      this.selectedAddress = addNewAddress ? null : val;

      if (!this.addNewAddress && addNewAddress) {
        this.addNewAddress = true;
        setTimeout(() => this.form.addControl('address', this.addressFields.form), 0);
      }
      else if (this.addNewAddress && !addNewAddress) {
        this.addNewAddress = false;
        setTimeout(() => this.form.removeControl('address'), 0);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.form.addControl('address', this.addressFields.form), 0);
  }

  selectCampaign(event) {
    const newCampaignId = event.target.value;

    if (newCampaignId !== this.selectedCampaignId) {
      this.selectedCampaignId = newCampaignId;
      this.form.patchValue({domain: null}); // clear domain selection
      this.updateDomainList();
    }
  }

  protected updateDomainList() {
    this.domains = this.selectedCampaignId in this.domainMap ? this.domainMap[this.selectedCampaignId] : [];
  }

  protected buildSelections(
    campaigns: Campaign[],
    selectedCampaignId: string | number | null,
    domains: CustomDomain[],
    selectedDomainId: string | number | null
  ) {
    domains.sort((a: CustomDomain, b: CustomDomain): number => {
      return (a.domain > b.domain) ? 1 : -1;
    })

    domains.forEach((domain: CustomDomain) => {
      const campaignId = domain.campaign.toString()

      if (campaignId in this.domainMap) {
        this.domainMap[campaignId].push({value: domain.id.toString(), label: domain.domain});
      } else {
        this.domainMap[campaignId] = [{value: domain.id.toString(), label: domain.domain}];
      }

      if (domain.id.toString() === selectedDomainId.toString()) {
        this.form.patchValue({domain: domain.id});
      }
    });

    campaigns.forEach((campaign: Campaign) => {
      this.campaigns.push({value: campaign.id.toString(), label: campaign.name});

      if (campaign.id.toString() === selectedCampaignId.toString()) {
        this.selectedCampaignId = campaign.id.toString();
        this.form.patchValue({campaign: campaign.id});
      }
    });

    this.updateDomainList();
  }

  protected getFormData() {
    let phone = this.form.value;

    if (!this.addNewAddress) {
      phone.address = phone.address_id;
    }

    return phone;
  }
}
