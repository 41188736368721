import { BaseModel } from './base-model';
import { Address } from './address';
import { Campaign, CampaignProduct } from '.';

/* Checkout Builder */
export interface BuilderConfig {
  build_mode: boolean;
  campaigns: Campaign[];
}

export enum DigitalPayment {
  Google,
  Apple,
  Paypal,
}

enum CardType {
  None,
  Visa,
  Mastercard,
  AmericanExpress,
  Discover,
  Other,
}

interface CheckoutStyles {
  background_color: string;
  primary_text_color: string;
  button_color: string;
  button_text_color: string;
  font_style: string;
  button_style: {
    background_color: string;
    border_color: string;
    border_radius: string;
    color: string;
  };
  options: {
    phone: boolean;
    logo_with_store_name: boolean;
    product_title: boolean;
    secure_payment_text: boolean;
  };
  payment_options: {
    sub_total: boolean;
    tax_support: boolean;
    shipping_fee: boolean;
    payment_details: boolean;
    price_on_button: boolean;
  };
}

interface ButtonControls {
  enable: boolean;
  text: string;
  redirect_url: string;
}

export enum CheckoutReviewStatus {
  None = 0,
  Approved = 1,
  Declined = 2,
  UnderReview = 3,
}

export enum CheckoutStatus {
  Inactive = 0,
  Active = 1,
  Archived = 2,
  Draft = 3,
  Disabled = 4,
}

export enum CheckoutConfirmationControl {
  OrderDetails = 0,
  BillingAddress = 1,
  ShippingAddress = 2,
}

export enum CheckoutField {
  ContactInfo = 0,
  ShippingAddress = 1,
}

export enum CheckoutDesignElement {
  PhoneNumber = 0,
  Logo = 1,
  ProductTitle = 2,
  PriceOnButton = 3,
  TaxSupport = 4,
  ShippingFee = 5,
  PaymentDetails = 6,
  SecurePaymentsText = 7,
}

export interface CheckoutSettings {
  id: number | string | null;
  account: number | string | null;
  account_type: number | null;
  template: CheckoutTemplate;
  campaign: number | string | null;
  name: string;
  country: string;
  main_product: CampaignProduct;
  review_status: CheckoutReviewStatus;
  digital_payment: DigitalPayment[];
  card_types: CardType[];
  discount_code: boolean;
  store_policies: boolean;
  privacy_policy: boolean;
  currency: string;
  related_products: CampaignProduct[];
  card_upsell: CampaignProduct;
  popup_upsell: CampaignProduct;
  fields: CheckoutField[];
  status: CheckoutStatus;
  styles: CheckoutStyles | {};
  elements: CheckoutDesignElement[];
  confirmation_title: string;
  confirmation_message: string;
  confirmation_controls: CheckoutConfirmationControl[];
  cta_controls: ButtonControls | {};
  support_button_controls: ButtonControls | {};
  tracking_pixels: string[];
  google_index: boolean;
  tracking_url_slug: string;
}

export interface CheckoutConfig {
  config: BuilderConfig;
  checkout: CheckoutSettings;
}

export const CheckoutConfigDefaults: CheckoutConfig = {
  config: {
    build_mode: false,
    campaigns: [],
  },
  checkout: {
    id: null,
    account: null,
    account_type: null,
    template: {} as CheckoutTemplate,
    campaign: null,
    name: '',
    country: 'US',
    review_status: CheckoutReviewStatus.None,
    status: CheckoutStatus.Inactive,
    main_product: null,
    digital_payment: [],
    card_types: [],
    discount_code: true,
    store_policies: true,
    privacy_policy: true,
    currency: 'usd',
    related_products: [],
    card_upsell: null,
    popup_upsell: null,
    fields: [CheckoutField.ContactInfo, CheckoutField.ShippingAddress],
    styles: {
      background_color: '#FFFFFF',
      primary_text_color: '#163549',
      button_color: '#4083e9',
      button_text_color: '#FFFFFF',
      font_style: 'Nunito',
      button_style: {
        background_color: '#4083e9',
        border_color: '#ffffff',
        border_radius: '5px',
        color: '#fff',
      },
      options: {
        phone: true,
        logo_with_store_name: true,
        product_title: true,
        secure_payment_text: true,
      },
      payment_options: {
        sub_total: true,
        tax_support: true,
        shipping_fee: true,
        payment_details: true,
        price_on_button: true,
      },
    },
    elements: [CheckoutDesignElement.PhoneNumber],
    confirmation_title: 'Thank you for your purchase!',
    confirmation_message: 'Your order will be processed within the next 24 hours',
    confirmation_controls: [
      CheckoutConfirmationControl.OrderDetails,
      CheckoutConfirmationControl.BillingAddress,
      CheckoutConfirmationControl.ShippingAddress,
    ],
    cta_controls: {
      enable: true,
      text: 'Manage my order',
      redirect_url: '',
    },
    support_button_controls: {
      enable: true,
      text: 'Contact Support',
      redirect_url: '',
    },
    tracking_pixels: [],
    google_index: false,
    tracking_url_slug: '',
  },
};

/* ============================ */

export enum BuilderProductType {
  Main,
  Related,
  Upsell,
  Popup,
}

export interface Checkout extends BaseModel {
  name: string;
  url: string;
  template_type: CheckoutTemplateType;
  account_type: CheckoutAccountType;
  review_status: CheckoutReviewStatus;
  status: CheckoutStatus;
  visitors?: number;
  orders?: number;
  revenue?: string;
  aov?: string;
  cvr?: string;
  updated: string;
  created: string;
  campaign?: number;
  showThreeDotMenu?: boolean;
  deployment_status: DeploymentStatus;
}

export enum DeploymentStatus {
  None = 0,
  Deploying = 1,
  Deployed = 2,
  Error = 3,
}

export const DeploymentStatusLabels = {
  [DeploymentStatus.None]: '--',
  [DeploymentStatus.Deploying]: 'Deploying',
  [DeploymentStatus.Deployed]: 'Deployed',
  [DeploymentStatus.Error]: 'Error',
};

export interface CheckoutTemplate extends BaseModel {
  name: string;
  is_verified: boolean;
  is_visible: boolean;
  type: CheckoutTemplateType;
  thumbnail: string;
  checkout_preview: string;
  confirmation_preview: string;
}

export enum CheckoutGatewayType {
  Unverified = 0,
  Verified = 1,
}

export const CheckoutGatewayTypeLabels = {};
CheckoutGatewayTypeLabels[CheckoutGatewayType.Unverified] = 'Unverified';
CheckoutGatewayTypeLabels[CheckoutGatewayType.Verified] = 'Verified';

export enum CheckoutAccountType {
  Unverified = 0,
  VerifiedWithSpProcessing = 1,
  VerifiedWith3rdPartyProcessing = 2,
}

export const CheckoutAccountTypeLabels = {};
CheckoutAccountTypeLabels[CheckoutAccountType.Unverified] = 'Unverified';
CheckoutAccountTypeLabels[CheckoutAccountType.VerifiedWithSpProcessing] = 'Verified';
CheckoutAccountTypeLabels[CheckoutAccountType.VerifiedWith3rdPartyProcessing] = 'Verified';

export enum CheckoutTemplateType {
  ShoppingCart = 0,
  FreeTrial = 1,
  OneTimePurchase = 2,
  BundlePackage = 3,
  FixedSubscription = 4,
}

export const CheckoutTemplateTypeLabels = {};
CheckoutTemplateTypeLabels[CheckoutTemplateType.ShoppingCart] = 'Shopping Cart';
CheckoutTemplateTypeLabels[CheckoutTemplateType.FreeTrial] = 'Free Trial';
CheckoutTemplateTypeLabels[CheckoutTemplateType.OneTimePurchase] = 'One Time Purchase';
CheckoutTemplateTypeLabels[CheckoutTemplateType.BundlePackage] = 'Bundle Package';
CheckoutTemplateTypeLabels[CheckoutTemplateType.FixedSubscription] = 'Fixed Subscription';

export const CheckoutTemplateTypeDescription = {};
CheckoutTemplateTypeDescription[CheckoutTemplateType.ShoppingCart] =
  'Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.';
CheckoutTemplateTypeDescription[CheckoutTemplateType.FreeTrial] =
  'Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.';
CheckoutTemplateTypeDescription[CheckoutTemplateType.OneTimePurchase] =
  'Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.';
CheckoutTemplateTypeDescription[CheckoutTemplateType.BundlePackage] =
  'Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.';
CheckoutTemplateTypeDescription[CheckoutTemplateType.FixedSubscription] =
  "As an online business owner looking to increase your revenue, selling subscriptions directly from your website can be a game-changer. With custom checkout forms, you don't need any coding skills to get started. You cantailor the checkout experience to your business needs, from pricing to design, making it easy for customers tosubscribe and stay subscribed.";

export const CheckoutReviewStatusTypesLabels = {};
CheckoutReviewStatusTypesLabels[CheckoutReviewStatus.None] = 'None';
CheckoutReviewStatusTypesLabels[CheckoutReviewStatus.Approved] = 'Approved';
CheckoutReviewStatusTypesLabels[CheckoutReviewStatus.Declined] = 'Declined';
CheckoutReviewStatusTypesLabels[CheckoutReviewStatus.UnderReview] = 'Under Review';

export enum CheckoutPopup {
  Create,
  Update,
  Duplicate,
  Archive,
  Delete,
  Unarchive,
}

export const TableStatusLabels = {};
TableStatusLabels[CheckoutStatus.Active] = 'Active';
TableStatusLabels[CheckoutStatus.Inactive] = 'Inactive';
TableStatusLabels[CheckoutStatus.Draft] = 'Draft';
TableStatusLabels[CheckoutStatus.Disabled] = 'Disabled';
TableStatusLabels[CheckoutStatus.Archived] = 'Archived';

export enum CheckoutDateType {
  LastModified = 'lastModified',
  DateCreated = 'dateCreated',
}

export enum ProfileTab {
  Credentials = 0,
  LimitAndFees = 1,
  BankInformation = 2,
  ProcessorInformation = 3,
  ContactInformation = 4,
  Permissions = 5,
  Users = 6,
  Documents = 7,
  Summary = 8,
}

export enum PermissionType {
  Bank = -1,
  BusinessType = 0,
  ProductType = 1,
  CheckoutType = 2,
}

export enum ProfileUserRole {
  SuperAdmin = 0,
  Admin = 1,
  Lead = 2,
  Editor = 3,
  Agent = 4,
}

export enum ProfileUser {
  Bank = 0,
  Processor = 1,
}

export enum BankStatus {
  Archived = -1,
  Inactive = 0,
  Active = 1,
  PendingApproval = 2,
}

export const BankStatusLabels = {};
BankStatusLabels[BankStatus.Archived] = 'Archived';
BankStatusLabels[BankStatus.Inactive] = 'InActive';
BankStatusLabels[BankStatus.Active] = 'Active';
BankStatusLabels[BankStatus.PendingApproval] = 'Pending Approval';

export enum BankAccessLevel {
  All = 0,
}

export const BankAccessLevelLabels = {};
BankAccessLevelLabels[BankAccessLevel.All] = 'All Access';

export const BankAccessLevelsList = [BankAccessLevel.All];

export enum BankContactTitle {
  Owner = 1,
}

export const BankContactTitleLabels = {};
BankContactTitleLabels[BankContactTitle.Owner] = 'Owner';

export const BankContactTitleList = [BankContactTitle.Owner];

export enum BusinessType {
  Automotive = 1,
  Software = 2,
  ConsumerGoods = 3,
  Education = 4,
  Entertainment = 5,
  FinancialServices = 6,
  Food = 7,
  Health = 8,
  IT = 9,
  Nonprofits = 10,
  ProfessionalServices = 11,
  RealEstate = 12,
  Sports = 13,
  Transportation = 14,
  Travel = 15,
  Adults = 16,
  Others = 17,
}

export const BusinessTypesList = [
  BusinessType.Automotive,
  BusinessType.Software,
  BusinessType.ConsumerGoods,
  BusinessType.Education,
  BusinessType.Entertainment,
  BusinessType.FinancialServices,
  BusinessType.Food,
  BusinessType.Health,
  BusinessType.IT,
  BusinessType.Nonprofits,
  BusinessType.ProfessionalServices,
  BusinessType.RealEstate,
  BusinessType.Sports,
  BusinessType.Transportation,
  BusinessType.Travel,
  BusinessType.Adults,
  BusinessType.Others,
];

export const BusinessTypeLabels = {};
BusinessTypeLabels[BusinessType.Automotive] = 'Automotive';
BusinessTypeLabels[BusinessType.Software] = 'Computer Software';
BusinessTypeLabels[BusinessType.ConsumerGoods] = 'Consumer Goods & Services';
BusinessTypeLabels[BusinessType.Education] = 'Education';
BusinessTypeLabels[BusinessType.Entertainment] = 'Entertainment';
BusinessTypeLabels[BusinessType.FinancialServices] = 'Financial Services';
BusinessTypeLabels[BusinessType.Food] = 'Food & Beverage';
BusinessTypeLabels[BusinessType.Health] = 'Health & Wellness';
BusinessTypeLabels[BusinessType.IT] = 'Information Technology';
BusinessTypeLabels[BusinessType.Nonprofits] = 'Nonprofits';
BusinessTypeLabels[BusinessType.ProfessionalServices] = 'Professional Services';
BusinessTypeLabels[BusinessType.RealEstate] = 'Real Estate';
BusinessTypeLabels[BusinessType.Sports] = 'Sports';
BusinessTypeLabels[BusinessType.Transportation] = 'Transportation';
BusinessTypeLabels[BusinessType.Travel] = 'Travel & Hospitality';
BusinessTypeLabels[BusinessType.Adults] = 'Adults';
BusinessTypeLabels[BusinessType.Others] = 'Others';

export enum ProductType {
  Medical = 1,
  Beauty = 2,
  CBD = 3,
  Finance = 4,
  Apparel = 5,
  Others = 6,
}

export const ProductTypesList = [
  ProductType.Medical,
  ProductType.Beauty,
  ProductType.CBD,
  ProductType.Finance,
  ProductType.Apparel,
  ProductType.Others,
];

export const ProductTypeLabels = {};
ProductTypeLabels[ProductType.Medical] = 'Medical';
ProductTypeLabels[ProductType.Beauty] = 'Beauty';
ProductTypeLabels[ProductType.CBD] = 'CBD';
ProductTypeLabels[ProductType.Finance] = 'Finance';
ProductTypeLabels[ProductType.Apparel] = 'Apparel';
ProductTypeLabels[ProductType.Others] = 'Others';

export enum CheckoutType {
  StraightSale = 1,
  StraightSaleWithSubscription = 2,
  Subscription = 3,
  Trial = 4,
  Bundle = 5,
  SubscriptionAndSave = 6,
}

export const CheckoutTypesList = [
  CheckoutType.StraightSale,
  CheckoutType.StraightSaleWithSubscription,
  CheckoutType.Subscription,
  CheckoutType.Trial,
  CheckoutType.Bundle,
  CheckoutType.SubscriptionAndSave,
];

export const CheckoutTypeLabels = {};
CheckoutTypeLabels[CheckoutType.StraightSale] = 'Straight Sale';
CheckoutTypeLabels[CheckoutType.StraightSaleWithSubscription] = 'Straight Sale with Subscription';
CheckoutTypeLabels[CheckoutType.Subscription] = 'Subscription';
CheckoutTypeLabels[CheckoutType.Trial] = 'Trial';
CheckoutTypeLabels[CheckoutType.Bundle] = 'Bundle';
CheckoutTypeLabels[CheckoutType.SubscriptionAndSave] = 'Subscription And Save';

export interface Contact extends BaseModel {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  access_level: BankAccessLevel;
  contact_title: BankContactTitle;
}

interface BankBase extends Address {
  name: string;
  phone: string;
  fax: string;
  email: string;
  mcc_code_type: string;
  risk_contact: string;
  primary_contact: Contact;
  exclude_types: boolean;
  business_types: BusinessType[];
  product_types: ProductType[];
  checkout_types: CheckoutType[];
  about: string;
  status: BankStatus;
  is_deleted: Boolean;
}

export interface Bank extends BankBase {
  bid: number;
}

export interface Processor extends BankBase {
  acquiring_banks: string[] | number[];
  documents: ProcessorDocument[];
}

export enum GatewayStatus {
  Archived = -1,
  Inactive = 0,
  Active = 1,
  Pending = 2,
  Suspended = 3,
  Declined = 4,
}

export const GatewayStatusLabels = {};
GatewayStatusLabels[GatewayStatus.Archived] = 'Archived';
GatewayStatusLabels[GatewayStatus.Inactive] = 'Inactive';
GatewayStatusLabels[GatewayStatus.Active] = 'Active';
GatewayStatusLabels[GatewayStatus.Pending] = 'Under Review';
GatewayStatusLabels[GatewayStatus.Suspended] = 'Suspended';
GatewayStatusLabels[GatewayStatus.Declined] = 'Declined';

export enum GatewayAdditionalOptions {
  IncludeRefunds = 1,
  BlockPrepaidCards = 2,
  BlockDebitCards = 3,
}

export const GatewayAdditionalOptionsList = [
  GatewayAdditionalOptions.IncludeRefunds,
  GatewayAdditionalOptions.BlockPrepaidCards,
  GatewayAdditionalOptions.BlockDebitCards,
];

export const GatewayAdditionalOptionLabels = {};
GatewayAdditionalOptionLabels[GatewayAdditionalOptions.IncludeRefunds] = 'Include Refunds';
GatewayAdditionalOptionLabels[GatewayAdditionalOptions.BlockPrepaidCards] = 'Block Prepaid Cards';
GatewayAdditionalOptionLabels[GatewayAdditionalOptions.BlockDebitCards] = 'Block Debit Cards';

export interface Gateway extends BaseModel {
  name: string;
  is_internal: boolean;
  status: GatewayStatus;
  bank: string | number;
  processor: string | number;
  descriptor: string;
  merchant_id: string;
  descriptor_phone_number: string;
  customer_service_email: string;
  customer_chargeback_number: string;
  url: string;
  merchant_category_code: string;
  acquirer_bin: string;
  api_login_id: string;
  api_transaction_key: string;
  currency: string;
  agent_bank_number: string;
  agent_chain_number: string;
  store_number: string;
  terminal_number: string;
  monthly_cap: string;
  transaction_fee: string;
  chargeback_fee: string;
  refund_processing_fee: string;
  verifi_cdrn: string;
  verifi_rdr: string;
  verifi_order: string;
  ethica_alert_fee: string;
  discount_rate: string;
  additional_options: GatewayAdditionalOptions[];
  processor_name: string;
  bank_name: string;
  is_deleted: boolean;
}

export enum ProcessorDocumentType {
  AgreementStatement = 1,
  FulfillmentAgreement = 2,
}

export const ProcessorDocumentTypeLabels = {};
ProcessorDocumentTypeLabels[ProcessorDocumentType.AgreementStatement] = 'Agreement Statement';
ProcessorDocumentTypeLabels[ProcessorDocumentType.FulfillmentAgreement] = 'Fulfillment Agreement';

export const ProcessorDocumentTypeList = [
  ProcessorDocumentType.AgreementStatement,
  ProcessorDocumentType.FulfillmentAgreement,
];

export enum ProcessorDocumentStatus {
  Pending = 0,
  Approved = 1,
  Suspended = 2,
}

export const ProcessorDocumentStatusLabels = {};
ProcessorDocumentStatusLabels[ProcessorDocumentStatus.Pending] = 'Under Review';
ProcessorDocumentStatusLabels[ProcessorDocumentStatus.Approved] = 'Approved';
ProcessorDocumentStatusLabels[ProcessorDocumentStatus.Suspended] = 'Suspended';

export const ProcessorDocumentStatusList = [
  ProcessorDocumentStatus.Pending,
  ProcessorDocumentStatus.Approved,
  ProcessorDocumentStatus.Suspended,
];

export interface ProcessorDocument extends BaseModel {
  name: string;
  file: { url: string; size: number };
  document_type: ProcessorDocumentType;
  description: string;
  status: ProcessorDocumentStatus;
  created: string;
  pre_signed_url: {};
}

export const FieldsToDisable = [
  'merchant_id',
  'processor',
  'bank',
  'merchant_category_code',
  'acquirer_bin',
  'api_login_id',
  'api_transaction_key',
];

export enum BankInfoFields {
  Name = 'name',
  Address = 'address1',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  Phone = 'phone',
  Email = 'email',
  BID = 'bid',
}

export enum ProcessorInfoFields {
  Name = 'name',
  Address = 'address1',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  Phone = 'phone',
  Email = 'email',
  AcquiringBank = 'acquiring_banks',
}

export enum ContactInfoFields {
  FirstName = 'primary_contact.first_name',
  LastName = 'primary_contact.last_name',
  Email = 'primary_contact.email',
  Phone = 'primary_contact.phone',
  RiskContact = 'risk_contact',
}

export enum CredentialsFields {
  Name = 'name',
  Bank = 'bank',
  Processor = 'processor',
  ApiLogin = 'api_login_id',
  ApiTransaction = 'api_transaction_key',
}

export enum AllowedDocumentTypes {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ZIP = 'application/x-zip-compressed',
}

export const CheckoutMerchantPopupText = {
  VerifiedText:
    'Access a curated gallery of high converting checkouts, verified and approved by card brands. Enjoy higher approval rates and a better bottom line.',
  UnverifiedText:
    'Non verified simply means the checkouts are not considered verified and controlled. You have more flexibility to edit as you wish. Get full access to a curated gallery of checkout and',
};

export const TemplateTypeMap: { [key: number]: string } = {
  [CheckoutTemplateType.ShoppingCart]: 'Cart',
  [CheckoutTemplateType.FreeTrial]: 'Free-Trial',
  [CheckoutTemplateType.OneTimePurchase]: 'One-Time',
  [CheckoutTemplateType.BundlePackage]: 'Bundle',
  [CheckoutTemplateType.FixedSubscription]: 'Subscription',
};

export const CheckoutPopupTitleMap: { [key: number]: string } = {
  [CheckoutPopup.Create]: 'Create New Checkout',
  [CheckoutPopup.Update]: 'Rename Checkout',
  [CheckoutPopup.Duplicate]: 'Duplicate Checkout',
  [CheckoutPopup.Archive]: 'Archive Checkout',
  [CheckoutPopup.Unarchive]: 'Unarchive Checkout',
};

export const UpsellPopupTitleMap: { [key: number]: string } = {
  [CheckoutPopup.Create]: 'Create New Upsell',
  [CheckoutPopup.Update]: 'Rename Upsell',
  [CheckoutPopup.Duplicate]: 'Duplicate Upsell',
  [CheckoutPopup.Delete]: 'Delete Upsell',
};

export const PopupActionMap: { [key: number]: string } = {
  [CheckoutPopup.Update]: 'Updated',
  [CheckoutPopup.Duplicate]: 'Duplicated',
  [CheckoutPopup.Delete]: 'Deleted',
  [CheckoutPopup.Archive]: 'Archived',
  [CheckoutPopup.Unarchive]: 'Unarchived',
};
