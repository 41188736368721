import { SideNavService } from "./../_services/sidenav.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  AccountService,
  AlertService,
  ReportsConfigService,
  StorageService,
  UserService,
} from "../_services";
import { Pager, User, SessionType, FunnelType, Account } from "../_models";
import { Form } from "../_forms";
import { mergeMap, takeUntil } from "rxjs/operators";
import { ReportsUserConfiguration } from "../_models/reports";
import { ReportTypes } from "../reports/models/reports";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent extends Form implements OnInit {
  user: User;

  public isExpanded: boolean = window.outerWidth > 1183;
  public reportsUserConfig: ReportsUserConfiguration[] = [];
  public legacyReportsUserConfig: ReportsUserConfiguration[] = [];
  public legacyReportsGlobalConfig: ReportsUserConfiguration[] = [];
  public isReportsConfigLoading: boolean = true;
  selectedDropdown = "";

  reportsLinkConfig = [
    { report_type: ReportTypes.REFUND, routerLink: "/refund-reports/" },
    {
      report_type: ReportTypes.PAYMENT_UPDATER,
      routerLink: "/payment-updater-reports/",
    },
    {
      report_type: ReportTypes.RETURN_RETENTION,
      routerLink: "/return-retention-reports/",
    },
    {
      report_type: ReportTypes.ORDER_RETENTION,
      routerLink: "/new-order-retention-reports/",
    },
    {
      report_type: ReportTypes.SUBSCRIPTION_RETENTION,
      routerLink: "/subscription-retention-reports/",
    },
    { report_type: ReportTypes.CHARGEBACK, routerLink: "/chargeback-reports/" },
    {
      report_type: ReportTypes.SP_CHARGEBACK,
      routerLink: "/sp-touched-reports/",
    },
    {
      report_type: ReportTypes.CHATBOT,
      routerLink: "/chatbot-reports/",
    },
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private userService: UserService,
    protected sideNavService: SideNavService,
    protected reportsConfigService: ReportsConfigService,
    private storageService: StorageService,
    protected accountService: AccountService
  ) {
    super(alertService, router, location);
  }

  dropdownStylesApplied: string = "";
  subDropdownStylesApplied: string = "";

  ngOnInit() {
    super.ngOnInit();
    this.sideNavService.setSideNavExpanded(this.isExpanded);

    this.userService
      .getCurrent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.user = user;

          this.reportsConfigService
            .list({})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (data: Pager) => {
                this.reportsConfigService._reportConfigSubject.next(
                  data.results
                );
              },
              (error) => {
                this.isReportsConfigLoading = false;
                this.alertService.error(error.message);
              }
            );

          this.reportsConfigService.reportConfig$.subscribe((configs) => {
            if (configs.length) {
              this.isReportsConfigLoading = true;
              this.legacyReportsGlobalConfig = [];
              this.reportsUserConfig = [];
              this.legacyReportsUserConfig = [];

              configs.forEach((config: ReportsUserConfiguration) => {
                if (
                  this.user &&
                  config.user === this.user.id &&
                  !config.is_global
                ) {
                  if (config.legacy) {
                    this.legacyReportsUserConfig.push(config);
                  } else {
                    config.link = this.reportsLinkConfig.find(
                      (x) => x.report_type === config.report_type
                    ).routerLink;
                    this.reportsUserConfig.push(config);
                  }
                } else if (config.is_global && config.legacy) {
                  this.legacyReportsGlobalConfig.push(config);
                }
              });
              this.isReportsConfigLoading = false;
            }
          });
        },
        (error) => {
          this.alertService.error(error.message);
        }
      );

    this.sideNavService.getSideNavObs().subscribe((data) => {
      if ("isSideNavExpanded" in data) {
        this.isExpanded = data.isSideNavExpanded;
      }
    });

    this.accountService.selectedAccount$.subscribe((account: Account) => {
      if (this.user) {
        this.user.account = account;
      }
    });
  }

  toggleDropdownStyles(dropdownId: string) {
    this.subDropdownStylesApplied = "";
    if (this.dropdownStylesApplied === dropdownId) {
      this.selectedDropdown = "";
      this.dropdownStylesApplied = "";
    } else {
      this.dropdownStylesApplied = dropdownId;
      this.selectedDropdown = dropdownId;
    }
  }

  closeSubDropdown() {
    this.subDropdownStylesApplied = "";
  }

  toggleSubDropdownStyles(dropdownId: string) {
    if (this.subDropdownStylesApplied === dropdownId) {
      this.subDropdownStylesApplied = "";
    } else {
      this.subDropdownStylesApplied = dropdownId;
    }
  }

  onDropdownClick(event: Event) {
    event.stopPropagation();
  }

  isProfilerEnabled() {
    return this.storageService.get("profilerEnabled");
  }

  isCProfilerEnabled() {
    return this.storageService.get("cprofilerEnabled");
  }

  closeDropdown() {
    this.selectedDropdown = "";
    this.dropdownStylesApplied = "";
    this.subDropdownStylesApplied = "";
  }

  outSideClick() {
    if (!this.isExpanded) {
      this.selectedDropdown = "";
      this.dropdownStylesApplied = "";
      this.subDropdownStylesApplied = "";
    }
  }
  get marketplaceParams() {
    return {
      resourcetype: FunnelType.Visual,
      session_type: SessionType.Marketplace,
    };
  }

  get paymentUpdaterParams() {
    return {
      resourcetype: FunnelType.Visual,
      session_type: SessionType.PaymentUpdate,
    };
  }
}
