import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignTrackingService} from '../_services';
import {TrackingSource} from '../_models';
import {CrudListComponent} from '../_directives';
import {Observable} from "rxjs";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-tracking.component.html'
})
export class CampaignTrackingComponent extends CrudListComponent implements OnInit {
  sources$: Observable<TrackingSource[]> = this.data$;
  campaignId: string | number = null;
  selectedSource: TrackingSource;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected trackingService: CampaignTrackingService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, trackingService, alertService);
    this.objectName = 'tracking source';
    this.title = 'Brand Tracking Sources';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected runInitialQuery() {
    // don't run the initial query - wait for filter event
  }

  protected onRouteParams(params: {}) {
    if ('campaign' in params) {
      this.campaignId = params['campaign']
    }
  }

  create() {
    this.selectedSource = {
      id: null,
      campaign: this.campaignId,
      source: null
    };
    this.openEditDialog();
  }

  edit(source: TrackingSource) {
    this.selectedSource = source;
    this.openEditDialog();
  }

  protected handleError(error: any) {
    if (error.status === 409) { // this should only happen on delete
      this.alertService.error("Tracking source is in use by a session so it can't be deleted.");
    }
    else {
      super.handleError(error);
    }
  }

  protected openEditDialog() {
    this.modalService.getModal('trackingSourceDialog').open();
  }

  onSaveSource(source: TrackingSource) {
    this.modalService.getModal('trackingSourceDialog').close();
    this.queryData();
  }
}
