import { PaginationService } from "./pagination.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

export abstract class ReportsAbstractService extends PaginationService {
  public reportRoute: string = "";
  protected customerMode: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
    
  constructor(protected http: HttpClient) {
    super(http, "");
    this.route = this.reportRoute;
  }

  getFilteredReport(params: any, route: string = ""): Observable<any> {
    const targetRoute = route ? "/" + route + "/" : "/filtered_report/";
    return this.http.post<any>(this.route + targetRoute, params);
  }

  getDownloadReportData(params: any): Observable<any> {
    return this.http.post<any>(this.route + "/download_report/", params);
  }

  getCancelReasons(queryParams: any): Observable<any> {
    let params = new HttpParams({ fromObject: queryParams });
    return this.http.get(this.route + "/cancel_reasons/", { params: params });
  }

  getProducts(queryParams: any): Observable<any> {
    let params = new HttpParams({ fromObject: queryParams });
    return this.http.get("campaign_products/", { params: params });
  }

  getProductPaths(queryParams: any): Observable<any> {
    let params = new HttpParams({ fromObject: queryParams });
    return this.http.get(this.route + "/product_paths/", { params: params });
  }

  getStepNames(queryParams: any): Observable<any> {
    let params = new HttpParams({ fromObject: queryParams });
    return this.http.get(this.route + "/step_names/", { params: params });
  }

  getInputNames(queryParams: any): Observable<any> {
    let params = new HttpParams({ fromObject: queryParams });
    return this.http.get(this.route + "/input_names/", { params: params });
  }

  getMerchants(queryParams: any): Observable<any> {
    let params = new HttpParams({ fromObject: queryParams });
    return this.http.get(this.route + "/merchants/", { params: params });
  }

  saveReport(params: any): Observable<any> {
    return this.http.post<any>("reports_config/", params);
  }

  updateReport(id: string, params: any): Observable<any> {
    return this.http.put<any>(`reports_config/${id}/`, params);
  }

  deleteReport(id: string): Observable<any> {
    return this.http.delete<any>(`reports_config/${id}/`);
  }

  downloadFile(queryParams, elem) {
    this.getDownloadReportData(queryParams).subscribe(
      (data) => {
        let dwldLink = document.createElement("a");
        dwldLink.setAttribute("href", data.download_url);
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
        elem.loading = false;
      },
      (error) => {
        elem.loading = false;
        if (error.status == 404) {
          elem.alertService.error("No reports to download");
          return;
        }
        elem.alertService.error("An error occurred while downloading report");
      }
    );
  }

  selectCustomerMode(value) {
    this.customerMode.next(value === "customer");
  }
}
