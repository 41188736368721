import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../_services';
import {ImageSize, Product, Variant, VariantOption} from "../_models";
import {Form} from '../_forms';
import {NgxSmartModalService} from "ngx-smart-modal";
import {getProductImageUrl} from "../_helpers";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './variant.component.html',
  selector: 'variants',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class VariantComponent extends Form implements OnInit, OnChanges {
  variantOptions: VariantOption[] = [];
  selectedVariantIndex: number;
  selectedVariant: Variant;

  @Input('product') product: Product;

  constructor(
    protected router: Router,
    protected location: Location,
    private modalService: NgxSmartModalService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.updateForm();
    super.ngOnInit();
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm() {
    let options = [];

    if (this.product && this.product.variants && this.product.variants.length) {
      options = this.product.variants[0].options;
    }

    this.variantOptions = options;
  }

  getVariantImageUrl(variant: Variant) {
    if (variant.image) {
      if (variant.image.file) {
        if (variant.image.file[ImageSize.original]) {
          return variant.image.file[ImageSize.original];
        } else if (typeof variant.image.file === 'string'){
          return variant.image.file;
        }
      }
    }
    return getProductImageUrl(this.product, ImageSize.original);
  }

  edit(variantIndex: number) {
    this.selectedVariantIndex = variantIndex;
    this.selectedVariant = this.product.variants[this.selectedVariantIndex];
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('variantDialog').open();
  }

  onSaveVariant(variant: Variant) {
    this.modalService.getModal('variantDialog').close();
    if (variant.image && this.selectedVariant['selectedImage']) {
      if (typeof variant.image !== 'object') {
        variant.image = {id: variant.image } as any;
      }
      variant.image.file = this.selectedVariant['selectedImage'];
    }
    Object.assign(this.product.variants[this.selectedVariantIndex], variant);
    this.selectedVariant = null;
  }
}
