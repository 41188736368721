import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, UserService, AccountService, PaymentSourceService, PlanService } from '../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { Form } from '../_forms';
import { User, Plan, PaymentSource } from '../_models';
import { config } from '../../config/config';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './subscription-edit.component.html'
})
export class SubscriptionEditComponent extends Form implements OnInit {
  loading = false;
  plans: Plan[];
  cards: PaymentSource[];
  sIndex = null;
  user: User;

  setIndex(index: number) {
    this.sIndex = index;
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    private userService: UserService,
    private accountService: AccountService,
    private paymentService: PaymentSourceService,
    private planService: PlanService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
    this.title = 'Change your subscription';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      plan: [null, Validators.required],
      payment_source: [null, Validators.required]
    });

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
          this.form.patchValue({
            plan: user.account.plan ? user.account.plan.id : null,
            payment_source: user.account.default_payment_source ? user.account.default_payment_source : null
          });
        },
        error => {
          this.handleError(error);
        }
      )

    this.planService.list({is_active: true})
      .subscribe(
        (plans: Plan[]) => {
          this.plans = plans;
        },
        error => {
          this.handleError(error);
        }
      )

    this.paymentService.list({page: 1, page_size: config.maxPageSize})
      .subscribe(
        data => {
          this.cards = data.results;
        },
        error => {
          this.handleError(error);
        }
      )
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      this.accountService.patch(this.user.account.id,
        {plan: this.form.value.plan, default_payment_source: this.form.value.payment_source})
        .subscribe(
          data => {
            this.alertService.success('Subscription changed.', true);
            this.userService.clearCache();
            this.loading = false;
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }
}
