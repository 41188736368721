import { CrudPagedListComponent } from '../_directives';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, FunnelService, LoaderService, PusherService } from '../_services';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {
  Path,
  PathDateTypes,
  PathPopup,
  PathTableTypes,
  PathTableStatusLabels,
  PathTableStatuses,
  OfferIntentTypeLabels,
} from '../_models/funnel';
import { PathService } from '../_services/path.service';
import { Audience, NotificationType, OfferIntents, OfferTypes, Pager, OfferTypeLabels } from '../_models';
import { AudienceService } from '../_services/audience.service';
import { config } from '../../config/config';
import { steps } from '../steps-library/step-util';
import { Filter } from '../_shared';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss'],
  selector: 'app-path-list',
})
export class PathComponent extends CrudPagedListComponent implements OnInit {
  pathsList: Path[] = [];
  pageTitle: string = 'Customer Paths';
  offerIntentOptions: { value: string; label: string }[] = [];
  showModal: boolean = false;
  pathStatus = PathTableStatuses;
  pathStatusLabels = PathTableStatuses;
  pathDateTypes = PathDateTypes;
  pathTableTypes = PathTableTypes;
  offerIntents = OfferIntents;
  offerIntentLabels = OfferIntentTypeLabels;
  showThreeDotMenu: boolean = false;
  selectedRow: number = -1;
  selectedStatus: string = PathTableStatusLabels[PathTableStatuses.All];
  selectedFilter: string = 'name';

  selectedDate: string = PathDateTypes.LastModified;
  searchQuery: string;
  showAddButton: boolean = true;
  showViewButton: boolean = true;
  selectedIndex: number | null = null;
  tableData: any[] = [];
  popupData = {
    name: '',
    path_id: null,
    popupType: null,
    offer_intent: OfferIntents.CancelOrder,
    path_steps: [],
    path_audience: null,
    offer_type_options: [],
  };
  originalTableData: any[] = [...this.tableData];
  isLoading = false;
  pageSize = 20;
  PathPopup = PathPopup;
  popupTitle: string = '';
  filters: Filter[] = [
    {
      name: 'Status',
      values: [
        { key: PathTableStatuses.Active, value: PathTableStatusLabels[PathTableStatuses.Active] },
        { key: PathTableStatuses.Inactive, value: PathTableStatusLabels[PathTableStatuses.Inactive] },
        { key: PathTableStatuses.Draft, value: PathTableStatusLabels[PathTableStatuses.Draft] },
      ],
      selectedValues: [],
      showSubPopup: false,
    },
    {
      name: 'Type',
      values: [
        { key: OfferIntents.CancelOrder, value: OfferIntentTypeLabels[OfferIntents.CancelOrder] },
        { key: OfferIntents.CancelSubscriptionHold, value: OfferIntentTypeLabels[OfferIntents.CancelSubscriptionHold] },
        { key: OfferIntents.CancelTrialHold, value: OfferIntentTypeLabels[OfferIntents.CancelTrialHold] },
        { key: OfferIntents.CancelSubscription, value: OfferIntentTypeLabels[OfferIntents.CancelSubscription] },
        { key: OfferIntents.CancelTrial, value: OfferIntentTypeLabels[OfferIntents.CancelTrial] },
        { key: OfferIntents.ReturnOrder, value: OfferIntentTypeLabels[OfferIntents.ReturnOrder] },
        { key: OfferIntents.ReturnSubscription, value: OfferIntentTypeLabels[OfferIntents.ReturnSubscription] },
        { key: OfferIntents.ReturnTrial, value: OfferIntentTypeLabels[OfferIntents.ReturnTrial] },
      ],
      selectedValues: [],
      showSubPopup: false,
    },
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected pathService: PathService,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected loader: LoaderService,
    protected ngxSmartModalService: NgxSmartModalService,
    protected audienceService: AudienceService,
    protected pusherService: PusherService
  ) {
    super(router, location, route, pathService, alertService);
    this.objectName = 'Path';
  }
  ngOnInit() {
    super.ngOnInit();
    this.loader.show();
    this.clearFilter();
    this.queryData();
    this.offerIntentOptions = this.getOfferIntentOptions();

    if (this.pusherService.channel) {
      this.pusherService.channel.bind(NotificationType[NotificationType.PATH_PUBLISHING], (data: any) => {
        data = data.payload;
        if (data && data.is_completed) {
          this.queryData();
        }
      });
    }
  }

  queryData() {
    let params = Object.assign({}, this.getQueryFilter(), { page: this.page, page_size: this.pageSize });
    this.loader.show();
    this.pathService.list(params).subscribe(
      (response) => {
        this.loader.hide();
        this.pathsList = response.results;
        this.setPagerData(response);
        this.tableData = response.results.map((item: Path) => {
          const lastModifiedMoment = moment(item.last_modified).tz('EST');

          const lastModified = {
            time: lastModifiedMoment.format('hh:mm A'),
            date: lastModifiedMoment.format('MM/DD/YYYY'),
          };

          return {
            id: item.id,
            displayId: item.display_id,
            pathName: item.name,
            offerIntent: item.offer_intent,
            allSteps: item.steps,
            type: item.offer_intent || '--',
            status: !item.is_modified ? PathTableStatuses.Active : PathTableStatuses.Draft,
            audience: item.audience || null,
            steps: item.steps || [],
            lastModified,
            showAllConditions: item.steps.length ? false : true,
          };
        });
        this.originalTableData = this.tableData;
        this.filterTableData();
      },
      (error) => {
        this.loader.hide();
        this.alertService.error(error.message);
      }
    );
  }

  createPath() {
    this.popupTitle = 'Create Path';

    this.popupData = {
      ...this.popupData,
      name: '',
      path_id: null,
      popupType: PathPopup.Create,
      offer_intent: null,
      path_steps: [],
      offer_type_options: this.offerIntentOptions,
    };
    this.openPopup();
  }

  editPath(id: number) {
    this.router.navigate(['new-paths', 'edit', id]);
  }

  navigateToAudience(audienceId: number) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['audiences', 'edit', audienceId]));
    window.open(url, '_blank');
  }

  isStepTypeValid(type: number): boolean {
    return (
      !!steps.find((s) => s.type === type) ||
      [OfferTypes.Downsell, OfferTypes.Upsell, OfferTypes.Support].includes(type)
    );
  }

  getOfferIntentOptions() {
    return Object.keys(OfferIntentTypeLabels)
      .filter((intentType) => parseInt(intentType, 10) !== OfferIntents.Legacy)
      .map((intentType) => ({
        value: intentType,
        label: OfferIntentTypeLabels[intentType],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  navigateToStep(type: number, stepId: number): void {
    const step = steps.find((s) => s.type === type);

    if (step) {
      const offerType = step.offer_type;

      if (offerType === OfferTypes.Survey) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['steps', 'survey', 'category', offerType, type, 'step', stepId])
        );
        window.open(url, '_blank');
      }
    } else if ([OfferTypes.Downsell, OfferTypes.Upsell, OfferTypes.Support].includes(type)) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['steps', OfferTypeLabels[type], type, 'details', stepId])
      );
      window.open(url, '_blank');
    }
  }

  openPathPopup(
    popupType: number,
    pathId?: number | string,
    pathName?: string,
    offerIntent?: number,
    pathSteps?: { id: number; name: string }[] | null,
    pathAudience?: string | null
  ): void {
    switch (popupType) {
      case PathPopup.Update:
        this.popupTitle = 'Rename Path';
        break;
      case PathPopup.Duplicate:
        this.popupTitle = 'Duplicate Path';
        break;
      case PathPopup.Delete:
        this.popupTitle = 'Delete Path';
        break;
    }

    if (pathAudience) {
      this.audienceService.list({ page: 1, page_size: config.maxPageSize }).subscribe(
        (result: Pager) => {
          const audienceObject = result.results.find((audience: Audience) => audience.name === pathAudience);
          this.popupData.path_audience = audienceObject.id || null;
        },
        (error) => {
          this.handleError(error);
        }
      );
    } else {
      this.popupData.path_audience = null;
    }

    this.popupData = {
      ...this.popupData,
      name: pathName || '',
      path_id: pathId || null,
      popupType: popupType,
      offer_intent: offerIntent,
      path_steps: pathSteps && pathSteps.length ? pathSteps : [],
    };

    this.openPopup();
  }

  openPopup() {
    this.ngxSmartModalService.getModal('pathPopup').open();
  }
  closePathPopup() {
    this.ngxSmartModalService.getModal('pathPopup').close();
  }

  savePath(data: { isNew: boolean; id: number }) {
    this.ngxSmartModalService.getModal('pathPopup').close();

    if (data.isNew) {
      this.router.navigate(['new-paths', 'edit', data.id]);
    } else {
      this.queryData();
    }
  }

  filterTableData() {
    const filteredData = [...this.originalTableData];
    switch (this.selectedFilter) {
      case 'name':
        filteredData.sort((a, b) => a.pathName.localeCompare(b.pathName));
        break;
      case 'type':
        filteredData.sort((a, b) => a.type.localeCompare(b.type));
        break;
      case 'status':
        filteredData.sort((a, b) => a.status.localeCompare(b.status));
        break;
      case 'audience':
        filteredData.sort((a, b) => a.audience.localeCompare(b.audience));
        break;
      case 'lastModified':
        this.selectedDate = 'lastModified';
        filteredData.sort((a, b) => {
          const dateA = moment(a.lastModified.date + ' ' + a.lastModified.time, 'DD MMM, YYYY HH:mm');
          const dateB = moment(b.lastModified.date + ' ' + b.lastModified.time, 'DD MMM, YYYY HH:mm');
          return dateB.diff(dateA);
        });
        break;
      default:
        break;
    }
    this.tableData = filteredData;
  }

  searchData() {
    !this.searchQuery ? this.clearFilter() : this.setFilter({ name__icontains: this.searchQuery });
  }
  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }
  toggleShowAllConditions(item: any): void {
    item.showAllConditions = !item.showAllConditions;
  }

  togglePathList(index: number): void {
    if (this.selectedRow === index) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = index;
    }
  }

  onFilterChanged(event: { filterName: string; selectedValues: string[] }): void {
    const { filterName, selectedValues } = event;
    let filteredData = [...this.originalTableData];

    switch (filterName) {
      case 'Status':
        filteredData =
          selectedValues.length === 0 || selectedValues.includes(null)
            ? [...this.originalTableData]
            : this.originalTableData.filter((item) => selectedValues.includes(item.status));
        break;

      case 'Type':
        filteredData =
          selectedValues.length === 0 || selectedValues.includes(null)
            ? [...this.originalTableData]
            : this.originalTableData.filter((item) => selectedValues.includes(item.type));
        break;
    }

    this.tableData = filteredData;
  }

  closePathList() {
    this.selectedRow = -1;
    this.tableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }

  isCancelType(type: OfferIntents) {
    return (
      [
        OfferIntents.CancelOrder,
        OfferIntents.CancelSubscription,
        OfferIntents.CancelSubscriptionHold,
        OfferIntents.CancelTrial,
        OfferIntents.CancelTrialHold,
      ].indexOf(type) > -1
    );
  }

  isReturnType(type: OfferIntents) {
    return [OfferIntents.ReturnOrder, OfferIntents.ReturnTrial, OfferIntents.ReturnSubscription].indexOf(type) > -1;
  }

  popupIconMap: Partial<Record<PathPopup, string>> = {
    [PathPopup.Create]: '/assets/stepAssets/create-icon.svg',
    [PathPopup.Delete]: '/assets/stepAssets/delete-icon.svg',
    [PathPopup.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [PathPopup.Update]: '/assets/stepAssets/rename.svg',
  };
}
