import {insertVariableCommand} from '../commands';
import grapesjs from 'grapesjs';

export default grapesjs.plugins.add('insertVariables', (editor, options) => {
  // proper way to add button into above options bar.
  editor.Panels.addButton('options', {
    id: 'insert',
    className: 'fa fa-subscript',
    context: 'insert-variable',
    command: insertVariableCommand,
    attributes: {
      'title': 'Insert Variable',
      'data-tooltip-pos': 'bottom',
      'id': 'insert-variable-id',
      'opt': options.variables
    },
  });
});
