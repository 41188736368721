export const fontFamilies = [
  { name: 'Open Sans', value: 'Open Sans, sans-serif' },
  { name: 'Arial', value: 'Arial, Helvetica, sans-serif' },
  { name: 'Arial Black', value: 'Arial Black, Gadget, sans-serif' },
  { name: 'Brush Script MT', value: 'Brush Script MT, sans-serif' },
  { name: 'Comic Sans MS', value: 'Comic Sans MS, cursive, sans-serif' },
  { name: 'Courier New', value: 'Courier New, Courier, monospace' },
  { name: 'Georgia', value: 'Georgia, serif' },
  { name: 'Helvetica', value: 'Helvetica, sans-serif' },
  { name: 'Impact', value: 'Impact, Charcoal, sans-serif' },
  { name: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode, Lucida Grande, sans-serif' },
  { name: 'Montserrat', value: 'Montserrat, sans-serif' },
  { name: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
  { name: 'Times New Roman', value: 'Times New Roman, Times, serif' },
  { name: 'Trebuchet MS', value: 'Trebuchet MS, Helvetica, sans-serif' },
  { name: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
  { name: 'Ubuntu', value: 'Ubuntu, sans-serif'}
];

export const DEFAULT_THEME_FONT_FAMILY = 'Ubuntu, sans-serif';

export function getFontFamilies(key = 'text') {
  return fontFamilies.map((fontFamily) => {
    return {[key]: fontFamily.name, value: fontFamily.value}
  });
}

export const customStyleProps = [{
  name: 'General',
  open: false,
  buildProps: [
    'float', 'display', 'position', 'top', 'right', 'left', 'bottom', 'flex-direction',
    'flex-wrap', 'justify-content', 'align-items', 'align-content', 'order', 'flex-basis',
    'flex-grow', 'flex-shrink', 'align-self'
  ],
  properties: [
    {
      name: 'Alignment',
      property: 'float',
      type: 'radio',
      defaults: 'none',
      list: [
        { value: 'none', className: 'fa fa-times'},
        { value: 'left', className: 'fa fa-align-left'},
        { value: 'right', className: 'fa fa-align-right'}
      ],
    },
    { property: 'position', type: 'select'},
    {
      type: 'select',
      property: 'display',
      properties: [
        { name: 'block', value: 'block'},
        { name: 'inline-block', property: 'inline-block'},
        { name: 'Bottom', property: 'margin-bottom'},
        { name: 'Left', property: 'margin-left'}
      ],
    }
  ],
}, {
  name: 'Dimension',
  open: false,
  buildProps: ['width', 'flex-width', 'height', 'max-width', 'min-width', 'min-height', 'max-height', 'margin', 'padding'],
  properties: [{
    id: 'flex-width',
    type: 'integer',
    name: 'Width',
    units: ['px', '%'],
    property: 'flex-basis',
    toRequire: 1,
  }, {
    property: 'margin',
    properties: [
      { name: 'Top', property: 'margin-top'},
      { name: 'Right', property: 'margin-right'},
      { name: 'Bottom', property: 'margin-bottom'},
      { name: 'Left', property: 'margin-left'}
    ],
  }, {
    property  : 'padding',
    properties: [
      { name: 'Top', property: 'padding-top'},
      { name: 'Right', property: 'padding-right'},
      { name: 'Bottom', property: 'padding-bottom'},
      { name: 'Left', property: 'padding-left'}
    ],
  }],
}, {
  name: 'Typography',
  open: false,
  buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-decoration', 'text-shadow', 'vertical-align'],
  properties: [
    {
      name: 'Font', property: 'font-family',
      properties: fontFamilies
    },
    { name: 'Weight', property: 'font-weight'},
    { name: 'Font color', property: 'color'},
    {
      property: 'text-align',
      type: 'radio',
      defaults: 'left',
      list: [
        { value : 'left',  name : 'Left',    className: 'fa fa-align-left'},
        { value : 'center',  name : 'Center',  className: 'fa fa-align-center' },
        { value : 'right',   name : 'Right',   className: 'fa fa-align-right'},
        { value : 'justify', name : 'Justify',   className: 'fa fa-align-justify'}
      ],
    },{
      property: 'vertical-align',
      name: 'Vertical Align',
      type: 'radio',
      defaults: 'flex-start',
      list: [
        { value : 'flex-start',  name : 'Top',    className: 'fa fa-align-left'},
        { value : 'center',  name : 'Center',  className: 'fa fa-align-center' },
        { value : 'flex-end',   name : 'Bottom',   className: 'fa fa-align-right'}
      ],
    }, {
      property: 'text-decoration',
      type: 'radio',
      defaults: 'none',
      list: [
        { value: 'none', name: 'None', className: 'fa fa-times'},
        { value: 'underline', name: 'underline', className: 'fa fa-underline' },
        { value: 'line-through', name: 'Line-through', className: 'fa fa-strikethrough'}
      ],
    }, {
      property: 'text-shadow',
      properties: [
        { name: 'X position', property: 'text-shadow-h'},
        { name: 'Y position', property: 'text-shadow-v'},
        { name: 'Blur', property: 'text-shadow-blur'},
        { name: 'Color', property: 'text-shadow-color'}
      ],
    }
  ],

}, {
  name: 'Decorations',
  open: false,
  buildProps: ['opacity', 'bg-color-type', 'background-color', 'border-radius', 'border-width', 'border-style', 'border-color-type', 'border-color', 'box-shadow', 'background'],
  properties: [
  {
    name: "Background color type",
    property: 'bg-color-type',
    type: 'radio',
    clear: false,
    canClear: false,
    list: [
      { name: 'Brand Theme Color', value: 'theme' },
      { name: 'Custom Color', value: 'custom' },
    ],
    defaults: 'theme',
  },
  {
    property: 'background-color',
    requires: { 'bg-color-type': ['custom'] },
  }, {
    name: "Border color type",
    property: 'border-color-type',
    type: 'radio',
    clear: false,
    canClear: false,
    list: [
      { name: 'Brand Theme Color', value: 'theme' },
      { name: 'Custom Color', value: 'custom' },
    ],
    defaults: 'theme',
  },
  {
    property: 'border-color',
    requires: { 'border-color-type': ['custom'] },
  }, {
    type: 'slider',
    property: 'opacity',
    defaults: 1,
    step: 0.01,
    max: 1,
    min: 0,
  }, {
    property: 'border-radius',
    properties  : [
      { name: 'Top', property: 'border-top-left-radius'},
      { name: 'Right', property: 'border-top-right-radius'},
      { name: 'Bottom', property: 'border-bottom-left-radius'},
      { name: 'Left', property: 'border-bottom-right-radius'}
    ],
  }, {
    property: 'box-shadow',
    properties: [
      { name: 'X position', property: 'box-shadow-h'},
      { name: 'Y position', property: 'box-shadow-v'},
      { name: 'Blur', property: 'box-shadow-blur'},
      { name: 'Spread', property: 'box-shadow-spread'},
      { name: 'Color', property: 'box-shadow-color'},
      { name: 'Shadow type', property: 'box-shadow-type'}
    ],
  }, {
    property: 'background',
    properties: [
      { name: 'Image', property: 'background-image'},
      { name: 'Repeat', property:   'background-repeat'},
      { name: 'Position', property: 'background-position'},
      { name: 'Attachment', property: 'background-attachment'},
      { name: 'Size', property: 'background-size'}
    ],
  }, ],
}, {
  name: 'Extra',
  open: false,
  buildProps: ['transition', 'perspective', 'transform'],
  properties: [{
    property: 'transition',
    properties: [
      { name: 'Property', property: 'transition-property'},
      { name: 'Duration', property: 'transition-duration'},
      { name: 'Easing', property: 'transition-timing-function'}
    ],
  }, {
    property: 'transform',
    properties: [
      { name: 'Rotate X', property: 'transform-rotate-x'},
      { name: 'Rotate Y', property: 'transform-rotate-y'},
      { name: 'Rotate Z', property: 'transform-rotate-z'},
      { name: 'Scale X', property: 'transform-scale-x'},
      { name: 'Scale Y', property: 'transform-scale-y'},
      { name: 'Scale Z', property: 'transform-scale-z'}
    ],
  }]
}];

export const DEFAULT_FONT_FAMILY = 'Open Sans, sans-serif';
