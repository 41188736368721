import {Injectable} from '@angular/core';
import {PaginationService} from '../pagination.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ProcessorDocumentService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'processors');
  }

  setProcessorId(id: string | number) {
    this.route = `processor/${id}/documents`
  }

  uploadToS3(pre_signed_url, file) {
    const url = `${pre_signed_url.url}`;
    const formData = new FormData();
    for (const field in pre_signed_url.fields) {
      formData.append(`${field}`, pre_signed_url.fields[field]);
    }
    formData.append('file', file);
    return this.http.post(url, formData)
  }
}
