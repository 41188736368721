import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {AlertService, CampaignService, FunnelService, PusherService, UserService} from '../_services';
import {FunnelComponent} from "../funnel";
import {NgxSmartModalService} from "ngx-smart-modal";
import {FunnelType} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: '../funnel/funnel.component.html',
  styleUrls: ['../funnel/funnel.component.css']
})
export class TroubleshooterComponent extends FunnelComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected modalService: NgxSmartModalService,
    protected pusherService: PusherService
  ) {
    super(router, location, route, funnelService, alertService, formBuilder, campaignService,
      userService, modalService, pusherService);
    this.objectName = 'Troubleshooter';
    this.title = 'My Troubleshooters';
    this.defaultFunnelType = FunnelType.Troubleshooter;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeFilter() {
    this.filter = {resourcetype: 'Troubleshooter'}; //only show troubleshooters
  }

}
