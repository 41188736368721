import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CrudPagedListComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, UserService, FolderService} from '../_services';
import {Folder, FolderType, User} from '../_models';
import {Observable} from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.css'],
  selector: 'folder'
})
export class FolderComponent extends CrudPagedListComponent {
  @Input('type') type: FolderType = FolderType.Widget;
  @Input('isTemplateSelection') isTemplateSelection: boolean = false;
  @Output('onTemplateSelection') onTemplateSelection: EventEmitter<any> = new EventEmitter<any>();

  public folders$: Observable<Folder[]> = this.data$;
  public user: User = null;
  public selectedFolders = null;
  pageSize = 10;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected folderService: FolderService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, folderService, alertService);
    this.objectName = "folder"
  }

  ngOnInit() {
    super.ngOnInit();
    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user
      }, error => {
        this.handleError(error)
      })
  }

  protected initializeFilter() {
    this.setFilter({'type': this.type, 'parent__isnull': true})
  }

  canChangeFolder(folder: Folder) {
    return !folder.is_global || (this.user && this.user.is_staff)
  }

  canPasteFolder() {
    return this.folderService.canPaste()
  }

  copy(folderId: string | number) {
    this.folderService.copy(folderId)
  }

  paste() {
    this.folderService.paste(null)
      .subscribe(_ => {
        this.queryData()
      }, error => {
        this.handleError(error)
      })
  }

  openFolder(folder: Folder) {
    this.folderService.get(folder.id).subscribe((result) => {
      if (!this.selectedFolders || (folder.parent && !this.selectedFolders[folder.parent])) {
        this.selectedFolders = {};
      }
      this.selectedFolders[folder.id] = result;
    });
  }

  selectTemplate(template) {
    this.onTemplateSelection.emit(template);
  }
}

