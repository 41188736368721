import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {CrudPagedListComponent} from "../_directives";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, ProductCategoryService, StorageService} from "../_services";
import {Observable} from "rxjs";
import {Category, Pager, ReportSessionInput, ReportsFormData, generateReportsQuery} from "../_models";
import {ReportsService} from "../_services";
import {takeUntil} from "rxjs/operators";
import {config} from "../../config/config";
import {reportsFieldData} from "../session/sessionFieldData";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  selector: "reports-table",
  templateUrl: "./templates/report-table.component.html",
  styleUrls: ["./styles/report-table.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ReportTableComponent extends CrudPagedListComponent implements OnInit, OnChanges {
  sessions$: Observable<ReportSessionInput[]> = this.data$;
  @Input('formData') formData: ReportsFormData = {};
  @Input('filter') inputFilter: {} = {};
  @Input('fields') fields: string[] = [];
  @Input('inputReportService') inputReportService: ReportsService;
  @Input('getSessionFieldValue') getSessionFieldValue;
  @Input('filtersDynamicOptions') filtersDynamicOptions;

  public hasTotalsRow: boolean = false
  public summaryData: object = null
  public selectedSessionId: number | string = null
  public reportsReq;

  rowsPerPageOptions = [
    {value: 50, label: "50"},
    {value: 100, label: "100"},
    {value: 250, label: "250"},
    {value: 500, label: "500"},
    {value: 1000, label: "1000"}
  ];

  private defaultFields = [
    'date_joined',
    'campaign.name',
    'result',
    'source',
    'customers.first_name',
    'customers.last_name',
    'device_info.device_type',
    'is_billable'
  ];
  pageSize = 50;

  protected fieldData = reportsFieldData;

  public campaignCategoriesMap = {}
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected reportsService: ReportsService,
    protected alertService: AlertService,
    protected categoryService: ProductCategoryService,
    protected modalService: NgxSmartModalService,
    protected storageService: StorageService
  ) {
    super(router, location, route, reportsService, alertService);
    this.objectName = 'session';

    if (!this.fields || !this.fields.length) {
      this.fields = this.defaultFields;
    }
    this.createCampaignCategoriesMap();
    this.requeryOnFilterChanges = false;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.inputReportService) {
      this.reportsService = this.inputReportService;
    }
    this.queryData();
  }

  protected initializeFilter() {
    this.filter = this.inputFilter;
  }

  ngOnChanges() {
    this.setFilter(this.inputFilter);
    if (this.inputReportService) {
      this.reportsService = this.inputReportService;
    }
  }

  public queryData() {
    this.loading = true;

    let formData = this.formData
    let data = Object.assign(generateReportsQuery(formData), {page: this.page, page_size: this.pageSize});
    if (this.ordering.length > 0) {
      data['ordering'] = this.ordering;
    }
    this.reportsReq = data
    this.reportsService.getFilteredReport(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.pageCount = data.num_pages;
          this.page = data.page;
          this._data$.next(data.results);
          this.hasTotalsRow = data.results && data.has_total_row;
          this.summaryData = data.summary_row;

          if (this.page === 1) {
            this.totalResultCount = data.count;
          }
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this._data$.error(error);
          this.loading = false;
        });
  }

  getFieldLabel(field: string) : string {
    if (field.startsWith('exclude_') || this.fieldData[field] == undefined) {
      return ''
    }
    return this.fieldData[field].label;
  }

  protected runInitialQuery() {
    // don't run the initial query - wait for filter event
  }

  private createCampaignCategoriesMap() {
    this.categoryService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (pager: Pager) => {
          pager.results.forEach((category: Category) => {
            this.campaignCategoriesMap[category.id] = category.name;
          });
          console.log("pager", pager.results);
          console.log("mapped", this.campaignCategoriesMap);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  getMappedCategories(ids: Array<string | number>) {
    let names = [];
    ids.forEach((id: string | number) => {
      names.push(this.campaignCategoriesMap[id]);
    });
    return names.sort().join(", ");
  }

  protected orderBy(ordering: string[]) {
    let currentFields = this.fields;
    this.fields = [];
    super.orderBy(ordering);
    this.fields = currentFields;
  }

  public resetPager() {
    super.resetPager();
  }

  showColumnWithoutLink(field: string) {
    return field !== 'username' && field !== 'recording_url' &&
      field !== 'customer_id' && field !== 'order_id' &&
      field !== 'sp_session_id'
  }

  getSummaryValue(column) {
    let fieldName = column.prop;
    try {
      let value = this.summaryData[fieldName]
      if (value)
        return this.fieldData[fieldName].getter({}, this.summaryData[fieldName]);
      else
        return ""
    } catch (e) {
      return '';
    }
  }

  getColumnWidth(fieldName) {
    let fieldToWidthMappings = {
      hour: 50,
      crm_campaign_name: 250,
      matched_status: 250,
      merchant_id: 400,
      request_type: 400,
      campaign_product_ids: 200,
      date: 100,
      month: 80,
      week: 80,
      year: 80,
      lifeline: 80,
      bypass: 80,
      tracking_source: 200,
      campaign_name: 110,
      crm_type: 110,
      product_name: 300,
      campaign_product_id: 180,
      exchangeable_product_name: 200,
      username: 300,
      order_id: 120,
      aff_id: 100,
      sub1: 100,
      country: 100,
      recording_url: 300,
      is_billable: 100,
      cancelled: 180,
      saved: 180,
      exchangeable_take_rate: 200,
      funnel_item_status: 220,
      lifeline_type: 110,
      bypass_type: 100,
      os_version: 100,
      total_count_sum: 130,
      return_amount: 100,
      refund_quantity: 140,
      downsell: 100,
      upsell: 100,
      upsell_take_rate: 135,
      save_rate: 80,
      refund_rate: 100,
      rma_rate: 80,
      amount_paid: 110,
      step_name: 300,
      input_name: 300,
      question: 300,
      search_phrase: 200,
      action_result: 270,
      latest_result: 270,
      latest_result_based_on_execution: 270,
      order_date: 220
    }

    if (fieldName in fieldToWidthMappings) {
      return fieldToWidthMappings[fieldName];
    }

    // Return default width
    return 150;
  }

  openSessionDetails(event, sessionId) {
    event.preventDefault();
    this.selectedSessionId = sessionId;
    this.modalService.getModal('sessionDetailModal').open();
  }
}
