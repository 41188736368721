import {
    AfterViewInit,
    Component,
    OnInit,
    ViewEncapsulation,
  } from "@angular/core";
  import { FormBuilder } from "@angular/forms";
  import {
    AccountService,
    AlertService,
    CampaignProductService,
    CampaignService,
    CampaignTrackingService,
    ChatbotReportService,
    CRMCampaignService,
    FunnelStepService,
    MerchantService,
    ReportsConfigService,
    StorageService,
    UserService,
  } from "../_services";
  import { ActivatedRoute, Router } from "@angular/router";
  import { Location } from "@angular/common";
  import { NgxSmartModalService } from "ngx-smart-modal";
  import { ReportsAbstractV2Component } from "./reports-abstract-v2.component";
  import { MultiOptions } from "../custom-multi-select/multi-select";
  import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
  import { ChatbotFields, ChatbotFilters } from "./utils/constants";
  import { cloneDeep } from "lodash";
  import { ReportTypes } from "./models/reports";
  
  @Component({
    moduleId: module.id.toString(),
    selector: "chatbot-report-v2",
    templateUrl: "./templates/reports-v2.component.html",
    styleUrls: ["./styles/reports-v2.component.scss"],
    encapsulation: ViewEncapsulation.None,
  })
  export class ChatbotReportV2Component
    extends ReportsAbstractV2Component
    implements OnInit, AfterViewInit
  {
    reportTitle = "Chatbot Report (Beta)";
    fields: MultiOptions = cloneDeep(ChatbotFields);
    filters: MultiOptions = cloneDeep(ChatbotFilters);
    reportType = ReportTypes.CHATBOT
    dropdownsLoading: boolean = false;
  
    predefinedFields = ["exclude_zeroes"];
    commonReportsFields = [
    ];
    summaryFields = [
        "total_sessions",
        "avg_message_count"
    ];
    selectedDimensions = [
      {
        model: "customer_name",
        label: "Name",
        selected: true,
        disabled: false,
      },
      {
        model: "session_source",
        label: "Session Source",
        selected: true,
        disabled: false,
      },
      {
        model: "chatbot_session_id",
        label: "Chatbot Session ID",
        selected: true,
        disabled: false,
      },
      {
        model: "message_count",
        label: "Message Count",
        selected: true,
        disabled: false,
      },
    ];
  
    constructor(
      protected router: Router,
      protected location: Location,
      protected route: ActivatedRoute,
      protected alertService: AlertService,
      protected formBuilder: FormBuilder,
      public reportsService: ChatbotReportService,
      protected modalService: NgxSmartModalService,
      protected campaignService: CampaignService,
      protected reportsConfigService: ReportsConfigService,
      protected trackingSourceService: CampaignTrackingService,
      protected userService: UserService,
      protected accountService: AccountService,
      protected campaignProductService: CampaignProductService,
      protected crmCampaignService: CRMCampaignService,
      protected storageService: StorageService,
      protected stepService: FunnelStepService,
      protected refundReportService: ChatbotReportService,
      protected merchantService: MerchantService,
    ) {
      super(
        router,
        location,
        route,
        alertService,
        reportsService,
        campaignService,
        reportsConfigService,
        trackingSourceService,
        userService,
        accountService,
        campaignProductService,
        crmCampaignService,
        storageService,
        stepService,
        merchantService,
        modalService
      );
  
      this.updatePreSelectedDimensions(["customer_name", 'session_source', "chatbot_session_id", "message_count"]);
      this.filterCommonDimensions(this.commonReportsFields);
    }
  
    ngOnInit() {
      super.ngOnInit();
      this.isChatbotReport = true
    }
  
    ngAfterViewInit() {
      if (this.panel) {
        this.panel.expanded = true;
      }
    }
  
    drop(event: CdkDragDrop<string[]>): void {
      moveItemInArray(
        this.selectedDimensions,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  