import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from './pagination.service';

@Injectable()
export class RelatedProductSetCategoryService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'related_product_set_category');
  }
}
