import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { DashboardChartData } from "../../../_models";

export type BarChartType = "VERTICAL" | "HORIZONTAL";

@Component({
  selector: "bar-chart",
  templateUrl: "./bar-chart.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./bar-chart.component.scss"],
})
export class BarChartComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.updateDynamicView();
  }
  @Input("isReport") isReport: boolean = false;
  @Input("chartType") chartType: BarChartType = "VERTICAL";
  @Input("chartData") chartData: DashboardChartData = [];
  @Input() isFullLength = false;
  customXAxisTicks: number[] = [];
  customYAxisTicks: number[] = [];
  dynamicView: [number, number] = [500, 340];

  isSmallScreen = false;
  colorScheme = {
    domain: ["#22B8D7"],
  };

  ngOnInit(): void {
    this.updateDynamicView();
    if (this.chartType === "HORIZONTAL")
      this.customXAxisTicks = this.calculateTicks();
    if (this.chartType === "VERTICAL")
      this.customYAxisTicks = this.calculateTicks();
  }

  updateDynamicView() {
    if (this.isReport) {
      this.dynamicView = [368, 278];
      return;
    }

    const screenWidth = window.innerWidth;
    const width =
      screenWidth <= 768
        ? 300
        : screenWidth <= 991
        ? 400
        : screenWidth <= 1499
        ? 430
        : screenWidth <= 1550
        ? 460
        : screenWidth <= 1750
        ? 520
        : 600;

    this.dynamicView[0] = width;
    const parentContainer = document.getElementById("bar-chart");

    if (parentContainer) {
      const width = parentContainer.clientWidth - 80;
      const height = 340;
      this.dynamicView = [width, height];
    }
  }

  wrapTicks(label: string): string {
    const screenWidth = window.innerWidth;
    const labelSliceLength =
      screenWidth <= 768
        ? 14
        : screenWidth <= 991
        ? 35
        : screenWidth <= 1233
        ? 14
        : screenWidth <= 1433
        ? 20
        : 25;

    return label.length > labelSliceLength
      ? label.slice(0, labelSliceLength) + "..."
      : label;
  }

  calculateTicks(): number[] {
    const maxValue = Math.max(...this.chartData.map((d) => d.value));
    let roundedMaxValue = 0;

    if (maxValue < 5) {
      return Array.from({ length: maxValue + 1 }, (_, index) => index);
    } else if (maxValue < 8) {
      return [0, 2, 4, 6, 8];
    } else if (maxValue < 10) {
      roundedMaxValue = 10;
    } else if (maxValue < 15) {
      roundedMaxValue = 15;
    } else if (maxValue < 20) {
      roundedMaxValue = 20;
    } else if (maxValue < 50) {
      roundedMaxValue = Math.ceil(maxValue / 5) * 5;
    } else if (maxValue < 80) {
      roundedMaxValue = Math.ceil(maxValue / 10) * 10;
    } else if (maxValue < 100) {
      roundedMaxValue = Math.ceil(maxValue / 20) * 20;
    } else if (maxValue < 200) {
      roundedMaxValue = Math.ceil(maxValue / 25) * 25;
    } else if (maxValue < 400) {
      roundedMaxValue = Math.ceil(maxValue / 50) * 50;
    } else if (maxValue < 1000) {
      roundedMaxValue = Math.ceil(maxValue / 100) * 100;
    } else if (maxValue < 1750) {
      roundedMaxValue = Math.ceil(maxValue / 250) * 250;
    } else {
      roundedMaxValue = Math.ceil(maxValue / 500) * 500;
    }

    if (roundedMaxValue <= 10) return [0, 2, 4, 6, 8, 10];
    else if (roundedMaxValue <= 15) return [0, 3, 6, 9, 12, 15];
    else if (roundedMaxValue <= 20) return [0, 4, 8, 12, 16, 20];
    else {
      const intervalSize = Math.ceil(roundedMaxValue / 5 / 5) * 5;
      const numberOfIntervals = Math.ceil(roundedMaxValue / intervalSize); // Maximum of 6 intervals, or less if roundedMaxValue is smaller
      const ticks = [];

      for (let i = 0; i < numberOfIntervals; i++) {
        ticks.push(i * intervalSize);
      }

      if (roundedMaxValue > ticks[ticks.length - 1])
        ticks.push(ticks[ticks.length - 1] + intervalSize); // Add the roundedMaxValue as the last tick

      return ticks;
    }
  }
}
