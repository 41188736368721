import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from "./pagination.service";

@Injectable()
export class InvoiceService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'invoices');
  }

  charge(id: string | number, amount: string | number | null = null, isExternal: boolean = false) {
    return this.http.post(this.route + '/' + id.toString() + '/charge/',
      {amount: amount, is_external: isExternal});
  }

  price(id: string | number) {
    return this.http.get(this.route + '/' + id.toString() + '/price/');
  }

  regenerate(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/regenerate/', {});
  }

  split(id: string | number, splitDate: string) {
    return this.http.post(this.route + '/' + id.toString() + '/split/', {split_date: splitDate});
  }
}
