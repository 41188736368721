import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CrudPagedListComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, UserService, WidgetService} from '../_services';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ImageSize, User, Widget, WidgetType, getPlaceholderImageUrl} from '../_models';
import {Observable} from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  selector: 'template-grid',
  styleUrls: ['./design-template.component.css'],
  templateUrl: './template-grid.component.html',
})
export class TemplateGridComponent extends CrudPagedListComponent implements OnInit, OnChanges {

  widgets$: Observable<Widget[]> = this.data$;
  selectedWidget: Widget;
  @Input('campaignId') campaignId: string | number = null;
  @Input('productId') productId: string | number = null;
  @Input('campaignProductId') campaignProductId: string | number = null;
  @Input('folderId') folderId: string | number = null;
  @Input('folderIsGlobal') folderIsGlobal: boolean = false;
  @Input('showNewTemplateBtn') showNewTemplateBtn: boolean = false;
  @Input('isTemplateSelection') isTemplateSelection: boolean = false;
  @Output('onTemplateSelection') onTemplateSelection: EventEmitter<any> = new EventEmitter<any>();

  private resourcetype = null;
  user: User;
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected widgetService: WidgetService,
              protected alertService: AlertService,
              protected userService: UserService,
              protected modalService: NgxSmartModalService) {
    super(router, location, route, widgetService, alertService);
    this.objectName = 'template';
    this.title = 'Template';
  }

  ngOnInit() {
    super.ngOnInit();
    this.userService.getCurrent()
    .subscribe(
      (user: User) => {
        this.user = user;
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected initializeFilter() {
    let filter = {};

    if (this.campaignProductId) {
      filter = {campaign_product: this.campaignProductId};
      this.resourcetype = WidgetType.CampaignProduct;
    } else if (this.productId) {
      filter = {product: this.productId};
      this.resourcetype = WidgetType.Product;
    } else if (this.campaignId) {
      filter = {campaign: this.campaignId};
      this.resourcetype = WidgetType.Campaign;
    } else {
      filter = {
        'campaign__isnull': true,
        'product__isnull': true,
        'campaign_product__isnull': true
      };
      filter['block_id__isnull'] = true;
      this.resourcetype = WidgetType.Account;
    }
    if (this.folderId === null) {
      filter['folder__isnull'] = true
    } else {
      filter['folder'] = this.folderId
    }

    filter['enhanced_mode'] = true;
    this.setFilter(filter);
  }

  ngOnChanges(): void {
    this.initializeFilter();
  }

  setting(widget: Widget) {
    this.selectedWidget = widget;
    this.openEditDialog();
  }

  protected openEditDialog() {
    setTimeout(() => this.modalService.getModal('editWidgetDialog').open(), 0);
  }

  onCloseEditDialog() {
    this.selectedWidget = null;
  }

  onSaveWidget(widget: Widget) {
    this.modalService.getModal('editWidgetDialog').close();
    this.queryData();
  }

  preview(widget: Widget) {
    this.selectedWidget = widget;
    setTimeout(() => this.modalService.getModal('widgetPreviewDialog').open(), 0);
  }

  onPreview(previewUrl: string) {
    this.modalService.getModal('widgetPreviewDialog').close();
    window.open(previewUrl, '_blank');
  }

  create() {
    this.selectedWidget = {
      resourcetype: this.resourcetype,
      name: null,
      content: '',
      campaign_product: this.campaignProductId,
      product: this.productId,
      campaign: this.campaignId,
      style: '',
      enhanced_mode: true,
      enhanced_content: '',
      enhanced_content_components: '',
      categories: [],
      thumbnail: '',
      folder: this.folderId,
      include_in_copy: false
    } as Widget;
    this.openEditDialog();
  }

  deleteWidget(id) {
    this.delete(id);
  }

  waitAndRenderDefaultImage(event) {
    let originalSrc = event.target.src;
    event.target.src = getPlaceholderImageUrl(ImageSize.original);
    setTimeout(function () {
      if (event.target)
        event.target.src = originalSrc;
    }, 5000);
  }

  canPasteWidget() {
    return (!this.folderIsGlobal || this.user.is_staff) && (!!this.folderId && this.widgetService.canPaste())
  }

  copy(widget: Widget) {
    this.widgetService.copy(widget)
  }

  paste() {
    if (this.folderId) {
      this.loading = true;
      this.widgetService.paste(this.folderId)
        .subscribe(_ => {
          this.queryData()
        }, error => {
          this.loading = false;
          this.handleError(error)
        })
    }
  }

  selectTemplate(template) {
    this.onTemplateSelection.emit(template);
  }
}
