import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CycleService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {CampaignProduct, Cycle, ImageSize, getProductImageUrl} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'cycle-form',
  templateUrl: './cycle-form.component.html',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class CycleFormComponent extends CrudSavePopupComponent implements OnInit {
  cycle: Cycle;
  @Input('product') campaignProduct: CampaignProduct;
  @Input('number') cycleNumber: number;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected cycleService: CycleService,
              protected alertService: AlertService
  ) {
    super(router, location, route, cycleService, alertService);
    this.objectName = 'Cycle';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      min_price: [null, Validators.min(0)],
      display_name: [null],
      return_days: [null, Validators.min(0)],
      exchange_days: [null, Validators.min(0)],
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.cycle = this.inputData;
  }

  getProduct() {
    return this.cycle.product || this.campaignProduct.product;
  }

  getProductImageUrl() {
    return getProductImageUrl(this.getProduct(), ImageSize.original);
  }

  getComboImageUrl() {
    return getProductImageUrl(this.cycle.combination, ImageSize.original);
  }

  getFormData() {
    let data: {} = this.form.value;
    const allow_blank_fields = ['min_price'];

    //convert empty strings to null for certain fields so the api will accept them
    allow_blank_fields.forEach((field: string) => {
      if (field in data) {
        let value = data[field];

        if (typeof(value) == 'string') {
          if (value.trim().length == 0) {
            data[field] = null;
          }
        }
      }
    });

    return data;
  }
}
