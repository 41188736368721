import { AlertService, AudienceService } from '../../_services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CrudSavePopupComponent } from '../../_directives';
import { AudiencePopup } from '../../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'audience-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['../audience.component.scss'],
})
export class AudiencePopupComponent extends CrudSavePopupComponent implements OnInit
{
  ngModelOptions = { standalone: true };
  AudiencePopup = AudiencePopup;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected AudienceService: AudienceService,
    protected alertService: AlertService
  ) {
    super(router, location, route, AudienceService, alertService);
    this.objectName = 'Audience';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        name: [this.inputData.name || '', Validators.required],
      })
    );

    super.ngOnInit();
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid || this.inputData.popupType === AudiencePopup.Delete || this.inputData.popupType === AudiencePopup.Duplicate) {
      this.loading = true;
      const { popupType, audience_id, audience, ...formData } =
        this.getFormData();

      let operation: Observable<Object>;

      if (popupType === AudiencePopup.Update) {
        operation = this.crudService.update(audience_id, formData);
      } else if (popupType === AudiencePopup.Duplicate) {
        const { name, ...audience_conditions } = audience;
        operation = this.crudService.create({
          ...formData,
          ...audience_conditions,
        });
      } else if (popupType === AudiencePopup.Delete) {
        operation = this.crudService.delete(audience_id);
      }

      const operationTitle =
        popupType === AudiencePopup.Update
          ? 'Updated'
          : popupType === AudiencePopup.Duplicate
          ? 'Duplicated'
          : popupType === AudiencePopup.Delete
          ? 'Deleted'
          : '';

      operation.subscribe(
        (data) => {
          if (this.showSuccessMessage) {
            this.alertService.success(
              `${this.objectName} ${operationTitle} Successfully.`,
              true
            );
          }

          this.canDeactivateService.setFormDirty(false);
          this.onSaveComplete(data);
        },
        (error) => {
          this.handleSubmitError(error);
          this.loading = false;
        }
      );
    }
  }

  protected getFormData() {
    const data = {
      ...this.form.value,
      name: this.inputData.name,
      audience_id: this.inputData.audience_id,
      popupType: this.inputData.popupType,
      audience: this.inputData.audience_data,
    };
    return data;
  }

  popupTypeTextMap: Partial<Record<AudiencePopup, string>> = {
    [AudiencePopup.Delete]: 'Delete',
    [AudiencePopup.Duplicate]: 'Duplicate',
    [AudiencePopup.Update]: 'Rename'
  };
}
