import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Account, CRM, CRMTypeId, Campaign, DashboardFilter, DashboardSections, DashboardState, Pager, User } from '../../_models';
import {
  AccountService,
  AlertService,
  CRMService,
  CampaignService,
  DashboardService,
  StorageService,
} from '../../_services';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Moment } from 'moment-timezone';
import { config } from '../../../config/config';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'dashboard-filter-bar',
  templateUrl: './dashboard-filter-bar.component.html',
  styleUrls: ['./dashboard-filter-bar.component.scss'],
})
export class DashboardFilterBarComponent implements OnInit {
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective: DaterangepickerDirective;
  @Input() selectedSection: DashboardSections;
  selectedFilters: DashboardFilter = null;
  currentUser: User;
  accounts: Account[] = [];
  crms: CRM[] = [];
  campaigns: Campaign[] = [];
  public updateSessionFilterFlag: boolean = true;

  /* Misc */
  protected dashboardServicesMap = {};
  dropdownsLoading = false;
  public state: DashboardState = {};
  protected _destroy$: Subject<boolean> = new Subject<boolean>();

  /* Dates */
  date: { startDate: Moment; endDate: Moment };
  maxDate: Moment;
  public ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Past 7 Days': [moment().subtract(7, 'days'), moment()],
    'Month to Date': [moment().startOf('month'), moment()],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 30 days': [moment().subtract(30, 'days'), moment()],
    'Last 3 Months to Date': [moment().subtract(3, 'months').startOf('month'), moment()],
    'Last 6 Months to Date': [moment().subtract(6, 'months').startOf('month'), moment()],
    'Last 12 Months to Date': [moment().subtract(12, 'months').startOf('month'), moment()],
    'Year to Date': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  };

  constructor(
    private storageService: StorageService,
    protected dashboardService: DashboardService,
    protected accountService: AccountService,
    protected campaignService: CampaignService,
    protected crmService: CRMService,
    protected alertService: AlertService
  ) {}

  ngOnInit() {
    this.currentUser = this.storageService.get('currentUser');
    this.getDashboardData();
    this.getAccountsList();
    this.getCRMList();
    this.initializeDate();
  }

  getDashboardData() {
    this.dashboardService.filters$.pipe(takeUntil(this._destroy$)).subscribe((change) => {
      let account_id = null;

      if (this.selectedFilters && this.selectedFilters.account_id) {
        account_id = this.selectedFilters.account_id;
      }

      this.selectedFilters = change;

      if (account_id !== change.account_id) {
        this.updateCampaigns();
      }
    });
  }

  updateCampaigns() {
    if (this.selectedFilters.account_id)
      this.campaignService
        .list({ is_hybrid: false, account_id: this.selectedFilters.account_id })
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (result: Pager) => {
            this.campaigns = result.results;
            if (this.dropdownsLoading) this.dropdownsLoading = false;
          },
          (error) => {
            this.alertService.error(error.message);
          }
        );
  }

  getAccountsList() {
    this.accountService
      .list({ page: 1, page_size: config.maxPageSize })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.accounts = data.results;
        },
        (error) => {
          this.alertService.error(error.message);
        }
      );
  }

  getCRMList() {
    this.crmService
      .list({
        page: 1,
        page_size: config.maxPageSize,
        'type!': CRMTypeId.Test,
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.crms = data.results;
        },
        (error) => {
          this.alertService.error(error.message);
        }
      );
  }

  onAccountChange(event) {
    this.dropdownsLoading = true;
    this.dashboardService.setFilter({
      ...this.selectedFilters,
      account_id: event.target.value,
      campaign_id: null,
    });
  }

  onCampaignChange(event) {
    this.dashboardService.setFilter({
      ...this.selectedFilters,
      campaign_id: event.target.value,
    });
    this.campaigns.forEach((campaign: Campaign) => {
      if (campaign.id.toString() === event.target.value.toString()) {
        this.state.campaign = campaign;
      }
    });
  }

  onCRMChange(event) {
    this.dashboardService.setFilter({
      ...this.selectedFilters,
      crm_id: event.target.value,
    });

    this.state.crm_id = event.target.value;
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  initializeDate() {
    this.selectedFilters = this.dashboardService.getFilter();
    this.date = {
      startDate: moment(this.selectedFilters.start_date),
      endDate: moment(this.selectedFilters.end_date),
    };
    this.maxDate = this.date.endDate;
  }

  onDateChange = (dateRange: { startDate: Moment; endDate: Moment }) => {
    if (dateRange.startDate && dateRange.endDate)
      this.dashboardService.setFilter({
        ...this.selectedFilters,
        ...{
          start_date: dateRange.startDate.format('YYYY-MM-DD HH:mm:ss'),
          end_date: dateRange.endDate.format('YYYY-MM-DD HH:mm:ss'),
        },
      });
  };

  get isOverviewSection() {
    return this.selectedSection === DashboardSections.Overview;
  }

  get isRetentionSection() {
    return this.selectedSection === DashboardSections.Retention;
  }

  get isMarketplaceSection() {
    return this.selectedSection === DashboardSections.Marketplace;
  }

  get isPhoneSection() {
    return this.selectedSection === DashboardSections.Phone;
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }
}
