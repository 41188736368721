import { Component, OnInit } from '@angular/core';
import {
  Bank,
  CredentialsFields,
  Gateway,
  GatewayAdditionalOptionLabels,
  GatewayAdditionalOptions,
  GatewayAdditionalOptionsList,
  GatewayStatus,
  Processor,
  ProfileTab,
} from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CrudSaveComponent } from '../../../_directives';
import { Location } from '@angular/common';
import {
  AlertService,
  CheckoutBankService,
  CheckoutGatewayService,
  CheckoutProcessorService,
} from '../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { config } from '../../../../config/config';
import { Pager } from '../../../_models';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'checkout-gateway-new',
  templateUrl: './checkout-gateway-edit.component.html',
  styleUrls: ['./checkout-gateway-edit.component.scss', '../profiles-styles.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CheckoutGatewayNewComponent extends CrudSaveComponent implements OnInit {
  ProfileTabs = ProfileTab;
  activeTab = ProfileTab.Credentials;

  banks: Bank[];
  processors: Processor[];
  additional_options: GatewayAdditionalOptions[] = GatewayAdditionalOptionsList;
  selected_options: GatewayAdditionalOptions[] = [];
  gateway: Gateway;
  isPDFLoading: boolean = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected gatewayService: CheckoutGatewayService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected bankService: CheckoutBankService,
    protected processorService: CheckoutProcessorService
  ) {
    super(router, location, route, gatewayService, alertService);
    this.isNew = true;
    this.objectName = 'gateway';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      status: [GatewayStatus.Active, [Validators.required]],
      bank: [null, [Validators.required]],
      processor: [null, [Validators.required]],
      descriptor: [null],
      merchant_id: [null],
      descriptor_phone_number: [null],
      customer_service_email: [null],
      customer_chargeback_number: [null],
      url: [null],
      merchant_category_code: [null, [Validators.maxLength(4), Validators.minLength(4)]],
      acquirer_bin: [null, [Validators.maxLength(8), Validators.minLength(6)]],
      api_login_id: [null, [Validators.required]],
      api_transaction_key: [null, [Validators.required]],
      currency: [null],
      agent_bank_number: [null],
      agent_chain_number: [null],
      store_number: [null],
      terminal_number: [null],
      monthly_cap: [null],
      transaction_fee: [null],
      chargeback_fee: [null],
      refund_processing_fee: [null],
      verifi_cdrn: [null],
      verifi_rdr: [null],
      verifi_order: [null],
      ethica_alert_fee: [null],
      discount_rate: [null],
    });
    super.ngOnInit();

    forkJoin([
      this.bankService.list({ page: 1, page_size: config.maxPageSize }),
      this.processorService.list({ page: 1, page_size: config.maxPageSize }),
    ]).subscribe(
      (data: [Pager, Pager]) => {
        if (data) {
          this.banks = data[0].results;
          this.processors = data[1].results;
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  downloadPDF(content: string): void {
    const data = document.getElementById(content);
    if (data) {
      this.isPDFLoading = true;
      html2canvas(data).then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pdfName = content.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        const xOffset = (pdf.internal.pageSize.getWidth() - pdf.getTextWidth(pdfName)) / 2;

        pdf.setFontSize(18).text(pdfName, xOffset, 10);
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 15, imgWidth, imgHeight);
        pdf.save(`${content}.pdf`);
        this.isPDFLoading = false;
      });
    }
  }

  setActiveTab(tab: ProfileTab) {
    this.activeTab = tab;
  }

  getAdditionalOptionLabel(option: GatewayAdditionalOptions) {
    return GatewayAdditionalOptionLabels[option];
  }

  toggleAdditionalOption(option: GatewayAdditionalOptions) {
    if (this.selected_options.indexOf(option) > -1) {
      let idx = this.selected_options.indexOf(option);
      this.selected_options.splice(idx, 1);
    } else {
      this.selected_options.push(option);
    }
  }

  protected getFormData(): any {
    const data = super.getFormData();
    data['additional_options'] = this.selected_options;
    return data;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      super.onSubmit();
    } else {
      this.navigateToErrorTab();
    }
  }

  protected navigateToErrorTab() {
    const credentialsFields = Object.values(CredentialsFields);

    let hasError = false;
    credentialsFields.forEach((field) => {
      const control = this.form.get(field);
      if (control && control.errors) {
        control.markAsTouched();
        control.markAsDirty();
        hasError = true;
      }
    });

    if (hasError) {
      this.activeTab = ProfileTab.Credentials;
    }
  }

  getBankName(bankId: number) {
    const bank = this.banks.find((bank) => bank.id === bankId);
    return bank ? bank.name : '--';
  }

  getProcessorName(processorId: number) {
    const processor = this.processors.find((processor) => processor.id === processorId);
    return processor ? processor.name : '--';
  }

  getAdditionalOptionsList(optionslist: number[]) {
    if (optionslist.length === 0) return '--';

    const additionalOptions = optionslist.map((option) => GatewayAdditionalOptionLabels[option]).join(', ');
    return additionalOptions || '--';
  }

  onBankChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (selectedValue === 'add-new-bank') {
      this.router.navigate(['/checkouts', 'bank-profiles', 'new']);
    }
  }

  onProcessorChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (selectedValue === 'add-new-processor') {
      this.router.navigate(['/checkouts', 'processor-profiles', 'new']);
    }
  }
}
