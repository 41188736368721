import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignCategoryService} from '../_services';
import {CategoryComponent} from "./category.component";
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-category.component.html'
})
export class CampaignCategoryComponent extends CategoryComponent implements OnInit {
  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected categoryService: CampaignCategoryService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService) {
    super(router, location, route, categoryService, alertService, modalService);
    this.title = 'Brand Categories';
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
