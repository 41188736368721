import {Component} from '@angular/core';
import {PasswordResetComponent} from './password-reset.component';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, AuthenticationService} from '../_services';

@Component({
  moduleId: module.id.toString(),
  templateUrl: 'password-reset.component.html'
})
export class ResendActivationEmailComponent extends PasswordResetComponent {
  pageTitle = 'Resend Activation Email'
  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected authenticationService: AuthenticationService) {
    super(formBuilder, router, location, alertService, authenticationService);
  }

  submit() {
    this.loading = true;
    this.authenticationService.resendActivationEmail(this.form.value.email)
      .subscribe(
        () => {
          this.loading = false
          this.alertService.success('We sent an email to ' + this.form.value.email + ' with a link to verify your account.  Please check your email inbox or spam and click the link to proceed.', true);
        },
        error => {
          this.loading = false
          let msg = 'Email is invalid.';

          switch (error.status) {
            case 500:
              msg = "Internal server error.";
              break;
            case 429:
              msg = "You have requested for activation email too many times. Please check your inbox or junk folder for the email.";
              break;
          }

          this.alertService.error(msg);
          this.loading = false;
        });
  }
}
