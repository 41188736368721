import {Component, OnInit} from '@angular/core';
import {EmailIntegrationService} from '../_services';
import {ActivatedRoute} from '@angular/router';

@Component({
    moduleId: module.id.toString(),
    selector: 'app-integration-select',
    templateUrl: './email-integration-select.component.html',
    styleUrls: ['./email-integration-select.component.scss']
})
export class EmailIntegrationSelectComponent implements OnInit {
  public campaignId: string | number;
  constructor(
    protected emailIntegrationService: EmailIntegrationService,
    protected route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.campaignId = this.route.snapshot.params['campaign'];
    this.emailIntegrationService.setCampaignId(this.campaignId);
  }
}
