import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CrudSavePopupComponent } from '../../../_directives';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, ProcessorDocumentService, UserService } from '../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AllowedDocumentTypes,
  ProcessorDocument,
  ProcessorDocumentStatus,
  ProcessorDocumentStatusLabels,
  ProcessorDocumentStatusList,
  ProcessorDocumentType,
  ProcessorDocumentTypeLabels,
  ProcessorDocumentTypeList,
} from '../../../_models';
import { User } from '../../../_models';

@Component({
  selector: 'app-checkout-processor-document',
  templateUrl: './processor-document-popup.component.html',
  styleUrls: ['../profiles-styles.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('500ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ProcessorDocumentPopupComponent extends CrudSavePopupComponent implements OnInit {
  selected_file = null;
  user: User;
  documentTypes = ProcessorDocumentTypeList;
  documentStatuses = ProcessorDocumentStatusList;
  fileUploaded: boolean = false;
  maxFileSize = 20 * 1024 * 1024;
  uploadProgress: number = 0;
  @Input('processorId') processorId: string | number;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected documentService: ProcessorDocumentService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected userService: UserService
  ) {
    super(router, location, route, documentService, alertService);
    this.isNew = true;
  }

  ngOnInit() {
    this.documentService.setProcessorId(this.processorId);
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      document_type: [ProcessorDocumentType.AgreementStatement, [Validators.required]],
      status: [ProcessorDocumentStatus.Pending, [Validators.required]],
      description: [null, null],
    });
    super.ngOnInit();

    this.userService.getCurrent().subscribe(
      (user: User) => {
        if (user) {
          this.user = user;
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  protected getFormData(): any {
    let data = super.getFormData();

    if (this.selected_file) {
      data['file_type'] = this.selected_file.type;
    }

    return data;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.isValidFile(file)) {
        this.selected_file = file;
        this.fileUploaded = true;
        this.startProgress();
      }
      event.dataTransfer.clearData();
    }
  }

  onSubmit() {
    if (this.isPartial || (this.isNew && this.selected_file)) {
      super.onSubmit();
    }
  }

  protected updateForm() {
    super.updateForm();

    if (this.inputData && this.inputData.id) {
      this.isPartial = true;
    }
  }

  protected onSaveComplete(data: ProcessorDocument) {
    if (this.selected_file) {
      data.file['size'] = this.selected_file.size;
    }

    if (this.isNew) {
      this.loading = true;
      this.documentService.uploadToS3(data.pre_signed_url, this.selected_file).subscribe(
        (_) => {
          this.loading = false;
          super.onSaveComplete(data);
        },
        (error) => {
          this.loading = false;
          this.handleError(error);
        }
      );
    } else {
      super.onSaveComplete(data);
    }
  }

  cancel() {
    this.resetFields();
    this.onCancel.emit();
  }

  resetFields() {
    this.selected_file = null;
    this.fileUploaded = false;
    this.uploadProgress = 0;
    this.form.reset();
  }

  getTypeLabel(type: ProcessorDocumentType) {
    return ProcessorDocumentTypeLabels[type];
  }

  getStatusLabel(status: ProcessorDocumentStatus) {
    return ProcessorDocumentStatusLabels[status];
  }

  getFileIcon(fileType: AllowedDocumentTypes | string): string {
    const iconMap = new Map<AllowedDocumentTypes, string>([
      [AllowedDocumentTypes.PDF, '/assets/IconsLibrary/pdf-file-icon.svg'],
      [AllowedDocumentTypes.DOCX, '/assets/IconsLibrary/docx-file-icon.png'],
      [AllowedDocumentTypes.PPTX, '/assets/IconsLibrary/pptx-file-icon.png'],
      [AllowedDocumentTypes.ZIP, '/assets/IconsLibrary/zip-file-icon.png'],
    ]);

    return iconMap.get(fileType as AllowedDocumentTypes) || '/assets/IconsLibrary/upload-doc-icon.svg';
  }

  onFileSelected(event) {
    let files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (this.isValidFile(file)) {
        this.selected_file = file;
        this.fileUploaded = true;
        this.startProgress();
      }
    }
  }

  startProgress() {
    const interval = setInterval(() => {
      if (this.uploadProgress < 100) {
        this.uploadProgress += 20;
      } else {
        clearInterval(interval);
      }
    }, 500);
  }

  removeFile() {
    this.fileUploaded = false;
    this.selected_file = null;
    this.uploadProgress = 0;
  }

  convertBytes(bytes: number): string {
    if (!bytes) return '--';

    const mb = bytes / (1024 * 1024);
    if (mb >= 1) {
      return `${mb.toFixed(1)}Mb`;
    } else {
      const kb = bytes / 1024;
      return `${kb.toFixed(1)}Kb`;
    }
  }

  private isValidFile(file: File): boolean {
    const allowedTypes = Object.values(AllowedDocumentTypes) as string[];

    if (!allowedTypes.includes(file.type)) {
      window.alert('Unsupported file type. Please upload a DOCX, PPTX, PDF, or Winzip file.');
      return false;
    }
    if (file.size > this.maxFileSize) {
      window.alert('File size exceeds the 20MB limit.');
      return false;
    }
    return true;
  }
}
