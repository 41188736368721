import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, AccountService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {Account} from "../_models";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './account-email-blacklist.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountEmailBlacklistComponent extends CrudSaveComponent implements OnInit {
  account: Account;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
    super(router, location, route, accountService, alertService);
    this.isNew = false;
    this.isPartial = true;
    this.objectName = 'Email Blacklist';
    this.title = 'Email Blacklist';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email_blacklist: [null]
    });

    super.ngOnInit();

    this.data$.subscribe((account: Account) => {
      if (account) {
        this.account = account;
        this.form.patchValue({email_blacklist: account.email_blacklist.join("\n")});
      }
    });
  }

  protected getFormData() {
    return {email_blacklist: this.form.value.email_blacklist.split("\n")};
  }

}
