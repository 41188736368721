import {componentOptions, InputTypes} from '../index';

export const typeBackButton = 'back-button';

const backButtonType = editor => {
  const dType = editor.DomComponents.getType('button');
  const dModel = dType.model;
  editor.DomComponents.addType(typeBackButton, {
    model: dModel.extend({
        defaults: Object.assign({}, dModel.prototype.defaults, {
          attributes: {elemtype: InputTypes.BACK_BUTTON},
          traits: [{ name: 'id' }],
          components: [
            {
              type: "text",
              copyable: false,
              droppable: false,
              draggable: false,
              removable: false,
              highlightable: false,
              content: "Back"
            }
          ]
        }),
        doStuff() {}
      },
      {
        isComponent: function(el) {
          if (el && el.getAttribute && el.getAttribute('elemtype') == InputTypes.BACK_BUTTON) {
            return { type: typeBackButton };
          } else if (el.closest && el.closest(`[elemtype="${InputTypes.BACK_BUTTON}"]`)) {
            if (el.tagName == 'svg') {
              return { type: 'svg', ...componentOptions };
            } else if (el.tagName == 'path') {
              return { type: 'svg-in'};
            } else if (el.hasAttribute('content')) {
              return {
                type: 'text',
                copyable: false,
                droppable: false,
                draggable: false,
                removable: false,
                highlightable: false,
                selectable: false
              };
            }

            return { ...componentOptions };
          }
        }
      }),
  });
};
export default backButtonType;

