import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder, Validators} from '@angular/forms';
import {AlertService, FunnelStepService, WidgetService} from '../_services';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {CrudSavePopupComponent} from '../_directives';
import {getFunnelPathVariables, Widget, WidgetContentType, WidgetType} from '../_models';
import {WebBuilderComponent} from '../web-builder';
import {takeUntil} from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  selector: 'template-form',
  styleUrls: ['./design-template.component.css'],
  templateUrl: './template-edit-form.component.html',
})
export class DesignTemplateEditFormComponent extends CrudSavePopupComponent implements OnInit {

  selectedWidget: Widget;
  @ViewChild(WebBuilderComponent, { static: false }) builder: WebBuilderComponent;
  stepList: any[];
  showEditors = false;
  funnelVariables = [];
  initialLoading: boolean = true;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected widgetService: WidgetService,
              protected alertService: AlertService,
              protected modalService: NgxSmartModalService,
              protected stepService: FunnelStepService) {
    super(router, location, route, widgetService, alertService);
    this.initialLoading = true;
    this.objectName = 'Template';
    this.widgetService.type = WidgetContentType.Content;
    if (this.route.snapshot.params.id) {
      const widgetID = this.route.snapshot.params.id;
      this.widgetService.get(widgetID).subscribe((res: Widget) => {
        this.selectedWidget = res;
        this.inputData = this.selectedWidget;
        this.updateForm();
        this.initialLoading = false;
      });
    }
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null, Validators.required],
      resourcetype: [null],
      style: [null],
      enhanced_mode: [true],
      enhanced_content: [null],
      enhanced_content_components: [null],
    }));

    super.ngOnInit();
    this.stepService.getGlobalJumpLinks(false, {resourcetype: 'VisualFunnel'})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        stepList => {
          this.stepList = stepList;
          this.showEditors = true;
          this.loading = false;
        },
        error => {
          this.showEditors = true;
          this.loading = false;
          this.handleError(error);
        }
      );
    this.getFunnelVariables();
  }
  preview() {
    setTimeout(() => this.modalService.getModal('widgetPreviewDialog').open(), 0);
  }

  onPreview(previewUrl: string) {
    this.modalService.getModal('widgetPreviewDialog').close();
    window.open(previewUrl, '_blank');
  }

  getFunnelVariables() {
    let items = getFunnelPathVariables();

    items.sort((a, b): number => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });
    this.funnelVariables = items;
  }

  onSubmit() {
    this.builder.saveBuilderContent();
    super.onSubmit();
  }

  onSaveContent(data) {
    this.form.controls['enhanced_content'].setValue(data.html);
    this.form.controls['enhanced_content_components'].setValue(data.components);
    this.form.controls['style'].setValue(data.css);
    this.form.controls['resourcetype'].setValue(this.selectedWidget.resourcetype ? this.selectedWidget.resourcetype : WidgetType.Account);
  }

  protected onSaveComplete(data) {
    super.onSaveComplete(data);
    this.updateForm();
    this.selectedWidget = data;
    this.inputData = this.selectedWidget;
  }
}
