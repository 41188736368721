import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_guards';
import {
  CheckoutBankComponent,
  CheckoutBankEditComponent,
  CheckoutBankNewComponent,
  CheckoutBankUserEditComponent,
  CheckoutBankUserNewComponent,
  CheckoutGatewayComponent,
  CheckoutGatewayEditComponent,
  CheckoutGatewayNewComponent,
  CheckoutProcessorComponent,
  CheckoutProcessorEditComponent,
  CheckoutProcessorNewComponent,
  CheckoutProcessorUserEditComponent,
  CheckoutProcessorUserNewComponent,
  CheckoutsComponent,
  CheckoutTemplateDetailsComponent,
  CheckoutTemplatesComponent,
  CheckoutBuilderComponent,
} from '.';

const routes: Routes = [
  {
    path: 'checkouts',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CheckoutsComponent, canActivate: [AuthGuard] },
      {
        path: 'templates',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: CheckoutTemplatesComponent, canActivate: [AuthGuard] },
          {
            path: ':name/campaign/:campaign/account/:account_type/gateway/:gateway_type/subdomain/:subdomain',
            component: CheckoutTemplatesComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'template-details',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: CheckoutTemplateDetailsComponent, canActivate: [AuthGuard] },
          {
            path: ':name/campaign/:campaign/account/:account_type/gateway/:gateway_type/subdomain/:subdomain/:id',
            component: CheckoutTemplateDetailsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      { path: 'template-details', component: CheckoutTemplateDetailsComponent, canActivate: [AuthGuard] },
      {
        path: 'checkout-builder',
        canActivate: [AuthGuard],
        children: [
          { path: ':id', component: CheckoutBuilderComponent, canActivate: [AuthGuard] },
        ],
      },
      {
        path: 'gateway-profiles',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: CheckoutGatewayComponent, canActivate: [AuthGuard] },
          { path: 'new', component: CheckoutGatewayNewComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: CheckoutGatewayEditComponent, canActivate: [AuthGuard] },
        ],
      },
      {
        path: 'bank-profiles',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: CheckoutBankComponent, canActivate: [AuthGuard] },
          { path: 'new', component: CheckoutBankNewComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: CheckoutBankEditComponent, canActivate: [AuthGuard] },
          { path: 'user/new', component: CheckoutBankUserNewComponent, canActivate: [AuthGuard] },
          { path: 'user/edit/:id', component: CheckoutBankUserEditComponent, canActivate: [AuthGuard] },
        ],
      },
      {
        path: 'processor-profiles',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: CheckoutProcessorComponent, canActivate: [AuthGuard] },
          { path: 'new', component: CheckoutProcessorNewComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: CheckoutProcessorEditComponent, canActivate: [AuthGuard] },
          { path: 'user/new', component: CheckoutProcessorUserNewComponent, canActivate: [AuthGuard] },
          { path: 'user/edit/:id', component: CheckoutProcessorUserEditComponent, canActivate: [AuthGuard] },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CheckoutsRoutingModule {}
