import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PaymentSourceService, StorageService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CrudGetComponent} from '../_directives';
import {takeUntil} from "rxjs/operators";
import {PaymentSource} from "../_models";
import {NgxSmartModalService} from 'ngx-smart-modal';
import {
  NgxPlaidLinkService,
} from "ngx-plaid-link";
import {PlaidLinkHandler} from "ngx-plaid-link/lib/ngx-plaid-link-handler";
import {PlaidConfig} from "ngx-plaid-link/lib/interfaces";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './bank-account-new.component.html'
})
export class BankAccountNewComponent extends CrudGetComponent implements OnInit, AfterViewInit {
  showManualAccountDialog = false;
  plaidLinkHandler: PlaidLinkHandler;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected paymentService: PaymentSourceService,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgxSmartModalService,
    private plaidLinkService: NgxPlaidLinkService,
    private storageService: StorageService
  ) {
    super(router, location, route, paymentService, alertService);
    this.title = 'Add New Bank Account';
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.formBuilder.group({});
  }

  ngAfterViewInit() {
    this.loading = true;

    this.paymentService.getPlaidLinkToken()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: {token: string}) => {
        this.storageService.set('plaidToken', result.token);
        this.plaidLinkService
          .createPlaid(Object.assign({} as PlaidConfig, {
            token: result.token,
            onSuccess: (token, metadata) => this.onSuccess(token, metadata),
            onExit: (error, metadata) => this.onExit(error, metadata)
          }))
          .then((handler: PlaidLinkHandler) => {
            this.plaidLinkHandler = handler;
            this.loading = false;
          })
          .catch(err => {
            this.handleError(err);
            this.loading = false;
          });
      }, error => {
        this.handleSubmitError(error);
        this.loading = false;
      });
  }

  createPlaidAccount() {
    this.plaidLinkHandler.open();
  }

  cancelPlaidAccount() {
    this.plaidLinkHandler.exit();
  }

  onSuccess(token, metadata) {
    this.loading = true;

    this.paymentService.createFromPlaidToken(token, metadata.account_id)
      .subscribe(
        data => {
          this.alertService.success('Added New Bank Account.', true);
          this.loading = false;
          this.storageService.remove('plaidToken');
          this.router.navigate(['/payment', 'sources'],{replaceUrl: true});
        },
        error => {
          if (error.status === 400) { //bad request
            if ('token' in error.error) {
              this.alertService.error(error.error.token);
            } else {
              this.handleSubmitError(error);
            }
          } else {
            this.handleError(error);
          }

          this.storageService.remove('plaidToken');
          this.loading = false;
        });
  }

  onExit(error, metadata) {
    if (error) {
      this.alertService.error(error);
    }
    this.storageService.remove('plaidToken');
  }

  createManualAccount() {
    this.showManualAccountDialog = false;

    setTimeout(() => {
      this.showManualAccountDialog = true;
      this.openAccountDialog();
    }, 0)
  }

  protected openAccountDialog() {
    this.modalService.getModal('newBankAccountDialog').open();
  }

  onNewAccountCreated(source: PaymentSource) {
    this.modalService.getModal('newBankAccountDialog').close();
    this.storageService.remove('plaidToken');
    this.router.navigate(['/payment', 'sources'],{replaceUrl: true});
  }
}
