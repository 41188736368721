import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AccountService,
  AlertService,
  AuthenticationService,
  NotificationService,
  UserService,
  FunnelService, PusherService, CallLogsService, SmsLogsService, MailLogsService
} from '../_services';
import {Account, EventStatus, Notification, Pager, User, NotificationType, LogType} from '../_models';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {config} from '../../config/config';
import {CrudPagedListComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent extends CrudPagedListComponent implements OnInit, OnDestroy {
  currentUser: User;
  notifications: Notification[];
  selectedNotification: Notification;
  selectedNotificationEventStatus: string ='';
  selectedNotificationEventName: string = '';
  NotificationStatus = EventStatus;
  @ViewChild('notificationDropdown', { static: false }) notificationDropdown: ElementRef;

  protected _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private notificationService: NotificationService,
              private authService: AuthenticationService,
              private modalService: NgxSmartModalService,
              protected alertService: AlertService,
              protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              private userService: UserService,
              private accountService: AccountService,
              private funnelService: FunnelService,
              private pusherService: PusherService,
              private callLogsService: CallLogsService,
              private smsLogsService: SmsLogsService,
              private mailLogsService: MailLogsService,
              )
  {
    super(router, location, route, notificationService, alertService);
  }

  get pendingOrUnreadNotifications() {
    return (this.notifications || []).filter((notification: Notification) => notification.event_status === EventStatus.IN_PROGRESS || !notification.is_read);
  }

  processNotification(data: any) {
    if (this.isLoggedIn() && data) {
      this.notificationService.setNotification(data);
      if (this.notificationDropdown && this.notificationDropdown.nativeElement) {
        if (!this.notificationDropdown.nativeElement.classList.contains('show')) {
          const notificationsEl = this.notificationDropdown.nativeElement.querySelector('#notifications');
          notificationsEl && notificationsEl.click();
        }
      }
    }
  }

  isActiveTab() {
    return document && document.visibilityState === 'visible'
  }

  ngOnInit() {
    let _this = this;

    this.notificationService.notifications$.pipe(
      takeUntil(this._destroy$))
      .subscribe((notifications: Notification[]) => {
        this.notifications = notifications;
        this.loading = false;
    });

    this.accountService.selectedAccount$.pipe(
      takeUntil(this._destroy$))
      .subscribe((account: Account) => {
        if (this.currentUser) {
          this.currentUser.account = account;
        }
        this.notificationService.clearNotifications();
        this.loadNotifications();
        this.loading = false;
    });

    if (this.isLoggedIn()) {
      this.userService.getCurrent().subscribe(
        (user: User) => {
          this.currentUser = user;
          this.pusherService.initialize();
        },
        error => {
        }
      );
    }

    if (this.pusherService.channel) {
      this.pusherService.channel.bind(NotificationType[NotificationType.CRM_SYNCING], (data: any) => {
        _this.processNotification(data);
      });
      this.pusherService.channel.bind(NotificationType[NotificationType.PATH_PUBLISHING], (data: any) => {
        _this.processNotification(data);
      });
      this.pusherService.channel.bind(NotificationType[NotificationType.COPY_FUNNEL], (data: any) => {
        _this.processNotification(data);
        if (this.currentUser && this.currentUser.account && data.account == this.currentUser.account.id) {
          data = data.payload;
          if (data) {
            if (data.is_completed) {
              _this.funnelService.copiedFunnel.next(data.id);
            } else if (data.has_error) {
              _this.funnelService.copiedFunnel.error(data);
            }
          }
        }
      });
      this.pusherService.channel.bind(NotificationType[NotificationType.GENERATING_REPORT], (data: any) => {
        _this.processNotification(data);
        // Download report only if tab is active/visible
        if (this.currentUser && this.currentUser.account && data.account == this.currentUser.account.id && this.isActiveTab()) {
          data = data.payload;
          if (data && data.is_completed) {
            let dwldLink = document.createElement("a");
            dwldLink.setAttribute("href", data.url);
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
          }
          return;
        }
      });
      this.pusherService.channel.bind(NotificationType[NotificationType.REGEN_INVOICE], (data: any) => {
        _this.processNotification(data);
      });

      this.pusherService.channel.bind(NotificationType[NotificationType.DEV_ENV_CREATION], (data: any) => {
        _this.processNotification(data);
      });

      this.pusherService.channel.bind(NotificationType[NotificationType.CRM_DELETION], (data: any) => {
        _this.processNotification(data);
      });

      this.pusherService.channel.bind(NotificationType[NotificationType.CUSTOM_DOMAIN_DELETION], (data: any) => {
        _this.processNotification(data);
      });

      this.pusherService.channel.bind(NotificationType[NotificationType.LOGS], (data: any) => {
        if (data.type === LogType.PhoneLogs) {
          this.callLogsService.updateLogs(data)
        } else if (data.type === LogType.SmsLogs) {
          this.smsLogsService.updateLogs(data)
        } else if (data.type === LogType.EmailLogs) {
          this.mailLogsService.updateLogs(data)
        }
      });

    }
  }

  loadNotifications(){
    this.notificationService.list({page: 1, page_size: config.defaultPageSize})
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.onPagerData(data);
          this.setPagerData(data);
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.onPagerError(error);
          this.loading = false;
        }
      );
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  notificationDetails(notification:Notification){
    this.selectedNotification = notification;
    this.selectedNotificationEventStatus = EventStatus[notification.event_status];
    this.selectedNotificationEventStatus = this.selectedNotificationEventStatus.split("_").pop();
    this.selectedNotificationEventName = notification.event_name.replace('_',' ');

    if(!notification.is_read){
      this.selectedNotification.is_read = true;
      this.notificationService.setNotification(this.selectedNotification)
      this.notificationService.updateNotification(notification).subscribe()
    }

    this.modalService.getModal('notificationDetailsDialog').open();
  }

  onScroll(event) {
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;
    const scrollTop = event.target.scrollTop;
    const height = Math.ceil(clientHeight + scrollTop);
    if (height >= scrollHeight && this.page < this.pageCount) {
      this.loading = true;
      this.onPageChange({page: this.page + 1, page_size: config.defaultPageSize});
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

}

