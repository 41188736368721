import {
  SessionType,
  SessionActionResult,
} from './session-enums'
import {Action, ActionType, ExecutionTypeEnum} from './funnel-action';
import * as moment from "moment-timezone";
import {BaseModel} from "./base-model";
import {IconFile} from "./icon-file";
import {AudioFile} from "./audio-file";
import {ContactType} from "./campaign";
import {SipRegion} from "./sip";
import {Image, SignedURL} from "./image";
import {StepButtonControlTypes, StepButtonShapes} from "./steps";
import {CampaignProduct, getCampaignProductImageHtml, SubscriptionDisplayType} from "./product";
import {PhoneCarrierType} from "./phone";
import {formatMoney} from "../_helpers/functions";

export enum FunnelInputTypeEnum {
  Choice = 0,
  Text = 1,
  Phone = 2,
  Item = 3,
  ActiveItem = 4,
  Product = 5,
  FAQs = 6,
  BillingAddress = 7,
  ShippingAddress = 8,
  ItemStatus = 9,
  InputActionStatus = 10,
  Radio = 11,
  Select = 12,
  NextSelectedItem = 13,  //deprecated
  RadioSelect = 14,
  Hidden = 15,
  EnterProductFunnel = 16,
  PaymentInfo = 17,
  Link = 18,
  EnterTroubleshooter = 19,
  ContactCallCenter = 20,
  SelectCampaign = 21,
  MatchCustomer = 22,
  Date = 23,
  Checkout = 24,
  CheckoutConfirm = 25,
  RepeatActionHoldoff = 26,
  ShipmentTracking = 27,
  Confirmation = 28,
  ConfirmationNo = 29,
  CustomerPortalHome = 30,
  CustomerPortalOrders = 31,
  CustomerPortalOrderHistory = 32,
  CustomerPortalPaymentInfo = 33,
  CustomerPortalPersonalInfo = 34,
  CustomerPortalOrderDetail = 35,
  RepeatActionHoldoffResponse = 36,

  // system child types
  ChangeQuantity = 1001,
  ChangeInterval = 1002,
  Cancel = 1003,
  NoActiveItems = 1004,
  ChangeProduct = 1005,
  Return = 1006,
  Billing = 1007,
  AltChildExit = 1008,
  PathNotFound = 1009,
  NoActiveCampaigns = 1010,
  CustomerNotFound = 1011,
  RebillItem = 1012,
  ShipmentReturnLabel = 1013,
  ShipmentReturnQRCode = 1014,
  PauseSubscription = 1015,
  SkipSubscription = 1016,
  ReactivateSubscription = 1017,
  CancelSubscription = 1018,
  CancelTrial = 1019,
  CancelSubscriptionHold = 1020,
  CancelTrialHold = 1021,
  ReturnSubscription = 1022,
  ReturnTrial = 1023
}

export let FunnelInputTypeLabels = {};
FunnelInputTypeLabels[FunnelInputTypeEnum.Choice] = 'Choice - Buttons';
FunnelInputTypeLabels[FunnelInputTypeEnum.Radio] = 'Choice - Radio Buttons';
FunnelInputTypeLabels[FunnelInputTypeEnum.Select] = 'Choice - Select Box';
FunnelInputTypeLabels[FunnelInputTypeEnum.Text] = 'Text Input';
FunnelInputTypeLabels[FunnelInputTypeEnum.Date] = 'Date';
FunnelInputTypeLabels[FunnelInputTypeEnum.Phone] = 'Phone Number';
FunnelInputTypeLabels[FunnelInputTypeEnum.Item] = 'Order Item Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.ActiveItem] = 'Active Order Item Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.Product] = 'Product Info';
FunnelInputTypeLabels[FunnelInputTypeEnum.FAQs] = 'FAQs';
FunnelInputTypeLabels[FunnelInputTypeEnum.BillingAddress] = 'Update Billing Address';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShippingAddress] = 'Update Shipping Address';
FunnelInputTypeLabels[FunnelInputTypeEnum.ItemStatus] = 'Order Item Status';
FunnelInputTypeLabels[FunnelInputTypeEnum.InputActionStatus] = 'Input Action Status';
FunnelInputTypeLabels[FunnelInputTypeEnum.ChangeQuantity] = 'Change Item Quantity';
FunnelInputTypeLabels[FunnelInputTypeEnum.ChangeInterval] = 'Change Item Interval';
FunnelInputTypeLabels[FunnelInputTypeEnum.Cancel] = 'Cancel Item';
FunnelInputTypeLabels[FunnelInputTypeEnum.NoActiveItems] = 'No Active Items';
FunnelInputTypeLabels[FunnelInputTypeEnum.ChangeProduct] = 'Change Product';
FunnelInputTypeLabels[FunnelInputTypeEnum.Return] = 'Return Item';
FunnelInputTypeLabels[FunnelInputTypeEnum.RadioSelect] = 'Choice - Radio for Desktop, Select for Mobile';
FunnelInputTypeLabels[FunnelInputTypeEnum.Hidden] = 'Hidden';
FunnelInputTypeLabels[FunnelInputTypeEnum.Billing] = 'Billing';
FunnelInputTypeLabels[FunnelInputTypeEnum.EnterProductFunnel] = 'Enter Product Path';
FunnelInputTypeLabels[FunnelInputTypeEnum.PaymentInfo] = 'Update Payment Info';
FunnelInputTypeLabels[FunnelInputTypeEnum.Link] = 'Choice - Link';
FunnelInputTypeLabels[FunnelInputTypeEnum.EnterTroubleshooter] = 'Enter Troubleshooter';
FunnelInputTypeLabels[FunnelInputTypeEnum.AltChildExit] = 'Alternate Child Exit';
FunnelInputTypeLabels[FunnelInputTypeEnum.PathNotFound] = 'Path Not Found';
FunnelInputTypeLabels[FunnelInputTypeEnum.ContactCallCenter] = 'Contact Call Center';
FunnelInputTypeLabels[FunnelInputTypeEnum.SelectCampaign] = 'Brand Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.NoActiveCampaigns] = 'No Active Brands';
FunnelInputTypeLabels[FunnelInputTypeEnum.MatchCustomer] = 'Match Customer';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerNotFound] = 'Customer Not Found';
FunnelInputTypeLabels[FunnelInputTypeEnum.Checkout] = 'Checkout';
FunnelInputTypeLabels[FunnelInputTypeEnum.CheckoutConfirm] = 'Checkout Confirmation';
FunnelInputTypeLabels[FunnelInputTypeEnum.RebillItem] = 'Rebill Item Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.RepeatActionHoldoff] = 'Repeat Action Holdoff';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShipmentTracking] = 'Shipment Tracking';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShipmentReturnLabel] = 'Shipment Return Label';
FunnelInputTypeLabels[FunnelInputTypeEnum.Confirmation] = 'Confirmation';
FunnelInputTypeLabels[FunnelInputTypeEnum.ConfirmationNo] = 'Confirmation Declined';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShipmentReturnQRCode] = 'Shipment Return QR Code';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerPortalHome] = 'Customer Portal Home';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerPortalOrders] = 'Customer Portal Orders List';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerPortalOrderHistory] = 'Customer Portal Order History';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerPortalPaymentInfo] = 'Customer Portal Payment Methods';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerPortalPersonalInfo] = 'Customer Portal Personal Information';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerPortalOrderDetail] = 'Customer Portal Order Detail';
FunnelInputTypeLabels[FunnelInputTypeEnum.PauseSubscription] = 'Pause Subscription';
FunnelInputTypeLabels[FunnelInputTypeEnum.SkipSubscription] = 'Skip Subscription';
FunnelInputTypeLabels[FunnelInputTypeEnum.ReactivateSubscription] = 'Reactivate Subscription';
FunnelInputTypeLabels[FunnelInputTypeEnum.CancelSubscription] = 'Cancel Subscription';
FunnelInputTypeLabels[FunnelInputTypeEnum.CancelTrial] = 'Cancel Trial';
FunnelInputTypeLabels[FunnelInputTypeEnum.CancelSubscriptionHold] = 'Cancel Subscription on Hold';
FunnelInputTypeLabels[FunnelInputTypeEnum.CancelTrialHold] = 'Cancel Trial on Hold';
FunnelInputTypeLabels[FunnelInputTypeEnum.ReturnSubscription] = 'Return Order Subscription';
FunnelInputTypeLabels[FunnelInputTypeEnum.ReturnTrial] = 'Return Trial';
FunnelInputTypeLabels[FunnelInputTypeEnum.RepeatActionHoldoffResponse] = 'Repeat Action Holdoff Response';

export const FunnelInputSystemTypes = [
  FunnelInputTypeEnum.Item,
  FunnelInputTypeEnum.ActiveItem,
  FunnelInputTypeEnum.Product,
  FunnelInputTypeEnum.FAQs,
  FunnelInputTypeEnum.BillingAddress,
  FunnelInputTypeEnum.ShippingAddress,
  FunnelInputTypeEnum.ItemStatus,
  FunnelInputTypeEnum.InputActionStatus,
  FunnelInputTypeEnum.Hidden,
  FunnelInputTypeEnum.EnterProductFunnel,
  FunnelInputTypeEnum.PaymentInfo,
  FunnelInputTypeEnum.EnterTroubleshooter,
  FunnelInputTypeEnum.ContactCallCenter,
  FunnelInputTypeEnum.SelectCampaign,
  FunnelInputTypeEnum.MatchCustomer,
  FunnelInputTypeEnum.Checkout,
  FunnelInputTypeEnum.CheckoutConfirm,
  FunnelInputTypeEnum.RebillItem,
  FunnelInputTypeEnum.RepeatActionHoldoff,
  FunnelInputTypeEnum.ShipmentTracking,
  FunnelInputTypeEnum.Confirmation,
  FunnelInputTypeEnum.ConfirmationNo,
  FunnelInputTypeEnum.CustomerPortalHome,
  FunnelInputTypeEnum.CustomerPortalOrders,
  FunnelInputTypeEnum.CustomerPortalPaymentInfo,
  FunnelInputTypeEnum.CustomerPortalOrderHistory,
  FunnelInputTypeEnum.CustomerPortalPersonalInfo,
  FunnelInputTypeEnum.CustomerPortalOrderDetail,
];

export const FunnelInputCustomerPortalInputs = [
  FunnelInputTypeEnum.CustomerPortalHome,
  FunnelInputTypeEnum.CustomerPortalOrders,
  FunnelInputTypeEnum.CustomerPortalPaymentInfo,
  FunnelInputTypeEnum.CustomerPortalOrderHistory,
  FunnelInputTypeEnum.CustomerPortalPersonalInfo
];

export const FunnelInputCustomerPortalTypes = [
  FunnelInputTypeEnum.CustomerPortalHome,
  FunnelInputTypeEnum.CustomerPortalOrders,
  FunnelInputTypeEnum.CustomerPortalPaymentInfo,
  FunnelInputTypeEnum.CustomerPortalOrderHistory,
  FunnelInputTypeEnum.CustomerPortalPersonalInfo,
  FunnelInputTypeEnum.CustomerPortalOrderDetail
]

//system input types that allow child inputs
export let FunnelSystemInputChildTypes = {};
FunnelSystemInputChildTypes[FunnelInputTypeEnum.Item] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.ActiveItem] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.ContactCallCenter] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.RepeatActionHoldoff] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.SelectCampaign] = [FunnelInputTypeEnum.NoActiveCampaigns];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.MatchCustomer] = [FunnelInputTypeEnum.CustomerNotFound];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.ItemStatus] = [
  FunnelInputTypeEnum.ChangeQuantity,
  FunnelInputTypeEnum.ChangeInterval,
  FunnelInputTypeEnum.Cancel,
  FunnelInputTypeEnum.ChangeProduct,
  FunnelInputTypeEnum.Return,
  FunnelInputTypeEnum.Billing,
  FunnelInputTypeEnum.ShipmentReturnLabel,
  FunnelInputTypeEnum.ShipmentReturnQRCode,
];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.EnterProductFunnel] = [
  FunnelInputTypeEnum.AltChildExit,
  FunnelInputTypeEnum.PathNotFound
];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.EnterTroubleshooter] = [
  FunnelInputTypeEnum.AltChildExit,
  FunnelInputTypeEnum.PathNotFound
];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.InputActionStatus] = [
  FunnelInputTypeEnum.ShipmentReturnLabel,
  FunnelInputTypeEnum.ShipmentReturnQRCode,
];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.Confirmation] = [
  FunnelInputTypeEnum.ConfirmationNo
];

export enum OfferIntents {
  Legacy = 0,
  CancelOrder = 1,
  ReturnOrder = 2,
  Upsell = 3,
  CancelSubscription = 4,
  CancelTrial = 5,
  Other = 6,
  AdjustShippingFrequency = 7,
  PauseSubscription = 8,
  ReviseOrder = 9,
  ReactivateSubscription = 10,
  CancelSubscriptionHold = 11,
  CancelTrialHold = 12,
  OfferDetails = 13,
  ReturnSubscription = 14,
  ReturnTrial = 15
}

export const OfferIntentTypeLabels = {};
OfferIntentTypeLabels[OfferIntents.Legacy] = 'Legacy';
OfferIntentTypeLabels[OfferIntents.CancelOrder] = 'Cancel One-Time Order';
OfferIntentTypeLabels[OfferIntents.CancelSubscriptionHold] = 'Cancel Subscription Order';
OfferIntentTypeLabels[OfferIntents.CancelTrialHold] ='Cancel Trial Order';
OfferIntentTypeLabels[OfferIntents.CancelSubscription] = 'Cancel Active Subscription';
OfferIntentTypeLabels[OfferIntents.CancelTrial] = 'Cancel Active Trial';
OfferIntentTypeLabels[OfferIntents.ReturnOrder] = 'Return One-Time Order';
OfferIntentTypeLabels[OfferIntents.ReturnSubscription] = 'Return Subscription Order';
OfferIntentTypeLabels[OfferIntents.ReturnTrial] = 'Return Trial Order';

export const OfferIntentTypeTableLabels = {};
OfferIntentTypeTableLabels[OfferIntents.Legacy] = 'Legacy';
OfferIntentTypeTableLabels[OfferIntents.CancelOrder] = 'Cancel One-Time Order';
OfferIntentTypeTableLabels[OfferIntents.CancelSubscriptionHold] = 'Cancel Subscription Order';
OfferIntentTypeTableLabels[OfferIntents.CancelTrialHold] ='Cancel Trial Order';
OfferIntentTypeTableLabels[OfferIntents.CancelSubscription] = 'Cancel Active Subscription';
OfferIntentTypeTableLabels[OfferIntents.CancelTrial] = 'Cancel Active Trial';
OfferIntentTypeTableLabels[OfferIntents.ReturnOrder] = 'Return One-Time Order';
OfferIntentTypeTableLabels[OfferIntents.ReturnSubscription] = 'Return Subscription Order';
OfferIntentTypeTableLabels[OfferIntents.ReturnTrial] = 'Return Trial Order';
OfferIntentTypeTableLabels[OfferIntents.AdjustShippingFrequency] = 'Adjust Shipping Frequency';
OfferIntentTypeTableLabels[OfferIntents.ReactivateSubscription] = 'Reactivate Subscription';
OfferIntentTypeTableLabels[OfferIntents.OfferDetails] = 'Offer Details';
OfferIntentTypeTableLabels[OfferIntents.PauseSubscription] = 'Pause Subscription';
OfferIntentTypeTableLabels[OfferIntents.ReviseOrder] = 'Swap Products';

export enum OfferTypes {
  Legacy = 0,
  Downsell = 1,
  Upsell = 2,
  SubPath = 3,
  CustomerPortal = 4,
  Support = 5,
  Survey = 6,
}

export const OfferTypeLabels = {
  [OfferTypes.Downsell]: 'downsell',
  [OfferTypes.Upsell]: 'upsell',
  [OfferTypes.Survey]: 'survey',
  [OfferTypes.Support]: 'support',
  [OfferTypes.CustomerPortal]: 'customer-portal',
};

export enum PathStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Draft = 'Draft'
}

FunnelSystemInputChildTypes[FunnelInputTypeEnum.CustomerPortalHome] = FunnelInputCustomerPortalInputs;
FunnelSystemInputChildTypes[FunnelInputTypeEnum.CustomerPortalOrders] = FunnelInputCustomerPortalInputs.concat(
  [FunnelInputTypeEnum.CustomerPortalOrderDetail]);
FunnelSystemInputChildTypes[FunnelInputTypeEnum.CustomerPortalOrderHistory] = FunnelInputCustomerPortalInputs;
FunnelSystemInputChildTypes[FunnelInputTypeEnum.CustomerPortalPaymentInfo] = FunnelInputCustomerPortalInputs;
FunnelSystemInputChildTypes[FunnelInputTypeEnum.CustomerPortalPersonalInfo] = FunnelInputCustomerPortalInputs;
FunnelSystemInputChildTypes[FunnelInputTypeEnum.CustomerPortalOrderDetail] = FunnelInputCustomerPortalInputs.concat(
  [FunnelInputTypeEnum.Item]);

const FunnelInputTypeGroups = [
  [FunnelInputTypeEnum.Radio, FunnelInputTypeEnum.RadioSelect, FunnelInputTypeEnum.Select],
  [FunnelInputTypeEnum.Item, FunnelInputTypeEnum.ActiveItem],
  [FunnelInputTypeEnum.BillingAddress, FunnelInputTypeEnum.ShippingAddress],
]

export function getFunnelInputTypeGroup(type: FunnelInputTypeEnum) {
  return FunnelInputTypeGroups.find((group) => group.indexOf(type) !== -1) || [type];
}

export function getFunnelItemTypeLabel(stepType: FunnelInputTypeEnum, inputType: FunnelInputTypeEnum,
                                       currentLabel?: string) {
  const defaultSystemLabel = currentLabel || 'Continue';
  let label = currentLabel || '';

  if (FunnelInputSystemTypes.indexOf(stepType) !== -1) {
    switch (stepType)
    {
      case FunnelInputTypeEnum.BillingAddress:
      case FunnelInputTypeEnum.ShippingAddress:
      case FunnelInputTypeEnum.PaymentInfo:
        if (inputType === stepType) {
          label = currentLabel || 'Save';
        }
        break;

      case FunnelInputTypeEnum.ActiveItem:
      case FunnelInputTypeEnum.Item:
      case FunnelInputTypeEnum.RebillItem:
        label = (inputType === stepType) ? defaultSystemLabel : 'No Items Found';
        break;

      case FunnelInputTypeEnum.ContactCallCenter:
        label = (inputType === stepType) ? defaultSystemLabel : 'Call Center Closed';
        break;

      case FunnelInputTypeEnum.RepeatActionHoldoff:
        label = (inputType === stepType) ? 'Repeating Action' : 'No Repeating Action';
        break;

      case FunnelInputTypeEnum.SelectCampaign:
        label = (inputType === stepType) ? defaultSystemLabel : 'No Brands Found';
        break;

      case FunnelInputTypeEnum.MatchCustomer:
        label = (inputType === stepType) ? defaultSystemLabel : 'No Customer Found';
        break;

      case FunnelInputTypeEnum.Checkout:
        if (inputType === stepType) {
          label = currentLabel || 'Process Order';
        }
        break;

      case FunnelInputTypeEnum.EnterTroubleshooter:
        switch (inputType) {
          case FunnelInputTypeEnum.EnterTroubleshooter:
            label = 'Troubleshooter Passed';
            break;

          case FunnelInputTypeEnum.AltChildExit:
            label = 'Troubleshooter Failed';
            break;

          case FunnelInputTypeEnum.PathNotFound:
            label = 'No Troubleshooter Found';
            break;
        }
        break;

      case FunnelInputTypeEnum.EnterProductFunnel:
        switch (inputType) {
          case FunnelInputTypeEnum.EnterProductFunnel:
            label = 'Path Exit';
            break;

          case FunnelInputTypeEnum.AltChildExit:
            label = 'Alternate Path Exit';
            break;

          case FunnelInputTypeEnum.PathNotFound:
            label = 'No Path Found';
            break;
        }
        break;

      case FunnelInputTypeEnum.InputActionStatus:
        switch (inputType) {
          case FunnelInputTypeEnum.ShipmentReturnLabel:
          case FunnelInputTypeEnum.ShipmentReturnQRCode:
            if (!label) {
              label = FunnelInputTypeLabels[inputType];
            }
            break;

          default:
            label = (inputType === stepType) ? defaultSystemLabel : FunnelInputTypeLabels[inputType];
            break
        }
        break;

      default:
        label = (inputType === stepType) ? defaultSystemLabel : FunnelInputTypeLabels[inputType];
        break;
    }
  } else if (FunnelInputCustomerPortalTypes.indexOf(stepType) > -1) {
    if (inputType === FunnelInputTypeEnum.CustomerPortalOrders) {
      label = currentLabel || 'Orders & Subscriptions';
    } else {
      label = currentLabel || FunnelInputTypeLabels[inputType].replace('Customer Portal ', '')
    }
  }

  return label;
}

export enum FunnelItemStatus {
  InActive = -1,
  SubscriptionActive = 0,
  SubscriptionInTrial = 1,
  ShippingHold = 2,
  ShippingProcessing = 3,
  OneTimePurchaseShipped = 4,
  OneTimePurchaseInTrial = 5,
  SubscriptionHold = 6,
  InTrialHold = 7,
  RebillHold = 8,
  RebillActive = 9,
  SubscriptionStopped = 10,
  RecycleFailed = 11,
  InTrialRebillHold = 12,
  RMAPending = 13,
}

export const AvailableFunnelItemStatuses = [
  {id: FunnelItemStatus.SubscriptionActive, text: 'Subscription Active'},
  {id: FunnelItemStatus.SubscriptionInTrial, text: 'Subscription In Trial'},
  {id: FunnelItemStatus.OneTimePurchaseShipped, text: 'One-Time Purchase Shipped'},
  {id: FunnelItemStatus.OneTimePurchaseInTrial, text: 'One-Time Purchase In Trial'},
  {id: FunnelItemStatus.ShippingProcessing, text: 'Shipping Processing'},
  {id: FunnelItemStatus.ShippingHold, text: 'Shipping Hold'},
  {id: FunnelItemStatus.SubscriptionHold, text: 'Subscription Hold'},
  {id: FunnelItemStatus.InTrialHold, text: 'In Trial Hold'},
  {id: FunnelItemStatus.InActive, text: 'Inactive'},
  {id: FunnelItemStatus.RebillHold, text: 'Rebill Hold'},
  {id: FunnelItemStatus.RebillActive, text: 'Rebill Active'},
  {id: FunnelItemStatus.SubscriptionStopped, text: 'Subscription Stopped'},
  {id: FunnelItemStatus.RecycleFailed, text: 'Recycle Failed'},
  {id: FunnelItemStatus.InTrialRebillHold, text: 'In Trial Rebill Hold'},
  {id: FunnelItemStatus.RMAPending, text: 'Return Pending'},
];

export const AvailableActionHoldoffs = [
  {id: SessionActionResult.Downsell, text: 'Refund'},
  {id: SessionActionResult.ReturnExtension, text: 'Return Extension'},
  {id: SessionActionResult.Revision, text: 'Revision'},
  {id: SessionActionResult.SubscriptionAdjust, text: 'Subscription Adjustment'},
  {id: SessionActionResult.SubscriptionDownsell, text: 'Subscription Discount'},
  {id: SessionActionResult.SubscriptionPause, text: 'Subscription Pause'},
  {id: SessionActionResult.TrialExtension, text: 'Trial Extension'},
  {id: SessionActionResult.Upsell, text: 'Upsell'},
];

export const AvailableActionHoldOffIntents = [
  {id: FunnelInputTypeEnum.ChangeQuantity, text: 'Change Item Quantity'},
  {id: FunnelInputTypeEnum.ChangeInterval, text: 'Change Item Interval'},
  {id: FunnelInputTypeEnum.ChangeProduct, text: 'Change Product'},
  {id: FunnelInputTypeEnum.PauseSubscription, text: 'Pause Subscription'},
  {id: FunnelInputTypeEnum.SkipSubscription, text: 'Skip Subscription'},
  {id: FunnelInputTypeEnum.ReactivateSubscription, text: 'Reactivate Subscription'},
  {id: FunnelInputTypeEnum.Cancel, text: 'Cancel Item'},
  {id: FunnelInputTypeEnum.CancelSubscription, text: 'Cancel Subscription'},
  {id: FunnelInputTypeEnum.Return, text: 'Return Item'},
  {id: FunnelInputTypeEnum.CancelTrial, text: 'Cancel Trial'},
  {id: FunnelInputTypeEnum.CancelTrialHold, text: 'Cancel Trial on Hold'},
  {id: FunnelInputTypeEnum.CancelSubscriptionHold, text: 'Cancel Subscription on Hold'},
];

export enum FunnelFulfillmentType {
  Shippable = 1,
  NotShippable = 2
}

interface BaseFunnelInput extends BaseModel {
  label: string;
  help: string;
  next_step: string | number;
  matched_step_key: string;
  type: FunnelInputTypeEnum;
  category: StepCategory;
  product_funnels: string[] | number[];
  step: string | number;
  builder_id?: string | number;
  hide_if_invalid: boolean;
  is_alt_child_exit: boolean;
  has_response_step?: boolean;
  autopick_next_step: string | number;
}

export interface FunnelInput extends BaseFunnelInput {
  icon: IconFile;
  values: string[];
  actions: Action[];
  classes: string[];
  button_styles?: StepButtonStyles;
}

export interface FunnelStepGroup extends BaseModel {
  name: string;
  classes: string[];
  lifeline: string | number;
  lifeline_enabled: boolean;
  error_lifeline: string | number;
  exit_lifeline: string | number;
}

interface BaseFunnelStep extends BaseModel {
  name: string;
  slug: string;
  label: string;
  help: string;
  next_step: string | number; // only used as a default when creating system steps
  voice_prompt: string;
  voice_file: AudioFile;
  sms_prompt: string;
  num_digits: number;
  forward_to_call_center: boolean;
  call_center_phone: string;
  allow_undo: boolean;
  show_step_link: boolean;
  type: FunnelInputTypeEnum;
  category: StepCategory;
  faq_topic: string | number | null;
  banner: string;
  subtitle: string;
  content: string;
  style: string;
  lifeline: string | number;
  lifeline_enabled: boolean;
  group: string | number;
  contact_type: ContactType;
  enhanced_mode: boolean;
  enhanced_content: string;
  call_center_sip_uri: string;
  call_center_sip_secure: boolean;
  call_center_sip_refer: boolean;
  call_center_sip_username: string;
  call_center_sip_password: string;
  call_center_sip_region: SipRegion;
  fail_if_any_input_invalid: boolean;
  invalid_message: string;
  error_lifeline: string | number;
  exit_lifeline: string | number;
  hide_status: boolean;
  enhanced_content_components?: string;
  mobile_icon_button_type?: number;
  use_bootstrap_spacing?: boolean;
  display_id: number;
}

export enum FunnelStepMobileIconButtonType {
  TILE = 1,
  BOX = 2,
}

export let FunnelStepMobileIconButtonTypeLabels = {};
FunnelStepMobileIconButtonTypeLabels[FunnelStepMobileIconButtonType.TILE] = 'Tile - Buttons';
FunnelStepMobileIconButtonTypeLabels[FunnelStepMobileIconButtonType.BOX] = 'Box - Buttons';


export function getFunnelStepMobileIconButtonTypeOptions() {
  let items = [];

  Object.keys(FunnelStepMobileIconButtonTypeLabels).forEach(key => {
    items.push({value: key, text: FunnelStepMobileIconButtonTypeLabels[key]})
  });

  return items;
}

export enum StepStaticDataImageType {
  None = 0,
  Product = 1,
  Tagged = 2,
  Gallery = 3,
  Fixed = 4,
  Embed = 5,
}

export interface StepStaticData {
  id?: number | null;
  step?: number | null;
  image: Image |  null;
  image_tablet: Image | null;
  image_mobile: Image | null;
  embed_code: number | null;
  image_type: StepStaticDataImageType;
}

export interface StepButtonStyles {
  label?: string,
  style: string,
  style_type: StepButtonShapes,
  control_type: StepButtonControlTypes,
}

export interface StepExternalButtonStyles{
  confirm: StepButtonStyles;
  back: StepButtonStyles;
}

export interface FunnelStep extends BaseFunnelStep {
  funnel: string | number;
  is_first_step: boolean;
  inputs: FunnelInput[];
  item_statuses: FunnelItemStatus[];
  campaign_products: string[] | number[];
  classes: string[];
  is_popup: boolean;
  action_holdoffs: SessionActionResult[];
  previous_inputs: string[] | number[];
  template: string | number;
  static_data: StepStaticData;
  button_styles?: StepExternalButtonStyles;
  response_data?: FunnelStep;
  pre_signed_url?: SignedURL;
}

export enum FunnelType {
  Product = 'ProductFunnel',
  Visual = 'VisualFunnel',
  Voice = 'VoiceFunnel',
  SMS = 'SMSFunnel',
  Troubleshooter = 'Troubleshooter',
  Lifeline = 'Lifeline',
  Survey = 'Survey',
  Hybrid = 'HybridCampaignFunnel',
  CustomerPortal = 'CustomerPortal',
}

export const FunnelTypeLabels = {
  ProductFunnel: 'Product',
  VisualFunnel: 'Visual',
  VoiceFunnel: 'Voice',
  SMSFunnel: 'SMS',
  Troubleshooter: 'Troubleshooter',
  Lifeline: 'Lifeline',
  Survey: 'Survey',
  HybridCampaignFunnel: 'Hybrid',
  CustomerPortal: 'Customer Portal',
};

export enum CancelItemsSelection {
  ShowAll = 1,
  ShowNone = 2,
  UpsellsOnly = 3,
  UpsellsOnlyIfUpsell = 4
}

interface CustomerPortalSettings extends BaseModel {
  side_menu_inputs: FunnelInputTypeEnum[];
  allow_cancel: boolean;
  show_fulfillment_status: boolean;
  show_subscription_status: boolean;
  show_tracking_in_list: boolean;
  show_tracking_in_details: boolean;
  show_transactions_in_list: boolean;
  show_transactions_in_details: boolean;
  allow_cancel_subscription: SubscriptionDisplayType;
  allow_edit_payment_method: boolean;
  allow_edit_shipping_address: boolean;
  allow_edit_billing_interval: boolean;
  allow_skip_shipment: boolean;
  allow_revision: boolean;
  show_next_bill_date: boolean;
  allow_reactivate_subscription: boolean;
  show_price_in_list: boolean;
  show_price_in_details: boolean;
  show_taxes: boolean;
  show_shipping_price: boolean;
  show_payment_status_in_list: boolean;
  show_payment_status_in_details: boolean;
  allow_revise_subscription: boolean;
  holdoff_actions_config: {[key: string]: FunnelInputTypeEnum[]};
}

interface BaseFunnel extends BaseModel {
  resourcetype: FunnelType;
  name: string;
  first_step: string | number;
  is_visual: boolean;
  is_voice: boolean;
  is_sms: boolean;
  is_after_hours: boolean;
  item_statuses: FunnelItemStatus[];
  voice_type: string;
  matched_verify_msg: string;
  unmatched_verify_msg: string;
  match_msg: string;
  matched_welcome_msg: string;
  unmatched_welcome_msg: string;
  thank_you_msg: string;
  lifeline_timeout: number;
  lifeline_undo_count: number;
  lifeline_unmatched_count: number;
  lifeline: string | number;
  error_lifeline: string | number;
  exit_lifeline: string | number;
  exit_survey: string | number;
  auto_select_single_item: boolean;
  is_test: boolean;
  hidden_fees_label: string;
  carrier_type: PhoneCarrierType;
  is_modified: boolean;
  cancel_items_selection: CancelItemsSelection;
  return_items_selection: CancelItemsSelection;
  is_public: boolean;
  is_template: boolean;
  session_type: SessionType;
  match_required: boolean;
  audience: string | number;
  customer_portal_settings?: CustomerPortalSettings;
  product_funnels?: string[] | number[];
  slug?: string;
  display_id: number;
  use_intents: boolean;
  offer_intent: OfferIntents;
  offer_type: OfferTypes;
}

export interface Funnel extends BaseFunnel {
  steps: FunnelStep[];
  campaign: string | number;
  campaigns: string[] | number[];
  products: string[] | number[];
  children: BaseFunnel[];
  offer_steps?: FunnelStep[]
}

export interface Path extends BaseModel {
  name: string;
  offer_intent: OfferIntents;
  is_modified: boolean;
  last_modified: string;
  steps: {id: number, name: string, display_id: number}[];
  audience: PathAudience;
  display_id: number;
}

export interface PathAudience {
  id: number;
  name: string;
}

export enum PathTableFilters {
  Name,
  Type,
  LastModified,
  DateCreated,
}

export enum PathTableTypes {
  CancelOrder =  "Cancel Order",
  ReturnOrder = "Return Order",
  CancelSubscription =  "Cancel Subscription",
  CancelTrial = "Cancel Trial",
}

export enum PathTableStatuses {
  All,
  Active,
  Inactive,
  Draft,
}

export const PathTableStatusLabels = {
  [PathTableStatuses.All] : "All",
  [PathTableStatuses.Active] : "Active",
  [PathTableStatuses.Inactive] : "Inactive",
  [PathTableStatuses.Draft] : "Draft",
}

export enum PathDateTypes {
  LastModified = "lastModified",
  DateCreated = "dateCreated",
}

export interface FunnelNode extends Funnel{
  children: FunnelNode[];
}

export interface FunnelRelationship {
  funnel_id: string | number;
  parent_id: string | number;
}

// funnel variable values are [label, dummy value for emulator, product value for emulator]
export const FunnelVariables = {
  billing_interval_days: ['Billing Interval (in days)', '30', null],
  brand_name: ['Brand Name', 'PetJoy', null],
  campaign_product_id: ['Campaign Product ID', '1339', 'campaign_product_id'],
  customer_id: ['Customer ID', '49613', null],
  'discount|currency': ['Discount Amount', '39.15', (product: CampaignProduct) => {
    return +product.price * 0.25;
  }],
  discount_percent: ['Discount Percent from Current Price', '25', null],
  'discount_price|currency': ['Discounted Sale Price', '117.45', (product: CampaignProduct) => {
    return +product.price * 0.75;
  }],
  email: ['Customer Email', 'testymctester@gmail.com', null],
  exchange_days: ['Exchange Days Remaining', '45', null],
  first_name: ['Customer First Name', 'John', null],
  full_name: ['Customer Full Name', 'John Doe', null],
  last_name: ['Customer Last Name', 'Doe', null],
  next_bill_date: ['Next Bill Date', () => {moment().add(30, 'days').calendar()}, null],
  offer_name: ['Offer Name', 'Pet Food', (product: CampaignProduct) => {
    return product.offer_name || product.name;
  }],
  offer_terms: ['Offer Terms', 'Lorem ipsum dolor sit amet...', 'offer_terms'],
  order_create_date: ['Order Create Date', '10/15/2024', null],
  order_id: ['Order ID', 'A135448', null],
  'order_shipping_price|currency': ['Order Shipping Price', '9.95', null],
  'original_price|currency': ['Original Price', '156.60', 'price'],
  'original_shipping_price|currency': ['Original Shipping Price', '9.95', 'shipping_price'],
  phone_number: ['Customer Phone Number', '516-555-1234', null],
  'price|currency': ['Sale Price', '156.60', 'price'],
  product_image: ['Product Default Image', null, (product: CampaignProduct) => {
    return getCampaignProductImageHtml(product);
  }],
  product_name: ['Product Name', 'Pet Food', 'name'],
  product_tagged_image: ['Product Tagged Image', null, (product: CampaignProduct) => {
    return getCampaignProductImageHtml(product);
  }],
  'rebill_amount|currency': ['Rebill Amount', '56.95', 'price'],
  'refund|currency': ['Refund Amount', '39.15', (product: CampaignProduct) => {
    return +product.price * 0.25;
  }],
  'restock_fee|currency': ['Restocking Fee', '15.00', 'restock_fee'],
  'retail_price|currency': ['Retail Price', '176.60', (product: CampaignProduct) => {
    return +product.retail_price || +product.price;
  }],
  'retail_unit_price|currency': ['Retail Unit Price', '176.60', (product: CampaignProduct) => {
    return +product.retail_price || +product.price;
  }],
  return_by_date: ['Return By Date', () => {moment().add(30, 'days').calendar()}, null],
  return_days: ['Return Days Remaining', '30', null],
  rma_number: ['RMA Number', 'RMA15466876', null],
  'savings|currency': ['Savings', '20.00', (product: CampaignProduct) => {
    const price = +product.price;
    const retail_price = +product.retail_price || price;
    return retail_price > price ? retail_price - price : 0;
  }],
  'shipping_price|currency': ['Sale Shipping Price', '9.95', 'shipping_price'],
  support_email: ['Support Email', 'support@mycompany.com', null],
  support_phone: ['Support Phone Number', '888-555-1234', null],
  total_discount_percent: ['Discount Percent from Original Price', '25', null],
  'total_price|currency': ['Sale Total Price (price + shipping)', '166.55', (product: CampaignProduct) => {
    return +product.price + +product.shipping_price;
  }],
  tracking_number: ['Tracking Number', 'U9868574152358', null],
  'unit_price|currency': ['Unit Price', '156.60', 'price'],
  'unit_savings|currency': ['Unit Savings', '20.00', (product: CampaignProduct) => {
    const price = +product.price;
    const retail_price = +product.retail_price || price;
    return retail_price > price ? retail_price - price : 0;
  }],
  upsale_image: ['Upsale Product Image', null, (product: CampaignProduct) => {
    return getCampaignProductImageHtml(product);
  }],
  upsale_name: ['Upsale Product Name', 'Earpods', 'name'],
  'upsale_price|currency': ['Upsale Product Price', '9.95', 'price'],
  'upsale_shipping_price|currency': ['Upsale Product Shipping Price', '4.95', 'shipping_price'],
  'upsale_total_price|currency': ['Upsale Product Total Price', '14.90', (product: CampaignProduct) => {
    return +product.price + +product.shipping_price;
  }],
  upsale_quantity: ['Upsale Product Quantity', '1', null],
};

export function parseFunnelVariableForEmulator(variableName: string, product: CampaignProduct) {
  const key = variableName.replace(/[{}]/g, '');
  const variable = FunnelVariables[key];
  let value = variableName;
  let parsed = false;

  if (variable) {
    if (!!product && variable[2]) {
      const productField = variable[2];

      if (typeof(productField) === 'function') {
        value = productField(product);
        parsed = true;
      } else if (typeof(productField) === 'string' && productField in product) {
        value = product[productField] as string;
        parsed = true;
      }
    }

    if (!parsed) {
      const field = variable[1];

      if (typeof(field) === 'function') {
        value = field();
      } else {
        value = field;
      }

      parsed = true;
    }

    if (parsed) {
      if (key.endsWith('|currency')) {
        value = '$' + formatMoney(value);
      }
    }
  }

  return value;
}

export const WelcomeMessageVariables = {
  first_name: 'Customer First Name',
  last_name: 'Customer Last Name',
}

export function getFunnelPathVariables(category?: CustomStepCategoryEnum) {
  let items = [];

  if (category && category in StepCategoryVariables) {
    // Filter variables based on category
    const allowedVariables = StepCategoryVariables[category];
    Object.keys(FunnelVariables).forEach(key => {
      if (allowedVariables.includes(key)) {
        items.push({value: '{' + key + '}', text: FunnelVariables[key][0]})
      }
    });
  } else {
    // Return all variables if no category specified
    Object.keys(FunnelVariables).forEach(key => {
      items.push({value: '{' + key + '}', text: FunnelVariables[key][0]})
    });
  }

  return items.sort((a, b) => a.text.localeCompare(b.text));
}

export interface FunnelHistory extends BaseFunnel {
  created: string;
}

export interface FunnelStepHistory extends BaseFunnelStep {
  funnel: FunnelHistory;
  created: string;
}

export interface FunnelInputHistory extends BaseFunnelInput {

}

export enum FunnelStepSaveType  {
  SaveAndStay = 'saveAndStay',
  Save= 'save',
  Cancel= 'cancel'
}

export function getExitLabel(type: FunnelType) {
  let label = 'Exit path (normal exit)';

  switch (type) {
    case FunnelType.Troubleshooter:
      label = 'Exit troubleshooter (pass)';
      break;

    case FunnelType.Lifeline:
      label = 'Accept lifeline and end session'
      break;
  }

  return label;
}

export function getAlternateExitLabel(type: FunnelType) {
  let label = 'Exit path (alternate exit)';

  switch (type) {
    case FunnelType.Troubleshooter:
      label = 'Exit troubleshooter (fail)';
      break;

    case FunnelType.Lifeline:
      label = 'Close lifeline and continue session'
      break;
  }

  return label;
}

export enum CustomStepCategoryEnum {
  // custom step categories
  Response = 10,
  Custom = 2000,
  Reasons = 2001,
  OrderOptions = 2002,
  DiscountFutureOrder = 2003,
  RefundOrder = 2004,
  ReturnOrderRMA = 2005,
  UpsellOrder = 2006,
  PauseSub = 2007,
  OfferDetails = 2008,
  ReviseOrder = 2009,
  NeedMoreHelp = 2010,
  AdjustShippingFrequency = 2011,
  ReturnPolicy = 2012,
  RefundWithDiscount = 2013,
  DiscountWithAdjustShippingFrequency = 2014,
  ReActivateSubscription = 2015,
  ReActivateSubscriptionWithDiscount = 2016,
  Survey = 2017,
  FixedAmountDownsell = 2018,
  PercentageDownsell = 2019,
  LossAversion = 2020,
  TimeForFree = 2021,
  ListLossAversion = 2022,
  Testimonial = 2023,
  ExtendTrial = 2024,
  DiscountedUpsell = 2025,
  SpecialOffer = 2026,
  CancelOrderSurvey = 2027,
  ReturnSurvey = 2028,
  Feedback = 2029,
  ScheduleSupport = 2030,
  LifelineStep = 2031,
  Handoff = 2032,
  PauseSurvey = 2033,
  ContactFrom = 2024,
  SwapProduct = 2025,
  CancelTrialSurvey = 2034,
  CancelSubSurvey = 2035
}

export type StepCategory = FunnelInputTypeEnum | CustomStepCategoryEnum
export let StepCategoryLabels = Object.assign({}, FunnelInputTypeLabels);
StepCategoryLabels[CustomStepCategoryEnum.Custom] = 'Custom';
StepCategoryLabels[CustomStepCategoryEnum.Reasons] = 'Reasons';
StepCategoryLabels[CustomStepCategoryEnum.OrderOptions] = 'Order Options';
StepCategoryLabels[CustomStepCategoryEnum.DiscountFutureOrder] = 'Discount Future Cycle';
StepCategoryLabels[CustomStepCategoryEnum.RefundOrder] = 'Refund Order';
StepCategoryLabels[CustomStepCategoryEnum.ReturnOrderRMA] = 'Return Downsell';
StepCategoryLabels[CustomStepCategoryEnum.UpsellOrder] = 'Upsell';
StepCategoryLabels[CustomStepCategoryEnum.PauseSub] = 'Pause Subscription Offer';
StepCategoryLabels[CustomStepCategoryEnum.OfferDetails] = 'Offer Details/Info';
StepCategoryLabels[CustomStepCategoryEnum.ReviseOrder] = 'Revise Order';
StepCategoryLabels[CustomStepCategoryEnum.NeedMoreHelp] = 'Need More Help';
StepCategoryLabels[CustomStepCategoryEnum.AdjustShippingFrequency] = 'Shipping Frequency Offer';
StepCategoryLabels[FunnelInputTypeEnum.RepeatActionHoldoff] = 'Tagging Customer For Repeating Action';
StepCategoryLabels[FunnelInputTypeEnum.ActiveItem] = 'Item Selection';
StepCategoryLabels[FunnelInputTypeEnum.Item] = 'Item Selection';
StepCategoryLabels[FunnelInputTypeEnum.Checkout] = 'Marketplace';
StepCategoryLabels[FunnelInputTypeEnum.CheckoutConfirm] = 'Marketplace';
StepCategoryLabels[FunnelInputTypeEnum.Product] = 'Marketplace';
StepCategoryLabels[FunnelInputTypeEnum.ContactCallCenter] = 'Life Line Step';
StepCategoryLabels[FunnelInputTypeEnum.EnterProductFunnel] = 'Sub Path';
StepCategoryLabels[FunnelInputTypeEnum.EnterTroubleshooter] = 'Troubleshooter';
StepCategoryLabels[FunnelInputTypeEnum.FAQs] = 'FAQs';
StepCategoryLabels[FunnelInputTypeEnum.ItemStatus] = 'Order Status';
StepCategoryLabels[FunnelInputTypeEnum.BillingAddress] = 'Update Info';
StepCategoryLabels[FunnelInputTypeEnum.ShippingAddress] = 'Update Info';
StepCategoryLabels[FunnelInputTypeEnum.PaymentInfo] = 'Update Info';
StepCategoryLabels[FunnelInputTypeEnum.MatchCustomer] = 'Match and Verify';
StepCategoryLabels[FunnelInputTypeEnum.InputActionStatus] = 'Response Step';
StepCategoryLabels[CustomStepCategoryEnum.ReturnPolicy] = 'Policy/Instruction';
StepCategoryLabels[CustomStepCategoryEnum.RefundWithDiscount] = 'Partial Refund';
StepCategoryLabels[CustomStepCategoryEnum.DiscountWithAdjustShippingFrequency] = 'Discount with Adjust Shipping Frequency';
StepCategoryLabels[CustomStepCategoryEnum.ReActivateSubscription] = 'Reactivate Subscription';
StepCategoryLabels[CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount] = 'Reactivate Subscription';
StepCategoryLabels[CustomStepCategoryEnum.Survey] = 'Basic Survey';
StepCategoryLabels[CustomStepCategoryEnum.CancelOrderSurvey] = 'Cancel Order Survey';
StepCategoryLabels[CustomStepCategoryEnum.CancelSubSurvey] = 'Cancel Subscription Survey';
StepCategoryLabels[CustomStepCategoryEnum.CancelTrialSurvey] = 'Cancel Trial Survey';
StepCategoryLabels[CustomStepCategoryEnum.ReturnSurvey] = 'Return Order Survey';
StepCategoryLabels[CustomStepCategoryEnum.PercentageDownsell] = 'Percentage Downsell';
StepCategoryLabels[CustomStepCategoryEnum.FixedAmountDownsell] = 'Fixed Amount Downsell';
StepCategoryLabels[CustomStepCategoryEnum.PauseSurvey] = 'Pause Options';
StepCategoryLabels[CustomStepCategoryEnum.ExtendTrial] = 'Extend Trial';
StepCategoryLabels[CustomStepCategoryEnum.SwapProduct] = 'Exchangeable Downsell';

export enum PathNewStepSelection {
  Survey = 0,
  DownsellOffer = 1,
  UpsellOffer = 2,
  Support = 3,
  Troubleshooter = 4
}

export enum PathSteps {
  Survey = 'Survey',
  Downsell = 'Downsell',
  Upsell = 'Upsell',
  Support = 'Support',
  Troubleshooter = 'Troubleshooter'
}

export enum PathPopup {
  Create = 0,
  Update = 1,
  Duplicate = 2,
  Delete = 3,
}

export const PathNewStepSelectionLabels = {};
PathNewStepSelectionLabels[PathNewStepSelection.Survey] = 'Survey';
PathNewStepSelectionLabels[PathNewStepSelection.DownsellOffer] = 'Downsell Offer';
PathNewStepSelectionLabels[PathNewStepSelection.UpsellOffer] = 'Upsell Offer';
PathNewStepSelectionLabels[PathNewStepSelection.Support] = 'Support';
PathNewStepSelectionLabels[PathNewStepSelection.Troubleshooter] = 'Troubleshooter';

export const IntentConfirmationButtonText = {};
IntentConfirmationButtonText[OfferIntents.CancelOrder] = 'cancel';
IntentConfirmationButtonText[OfferIntents.CancelTrial] = 'cancel';
IntentConfirmationButtonText[OfferIntents.CancelSubscription] = 'cancel';
IntentConfirmationButtonText[OfferIntents.CancelTrialHold] = 'cancel';
IntentConfirmationButtonText[OfferIntents.CancelSubscriptionHold] = 'cancel';
IntentConfirmationButtonText[OfferIntents.ReturnOrder] = 'return';
IntentConfirmationButtonText[OfferIntents.ReturnSubscription] = 'return';
IntentConfirmationButtonText[OfferIntents.ReturnTrial] = 'return';
IntentConfirmationButtonText[OfferIntents.Upsell] = 'upsell';

export const IntentConfirmationHeadlineText = {};
IntentConfirmationHeadlineText[OfferIntents.CancelOrder] = 'Are you sure you want to cancel your order?';
IntentConfirmationHeadlineText[OfferIntents.CancelTrialHold] = 'Are you sure you want to cancel your order?';
IntentConfirmationHeadlineText[OfferIntents.CancelSubscriptionHold] = 'Are you sure you want to cancel your order?';
IntentConfirmationHeadlineText[OfferIntents.CancelTrial] = 'Are you sure you want to cancel your trial?';
IntentConfirmationHeadlineText[OfferIntents.CancelSubscription] = 'Are you sure you want to cancel your subscription?';
IntentConfirmationHeadlineText[OfferIntents.ReturnOrder] = 'Are you sure you want to return your order?';
IntentConfirmationHeadlineText[OfferIntents.ReturnSubscription] = 'Are you sure you want to return your order?';
IntentConfirmationHeadlineText[OfferIntents.ReturnTrial] = 'Are you sure you want to return your order?';

export const IntentConfirmationIntroductionText = {};
IntentConfirmationIntroductionText[OfferIntents.CancelOrder] = "Are you sure? Your order will be canceled and won't be shipped.";
IntentConfirmationIntroductionText[OfferIntents.CancelTrialHold] = "Are you sure? Your order will be canceled and won't be shipped.";
IntentConfirmationIntroductionText[OfferIntents.CancelSubscriptionHold] = "Are you sure? Your order will be canceled and won't be shipped.";
IntentConfirmationIntroductionText[OfferIntents.CancelTrial] = "Are you sure? You'll lose access to the trial and its features.";
IntentConfirmationIntroductionText[OfferIntents.CancelSubscription] = "Are you sure? You'll lose access to all benefits immediately.";
IntentConfirmationIntroductionText[OfferIntents.ReturnOrder] = 'Are you sure? Your return will be processed, and the product must be sent back.';
IntentConfirmationIntroductionText[OfferIntents.ReturnSubscription] = 'Are you sure? Your return will be processed, and the product must be sent back.';
IntentConfirmationIntroductionText[OfferIntents.ReturnTrial] = 'Are you sure? Your return will be processed, and the product must be sent back.';


export const IntentConfirmationAgreeHeadlineText = {};
IntentConfirmationAgreeHeadlineText[OfferIntents.CancelOrder] = '<div class="response-heading">Order Cancelled!</div>';
IntentConfirmationAgreeHeadlineText[OfferIntents.CancelSubscriptionHold] = '<div class="response-heading">Order Cancelled!</div>';
IntentConfirmationAgreeHeadlineText[OfferIntents.CancelSubscription] = '<div class="response-heading">Subscription Cancelled!</div>';
IntentConfirmationAgreeHeadlineText[OfferIntents.CancelTrial] = '<div class="response-heading">Order Trial Cancelled!</div>';
IntentConfirmationAgreeHeadlineText[OfferIntents.CancelTrialHold] = '<div class="response-heading">Order Trial Cancelled!</div>';
IntentConfirmationAgreeHeadlineText[OfferIntents.ReturnOrder] = '<div class="response-heading">We are sorry to see you go</div>';
IntentConfirmationAgreeHeadlineText[OfferIntents.ReturnSubscription] = '<div class="response-heading">We are sorry to see you go</div>';
IntentConfirmationAgreeHeadlineText[OfferIntents.ReturnTrial] = '<div class="response-heading">We are sorry to see you go</div>';

export const IntentConfirmationAgreeContentText = {};
IntentConfirmationAgreeContentText[OfferIntents.CancelOrder] = '<div class="response-content">Your order has been cancelled and a <strong>${refund}&nbsp;</strong>refund has been issued.</div>';
IntentConfirmationAgreeContentText[OfferIntents.CancelSubscriptionHold] = '<div class="response-content">Your order has been cancelled and a <strong>${refund}&nbsp;</strong>refund has been issued.</div>';
IntentConfirmationAgreeContentText[OfferIntents.CancelSubscription] = '<div class="response-content">Your subscription has been cancelled</div>';
IntentConfirmationAgreeContentText[OfferIntents.CancelTrial] = '<div class="response-content">Your trial has been cancelled</div>';
IntentConfirmationAgreeContentText[OfferIntents.CancelTrialHold] = '<div class="response-content">Your trial has been cancelled</div>';
IntentConfirmationAgreeContentText[OfferIntents.ReturnOrder] = '<div class="response-content return-content"><p>Please ship it back and make sure to include the RMA number on your shipping label and again inside your package with your name, address and phone number.</p><p>Your RMA Number is <strong>{rma_number}</strong>.</p><p><strong>Return Address:</strong>{return-address}</p><p>Thank You</p></div>';
IntentConfirmationAgreeContentText[OfferIntents.ReturnSubscription] = IntentConfirmationAgreeContentText[OfferIntents.ReturnOrder];
IntentConfirmationAgreeContentText[OfferIntents.ReturnTrial] = IntentConfirmationAgreeContentText[OfferIntents.ReturnOrder];

export const IntentConfirmationRejectHeadlineText = {};
IntentConfirmationRejectHeadlineText[OfferIntents.CancelOrder] = '<div class="response-heading">Thank you for staying with us!</div>';
IntentConfirmationRejectHeadlineText[OfferIntents.CancelTrialHold] = '<div class="response-heading">Thank you for staying with us!</div>';
IntentConfirmationRejectHeadlineText[OfferIntents.CancelSubscriptionHold] = '<div class="response-heading">Thank you for staying with us!</div>';
IntentConfirmationRejectHeadlineText[OfferIntents.CancelTrial] = '<div class="response-heading">Thank you for staying with us!</div>';
IntentConfirmationRejectHeadlineText[OfferIntents.CancelSubscription] = '<div class="response-heading">Thank you for staying with us!</div>';
IntentConfirmationRejectHeadlineText[OfferIntents.ReturnOrder] = '<div class="response-heading">Thank you for staying with us!</div>';
IntentConfirmationRejectHeadlineText[OfferIntents.ReturnSubscription] = '<div class="response-heading">Thank you for staying with us!</div>';
IntentConfirmationRejectHeadlineText[OfferIntents.ReturnTrial] = '<div class="response-heading">Thank you for staying with us!</div>';

export enum ConfirmationStepTextType {
  Button = 0,
  Headline = 1
}

export const ConfirmationResponseStepName = {}
ConfirmationResponseStepName[FunnelInputTypeEnum.Confirmation] = 'Accept Response';
ConfirmationResponseStepName[FunnelInputTypeEnum.ConfirmationNo] = 'Reject Response';

export enum SettingsPreviewTypes {
  CancelOrder = 0,
  FulfillmentStatus = 1,
  TrackShipment = 2,
  ViewTransaction = 3,
  UpsellSelection = 4,
  HiddenFees = 5,
  CancelSubscription = 6,
  SubscriptionStatus = 7,
  EditPaymentMethod = 8,
  EditShippingAddress = 9,
  EditShippingFrequency = 10,
  SkipShipment = 11,
  ReviseOrder = 12,
  ShowNextShipmentDate = 13,
  AllowReactiveSubscription = 14,
  ShowOrderPrice = 15,
  Taxes = 16,
  ShippingFee = 17,
  PaymentStatus = 18
}
export enum CustomerPortalSettingType {
  General = 0,
  Order = 1,
  Subscription = 2,
  Payment = 3,
  Paths = 4,
  Lifeline = 5,
  HoldoffAction = 6
}

export interface SettingsPreviewContent {
  heading: string;
  imageSrc: string;
  imageHeight: number;
  bottom: string;
}

export const ConfirmationStepActions = {
  [OfferIntents.CancelOrder]: [{
    resourcetype: ActionType.Cancel,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested cancel',
  } as Action],
  [OfferIntents.ReturnOrder]: [{
    resourcetype: ActionType.RMA,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested return',
  } as Action],
  [OfferIntents.CancelSubscription]: [{
    resourcetype: ActionType.CancelSubscription,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested cancel subscription',
  } as Action],
  [OfferIntents.CancelTrial]: [{
    resourcetype: ActionType.RMA,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested return',
  } as Action,
    {
      resourcetype: ActionType.BillingCycle,
      execution_type: ExecutionTypeEnum.Immediate,
      final_billing_cycle_offset: 1,
      next_bill_date_delay: 14
    } as Action],
  [OfferIntents.CancelSubscriptionHold]: [{
    resourcetype: ActionType.Cancel,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested cancel',
    cancel_subscription: true
  } as Action],
  [OfferIntents.CancelTrialHold]: [{
    resourcetype: ActionType.Cancel,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested cancel',
  } as Action],
  [OfferIntents.ReturnSubscription]: [{
    resourcetype: ActionType.RMA,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested return',
  } as Action,
  {
    resourcetype: ActionType.CancelSubscription,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested return',
  } as Action],
  [OfferIntents.ReturnTrial]: [{
    resourcetype: ActionType.RMA,
    execution_type: ExecutionTypeEnum.Immediate,
    reason: 'Customer requested return',
  } as Action,
    {
      resourcetype: ActionType.BillingCycle,
      execution_type: ExecutionTypeEnum.Immediate,
      final_billing_cycle_offset: 1,
      next_bill_date_delay: 14
    } as Action],
}

export const CustomerPortalSettingHints = {
  general: {
    name: "Enter the name for this support path.",
    welcomeMessage: "Set a personalized greeting for returning customers. (default text already displayed)",
    menuOptions: "Select which menu options customers see on the portal’s main page.",
    lifeline: "Configure support options that activate during customer interactions to assist them at critical moments.",
    generalLifeline: "Choose a lifeline to guide customers needing extra help.",
    errorLifeline: "Select a lifeline for situations when errors occur in the session.",
    sessionExitAlert: "Select a support option for customers attempting to leave the support portal during their session.",
    searchAssistanceLifeline: "Activate this lifeline to assist customers when they use specific trigger words in the search bar.",
    idleTimeoutDuration: "Enter idle time (in seconds) before a lifeline appears for inactive sessions.",
    undoStepsLimit: "Set the number of back steps a customer can take before a lifeline appears.",
    unmatchedLimit: "Set the number of failed customer match attempts before a lifeline appears.",
  },
  order: {
    orderCancellation: "Allow customers to cancel their orders in the portal.",
    fulfillmentStatus: "Show the shipping status of the order (e.g., pending, shipped, delivered).",
    shipmentTracking: "Choose where to show shipment tracking: on the order list page or details page.",
    transactionStatus: "Select where to display transaction status: on the order list page or details page.",
    singleItemAutoSelect: "Automatically select the only item for RMAs and cancellations, skipping item selection.",
    upsellItemSelection: "Control which additional items customers can select when cancelling or returning an item.",
    cancelItemOptions: "Control which additional items customers can select when cancelling an item.",
    returnItemOptions: "Control which additional items customers can select when returning an item.",
    additionalFeesLabel: "Enter a label for any additional fees associated with orders. (i.e: Processing Fee, Handling Fee)",
  },
  subscription: {
    subscriptionCancellation: "Allow customers to cancel their subscriptions through the customer portal.",
    cancellationDelay: "Set a restriction on when customers can cancel their subscription (e.g., after the first cycle).",
    subscriptionStatus: "Show or hide the current status of the subscription (e.g., active, canceled, inactive).",
    paymentMethodEditing: "Allow customers to update their payment method.",
    shippingAddressEditing: "Allow customers to modify their shipping address.",
    shippingFrequencyEditing: "Allow customers to change their subscription shipping frequency.",
    skipUpcomingShipment: "Allow customers to skip their next shipment.",
    reviseSubscriptionItems: "Allow customers to change items in their subscription for unshipped cycles.",
    nextShipmentDateVisibility: "Show or hide the date of the next scheduled shipment.",
    reactivateSubscription: "Allow customers to reactivate their canceled subscription.",
  },
  payment: {
    showOrderPrice: "Select where to display the order price (order list or details page).",
    taxes: "Choose to show or hide tax amounts in the support portal.",
    shippingFee: "Choose to show or hide shipping fees in the support portal.",
    paymentStatus: "Select where to show the payment status (order list or details page).",
  },
  paths: {
    assignPaths: "Choose your action paths for this customer portal.",
  },
  actionCooldown: {
    cooldownDesc: "Prevent customers from repeating the same request too soon. Set a cooldown period to temporarily stop repeated actions like cancellations, refunds, or returns within a chosen timeframe.",
    intent: "Choose the type of customer request that triggers the cooldown.",
    action: "Select the specific action you want to delay if a customer has already performed it.",
    delay: "Set how long to hold off repeated actions after they are triggered."
  }
};

function isConfirmation(type: FunnelInputTypeEnum) {
  return [FunnelInputTypeEnum.Confirmation, FunnelInputTypeEnum.ConfirmationNo].indexOf(type) > -1;
}

export function getConfirmationLabel(text_type: ConfirmationStepTextType, step_type: FunnelInputTypeEnum, funnel: Funnel) {
  let library = IntentConfirmationButtonText;

  if (text_type === ConfirmationStepTextType.Headline) {
    library = IntentConfirmationHeadlineText;
  }

  if (isConfirmation(step_type)) {
    return library[funnel.offer_intent];
  }
  return '';
}

export function generateStep(type: FunnelInputTypeEnum, funnel: Funnel, category: StepCategory = CustomStepCategoryEnum.Custom) {
  const step = {
    name: FunnelInputTypeLabels[type] + ' Step ' + category,
    type: type,
    category: category,
    inputs: [],
    funnel: funnel ? funnel.id : null
  } as FunnelStep;

  if (FunnelInputSystemTypes.indexOf(type) !== -1) {
    step.inputs = [{
      type: type,
      label: getFunnelItemTypeLabel(type, type),
      next_step: null,
    } as FunnelInput];

    if (type in FunnelSystemInputChildTypes) {
      FunnelSystemInputChildTypes[type].forEach(childType => {
        if (childType !== FunnelInputTypeEnum.ShipmentReturnLabel) {
          step.inputs.push({
            type: childType,
            label: getFunnelItemTypeLabel(type, childType),
            next_step: null,
          } as FunnelInput);
        }
      });
    }
  }

  if (isConfirmation(step.type)) {
    step.label = IntentConfirmationHeadlineText[funnel.offer_intent];
    step.is_popup = true
    step.content = IntentConfirmationIntroductionText[funnel.offer_intent]

    step.inputs.forEach((input: FunnelInput) => {
      let labelPrefix = '';
      let actions = []

      if (input.type === FunnelInputTypeEnum.Confirmation) {
        labelPrefix = 'Yes, please';
        actions = ConfirmationStepActions[funnel.offer_intent] || []
      } else if (input.type === FunnelInputTypeEnum.ConfirmationNo) {
        labelPrefix = "No, don't";
      }

      input.label = `${labelPrefix} ${IntentConfirmationButtonText[funnel.offer_intent]}`;
      input.actions = actions;
    })
  }
  return step;
}

export const StepCategoryVariables = {
  [CustomStepCategoryEnum.DiscountFutureOrder]: [
    'brand_name',
    'first_name',
    'original_price|currency',
    'original_shipping_price|currency',
    'discount|currency',
    'discount_price|currency',
    'discount_percent',
    'unit_price|currency',
    'retail_price|currency',
    'retail_unit_price|currency',
    'savings|currency',
    'unit_savings|currency',
    'total_discount_percent',
    'product_name',
    'product_image',
    'product_tagged_image',
    'upsale_name',
    'upsale_price|currency',
    'upsale_quantity',
    'upsale_total_price|currency',
    'upsale_image',
    'order_id',
    'order_shipping_price|currency',
    'rebill_amount|currency',
    'next_bill_date',
    'billing_interval_days'
  ],
  [CustomStepCategoryEnum.RefundWithDiscount]: [
    'brand_name',
    'first_name',
    'price|currency',
    'shipping_price|currency',
    'total_price|currency',
    'original_price|currency',
    'original_shipping_price|currency',
    'discount|currency',
    'discount_price|currency',
    'discount_percent',
    'unit_price|currency',
    'retail_price|currency',
    'retail_unit_price|currency',
    'savings|currency',
    'unit_savings|currency',
    'email',
    'product_name',
    'product_image',
    'product_tagged_image',
    'return_by_date',
    'return_days',
    'rebill_amount|currency',
    'next_bill_date',
    'billing_interval_days',
    'order_create_date',
    'offer_name',
    'offer_terms'
  ],
  [CustomStepCategoryEnum.OfferDetails]: [
    'brand_name',
    'first_name',
    'price|currency',
    'total_price|currency',
    'original_price|currency',
    'unit_price|currency',
    'retail_price|currency',
    'retail_unit_price|currency',
    'savings|currency',
    'unit_savings|currency',
    'product_name',
    'product_image',
    'product_tagged_image',
    'offer_name',
    'offer_terms',
    'rebill_amount|currency',
    'billing_interval_days',
    'return_by_date',
    'return_days',
    'order_create_date'
  ],
  [CustomStepCategoryEnum.SwapProduct]: [
    'brand_name',
    'first_name',
    'original_price|currency',
    'original_shipping_price|currency',
    'unit_price|currency',
    'retail_price|currency',
    'retail_unit_price|currency',
    'savings|currency',
    'unit_savings|currency',
    'product_name',
    'product_image',
    'product_tagged_image',
    'offer_name',
    'offer_terms',
    'rebill_amount|currency',
    'next_bill_date',
    'billing_interval_days'
  ],
  [CustomStepCategoryEnum.LossAversion]: [
    'brand_name',
    'first_name',
    'product_name',
    'product_image',
    'product_tagged_image',
    'offer_name',
    'offer_terms'
  ],
  [CustomStepCategoryEnum.PauseSub]: [
    'brand_name',
    'first_name',
    'price|currency',
    'discount|currency',
    'discount_price|currency',
    'rebill_amount|currency',
    'next_bill_date',
    'billing_interval_days',
    'return_by_date',
    'offer_name',
    'offer_terms'
  ],
  [CustomStepCategoryEnum.AdjustShippingFrequency]: [
    'brand_name',
    'first_name',
    'discount|currency',
    'product_name',
    'product_image',
    'product_tagged_image',
    'rebill_amount|currency',
    'next_bill_date',
    'billing_interval_days'
  ],
  [CustomStepCategoryEnum.PauseSurvey]: [
    'price|currency',
    'original_price|currency',
    'unit_price|currency',
    'retail_price|currency',
    'retail_unit_price|currency',
    'savings|currency',
    'unit_savings|currency',
    'rebill_amount|currency',
    'product_name',
    'product_image',
    'product_tagged_image'
  ],
  [CustomStepCategoryEnum.TimeForFree]: [
    'brand_name',
    'first_name',
    'price|currency',
    'total_price|currency',
    'original_price|currency',
    'discount|currency',
    'discount_price|currency',
    'rebill_amount|currency',
    'savings|currency',
    'product_name',
    'product_image',
    'product_tagged_image'
  ],
  [CustomStepCategoryEnum.ReturnOrderRMA]: [
    'brand_name',
    'first_name',
    'price|currency',
    'original_price|currency',
    'shipping_price|currency',
    'total_price|currency',
    'discount|currency',
    'discount_price|currency',
    'product_name',
    'product_image',
    'product_tagged_image',
    'return_by_date',
    'return_days',
    'restock_fee|currency'
  ],
  [CustomStepCategoryEnum.ExtendTrial]: [
    'brand_name',
    'first_name',
    'price|currency',
    'original_price|currency',
    'shipping_price|currency',
    'total_price|currency',
    'discount|currency',
    'discount_price|currency',
    'product_name',
    'product_image',
    'product_tagged_image',
    'exchange_days',
    'restock_fee|currency'
  ]
} as const;


export enum PopupCloseSetting {
  NoClose = 1,
  Undo = 2,
  FirstInput = 3,
  LastInput = 4
}

export const PopupCloseSettingLabels = {};
PopupCloseSettingLabels[PopupCloseSetting.NoClose] = 'Hide close icon';
PopupCloseSettingLabels[PopupCloseSetting.Undo] = 'Undo to previous step';
PopupCloseSettingLabels[PopupCloseSetting.FirstInput] = 'First Input - Accept';
PopupCloseSettingLabels[PopupCloseSetting.LastInput] = 'Last Input - Reject';
