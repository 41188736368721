import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router, ActivatedRoute} from '@angular/router';
import {
  UserService,
  AuthenticationService,
  CampaignService,
  AlertService,
  GuiService,
  AccountService
} from "../_services";
import {User, Account} from '../_models';
import {Form} from '../_forms'
import {Location} from '@angular/common';
import {takeUntil} from "rxjs/operators";
import { SideNavService } from '../_services/sidenav.service';


@Component({
  moduleId: module.id.toString(),
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent extends Form implements OnInit {
  currentUser: User;
  showAddButton: boolean = false;
  isSidebarExpanded: boolean = false;
  isHybridCampaign: boolean = false;
  showSideNavToggle: boolean = true;
  selectedAccount: Account;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private authService: AuthenticationService,
    private campaignService: CampaignService,
    private userService: UserService,
    private guiService: GuiService,
    private sideNavService: SideNavService,
    private route: ActivatedRoute,
    protected accountService: AccountService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.router.events.subscribe(data => {
      if (data instanceof NavigationStart) {
        this.isHybridCampaign = data.url === '/hybrid-campaigns';
        this.showAddButton = this.isHybridCampaign || (data.url === '/campaigns');
      }
    });

    this.userService.getCurrent().pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        },
        error => {
          this.handleError(error);
        }
      );

    this.guiService.getGuiDataObs().subscribe(data => {

      if ('title' in data) {
        this.title = data.title;
      }
    })
    this.sideNavService.getSideNavObs().subscribe(data => {
      if ('isSideNavExpanded' in data) {
        this.isSidebarExpanded = data.isSideNavExpanded;
      }

      if ('showSideNavToggle' in data) {
        this.showSideNavToggle = data.showSideNavToggle
      }
    })

    this.sideNavService.setSideNavExpanded(this.isSidebarExpanded)
    this.sideNavService.setShowSideNavToggle(this.showSideNavToggle)
    this.accountService.selectedAccount$.subscribe((account: Account) => {
      this.selectedAccount = account;
      if (this.currentUser) {
        this.currentUser.account = account;
      }
    })
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  gotoAccountPage(page) {
    this.campaignService.hideMenu();
    let path = null;

    switch (page) {
      case 'subscription':
        path = ['/user', 'subscribe', 'edit'];
        break;

      case 'payment':
        path = ['/payment', 'sources'];
        break;

      case 'plan':
        path = ['/plan', 'edit', this.currentUser.account.plan.id];
        break;

      default:
        path = ['/' + page];
        break;
    }

    if (path) {
      this.router.navigate(path);
    }
  }


  logout() {
    this.authService.logout();
    this.router.navigate(['/user', 'login']);
    document.getElementById("footer").className = "main-footer text-center";
  }

  toggleSidebar() {
    this.sideNavService.setSideNavExpanded(!this.isSidebarExpanded);
  }
}
