import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, InvoiceTransactionService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {InvoiceChargeType, Transaction} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'invoice-transaction-refund-form',
  templateUrl: './invoice-transaction-refund.component.html'
})
export class InvoiceTransactionRefundComponent extends CrudSavePopupComponent implements OnInit {
  transaction: Transaction;
  chargeTypes: { value: string, label: string }[] = [];

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected transactionService: InvoiceTransactionService,
              protected alertService: AlertService
  ) {
    super(router, location, route, transactionService, alertService);
    this.objectName = 'Transaction';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      type: [InvoiceChargeType.Full, Validators.required],
      is_external: [false]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.transaction = this.inputData;

    if (this.transaction && this.transaction.is_refundable) {
      let chargeTypes = [
        {value: InvoiceChargeType.Full, label: 'Total Refund: $' + this.transaction.refundable_amount},
        {value: InvoiceChargeType.Custom, label: 'Other Amount'},
      ];

      // rebuild the amount control based off the invoice
      if ('amount' in this.form.controls) {
        this.form.removeControl('amount');
      }

      this.form.addControl('amount', new FormControl(this.transaction.refundable_amount,[Validators.required,
        Validators.min(0.01), Validators.max(Number(this.transaction.refundable_amount))]));

      this.chargeTypes = chargeTypes;
    }
  }

  isCustomRefund() {
    if (this.form) {
      const control = this.form.get('type');

      if (control) {
        return control.value === InvoiceChargeType.Custom;
      }
    }
  }

  onSubmit() {
    if (this.form.value.is_external || window.confirm('Are you sure you want to refund this transaction?')) {
      this.submitted = true;

      if (this.form.valid) {
        let amount = this.form.value.amount;  // default to custom amount entered

        if (this.form.value.type === InvoiceChargeType.Full) {
          amount = null; // full amount
        }

        this.transactionService.refund(this.transaction.id, amount, this.form.value.is_external)
          .subscribe(
            data => {
              this.alertService.success('Refund Successful.');
              this.onSaveComplete(data);
            },
            error => {
              this.handleSubmitError(error);
              this.loading = false;
            });
      }
    }
  }

}
