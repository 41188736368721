import { Component, OnInit } from '@angular/core';
import {LogsContainerAbstractComponent} from "./logs-container.abstract.component";
import {FormBuilder} from "@angular/forms";
import {AlertService} from "../_services";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  templateUrl: './email-logs-container.component.html',
  styleUrls: ['./logs.component.css']
})
export class EmailLogsContainerComponent extends LogsContainerAbstractComponent implements OnInit {
  constructor(
    protected alertService: AlertService,
    protected router: Router,
    protected location: Location,
    protected formBuilder: FormBuilder,
  ) {
    super(alertService, router, location, formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
