import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelService, FunnelStepGroupService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {FunnelStepGroup, Funnel, Pager} from "../_models";
import {config} from "../../config/config";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  selector: 'funnel-step-group-form',
  templateUrl: './funnel-step-group-form.component.html'
})
export class FunnelStepGroupFormComponent extends CrudSavePopupComponent implements OnInit {
  lifelines: Funnel[] = [];
  group: FunnelStepGroup;

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected groupService: FunnelStepGroupService,
              protected alertService: AlertService,
              protected funnelService: FunnelService
  ) {
    super(router, location, route, groupService, alertService);
    this.objectName = 'Group';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null, Validators.required],
      classes: [null],
      lifeline: [null],
      exit_lifeline: [null],
      error_lifeline: [null],
      lifeline_enabled: [true]
    }));

    super.ngOnInit();

    this.funnelService.list({resourcetype: 'Lifeline', page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.lifelines = data.results;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  protected onInputDataChanged() {
    this.group = this.inputData;

    if (this.group) {
      if (this.group.classes !== null) {
        // convert the classes array into a class string
        this.form.patchValue({classes: this.group.classes.join(' ')});
      }
    }
  }

  protected getFormData() {
    const classStr = this.form.value.classes ? this.form.value.classes.trim(): '';
    let classes = [];

    // convert the class string into an array of unique values
    if (classStr.length) {
      classes = classStr.split(' ').filter((value, index, self) => self.indexOf(value) === index);
    }

    return Object.assign({}, this.form.value, {classes: classes});
  }

}
