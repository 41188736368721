import {Component} from "@angular/core";
import {CrudSaveComponent} from "../_directives";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {
  AccountService,
  AlertService,
  PersonalInfoService,
  StorageService,
  UserService
} from "../_services";
import {FormBuilder, Validators} from "@angular/forms";
import {User} from "../_models";
import {CRMChoices, SupportTickets} from "../register/enums";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent extends CrudSaveComponent {
  public user: User = null;
  crmChoices = CRMChoices;
  supportTicketChoices = SupportTickets;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected crudService: PersonalInfoService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    protected storageService: StorageService,
    protected accountService: AccountService
  ) {
    super(router, location, route, crudService, alertService);
    this.isNew = false
    this.objectName = 'personal info'
    this.title = 'Personal Info'
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      phone: [null, null],
      account: this.formBuilder.group({
        name: [null, Validators.required],
        website: [null, Validators.required],
        current_crm: [null, Validators.required],
        monthly_support_tickets: [null, Validators.required],
        industry: [null, Validators.required]
      })
    })
    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.id = user.id;
        if (user.is_staff && user.account.owner_id) {
          this.id = user.account.owner_id
        }
        this.user = user
        this.crudService.get(this.id)
          .subscribe((data: User) => {
            this._data$.next(data)
            this.form.patchValue(data);
          })
      },error => {
        this.handleError(error)
      })
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      if (this.form.value.account) {
        // update account first, then the user
        this.accountService.update(this.user.account.id, this.form.value.account).subscribe(
          data => super.onSubmit(),
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
      }
      else {
        super.onSubmit();
      }
    }
  }

  protected onSaveComplete(data) {
    this.storageService.set('currentUser', {...this.user, ...data})
    this._data$.next(data)
    this.loading = false
    window.scroll(0, 0);
  }
}
