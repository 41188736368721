import {IntentConfirmationAgreeContentText, StepCategory} from '../_models/funnel';
import { Component, OnInit } from '@angular/core';
import { CrudSaveComponent } from '../_directives';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  AlertService,
  FunnelInputService,
  FunnelService,
  FunnelStepService,
} from '../_services';
import { FormBuilder, Validators } from '@angular/forms';
import {
  Audience,
  CustomStepCategoryEnum,
  Funnel,
  FunnelInput,
  FunnelInputTypeEnum,
  OfferTypes,
  FunnelStep,
  IntentConfirmationAgreeHeadlineText,
  IntentConfirmationRejectHeadlineText,
  OfferIntents,
  OfferIntentTypeLabels,
  Pager,
  PathNewStepSelection,
  PathSteps,
  ConfirmationResponseStepName,
  OfferIntentChildTypes,
  OfferIntentChildTypeLabels,
  generateStep,
} from '../_models';
import { config } from '../../config/config';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AudienceService } from '../_services';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  moduleId: module.id.toString(),
  styleUrls: ['./path-edit.component.scss'],
  templateUrl: './path-edit.component.html',
})
export class PathNewComponent extends CrudSaveComponent implements OnInit {
  public audiences: Audience[] = [];
  public offerIntentOptions: { value: string; label: string }[] = [];
  public funnel: Funnel;
  public pageTitle: string = 'Create new path';
  public popupTitle: string = 'Add a new step';
  public isPublish: boolean = false;
  public isPathCreated: boolean = false;
  public dynamicSteps: FunnelStep[] = [];
  public confirmationStep: FunnelStep = null;
  public rejectResponseStep: FunnelStep = null;
  public acceptResponseStep: FunnelStep = null;
  public staticSteps: FunnelStep[] = [];
  public stepInputsHavingNextStep = {};
  public isNewPath: boolean = true;
  public pathNewStepSelection = PathNewStepSelection;
  public PathSteps = PathSteps;
  public pathSteps = {
    survey: '',
    downsell: '',
    upsell: '',
    // support: '', to be added
    troubleshooter: '',
  };
  public intentChildTypes: OfferIntents[] = []

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected audienceService: AudienceService,
    protected modalService: NgxSmartModalService,
    protected stepService: FunnelStepService,
    protected inputService: FunnelInputService
  ) {
    super(router, location, route, funnelService, alertService);
    this.isNew = true;
    this.objectName = 'Path';
    this.stepInputsHavingNextStep[FunnelInputTypeEnum.EnterProductFunnel] = [
      FunnelInputTypeEnum.EnterProductFunnel,
      FunnelInputTypeEnum.PathNotFound,
    ];
    this.stepInputsHavingNextStep[FunnelInputTypeEnum.EnterTroubleshooter] = [
      FunnelInputTypeEnum.EnterTroubleshooter,
      FunnelInputTypeEnum.PathNotFound,
    ];
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      offer_intent: [null, [Validators.required]],
      audience: [null],
      resourcetype: ['ProductFunnel', null],
      is_visual: [true],
      offer_type: [OfferTypes.SubPath],
      child_intent: [null]
    });
    super.ngOnInit();

    this.audienceService
      .list({ page: 1, page_size: config.maxPageSize, legacy: false })
      .subscribe(
        (result: Pager) => {
          this.audiences = result.results;
        },
        (error) => {
          this.handleError(error);
        }
      );

    Object.keys(OfferIntentTypeLabels).forEach((intentType) => {
      if (parseInt(intentType, 10) !== OfferIntents.Legacy) {
        this.offerIntentOptions.push({
          value: intentType,
          label: OfferIntentTypeLabels[intentType],
        });
      }
    });
  }

  isStaticStep(step: FunnelStep) {
    return (
      this.isConfirmation(step) ||
      step.type === FunnelInputTypeEnum.InputActionStatus
    );
  }

  populateExistingSteps(steps: FunnelStep[]) {
    let ignoredSteps = [];
    let toBeAddedInStaticSteps = [];
    steps.forEach((step) => {
      if (
        this.isStaticStep(step) ||
        toBeAddedInStaticSteps.indexOf(step.id) > -1
      ) {
        this.staticSteps.push(step);

        if (this.isConfirmation(step)) {
          this.confirmationStep = step;
        }
        toBeAddedInStaticSteps = toBeAddedInStaticSteps.concat(
          step.inputs.map((input) => input.next_step)
        );
        return;
      }

      if (step.is_first_step || ignoredSteps.indexOf(step.id) < 0) {
        this.dynamicSteps.push(step);
      }
      if (this.isSurvey(step)) {
        ignoredSteps = ignoredSteps.concat(
          step.inputs.map((input) => input.next_step)
        );
      }
    });
    this.rejectResponseStep = this.staticSteps.find((step) =>
      step.type === FunnelInputTypeEnum.InputActionStatus && step.category === FunnelInputTypeEnum.ConfirmationNo)
    this.acceptResponseStep = this.staticSteps.find((step) =>
      step.type === FunnelInputTypeEnum.InputActionStatus && step.category === FunnelInputTypeEnum.Confirmation)

    this.staticSteps = this.staticSteps.sort((a, b) =>
      a.category > b.category ? -1 : 1
    ).filter((step) => step.type !== FunnelInputTypeEnum.InputActionStatus)

    this.activeSteps(this.dynamicSteps);
  }

  onPublish() {
    this.isPublish = true;
    this.onSubmit();
  }
  redirectToAudience() {
    const selectedAudience = this.form.value.audience;
    const url = this.router.serializeUrl(this.router.createUrlTree(selectedAudience ? ['audiences', 'edit', selectedAudience] : ['/audiences']));
    window.open(url, '_blank');
  }

  activeSteps(step: FunnelStep[]) {
    step.forEach((step) => {
      switch (step.category) {
        case FunnelInputTypeEnum.EnterProductFunnel:
          this.pathSteps.downsell = PathSteps.Downsell;
          break;
        case FunnelInputTypeEnum.EnterTroubleshooter:
          this.pathSteps.troubleshooter = PathSteps.Troubleshooter;
          break;
        case CustomStepCategoryEnum.UpsellOrder:
          this.pathSteps.upsell = PathSteps.Upsell;
          break;
      }
      if (this.isSurvey(step)) {
        this.pathSteps.survey = PathSteps.Survey;
      }
    });
  }

  getPathType() {
    return OfferIntentTypeLabels[this.funnel.offer_intent];
  }

  getDefaultPathStepModalData() {
    return { step_type: PathNewStepSelection.Survey };
  }

  navigateBackToPaths() {
    this.router.navigate(['/new-paths']);
  }

  openStepModal() {
    // if we haven't saved the funnel yet, save first so the steps can be created
    if (!this.funnel) {
      this.isPathCreated = true;
      this.onSubmit();
    } else {
      this.modalService.getModal('newPathStep').open();
    }
  }

  closeStepModal() {
    this.modalService.getModal('newPathStep').close();
  }

  isSurvey(step: FunnelStep) {
    const surveyCategories: StepCategory[] = [
      CustomStepCategoryEnum.Survey,
      CustomStepCategoryEnum.CancelOrderSurvey,
      CustomStepCategoryEnum.CancelTrialSurvey,
      CustomStepCategoryEnum.CancelSubSurvey,
      CustomStepCategoryEnum.ReturnSurvey,
      CustomStepCategoryEnum.PauseSurvey,
    ];

    return surveyCategories.indexOf(step.category) > -1;
  }

  isConfirmation(step: FunnelStep) {
    return (
      [
        FunnelInputTypeEnum.Confirmation,
        FunnelInputTypeEnum.ConfirmationNo,
      ].indexOf(step.type) > -1
    );
  }

  newPathStepSelected(step_type: PathNewStepSelection) {
    this.closeStepModal();
    let saveStep = false;
    let step = null;

    switch (step_type) {
      case PathNewStepSelection.Survey:
        step = generateStep(
          FunnelInputTypeEnum.Radio,
          this.funnel,
          CustomStepCategoryEnum.Survey
        );
        this.pathSteps.survey = PathSteps.Survey;
        break;
      case PathNewStepSelection.DownsellOffer:
        step = generateStep(
          FunnelInputTypeEnum.EnterProductFunnel,
          this.funnel,
          FunnelInputTypeEnum.EnterProductFunnel
        );
        saveStep = true;
        this.pathSteps.downsell = PathSteps.Downsell;
        break;
      case PathNewStepSelection.UpsellOffer:
        step = generateStep(
          FunnelInputTypeEnum.EnterProductFunnel,
          this.funnel,
          CustomStepCategoryEnum.UpsellOrder
        );
        saveStep = true;
        this.pathSteps.upsell = PathSteps.Upsell;
        break;
      case PathNewStepSelection.Troubleshooter:
        step = generateStep(
          FunnelInputTypeEnum.EnterTroubleshooter,
          this.funnel,
          FunnelInputTypeEnum.EnterTroubleshooter
        );
        saveStep = true;
        this.pathSteps.troubleshooter = PathSteps.Troubleshooter;
        break;
      default:
        return;
    }

    if (saveStep && step) {
      this.stepService.create(step).subscribe(
        (stepObject: FunnelStep) => {
          if (stepObject) {
            this.dynamicSteps.push(stepObject);
            if (stepObject.is_first_step) {
              this.onFirstStepsCreated([stepObject]);
            } else {
              this.adjustStepInputs(stepObject);
            }
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
    } else {
      if (!this.dynamicSteps.some((Step) => Step.id === step.id)) {
        this.dynamicSteps.push(step);
      }
    }
  }

  adjustStepInputs(newStep: FunnelStep) {
    let previousInputs = this.confirmationStep.previous_inputs;
    this.pointInputsToNextStep(previousInputs, newStep.id);
    let newStepInputs = [];

    if (this.isSurvey(newStep)) {
      let childStepIds = newStep.inputs.map((input) => input.next_step);
      let childSteps = this.funnel.steps.filter(
        (step) => childStepIds.indexOf(step.id) > -1
      );
      childSteps.forEach((step) => {
        let inputTypes = this.stepInputsHavingNextStep[step.type];
        if (inputTypes) {
          newStepInputs = newStepInputs.concat(
            step.inputs
              .filter((input) => inputTypes.indexOf(input.type) > -1)
              .map((input) => input.id)
          );
        } else {
          newStepInputs.push(step.inputs[0].id);
        }
      });
    } else if (
      [
        FunnelInputTypeEnum.EnterProductFunnel,
        FunnelInputTypeEnum.EnterTroubleshooter,
      ].indexOf(newStep.type) > -1
    ) {
      let inputTypes = this.stepInputsHavingNextStep[newStep.type];
      if (inputTypes) {
        newStepInputs = newStepInputs.concat(
          newStep.inputs
            .filter((input) => inputTypes.indexOf(input.type) > -1)
            .map((input) => input.id)
        );
      } else {
        newStepInputs.push(newStep.inputs[0].id);
      }
    }

    if (newStepInputs.length > 0) {
      this.pointInputsToNextStep(newStepInputs, this.confirmationStep.id);
    }
  }

  protected onSaveComplete(data: Funnel) {
    const openStepModal = !this.funnel;
    this.funnel = data;
    this.loading = false;
    this.stepService.setFunnelId(this.funnel.id);
    if (openStepModal && this.isPathCreated) {
      this.openStepModal();
    } else {
      super.onSaveComplete(data);
    }
  }

  pointInputsToNextStep(
    inputs: (string | number)[],
    next_step_id: string | number,
    callback: () => void = () => {}
  ) {
    const apiCalls = [];
    inputs.forEach((input) => {
      apiCalls.push(
        this.inputService.patch(input, { next_step: next_step_id })
      );
    });

    forkJoin(apiCalls).subscribe(
      (_) => {
        callback();
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  createConfirmationResponses() {
    const stepsToCreate = [];
    this.confirmationStep.inputs.forEach((input: FunnelInput) => {
      const step = generateStep(
        FunnelInputTypeEnum.InputActionStatus,
        this.funnel,
        input.type
      );
      step['parent_input'] = input.id;
      step['name'] = ConfirmationResponseStepName[input.type]
      step.allow_undo = false

      if (input.type === FunnelInputTypeEnum.Confirmation) {
        step.label = IntentConfirmationAgreeHeadlineText[this.funnel.offer_intent];
        step.content = IntentConfirmationAgreeContentText[this.funnel.offer_intent];
      } else if (input.type === FunnelInputTypeEnum.ConfirmationNo) {
        step.label = IntentConfirmationRejectHeadlineText[this.funnel.offer_intent];
      }
      stepsToCreate.push(step);
    });

    this.stepService.bulk_create(stepsToCreate)
      .subscribe((_) => {
        this.router.navigate(['/new-paths', 'edit', this.funnel.id]);
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  createConfirmationStep(
    inputsPointingToConfirmationStep: (string | number)[]
  ) {
    const step = generateStep(
      FunnelInputTypeEnum.Confirmation,
      this.funnel,
      FunnelInputTypeEnum.Confirmation
    );
    this.stepService.create(step).subscribe(
      (confirmationStep: FunnelStep) => {
        this.confirmationStep = confirmationStep;
        this.pointInputsToNextStep(
          inputsPointingToConfirmationStep,
          this.confirmationStep.id
        );
        this.createConfirmationResponses();
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  public onFirstStepsCreated(steps: FunnelStep[]) {
    let inputsPointingToConfirmationStep: (string | number)[] = [];

    steps.forEach((step: FunnelStep) => {
      if (step.type in this.stepInputsHavingNextStep) {
        const nextStepInputs = this.stepInputsHavingNextStep[step.type];
        inputsPointingToConfirmationStep =
          inputsPointingToConfirmationStep.concat(
            step.inputs
              .filter((input) => nextStepInputs.indexOf(input.type) > -1)
              .map((input) => input.id)
          );
      }
    });

    if (!this.confirmationStep) {
      this.createConfirmationStep(inputsPointingToConfirmationStep);
    } else {
      this.pointInputsToNextStep(
        inputsPointingToConfirmationStep,
        this.confirmationStep.id
      );
    }
  }

  getNextStepId(index: number) {
    index = index + 1;

    // last step of dynamic steps will point to the confirmation step
    if (index >= this.dynamicSteps.length && this.confirmationStep) {
      return this.confirmationStep.id;
    }

    return this.dynamicSteps[index] ? this.dynamicSteps[index].id : null;
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.dynamicSteps,
      event.previousIndex,
      event.currentIndex
    );
  }

  onStepDelete(data: { step: FunnelStep; next_step: string | number }) {
    // if the step is not created yet, just pop out the last entry of dynamic steps
    if (!('id' in data.step)) {
      this.dynamicSteps.pop();
      this.onStepDeletion(data.step)
      this.loading = false;
      return;
    }

    let apiCall = this.stepService.delete(data.step.id);
    let extraCalls = [];
    // if deleting a survey step, delete its child steps i-e enter product path steps
    if (this.isSurvey(data.step)) {
      data.step.inputs.forEach((input) => {
        extraCalls.push(
          mergeMap((value, index) => this.stepService.delete(input.next_step))
        );
      });
    }

    if (extraCalls.length) {
      // @ts-ignore
      apiCall = apiCall.pipe(...extraCalls);
    }

    // if the deleted step is from the middle, we will assign the previous inputs to the next step of the deleting step
    let previousInputs = data.step.previous_inputs;
    if (previousInputs.length > 0 && data.next_step) {
      this.pointInputsToNextStep(
        previousInputs,
        data.next_step,
        this.sendDeletionCalls(apiCall, data.step)
      );
    } else {
      this.sendDeletionCalls(apiCall, data.step)();
    }
  }
  onStepLoading(data: { loading: boolean }) {
    this.loading = data.loading;
  }

  onStepDeletion(step: FunnelStep) {
    switch (step.category) {
      case FunnelInputTypeEnum.EnterProductFunnel:
        this.pathSteps.downsell = '';
        break;
      case FunnelInputTypeEnum.EnterTroubleshooter:
        this.pathSteps.troubleshooter = '';
        break;
      case CustomStepCategoryEnum.UpsellOrder:
        this.pathSteps.upsell = '';
        break;
    }
    if (this.isSurvey(step)) {
      this.pathSteps.survey = '';
    }
  }

  sendDeletionCalls(apiCall, step: FunnelStep) {
    this.onStepDeletion(step);
    return () => {
      apiCall.subscribe(
        (_) => {
          if (!this.isSurvey(step)) {
            this.dynamicSteps = this.dynamicSteps.filter(
              (item) => item.id !== step.id
            );
            this.loading = false;
          } else {
            // reload the page if multiple steps were deleted so that the whole view can be adjusted according to new setting
            this.loading = false;
            window.location.reload();
          }
        },
        (error) => {
          this.loading = false;
          this.handleError(error);
        }
      );
    };
  }

  isPathPublished(): boolean {
    return this.funnel && this.funnel.is_modified;
  }

  isAllStepsSelected(): boolean {
    for (let key in this.pathSteps) {
      if (this.pathSteps.hasOwnProperty(key) && !this.pathSteps[key]) {
        return false;
      }
    }
    return true;
  }

  onOfferIntentChanged(offerIntent) {
    const childIntentControl = this.form.get('child_intent');

    childIntentControl.setValidators(
      +offerIntent === OfferIntents.CancelOrder ? [Validators.required] : []
    );

    childIntentControl.updateValueAndValidity();

    this.intentChildTypes = OfferIntentChildTypes[offerIntent] || [];
  }

  getChildLabel(offerIntent) {
    return OfferIntentChildTypeLabels[offerIntent] || ''
  }

  protected getFormData(): any {
    let data = super.getFormData();

    if (this.intentChildTypes.length > 0) {
      data['offer_intent'] = data['child_intent']
    }

    return data
  }
}
