import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, StyleService, CampaignService } from '../_services';
import { CrudPagedListComponent } from '../_directives';
import { Style, Campaign } from '../_models';
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.css']
})
export class StyleComponent extends CrudPagedListComponent implements OnInit {
  styles$: Observable<Style[]> = this.data$;
  campaign: Campaign;
  campaignStylesMap: {} = {};
  selectStylesFlag = false;
  loading = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected styleService: StyleService,
    protected alertService: AlertService,
    private campaignService: CampaignService
  ) {
    super(router, location, route, styleService, alertService);
    this.objectName = 'theme';
    this.title = 'Themes';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected onRouteParams(params: {}) {
    let campaign_id = null;

    this.title = 'Themes';
    this.selectStylesFlag = false;
    this.campaignStylesMap = {};
    this.campaign = null;

    if ('campaigns' in params) {
      campaign_id = params['campaigns'];
    }
    else if ('select_for_campaign' in params) {
      campaign_id = params['select_for_campaign'];
      this.selectStylesFlag = true;
      this.title = 'Select Themes';
      this.removeFilter('campaigns'); //make sure this filter is removed so we can select from all campaigns
    }

    if (campaign_id) {
      this.campaignService.get(campaign_id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (campaign: Campaign) => {
            this.campaign = campaign;
            this.title += ' for ' + campaign.name;

            for (let style_id of campaign.styles) {
              this.campaignStylesMap[style_id] = true;
            }
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  isEnabled(style: Style) {
    if (this.campaign && style.id in this.campaignStylesMap) {
      return true;
    }

    return false;
  }

  enable(style: Style, event) {
    let enabled: boolean = event.target.checked;

    if (this.campaign) {
      if (enabled) {
        this.campaignStylesMap[style.id] = true;
      }
      else if (style.id in this.campaignStylesMap) {
        delete this.campaignStylesMap[style.id];
      }
    }
  }

  save() {
    if (this.campaign && this.selectStylesFlag) {
      this.loading = true;

      this.campaignService.patch(this.campaign.id, {styles: Object.keys(this.campaignStylesMap)})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.alertService.success('Updated styles for ' + this.campaign.name + '.', true);
            this.router.navigate(['/campaign', 'edit', this.campaign.id]);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

}
