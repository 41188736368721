import {Component} from '@angular/core';
import {RelatedProductSetCategoryNewComponent} from './related-product-set-category-new.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, RelatedProductSetCategoryService, RelatedProductSetService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {RelatedProductSetCategory} from '../_models';
import {takeUntil} from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  selector: 'exchangeable-product-set-category-edit',
  templateUrl: './related-product-set-category-edit.component.html'
})
export class RelatedProductSetCategoryEditComponent extends RelatedProductSetCategoryNewComponent {

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected relatedProductSetCategoryService: RelatedProductSetCategoryService,
    protected relatedProductSetService: RelatedProductSetService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
  ) {
    super(router, location, route, relatedProductSetCategoryService, relatedProductSetService, alertService, formBuilder);
    this.isNew = false
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true
    this.data$.subscribe((data: RelatedProductSetCategory) => {
      if (data) {
        this.loading = false
        this.relatedProductSetCategory = data
        this.parent = data.parent_id
        this.form.patchValue({name: data.name})
      }
    })
  }

  getUrlParams(fieldName: string) {
    let obj = {}

    if (this.relatedProductSetCategory) {
      obj[fieldName] = this.relatedProductSetCategory.id
      return obj
    }

    return obj
  }
}
