import {Address, BaseModel, PhoneNumberTypeEnum} from './index';

export enum SipRegion {
  Default = 0,
  USEast = 1,
  USWest = 2,
  Ireland = 3,
  Germany = 4,
  Singapore = 5,
  Japan = 6,
  Brazil = 7,
  Australia = 8
}

export const SipRegionLabels = [
  'Default',                  // 0
  'North America Virginia',   // 1
  'North America Oregon',     // 2
  'Europe Ireland',           // 3
  'Europe Frankfurt',         // 4
  'Asia Pacific Singapore',   // 5
  'Asia Pacific Tokyo',       // 6
  'South America São Paulo',  // 7
  'Asia Pacific Sydney'       // 8
];

export interface SipIpAddress extends BaseModel {
  ip_address: string;
  cidr_length: number;
}

export interface SipCredential extends BaseModel {
  username: string;
  password: string;
}

export interface SipDomain extends BaseModel {
  twilio_inbound_uri: string;
  inbound_secure: boolean;
  outbound_uri: string;
  outbound_secure: boolean;
  outbound_refer: boolean;
  outbound_username: string;
  outbound_password: string;
  region: SipRegion;
  ip_addresses: SipIpAddress[];
  credentials: SipCredential[];
}
