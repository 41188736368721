import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignProductService, CampaignService, FunnelService} from '../_services';
import {CrudSaveComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {
  Campaign,
  Funnel,
  Pager,
  SessionType,
  initializeSessionTypeFunnels,
  CampaignProduct,
  ImageSize,
  ShowMarketplaceSetting,
  ShowMarketplaceSettingOptions,
  getCampaignProductImageUrl,
} from "../_models";
import {config} from '../../config/config';
import {EMPTY} from "rxjs";
import {mergeMap, takeUntil} from "rxjs/operators";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-marketplace.component.html'
})
export class CampaignMarketplaceComponent extends CrudSaveComponent implements OnInit {
  isNewCampaign = false;
  campaign: Campaign;
  campaignProducts: CampaignProduct[] = null;
  campaignProductsModalInput: CampaignProduct[] = null;
  selectedCampaignProduct: CampaignProduct;
  showMarketplaceSettings = ShowMarketplaceSettingOptions;

  private assignedFunnels: {} = {};
  private funnels: {} = {};

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    private funnelService: FunnelService,
    private formBuilder: FormBuilder,
    private productService: CampaignProductService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = 'Setup Brand Marketplace';
    this.isPartial = true;
    this.objectName = 'Brand';

    initializeSessionTypeFunnels(this.funnels);
    initializeSessionTypeFunnels(this.assignedFunnels);
  }

  ngOnInit() {
    let selectedFunnelMap = {};

    this.form = this.formBuilder.group({
      visual_entry_funnels: [[]],
      show_marketplace: [ShowMarketplaceSetting.HideIfEmbedded, Validators.required],
    });

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        this.form.patchValue(campaign);
        this.campaign = campaign;
        this.campaignService.showMenu(campaign, 'marketplace', this.isNewCampaign, campaign.is_hybrid);
        const funnelIds = campaign.visual_entry_funnels;

        for (let funnel_id of funnelIds) {
          selectedFunnelMap[funnel_id] = true;
        }

        return this.funnelService.list({
          resourcetype: 'VisualFunnel',
          page: 1,
          page_size: config.maxPageSize
        });
      }

      return EMPTY;
    })).subscribe(
      (data: Pager) => {
        for (let funnel of data.results) {
          this.funnels[funnel.session_type].push(funnel);

          if (funnel.id in selectedFunnelMap) {
            this.assignedFunnels[funnel.session_type].push(funnel);
          }
        }

        this.getCampaignProducts();
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected getCampaignProducts(hasCampaignProduct = false) {
    if (this.campaign) {
      if (this.campaign.campaign_products.length) {
        this.productService.list({page: 1, page_size: config.maxPageSize, campaigns: this.campaign.id})
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (page: Pager) => {
              let products = page.results;

              // we got the products for this step, but now we need to sort them by step's campaign product ids
              products.sort((a: CampaignProduct, b: CampaignProduct) => {
                let productIds: any[] = this.campaign.campaign_products;

                return productIds.indexOf(a.id) - productIds.indexOf(b.id);
              });

              this.campaignProducts = products;
            },
            error => {
              this.handleError(error);
            }
          );
      }
      else if (!hasCampaignProduct) {
        this.campaignProducts = [];
      }
    }
  }

  addCampaignProduct() {
    this.campaignProductsModalInput = this.campaignProducts;
    this.modalService.getModal('selectProductsDialog').open();
  }

  onCampaignProductsSelected(products: CampaignProduct[]) {
    this.campaignProducts = products;
    this.modalService.getModal('selectProductsDialog').close();
  }

  removeCampaignProduct(productId) {
    this.campaignProducts = this.campaignProducts.filter(
      (product: CampaignProduct) => product.id != productId);
  }

  getCampaignProductImageUrl(campaignProduct) {
    return getCampaignProductImageUrl(campaignProduct, ImageSize.small);
  }

  copyCampaignProducts() {
    this.campaignService.copyProducts(this.campaignProducts);
  }

  canPasteCampaignProducts() {
    return this.campaignService.canPasteProducts();
  }

  pasteCampaignProducts() {
    this.campaignProducts = this.campaignService.pasteProducts();
  }

  editCampaignProduct(campaignProduct: CampaignProduct) {
    this.selectedCampaignProduct = campaignProduct;
    this.openEditCampaignProductDialog();
  }

  protected openEditCampaignProductDialog() {
    this.modalService.getModal('editCampaignProductDialog').open();
  }

  onSaveCampaignProduct(newItem: CampaignProduct) {
    this.selectedCampaignProduct = null; // to re-initialize the form, otherwise the form gets invalid
    this.modalService.getModal('editCampaignProductDialog').close();
    const index = (this.campaignProducts || []).findIndex((product) => newItem.id == product.id);
    if (index === -1) {
      this.campaignProducts = [];
    }
    this.getCampaignProducts(true);
  }

  onCloseCampaignProductDialog() {
    this.selectedCampaignProduct = null;
  }

  get marketplaceFunnels() {
    return this.funnels[SessionType.Marketplace];
  }

  get assignedMarketplaceFunnels() {
    return this.assignedFunnels[SessionType.Marketplace];
  }

  set assignedMarketplaceFunnels(funnel: Funnel[]) {
    this.assignedFunnels[SessionType.Marketplace] = funnel;
  }

  get paymentUpdateFunnels() {
    return this.funnels[SessionType.PaymentUpdate];
  }

  get assignedPaymentUpdateFunnels() {
    return this.assignedFunnels[SessionType.PaymentUpdate];
  }

  set assignedPaymentUpdateFunnels(funnel: Funnel[]) {
    this.assignedFunnels[SessionType.PaymentUpdate] = funnel;
  }

  protected getFormData() {
    let funnelIds = [];

    Object.values(this.assignedFunnels).forEach((assignedFunnels: Funnel[]) => {
      assignedFunnels.forEach((funnel: Funnel) => {
        funnelIds.push(funnel.id);
      });
    });

    if (this.campaignProducts) {
      this.form.value.campaign_products = this.campaignProducts.map((product: CampaignProduct) => product.id);
    }

    return Object.assign({}, this.form.value, {visual_entry_funnels: funnelIds});
  }

  protected onSaveComplete(data) {
    if (this.isNewCampaign) {
      this.campaignService.getNextMenuRoute().pipe(takeUntil(this.destroy$)).subscribe((route: string) => {
        this.navigate(['/campaign', route, this.id, {isNewCampaign: true}], {replaceUrl: true});
      });
    }
    else {
      this.goBack();
    }
  }

  getFunnelLabel(funnel: Funnel): string {
    return funnel.is_template ? 'System Template - ' + funnel.name : funnel.name;
  }

}
