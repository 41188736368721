import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, FaqTopicService, FaqService, FunnelStepService, WidgetService} from "../_services";
import {FormBuilder} from "@angular/forms";
import {Faq} from "../_models";
import {FaqNewComponent} from "./faq-new.component";
import {mergeMap} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqEditComponent extends FaqNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected topicService: FaqTopicService,
    protected faqService: FaqService,
    protected alertService: AlertService,
    protected stepService: FunnelStepService,
    protected widgetService: WidgetService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, topicService, faqService, alertService, stepService, widgetService, formBuilder);
    this.title = 'Edit FAQ';
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getTopics();
  }

  protected onTopicsReady(params?: {}) {
    this.data$.subscribe((faq: Faq) => {
        this.loading = false;
        if (faq) {
          this.faq = faq;

          if (faq.topics && faq.topics.length) {
            let topics = [];

            faq.topics.forEach(topicId => {
              topics.push({id: topicId, text: this.topicLabels[topicId]});
            });

            this.form.patchValue({topics: topics});
            delete faq.topics;
          }

          this.form.patchValue(faq);

          if (faq.keywords !== null) {
            // convert the keywords array into a string
            this.form.patchValue({keywords: faq.keywords.join(',')});
          }
        }
      },
      error => {
        this.loading = false
        this.handleError(error);
      }
    );
  }

  protected onRouteParams(params: {}) {
    this.loading = true
  }
}
