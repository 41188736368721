import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AccountService,
  AlertService,
  CampaignService,
  CampaignTrackingService,
  ReportsConfigService,
  ReportsService,
  UserService,
  CampaignProductService,
  CRMCampaignService,
  StorageService,
  FunnelStepService,
  RefundReportService,
  SessionSummaryReportService,
  MerchantService
} from '../_services';
import {FormBuilder} from "@angular/forms";
import {NgxSmartModalService} from "ngx-smart-modal";
import {
  Account,
} from "../_models";
import {ReportsAbstractComponent} from "./reports-abstract.component";

@Component({
  selector: 'app-reports',
  templateUrl: './templates/reports.component.html',
  styleUrls: ['./styles/reports.component.css'],
})
export class ReportsComponent extends ReportsAbstractComponent{
  public fields = {
    dimensions: [
      [
        {model: "lifeline", label: "Lifelines", default: false, disabled: false},
        {model: "lifeline_type", label: "Lifeline type", default: false, disabled: false},
        {model: "bypass", label: "Bypass", default: false, disabled: false},
        {model: "bypass_type", label: "Bypass type", default: false, disabled: false},
        {model: "source", label: "Path channel", default: false, disabled: false},
        {model: "sid1", label: "Source sub 1", default: false, disabled: false},
        {model: "sid2", label: "Source sub 2", default: false, disabled: false},
        {model: "sid3", label: "Source sub 3", default: false, disabled: false},
        {model: "funnel_item_status", label: "Order status", default: false, disabled: false},
        {model: "tracking_source", label: "Path channel source", default: false, disabled: false},
        {model: "campaign_name", label: "Brand", default: false, disabled: false},
        {model: "crm_campaign_name", label: "CRM campaign", default: false, disabled: false},
        {model: "crm_campaign_id", label: "CRM campaign ID", default: false, disabled: false},
        {model: "crm_type", label: "CRM name", default: false, disabled: false},
        {model: "category", label: "Category", default: false, disabled: false},
        {model: "product_name", label: "Product name", default: false, disabled: false},
        {model: "product_price", label: "Product price", default: false, disabled: false},
        {model: "session_type", label: "Session Type", default: false, disabled: false},
        {model: "campaign_product_id", label: "Campaign product id", default: false, disabled: false},
      ],
      [
        {model: "exchangeable_product_id", label: "Exchangeable product ID", default: false, disabled: false},
        {model: "exchangeable_product_price", label: "Exchangeable product price", default: false, disabled: false},
        {model: "status", label: "Status", default: false, disabled: false},
        {model: "result", label: "Session result", default: false, disabled: false},
        {model: "username", label: "Session ID", default: false, disabled: false},
        {model: "customer_id", label: "Customer ID", default: false, disabled: false},
        {model: "order_id", label: "Order ID", default: false, disabled: false},
        {model: "aff_id", label: "Affiliate", default: false, disabled: false},
        {model: "sub1", label: "Aff sub 1", default: false, disabled: false},
        {model: "sub2", label: "Aff sub 2", default: false, disabled: false},
        {model: "sub3", label: "Aff sub 3", default: false, disabled: false},
        {model: "country", label: "Country", default: false, disabled: false},
        {model: "cancel_reason", label: "Cancel reason", default: false, disabled: false},
        {model: "device_type", label: "Device type", default: false, disabled: false},
        {model: "message", label: "Message", default: false, disabled: false},
        {model: "browser_name", label: "Browser", default: false, disabled: false},
        {model: "os_version", label: "Device OS version", default: false, disabled: false},
      ],
      [
        {model: "mobile_vendor", label: "Device brand", default: false, disabled: false},
        {model: "mobile_model", label: "Device model", default: false, disabled: false},
        {model: "recording_url", label: "Video Recording", default: false, disabled: false},
        {model: "phone_recording_url", label: "Audio Recording", default: false, disabled: false},
        {model: "refund_status_conditional", label: "Refund status", default: false, disabled: false},
        {model: "frequency", label: "Frequency", default: false, disabled: false},
        {model: "carrier_type", label: "Phone Type", default: false, disabled: false},
        {model: "entered_input", label: "Key Prompts", default: false, disabled: false},
        {model: "action_result", label: "Session Action Result", default: false, disabled: false},
        {model: "latest_result", label: "Latest Result", default: false, disabled: false},
        {model: "latest_result_based_on_execution", label: "Latest result based on time", default: false, disabled: false},
        {model: "product_path", label: "Product Path", default: false, disabled: false},
        {model: "account_name", label: "Account", default: false, disabled: false},
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
        {model: "session_action_status", label: "Session Action Status", default: false, disabled: false},
        {model: "step_name", label: "Step Impressions", default: false, disabled: false},
        {model: "steps_count", label: "Steps Count", default: false, disabled: false},
      ],
      [
        {model: "input_name", label: "Input Name", default: false, disabled: false},
        {model: "inputs_count", label: "Input Count", default: false, disabled: false},
        {model: "autopick", label: "Autopick", default: false, disabled: false},
        {model: "is_recurring", label: "Recurring", default: false, disabled: false},
        {model: "is_trial", label: "Trial", default: false, disabled: false},
        {model: "is_billable", label: "Billable", default: false, disabled: false},
        {model: "order_date", label: "Order Timestamp", default: false, disabled: false},
        {model: "customer_name", label: "Customer Name", default: false, disabled: false},
        {model: "billing_interval_days", label: "Rebill Interval", default: false, disabled: false},
        {model: "is_save", label: "Save", default: false, disabled: false},
        {model: "initial_transaction_date", label: "Initial Rebill Date", default: false, disabled: false},
        {model: "rebill_date", label: "Rebill Date", default: false, disabled: false},
        {model: "sms_status", label: "SMS Status", default: false, disabled: false},
        {model: "is_order_active", label: "Order Active", default: false, disabled: false},
        {model: "step_category", label: "Step Category", default: false, disabled: false},
        {model: "path_aff_ids", label: "Affiliate Ids of Path", default: false, disabled: false},
        {model: "session_time", label: "Session Time", default: false, disabled: false},
      ],
    ],
    timeInterval: [
      [
        {model: "hour", label: "Hours", default: false, disabled: false},
        {model: "date", label: "Date", default: false, disabled: false},
        {model: "week", label: "Week", default: false, disabled: false},
        {model: "month", label: "Month", default: false, disabled: false},
        {model: "year", label: "Year", default: false, disabled: false},
      ],
    ],
    statistics: [
      [
        {model: "total_saves", label: "Total saves", default: false, disabled: false},
        {model: "hold_save", label: "Saves on Holds", default: false, disabled: false},
        {model: "subscription_save", label: "Saves on Subscriptions", default: false, disabled: false},
        {model: "rma_save", label: "Saves on RMAs", default: false, disabled: false},
        {model: "save_amount", label: "Save amount", default: false, disabled: false},
        {model: "session_count", label: "Session quantity", default: false, disabled: false},
        {model: "customer_count", label: "Customer Quantity", default: false, disabled: false},
        {model: "total_count_sum", label: "Action totals", default: false, disabled: false},
        {model: "amount_paid", label: "Total billed", default: false, disabled: false},
        {model: "cancelled", label: "Total cancels", default: false, disabled: false},
        {model: "hold_cancel", label: "Cancels on Holds", default: false, disabled: false},
      ],
      [
        {model: "subscription_cancel", label: "Cancels on Subscriptions", default: false, disabled: false},
        {model: "refund_quantity", label: "Refund quantity", default: false, disabled: false},
        {model: "refund_amount", label: "Refund amount", default: false, disabled: false},
        {model: "partial_refund_amount", label: "Partial refund amount", default: false, disabled: false},
        {model: "full_refund_amount", label: "Full refund amount", default: false, disabled: false},
        {model: "return_quantity", label: "Return quantity", default: false, disabled: false},
        {model: "return_amount", label: "Return amount", default: false, disabled: false},
        {model: "revenue", label: "Revenue", default: false, disabled: false},
        {model: "revenue_type", label: "Revenue type", default: false, disabled: false},
        {model: "sales", label: "Sales", default: false, disabled: true},
        {model: "help_desk_inquiries", label: "Help desk inquiries", default: false, disabled: true},
      ],
      [
        {model: "help_desk_inquiry_clicks", label: "Help desk inquiry clicks", default: false, disabled: true},
        {model: "help_desk_searches", label: "Help desk searches", default: false, disabled: true},
        {model: "downsell", label: "Downsells", default: false, disabled: false},
        {model: "downsell_attempt", label: "Downsell Attempts", default: false, disabled: false},
        {model: "upsell", label: "Upsells", default: false, disabled: false},
        {model: "revisions", label: "Exchangeables", default: false, disabled: false},
        {model: "handoff", label: "HandOffs", default: false, disabled: false},
        {model: "handled_handoff", label: "Handled by call center", default: false, disabled: false},
        {model: "deflected_hold_cancel", label: "Deflected Holds", default: false, disabled: false},
        {model: "deflected_subscription_cancel", label: "Deflected Subscriptions", default: false, disabled: false},
        {model: "deflected_rma", label: "Deflected RMAs", default: false, disabled: false},
      ],
      [
        {model: "retracted_save", label: "Retracted Saves", default: false, disabled: false},
        {model: "cancel_hold_request", label: "Cancel on hold requests", default: false, disabled: false},
        {model: "cancel_subscription_request", label: "Cancel on subscription requests", default: false, disabled: false},
        {model: "revise_request", label: "Revise requests", default: false, disabled: false},
        {model: "shipping_frequency_request", label: "Shipping Frequency requests", default: false, disabled: false},
        {model: "order_status_request", label: "Order Status requests", default: false, disabled: false},
        {model: "update_info_request", label: "Update Info requests", default: false, disabled: false},
        {model: "faqs_request", label: "FAQs requests", default: false, disabled: false},
        {model: "marketplace_request", label: "Marketplace requests", default: false, disabled: false},
        {model: "subscription_adjustment", label: "Subscription Adjustments", default: false, disabled: false},
      ]
    ],
    calculations: [
      [
        {model: "downsell_take_rate", label: "Downsell take rate", default: false, disabled: false},
        {model: "downsell_step_name", label: "Show Itemized by Downsell Step",
          default: false, disabled: false, dependentTo: 'downsell_take_rate'},
        {model: "exchangeable_take_rate", label: "Exchangeable take rate", default: false, disabled: false},
        {model: "upsell_take_rate", label: "Upsell take rate", default: false, disabled: false},
      ],
      [
        {model: "self_service_take_rate", label: "Self service take rate", default: false, disabled: true},
        {model: "total_save_rate", label: "Save rate", default: false, disabled: false},
        {model: "refund_rate", label: "Refund rate", default: false, disabled: false},
      ],
      [
        {model: "partial_refund_rate", label: "Partial refund rate", default: false, disabled: false},
        {model: "full_refund_rate", label: "Full refund rate", default: false, disabled: false},
        {model: "rma_rate", label: "RMA rate", default: false, disabled: false},
      ],
      [
        {model: "cancel_rate", label: "Cancel rate", default: false, disabled: false},
        {model: "help_desk_inquiry_rate", label: "Help desk inquiry helpful rate", default: false, disabled: true},
        {model: "inputs_take_rate", label: "Inputs take rate", default: false, disabled: false},
      ]
    ],
    exclude: [
      [
        {model: "exclude_tests", label: "Tests", default: true, disabled: false, isGlobal: false},
        {model: "exclude_no_refunds", label: "No refunds", default: false, disabled: false, isGlobal: false},
        {model: "exclude_bypass", label: "Bypass", default: false, disabled: false, isGlobal: false},
      ],
      [
        {model: "exclude_hangups", label: "Hangups", default: true, disabled: false, isGlobal: false},
        {model: "exclude_lifeline", label: "Lifeline", default: false, disabled: false, isGlobal: false},
        {model: "exclude_unknowns", label: "Unknowns", default: true, disabled: false, isGlobal: false}
      ],
      [
        {model: "exclude_no_activity", label: "No CRM Actions", default: false, disabled: false, isGlobal: false},
        {model: "exclude_any_empty_column", label: "Rows with empty cells", default: false, disabled: false, isGlobal: false},
        {model: "exclude_autopick", label: "Autopicks", default: false, disabled: false, isGlobal: false}
      ],
      [
        {model: "exclude_zeroes", label: "All Zeroes", default: false, disabled: false, isGlobal: false},
        {model: "exclude_child_sessions", label: "Consolidate Sessions", default: true, disabled: false,
          isGlobal: true},
        {model: "exclude_child_orders", label: "Consolidate Orders", default: true, disabled: false, isGlobal: true}
      ],
    ],
    filters: [
      [
        {model: "campaign_id", label: "Brand", default: false, disabled: false},
        {model: "crm_campaign_id", label: "CRM Campaign", default: false, disabled: false},
        {model: "product_id", label: "Product", default: false, disabled: false},
        {model: "exchangeable_product_id", label: "Exchangeable product", default: false, disabled: false},
        {model: "tracking_source", label: "Traffic source", default: "", disabled: false},
        {model: "step_slug", label: "Step Impressions", default: "", disabled: false},
        {model: "autopick", label: "Autopick", default: false, disabled: false},
        {model: "is_billable", label: "Billable", default: false, disabled: false},
        {model: "session_type", label: "Session Type", default: false, disabled: false},
      ],
      [
        {model: "source", label: "Channel source", default: false, disabled: false},
        {model: "help_desk_inquiries_filter", label: "Help desk inquiries", default: false, disabled: true},
        {model: "action_result", label: "Session Action Result", default: false, disabled: false},
        {model: "result", label: "Session result", default: false, disabled: false},
        {model: "funnel_item_status", label: "Order status", default: false, disabled: false},
        {model: "input_name", label: "Input Name", default: false, disabled: false},
        {model: "sale", label: "Sale", default: false, disabled: true},
        {model: "revenue_type", label: "Revenue type", default: false, disabled: false},
        {model: "status", label: "Status", default: false, disabled: false},
      ],
      [
        {model: "os_name", label: "Operating system", default: false, disabled: false},
        {model: "browser_name", label: "Browsers", default: false, disabled: false},
        {model: "locations_geo", label: "Locations GEO", default: false, disabled: true},
        {model: "client", label: "Client", default: false, disabled: true},
        {model: "downsell_step_slug", label: "Downsell step", default: false, disabled: false},
        {model: "carrier_type", label: "Phone Type", default: false, disabled: false},
        {model: "latest_result", label: "Latest Result", default: false, disabled: false},
        {model: "latest_result_based_on_execution", label: "Latest result based on time", default: false, disabled: false},
        {model: "session_action_status", label: "Session Action Status", default: false, disabled: false},
      ],
      [
        {model: "cancel_reason", label: "Cancel Reason", default: false, disabled: false},
        {model: "product_path", label: "Product Path", default: false, disabled: false},
        {model: "billing_cycle", label: "Billing Cycle", default: false, disabled: false},
        {model: "is_recurring", label: "Recurring", default: false, disabled: false},
        {model: "is_trial", label: "Trial", default: false, disabled: false},
        {model: "upsell_product_id", label: "Upsell Product", default: false, disabled: false},
        {model: "sms_status", label: "SMS Status", default: false, disabled: false},
        {model: "is_order_active", label: "Order Active", default: false, disabled: false},
        {model: "step_category", label: "Step Category", default: false, disabled: false},
      ]
    ],
    filtersData: [
      [
        {model: "campaign_id", filter: "campaign_id", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "crm_campaign_id", filter: "crm_campaign_id", type: "multi-select", label: "CRM campaign", default: "", options: []},
        {model: "product_id", filter: "product_id", type: "products", label: "Product", default: "", options: []},
        {model: "exchangeable_product_id", filter: "exchangeable_product_id", type: "products", label: "Exchangeable product", default: "", options: []},
        {model: "tracking_source", filter: "tracking_source", type: "multi-select", label: "Traffic source", default: "",
          options: []},
        {model: "step_slug", filter: "step_slug", type: "multi-select-with-negation", label: "Step Impressions", default: "",
          options: []},
        {model: "source", filter: "source", type: "multi-select", label: "Channel source", default: "",
          options: [
            {id: "Web", text: "Web"},
            {id: "Email", text: "Email"},
            {id: "Phone", text: "Phone"},
            {id: "Social Media", text: "Social Media"},
          ]},
        {model: "session_type", filter: "session_type", type: "multi-select", label: "Session Type", default: "",
          options: [
            {id: "Support", text: "Support"},
            {id: "Marketplace", text: "Marketplace"},
            {id: "Payment Update", text: "Payment Update"},
          ]},
        {model: "is_recurring", filter: "is_recurring", type: "select", label: "Recurring", default: "",
          options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
          ]},
        {model: "is_trial", filter: "is_trial", type: "select", label: "Trial", default: "",
          options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
          ]},
        {model: "is_order_active", filter: "is_order_active", type: "select", label: "Order Active", default: "",
          options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
          ]},
        {model: "help_desk_inquiries_filter_data", filter: "help_desk_inquiries_filter", type: "input", label: "Help desk inquiries", default: ""},
        {model: "action_result", filter: "action_result", type: "multi-select", label: "Session Action Result", default: "",
        options: [
          {id: 'Cancel', text: 'Cancel'},
          {id: 'RMA for Refund', text: 'RMA for Refund'},
          {id: 'RMA for Exchange', text: 'RMA for Exchange'},
          {id: 'Save', text: 'Save'},
          {id: 'Product Revision', text: 'Product Revision'},
          {id: 'Upsell', text: 'Upsell'},
          {id: 'Subscription Adjustment', text: 'Subscription Adjustment'},
          {id: 'Subscription Downsell', text: 'Subscription Downsell'},
          {id: 'Subscription Pause', text: 'Subscription Pause'},
          {id: 'Downsell with Refund', text: 'Downsell with Refund'},
          {id: 'Cancel Subscription', text: 'Cancel Subscription'},
        ]},
        {model: "sms_status", filter: "sms_status", type: "multi-select", label: "SMS Status", default: "",
          options: [
            {id: 'Queued', text: 'Queued'},
            {id: 'Failed', text: 'Failed'},
            {id: 'Sending', text: 'Sending'},
            {id: 'Sent', text: 'Sent'},
            {id: 'Delivered', text: 'Delivered'},
            {id: 'Undelivered', text: 'Undelivered'}
          ]},
        {model: "latest_result", filter: "latest_result", type: "multi-select",
          label: "Latest Result", default: "",
          options: [
            {id: 'Cancel', text: 'Cancel'},
            {id: 'RMA for Refund', text: 'RMA for Refund'},
            {id: 'RMA for Exchange', text: 'RMA for Exchange'},
            {id: 'Save', text: 'Save'},
            {id: 'Product Revision', text: 'Product Revision'},
            {id: 'Upsell', text: 'Upsell'},
            {id: 'Subscription Adjustment', text: 'Subscription Adjustment'},
            {id: 'Subscription Downsell', text: 'Subscription Downsell'},
            {id: 'Subscription Pause', text: 'Subscription Pause'},
            {id: 'Downsell with Refund', text: 'Downsell with Refund'},
            {id: 'Cancel Subscription', text: 'Cancel Subscription'},
          ]},
        {model: "latest_result_based_on_execution", filter: "latest_result_based_on_execution", type: "multi-select",
          label: "Latest result based on time", default: "",
          options: [
            {id: 'Cancel', text: 'Cancel'},
            {id: 'RMA for Refund', text: 'RMA for Refund'},
            {id: 'RMA for Exchange', text: 'RMA for Exchange'},
            {id: 'Save', text: 'Save'},
            {id: 'Product Revision', text: 'Product Revision'},
            {id: 'Upsell', text: 'Upsell'},
            {id: 'Subscription Adjustment', text: 'Subscription Adjustment'},
            {id: 'Subscription Downsell', text: 'Subscription Downsell'},
            {id: 'Subscription Pause', text: 'Subscription Pause'},
            {id: 'Downsell with Refund', text: 'Downsell with Refund'},
            {id: 'Cancel Subscription', text: 'Cancel Subscription'},
          ]},
        {model: "funnel_item_status", filter: "funnel_item_status", type: "multi-select", label: "Order Status", default: "",
          options: [
            {id: 'Subscription Active', text: 'Subscription Active'},
            {id: 'Subscription In Trial', text: 'Subscription In Trial'},
            {id: 'One Time Purchase Shipped', text: 'One Time Purchase Shipped'},
            {id: 'One Time Trial Shipped', text: 'One Time Trial Shipped'},
            {id: 'Shipping Processing', text: 'Shipping Processing'},
            {id: 'Shipping Hold', text: 'Shipping Hold'},
            {id: 'Subscription Hold', text: 'Subscription Hold'},
            {id: 'Trial Hold', text: 'Trial Hold'},
            {id: 'InActive', text: 'InActive'},
            {id: 'Rebill Hold', text: 'Rebill Hold'},
            {id: 'Rebill Active', text: 'Rebill Active'},
          ]
        },
        {model: "sale_filter_data", filter: "sale", type: "input", label: "Sale", default: ""},
        {model: "revenue_type", filter: "revenue_type", type: "multi-select", label: "Revenue type", default: "",
          options: [
            {id: "None", text: "None"},
            {id: "Upsell", text: "Upsell"},
            {id: "Downsell", text: "Downsell"},
            {id: "Partial Cancel", text: "Partial Cancel"},
          ]},
        {model: "os_name", filter: "os_name", type: "multi-select", label: "Operating systems", default: "",
          options: [
            {id: "Android", text: "Android"},
            {id: "Windows", text: "Windows"},
            {id: "Linux", text: "Linux"},
            {id: "iOS", text: "iOS"},
            {id: "Mac OS", text: "Mac OS"},
          ]},
        {model: "browser_name", filter: "browser_name", type: "multi-select", label: "Browsers", default: "",
          options: [
            {id: "Chrome", text: "Chrome"},
            {id: "Firefox", text: "Firefox"},
            {id: "Safari", text: "Safari"},
            {id: "Mobile Safari", text: "Mobile Safari"},
            {id: "Samsung Browser", text: "Samsung Browser"},
          ]},
        {model: "locations_geo_filter_data", filter: "locations_geo", type: "input", label: "Locations", default: ""},
        {model: "result", filter: "result", type: "multi-select", label: "Session results", default: "",
          options: [
            {id: "Unknown", text: "Unknown"},
            {id: "Active Session", text: "Active Session"},
            {id: "Timeout", text: "Timeout"},
            {id: "Abandoned", text: "Abandoned"},
            {id: "Complete", text: "Complete"},
            {id: "Lifeline - Timeout", text: "LifelineTimeout"},
            {id: "Lifeline - Undo", text: "Lifeline - Undo"},
            {id: "Lifeline - Exit", text: "Lifeline - Exit"},
            {id: "Lifeline - Error", text: "Lifeline - Error"},
            {id: "Lifeline Contact Failed", text: "Lifeline Contact Failed"},
            {id: "Logged Out", text: "Logged Out"},
            {id: "Bypassed", text: "Bypassed"},
            {id: "Invalid Token", text: "Invalid Token"},
            {id: "Error", text: "Error"},
            {id: "Expired Token", text: "Expired Token"},
            {id: "Frequency Bypassed", text: "Frequency Bypassed"},
            {id: 'Active with Lifeline', text: 'Active with Lifeline'},
            {id: 'Active with Dropoff', text: 'Active with Dropoff'},
            {id: 'Active with Error', text: 'Active with Error'},
            {id: "Lifeline - Search", text: "Lifeline - Search"},
            {id: "Lifeline - No Match", text: "Lifeline - No Match"},
          ]},
        {model: "client", filter: "client", type: "select", label: "Client", default: ""},
        {model: "downsell_step_slug", filter: "downsell_step_slug", type: "multi-select", label: "Downsell step", default: "",
          options: []},
        {model: "carrier_type", filter: "carrier_type", type: "multi-select", label: "Phone Type", default: "",
          options: [
            {id: "Mobile", text: "Mobile"},
            {id: "Landline", text: "Landline"},
            {id: "VOIP", text: "VOIP"},
          ]},
        {model: "cancel_reason", filter: "cancel_reason", type: "multi-select", label: "Cancel Reason", default: "",
          options: []},
        {model: "product_path", filter: "product_path", type: "multi-select", label: "Product Path", default: "",
          options: []},
        {model: "input_name", filter: "input_name", type: "multi-select", label: "Input Name", default: "",
          options: []},
        {model: "billing_cycle", filter: "billing_cycle", type: "arithmetic-comparison-input", label: "Billing Cycle",
          default: "", options: [], comparisonDefault: 'e', comparisonOptions: [
            {id: "e", text: "Equals to"},
            {id: "gt", text: "Greater than"},
            {id: "gte", text: "Greater than or equals to"},
            {id: "lt", text: "Less than"},
            {id: "lte", text: "Less than or equals to"},
          ]},
        {model: "autopick", filter: "autopick", type: "select", label: "Autopick", default: "yes",
          options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
          ]},
        {model: "is_billable", filter: "is_billable", type: "select", label: "Billable", default: "yes",
          options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
          ]},
        {model: "status", filter: "status", type: "multi-select", label: "Status", default: "",
          options: [
            {id: "Unmatched", text: "Unmatched"},
            {id: "Matched, but unverified", text: "Matched, but unverified"},
            {id: "Matched and verified", text: "Matched and verified"},
            {id: "Active", text: "Active"},
            {id: "Matched", text: "Matched"},
          ]},
        {model: "session_action_status", filter: "session_action_status", type: "multi-select",
          label: "Session Action Status", default: "",
          options: [
            {id: "Delayed", text: "Delayed"},
            {id: "Executed", text: "Executed"},
            {id: "Cancelled", text: "Cancelled"},
            {id: "Pending", text: "Pending"},
            {id: "Failed", text: "Failed"},
            {id: "Execute After Delay", text: "Execute After Delay"},
            {id: "Handled by Call Center", text: "Handled by Call Center"},
          ]},
        {model: "step_category", filter: "step_category", type: "multi-select",
          label: "Step Category", default: "",
          options: [
            {id: "Custom", text: "Custom"},
            {id: "Reasons", text: "Reasons"},
            {id: "Order Options", text: "Order Options"},
            {id: "Discount Future Order", text: "Discount Future Order"},
            {id: "Refund Order", text: "Refund Order"},
            {id: "Return Order RMA", text: "Return Order RMA"},
            {id: "Upsell Order", text: "Upsell Order"},
            {id: "Pause Sub", text: "Pause Sub"},
            {id: "Offer Details/Info", text: "Offer Details/Info"},
            {id: "Revise Order", text: "Revise Order"},
            {id: "Need More Help", text: "Need More Help"},
            {id: "Adjust Shipping Frequency", text: "Adjust Shipping Frequency"},
            {id: "Item Selection", text: "Item Selection"},
            {id: "Marketplace", text: "Marketplace"},
            {id: "Life Line Step", text: "Life Line Step"},
            {id: "Sub Path", text: "Sub Path"},
            {id: "Troubleshooter", text: "Troubleshooter"},
            {id: "FAQs", text: "FAQs"},
            {id: "Response Step", text: "Response Step"},
            {id: "Match and Verify", text: "Match and Verify"},
            {id: "Order Status", text: "Order Status"},
            {id: "Tagging Customer for Repeating Action", text: "Tagging Customer for Repeating Action"},
            {id: "Update Info", text: "Update Info"},
            {id: "Survey", text: "Survey"},
          ]},
      ]
    ]
  };

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: ReportsService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService, campaignService,
      reportsConfigService, trackingSourceService, userService, accountService, campaignProductService,
      crmCampaignService, storageService, stepService, refundReportService, merchantService)
  }
}
