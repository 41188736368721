import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService, AudienceService,
  FunnelService,
  FunnelStepService,
  ProductService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {FunnelNewComponent} from "./funnel-new.component";
import {FunnelStepsComponent} from "./funnel-steps.component";
import {Funnel} from "../_models";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-edit.component.html',
  styleUrls: ['./funnel.component.css'],
})
export class FunnelEditComponent extends FunnelNewComponent implements OnInit {
  @ViewChild(FunnelStepsComponent, { static: false }) stepsComponent: FunnelStepsComponent;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected modalService: NgxSmartModalService,
    private stepService: FunnelStepService,
    protected audienceService: AudienceService
  ) {
    super(router, location, route, funnelService, alertService, formBuilder, productService, modalService,
      audienceService);
    this.isNew = false;
    this.title = 'Edit Your Path';
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.data$.subscribe(
      (funnel: Funnel) => {
        if (funnel) {
          this.loading = false;
          this.funnel = funnel;
          this.funnelType = funnel.resourcetype;
          this.updateControlsForFunnelType();
          this.form.patchValue(funnel);

          if (funnel.item_statuses && funnel.item_statuses.length) {
            let itemStatuses = [];

            funnel.item_statuses.forEach(value => {
              itemStatuses.push({id: value, text: this.itemStatusLabels[value]});
            });

            this.buildItemStatusList();
            this.form.patchValue({item_statuses: itemStatuses});
            delete funnel.item_statuses;
          }

          this.form.patchValue(funnel);
          this.useMatchedWelcomeMessage = !!(funnel.matched_welcome_msg && funnel.matched_welcome_msg.length);
          this.useUnmatchedWelcomeMessage = !!(funnel.unmatched_welcome_msg && funnel.unmatched_welcome_msg.length);
          this.fetchAudience();
        }
      },
      error => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }

  protected onSaveComplete(data) {
    this.reload();
  }

  canPasteStep() {
    return this.stepService.canPaste();
  }

  pasteStep() {
    if (this.stepsComponent) {
      this.stepsComponent.pasteStep();
    }
  }

}
