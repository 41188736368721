import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {CrudSavePopupComponent} from "../_directives";
import {AlertService, ReportsConfigService, UserService} from "../_services";
import {Location} from '@angular/common';
import {FormBuilder, Validators} from "@angular/forms";
import {ReportsFormData, User} from "../_models";
import {ReportsUserConfiguration} from "../_models/reports";
import {takeUntil} from "rxjs/operators";


@Component({
  moduleId: module.id.toString(),
  selector: 'reports-config',
  templateUrl: './templates/reports-config.component.html',
  styleUrls: ['./styles/reports-config.component.css'],
})
export class ReportsConfigComponent extends CrudSavePopupComponent implements OnInit, OnChanges {
  @Input('config') config: ReportsFormData = null;
  @Input('saveConfiguration') saveConfiguration: boolean = false;
  @Output('loadConfig') loadConfig: EventEmitter<any> = new EventEmitter<any>();
  @Input('loadedConfigId') loadedConfigurationId: Number = null;
  public selectedId = "";
  public configurations: ReportsUserConfiguration[] = [];
  public user: User = null;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected reportsConfig: ReportsConfigService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    private userService: UserService,
  ) {
    super(router, location, route, reportsConfig, alertService);
    this.objectName = "report configuration";
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      name: [null, Validators.required],
      configurationId: [null],
      is_global: [false]
    }));

    super.ngOnInit();

    this.reportsConfig.list({})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.configurations = data.results;
        },
        error => {
          this.handleError(error);
        }
      );
    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user;
      }, error => {
        this.handleError(error);
      });
  }

  ngOnChanges() {
    super.ngOnChanges();

    if (this.form) {
      this.form.patchValue({configurationId: this.loadedConfigurationId});
    }
  }

  protected getFormData(): any {
    let configName = this.form.value.name;
    let config = {...this.config}
    if ('account' in config) {
      delete config.account
    }
    return {name: configName, configuration: config, is_global: this.form.value.is_global};
  }

  protected onSaveComplete(data) {
    this.configurations.push(data);
    this.loading = false;
  }

  deleteConfiguration(id: Number | string) {
    let index = -1;
    this.configurations.forEach(item => {
      if (item.id === id) {
        index = this.configurations.indexOf(item);
      }
    })

    if (index > -1) {
      this.configurations.splice(index, 1);
    }
  }

  loadConfiguration(e) {
    e.preventDefault();
    this.configurations.forEach(item => {
      if (item.id == Number(this.form.value.configurationId)) {
        this.loadConfig.emit(item);
      }
    })
  }
}
