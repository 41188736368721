import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignService, FaqTopicService, FunnelService, RegionService} from '../_services';
import {CrudSaveComponent} from '../_directives';
import {FormBuilder, Validators} from '@angular/forms';
import {
  Campaign,
  Funnel,
  FunnelType,
  Region,
  Pager,
  VerificationMethod,
  SessionType,
  initializeSessionTypeFunnels
} from "../_models";
import {config} from '../../config/config';
import {EMPTY} from "rxjs";
import {mergeMap, takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-funnels.component.html'
})
export class CampaignFunnelsComponent extends CrudSaveComponent implements OnInit {
  isNewCampaign = false;
  campaign: Campaign;
  nameVerifyMethods = [];
  addressVerifyMethods = [];
  phoneOrEmailVerifyMethods = [];
  nameVerifyMethod: {unmatched: VerificationMethod | null, matched: VerificationMethod | null} =
    {unmatched: null, matched: null};
  addressVerifyMethod: {unmatched: VerificationMethod | null, matched: VerificationMethod | null} =
    {unmatched: null, matched: null};
  phoneEmailVerifyMethod: {unmatched: VerificationMethod | null, matched: VerificationMethod | null} =
    {unmatched: null, matched: null};
  billingAddressRequired: {unmatched: boolean, matched: boolean} = {unmatched: false, matched: false};
  faqTopicOptions: {value: number | string, label: string}[] = [];

  private assignedFunnels: {} = {};
  private funnels: {} = {};
  public blacklistedStatesFilterSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false,
    textField: 'name'
  };
  public states: Region[] = []
  public renderStateSelection: boolean = false

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    private funnelService: FunnelService,
    private faqTopicService: FaqTopicService,
    private formBuilder: FormBuilder,
    private regionService: RegionService
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = 'Setup Brand Main Path';
    this.isPartial = true;
    this.objectName = 'Brand';

    this.nameVerifyMethods = [
      {
        label: 'Disabled',
        value: null,
        help: 'Check to disable name verification'
      },
      {
        label: 'Last Name',
        value: VerificationMethod.LastName,
        help: 'Check to require last name verification'
      },
      {
        label: 'Full Name',
        value: VerificationMethod.FullName,
        help: 'Check to require first and last name verification'
      }
    ];

    this.addressVerifyMethods = [
      {
        label: 'Disabled',
        value: null,
        help: 'Check to disable address verification'
      },
      {
        label: 'Street',
        value: VerificationMethod.Street,
        help: 'Check to require street verification'
      },
      {
        label: 'Zip Code',
        value: VerificationMethod.Zip,
        help: 'Check to require zip/postal code verification'
      },
      {
        label: 'Street and/or Zip Code',
        value: VerificationMethod.StreetAndZip,
        help: 'Check to require street and zip/postal code verification if all selected verification methods are ' +
          'required.  Check to require either street or zip/postal code verification if all selected verification ' +
          'are not required (i.e. any one of the selected methods are required).'
      },
      {
        label: 'Full Address',
        value: VerificationMethod.Address,
        help: 'Check to require full address (street, city, state and zip/postal code) verification'
      }
    ];

    this.phoneOrEmailVerifyMethods = [
      {
        label: 'Disabled',
        value: null,
        help: 'Check to disable phone/email verification'
      },
      {
        label: 'Enabled',
        value: VerificationMethod.PhoneOrEmail,
        help: 'Check to require phone or email verification.  If the customer matched by email address, then phone ' +
          'number verification will be required.  If the customer matched by phone number, then email address ' +
          'verification will be required.  If the customer matched by both phone and email, then this verification ' +
          'step will be skipped.  If the customer did not match by either phone nor email (i.e. matched by order ' +
          'number), then either phone number or email address verification will be required.'
      }
    ];

    initializeSessionTypeFunnels(this.funnels);
    initializeSessionTypeFunnels(this.assignedFunnels);
  }

  ngOnInit() {
    let selectedFunnelMap = {};

    this.form = this.formBuilder.group({
      verify_two_factor: [false],
      unmatched_verify_all: [false],
      matched_verify_all: [false],
      match_phone: [true],
      match_email: [true],
      match_order_number: [false],
      visual_entry_funnels: [[]],
      hybrid_entry_funnels: [[]],
      unmatched_verification_methods: [[]],
      matched_verification_methods: [[]],
      faq_topic: [null],
      max_order_days: [null, [Validators.min(0), Validators.max(1000)]],
      action_holdoff_delay: [0, [Validators.required, Validators.min(0), Validators.max(10000)]],
      always_show_active_orders: [true],
      show_declined_orders: [false],
      show_replaced_items: [false],
      blacklisted_states: [[], null],
      chatbot_enabled: [false],
    });

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;

    this.regionService.list('us')
      .subscribe((states: Region[]) => {
        this.states = states
      })

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        let customFormValues = {action_holdoff_delay: campaign.action_holdoff_delay / 3600,
          blacklisted_states: []}
        if (campaign.blacklisted_states && campaign.blacklisted_states.length > 0) {
          let mappedStates = this.states.filter((item) => campaign.blacklisted_states.indexOf(item.id) > -1)
          mappedStates.forEach(state => {
            customFormValues.blacklisted_states.push(state)
          })
        }
        this.form.patchValue(Object.assign({}, campaign, customFormValues));
        this.renderStateSelection = true
        this.campaign = campaign;
        this.campaignService.showMenu(campaign, 'paths', this.isNewCampaign, campaign.is_hybrid);
        const funnelIds = campaign.is_hybrid ? campaign.hybrid_entry_funnels : campaign.visual_entry_funnels;

        for (let funnel_id of funnelIds) {
          selectedFunnelMap[funnel_id] = true;
        }

        this.initVerificationFields(campaign.unmatched_verification_methods, 'unmatched');
        this.initVerificationFields(campaign.matched_verification_methods, 'matched');
        let filters = {page: 1, page_size: config.maxPageSize}

        if (campaign.is_hybrid) {
          filters['resourcetype'] = 'HybridCampaignFunnel'
        }
        else {
          filters['resourcetype__in'] = 'VisualFunnel,CustomerPortal'
        }

        return this.funnelService.list(filters);
      }

      return EMPTY;
    })).subscribe(
      (data: Pager) => {
        for (let funnel of data.results) {
          this.funnels[funnel.session_type].push(funnel);

          if (funnel.id in selectedFunnelMap) {
            this.assignedFunnels[funnel.session_type].push(funnel);
          }
        }
      },
      error => {
        this.handleError(error);
      }
    );

    this.faqTopicService.getSelectOptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        options => {
          this.faqTopicOptions = options;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  get supportFunnels() {
    return this.funnels[SessionType.Support];
  }

  get assignedSupportFunnels() {
    return this.assignedFunnels[SessionType.Support];
  }

  set assignedSupportFunnels(funnel: Funnel[]) {
    this.assignedFunnels[SessionType.Support] = funnel;
  }

  protected initVerificationFields(methods: VerificationMethod[], type: string) {
    methods.forEach((method: VerificationMethod) => {
      switch (method) {
        case VerificationMethod.LastName:
          this.nameVerifyMethod[type] = VerificationMethod.LastName;
          break;

        case VerificationMethod.FullName:
          this.nameVerifyMethod[type] = VerificationMethod.FullName;
          break;

        case VerificationMethod.Street:
          this.addressVerifyMethod[type] = (this.addressVerifyMethod[type] == VerificationMethod.Zip) ?
            VerificationMethod.StreetAndZip : VerificationMethod.Street;
          this.billingAddressRequired[type] = false;
          break;

        case VerificationMethod.Zip:
          this.addressVerifyMethod[type] = (this.addressVerifyMethod[type] == VerificationMethod.Street) ?
            VerificationMethod.StreetAndZip : VerificationMethod.Zip;
          this.billingAddressRequired[type] = false;
          break;

        case VerificationMethod.Address:
          this.addressVerifyMethod[type] = VerificationMethod.Address;
          this.billingAddressRequired[type] = false;
          break;

        case VerificationMethod.BillingStreet:
          this.addressVerifyMethod[type] = (this.addressVerifyMethod[type] == VerificationMethod.Zip) ?
            VerificationMethod.StreetAndZip : VerificationMethod.Street;
          this.billingAddressRequired[type] = true;
          break;

        case VerificationMethod.BillingZip:
          this.addressVerifyMethod[type] = (this.addressVerifyMethod[type] == VerificationMethod.Street) ?
            VerificationMethod.StreetAndZip : VerificationMethod.Zip;
          this.billingAddressRequired[type] = true;
          break;

        case VerificationMethod.BillingAddress:
          this.addressVerifyMethod[type] = VerificationMethod.Address;
          this.billingAddressRequired[type] = true;
          break;

        case VerificationMethod.PhoneOrEmail:
          this.phoneEmailVerifyMethod[type] = VerificationMethod.PhoneOrEmail;
          break;
      }
    });
  }

  protected getSelectedVerificationMethods(type: string) : VerificationMethod[] {
    let methods = [];

    if (this.nameVerifyMethod[type]) {
      methods.push(this.nameVerifyMethod[type]);
    }

    if (this.addressVerifyMethod[type]) {
      switch (this.addressVerifyMethod[type]) {
        case VerificationMethod.Street:
          methods.push(this.billingAddressRequired[type] ?
            VerificationMethod.BillingStreet : VerificationMethod.Street);
          break;

        case VerificationMethod.Zip:
          methods.push(this.billingAddressRequired[type] ?
            VerificationMethod.BillingZip : VerificationMethod.Zip);
          break;

        case VerificationMethod.Address:
          methods.push(this.billingAddressRequired[type] ?
            VerificationMethod.BillingAddress : VerificationMethod.Address);
          break;

        case VerificationMethod.StreetAndZip:
          if (this.billingAddressRequired[type]) {
            methods.push(VerificationMethod.BillingStreet);
            methods.push(VerificationMethod.BillingZip);
          }
          else {
            methods.push(VerificationMethod.Street);
            methods.push(VerificationMethod.Zip);
          }
          break;
      }
    }

    if (this.phoneEmailVerifyMethod[type]) {
      methods.push(this.phoneEmailVerifyMethod[type]);
    }

    return methods;
  }

  protected getFormData() {
    const funnelTypes = this.campaign.is_hybrid
      ? [FunnelType.Hybrid] : [FunnelType.Visual, FunnelType.CustomerPortal];
    const key = this.campaign.is_hybrid ? 'hybrid_entry_funnels' : 'visual_entry_funnels';
    let funnelIds = {};
    funnelIds[key] = [];

    Object.values(this.assignedFunnels).forEach((assignedFunnels: Funnel[]) => {
      assignedFunnels.forEach((funnel: Funnel) => {
        if (funnelTypes.indexOf(funnel.resourcetype) !== -1) {
          funnelIds[key].push(funnel.id);
        }
      });
    });

    let selectedBlacklistedStates = this.form.value.blacklisted_states
    let blackListedStates = []

    if (selectedBlacklistedStates) {
      selectedBlacklistedStates.forEach(state => {
        blackListedStates.push(state.id)
      })
    }

    return Object.assign({}, this.form.value, funnelIds,
      {
        unmatched_verification_methods: this.getSelectedVerificationMethods('unmatched'),
        matched_verification_methods: this.getSelectedVerificationMethods('matched'),
        action_holdoff_delay: this.form.value.action_holdoff_delay * 3600,
        blacklisted_states: blackListedStates
      });
  }

  protected onSaveComplete(data) {
    if (this.isNewCampaign) {
      this.campaignService.getNextMenuRoute().pipe(takeUntil(this.destroy$)).subscribe((route: string) => {
        this.navigate(['/campaign', route, this.id, {isNewCampaign: true}], {replaceUrl: true});
      });
    }
    else {
      this.goBack();
    }
  }

  getFunnelLabel(funnel: Funnel): string {
    return funnel.is_template ? 'System Template - ' + funnel.name : funnel.name;
  }

}
