import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AlertService, AuthenticationService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Form} from '../_forms'
import {Location} from "@angular/common";

@Component({
  moduleId: module.id.toString(),
  templateUrl: 'user-invite-accept.component.html',
  styleUrls: ['./user.component.css']
})

export class UserInviteAcceptComponent extends Form implements OnInit {
  loading = false;

  private uid;
  private token;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected location: Location,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    protected alertService: AlertService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.uid = this.route.snapshot.queryParams['uid'];
    this.token = this.route.snapshot.queryParams['token'];
    this.form = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      new_password: [null, [Validators.required, Validators.minLength(8)]],
      cpassword: [null, [Validators.required, Validators.minLength(8)]]
    }, {validators: this.mustMatch('new_password', 'cpassword')});
  }

  inviteAccept() {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.inviteAccept(this.uid, this.token, this.form.value.first_name, this.form.value.last_name,
      this.form.value.new_password).subscribe(
        () => {
          this.alertService.success('Thank you for accepting our invitation. You may now login and use your account.',
            true);
          this.router.navigate(['/user/login']);
          this.loading = false
        },
        error => {
          this.loading = false
          this.handleSubmitError(error)
        });
  }
}
