import {Injectable} from "@angular/core";
import {CrudService} from "./crud.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class PerformanceReportService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http, 'performance_report');
  }

  get_excel(data) {
    return this.http.post(this.route + "/excel/", data)
  }

  downloadPerformanceReport(data, elem, fileType='excel') {
    let response = this.get_excel(data);
    response.subscribe((data: {download_url: string}) => {
      let dwldLink = document.createElement("a");
      dwldLink.setAttribute("href", data.download_url);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      elem.loading = false;
    }, error => {
      elem.loading = false;
      if (error.status == 404) {
        elem.alertService.error("No reports to download");
        return;
      }
      elem.alertService.error("An error occurred while downloading report");
    })
  }
}
