import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {LogsAbstractService} from "./logs-abstract.service";

@Injectable()
export class CallLogsService extends LogsAbstractService {
  constructor(protected http: HttpClient) {
    super(http, 'phone_logs');
  }
}
