import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, AudioFileService } from '../_services';
import { CrudListComponent } from '../_directives';
import { AudioFile } from '../_models';
import { Observable } from "rxjs";

@Component({
  moduleId: module.id.toString(),
  selector: 'audio-files',
  templateUrl: './audio-file.component.html',
  styleUrls: ['./audio-file.component.css']
})
export class AudioFileComponent extends CrudListComponent implements OnInit {
  files$: Observable<AudioFile[]> = this.data$;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected fileService: AudioFileService,
    protected alertService: AlertService
  ) {
    super(router, location, route, fileService, alertService);
    this.objectName = 'file';
    this.title = 'Audio Files';
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
