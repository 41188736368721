import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import {
  StepButtonControlTypes,
  StepButtonShapes,
  StepButtons,
  StepElementTypes,
  StepEmulatorDeviceWidths,
  StepEmulatorDevices,
  StepFixedMediaCategory,
  StepLayout,
  StepMedia,
  StepMediaCategory,
  StepMediaPanels,
  StepProductsPreview,
  StepResponseMedia,
  StepResponseScreen,
  StepTextual,
  StepTextualLayout,
  transparent,
  getCampaignProductImageUrl,
  Campaign,
  StepImagesLayout,
  StepPreviewButtonDetails,
  getFunnelPathVariables,
  CampaignProduct,
} from '../../../_models';
import { FormArray, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StepEmulatorService, StepTemplateService } from '../../../../app/_services';
import { addDummyValues, setEmulatorWidth, updateFontSizes } from '../../step-util';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-step-emulator',
  templateUrl: './step-emulator.component.html',
  styleUrls: ['./step-emulator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepEmulatorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() layout: StepLayout[] = [];
  @Input() form: FormGroup;
  @Input() isResponseScreen: boolean = false;
  @Input() activeDevice: StepEmulatorDevices;
  @Input() productPlaceholderUrl: string;
  @Input() selectedCampaign: Campaign;
  @Input() theme: string;
  @Input() preview: boolean = false;
  @Input() useExternalButtons = false;
  @Input() product: CampaignProduct = {} as CampaignProduct;
  stepTextual: StepTextual;
  stepMedia: StepMedia;
  stepResponseMedia: StepResponseMedia;
  stepButtons: StepButtons;
  stepResponseScreen: StepResponseScreen;
  stepProductsPreview: StepProductsPreview;
  private subscriptions: Subscription[] = [];
  hasSurveyOptions: boolean;
  hasTimeOptions: boolean;
  selectedImage: string;
  selectedResponseImage: string;
  videoUrl: SafeResourceUrl;
  responseVideoUrl: SafeResourceUrl;
  loading: boolean = true;
  stepMediaCategory = StepMediaCategory;
  stepElements = StepElementTypes;
  stepEmulatorDevices = StepEmulatorDevices;
  isPreviewMediaEnabled = true;
  noStepMedia = false;
  noPreviewMedia = false;
  noResponseMedia = false;
  placeholder: string = '';
  previewImage = '';
  isPreviewEmbed = false;
  previewConfirmButton: StepPreviewButtonDetails = {
    label: '',
    backgroundColor: '',
    color: '',
    borderRadius: '',
    borderColor: '',
  };
  previewBackButton: StepPreviewButtonDetails = {
    label: '',
    backgroundColor: '',
    color: '',
    borderRadius: '',
    borderColor: '',
  };
  outlineShapes = [StepButtonShapes.FlatOutline, StepButtonShapes.ModernOutline, StepButtonShapes.PillOutline];
  funnelPathVariables: { value: string; text: string }[] = [];
  @ViewChild('emulatorRef', { static: false }) emulatorRef: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    protected stepService: StepTemplateService,
    private stepEmulatorService: StepEmulatorService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 4000);

    this.stepEmulatorService.resetStepData();
    this.funnelPathVariables = getFunnelPathVariables();
    const textual = this.layout.find((element) => element.type === StepElementTypes.Textual);

    const surveyOptions = this.layout.find((element) => element.type === StepElementTypes.SurveyOptions);
    this.hasSurveyOptions = surveyOptions ? true : false;

    const timeOptions = this.layout.find((element) => element.type === StepElementTypes.TimeOptions);
    this.hasTimeOptions = timeOptions ? true : false;

    const textSubscription = this.stepEmulatorService.stepTextual$.subscribe((data) => {
      this.stepTextual = data;
    });
    this.addSubscription(textSubscription);

    const stepMediaSubscription = this.stepEmulatorService.stepMedia$.subscribe((data) => {
      this.stepMedia = data;
      this.extractStepMedia();
    });
    this.addSubscription(stepMediaSubscription);

    const responseMediaSubscription = this.stepEmulatorService.stepResponseMedia$.subscribe((data) => {
      this.stepResponseMedia = data;
      this.extractResponseMedia();
    });
    this.addSubscription(responseMediaSubscription);

    const buttonsSubscription = this.stepEmulatorService.stepButtons$.subscribe((data) => {
      this.stepButtons = data;
    });
    this.addSubscription(buttonsSubscription);

    const responseScreenSubscription = this.stepEmulatorService.stepResponseScreen$.subscribe((data) => {
      this.stepResponseScreen = data;
    });
    this.addSubscription(responseScreenSubscription);

    const productsPreviewSubscription = this.stepEmulatorService.stepProductsPreview$.subscribe((data) => {
      this.stepProductsPreview = data;
    });
    this.addSubscription(productsPreviewSubscription);

    this.applyThemeColor(this.theme);

    /* Emulator Preview Settings */
    if (this.preview) {
      /* Media */
      const { embed_code, image, image_tablet, image_mobile, image_type } = this.form.get('static_data').value;
      if (image_type === StepMediaCategory.NoMedia) {
        this.isPreviewMediaEnabled = false;
      }

      this.placeholder =
        this.productPlaceholderUrl ||
        (this.layout.find((element) => element.type === StepElementTypes.Media).layout as StepImagesLayout)
          .placeholderImage;

      if (!embed_code && !image && !image_tablet && !image_mobile) {
        this.noPreviewMedia = true;
      } else if (image || image_tablet || image_mobile) {
        this.previewImage = image_mobile.file || image_tablet.file || image.file;
      } else if (embed_code) {
        this.isPreviewEmbed = true;
      }

      /* Buttons */
      if (this.useExternalButtons) {
        const confirmStyles = (this.form.get('button_styles') as FormGroup).get('confirm').value.style;
        const backStyles = (this.form.get('button_styles') as FormGroup).get('back').value.style;
        const confirmLabel = (this.form.get('button_styles') as FormGroup).get('confirm').value.label;
        const backLabel = (this.form.get('button_styles') as FormGroup).get('back').value.label;

        this.previewConfirmButton = {
          label: this.getHtml(addDummyValues(confirmLabel, this.funnelPathVariables, this.product)),
          ...this.extractButtonStyles(confirmStyles),
        };

        this.previewBackButton = {
          label: this.getHtml(addDummyValues(backLabel, this.funnelPathVariables, this.product)),
          ...this.extractButtonStyles(backStyles),
        };
      } else {
        const inputs = this.form.get('inputs') as FormArray;
        const confirmLabel = inputs.at(0).get('label').value;
        const backLabel = inputs.at(1).get('label').value;

        this.previewConfirmButton = {
          label: this.getHtml(addDummyValues(confirmLabel, this.funnelPathVariables, this.product)),
          ...this.extractButtonStyles(inputs.at(0).value.button_styles.style),
        };

        this.previewBackButton = {
          label: this.getHtml(addDummyValues(backLabel, this.funnelPathVariables, this.product)),
          ...this.extractButtonStyles(inputs.at(1).value.button_styles.style),
        };
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDevice) {
      setEmulatorWidth(this.getDeviceWidth());
      if (this.stepMedia) {
        if (this.stepMedia.fixedImage[this.activeDevice].file) {
          this.noStepMedia = false;
        } else if (this.stepMedia.fixedImage.productImage) {
          this.noStepMedia = false;
        } else if (this.stepMedia.embedVideo.url && this.stepMedia.embedVideo.sanitizedURL) {
          this.noStepMedia = false;
        } else {
          this.noStepMedia = true;
        }
      }

      if (this.stepResponseMedia) {
        if (this.stepResponseMedia.fixedImage[this.activeDevice].file) {
          this.noResponseMedia = false;
        } else if (this.stepResponseMedia.fixedImage.productImage) {
          this.noResponseMedia = false;
        } else if (this.stepResponseMedia.embedVideo.url && this.stepResponseMedia.embedVideo.sanitizedURL) {
          this.noResponseMedia = false;
        } else {
          this.noResponseMedia = true;
        }
      }
    }

    if (changes.theme) {
      this.applyThemeColor(changes.theme.currentValue);
    }

    if (this.stepMedia) {
      this.extractStepMedia();
    }

    if (this.stepResponseMedia) {
      this.extractResponseMedia();
    }
  }

  addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  extractStepMedia() {
    this.noStepMedia = true;

    switch (this.stepMedia.category) {
      case StepMediaCategory.Fixed:
        switch (this.stepMedia.fixedImage.category) {
          case StepFixedMediaCategory.Gallery:
            if (this.stepMedia.fixedImage.productImage) {
              this.selectedImage =
                this.stepMedia.fixedImage.productImage.file.original || this.stepMedia.fixedImage.productImage.file;
              this.noStepMedia = false;
            } else {
              this.selectedImage = null;
            }
            break;

          case StepFixedMediaCategory.Upload:
            if (this.stepMedia.fixedImage[this.activeDevice].file) {
              this.noStepMedia = false;
            }
            break;
        }
        break;

      case StepMediaCategory.Embed:
        if (this.stepMedia.embedVideo.url) {
          this.noStepMedia = false;
        } else {
          this.videoUrl = null;
        }
        break;
    }

    if (this.noStepMedia) {
      if (this.productPlaceholderUrl) {
        this.placeholder = this.productPlaceholderUrl;
      } else if (this.stepMedia.placeholderImage) {
        this.placeholder = this.stepMedia.placeholderImage;
      } else this.placeholder = null;
    }
  }

  extractResponseMedia() {
    this.noResponseMedia = true;

    switch (this.stepResponseMedia.category) {
      case StepMediaCategory.Fixed:
        switch (this.stepResponseMedia.fixedImage.category) {
          case StepFixedMediaCategory.Gallery:
            if (this.stepResponseMedia.fixedImage.productImage) {
              this.selectedResponseImage =
                this.stepResponseMedia.fixedImage.productImage.file.original ||
                this.stepResponseMedia.fixedImage.productImage.file;
              this.noResponseMedia = false;
            } else {
              this.selectedResponseImage = null;
            }
            break;

          case StepFixedMediaCategory.Upload:
            if (this.stepResponseMedia.fixedImage[this.activeDevice].file) {
              this.noResponseMedia = false;
            }
            break;
        }
        break;

      case StepMediaCategory.Embed:
        if (this.stepResponseMedia.embedVideo.url) {
          this.noResponseMedia = false;
        } else {
          this.videoUrl = null;
        }
        break;
    }
  }

  get banner(): SafeHtml {
    return this.preview
      ? this.getHtml(addDummyValues(this.form.get('banner').value, this.funnelPathVariables, this.product))
      : this.getHtml(this.stepTextual.banner.value);
  }

  get label() {
    return this.preview
      ? this.getHtml(addDummyValues(this.form.get('label').value, this.funnelPathVariables, this.product))
      : this.getHtml(this.stepTextual.body.label);
  }

  get content() {
    return this.preview
      ? this.getHtml(addDummyValues(this.form.get('content').value, this.funnelPathVariables, this.product))
      : this.getHtml(this.stepTextual.body.content);
  }

  get confirmButton() {
    return this.getHtml(this.stepButtons.confirmButton.content);
  }

  get backButton() {
    return this.getHtml(this.stepButtons.backButton.content);
  }

  get responseTitle() {
    return this.getHtml(this.stepResponseScreen.titleContent);
  }

  get response() {
    return this.getHtml(this.stepResponseScreen.responseContent);
  }

  getHtml(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(updateFontSizes(content || ''));
  }

  takeScreenshot(): Promise<File> {
    const emulatorElement = this.emulatorRef.nativeElement;
    const iframes = emulatorElement.getElementsByTagName('iframe');
    const iframePromises: Promise<void>[] = [];

    Array.from(iframes).forEach((iframe: HTMLIFrameElement) => {
      const placeholder = document.createElement('img');
      placeholder.style.width = '95%';
      placeholder.style.maxWidth = '100%';
      placeholder.style.height = '315px';
      placeholder.style.display = 'block';
      placeholder.style.objectFit = 'cover';
      placeholder.src = '/assets/stepAssets/video-placeholder.png';

      const parent = iframe.parentNode;
      parent.replaceChild(placeholder, iframe);

      iframePromises.push(
        new Promise<void>((resolve) => {
          setTimeout(() => {
            parent.replaceChild(iframe, placeholder);
            resolve();
          }, 100);
        })
      );
    });

    return html2canvas(emulatorElement, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      return new Promise<File>((resolve, reject) => {
        Promise.all(iframePromises).then(() => {
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], 'emulator.jpg', {
                type: 'image/jpg',
              });
              resolve(file);
            } else {
              reject('Screenshot generation failed');
            }
          });
        });
      });
    });
  }

  get isStepMedia() {
    return this.stepEmulatorService.selectedMediaPanel === StepMediaPanels.Step;
  }

  get isResponseMedia() {
    return this.stepEmulatorService.selectedMediaPanel === StepMediaPanels.Response;
  }

  get isStepFixed() {
    return this.stepMedia.category === StepMediaCategory.Fixed;
  }

  get isResponseFixed() {
    return this.stepResponseMedia.category === StepMediaCategory.Fixed;
  }

  get isStepUpload() {
    return this.stepMedia.fixedImage.category === StepFixedMediaCategory.Upload;
  }

  get isResponseUpload() {
    return this.stepResponseMedia.fixedImage.category === StepFixedMediaCategory.Upload;
  }

  get isStepGallery() {
    return this.stepMedia.fixedImage.category === StepFixedMediaCategory.Gallery;
  }

  get isResponseGallery() {
    return this.stepResponseMedia.fixedImage.category === StepFixedMediaCategory.Gallery;
  }

  get isStepEmbed() {
    return this.stepMedia.category === StepMediaCategory.Embed;
  }

  get isResponseEmbed() {
    return this.stepResponseMedia.category === StepMediaCategory.Embed;
  }

  get isMobile() {
    return this.activeDevice === StepEmulatorDevices.Mobile;
  }

  get isTablet() {
    return this.activeDevice === StepEmulatorDevices.Tablet;
  }

  get isDesktop() {
    return this.activeDevice === StepEmulatorDevices.Desktop;
  }

  get hasLogo() {
    return (
      this.selectedCampaign && this.selectedCampaign.id && this.selectedCampaign.logo && this.selectedCampaign.logo.file
    );
  }

  get hasMobileLogo() {
    return (
      this.selectedCampaign &&
      this.selectedCampaign.id &&
      this.selectedCampaign.mobile_logo &&
      this.selectedCampaign.mobile_logo.file
    );
  }

  getDeviceWidth(): number {
    if (this.isMobile) {
      return StepEmulatorDeviceWidths.Mobile;
    } else if (this.isTablet) {
      return StepEmulatorDeviceWidths.Tablet;
    }
    return StepEmulatorDeviceWidths.Desktop;
  }

  applyStyles() {
    let styles = {},
      padding = '20px';
    if (this.isDesktop) padding = '50px';

    if (!this.hasBanner || !this.stepTextual.banner.value) {
      styles = {
        'padding-top': padding,
      };
    }

    return styles;
  }

  applyThemeColor(color: string) {
    document.documentElement.style.setProperty('--label-border-color', color || '#2eb3c9');
  }

  exchangeableProductDiscount(product) {
    return Math.round(100 - ((+product.price * product.quantity) / +product.retail_price) * 100);
  }

  selectExchangeable(id: string) {
    const checkbox = document.getElementById(id) as HTMLInputElement;
    checkbox.checked = true;
  }

  get getConfirmFontColor() {
    if (
      this.stepButtons.confirmButton.type === StepButtonControlTypes.Theme &&
      (this.outlineShapes.includes(this.stepButtons.confirmButton.style) ||
        this.stepButtons.confirmButton.style === StepButtonShapes.TextOnly)
    ) {
      return this.stepButtons.confirmButton.brandFont;
    } else {
      return this.stepButtons.confirmButton.fontColor;
    }
  }

  get getBackFontColor() {
    if (
      this.stepButtons.backButton.type === StepButtonControlTypes.Theme &&
      (this.outlineShapes.includes(this.stepButtons.backButton.style) ||
        this.stepButtons.backButton.style === StepButtonShapes.TextOnly)
    ) {
      return this.stepButtons.backButton.brandFont;
    } else {
      return this.stepButtons.backButton.fontColor;
    }
  }

  get getConfirmBorderColor() {
    if (this.stepButtons.confirmButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.confirmButton.borderColor;
    } else {
      if (this.stepButtons.confirmButton.isBorderDisabled) return transparent;
      return this.stepButtons.confirmButton.brandBorder;
    }
  }

  get getBackBorderColor() {
    if (this.stepButtons.backButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.backButton.borderColor;
    } else {
      if (this.stepButtons.backButton.isBorderDisabled) return transparent;
      return this.stepButtons.backButton.brandBorder;
    }
  }

  get getConfirmBackgroundColor() {
    if (this.stepButtons.confirmButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.confirmButton.backgroundColor;
    } else {
      if (this.stepButtons.confirmButton.isBackgroundDisabled) return transparent;
      return this.stepButtons.confirmButton.brandBackground;
    }
  }

  get getBackBackgroundColor() {
    if (this.stepButtons.backButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.backButton.backgroundColor;
    } else {
      if (this.stepButtons.backButton.isBackgroundDisabled) return transparent;
      return this.stepButtons.backButton.brandBackground;
    }
  }

  get isStepMediaEnabled() {
    return this.stepMedia.category !== StepMediaCategory.NoMedia;
  }

  get isResponseMediaEnabled() {
    return this.stepResponseMedia.category !== StepMediaCategory.NoMedia;
  }

  get hasBanner(): boolean {
    const textual = this.layout.find((element) => element.type === StepElementTypes.Textual);
    return textual && (textual.layout as StepTextualLayout).banner && !!this.form.get('banner').value;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  protected readonly getCampaignProductImageUrl = getCampaignProductImageUrl;

  extractButtonStyles(styles: string) {
    if (!styles) {
      return {
        backgroundColor: '',
        color: '',
        borderRadius: '',
        borderColor: '',
      };
    }

    const styleMap = styles.split(';').reduce((acc, style) => {
      const [key, value] = style.split(':').map((s) => s.trim());
      if (key && value) {
        acc[key] = value.replace('var(--theme_color)', this.theme);
      }
      return acc;
    }, {} as Record<string, string>);

    return {
      backgroundColor: styleMap['background-color'] || '',
      color: styleMap['color'] || '',
      borderRadius: styleMap['border-radius'] || '',
      borderColor: styleMap['border-color'] || '',
    };
  }
}
