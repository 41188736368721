import {BaseModel} from "./base-model";

export enum DelayActionType {
  Normal = 0,
  WaitUntilCallCenterOpen = 1,
  OnlyIfCallCenterOpen = 2
}

export const DelayActionTypeLabels = [
  'Normal',
  'Wait until call center is open',
  'Only if call center is open'
];

export interface CallCenterAction extends BaseModel {
  name: string;
  contact_popup: string;
  after_hours_popup: string;
  prevent_action: boolean;
  action_delay: number;
  delay_type: DelayActionType;
  min_price: number;
}
