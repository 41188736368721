import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PaginationService } from "./pagination.service";

@Injectable({
  providedIn: "root",
})
export class TemplateFolderService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, "template_folders");
  }

  getFolderDetails(folderId: string | number): Observable<any> {
    return this.http.get(`${this.route}/${folderId}/templates/`);
  }
}
