import { BaseModel } from './index';

export enum PaymentSourceType {
  Card = 'Card',
  BankAccount = 'BankAccount'
}

export enum BankAccountHolderType {
  Personal = 1,
  Business = 2
}

export enum BankAccountType {
  Checking = 1,
  Savings = 2
}

export enum BankAccountStatus {
  New = 1,
  Validated = 2,
  Verified = 3,
  VerificationFailed = 4,
  Error = 5
}

export enum ChargeStatus {
  Success = 1,
  Decline = 2,
  Pending = 3,
  Cancelled = 4
}

export const ChargeStatusLabels = [
  '',         // 0
  'Success',  // 1
  'Declined', // 2
  'Pending',  // 3
  'Cancelled' // 4
];

export interface PaymentSource extends BaseModel {
  resourcetype: PaymentSourceType;
  provider_name: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  name: string;
  is_verified: boolean;
  is_default: boolean;
  account_status: BankAccountStatus;
  account_type: BankAccountType;
  account_holder_type: BankAccountHolderType;
  charge_status: ChargeStatus;
}

export interface BankAccountVerification {
  amount1: number;
  amount2: number;
}


