import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ReportsAbstractService} from "./reports-abstract.service";

@Injectable()
export class FaqReportsService extends ReportsAbstractService {
  reportRoute = 'faq_reports'
  constructor(protected http: HttpClient) {
    super(http);
    this.route = this.reportRoute;
  }
}
