import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {AlertService, CRMService, UserService} from '../_services';
import { FormBuilder } from '@angular/forms';
import { CRM } from "../_models";
import { CRMNewComponent } from "./crm-new.component";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './crm-edit.component.html',
  styleUrls: ['./crm.component.css']
})
export class CRMEditComponent extends CRMNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected crmService: CRMService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, crmService, formBuilder, alertService, userService);
    this.isNew = false;
    this.title = 'Edit Your CRM';
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe((crm: CRM) => {
      if (crm) {
        this.crm = crm;
        this.form.patchValue({
          type: crm.type,
          subscription_plugin: crm.subscription_plugin,
          max_fulfillments: crm.max_fulfillments,
          max_transactions: crm.max_transactions,
          auto_approve: crm.auto_approve,
          use_partial_refunds: crm.use_partial_refunds,
          allow_update_fulfillment: crm.allow_update_fulfillment,
          link_id_field: crm.link_id_field,
          link_field_type: crm.link_field_type,
          display_name: crm.display_name,
          auto_sync: crm.auto_sync,
          parent_crm: crm.parent_crm,
        });

        if (crm.type in this.typeMap) {
          this.selectedType = this.typeMap[crm.type];
          this.buildSubscriptionPluginList();
          if (!crm.display_name) {
            this.form.patchValue({display_name: this.selectedType.name})
          }
        }

        if (crm.subscription_plugin && crm.subscription_plugin in this.subscriptionPluginMap) {
          this.selectedSubscriptionPlugin = this.subscriptionPluginMap[crm.subscription_plugin];
        }

        this.updateFields();
      }
    });
  }

  protected onSaveComplete(data) {
    this.loading = false;
    this.goBack();
  }
}
