import {BaseModel} from "./base-model";
import {CRMField} from "./crm";

export enum FulfillmentStatus {
  Hold = 0,
  Shipped = 1,
  Delivered = 2,
  RMAPending = 3,
  Returned = 4,
  Cancelled = 5,
  Processing = 6,
  PendingShipment = 7,
  Failed = 8,
  None = 9,
  NextCycle = 10,
  Removed = 11,
  ProcessingReattempt = 12,
  Skipped = 13,
  ParentOrder = 14,
}

export const FulfillmentStatusLabels = [
  'Hold',             // 0
  'Shipped',          // 1
  'Delivered',        // 2
  'RMA Pending',      // 3
  'Returned',         // 4
  'Cancelled',        // 5
  'Processing',       // 6
  'Pending Shipment', // 7
  'Failed',           // 8
  'None',             // 9
  'NextCycle',        // 10
  'Removed',          // 11
  'ProcessingReattempt', // 12
  'Skipped',          // 13
  'Parent Order',     // 14
];

export enum ShippingSystemTypeName {
  PitneyBowesUSPS = 1,
  PitneyBowesStandard = 2,
}
export interface ShippingSystem extends BaseModel {
  display_name: string;
  type: ShippingSystemTypeName;
  credentials: {[key: string]: string;};
  config: {[key: string]: string;};
  label_data: {[key: string]: string;};
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
export interface ShippingSystemField extends CRMField {}
export interface ShippingSystemType extends BaseModel {
  name: string;
  fields: ShippingSystemField[];
  encrypted_fields: ShippingSystemField[];
  label_fields: ShippingSystemField[];
}
