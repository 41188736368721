import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  AlertService,
  AudienceService,
  CampaignProductService,
  CRMCampaignService,
  CRMService,
  RegionService,
  StorageService,
} from '../_services';
import { FormBuilder } from '@angular/forms';
import {
  Audience,
  AudienceConditionType,
  BillingCycleCustomMin,
  CRMCampaign,
  CRMTypeId,
  CardType,
  DefaultMinBillingCycle,
  FunnelFulfillmentType,
  Pager,
  Region,
  TrafficSource,
} from '../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AudienceNewComponent } from './audience-new.component';
import { forkJoin } from 'rxjs';
import { config } from '../../config/config';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './audience-edit.component.html',
  styleUrls: ['./audience-edit.component.scss'],
  selector: 'app-audience-edit',
  encapsulation: ViewEncapsulation.None,
})
export class AudienceEditComponent extends AudienceNewComponent implements OnInit {
  pageTitle: string = 'Edit Audience';
  isCampaignFilterLoading: boolean = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected audienceService: AudienceService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected regionService: RegionService,
    protected crmService: CRMService,
    protected crmCampaignService: CRMCampaignService,
    protected campaignProductService: CampaignProductService,
    protected modalService: NgxSmartModalService,
    protected storageService: StorageService
  ) {
    super(
      router,
      location,
      route,
      audienceService,
      alertService,
      formBuilder,
      regionService,
      crmService,
      crmCampaignService,
      campaignProductService,
      modalService,
      storageService
    );
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.isNewComponent = false;
    this.data$.subscribe(
      (audience: Audience) => {
        if (audience) {
          this.audience = audience;
          this.getProducts();

          if (audience.fulfillment_type) {
            this.setFulfillmentConditions(audience.fulfillment_type);
          }

          if (audience.max_billing_cycle || audience.min_billing_cycle > 1) {
            this.setBillingCycleConditions(audience.max_billing_cycle, audience.min_billing_cycle);
          }
          if (audience.countries && audience.countries.length) {
            this.setLocationConditions();
          }

          if (
            (audience.cc_types && audience.cc_types.length) ||
            audience.is_3ds ||
            (audience.merchant_ids && audience.merchant_ids.length)
          ) {
            this.setTransactionConditions();
          }

          if (audience.traffic_sources && audience.traffic_sources.length) {
            this.setTrafficSourceConditions();
          }

          if (audience.use_product_filter) {
            this.isProductFiltersSelected = true;
            this.conditions.productFilters = AudienceConditionType.ProductFilters;
          }

          if (audience.use_crm_campaign_filter) {
            this.conditions.campaignFilters = AudienceConditionType.CampaignFilters;
            this.isCampaignFilterLoading = true;
            this.setCampaignFilterConditions();
          }

          this.form.patchValue(audience);

          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }

  setFulfillmentConditions(fulfillmentType: FunnelFulfillmentType) {
    this.fulfillmentTypeSelected = fulfillmentType;
    this.form.patchValue({
      fulfillment_type: fulfillmentType,
    });
    this.conditions.fulfillmentType = AudienceConditionType.FulfillmentType;
  }
  setBillingCycleConditions(maxBillingCycle: number, minBillingCycle: number) {
    this.billingCycleObject.maximum = maxBillingCycle;
    this.billingCycleObject.minimum = minBillingCycle;
    this.form.patchValue({
      max_billing_cycle: maxBillingCycle,
      min_billing_cycle: minBillingCycle,
    });

    if (
      minBillingCycle > DefaultMinBillingCycle ||
      maxBillingCycle >= BillingCycleCustomMin ||
      (minBillingCycle && maxBillingCycle === null)
    ) {
      this.billingCycleObject.isCustom = true;
      this.filteredMaxBillingOptions = this.billingOptions.filter((value) => value >= minBillingCycle);
    }

    this.conditions.billingCycle = AudienceConditionType.BillingCycle;
  }

  setLocationConditions() {
    this.regionService.getCountries().subscribe((countries: Region[]) => {
      const countryLabels = {};
      const selectedCountries = [];

      countries.forEach((country: Region) => {
        countryLabels[country.id] = country.name;
      });

      this.audience.countries.forEach((value) => {
        const updatedValue = value.toLowerCase();
        if (countryLabels[updatedValue]) {
          selectedCountries.push({ id: updatedValue, text: countryLabels[updatedValue] });
        }
      });

      this.form.patchValue({ countries: selectedCountries });
      this.selectedCountries = selectedCountries;
    });

    this.updateStates();

    if (this.audience.states && this.audience.states.length) {
      const stateRequests = this.selectedCountries.map((country) => this.regionService.list(country.id));

      forkJoin(stateRequests).subscribe((responses: Region[][]) => {
        const stateLabels = {};
        const selectedStates = [];

        responses.forEach((states) => {
          states.forEach((state: Region) => {
            stateLabels[state.id] = state.name;
          });
        });

        this.audience.states.forEach((value) => {
          if (stateLabels[value]) {
            selectedStates.push({ id: value, text: stateLabels[value] });
          }
        });

        this.form.patchValue({ states: selectedStates });
        this.selectedStates = selectedStates;
      });
    }

    this.conditions.location = AudienceConditionType.Location;
  }
  setCampaignFilterConditions() {
    forkJoin([
      this.crmService.list({
        page: 1,
        page_size: config.maxPageSize,
        'type!': CRMTypeId.Test,
      }),
      this.crmCampaignService.list({
        page: 1,
        page_size: config.maxPageSize,
        'crm__type!': CRMTypeId.Test,
      }),
    ]).subscribe(
      (data: [Pager, Pager]) => {
        this.crms = data[0].results;

        this.allCRMCampaigns = data[1].results;

        const campaignLabels = {};
        const selectedCampaigns = [];

        this.allCRMCampaigns.forEach((campaign: CRMCampaign) => {
          campaignLabels[campaign.id] = campaign.name;
        });

        this.audience.crm_campaigns.forEach((value) => {
          selectedCampaigns.push({ id: value, text: campaignLabels[value] });
        });

        this.form.patchValue({ crm_campaigns: selectedCampaigns });
        this.selectedCampaigns = selectedCampaigns;

        this.isCampaignFilterLoading = false;
      },
      (error) => {
        this.handleError(error);
        this.isCampaignFilterLoading = false;
      }
    );
  }

  setTransactionConditions() {
    const isMerchantId = this.audience.merchant_ids && this.audience.merchant_ids.length;
    const isCreditCardsSelected = this.audience.cc_types && this.audience.cc_types.length;
    if (isMerchantId || isCreditCardsSelected || this.audience.is_3ds) {
      this.conditions.transactionFilters = AudienceConditionType.TransactionFilters;
    }
    if (this.audience.is_3ds) {
      this.transactionFiltersObject.is3dsVerified = true;
    }

    if (isMerchantId) {
      this.transactionFiltersObject.merchantId = this.audience.merchant_ids.join(',');
    }
    if (isCreditCardsSelected) {
      this.convertToCreditCardTypes(this.audience.cc_types);
    }
  }

  setTrafficSourceConditions() {
    this.conditions.trafficSource = AudienceConditionType.TrafficSource;
    this.convertToTrafficSourceObject(this.audience.traffic_sources);
  }

  private convertToCreditCardTypes(cardTypeArray: number[]) {
    // Loop through the array of numbers and set the corresponding properties to true
    cardTypeArray.forEach((type) => {
      switch (type) {
        case CardType.None:
          this.creditCardTypes.none = true;
          break;
        case CardType.Visa:
          this.creditCardTypes.visa = true;
          break;
        case CardType.Mastercard:
          this.creditCardTypes.mastercard = true;
          break;
        case CardType.Amex:
          this.creditCardTypes.amex = true;
          break;
        case CardType.Discover:
          this.creditCardTypes.discover = true;
          break;
        case CardType.Other:
          this.creditCardTypes.other = true;
          break;
        default:
          break;
      }
    });
  }

  private convertToTrafficSourceObject(trafficSourceArray: number[]) {
    // Loop through the array of numbers and set the corresponding properties to true
    trafficSourceArray.forEach((type) => {
      switch (type) {
        case TrafficSource.EMAIL:
          this.trafficSourceObject.email = true;
          break;
        case TrafficSource.PHONE:
          this.trafficSourceObject.phone = true;
          break;
        case TrafficSource.DIRECTLINK:
          this.trafficSourceObject.directLink = true;
          break;
        default:
          break;
      }
    });
  }
}
