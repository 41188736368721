import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ImageService, UserService} from '../_services';
import {Image, User} from '../_models';
import {Observable} from 'rxjs';
import {ImageAbstractComponent} from './image-abstract.component';
import {FormBuilder} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  selector: 'global-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image.component.css']
})
export class GlobalGalleryComponent extends ImageAbstractComponent implements OnInit {
  images$: Observable<Image[]> = this.data$;
  user: User = null;
  isPublic = true;
  componentId = 'global';

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected imageService: ImageService,
    protected alertService: AlertService,
    protected userService: UserService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService
  ) {
    super(router, location, route, imageService, alertService, formBuilder, modalService);
    this.objectName = 'image';
  }

  ngOnInit() {
    super.ngOnInit();
    this.userService.getCurrent()
      .subscribe((user: User) => {
        this.user = user;
      }, error => {
        this.handleError(error);
      });
  }

  protected onDelete(id) {
    //image was deleted, so make sure it's not selected
    this.selectedImages = this.selectedImages.filter(selectedImage => selectedImage.id != id);
    this.queryData()
  }

  protected initializeFilter() {
    super.initializeFilter();
    this.filter['is_public'] = true;
  }
}

