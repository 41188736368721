import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, StyleService, CampaignService } from '../_services';
import { CrudSaveComponent } from '../_directives';
import { FormBuilder } from '@angular/forms';
import { Style, Pager, Campaign, CampaignStatus } from "../_models";
import { config } from '../../config/config';
import { EMPTY } from "rxjs";
import { takeUntil, mergeMap } from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-styles.component.html'
})
export class CampaignStylesComponent extends CrudSaveComponent implements OnInit {
  assignedStyles: Style[] = [];
  styles: Style[] = [];
  isNewCampaign = false;
  campaign: Campaign;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected campaignService: CampaignService,
    protected alertService: AlertService,
    private styleService: StyleService,
    private formBuilder: FormBuilder
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = 'Setup Brand Theme';
    this.isPartial = true;
    this.objectName = 'Brand';
  }

  ngOnInit() {
    let selectedStyleMap = {};

    this.form = this.formBuilder.group({
      styles: [[]]
    });

    super.ngOnInit();
    this.isNewCampaign = this.route.snapshot.params['isNewCampaign'];
    this.showSuccessMessage = !this.isNewCampaign;

    this.data$.pipe(mergeMap((campaign: Campaign) => {
      if (campaign) {
        this.form.patchValue(campaign);
        this.campaign = campaign;
        this.campaignService.showMenu(campaign, 'styles', this.isNewCampaign, campaign.is_hybrid);

        for (let style_id of campaign.styles) {
          selectedStyleMap[style_id] = true;
        }

        return this.styleService.list({page: 1, page_size: config.maxPageSize});
      }

      return EMPTY;
    })).subscribe(
      (data: Pager) => {
        for (let style of data.results) {
          this.styles.push(style);

          if (style.id in selectedStyleMap) {
            this.assignedStyles.push(style);
          }
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected getFormData() {
    let style_ids = [];

    for (let style of this.assignedStyles) {
      style_ids.push(style.id);
    }

    return {styles: style_ids};
  }

  protected onSaveComplete(data) {
    if (this.isNewCampaign) {
      //activate the campaign
      this.campaignService.patch(this.id, {'status': CampaignStatus.Active})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            const nextRoute = this.campaign.is_hybrid ? '/hybrid-campaigns' : '/campaigns';
            this.alertService.success('Setup complete for ' + this.campaign.name + '.', true);
            this.navigate([nextRoute]);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
    else {
      this.goBack();
    }
  }

}
