import {Component, OnInit} from '@angular/core';
import {CrudListComponent} from "../_directives";
import {Observable} from "rxjs";
import {Plan} from "../_models";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, PlanService} from "../_services";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent extends CrudListComponent implements OnInit {
  plans$: Observable<Plan[]> = this.data$;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected planService: PlanService,
    protected alertService: AlertService,
  ) {
    super(router, location, route, planService, alertService);
    this.objectName = 'plan';
    this.title = 'Billing Plans';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  isEnabled(plan: Plan) {
    return plan.is_active;
  }

  enable(plan: Plan, event) {
    let enable: boolean = event.target.checked;
    let action = enable ? 'enable' : 'disable';

    if (window.confirm('Are you sure you want to ' + action + ' ' + plan.name + ' plan?')) {
      this.planService.patch(plan.id, {is_active: enable})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            action = enable ? 'Enabled' : 'Disabled';
            this.alertService.success(action + ' ' + plan.name + ' campaign.');
          },
          error => {
            this.handleSubmitError(error);
            event.target.checked = !enable; //revert checkbox to previous state
          });
    }
    else {
      event.target.checked = !enable; //revert checkbox to previous state (should not get here but just in case)
    }
  }

}
