import {
  AvailableActionHoldoffs,
  CustomerPortalOrderDetailsSpecialInputs,
  CustomerPortalSettingHints,
  CustomerPortalSettingType,
  FunnelInputTypeLabels,
  SettingsPreviewTypes
} from './../_models/funnel';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, AudienceService, FunnelService, ProductService } from '../_services';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import { FunnelNewComponent } from '../funnel';
import {
  CancelItemsSelection,
  Funnel,
  FunnelInputTypeEnum,
  FunnelType,
  FunnelVariables, OfferIntents, OfferTypes,
  Pager,
  SubscriptionDisplayType,
  WelcomeMessageVariables,
} from '../_models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FontFamilyFormats, FontSizes, initializeEditorStyles } from '../steps-library/step-data';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as tinymce from 'tinymce';
import {config} from "../../config/config";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './customer-portal-edit.component.html',
  styleUrls: ['../funnel/funnel.component.css', './customer-portal.component.scss'],
  animations: [
    trigger("fadeIn", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerPortalNewComponent extends FunnelNewComponent implements OnInit {
  availableSideMenuInputs: { id: FunnelInputTypeEnum; text: string }[] = [
    { id: FunnelInputTypeEnum.CustomerPortalHome, text: 'Home' },
    { id: FunnelInputTypeEnum.CustomerPortalOrders, text: 'Orders & Subscriptions' },
    { id: FunnelInputTypeEnum.CustomerPortalPaymentInfo, text: 'Payment Method' },
    { id: FunnelInputTypeEnum.CustomerPortalOrderHistory, text: 'Order History' },
    { id: FunnelInputTypeEnum.CustomerPortalPersonalInfo, text: 'Personal Information' },
  ];

  cancelSubscriptionOptions: { value: SubscriptionDisplayType; label: string }[] = [
    { value: SubscriptionDisplayType.Always, label: 'No Restriction' },
    { value: SubscriptionDisplayType.AfterFirstCycle, label: 'After 1st Cycle' },
  ];

  sideMenuInputSettings = {
    enableCheckAll: false,
  };

  protected settingsFields = {
    side_menu_inputs: [this.availableSideMenuInputs, Validators.required],
    allow_cancel: [true],
    show_fulfillment_status: [true],
    show_subscription_status: [true],
    show_tracking_in_list: [true],
    show_tracking_in_details: [true],
    show_transactions_in_list: [true],
    show_transactions_in_details: [true],
    allow_cancel_subscription: [SubscriptionDisplayType.Always, Validators.required],
    allow_edit_payment_method: [true],
    allow_edit_shipping_address: [true],
    allow_edit_billing_interval: [true],
    allow_skip_shipment: [true],
    allow_revision: [true],
    show_next_bill_date: [true],
    allow_reactivate_subscription: [true],
    show_price_in_list: [false],
    show_price_in_details: [true],
    show_taxes: [true],
    show_shipping_price: [true],
    show_payment_status_in_list: [true],
    show_payment_status_in_details: [true],
    allow_revise_subscription: [true],
  };

  protected defaultValues = {
    matched_verify_msg: 'Verify your identity',
    unmatched_verify_msg: 'Verify your identity',
    match_msg: "Let's find your account!",
    thank_you_msg: 'Thank you for visiting {campaign_name}!',
    matched_welcome_msg: 'Hello, {first_name}'
  };

  protected expandedStates: boolean[] = [
    false, //General Settings
    false, //Order Settings
    false, //Subscription Settings
    false, //Payment Settings
    false, //Path Settings
    true, //Lifeline Settings
    false,
  ];

  customerPortalHints = CustomerPortalSettingHints;
  welcomeMessageVariables: { value: string; text: string }[] = [];
  customerPortalSettingType = CustomerPortalSettingType;
  SettingsPreviewTypes = SettingsPreviewTypes;
  activePreviewType: SettingsPreviewTypes | null = null;
  matchedWelcomeMsgEditor = {
    height: 115,
    resize: false,
    fontsize_formats: FontSizes,
    font_formats: FontFamilyFormats,
    setup: (editor: tinymce.Editor) => {
      initializeEditorStyles(editor, '16px', "'Open Sans', sans-serif");
    },
  };
  assignedProductFunnels: Funnel[] = [];
  productFunnels: Funnel[] = [];
  availableActionHoldoffs: { id: number, text: string }[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected modalService: NgxSmartModalService,
    protected audienceService: AudienceService
  ) {
    super(router, location, route, funnelService, alertService, formBuilder, productService, modalService, audienceService);
    this.isNew = true;
    this.objectName = 'Customer Portal Settings';
    this.title = '';
    this.funnelType = FunnelType.CustomerPortal;
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.patchValue(this.defaultValues);
    this.welcomeMessageVariables = this.getWelcomeMessageVariables();
    this.buildProductFunnelList();
    if (this.isNew) this.toggleSetting(this.customerPortalSettingType.General)
    this.availableActionHoldoffs = AvailableActionHoldoffs.slice(0);
  }

  protected updateControlsForFunnelType() {
    const holdoff_actions_config = []

    CustomerPortalOrderDetailsSpecialInputs.forEach(input => {
      holdoff_actions_config.push(this.formBuilder.group({
        intent: [input],
        holdoff_actions: [[]]
      }))
    })

    this.settingsFields['holdoff_actions_config'] = [holdoff_actions_config]

    const extraFields = Object.assign({}, this.defaultExtraFields, this.settingsFields);

    //add the extra fields
    Object.keys(extraFields).forEach((fieldName: string) => {
      let field = extraFields[fieldName];

      if (fieldName === 'holdoff_actions_config') {
        this.form.addControl(fieldName, new FormArray(holdoff_actions_config))
      } else {
        this.form.addControl(fieldName, new FormControl(field[0], field[1]));
      }
    });

    this.updateTitle();
  }

  protected getFormData() {
    let data = {customer_portal_settings: {}, product_funnels: []};

    this.assignedProductFunnels.forEach((funnel: Funnel) => {
      data.product_funnels.push(funnel.id);
    });

    Object.entries(this.form.value).forEach(([fieldName, value]) => {
      if (fieldName in this.settingsFields) {
        if (fieldName === 'side_menu_inputs') {
          let inputs = [];

          if (value) {
            this.form.value.side_menu_inputs.forEach((input) => {
              inputs.push(input.id);
            });
          }

          data.customer_portal_settings[fieldName] = inputs;
        } else if (fieldName === 'holdoff_actions_config') {
          (value as []).forEach((row: {}) => {
            row['holdoff_actions'] = (row['holdoff_actions'] as []).map(item => item['id'])
          })
          data.customer_portal_settings[fieldName] = value;
        } else {
          data.customer_portal_settings[fieldName] = value;
        }
      } else {
        data[fieldName] = value;
      }
    });

    return data;
  }

  protected onSaveComplete(data) {
    this.navigate(['/customer-portals'], { replaceUrl: true });
    this.alertService.success('Customer Portal Settings Created Successfully.');
  }

  toggleTrackShipment(event: any) {
    this.form.patchValue({
      show_tracking_in_list: event.target.checked,
      show_tracking_in_details: event.target.checked,
    });
  }
  toggleShowOrderPrice(event: any) {
    this.form.patchValue({
      show_price_in_list: event.target.checked,
      show_price_in_details: event.target.checked,
    });
  }
  toggleShowPaymentStatus(event: any) {
    this.form.patchValue({
      show_payment_status_in_list: event.target.checked,
      show_payment_status_in_details: event.target.checked,
    });
  }
  toggleViewTransactions(event: any) {
    this.form.patchValue({
      show_transactions_in_list: event.target.checked,
      show_transactions_in_details: event.target.checked,
    });
  }
  toggleSetting(settingType: number) {
    if (settingType === CustomerPortalSettingType.Lifeline) {
      this.expandedStates[settingType] = !this.expandedStates[settingType];
    } else {
      this.expandedStates = this.expandedStates.map((state, i) => {
        if (i === CustomerPortalSettingType.Lifeline) {
          return state;
        }
        return i === settingType ? !state : false;
      });
    }

    const sectionClass = this.getSectionId(settingType);
    const sectionElements = document.getElementsByClassName(sectionClass);

    if (sectionElements.length > 0) {
      const sectionElement = sectionElements[0];
      if (sectionElement && settingType !== CustomerPortalSettingType.General) {
        setTimeout(() => {
          window.scrollTo({
            top: sectionElement.getBoundingClientRect().top + window.scrollY - 100,
            behavior: 'smooth',
          });
        }, 50);
      }
    }
  }

  isExpanded(index: number): boolean {
    return this.expandedStates[index];
  }
  addMessageVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = event.target.value;
    const editorInstance = tinymce.get('matched_welcome_msg');
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }

    selectElement.selectedIndex = 0;
  }

  get canSelectUpsell() {
    return (
      !this.isFieldEqual('cancel_items_selection', CancelItemsSelection.ShowNone) ||
      !this.isFieldEqual('return_items_selection', CancelItemsSelection.ShowNone)
    );
  }

  allowUpsellSelection(event) {
    this.form.patchValue({
      cancel_items_selection: event.target.checked ? CancelItemsSelection.UpsellsOnlyIfUpsell : CancelItemsSelection.ShowNone,
      return_items_selection: event.target.checked ? CancelItemsSelection.ShowAll : CancelItemsSelection.ShowNone,
    });
  }

  get canCancelSubscription() {
    return !this.isFieldEqual('allow_cancel_subscription', SubscriptionDisplayType.Never);
  }

  allowCancelSubscription(event) {
    this.form.patchValue({
      allow_cancel_subscription: event.target.checked ? SubscriptionDisplayType.Always : SubscriptionDisplayType.Never,
    });
  }

  navigateBackToSettings() {
    this.router.navigate(['customer-portals']);
  }
  protected getWelcomeMessageVariables() {
    let items = [];

    Object.keys(WelcomeMessageVariables).forEach((key) => {
      items.push({ value: '{' + key + '}', text: FunnelVariables[key][0] });
    });

    return items;
  }

  getFunnelLabel(funnel: Funnel): string {
    return funnel.is_template ? 'System Template - ' + funnel.name : funnel.name;
  }

  protected buildProductFunnelList() {
    this.productFunnels = [];
    this.assignedProductFunnels = [];
    let selectedFunnelMap = {};
    let funnels = [];
    let assignedFunnels = [];

    if (this.funnel && this.funnel.product_funnels) {
      for (let funnel_id of this.funnel.product_funnels) {
        selectedFunnelMap[funnel_id] = true;
      }
    }

    this.funnelService.list({
      resourcetype: 'ProductFunnel', is_visual: true, page: 1, page_size: config.maxPageSize,
      'offer_intent__in!': [OfferIntents.Legacy, OfferIntents.Other].join(','),
      'offer_type__in': [OfferTypes.SubPath, OfferTypes.CustomerPortal].join(','),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          for (let funnel of data.results) {
            funnels.push(funnel);

            if (funnel.id in selectedFunnelMap) {
              assignedFunnels.push(funnel);
            }
          }

          this.productFunnels = funnels;
          this.assignedProductFunnels = assignedFunnels;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  showPreview(previewType: SettingsPreviewTypes) {
    this.activePreviewType = previewType;
  }

  hidePreview(previewType: SettingsPreviewTypes) {
    if (this.activePreviewType === previewType) {
      this.activePreviewType = null;
    }
  }

  private sectionIdMap: { [key: number]: string } = {
    [CustomerPortalSettingType.General]: 'general-settings',
    [CustomerPortalSettingType.Order]: 'order-settings',
    [CustomerPortalSettingType.Subscription]: 'subscription-settings',
    [CustomerPortalSettingType.Payment]: 'payment-settings',
    [CustomerPortalSettingType.Paths]: 'path-settings',
    [CustomerPortalSettingType.HoldoffAction]: 'holdoff-action',
  };

  getSectionId(settingType: number): string {
    return this.sectionIdMap[settingType] || '';
  }

  getInputLabel(input_type: FunnelInputTypeEnum) {
    return FunnelInputTypeLabels[input_type]
  }
}
