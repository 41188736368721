import {Injectable} from "@angular/core";
import {PaginationService} from "./pagination.service";
import {HttpClient} from "@angular/common/http";
import {Observable, of as observableOf} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class FulfillmentService extends PaginationService {
  constructor(protected http: HttpClient) {
    super(http, 'fulfillments');
  }
}
