import {CrudPagedListComponent} from "../_directives";
import {Input, OnChanges, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, LogsAbstractService, SessionService} from "../_services";
import {Moment} from 'moment';
import {NgxSmartModalService} from "ngx-smart-modal";
import {Pager, Session} from "../_models";

interface DateRange {
  startDate: Moment,
  endDate: Moment
}

export abstract class LogsAbstractComponent extends CrudPagedListComponent implements OnInit, OnChanges {
  logs$: Observable<any> = this.data$;
  public fields = {}
  public fieldsList = Object.keys(this.fields);
  public logName = ''

  public fieldsWidthMapping = {
    body: 400,
    message: 700,
    message_id: 300
  }

  public detailsFields = this.fields;
  public detailsFieldsList = Object.keys(this.detailsFields);

  public selectedLogsRow = null;
  public session: Session = null;
  public showSessionDetails: boolean = false;

  @Input('date_range') dateRange: DateRange = null;

  protected constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected logsService: LogsAbstractService,
    protected alertService: AlertService,
    protected modalService: NgxSmartModalService,
    protected sessionService: SessionService
  ) {
    super(router, location, route, logsService, alertService);
  }

  ngOnInit() {
    super.ngOnInit()
  }

  getDefaultColumnWidth(fieldName: string) {
    if (fieldName in this.fieldsWidthMapping) {
      return this.fieldsWidthMapping[fieldName];
    }

    return 150;
  }

  protected generateFilters() {
    this.filter['start_date'] = this.dateRange.startDate.format('Y-MM-DD');
    this.filter['end_date'] = this.dateRange.endDate.format('Y-MM-DD');
  }

  ngOnChanges() {
    if (this.dateRange) {
      this.generateFilters();
      this.queryData();
    }
  }

  downloadLogs() {
    this.loading = true;
    this.logsService.downloadLogs(this.getQueryFilter(), this, this.id);
  }

  protected getQueryFilter() {
    if (this.dateRange) {
      this.generateFilters();
    }
    return super.getQueryFilter();
  }

  onSelect(event) {
    this.selectedLogsRow = event.selected[0];
    if ('sid' in this.selectedLogsRow && this.selectedLogsRow.sid) {
      this.sessionService.list({phone_call__phone_id: this.selectedLogsRow.sid})
        .subscribe((data: Pager) => {
          if (data.results.length > 0) {
            this.session = data.results[0]
          }
        }, err => {
          this.alertService.error(err)
        })
    }
    this.modalService.getModal(`logModal-${this.logName}`).open();
  }

  getDetailValue(fieldName) {
    let value = ''
    if (this.selectedLogsRow && this.selectedLogsRow[fieldName]) {
      value = this.selectedLogsRow[fieldName]
    }
    return value
  }
}
