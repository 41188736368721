export enum AlertSource {
  None = 0,
  Ethoca = 1,
  Verifi = 2,
  RDR = 3,
  Other = 4
}

export const AlertSourceLabel = []
AlertSourceLabel[AlertSource.None] = 'None';
AlertSourceLabel[AlertSource.Ethoca] = 'Ethoca';
AlertSourceLabel[AlertSource.Verifi] = 'Verifi';
AlertSourceLabel[AlertSource.RDR] = 'RDR';
AlertSourceLabel[AlertSource.Other] = 'Other';

export enum TranMatchedStatus {
  NoMatch = 0,
  MatchByCustomer = 1,
  MatchBySession = 2,
  MatchByOrder = 3,
}

export const TranMatchedStatusLabel = []
TranMatchedStatusLabel[TranMatchedStatus.NoMatch] = 'No Match';
TranMatchedStatusLabel[TranMatchedStatus.MatchByCustomer] = 'Match by Customer';
TranMatchedStatusLabel[TranMatchedStatus.MatchBySession] = 'Match by Session';
TranMatchedStatusLabel[TranMatchedStatus.MatchByOrder] = 'Match by Order';


export enum RequestType {
  NONE = 0,
  CANCEL_REQUEST = 1,
  RETURN_REQUEST = 2,
  BOTH = 3
}
export const RequestTypeLabel = []
RequestTypeLabel[RequestType.NONE] = 'None';
RequestTypeLabel[RequestType.CANCEL_REQUEST] = 'Cancel Request';
RequestTypeLabel[RequestType.RETURN_REQUEST] = 'Return Request';
RequestTypeLabel[RequestType.BOTH] = 'Cancel Request and Return Request';

