import { BaseModel, IconFile } from './index';

export interface Faq extends BaseModel {
  id: string | number;
  topics: any[];
  question: string;
  blurb: string;
  answer: string;
  chat_answer: string;
  keywords: string[];
  visits: number;
  up_votes: number;
  down_votes: number;
  include_in_copy: boolean;
  display: boolean;
}

export interface FaqTopic extends BaseModel {
  id: string | number;
  parent: string | number | null;
  title: string;
  icon: IconFile;
  faqs: string[] | number[];
  include_in_copy: boolean;
}

export enum SessionFaqStatus {
  VISIT = 1,
  UP_VOTE = 2,
  DOWN_VOTE = 3
}

export const SessionFaqStatusLabels = [
  'None',  // 0
  'Visit',  // 1
  'Up Vote',  // 2
  'Down Vote' // 3
]

export interface SessionFaq {
  question: string;
  status: SessionFaqStatus
}

export interface FaqSearchHistory {
  search_phrase: string;
  number_of_results_shown: number
}
