import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, AutoResponderService, EmailTemplateService } from '../_services';
import { FormBuilder } from '@angular/forms';
import { AutoResponderNewComponent } from "./autoresponder-new.component";
import { AutoResponder } from "../_models";


@Component({
  moduleId: module.id.toString(),
  templateUrl: './autoresponder-edit.component.html',
  styleUrls: ['./autoresponder.component.css']
})
export class AutoResponderEditComponent extends AutoResponderNewComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected responderService: AutoResponderService,
    protected alertService: AlertService,
    protected templateService: EmailTemplateService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, responderService, alertService, templateService, formBuilder);
    this.isNew = false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.data$.subscribe(
      (responder: AutoResponder) => {
        if (responder) {
          this.form.patchValue(responder);
          this.responder = responder;
          this.defaultType = responder.type;
          this.selectedType = responder.type;
          this.setObjectNameFromType(responder.type);

          for (let response of responder.responses) {
            this.addResponse(response);
          }
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

}
