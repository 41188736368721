import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, PaymentSourceService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {CrudSavePopupComponent} from '../_directives';
import {BankAccountNewFieldsComponent} from "./bank-account-new-fields.component";
import {takeUntil} from "rxjs/operators";
import {PaymentSourceType} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'bank-account-new-form',
  templateUrl: './bank-account-new-form.component.html'
})
export class BankAccountNewFormComponent extends CrudSavePopupComponent implements OnInit, AfterViewInit {
  @ViewChild(BankAccountNewFieldsComponent, { static: false }) accountFields: BankAccountNewFieldsComponent;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected paymentService: PaymentSourceService,
    private formBuilder: FormBuilder,
    protected alertService: AlertService
  ) {
    super(router, location, route, paymentService, alertService);
    this.objectName = 'Bank Account';
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.formBuilder.group({});
  }

  ngAfterViewInit() {
    setTimeout(() => this.form.addControl('bankAccount', this.accountFields.form), 0);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      this.accountFields.createToken()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          console.log(result);
          if (result.token) {
            this.paymentService.createFromToken(PaymentSourceType.BankAccount, result.token.id)
              .subscribe(
                data => {
                  this.alertService.success('Added New Bank Account.', true);
                  this.canDeactivateService.setFormDirty(false);
                  this.onSaveComplete(data);
                },
                error => {
                  if (error.status === 400) { //bad request
                    if ('token' in error.error) {
                      this.alertService.error(error.error.token);
                    }
                    else {
                      this.handleSubmitError(error);
                    }
                  }
                  else {
                    this.handleError(error);
                  }

                  this.loading = false;
                  this.cancel();
                });
          } else if (result.error) {
            // Error creating the token
            this.alertService.error(result.error.message);
            this.loading = false;
            this.cancel();
          }
        });
    }
  }
}
